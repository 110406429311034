/*   -------------------------------------------------- */
/*   -------------------------------------------------- */
/*   ------------    main_universal      ---------------- */
/*   
/*  
/*	
/*	
/*  
/*  
/*  
/*  
/*	


01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 followers_button
CONTRUCTION
    BORDERS
        TABLE OUTSIDE   border: 1px solid rgba(209,209,209, 0.7)
        TD OUTSIDE      border: 1px solid rgba(204,204,204, 0.7)

        TABLE INSIDE    border: 1px solid rgba(204,204,204, 0.0)
        TD INSIDE       border: 1px solid rgba(204,204,204, 0.2)

        BUTTON          border: 1px solid rgba(204,204,204, 0.3)
		
    BACKGROUND
        MAIN            background-color: rgba(255,255,255, 0.7);
        PAGE            background-color: rgba(255,255,255, 0.2);
        FORM PAGE       background-color: rgba(239,239,239, 0.7);
        MENU            background-color: rgba(239,239,239, 0.7);


/*  					rgba(255,255,255, 0.7) = #FFF (white)
/*						rgba(0,0,0, 0.7) =  (black)
/*						rgba(239,239,239, 0.7) = #EFEFEF (grey)
/* 						rgba(204,204,204, 0.7) = #CCC (dark border)
						rgba(0,255,0, 0.7)  =(green)
						rgba(255,255,0, 0.7) =(yellow)
						rgba(255,0,0, 0.7)  =(red)
						rgba(0,0,255, 0.7)  =(blue)


    rgba(15, 5, 26, 0.7) =  (black scheme)
    rgba(10, 245, 92, 0.7)  =(green scheme)     |   #43ff64d9
    rgba(4, 144, 242, 0.7)  =(blue scheme)      |   #0490f2b3
    rgba(255, 28, 255, 0.7)  =(pink scheme)
    rgba(204, 204, 204, 0.7)  =(grey scheme)
    rgba(252, 71, 71, 0.7)  =(red scheme)
    rgba(255, 255, 128, 0.7) =(yellow scheme)
    204,204,204
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001
    MESSAGE INPUT HEADERS
                        background:rgba(239,239,239, 0.1);
                        border-spacing: 0px;
                        border:hidden;
                        font-size: 1.05em;
                        font-weight: 500;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
    
    LABELS: login_form_label_top   reset_form_label 
                        
                        font-size: .90em;
                        font-weight: 600;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.2);
                        background:rgba(239,239,239, 0.7);


    BUTONS:
                        font-size: .90em;
                        font-weight: 600;
                        text-align:center;
                        text-indent:0px;
                        text-decoration:none;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.3);
                        background-color: rgba(204,204,204, 0.7);
                        -webkit-box-shadow: none !important;
                        -moz-box-shadow: none !important;
                        box-shadow: none !important;

theme_view_control_td_visible_cell_unfollow
event_option_main_table

event_multif_event_header_mid
ls_search_submit_button
event_type_15

theme_view_control_td_visible_cell
media_filter_recent
event_multi_td_type1
menu_panel
window_load_action
container
body {
window_load_action	loader_active
defaultBodyTable
controlBodyTable	mainBodyTable_td	font-family: 		catalog_image_viewer_dislike_frame  ..social_select_media_metrics


*/
/************************//*sprite_STANDARD

ROW 1 - ITEM 1 || -1px X -1px       || CLEAR                | clear_img.png
ROW 1 - ITEM 2 || -1px X -145px     || BUTTON TEMPLATE      | butt_template.png
ROW 1 - ITEM 3 || -1px X -285px     || LOADING              | loader.gif
ROW 1 - ITEM 4 || -1px X -435px     || SETTINGS             | sys_settings_button_large.png - profile_header_background.png - sys_settings_menu_button.png
ROW 1 - ITEM 5 || -1px X -580px     || RECYCLE ACTIVE       |
ROW 1 - ITEM 6 || -1px X -725px     || RECYCLE DEFAULT      |



*/
/************************//*	BUTTON TEMPLATE	*/

/************************//*sprite_STANDARD HEIGHT 30

ROW 1 - ITEM 1 || -1px X -1px       || CLEAR                | clear_img.png
ROW 1 - ITEM 2 || -1px X -145px     || BUTTON TEMPLATE      | butt_template.png
ROW 1 - ITEM 3 || -1px X -285px     || LOADING              | loader.gif
ROW 1 - ITEM 4 || -1px X -435px     || SETTINGS             | sys_settings_button_large.png - sys_settings_option.png - sys_settings_menu_button.png
ROW 1 - ITEM 5 || -1px X -580px     || RECYCLE ACTIVE       |
ROW 1 - ITEM 6 || -1px X -725px     || RECYCLE DEFAULT      |



*/
/************************//*	BUTTON TEMPLATE	
.CUL_button_1, .CUL_button_02, .CUL_button_2, .sys_search_bar_field_default{
    background-image: url('https://glbl-025498wzxxs-0knl-0001.s3.amazonaws.com/IMAGES/BUTTONS/sprite_STANDARD_HEIGHT30.png');
    background-repeat: no-repeat;
    background-size: 1603px 190px, base_functional_full_table;
  
}*/
/* ################################################ */
/* ######            MEDIA_VIEW_INDEX    ##### */
/*

event_create_active
theme_view_control_multifunction_parent
media_index_top_table
event_theme_default1
event_multif_event_header_mid
event_messagedisplayset_date_default
media_menu_pagination_option_disabled
.map
site_footer_table_full_td1
profile_header_table
window_view_UI_control_class
.profile_header_table
CUL_login_table
Arial, Helvetica, ls_set-notificationsystem_table
*/
/* ######################	EVENT VIEW AJAX	########################## */
/*--------*//*--------*//*--------*//*--------*//*--------*//*--------*/
/*
MULTI EVENT 

carousel
pagination_shiftview_left_option
pagination_shiftview_center_option
pagination_shiftview_right_option
multi_event_create_div
contact_compliance_0
*/
	.multi_pop_functional_listview_filter_menu {
		height:36px !important;
		width:718px;
		display:inline-block;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.multi_pagination_shiftview_left_option {
		height:36px !important;
		width:98px !important;
		display:inline-block;
		position:absolute;
		border:hidden;
		border-spacing: 0px;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.multi_pagination_shiftview_center_option {
		height:36px !important;
		width:480px !important;
		margin-left:36px;
		display:inline-block;
		position:absolute;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}

	.multi_pagination_shiftview_right_option {
		height:36px !important;
		width:140px !important;
		margin-left:578px;
		display:inline-block;
		position:absolute;
		border:hidden;
		border-spacing: 0px;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*

event_multif_base_table_td
event_item_select_results
event_multif_main_table


*/
	.multi_pagination_functional_listview_table_td_r1 {
		height:340px !important;
		width:718px;
		
		line-height: 140px;
		/*
		line-height: 340px;
		*/
		position: absolute;
		
		margin-top: 60px;
		display: inline-block;
		
		vertical-align:text-top !important;
		text-align:left;
		
		border:hidden;
		border-spacing: 0px;
		
		
	} 



	.event_multif_base_table_td {
		height:360px !important;
		width:718px !important;
		
		position: relative;
		vertical-align:text-top;
		background-color: rgba(25,55,255, 1.0);
	} 

	.event_multif_base_table_td1_1 {
		height:360px !important;
		width:100px !important;
		border-spacing: 0px;
		border-right: 1px solid #ccc;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		display:inline-block;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_multif_base_table_td1_1_1 {
		height:120px !important;
		width:100px !important;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: 1px solid #ccc;
		display:inline-block;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_multif_base_table_td1_1_2 {
		height:120px !important;
		width:100px !important;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: 1px solid #ccc;
		display:inline-block;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_multif_base_table_td1_1_3 {
		height:120px !important;
		width:100px !important;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		display:inline-block;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
/*--------*//*--------*//*--------*//*--------*//*--------*//*--------*/
/*
MULTI EVENT 

*/
	.event_multif_base_table_td2_1 {
		height:360px !important;
		width:617px !important;
		display: inline-block;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_multif_base_table_td2_1_1 {
		height:260px !important;
		width:617px !important;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: 1px solid #ccc;
		display:inline-block;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_multif_base_table_td2_1_1_1 {
		height:260px !important;
		width:451px !important;
		border-spacing: 0px;
		border-right: 1px solid #ccc;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		display:inline-block;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_multif_base_table_td2_1_1_2 {
		height:260px !important;
		width:165px !important;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		display:inline-block;
		background-color:transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_multif_base_table_td2_1_1_2_1 {
		height:54px !important;
		width:165px !important;
		line-height: 54;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: 1px solid #ccc;
		display:inline-block;
		background-color:transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.event_multif_base_table_td2_1_1_2_2 {
		height:205px !important;
		width:165px !important;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		display:inline-block;
		background-color:transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*--------*//*--------*//*--------*//*--------*//*--------*//*--------*/
/*
MULTI EVENT GUIDELINES

*/
	.event_multif_base_table_td3_1 {
		height:99px !important;
		width:617px !important;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		display:inline-block;
		text-align: center;
		vertical-align: middle;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_multif_base_table_td3_1_1 {
		height:99px !important;
		width:122px !important;
		border-spacing: 0px;
		border: hidden;	
		margin-left: 1;
		display:inline-block;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*
background-color:rgba(239,85,147, 0.3);
*/
	.event_multif_event_header_mid {
		height:160px !important;
		width:452px !important;
		border-spacing: 0px;
		border-right: hidden;
		border: hidden;
		background-color:transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;	
	}
	/*
	
	*/
	.event_multi_theme_default1 {
		height:160px !important;
		width:452px !important;
		position:absolute;
		display:inline-block;
		background-color:darkgray;
		background-position:center;
		background-size:  cover;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_theme_screen {
		z-index: 0;
		height:160px !important;
		width:452px !important;
		position:absolute;
		display:inline-block;
		background-color:rgba(255,255,255, 0.5);
		background-position:center;
		background-size:  cover;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*
top:0px;

*/
	.event_multi_type_div{
		height:85px !important;
		width:452px !important;
		position:absolute;
		left:0px;
		
		line-height:85px;
		border:hidden;
		background-color:transparent;
		padding:0;
		margin-top: -95px;
		margin-left: 105px;
		text-align:left;
		vertical-align:text-top !important;
	}
/*
border:1px solid #3B9E43;
*/
	.event_multi_td_type1{
		height:80px !important;
		width:372px !important;
		line-height:80px;
		border:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:left;
		vertical-align:middle !important;
		text-indent:2px;
	}
/*
height:125px !important;
		width:130px !important;
*/
	.event_multi_td_type2{
		height:80px !important;
		width:85px !important;
		line-height:80px;
		border:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:right;
		vertical-align:middle !important;
	}
	/* #######################	BASIC	########################## */	

	
	/* ######################	ITEM	########################## */
	

	
	
	
	
	/* ######################	NONSHARE	########################## */
	
	
	
	
	
	
	
/* ######################	     	########################## */
/*
	.event_set_date_button_default
	
*/
	.event_multif_main_table{
		height:615px !important;
		width:718px !important;
		/*
		line-height:615px;
		*/
		vertical-align:text-top!important;
		
		border:1px solid #CCC; 
		
		
	}
.event_multif_main_table_td1{
    height:258px !important;
    width:718px !important;
    line-height:258px;

    vertical-align:text-top !important;

    border: hidden;
    padding:0;
    margin:0;


}
/*
.event_option_main_table
*/
.event_multif_main_table_td2{
    height:361px !important;
    width:718px !important;
    line-height:361px;
	vertical-align: top;
    /*
    margin-top: 260px;
    */
    position: absolute;

    border-top:1px solid #CCC;  
    border-bottom:hidden;  
    border-left:hidden; 
    border-right:hidden; 

    padding:0;
    margin:0;

}
/*
.map
margin-top: 50px;
*/
	.event_multif_map {
		height:254px !important;
		width:718px !important;
		line-height:254px;
		display: none;
		
		vertical-align:top !important;
		position:absolute !important;
		
		
		
		border: hidden;
		padding:0;
		margin:0;
	}

.event_item_geo_map {
	height:530px !important;
	width:668px !important;
	line-height:530px;
	/*
	display: none;
	*/
	position:absolute !important;
	vertical-align:top !important;
	text-align: left !important;
	
	border: hidden;
	padding:0;
	margin:0;
}

.event_item_geo_mapkey {
	height:573px !important;
	width:952px !important;
	line-height:573px;
	/*
	display: none;
	*/
	position:absolute !important;
	vertical-align:top !important;
	text-align: left !important;
	
	border: hidden;
	padding:0;
	margin:0;
}
/* ######################	MAP 	########################## 
	#map {
	  position: relative;
		
	}
*/

	#info {
	  position: absolute;
	  height: 1px;
	  width: 1px;
	  z-index: 100;
	}
	.tooltip.in {
	  opacity: 1;
	}
	.tooltip.top .tooltip-arrow {
	  border-top-color: white;
	}
	.tooltip-inner {
	  border: 2px solid white;
	}

/* ################################################ */
/*
position: absolute;
z-index: 100;
 height:404px !important;

*/
	
/* ################################################ */
/* ######            MEDIA_INDEX    ##### */
/*
	event_option_main_table_td_command
	event_full_base_table_td2
event_full_base_table_td2

	border:1px solid #CCC;
	event_options_temp
	guideline_popup_view_options_absolute


*/	
	.media_messagedisplaylistview_flare_total{
		z-index: 0;
	  	height:50px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: -170;
		margin-left: 180px;
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	.media_messagedisplaylistview_flare_bio{
		z-index: 0;
	  	height:50px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: -170;
		margin-left: 180px;
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(144,178,71, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	.media_messagedisplaylistview_flare_geo{
		z-index: 0;
	  	height:50px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: -170;
		margin-left: 180px;
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(240,239,136, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	.media_messagedisplaylistview_flare_socio{
		z-index: 0;
	  	height:50px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: -170;
		margin-left: 180px;
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(191,84,46, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	
	
	
	.media_popup_view_options_absolute {
		height:207px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
		display: inline-block;
		margin-top:5px;
		margin-left:2px;
		vertical-align:middle;
		text-align:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.media_filter_activity_view {
		height:40px !important;
		width: 100px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		color: black;
		
		background: url(../images/buttons/butt_template.png) #29913C;
		background-size: 40px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_filter_activity_event {
		height:207px !important;
		width:230px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_filter_recent_view {
		height:40px !important;
		width: 100px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		color: black;
		
		background: url(../images/buttons/butt_template.png) #D53EC6;
		background-size: 40px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_filter_recent_event {
		height:207px !important;
		width:230px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_filter_likes_view {
	height:40px !important;
		width: 100px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		color: black;
		
		background: url(../images/buttons/butt_template.png) #2E959B;
		background-size: 40px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_filter_likes_event {
		height:207px !important;
		width:230px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_filter_dislikes_view {
		height:40px !important;
		width: 100px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		color: black;
		
		background: url(../images/buttons/butt_template.png) #928BD2;
		background-size: 40px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_filter_dislikes_event {
		height:207px !important;
		width:230px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 


	.media_filter_shared_view {
		height:40px !important;
		width: 100px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		color: black;
		
		background: url(../images/buttons/butt_template.png) #D0CB48;
		background-size: 40px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.media_filter_shared_event {
		height:207px !important;
		width:230px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_filter_bio_view {
		height:40px !important;
		width: 100px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		color: black;
		
		background: url(../images/buttons/butt_template.png) #5EA375;
		background-size: 40px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_filter_bio_event {
		height:207px !important;
		width:230px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.media_filter_geo_view {
		height:40px !important;
		width: 100px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		color: black;
		
		background: url(../images/buttons/butt_template.png) #5B64A2;
		background-size: 40px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.media_filter_geo_event{
		height:207px !important;
		width:230px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.media_filter_socio_view {
		height:40px !important;
		width: 100px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		color: black;
		
		background: url(../images/buttons/butt_template.png) #4F2675;
		background-size: 40px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_filter_socio_event {
		height:207px !important;
		width:230px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_filter_temp {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	



	/*\




*/
	.media_filter_stat{
		height:40px !important;
		width: 100px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 40px 30px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.media_filter_image{
		height:40px !important;
		width: 100px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 40px 30px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	/*



	*/
	.media_index_top_table{
		height:40px !important;
		width:720px !important;
		
		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:right;
		vertical-align:middle !important;
	}
/*
	.media_index_menu_table {
		height:25px !important;
		width:718px !important;
		
		border-top:hidden;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:hidden;
		
		vertical-align: top;
	} 
*/
	
	.media_index_bottom_table {
		height:541px !important;
		width:720px !important;
		
		vertical-align: top;
	} 
/*
ls_form_event_listview_frame
event_messagedisplaylistview_flare
event_messagedisplaylistview_activity
event_messagedisplaylistview_stat
ls_default_results_main
guideline_popup_view_options_absolute
width:239px;
*/
	
	.media_view_absolute {
		height:175px !important;
		width:234px;
		border-spacing: 0px;
		border:hidden;
		display: inline-block;
		margin-top:4px;
		margin-left:4px;
		vertical-align:middle;
		text-align:left;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*
margin-top: 5px;
		margin-left: 5px;

*/
	.ls_form_media_listview_frame {
		height:175px !important;
		width:234px;
		line-height: 170;
		
		display:inline-block;
		
		
		
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 2.00em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:text-top;
		text-decoration:none;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
			
		background: url(../images/buttons/clear_img.png);
		background-size: 234px 175px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	} 
	/*

	*/
	.media_messagedisplaylistview_flare{
		z-index: 0;
	  	height:50px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: -170;
		margin-left: 180px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	/*

	media_messagedisplaylistview_dislike_enabled
	*/
	.media_messagedisplaylistview_like_disabled{
		z-index: 10;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 140px;
		margin-left: 151px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.media_messagedisplaylistview_like_enabled{
		z-index: 10;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 140px;
		margin-left: 151px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(0, 255, 0, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.media_messagedisplaylistview_dislike_disabled{
		z-index: 10;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 140px;
		margin-left: 100px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.media_messagedisplaylistview_dislike_enabled{
		z-index: 10;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 140px;
		margin-left: 100px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255, 25, 52, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	/*
media_messagedisplaylistview_dislike_disabled

	*/
	.media_messagedisplaylistview_activity{
		z-index: 3;
	  	height:40px !important;
		width:88px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 5px;
		margin-left: 3px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .30em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	/*
	base_functional_profile_table_menu_td

	*/
	.media_functional_table_menu {
		height:40px !important;
		width:720px;
		line-height: 10;
		vertical-align:middle;
		text-align: center;
		
		background-color: rgba(255,255,255, 0.7);
		
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	} 
	.media_functional_table_menu_td1 {
		height:40px !important;
		width:282px !important;
		line-height: 10;
		border-left:hidden;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
		border-bottom:1px solid #CCC;
		border-spacing: 0px;
		vertical-align:top;
	} 
	.media_functional_table_menu_td2 {
		height:40px !important;
		width:108px !important;
		line-height: 10;
		border-left:hidden;
		border-right:hidden;
		border-top:1px solid #CCC;
		border-bottom:1px solid #CCC;
		border-spacing: 0px;
		vertical-align:middle;
		text-align: center;
	} 
	.media_functional_table_menu_end {
		height:40px !important;
		width:100px !important;
		line-height: 10;
		
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
		border-spacing: 0px;
		
		vertical-align:top;
		text-align: left  !important;
	} 
	/*
	event_view_pending_active
	add
plus

	*/
	.media_filter_add{
		height:52px !important;
		width: 100px !important;
		line-height: 0;
		
		text-align:center !important;
		vertical-align:middle !important;
		
		position: absolute;
		margin-top: -10;
		
		background: url(../images/buttons/plus_button.png);
		background-size: 60px 60px;
		background-position:50% 45%;
   		background-repeat: no-repeat;
		
		border-right:hidden;
		border-left:1px solid #CCC;
		border-top:1px solid #CCC;
		border-bottom:1px solid #CCC;
		
		border-spacing: 0px;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.media_filter_menu{
		height:40px !important;
		width: 100px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 40px 30px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	

/* ################################################ */
/* ######             ACTIVE EVENT_INSTANCE_VIEW       ##### */

/*
full_MGMT_query_metrics

event_messagedisplayset_date_default

*/
	.return_menu_absolute {
		z-index: 1;
		height:40px !important;
		width:110px;
		display:inline-block;
		
		line-height:10px;
		
		display:inline-block;
		position:absolute;
		left:-5px;
		top:5px;
		
		vertical-align: top;
		text-align:right;
		
		font-size: 10;
		font-weight: 400;
		color:#666;
		text-decoration:none;
		
		background-color: transparent;
		border:hidden;
		
	}
	.event_messagedisplayset_date_instance{
	  	height:205px !important;
		/*width: 345px;
		100%;
		
		*/
		width:165px;
		line-height:30px !important;
		position:absolute;
		background-color:transparent;
		border-spacing: 0px;
		border:hidden;
		color:#888888;
		font-size: 2.0em;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
	  }

/* ################################################ */
/* ######             ACTIVE EVENT_LIST_VIEW       ##### */
	.base_functional_event_listview_table_td_active {
		height:537px !important;
		width:720px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
		
	} 
	/* background-color:#FFF; pagination 
	.base_functional_event_listview_table_td_r2 {
		height:56px !important;
		width:720px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
	} 

line-height:537px !important; 
	*/
	.ls_default_event_listview_main{
		height:537px !important; 
	  	width:718px !important;
		
		
		text-align:left;
		vertical-align:text-bottom;
	}
	.ls_default_event_listview_main_row{
		
	  	width:717px !important;
		
		text-align:left;
		vertical-align:middle;
		
		
	}

	/*
	ls_form_event_option_frame
	event_messagedisplayguideline_pop_default
	event_messagedisplayguideline_pop_flare
	
	position:absolute;
	
	*/
	.ls_form_event_listview_frame {
		height:186px !important;
		width:352px;
		line-height: 178;
		
		display:inline-block;
		
		
		margin-top: 5px;
		margin-left: 5px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 2.00em  !important;
		font-weight: 700;
		text-align:left;
		vertical-align:bottom;
		text-decoration:none;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
			
		background: url(../images/buttons/clear_img.png);
		background-size: 120px 120px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	} 
	.event_messagedisplaylistview_date{
		z-index: 0;
	  	height:50px !important;
		width:278px !important;
		
		display: inline-block;
		line-height: 20px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 20;
		margin-left: -348px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .80em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:5% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.event_messagedisplaylistview_label{
		z-index: 0;
	  	height:60px !important;
		width:344px !important;
		
		display: inline-block;
		line-height: 60px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 80;
		margin-left: -348px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 2.00em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:5% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.event_messagedisplaylistview_guide1{
		z-index: 0;
	  	height:40px !important;
		width:220px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 147;
		margin-left: -350px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:5% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }

	.event_messagedisplaylistview_stat{
		z-index: 0;
	  	height:40px !important;
		width:76px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 147;
		margin-left: -126px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:5% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.event_messagedisplaylistview_activity{
		z-index: 0;
	  	height:40px !important;
		width:40px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 147;
		margin-left: -45px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .30em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.event_messagedisplaylistview_flare{
		z-index: 0;
	  	height:60px !important;
		width:60px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 10;
		margin-left: -65px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }

/* ################################################ */
/* ######              LANDSCAPE_EVENTS       ##### */

	
/*

	
	event_option_main_table_td_command
	type_list_options_1

	window_panel_land 
	event_messagedisplaytype_list_view_options_full
	top: 0;

	
*/
/* ########  EVENT RATING  ##########*/

	/*
	feedback_rating_td
	feedback_rating_on	
event_full_base_table_td2_2_1_2_1
	
event_rating_label
event_stat_label
input
position:absolute;
text-indent:5px;
	*/
	 /*
	event_full_base_table_td2

*/
	.event_option_main_table_filler{
		height:215px !important;
		width:720px !important;
		line-height:215px;
		border-top:hidden;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}


	
	.event_full_base_table {
		height:618px !important;
		width:718px !important;
		display: inline-block;
		background:#FFF;
		border-right: 1px solid #ccc;
		border-left: 1px solid #ccc;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		border: hidden;
		border-spacing: 0px;
	} 
	/*
	add
event_theme_default1
base_functional_event_table_td_r4_3
base_functional_profile_table_td_r4_3

*/
.event_create_active{
    height:50px !important;
    width: 50px !important;

    /**/
	position: absolute;
	top:0px;
    margin-top: 0px;

    text-align:center !important;
    vertical-align:text-top!important;
    border:hidden;

    background: url(../images/buttons/plus_button.png) rgba(255,255,255, 0.0);
    background-size: 70px 40px;
    background-repeat: no-repeat;
    background-position:center;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*  8888888 WINDOW *********/
/*
	event_option_main_table
	event_item_select_results
	.event_item_select_results
*/	
	.multifunction_item_ajax{
		z-index: 10;
		height:360px !important;
		width:718px !important;
		
		position: absolute;
		display: none;
		vertical-align:text-top !important;
		
		background-color: rgba(225,255,255, 0.0);
	}
	.event_item_select_results {
		height:360px !important;
		width:718px !important;
		
		
		vertical-align:text-top !important;
		
		background-color: rgba(25,255,255, 0.0);
	} 
	.event_full_base_table_td1 {
		height:257px !important;
		width:718px !important;
		/*vertical-align: top;*/
	} 

	.event_full_base_table_td2 {
		height:330px !important;
		width:718px !important;
		
		vertical-align: top;
	} 
	/*
.event_full_base_table_td2
.event_multif_main_table_td2
	margin-top: 255px;
	*/
	.multi_event_create_div {
		height:100px !important;
		width:100px !important;
		
		position:relative;
		margin-top: -110px;
		margin-left: 10px;
		
		vertical-align:text-top !important;
		
		background:#22B16A;
	
		border: hidden;
		border-spacing: 0px;
	} 

/*
EVENT TYPES


*/
/*--------*//*--------*//*--------*//*--------*/
	/* #######	DESC TYPE VIEW	######### */
	.type_view_options_absolute {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:1px solid #CCC;
		display: inline-block;
		margin-top:10px;
		margin-left:9px;
		vertical-align:middle;
		text-align:left;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.type_event_view_options_select {
		height:100px !important;
		width:100px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		margin-top:145px;
		margin-left:195px;
		
		
		position:absolute;
		font-size: .75em;
		font-weight: 400;
		color:#666;
		text-decoration:none;
		background-color:#FFF;
		background: url(../images/buttons/butt_template.png);
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	

/*--------*//*--------*//*--------*//*--------*//*--------*//*--------*/
/*
EVENT GUIDELINES

*/
	.event_full_base_table_td2_2_2 {
		height:99px !important;
		width:617px !important;
		
		position: absolute;
		margin-top: 261px;
		
		display:inline-block;
		
		text-align: center;
		vertical-align: middle;
		
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*
border-right: 1px solid #ccc;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
event_full_base_table_td2_2_1_2_2

*/
	.event_full_base_table_td2_2_2_1 {
		height:99px !important;
		width:122px !important;
		
		display:inline-block;
		position:relative;
		
		margin-left: 1;
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border: hidden;	
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*

*/
	.event_item_base_table_td {
		height:290px !important;
		width:662px !important;
		
		display:inline-block;
		
		margin-top: -305px;
		margin-left: 0px;
		
		position: absolute;
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_item_base_table_td1 {
		height:288px !important;
		width:178px !important;
		
		display:inline-block;
		
		margin-top: 0px;
		margin-left: 0px;
		
		
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background-color: rgba(255,42,255, 0.7);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
/**********************************/
	.event_item_base_table_td2 {
		height:288px !important;
		width:481px !important;
		
		display:inline-block;
		
		margin-top: 0px;
		margin-left: 0px;
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border:hidden;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_item_base_table_td2-1 {
		height:213px !important;
		width:481px !important;
		
		margin-top: 0px;
		margin-left: 0px;
	
		vertical-align: top !important;
		
		border-spacing: 0px;
		border:hidden;
		
		background-color: rgba(14,255,255, 0.6);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_item_base_table_td2-2 {
		height:76px !important;
		width:481px !important;
		
		margin-top: 0px;
		margin-left: 0px;
		
		position: absolute;
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border:hidden;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*
event_item_settings_main_table_theme02_rw
*/
/**********************************/
	.event_item_config3_td1 {
		height:80px !important;
		width:662px !important;
		
		margin-top: 0px;
		margin-left: 0px;
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
.form_settings_config_td01 {
    height:80px !important;
    width:714px !important;

    margin-top: 0px;
    margin-left: 0px;

    vertical-align: top !important;

    border-spacing: 0px;
    border:1px solid #CCC;

    background-color: rgba(255,255,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.form_settings_config_td02 {
    height:352px !important;
    width:714px !important;

    margin-top: 0px;
    margin-left: 0px;

    vertical-align: top !important;

    border-spacing: 0px;
    border:hidden;

    background-color: rgba(255,255,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.form_settings_config_td02_01 {
    height:150px !important;
    width:714px !important;

    margin-top: 0px;
    margin-left: 0px;

    vertical-align: top !important;

    border-spacing: 0px;
    border:hidden;

    background-color:rgba(255,255,255, 0.5);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.form_settings_config_td02_01td{
    height:40px !important;
    width:712px !important;
    line-height:40px;
    
    margin-top: 0px;
    border:1px solid #ccc;
    background-color:rgba(255,255,255, 0.5);
    padding:0;
    margin:0;
    vertical-align:top !important;
}
.form_settings_config_td02_02td{
    height:110px !important;
    width:400px !important;
    line-height:110px;
    border:hidden; 
    background-color:rgba(255,255,255, 0.5);
    padding:0;
    margin:0;
    vertical-align:top !important;
}	
.form_settings_config_empty {
    /*
        event_container_data3
    */
    height:20% !important;
    width:96% !important;
    position: absolute;
	margin-top: 0px;
    border: hidden;
    border-spacing: 0px;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-size: 30% 50%;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.form_settings_parent_event_host_config_empty {
    /*
        event_container_data3
    */
    height:33% !important;
    width:84% !important;
    position: absolute;
	margin-top: 1px;
    margin-left: 1px;
    border: hidden;
    border-spacing: 0px;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-size: 30% 50%;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

/*
event_item_config3_td2-2
event_host_list_sadmin_default
event_item_settings_panel_empty

.event_item_config3_td2-1_rw{
    height:40px !important;
    width:662px !important;
    line-height:40px;


    margin-top: 0px;
    border:hidden; 
    background-color:transparent;
    padding:0;
    margin:0;
    vertical-align:top !important;
}	
*/

	.switch_form_label_item_status {
		height:25px !important;
		width:215px;
		line-height:25px !important;
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:45px;
		
		vertical-align:middle !important;
		border-spacing: 0px;
		border-right:1px solid #ccc;
		background: url(../images/buttons/sys_pin_default.png);
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position: 1% 50%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.switch_form_label_item_privacy {
		height:25px !important;
		width:215px;
		line-height:25px !important;
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:45px;
		
		vertical-align:middle !important;
		border-spacing: 0px;
		border-right:1px solid #ccc;
		background: url(../images/buttons/sys_security_option.png);
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position: 1% 50%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.switch_form_label_item_restictions {
		height:25px !important;
		width:215px;
		line-height:25px !important;
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:45px;
		
		vertical-align:middle !important;
		border-spacing: 0px;
		border-right:1px solid #ccc;
		background: url(../images/buttons/sys_security_option.png);
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position: 1% 50%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/**********************************/
	.event_item_config3_td2 {
		height:159px !important;
		width:662px !important;
		
		margin-top: 0px;
		margin-left: 0px;
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border:hidden;
		
		background-color: rgba(255,255,255, 0.6);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_item_config3_td2-1 {
		height:10px !important;
		width:662px !important;
		
		margin-top: 0px;
		margin-left: 0px;
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border:hidden;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_item_config3_td2-2 {
		height:150px !important;
		width:662px !important;
		
		margin-top: 50px;
		margin-left: 0px;
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border:hidden;
		
		background-color: rgb(255,255,255, 0.6);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_item_config3_td2-1_rw{
		height:40px !important;
		width:662px !important;
		line-height:40px;
		
		
		margin-top: 0px;
		border:hidden; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top !important;
	}	
	.event_item_config3_td2-1_rwtd{
		height:40px !important;
		width:660px !important;
		line-height:40px;
		position: absolute;
		margin-top: 0px;
		border:1px solid #ccc;
		
		background-color:rgba(255,255,255, 0.5);
		padding:0;
		margin:0;
		vertical-align:top !important;
	}
	.cohost_text_full_field{
	  	height:36px !important;
		/*width: 345px;*/
		width: 70px;
		line-height:36px !important;
		
		margin-top: 2px;
		margin-left: 5px;
		
		background-color:rgb(239,239,239, 0.6);
		border-spacing: 0px;
		border:1px solid #ccc;
		vertical-align: top  !important;
		
		color:rgb(15, 5, 26, 0.9);
		font-size: .85em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		
	  }
	.cohost_label_full_field{
	  	height:32px !important;
		/*width: 345px;*/
		width: 460px;
		line-height:32px !important;
		
		margin-top: 2px;
		margin-left: 5px;
		
		background-color:rgb(239,239,239, 0.6);
		border-spacing: 0px;
		border:1px solid #ccc;
		
		vertical-align: top  !important;
		
		font-size: .85em;
		font-weight: 500;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
		
	  }
.cohost_business_label_full_field{
    height:32px !important;
    /*width: 345px;*/
    width: 510px;
    line-height:32px !important;

    margin-top: 2px;
    margin-left: 5px;

    background-color:rgb(239,239,239, 0.6);
    border-spacing: 0px;
    border:1px solid #ccc;

    vertical-align: top  !important;

    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:5px;

  }
	.cohost_add_button{
	  	height:36px !important;
		/*width: 345px;*/
		width: 38px;
		line-height:36px !important;
	
		margin-top: 2px;
		margin-left: 5px;
		vertical-align: top  !important;
		border-spacing: 0px;
		border:1px solid #ccc;
		
		font-size: .85em;
		font-weight: 500;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
		
		background: url(../images/buttons/plus_button.png) #FFF;
		background-position:center;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.cohost_view_button{
	  	height:36px !important;
		/*width: 345px;*/
		width: 50px;
		line-height:36px !important;
		
		margin-top: 2px;
		margin-left: 5px;
		vertical-align: top  !important;
		background-color:rgb(239,239,239, 0.6);
		border-spacing: 0px;
		border:1px solid #ccc;
		
		font-size: .85em;
		font-weight: 500;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
		
	  }

	.event_item_config3_td2-2_rw{
		height:110px !important;
		width:662px !important;
		line-height:110px;
		
		margin-top: 10px;
		
		border:hidden; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top !important;
	}	


/*


*/
 
/*
event_host_list_admin_remove
.event_host_list_sadmin_default{
    height:33px !important;
    width:33px;
    line-height:33px  !important;

    margin-top: 2px;
    margin-left: 2px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid #CCC;

    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
*/

.business_host_list_admin_active{
    height:33px !important;
    width:33px;
    line-height:33px  !important;

    margin-top: 2px;
    margin-left: 2px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid #CCC;

    background: url(../images/buttons/butt_template.png) rgba(13,255,255, 0.9);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_host_list_proj_active{
    height:33px !important;
    width:33px;
    line-height:33px  !important;

    margin-top: 2px;
    margin-left: 2px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid #CCC;

    background: url(../images/buttons/butt_template.png) rgba(255,12,255, 0.9);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.business_host_list_pending{
    height:33px !important;
    width:100px;
    line-height:33px  !important;

    margin-top: 40px;
    margin-left: 2px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid #CCC;

    background: url(../images/buttons/butt_template.png) rgba(255,255,47, 0.6);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.business_creator_list_admin{
    height:33px !important;
    width:33px;
    line-height:33px  !important;

    margin-top: 2px;
    margin-left: 70px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid #CCC;

    background: url(../images/buttons/butt_template.png) rgba(47,180,255, 0.9);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*
.event_host_list_admin_default{
    height:33px !important;
    width:33px;
    line-height:33px  !important;

    margin-top: 2px;
    margin-left: 2px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid #CCC;

    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
*/
/**********************************/
	.event_item_config3_td3 {
		height:265px !important;
		width:662px !important;
		line-height: 239px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border:hidden;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	
	.item_map {
		height:260px !important; 
	  	width:663px !important;
		margin-left:0px;
		margin-top: 0px;
		position: absolute;
		
	}

.location_search_submit_button {
    height: 40px;
    width: 86px;
    line-height:20px;
    position: absolute;
    margin-top: 200px;
    margin-bottom: 6px;
    margin-left: 570px;
	/*
    font-size: 1em;
    font-weight: 400;
	*/
    vertical-align: middle;
    text-align:center;
    text-decoration:none;
    background-color:rgba(255,255,47, 0.8);
    border:1px solid rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.location_set_submit_button {
    height: 40px;
    width: 86px;
    line-height:20px;
    position: absolute;
    margin-top: 200px;
    margin-bottom: 6px;
    margin-left: 480px;
	/*
    font-size: 1em;
    font-weight: 400;
	*/
    vertical-align: middle;
    text-align:center;
    text-decoration:none;
    background-color:rgba(47,255,255, 0.8);
    border:1px solid rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.location_center_submit_button {
    height: 40px;
    width: 86px;
    line-height:20px;
    position: absolute;
    margin-top: 155px;
    margin-bottom: 6px;
    margin-left: 570px;
	/*
    font-size: 1em;
    font-weight: 400;
	*/
    vertical-align: middle;
    text-align:center;
    text-decoration:none;
    background-color:rgba(255,47,255, 0.8);
    border:1px solid rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.location_search_full_field{
    height:38px !important;
    /*width: 345px;*/
    width: 470px;
    line-height:25px !important;

    position: absolute;
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 20px;

    background-color:rgb(255,255,255, 0.8);
    border-spacing: 0px;
    border:1px solid #ccc;
    /*
	color: black;
    font-size: .85em;
    font-weight: 500;
	*/
    text-align:left;
    
    text-decoration:none;
    text-indent:5px;

  }
/**********************************/
.event_item_config4_td1{
	height:80px !important;
	width:662px !important;
		
	margin-top: 0px;
	margin-left: 0px;
		
	vertical-align: top !important;
		
	border-spacing: 0px;
	border:1px solid #CCC;
		
	background-color: rgba(255,255,255, 0.7);
		
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_item_config4_td2 {
	height:100px !important;
	width:662px !important;
		
	margin-top: 0px;
	margin-left: 0px;
		
	vertical-align: top !important;
		
	border-spacing: 0px;
	border:hidden;
		
	background-color: rgba(255,58,255, 0.6);
		
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}


.event_item_config4_td3{
	height:330px !important;
	width:662px !important;
	line-height: 330px;
		
	margin-top: 0px;
	margin-left: 0px;
		
	vertical-align: top !important;
		
	border-spacing: 0px;
	border:hidden;
		
	background-color: rgba(255,255,255, 0.7);
		
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.event_item_config4_td1-1{
	height:330px !important;
	width:662px !important;
		
	margin-top: 0px;
	margin-left: 0px;
		
	vertical-align: top !important;
		
	border-spacing: 0px;
	border:hidden;
		
	background-color: rgb(239,239,239, 0.6);
		
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_item_config4_td1-1_rw{
	height:40px !important;
	width:662px !important;
	line-height:40px;
		
		
	margin-top: 0px;
	border:hidden; 
	background-color:transparent;
	padding:0;
	margin:0;
	vertical-align:top !important;
}	

.event_item_config4_td1-1_rwtd{
	height:40px !important;
	width:660px !important;
	line-height:40px;
	position: absolute;
	margin-top: 0px;
	border:1px solid #ccc;
		
	background-color:rgba(255,255,255, 0.5);
	padding:0;
	margin:0;
	vertical-align:top !important;
}

.event_item_config4_td1-2_rw{
	height:110px !important;
	width:662px !important;
	line-height:110px;
		
	margin-top: 10px;
		
	border:hidden; 
	background-color:transparent;
	padding:0;
	margin:0;
	vertical-align:top !important;
}	

.event_item_notifications_panel_empty {
		height:250px !important;
		width:660px !important;
		line-height:250px;
		background:#999;
		border: hidden;
		text-align:center;
		vertical-align:middle;
		border-spacing: 0px;
		background: url(../images/buttons/butt_template.png);
		background-size: 400px 200px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/**********************************/


/**********************************/


/**********************************/
/*
ajax_location_menu_search_full_field

*/








/*  8888888 PANEL *********/
	.event_full_panel_table {
		height:621px !important;
		width:232px !important;
		display: inline-block;
		border: hidden;
		text-align: right;
		border-spacing: 0px;
		border:0; 
		padding:0;
		margin:0;
		
	} 
/*
	window_panel_Table_td
	window_panel_td1_land 
border-right: 1px solid #ccc;
		border-left: 1px solid #ccc;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
*/


	.event_panel_land {
		height:620px;
		width:210px !important;
		background:#FFF;
		
		
		border-spacing: 0px;
		margin-top: 0px;
		margin-left: 23px;
		
	}
	.event_panel_td1_land  {
		height:200px;
		width:210px !important;
		background-color:#CCC;
		word-wrap: break-word !important;
		
	}
	.event_panel_td2_land {
		height:200px;
		width:210px !important;
		background-color:#CCC; 
	}
	.event_panel_td3_land  {
		height:200px;
		width:210px !important;
		background-color:#CCC;
	}

	/**/
	/* #######	EVENT CALENDAR MULTI SELECT	######### */
/*
0
*/
	.event_multi_select_highlight {
		border-spacing:  !important;
		border:2px solid #35BCED !important;
		
	} 
	.event_multi_select_highlight_start {
		z-index: 999;
		border-spacing: !important;
		border:2px solid green !important;
		
	} 
	.event_multi_select_highlight_end {
		z-index: 999;
		border-spacing: !important;
		border:2px solid red !important;
		
	} 
	.event_multi_select_highlight_duration {
		z-index: 900;
		border-spacing: !important;
		border:2px solid #74C5FA !important;
		
	} 
	.ls_form_event_option_td_form {
		height:48px !important;
		width:190px !important;
		line-height: 45px;
		vertical-align: middle;
		border-spacing: 0px;
		border:hidden;
		display:inline-block;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.ls_form_event_option_td_filler_form {
		height:48px !important;
		width:30px !important;
		line-height: 45px;
		vertical-align: middle;
		border-spacing: 0px;
		border:hidden;
		display:inline-block;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.ls_form_event_option_td_submit_form {
		height:48px !important;
		width:130px !important;
		line-height: 45px;
		vertical-align: middle;
		border-spacing: 0px;
		border:hidden;
		display:inline-block;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*


*/
	.ls_form_event_option_td_set_form {
		height:48px !important;
		width:65px !important;
		line-height: 48px;
		vertical-align: middle;
		text-align: center !important;
		border-spacing: 0px;
		border:hidden;
		display:inline-block;
		background-color: transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*
	ls_form_event_option_td_set_form
	ls_form_event_option_td_time_form
	ls_form_event_option_td_form

*/
	.ls_form_menu_button_active {
		height: 40px;
		width: 60px;
		line-height:33px;
		
		color:#666;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color:#EFEFEF; 
		border:1px solid #CCC;
		
		background: url(../images/buttons/sys_metrics_buttonActive.png);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.ls_form_menu_button_disabled {
		height: 40px;
		width: 60px;
		line-height:33px;
		
		color:#666;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color:#EFEFEF; 
		border:1px solid #CCC;
		
		background: url(../images/buttons/sys_metrics_buttonDefault.png);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.ls_form_location_set_button_active {
		height: 40px;
		width: 60px;
		line-height:33px;
		
		color:#666;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		background-color:#EFEFEF; 
		border:1px solid #CCC;
		background: url(../images/buttons/sys_pin_buttonActive.png);
		background-size: 38px 38px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.ls_form_location_set_button_disabled {
		height: 40px;
		width: 60px;
		line-height:33px;
		
		color:#666;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		
		background-color:#EFEFEF; 
		border:1px solid #CCC;
		background: url(../images/buttons/sys_pin_buttonDefault.png);
		background-size: 38px 38px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.ls_form_date_set_button_active {
		height: 40px;
		width: 60px;
		line-height:33px;
		
		color:#666;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		background-color:#EFEFEF; 
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png);
		background-size: 38px 38px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.ls_form_set_complete_button {
		height: 40px;
		width: 125px;
		line-height:33px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color:#EFEFEF; 
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*	

position: absolute;
ls_search_submit_button
opacity
pop_full_profile_table_empty_medium
width: 665px !important;
*/
	.event_options_control_menu{
		z-index: 900;
		height: 50px !important;
		line-height: 50px;
		width: 733px !important;
		background-color:#FFF;
		
		display: inline-block;
		vertical-align: text-top  !important;
		text-align: left;
		position: absolute;
		left: 3px;
		top:90px;
		filter: Alpha(Opacity=90);
		opacity: .90;
	}
	.ajax_location_menu{
		z-index: 990;
		height: 50px !important;
		width: 733px !important;
		line-height: 50px;
	}

	.event_options_complete_menu{
		z-index: 800;
		height: 100px !important;
		line-height: 100px;
		width: 105px !important;
		background-color:transparent;
		
		display: inline-block;
		vertical-align: text-top  !important;
		text-align: left;
		position: absolute;
		left: 610px;
		top:520px;
		filter: Alpha(Opacity=90);
		opacity: .90;
	}
	/*
height: 100px !important;
left: 3px;


	*/
	.ajax_location_complete_menu{
		z-index: 800;
		height: 100px !important;
		width: 105px !important;
		line-height: 90px;
		
		left: 607px;
		top:430px;
		position: absolute;
	}
/*
top:450px;
*/
	.ajax_location_complete_menu_1{
		z-index: 800;
		height: 100px !important;
		width: 105px !important;
	
		display: inline-block;
		background-color:transparent;
		border: hidden;
		border-spacing: 0;
		
		margin-left: 1px;
		
		
		position: absolute;
		
	}
/*
	.ajax_location_complete_menu_2{
		z-index: 800;
		height: 50px !important;
		width: 233px !important;
		line-height: 100px;
		display: inline-block;
		background-color:transparent;
		border: hidden;
		border-spacing: 0;
		
		margin-left: 400px;
		top:0px;
		
		position: absolute;
	}
*/
/*
	ajax_location_menu_multi_button
ajax_location_menu_1
margin-top: 2px;
		margin-bottom: 2px;
		margin-left: 1px;

left: 50px;
		top:330px;

*/
	.ls_form_menu_save_location_button_active {
		z-index: 999;
		height: 100px;
		width: 100px;
		line-height:100px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
	
		border:1px solid #CCC;
		
		
		
		position: absolute;
		
		
		background: url(../images/buttons/butt_template.png) #74E385;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*

		margin-top: 2px;
		margin-bottom: 2px;
		margin-left: 1px;
left: 160px;
		top:417px;
top: 150px;
	*/
/* -----------   MAP PATH MODIFY     ---------  */
	/*
	width: 80px !important;
	
	
	*/
	.event_options_modify_menu{
		z-index: 800;
		height: 60px !important;
		line-height: 80px;
		width: 120px !important;
		background-color:transparent;
		
		display: inline-block;
		vertical-align: text-top  !important;
		text-align: left;
		position: absolute;
		left: 20px;
		top:160px;
		filter: Alpha(Opacity=90);
		opacity: .90;
	}
	/*
height: 100px !important;
left: 3px;


	*/
	.ajax_location_modify_menu{
		z-index: 800;
		height: 60px !important;
		width: 60px !important;
		display: inline-block;
		background-color:transparent;
		line-height: 60px;
		left: 17px;
		top:70px;
		position: absolute;
	}

	.ajax_location_modify_menu_1{
		z-index: 810;
		height: 60px !important;
		width: 60px !important;
		background-color:transparent;
		display: inline-block;
	
		vertical-align: bottom;
		
		border: hidden;
		border-spacing: 0;
		
		margin-left: 0px;
	}
/*
	.ajax_location_modify_menu_2{
		z-index: 810;
		height: 60px !important;
		width: 55px !important;
		display: inline-block;
		background-color:transparent;
	
		vertical-align: bottom;
		
		border: hidden;
		border-spacing: 0;
		
		margin-left: 0px;
	}
*/
	.ajax_location_modify_menu_sub{
		z-index: 800;
		height: 60px !important;
		width: 60px !important;
		display: inline-block;
		background-color:transparent;
		line-height: 60px;
		left: 77px;
		top:70px;
		position: absolute;
	}

	.ajax_location_modify_menu_sub_1{
		z-index: 810;
		height: 60px !important;
		width: 60px !important;
		background-color:transparent;
		display: inline-block;
	
		vertical-align: bottom;
		
		border: hidden;
		border-spacing: 0;
		
		margin-left: 0px;
	}
/*
	.ajax_location_modify_menu_3{
		z-index: 810;
		height: 60px !important;
		width: 55px !important;
		display: inline-block;
		background-color:transparent;
	
		vertical-align: bottom;
		
		border: hidden;
		border-spacing: 0;
		
		margin-left: 0px;
	}
*/
	.ls_form_menu_modify_path_back {
		z-index: 999;
		height: 60px;
		width: 55px;
		line-height:40px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
	
		border:1px solid #CCC;
		position: absolute;
		background: url(../images/buttons/butt_template.png) #FFF;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ls_form_menu_modify_path_clear {
		z-index: 999;
		height: 60px;
		width: 55px;
		line-height:40px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
	
		border:3px solid #CCC;
		position: absolute;
		
		background: url(../images/buttons/sys_delete_buttonActive.png) #FFF;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ls_form_menu_modify_path_save {
		z-index: 999;
		height: 60px;
		width: 60px;
		line-height:40px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
	
		border:1px solid #CCC;
		position: absolute;
		background: url(../images/buttons/butt_template.png) #34CA3B;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ls_form_menu_modify_path_edit {
		z-index: 999;
		height: 80px;
		width: 80px;
		line-height:40px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
	
		border:1px solid #CCC;
		position: absolute;
		
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

/* -----------   LOCATION PATH MENU  --------- */
/*

width: 130px !important;


*/	
	.event_options_path_panel{
		z-index: 810;
		height: 50px !important;
		line-height: 50px;
		width: 50px !important;
		background-color:white;
		
		display: inline-block;
		vertical-align: text-top  !important;
		text-align: left;
		position: absolute;
		left: 120px;
		top:555px;
		filter: Alpha(Opacity=90);
		opacity: .90;
	}
	.ajax_location_path_panel{
		z-index: 810;
		background-color:transparent;
		height: 50px !important;
		width: 50px !important;
		line-height: 120px;
		position: absolute;
		left: 117px;
		top:465px;
	}
	.ajax_location_path_panel_1{
		z-index: 810;
		height: 55px !important;
		width: 50px !important;
		background-color:transparent;
	
		vertical-align: bottom;
		
		border: hidden;
		border-spacing: 0;
		
		margin-left: 0px;
	}
/*
	.ajax_location_path_panel_2{
		z-index: 810;
		height: 55px !important;
		width: 50px !important;
		background-color:transparent;
	
		display: inline-block;
		
		border: hidden;
		border-spacing: 0;
		
		margin-left: 0px;
	}
*//*
	.ajax_location_path_panel_3{
		z-index: 810;
		height: 50px !important;
		width: 50px !important;
		background-color:darkred;
	
		display: inline-block;
		
		border: hidden;
		border-spacing: 0;
		
		margin-left: 0px;
	}
*/
	/*
	background: url(../images/buttons/butt_template.png) #EFEFEF;
	*/

	.ls_form_menu_start_map_path_active {
		z-index: 999;
		height: 50px;
		width: 50px;
		line-height:40px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
	
		border:3px solid #CCC;
		
		background: url(../images/pins/default_start.png);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ls_form_menu_start_map_path_default {
		z-index: 999;
		height: 50px;
		width: 50px;
		line-height:40px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
	
		border:3px solid #CCC;
		position: absolute;
		
		background: url(../images/pins/default_default_flag.png);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.ls_form_menu_end_map_path_active {
		z-index: 999;
		height: 50px;
		width: 50px;
		line-height:40px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
	
		border:1px solid #CCC;
		
		position: absolute;
		
		background: url(../images/buttons/butt_template.png) #34CA3B;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ls_form_menu_end_map_path_default {
		z-index: 999;
		height: 50px;
		width: 50px;
		line-height:40px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
	
		border:1px solid #CCC;
		
		position: absolute;
		
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}


	.event_options_path_menu{
		z-index: 810;
		height: 90px !important;
		line-height: 75px;
		width: 90px !important;
		background-color:white;
		
		display: inline-block;
		vertical-align: text-top  !important;
		text-align: left;
		position: absolute;
		left: 10px;
		top:520px;
		filter: Alpha(Opacity=90);
		opacity: .90;
	}
	.ajax_location_path_menu{
		z-index: 810;
		
		height: 90px !important;
		width: 90px !important;
		line-height: 90px;
		position: absolute;
		left: 7px;
		top:430px;
	}/*
	.ajax_location_path_menu_1{
		z-index: 810;
		height: 115px !important;
		width: 90px !important;
		background-color: antiquewhite;
	
		display: inline-block;
		
		border: hidden;
		border-spacing: 0;
		
		margin-left: 0px;
	}
	.ajax_location_path_menu_2{
		z-index: 810;
		height: 115px !important;
		width: 90px !important;
		background-color:aquamarine;
	
		display: inline-block;
		
		border: hidden;
		border-spacing: 0;
		
		margin-left: 0px;
	}
	.ajax_location_path_menu_3{
		z-index: 810;
		height: 155px !important;
		width: 90px !important;
		background-color:darkred;
	
		display: inline-block;
		
		border: hidden;
		border-spacing: 0;
		
		margin-left: 0px;
	}
*/
	.ajax_location_path_menu_1{
		z-index: 810;
		height: 90px !important;
		width: 90px !important;
		background-color:transparent;
	
		display: inline-block;
		
		border: hidden;
		border-spacing: 0;
		
		margin-left: 0px;
	}

	

	.ls_form_menu_select_map_path_active {
		z-index: 999;
		height: 90px;
		width: 90px;
		line-height:40px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
	
		border:3px solid #CCC;
		
		position: absolute;
		
		background: url(../images/buttons/deafult_path_active.png);
		background-size: 85px 85px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ls_form_menu_select_map_path_default {
		z-index: 999;
		height: 90px;
		width: 90px;
		line-height:40px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
	
		border:3px solid #CCC;
		
		position: absolute;
		
		background: url(../images/buttons/deafult_path_inactive.png);
		background-size: 85px 85px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*
	.ls_form_menu_save_location_button_disabled {
		z-index: 990;
		height: 100px;
		width: 140px;
		line-height:100px;
		
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		margin-top: 2px;
		margin-bottom: 2px;
		margin-left: 1px;
		
		position: absolute;
		left: 150px;
		top:417px;
		
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
*/
/*
display: inline-block;
vertical-align:text-top;
vertical-align:text-top;
	line-height: 50px;

top: 1px;
		
*/
	.ajax_location_menu_1{
		z-index: 900;
		height: 50px !important;
		width: 524px !important;
	
		display: inline-block;
		background-color:transparent;
		border: hidden;
		border-spacing: 0;
		
		margin-left: 1px;
	}
	.ajax_location_menu_2{
		z-index: 999;
		height: 48px !important;
		width: 90px !important;
		line-height: 50px;
		display: inline-block;
		background-color:transparent;
		border: hidden;
		border-spacing: 0;
		position: absolute;
		margin-left: -92px;
		top: 1px;
	}
/*
top: 1px;
ls_search_full_field
*/
	.ajax_location_menu_search_submit_button {
		height: 34px;
		width: 86px;
		line-height:20px;
		font-size: 1em;
		font-weight: 400;
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
		background-color:#EFEFEF; 
		border:1px solid #33BC08;
		margin-top: 6px;
		margin-bottom: 6px;
		margin-left: 2px;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ajax_location_menu_search_full_field{
	  	height:38px !important;
		/*width: 345px;*/
		width: 100%;
		line-height:25px !important;
		background-color:#EEEEEE;
		border-spacing: 0px;
		border:hidden;
		font-size: .85em;
		font-weight: 500;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
		margin-top: 5px;
		margin-bottom: 5px;
	  }
/*
top: 1px;
top: 1px;
top: 1px;
*/
	.ajax_location_menu_3{
		height: 50px !important;
		width: 68px !important;
		line-height: 45px;
		display: inline-block;
		background-color:transparent;
		border: hidden;
		border-spacing: 0;
		
		position: absolute;
		margin-left: 1px;
	}
	.ajax_location_menu_4{
		height: 50px !important;
		width: 68px !important;
		line-height: 45px;
		display: inline-block;
		background-color:transparent;
		border: hidden;
		border-spacing: 0;
		
		position: absolute;
		margin-left: 71px;
	}
	.ajax_location_menu_5{
		height: 50px !important;
		width: 68px !important;
		line-height: 45px;
		display: inline-block;
		background-color:transparent;
		border: hidden;
		border-spacing: 0;
		
		position: absolute;
		margin-left: 141px;
	}
/*

*/
	
	.ajax_location_menu_physical_button_inactive {
		height: 40px;
		width: 66px;
		line-height:40px;
		font-size: 1em;
		font-weight: 400;
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		margin-top: 3px;
		margin-bottom: 7px;
		margin-left: 1px;
		
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ajax_location_menu_physical_button_active {
		height: 40px;
		width: 66px;
		line-height:40px;
		font-size: 1em;
		font-weight: 400;
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		margin-top: 3px;
		margin-bottom: 7px;
		margin-left: 1px;
		
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ajax_location_menu_virtual_button_inactive {
		height: 40px;
		width: 66px;
		line-height:40px;
		font-size: 1em;
		font-weight: 400;
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		margin-top: 3px;
		margin-bottom: 7px;
		margin-left: 1px;
		
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ajax_location_menu_virtual_button_active {
		height: 40px;
		width: 66px;
		line-height:40px;
		font-size: 1em;
		font-weight: 400;
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		margin-top: 3px;
		margin-bottom: 7px;
		margin-left: 1px;
		
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ajax_location_menu_multi_button_inactive {
		height: 40px;
		width: 66px;
		line-height:40px;
		font-size: 1em;
		font-weight: 400;
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
		 
		border:1px solid #CCC;
		
		margin-top: 3px;
		margin-bottom: 7px;
		margin-left: 1px;
		
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ajax_location_menu_multi_button_active {
		height: 40px;
		width: 66px;
		line-height:40px;
		font-size: 1em;
		font-weight: 400;
		vertical-align: middle;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		margin-top: 3px;
		margin-bottom: 7px;
		margin-left: 1px;
		
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}




/* ------------- ------------------------------*/
	/* #######	EVENT MANAGER  VIEW	######### top:4px;
	base_functional_listview_filter_menutd1
*/

	.type_view_event_options_absolute {
		height:127px !important;
		/*
		height:140px !important;
		width:234px;
		*/
		width:228px;
		border-spacing: 0px;
		border:1px solid #CCC;
		display: inline-block;
		margin-top:2px;
		margin-left:3px;
		vertical-align:middle;
		text-align:left;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.ls_form_event_option_frame {
		height:140px !important;
		width:234px;
		border-spacing: 0px;
		display:block;
		background: url(../images/buttons/clear_img.png);
		background-size: 120px 120px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		position:absolute;
	} 
	/*
		background: url(../images/buttons/clear_img.png);
		background-size: 120px 120px;
	*/
	.ls_form_event_title_frame {
		height:30px !important;
		width:190px;
		border:1px solid #CCC;
		border-spacing: 0px;
		display:block;
		margin-top:60px;
		margin-left:40px;
		font-size: .75em;
		font-weight: 500;
		color:#666;
		text-decoration:none;
		background-color:transparent;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		position:absolute;
	} 
/*
vertical-align:middle;
		text-align:center;
 EFEFEF
	.event_occurrence_once_active
*/
	.type_view_event_options_select {
		height:30px !important;
		width:30px;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:5px;
		margin-left:200px;
		
		position:absolute;
		font-size: .75em;
		font-weight: 400;
		color:#666;
		text-decoration:none;
		background: url(../images/buttons/sys_settings_button_large.png);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_view_event_occurrence_select {
		height:40px !important;
		width:40px;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:95px;
		margin-left:190px;
		
		position:absolute;
		font-size: .75em;
		font-weight: 400;
		color:#666;
		text-decoration:none;
		background: url(../images/buttons/butt_template.png);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	/*
	background: url(../images/buttons/sys_settings_button_large.png);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:center;
	*/
	.type_view_event_dateline_select {
		height:30px !important;
		width:150px;
		border-spacing: 0px;
		border:1px solid #CCC;
		background-color:transparent;
		margin-top:5px;
		margin-left:5px;
		
		position:absolute;
		font-size: .45em;
		font-weight: 400;
		color:#666;
		text-decoration:none;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.base_functional_event_listview_filter_menu {
		height:36px !important;
		width:720px;
		display:inline-block;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.base_functional_event_listview_table_td_r1 {
		height:437px !important;
		width:720px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
		
	} 
	/* background-color:#FFF; pagination */
	.base_functional_event_listview_table_td_r2 {
		height:56px !important;
		width:720px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
	} 
	
	.ls_default_event_results_main{
		height:437px !important; 
	  	width:718px !important;
		line-height:404px !important; 
		
		text-align:left;
		vertical-align:text-top !important; 
	}
	.ls_default_event_results_main_row{
	  	width:717px !important;
		display:block;
		text-align:left;
		vertical-align:text-top;
		
	}

	.event_view_control{
		height:100px !important;
		width: 320px !important;
		background-color:transparent;
		text-align:left !important;
		vertical-align:middle !important;
	}
	.base_functional_event_table_td_r4_1 {
		height:102px !important;
		width:320px !important;
		border:hidden;
		border-spacing: 0px;
		vertical-align:bottom;
	} 
	.base_functional_event_table_td_r4_2 {
		height:102px !important;
		width:300px !important;
		border:hidden;
		border-spacing: 0px;
	} 
	.base_functional_event_table_td_r4_3 {
		height:102px !important;
		width:100px !important;
		
		vertical-align:text-top ;
		
		border:hidden;
		border-spacing: 0px;
	} 
	.event_view_pending_default{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #CCC;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_view_pending_active{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_view_history_default{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #CCC;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_view_history_active{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_view_activity_default{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #CCC;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_view_activity_active{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_view_find_default{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #CCC;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_view_find_active{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	
	
	/* #######	DESC EVENT TYPE VIEW	######### */
/*
	event_set_date_button_default
	height:215px !important;
		width:420px;

*/
	
	
	
	
	
	
	/* #######	POP SEARCH		######### */
	/*
		pop_search_table_menu
		ls_pop_search_td_title
		ls_pop_search_td_1
		ls_pop_search_table_body
		ls_pop_search_full_field
		base_functional_listview_filter_menu
		base_functional_listview_table_td_r1
		ul_list_frame_absolute
		rec_results
		TYPE_DEFAULT
		sortables connectedSortable dropfalse
	
	*/
	.pop_functional_listview_filter_menu {
		height:36px !important;
		width:950px;
		display:inline-block;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	/*
	top:645px;
	
	*/
	.pop_MGMT_query_metrics {
		height:12px !important;
		width:200px;
		display:inline-block;
		vertical-align: top;
		text-align:right;
		line-height:12px;
		display:inline-block;
		position:absolute;
		left:745px;
		top:642px;
		font-size: 10;
		font-weight: 400;
		color:#666;
		text-decoration:none;
		background-color:transparent;
		border:hidden;
	}
	
	.pop_functional_listview_filter_menutd1 {
		height:36px !important;
		width:510px;
		display:inline-block;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.pop_functional_listview_filter_menutd2 {
		height:36px !important;
		width:100px !important;
		display:inline-block;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.pop_functional_listview_table_td_r1 {
		height:480px !important;
		width:950px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
		text-align:left;
		
	} 
/*
pop_full_profile_table_empty
form_date_ajax_view
*/
	
	
	.pop_full_profile_table_empty {
		height:404px !important;
		width:950px !important;
		line-height:404px;
		background:#999;
		border: hidden;
		text-align:center;
		vertical-align:middle;
		border-spacing: 0px;
		background: url(../images/buttons/butt_template.png);
		background-size: 180px 100px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	} 
	.pop_functional_listview_table_td_medium {
		height:558px !important;
		width:735px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
		text-align:left;
		
	} 
	.pop_full_profile_table_empty_medium {
		height:558px !important;
		width:735px !important;
		line-height:558px;
		border: hidden;
		text-align:center;
		vertical-align:middle;
		border-spacing: 0px;
		background: url(../images/buttons/butt_template.png) #93CD97;
		background-size: 180px 100px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	} 

	
	
	
	
	
	
	
	
	.pop_search_table_menu {
		height:50px !important;
		width:950px;
		vertical-align:middle;
		border:1px solid #CCC;
	} 
	.ls_pop_search_td_title {
		height: 50px;
		width: 232px;
		line-height:40px;
		text-align:center;
		text-decoration:none;
		background-color:#FFF;
		border-bottom:hidden;
		border-left:hidden;
		border-right:1px solid #CCC;
		border-top:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.ls_pop_search_td_1 {
		height: 50px;
		width: 460px;
		line-height:40px;
		text-align:center;
		text-decoration:none;
		background-color:#FFF; 
		border-bottom:hidden;
		border-left:hidden;
		border-right:1px solid #CCC;
		border-top:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ls_pop_search_td_2 {
		height: 50px;
		width: 252px;
		line-height:50px;
		
		text-align:left;
		vertical-align: text-top;
		text-decoration:none;
		
		background-color:rgba(255,255,255, 0.7); 
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		border-top:hidden;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ls_pop_search_table_body {
		height: 575px;
		width: 950px;
		text-align:center;
		text-decoration:none;
		background-color:#FFF;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.ls_pop_search_full_field{
	  	height:35px !important;
		/*width: 345px;*/
		width: 100%;
		line-height:35px !important;
		background-color:#EEEEEE;
		border-spacing: 0px;
		border:hidden;
		font-size: .85em;
		font-weight: 500;
		text-align:left;
		text-decoration:none;
		text-indent:5px;

	  }
	


/* #######	DATE CONTROL MENU		######### */
	.date_listview_control_menu {
		height:50px !important;
		width:945px;
		line-height: 50px;
		display:inline-block;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.date_listview_control_menu_td1 {
		height:50px !important;
		width:320px;
		line-height: 50px;
		display:inline-block;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.date_listview_control_menu_td2 {
		height:50px !important;
		width:320px;
		line-height: 50px;
		display:inline-block;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.date_listview_control_menu_td3 {
		height:50px !important;
		width:75px;
		line-height: 50px;
		display:inline-block;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.date_listview_control_menu_td4 {
		height:50px !important;
		width:130px;
		line-height: 50px;
		display:inline-block;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}

	/*
		width: 125px;
		event_personal_button
		
	*/
	
	
	.base_functional_listview_control_menu {
		height:50px !important;
		width:720px;
		display:inline-block;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.base_functional_listview_control_menutd0 {
		height:50px !important;
		width:237px;
		display:inline-block;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.base_functional_listview_control_menutd1 {
		height:50px !important;
		width:120px;
		display:inline-block;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.base_functional_listview_control_menutd2 {
		height:50px !important;
		width:120px !important;
		display:inline-block;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.base_functional_listview_control_menutd3 {
		height:50px !important;
		width:120px !important;
		display:inline-block;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.base_functional_listview_control_menutd4 {
		height:50px !important;
		width:120px !important;
		display:inline-block;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	/************************/
	/*	EVENT DESCRIPTION MGMT [DESCRIPTION TYPES] 	
		event_option_main_table_td_dates
		event_option_main_table_td_dates_output
	*/
	.TYPE_DEFAULT {
		height:147px;
		width: 420px;
		line-height:140px;
		font-size: 1em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		color:#300;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #39F;
		background-position:center;
		background-size: 420px 130px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.TYPE11 {
		height: 94px;
		width: 471px;
		line-height:60px;
		background: url(../images/buttons/butt_template.png) #39F;
		background-position:center;
		background-size: 471px 94px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.TYPE21 {
		height: 94px;
		width: 471px;
		line-height:60px;
		background: url(../images/buttons/butt_template.png) #FC0;
		background-position:center;
		background-size: 471px 94px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.TYPE31 {
		height: 94px;
		width: 471px;
		line-height:60px;
		background: url(../images/buttons/butt_template.png) #C30;
		background-position:center;
		background-size: 471px 94px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	
	.terms_submit_button_default {
		height: 40px;
		width: 125px;
		line-height:33px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color:#EFEFEF; 
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.terms_submit_button_error {
		height: 40px;
		width: 125px;
		line-height:33px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color:#EFEFEF; 
		border:1px solid #F00;
		border-spacing:0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.terms_submit_button_active {
		height: 40px;
		width: 125px;
		line-height:33px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color:#1FDD00;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
/* ################################################ */
/* ######                     ##### */


 	
 
 
 	.ls_default_results_main{
		height:404px !important; 
	  	width:715px !important;
		line-height:404px !important; 
		
		text-align:left;
		vertical-align:text-top !important; 
	}
	/*  
	*/
.ls_default_results_main_row{
    width:713px !important;
    display:block;
    text-align:left;
    vertical-align:text-top;
    top: 0px;

}
/*
ls_default_results_main
ls_default_results_main_row
ls_form_med_option_frame
type_view_event_options_absolute
*/
.ls_default_profile_listview_frame {
    height:130px !important;
    width:138px !important;
    border-spacing: 0px;
    display: inline-block;
    margin-top:2px;
    margin-left:3px;
    vertical-align:top;
    text-align:left;
	top: 0px;
    background-repeat: no-repeat;
    background-position: center;
    border:thin solid rgba(233,233,233, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_default_profile_listview_button {
    height:130px !important;
    width:138px;
    border-spacing: 0px;
	position: absolute;
    margin-top:0px;
    margin-left:0px;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    background-size: 110px 80px;
    background-repeat: no-repeat;
    background-position:center;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*
type_view_event_options_absolute

*/
.ls_default_profile_select_listview_button {
    height:140px !important;
    width:138px;
    border-spacing: 0px;
	position: absolute;
    margin-top:0px;
    margin-left:0px;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    background-size: 130px 130px;
    background-repeat: no-repeat;
    background-position:center;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_profile_select_title_button {
	z-index: 10;
    height:40px !important;
    width:220px !important;
    border-spacing: 0px;
	position: absolute;
    margin-top:50px;
    margin-left:5px;
    background: url(../images/buttons/butt_template.png) rgba(4,255,255, 0.3);
    background-size: 215px 33px;
    background-repeat: no-repeat;
    background-position:center;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	color:rgba(15, 5, 26, 0.6);
	font-size: 0.75em;
	font-weight: 500;
	text-align:left;
	text-decoration:none;
}
.ls_profile_select_stauts_button {
    height:70px !important;
    width:78px;
    border-spacing: 0px;
	position: absolute;
    margin-top:2px;
    margin-left:148px;
    background: url(../images/buttons/butt_template.png) rgba(255,255,2, 0.3);
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position:center;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.type_view_event_profile_select_options_absolute {
    height:140px !important;
    width:228px;
    border-spacing: 0px;
    border:1px solid #CCC;
    display: inline-block;
    margin-top:2px;
    margin-left:3px;
    vertical-align:middle;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
	.ls_default_results_button {
		width:139px !important;
		max-width: 141px !important;
		text-align:center !important;
		position:relative;
   		background-repeat: no-repeat;
    	background-position: center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ls_default_results_button_div {
		height:30px !important;
		width:137px !important;
		max-width: 141px !important;
		position:absolute;
		top:90px;
		left:3;
		text-align:center !important;
		background-color: rgba(255,255,255,0.3);
   		background-repeat: no-repeat;
    	background-position: center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
 
 /* SEARCH FIELDS  */

 .search_table_menu {
		height:40px !important;
		width:720px;
		vertical-align:middle;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	} 
 .ls_search_td_title {
		height: 40px;
		width: 182px;
		line-height:40px;
		text-align:center;
		text-decoration:none;
		background-color:#FFF;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:1px solid #CCC;
		border-top:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*
	ls_search_full_field
	ls_search_submit_button
search_table_menu
*/
 .ls_search_td_1 {
		height: 37px !important;
		width: 490px;
		line-height:36px;
		text-align:center;
		text-decoration:none;
		background-color:rgba(255,255,255, 0.7); 
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:1px solid #CCC;
		border-top:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
.ls_search_td_2 {
    height: 37px !important;
    width: 128px;
    line-height:36px;
    text-align:left;
    vertical-align: text-top;
    text-decoration:none;
    background-color:rgba(255,255,255, 0.7); 
    border-bottom:1px solid #CCC;
    border-left:hidden;
    border-right:hidden;
    border-top:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_search_table_body {
    height: 575px;
    width: 720px;
    text-align:center;
    text-decoration:none;
    background-color:#FFF; 
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_search_table_body_td1 {
    height: 285px !important;
    width: 720px !important; 
    line-height:575px;
    text-align:center;
    text-decoration:none;
    background-color:#FFF; 
    border-bottom:1px solid #CCC;
    border-left:hidden;
    border-right:hidden;
    border-top:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_search_table_body_td2 {
    height: 287px !important;
    width: 720px !important;
    line-height:575px;
    text-align:center;
    text-decoration:none;
    background-color:#FC0;
    border-bottom:hidden;
    border-left:hidden;
    border-right:hidden;
    border-top:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_search_submit_button {
    height: 32px;
    width: 125px;
    line-height:28px;
    position: absolute;
    margin-top: 2px;
    margin-left: 2px;
    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    background-color:#EFEFEF; 
    border:1px solid #CCC;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ls_mini_search_full_field{
    height:28px !important;
    /*width: 345px;*/
    width: 99%;
    line-height:25px !important;
    border-radius: 25px;
    border-spacing: 0px;
    border:hidden;
    background-color:rgba(239,239,239, 0.7);
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:5px;

  }
.ls_mini_search_submit_button {
    height: 26px;
    width: 50px;
    line-height:12px;
    position: absolute;
    margin-top: 2px;
    margin-left: 235px;
    border-radius: 25px;
    border:1px solid #CCC;
    background-color:rgba(4, 144, 242, 0.7); 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .75em;
    font-weight: 500;
    vertical-align:middle;
    text-align:center;
    text-decoration:none;
}
/*

position: absolute;
rgba(255,255,255, 0.7) = #FFF (white)
rgba(15, 5, 26, 0.7) = #FFF (black)
rgb(239,239,239, 0.7) = #EFEFEF (grey)
rgb(204,204,204, 0.7) = #CCC (dark border)
ls_pop_search_td_2
*/
.ls_ajax_search_submit_button {
    height: 32px;
    width: 125px;
    line-height:28px;

    position: absolute;

    margin-top: 10px;
    margin-left: 2px;

    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    background-color:rgb(239,239,239, 0.7); 
    border:1px solid #CCC;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
    clear
*/
.ls_clear_submit_button {
    height: 34px;
    width: 34px;
    line-height:33px;

    position: absolute;

    margin-top:-34px;
    margin-left: 195px;

    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    background-color:#EFEFEF; 
    border:1px solid #CCC;
    background-image:url(../images/buttons/sys_close_buttonDefault.png);
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

/*
width: 400px;
admin_frame_td_label
*/
.ls_search_full_field{
    height:28px !important;
    /*width: 345px;*/
    width: 99%;
    line-height:25px !important;
    background-color:#EEEEEE;
    border-spacing: 0px;
    border:hidden;
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:5px;

  }
.ls_right_panel_title {
    height: 40px !important;
    width: 227px !important;
    line-height:227px;
    text-align:center;
    text-decoration:none;
    background-color:#FC0;
    border-bottom:hidden;
    border-left:hidden;
    border-right:hidden;
    border-top:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
 /* SORTABLE FIELDS  */
 .ul_list_frame_absolute {
		height:1px !important;
		width:1px;
		border-spacing: 0px;
		border:hidden;
		background: url(../images/buttons/clear_img.png);
		background-size: 100px 100px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
 	ul {
		list-style: none;
		margin: 0px 0;
		padding: 0;
		height:520px;
		
	}

	ul li {
		 margin: 3px 2px 2px 3; padding: 0px; float: left; width: 106px; height: 108px;  vertical-align:middle; cursor: move;
			/*text-align: center;
			 margin: 3px 3px 3px 0; padding: 1px; float: left; width: 100px; height: 90px; font-size: 4em; text-align: center; display:inline-block;
			border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
			
			line-height: 1.6em;
float: left;
			cursor: move;

			margin: 5px 0
			
			base_functional_right_panelfull_table_td
			*/

	}

	ul li span {
		display: inline-block;
		margin: 0 5px;
	}



	#sortable-a li a {
		color: #666;

	}

	#sortable-b li a {	
		color: #CCC;
			
			/*
			background-color:transparent;
			z-index:110;
			
			
			*/

	}
	#sortable-a {
		width: 720px !important; 	
	}
	/*
	background: #CCC;
	*/
	#sortable-a li {
		height: 128px !important;
 		width: 140px !important; 
		background-color:#FFF;
		margin: 1px; padding: 0px;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
		border-spacing: 0px;
		background-size: 130px 130px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	top:90px;
		left:3;
	
	*/
	.recommend_label_button_div {
		height:30px !important;
		width:140px !important;
		line-height:30px;
		max-width: 141px !important;
		position:absolute;
		margin-top:89px;
		text-align:center !important;
		vertical-align:middle;
		background-color: rgba(255,255,255,0.3);
   		background-repeat: no-repeat;
    	background-position: center;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:hidden;
		border-top:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	#sortable-b {
		width: 231px !important;
		text-align:left !important;	
	}
	/*
		text-align:center;
	*/
	#sortable-b li {
		height: 108px !important;	
		width: 106px !important;
		line-height:108px;
		vertical-align:middle;
	
		background-color:#FFF;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
		border-spacing: 0px;
		background-size: 108px 108px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	/*
	float: right;
		clear: right;text-align:center;
		float:left !important;
		opacity
		marketplace_pin_button_div
		
	*/
	.sortable_div_frame {
		height:40px !important;
		width: 40px !important;
		line-height:40px !important;
		margin-top:30px !important;
		margin-left:23px !important;
		position:absolute;
		display: inline-block;
		text-align:center;
		vertical-align:middle;
		background-color:#FFF;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC; 	
		background-image:url(../images/buttons/butt_template.png);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=75);
		opacity: .75;
	}
	.sortable_div_text {
		display: block;
		font-size: 1.30em;
		color:#0F0;
		font-weight: bold;
		text-decoration: none;
		filter: Alpha(Opacity=99);
		opacity: .99;
	}
	/*
	margin: 1 5px
	remove
	
	ul li a {
		float: right;
		clear: right;
		
		text-decoration: none;
		display: inline-block;
		close
	}
	*/
	.remove{
		height:30px;
		width:30px;
		line-height:20px;
		margin-top:1px !important;
		margin-left:64px !important;
		float: right;
		clear: right;
		position:absolute;
		text-align:center;
		display: inline-block;
		background-image:url(../images/buttons/sys_close_buttonDefault.png);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

 
/* -----------------------------------------------------------------------------------------/ */

.pagination_count {
    height: 36px !important;
    width: 145px !important;
    margin: 0;
    padding:0;
    line-height:36px;
    display:inline-block;
    text-align:center;
    font-size: .75em;
    color:#000;
    font-weight:600;
    text-decoration: none;
    vertical-align:middle;
    background-color:#FFF; 
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.default_pagination_count {
    height: 36px !important;
    width: 145px !important;
	line-height: 36px;
    margin: 0;
    padding:0;
    line-height:36px;
    display:inline-block;
    text-align:center;
	vertical-align:top;
    background-color: rgba(255,255,255, 0.7);
    border:1px solid rgba(204,204,204, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.default_pagination_field{
	height:36px !important;
	/*width: 195px;*/
	width: 98%;
	line-height:36px !important;
	background:rgba(238,238,238, 0.5);
	border-spacing: 0px;
	border:hidden;
	text-align:center;
	vertical-align:top;
  	text-decoration:none;
 
}
.default_pagination_listview_01 {
    height:36px !important;
    width:565px;
    display:inline-block;
    /*
    pagination_count
    ls_default_results_button
    */
    background-color: transparent;
    vertical-align:top;
	text-align:center;
    border: 1px solid rgba(239,239,239, 0.5);
}
.default_pagination_listview_02 {
    height:36px !important;
    width:803px;
    display:inline-block;
    /*
    pagination_count
    ls_default_results_button
    */
    background-color: transparent;
    vertical-align:top;
	text-align:center;
    border: 1px solid rgba(239,239,239, 0.5);
}
.default_pagination_listview_03 {
    height:36px !important;
    width:563px;
    display:inline-block;
    /*
    pagination_count
    ls_default_results_button
    */
    background-color: rgba(255,255,255, 0.5);
    vertical-align:top;
	text-align:center;
    border: 1px solid rgba(239,239,239, 0.5);
}
.default_pagination_listview_04 {
    height:36px !important;
    width:650px;
    display:inline-block;
    /*
    pagination_count
    ls_default_results_button
    */
    background-color: rgba(255,255,255, 0.5);
    vertical-align:top;
	text-align:center;
    border: 1px solid rgba(239,239,239, 0.5);
}
.default_pagination_listview_05 {
    height:36px !important;
    width:799px;
    display:inline-block;
    /*
    pagination_count
    ls_default_results_button
    */
    background-color: rgba(255,255,255, 0.5);
    vertical-align:top;
	text-align:center;
    border: 1px solid rgba(239,239,239, 0.5);
}
.base_functional_listview_pagination_tr {
    /*
    base_functional_listview_filter_menutd1
    */
    height:36px !important;
    width:610px;
    display:inline-block;
    position: absolute;
    margin-top: -40px;
    margin-left: 170px;

    background-color:aquamarine;

    vertical-align: top;
    text-align: center;
    border-right:1px solid #CCC;
    border-left:1px solid #CCC;
    border-top:1px solid #CCC;
    border-bottom:1px solid #CCC;
}
	.pagination {
		height: 56px !important;
		width: 650px !important;
		line-height:56px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		background-color:#FFF; 
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_td1{
		height:56px !important; 
	  	width:650px !important;
		line-height:56px;
		background-color:#FFF;
		vertical-align:middle;
	}
	.pagination_td2{
		height:56px !important; 
	  	width:64px !important;
		line-height:56px;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
	}
	/*
	width70
	*/
	.pagination_main_previous {
		height: 56px !important;
		width: 65px !important;
		line-height:56px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_left_arrow.png);
		background-position:50% 45%; 
		background-size: 40px 50px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_main_previous_disabled {
		height: 56px !important;
		width: 70px !important;
		line-height:56px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_left_arrow_disabled.png);
		background-position:50% 45%; 
		background-size: 40px 50px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	width70
	*/
	.pagination_main_next {
		height: 56px !important;
		width: 65px !important;
		line-height:56px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_right_arrow.png);
		background-position:50% 45%; 
		background-size: 40px 50px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_main_next_disabled {
		height: 56px !important;
		width: 70px !important;
		line-height:56px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_right_arrow_disabled.png);
		background-position:50% 45%; 
		background-size: 40px 50px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_main_option {
		height: 40px !important;
		width: 40px !important;
		line-height:40px;
		display:inline-block;
	  	font-size: .85em;
		font-weight: 500;
		color:#000;
		text-decoration:none;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background: url(../images/buttons/sys_menu_pagination_opt.png);
		background-position:50% 45%; 
		background-size: 35px 30px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_main_option_active {
		height: 40px !important;
		width: 40px !important;
		line-height:40px;
		display:inline-block;
	  	font-size: .85em;
		font-weight: 500;
		color:#000;
		text-decoration:none;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background: url(../images/buttons/sys_menu_pagination_opt_active.png);
		background-position:50% 45%; 
		background-size: 35px 30px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	width: 510px !important;
	background: url(../images/buttons/butt_template.png);
		background-size: 90px 32px;
   		background-repeat: no-repeat;
		background-position:center;
	
		
		base_functional_listview_filter_menu
	*/
	.pagination_listview {
		height: 36px !important;
		width: 360px !important;
		line-height:36px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		background-color:#FFF; 
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_listview_return_option {
		height:36px !important;
		width:100px !important;
		display:inline-block;
		border-right:1px solid #CCC;
		border-left:1px solid #CCC;
		border-top:1px solid #CCC;
		border-bottom:1px solid #CCC;
		border-spacing: 0px;
		
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.pagination_listview_td1{
		height:36px !important; 
	  	width:650px !important;
		line-height:36px;
		background-color:#FFF;
		vertical-align:middle;
	}
	.pagination_listview_td2{
		height:36px !important; 
	  	width:64px !important;
		line-height:36px;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
	}
	/*
	width70
	*/
	.pagination_listview_main_previous {
		height: 36px !important;
		width: 65px !important;
		line-height:36px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_left_arrow.png);
		background-position:50% 45%; 
		background-size: 40px 30px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_listview_main_previous_disabled {
		height: 36px !important;
		width: 65px !important;
		line-height:36px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_left_arrow_disabled.png);
		background-position:50% 45%; 
		background-size: 40px 30px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_listview_main_next {
		height: 36px !important;
		width: 65px !important;
		line-height:36px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_right_arrow.png);
		background-position:50% 45%; 
		background-size: 40px 30px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_listview_main_next_disabled {
		height: 36px !important;
		width: 65px !important;
		line-height:36px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_right_arrow_disabled.png);
		background-position:50% 45%; 
		background-size: 40px 30px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_listview_main_option {
		height: 36px !important;
		width: 40px !important;
		line-height:36px;
		display:inline-block;
	  	font-size: .85em;
		font-weight: 500;
		color:#000;
		text-decoration:none;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background: url(../images/buttons/sys_menu_pagination_opt.png);
		background-position:50% 45%; 
		background-size: 35px 30px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_listview_main_option_active {
		height: 36px !important;
		width: 40px !important;
		line-height:36px;
		display:inline-block;
	  	font-size: .85em;
		font-weight: 500;
		color:#000;
		text-decoration:none;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background: url(../images/buttons/sys_menu_pagination_opt_active.png);
		background-position:50% 45%; 
		background-size: 35px 30px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/********************************/
	/********************************/
/*
	
*/
	.pagination_listview_item {
		height: 36px !important;
		width: 180px !important;
		line-height:36px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		position: absolute;
		margin-top: 10px;
		
		background-color:transparent;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_item_count {
		height: 60px !important;
		width: 95px !important;
		line-height:60px;
		margin: 0;
	 	padding:0;
		
		display:inline-block;
		
		position: absolute;
		margin-left: 180px;
		
		text-align:center;
		font-size: .75em;
		color:#000;
		font-weight:600;
		text-decoration: none;
		vertical-align:middle;
		background-color:#FFF; 
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_item_option {
		height: 40px !important;
		width: 40px !important;
		line-height:40px;
		display:inline-block;
	  	font-size: .85em;
		font-weight: 500;
		color:#000;
		text-decoration:none;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background: url(../images/buttons/sys_menu_pagination_opt.png);
		background-position:50% 45%; 
		background-size: 35px 30px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_item_option_active {
		height: 40px !important;
		width: 40px !important;
		line-height:40px;
		display:inline-block;
	  	font-size: .85em;
		font-weight: 500;
		color:#000;
		text-decoration:none;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background: url(../images/buttons/sys_menu_pagination_opt_active.png);
		background-position:50% 45%; 
		background-size: 35px 30px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	

	.pagination_listview_item_previous {
		height: 26px !important;
		width: 35px !important;
		line-height:26px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_left_arrow.png);
		background-position:50% 45%; 
		background-size: 30px 20px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_listview_item_previous_disabled {
		height: 26px !important;
		width: 35px !important;
		line-height:26px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_left_arrow_disabled.png);
		background-position:50% 45%; 
		background-size: 30px 20px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_listview_item_next {
		height: 26px !important;
		width: 35px !important;
		line-height:36px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_right_arrow.png);
		background-position:50% 45%; 
		background-size: 30px 20px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_listview_item_next_disabled {
		height: 26px !important;
		width: 35px !important;
		line-height:26px;
		display:inline-block;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-image:url(../images/buttons/sys_menu_right_arrow_disabled.png);
		background-position:50% 45%; 
		background-size: 30px 20px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_listview_item_option {
		height: 26px !important;
		width: 30px !important;
		line-height:26px;
		display:inline-block;
	  	font-size: .85em;
		font-weight: 500;
		color:#000;
		text-decoration:none;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background: url(../images/buttons/sys_menu_pagination_opt.png);
		background-position:50% 45%; 
		background-size: 35px 20px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_listview_item_option_active {
		height: 26px !important;
		width: 30px !important;
		line-height:26px;
		display:inline-block;
	  	font-size: .85em;
		font-weight: 500;
		color:#000;
		text-decoration:none;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background: url(../images/buttons/sys_menu_pagination_opt_active.png);
		background-position:50% 45%; 
		background-size: 35px 20px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}




	/********************************/
	/********************************/
	
	
	.pagination_shiftview {
		height: 36px !important;
		width: 360px !important;
		line-height:36px;
		display:inline-block;
		position: absolute;
		text-align:center;
		vertical-align:middle;
		background-color:#FFF; 
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_shiftview_left_option {
		height:36px !important;
		width:300px !important;
		display:inline-block;
		position:absolute;
		border:hidden;
		border-spacing: 0px;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.pagination_shiftview_center_option {
		height:36px !important;
		width:480px !important;
		margin-left:301px;
		display:inline-block;
		position:absolute;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}

	.pagination_shiftview_right_option {
		height:36px !important;
		width:140px !important;
		margin-left:782px;
		display:inline-block;
		position:absolute;
		border:hidden;
		border-spacing: 0px;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.pagination_shift_count {
		height: 36px !important;
		width: 130px !important;
		line-height:36px;
		display:inline-block;
		position:absolute;
		vertical-align:middle;
		text-align:center;
		font-size: .95em !important;
		color:#000 !important;
		font-weight:600 !important;
		text-decoration: none;
		background-color:#FFF; 
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	margin-top:75px;
		margin-left:10px;
	
		height: 36px !important;
		width: 65px !important;
	background-image:
	background-image:
	position:static;position:absolute;
	*/
	.pagination_shiftview_main_previous {
		height: 36px !important;
		width: 65px !important;
		line-height:36px;
		
		display:inline-block;
		
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background: url(../images/buttons/sys_menu_left_arrow.png);
		background-position:50% 45%; 
		background-size: 60px 35px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_shiftview_main_previous_disabled {
		height: 36px !important;
		width: 65px !important;
	
		line-height:36px;
		display:inline-block;
		
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background: url(../images/buttons/sys_menu_left_arrow_disabled.png);
		background-position:50% 45%; 
		background-size: 60px 35px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	
	
	*/
	.pagination_shiftview_main_next {
		height: 36px !important;
		width: 65px !important;
		
		display:inline-block;
		
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background:url(../images/buttons/sys_menu_right_arrow.png) ;
		background-position:50% 45%; 
		background-size: 60px 35px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.pagination_shiftview_main_next_disabled {
		height: 36px !important;
		width: 65px !important;
		
		display:inline-block;
		
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background:url(../images/buttons/sys_menu_right_arrow_disabled.png) ;
		background-position:50% 45%; 
		background-size: 60px 35px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
/* #################################################################################### */
/* #################################################################################### */
.window_dashboard_frame {
    z-index: 99999;
    background: rgba(255,255,255, 0.0);
    border:hidden;
    border-style: none !important;
    height:100% !important;
    width:100% !important;
    position: fixed;
    display:none;
}
.dashboard_window_table{
    height:675px; 
    width:952px;
    border:thin #000;
}
.dashboard_window_table_td_header{
    height:30px; 
    width:952px;
    position:relative;
    text-align:right;
    background-color:rgba(47,255,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.dashboard_window_table_main{
    height:620px; 
    width:951px;
    background-color:rgba(255,255,255, 0.7);
    vertical-align:text-top;
    font-size:12px; 
    font-weight:700;
    text-decoration:none;
}
.dashboard_window_table_td_footer{
    height:25px; 
    width:952px;
    vertical-align:text-top;
    background-color:rgba(255,255,47, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.dashboard_window_close{
    right:0;
    position:absolute;
    z-index: 10;
}
.dashboard_table{
	display:none;
    height:620px; 
    width:951px;
    background-color:rgba(255,255,255, 0.0);
    vertical-align:text-top;
    text-decoration:none;
}
.dashboard_table_main{
    height:620px !important;
    width:951px !important;
	display:inline-block;
    background-color:rgba(255,255,255, 0.4) !important;
    vertical-align:text-top !important;
    text-decoration:none !important;
}
.dashboard_menu {
    height:43px;
    width:90px;
    line-height:23px;
    margin-top: 5px;
    margin-left: 2px;

    padding:0px;
	text-align:center!important;
	vertical-align: top;
    font-size: .85em;
    font-weight: 600;
    
    text-decoration:none;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);

    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_active {
    height:43px;
    width:90px;
    line-height:23px;
    margin-top: 5px;
    margin-left: 1px;

    padding:0px;
	text-align:center!important;
	vertical-align: top;
    font-size: .85em;
    font-weight: 600;
    
    text-decoration:none;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_textarea {
    height: 43px;
    width: 90px ;
    line-height:43px;
    /**/
    top:3px;
    margin-top: 3px;
    margin-left:5px;
    position:absolute;

    font-size:14px;
    font-weight:600;
    text-align:center;
    background-color: transparent;
}
/* ################################# */
.dashboard_menu_header {
    height:65px;
    width:950px;
    padding:0px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
	background-color: rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_middle {
    height:401px;
    width:163px;
    padding:0px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.7);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_option_1 {
    height: 100px;
    width: 160px ;
    top:0px;
    margin-top: 1px;
    margin-left:2px;
    text-align:center;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_option_1_active {
    height: 100px;
    width: 160px ;
    top:0px;
    margin-top: 1px;
    margin-left:2px;
    text-align:center;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.4);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_option_2 {
    height: 100px;
    width: 160px ;
    top:0px;
    margin-top: 1px;
    margin-left:2px;
    text-align:center;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_option_2_active {
    height: 100px;
    width: 160px ;
    top:0px;
    margin-top: 1px;
    margin-left:2px;
    text-align:center;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.4);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_option_3 {
    height: 100px;
    width: 160px ;
    top:0px;
    margin-top: 1px;
    margin-left:2px;
    text-align:center;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_option_3_active {
    height: 100px;
    width: 160px ;
    top:0px;
    margin-top: 1px;
    margin-left:2px;
    text-align:center;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.4);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_option_4 {
    height: 100px;
    width: 160px ;
    top:0px;
    margin-top: 1px;
    margin-left:2px;
    text-align:center;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_option_4_active {
    height: 100px;
    width: 160px ;
    top:0px;
    margin-top: 1px;
    margin-left:2px;
    text-align:center;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.4);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_left {
    height:401px;
    width:163px;
    padding:0px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_right {
    height:401px;
    width:785px;
    padding:0px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
	background: url(../images/logo/MADEWITHTHEAPPLICATIONOFLOVE/MADEWITH-LOGO-01-BLUE.png) rgba(255,255,255, 0.0);
    background-size: 380px 380px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_middle_top {
    height:200px;
    width:780px;
    padding:0px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
	background-color: rgba(239,239,239, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_middle_bottom {
    height:200px;
    width:780px;
    padding:0px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
	background-color: rgba(239,239,239, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_bottom1 {
    height:146px;
    width:801px;
    padding:0px;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.1);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_bottom1_td {
    height:72px;
    width:133px;
    padding:0px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_menu_bottom2 {
    height:146px;
    width:148px;
    padding:0px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.dashboard_event_menu_option_10 {
	z-index: 100;
    height: 50px;
    width: 100px ;
	position: absolute;
    top:0px;
    margin-top: 1px;
    margin-left:300px;
    text-align:center;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(45,239,239, 0.7);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/****************************************/
.dashboard_menu_option_5 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_5_active {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_6 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_6_active {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_7 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_7_active {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_8 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_8_active {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_9 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_9_active {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_10 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_10_active {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_11 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_11_active {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_12 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_12_active {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_13 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_13_active {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_14 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_14_active {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_15 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_15_active {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_16 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_16_active {
    height:63px;
    width:124px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_17 {
    height:136px;
    width:145px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_17_active {
    height:136px;
    width:142px;
    top:0px;
    margin-top: 2px;
    margin-left:3px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_18 {
    height:63px;
    width:128px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
.dashboard_menu_option_18_active {
    height:63px;
    width:124px;
    top:0px;
    margin-top: 2px;
    margin-left:1px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 0.90em;
	font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}
/****************************************/
/****************************************/
.window_photosystem_frame {
    z-index: 99999;
    background: rgba(255,255,255, 0.0);
    border:hidden;
    border-style: none !important;
    height:100% !important;
    width:100% !important;
    position: fixed;
    display:none;
}

.photosystem_window_table{
    height:550px; 
    width:952px;
    border:thin #000;
}
.photosystem_window_table_td_header{
    height:30px; 
    width:952px;
    position:relative;
    text-align:right;
    background-color:rgba(47,255,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.photosystem_window_table_main{
    height:550px; 
    width:951px;
    background-color:rgba(255,255,255, 0.7);
    vertical-align:text-top;
    font-size:12px; 
    font-weight:700;
    text-decoration:none;
}
.photosystem_window_table_td_footer{
    height:25px; 
    width:952px;
    vertical-align:text-top;
    background-color:rgba(255,255,47, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.photosystem_window_close{
    right:0;
    position:absolute;
    z-index: 10;
}
.photosystem_table{
	display:none;
    height:550px; 
    width:951px;
    background-color:rgba(255,255,255, 0.0);
    vertical-align:text-top;
    text-decoration:none;
}
.photosystem_table_main{
    height:550px !important;
    width:951px !important;
	display:inline-block;
    background-color:rgba(239,239,239, 0.4) !important;
    vertical-align:text-top !important;
    text-decoration:none !important;
}
.photosystem_image_viewer {
    height:377px;
    width:739px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3);
    background-size: 739px 377px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photosystem_image_viewer_right {
	z-index: 100;
    height:85px;
    width:85px;
	position: absolute;
	margin-left: 657px;
	margin-top: 150px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photosystem_image_viewer_left {
	z-index: 100;
    height:85px;
    width:85px;
	position: absolute;
	margin-left: 5px;
	margin-top: 150px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photosystem_viewer_right_disabled {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_right_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photosystem_viewer_right_active {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_right_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photosystem_viewer_left_disabled {
   height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photosystem_viewer_left_active {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photosystem_menu_left {
    height:515px;
    width:749px;
    padding:0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photosystem_menu_right {
    height:515px;
    width:199px;
    padding:0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(47,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photosystem_menu_middle_top {
    height:255px;
    width:199px;
    padding:0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(239,0,0, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photosystem_menu_middle_bottom {
    height:255px;
    width:199px;
    padding:0px;
    border-left:1px solid #CCC;
    border-right:1px solid #CCC;
    border-top:1px solid #CCC;
    border-bottom:1px solid #CCC;
	background-color: rgba(0,239,10, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/****************************************/
/****************************************/
.window_photobucket_frame {
    z-index: 99999;
    background: rgba(255,255,255, 0.0);
    border:hidden;
    border-style: none !important;
    height:100% !important;
    width:100% !important;
    position: fixed;
    display:none;
}


.photobucket_window_table{
    height:550px; 
    width:952px;
    border:thin #000;
}
.photobucket_window_table_td_header{
    height:30px; 
    width:952px;
    position:relative;
    text-align:right;
    background-color:rgba(255,255,255, 0.7);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.photobucket_window_table_main{
    height:555px; 
    width:951px;
    background-color:rgba(255,255,255, 0.7);
    vertical-align:text-top;
    font-size:12px; 
    font-weight:700;
    text-decoration:none;
}
.photobucket_window_table_td_footer{
    height:25px; 
    width:952px;
    vertical-align:text-top;
    background-color:rgba(255,255,255, 0.7);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.photobucket_window_close{
    right:0;
    position:absolute;
    z-index: 10;
}
.photobucket_table{
	display:none;
    height:550px; 
    width:951px;
    background-color:rgba(255,255,255, 0.0);
    vertical-align:text-top;
    text-decoration:none;
}
.photobucket_table_main{
    height:550px !important;
    width:951px !important;
	display:inline-block;
    background-color:rgba(239,239,239, 0.4) !important;
    vertical-align:text-top !important;
    text-decoration:none !important;
}
.photobucket_image_viewer {
    height:555px;
    width:739px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	/*
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3);
    catalog_image_viewer
    photobucket_contribution_image_viewer
    */
    background: url(../images/bkgrounds/empty_cloud.png) rgba(239,239,239, 0.3);
    background-size: 739px 555px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
event_compliance_button_save
url(../images/buttons/sys_pin_default.png) rgba(204,204,204, 0.8)
*/
/* ################################# */
.photobucket_image_viewer_menu_event{
	z-index: 1;
   	height:60px !important;
	width:60px !important;
	display:inline-block;
	position:absolute;
	margin-left: 2px;
	margin-top: 2px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/sys_pin_default.png) rgba(204,204,204, 0.4);
    background-size: 55px 55px;
    background-repeat: no-repeat;
    background-position:50% 45%;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/* ################################# */
.photobucket_image_viewer_menu_activity{
	z-index: 1;
   	height:30px !important;
	width:50px !important;
	display:inline-block;
	position:absolute;
	margin-left: 592px;
	margin-top: 2px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,233,233, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_image_viewer_menu_count{
	z-index: 1;
   	height:30px !important;
	width:90px !important;
	display:inline-block;
	position:absolute;
	margin-left: 645px;
	margin-top: 2px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,233,233, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
}
/* ################################# */
.photobucket_image_viewer_like_frame{
	z-index: 1;
   	height:60px !important;
	width:100px !important;
	display:inline-block;
	position:absolute;
	margin-left: 617px;
	margin-top: 490px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,233,233, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_image_viewer_dislike_frame{
	z-index: 1;
   	height:60px !important;
	width:100px !important;
	display:inline-block;
	position:absolute;
	margin-left: 512px;
	margin-top: 490px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,233,233, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/* ################################# */
.photobucket_image_viewer_right {
	z-index: 100;
    height:85px;
    width:85px;
	position: absolute;
	margin-left: 657px;
	margin-top: 150px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_image_viewer_left {
	z-index: 100;
    height:85px;
    width:85px;
	position: absolute;
	margin-left: 5px;
	margin-top: 150px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_viewer_right_disabled {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_right_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_viewer_right_active {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_right_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_viewer_left_disabled {
   height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_viewer_left_active {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_left {
    height:515px;
    width:749px;
    padding:0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_right {
    height:515px;
    width:199px;
    padding:0px;
	vertical-align: top;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_middle_top {
    height:55px !important;
    width:199px;
    padding:0px;
	/**/
	position: absolute !important;
	top: 45px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_middle_top_left_div {
    height:52px !important;
    width:52px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    background: url(../images/buttons/sys_profile_buttonDefault.png) rgba(255,255,255, 0.7);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_middle_top_right_div {
    height:52px !important;
    width:144px;
    line-height: 22px; 
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 53px !important;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 0.85em;
	font-weight: 600;
    /*
	
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
    word-wrap: break-word !important;
	overflow-wrap: break-word;
}
.photobucket_menu_middle_middle {
    height:70px  !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 100px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_middle_middle_div {
    height:67px  !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_tags_middle {
    height:205px  !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 168px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_tags_middle_div {
    height:202px  !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_middle_bottom {
    height:220px !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 340px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_middle_bottom_div {
    height:217px !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_middle_adv {
    height:20px !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 562px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_middle_adv_div {
    height:17px !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/****************************************/
.Photobucket_Tag_Label_class{
    height:0px;
    width:0px;
}
.photobucket_populate_tags_absolute {
    height:30px !important;
    /*
    height:140px !important;
    width:234px;
	width:228px;
    */
	position: relative;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
    display: inline-block;
	top: 80px;
    margin-top:2px;
    margin-left:3px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.photobucket_populate_label{
    height:30px !important;
	/*
	feed_container_top_bar_td1
    width:70px !important;
	*/
    line-height:20px;
    border:hidden; 
    vertical-align:middle !important;
    text-align:center !important;
    /*
    position: absolute;
    */
    margin-top: 0px;
    margin-left: 2px;
    background-color:azure;
    border:3px solid #FFF;
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

}
.photobucket_edit_tags_absolute {
    height:25px !important;
    /*
    height:140px !important;
    width:234px;
	width:228px;
    */
	position: relative;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
    display: inline-block;
	top: 1px;
    margin-top:1px;
    margin-left:3px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.photobucket_edit_tags_label{
    height:25px !important;
	/*
	feed_container_top_bar_td1
    width:70px !important;
	*/
    line-height:20px;
    border:hidden; 
    vertical-align:middle !important;
    text-align:center !important;
    /*
    position: absolute;
    */
    margin-top: 0px;
    margin-left: 2px;
    background-color:azure;
    border:3px solid #FFF;
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

}
.photobucket_edit_ajax_tags_absolute {
    height:25px !important;
	width: px;
    /*
    height:140px !important;
    
	width:228px;
    */
	position: relative;
	display: inline-block !important;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
	top: 1px;
    margin-top:1px;
    margin-left:3px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.photobucket_edit_tags_remove{
	z-index: 100;
    height:20px !important;
    width:20px !important;
    line-height:20px;
	position: absolute;
	display: none;
	margin-top: 2px;
    margin-left: 2%;
    border:3px solid #FFF;
    border-radius: 25px;
	background: url(../images/buttons/sys_close_buttonDefault.png) rgba(252, 71, 71, 0.7);
    background-position: center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
 	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	
	*/
}
/****************************************/
.Photobucket_Recommentation_Label_class{
    height:0px;
    width:0px;
}
.photobucket_populate_recommendation_absolute {
    height:63px !important;
	width: 63px !important;
    /*
    height:140px !important;
    width:234px;
	width:228px;
    */
	position: relative;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.3);
    display: inline-block;
	top: 127px;
    margin-top:2px;
    margin-left:3px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.photobucket_recommendation_label{
    height:61px !important;
	width: 61px !important;
    line-height:61px;
    vertical-align:top!important;
    text-align:left!important;
    /*
    position: absolute;
    */
    margin-top: 0px;
    margin-left: 0px;
	 border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.2);
    background-size: 61px 61px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

}
/****************************************/
.Photobucket_Profile_Label_class{
    height:0px;
    width:0px;
}

.photobucket_profile_button {
    height:50px !important;
    width:50px !important;
    padding:0px;
	margin-top:2px;
	margin-left: 0px;
	vertical-align: top;
	border:1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.7);
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.2);
    background-size: 49px 49px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_profile_table {
    height:50px !important;
    width:142px!important;
	margin-top:2px;
	margin-left: 0px;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.7);
	font-size: 1.00em;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/****************************************/
/****************************************/
.window_photobucket_contribution_frame {
    z-index: 99999;
    background: rgba(255,255,255, 0.0);
    border:hidden;
    border-style: none !important;
    height:100% !important;
    width:100% !important;
    position: fixed;
    display:none;
}


.photobucket_contribution_window_table{
    height:550px; 
    width:952px;
    border:thin #000;
}
.photobucket_contribution_window_table_td_header{
    /*
    catalog_window_table_td_header
    photobucket_contribution_window_table_td_footer
    */
    height:30px; 
    width:952px;
    position:relative;
    text-align:right;
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.photobucket_contribution_window_table_main{
    height:555px; 
    width:951px;
    background-color:rgba(255,255,255, 0.7);
    vertical-align:text-top;
    font-size:12px; 
    font-weight:700;
    text-decoration:none;
}
.photobucket_contribution_window_table_td_footer{
    height:25px; 
    width:952px;
    vertical-align:text-top;
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.photobucket_contribution_window_close{
    right:0;
    position:absolute;
    z-index: 10;
}
.photobucket_contribution_table{
	display:none;
    height:550px; 
    width:951px;
    background-color:rgba(255,255,255, 0.0);
    vertical-align:text-top;
    text-decoration:none;
}
.photobucket_contribution_table_main{
    height:550px !important;
    width:951px !important;
	display:inline-block;
    background-color:rgba(239,239,239, 0.4) !important;
    vertical-align:text-top !important;
    text-decoration:none !important;
}
.photobucket_contribution_image_viewer {
    height:555px;
    width:739px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	/*
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3);
    catalog_image_viewer
    catalog_image_viewer_dislike_frame
    */
    background: url(../images/bkgrounds/empty_cloud.png) rgba(239,239,239, 0.3);
    background-size: 739px 555px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
photobucket_contribution_menu_middle_top_right_div
url(../images/buttons/sys_pin_default.png) rgba(204,204,204, 0.8)
*/
/* ################################# */
.photobucket_contribution_image_viewer_menu_event{
	z-index: 1;
   	height:60px !important;
	width:60px !important;
	display:inline-block;
	position:absolute;
	margin-left: 2px;
	margin-top: 2px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/sys_pin_default.png) rgba(204,204,204, 0.4);
    background-size: 55px 55px;
    background-repeat: no-repeat;
    background-position:50% 45%;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/* ################################# */

.photobucket_contribution_image_viewer_menu_private{
	z-index: 1;
   	height:50px !important;
	width:60px !important;
	display:inline-block;
	position:absolute;
	margin-left: 545px;
	margin-top: 2px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,22,233, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_image_viewer_menu_sensitivity{
	z-index: 1;
   	height:50px !important;
	width:60px !important;
	display:inline-block;
	position:absolute;
	margin-left: 610px;
	margin-top: 2px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(22,233,233, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_image_viewer_menu_delete{
	z-index: 1;
   	height:50px !important;
	width:60px !important;
	display:inline-block;
	position:absolute;
	margin-left: 675px;
	margin-top: 2px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,233,22, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/* ################################# */
.photobucket_contribution_image_viewer_like_frame{
    /*
    catalog_image_viewer_like_frame
    */
	z-index: 1;
   	height:60px !important;
	width:100px !important;
	display:inline-block;
	position:absolute;
	margin-left: 617px;
	margin-top: 490px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color: rgba(233,233,233, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_image_viewer_dislike_frame{
    z-index: 1;
   	height:60px !important;
	width:100px !important;
	display:inline-block;
	position:absolute;
	margin-left: 512px;
	margin-top: 490px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color: rgba(233,233,233, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/* ################################# */
.photobucket_contribution_image_viewer_right {
	z-index: 100;
    height:85px;
    width:85px;
	position: absolute;
	margin-left: 657px;
	margin-top: 150px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_image_viewer_left {
	z-index: 100;
    height:85px;
    width:85px;
	position: absolute;
	margin-left: 5px;
	margin-top: 150px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_viewer_right_disabled {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_right_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_viewer_right_active {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_right_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_viewer_left_disabled {
   height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_viewer_left_active {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_left {
    height:515px;
    width:749px;
    padding:0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
photobucket_contribution_menu_middle_top
*/
.photobucket_contribution_menu_right {
    height:515px;
    width:199px;
    padding:0px;
	vertical-align: top;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_middle_top {
    /*
    
    
    */
    height:55px !important;
    width:199px;
    padding:0px;
	/**/
	position: absolute !important;
	top: 47px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_middle_top_left_div {
    /*
    photobucket_contribution_window_table_td_header
    */
    height:52px !important;
    width:52px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    background: url(../images/buttons/sys_profile_buttonDefault.png) rgba(255,255,255, 0.7);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_middle_top_right_div {
    height:52px !important;
    width:144px;
    line-height: 24px; 
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 53px !important;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 0.75em;
	font-weight: 600;
    /*
	
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
    word-wrap: break-word !important;
	overflow-wrap: break-word;
}
.photobucket_contribution_menu_middle_middle {
    /*
    photobucket_menu_middle_middle
    */
    height:70px  !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 100px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_middle_middle_div {
    height:67px  !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_tags_middle {
    height:205px  !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 168px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_tags_middle_div {
    height:202px  !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_middle_bottom {
    height:220px !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 340px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_middle_bottom_div {
    height:217px !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_middle_adv {
    height:20px !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 562px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_middle_adv_div {
    height:17px !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*

photobucket_contribution_image_viewer_menu_count

*/
/****************************************/
/****************************************/
.window_catalog_frame {
    z-index: 99999;
    background: rgba(255,255,255, 0.0);
    border:hidden;
    border-style: none !important;
    height:100% !important;
    width:100% !important;
    position: fixed;
    display:none;
}


.catalog_window_table{
    height:550px; 
    width:952px;
    border:thin #000;
}
.catalog_window_table_td_header{
    height:30px; 
    width:952px;
    position:relative;
    text-align:right;
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.catalog_window_table_main{
    height:555px; 
    width:951px;
    background-color:rgba(255,255,255, 0.7);
    vertical-align:text-top;
    font-size:12px; 
    font-weight:700;
    text-decoration:none;
}
.catalog_window_table_td_footer{
    height:25px; 
    width:952px;
    vertical-align:text-top;
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.catalog_window_close{
    right:0;
    position:absolute;
    z-index: 10;
}
.catalog_table{
	display:none;
    height:550px; 
    width:951px;
    background-color:rgba(255,255,255, 0.0);
    vertical-align:text-top;
    text-decoration:none;
}
.catalog_table_main{
    height:550px !important;
    width:951px !important;
	display:inline-block;
    background-color:rgba(239,239,239, 0.4) !important;
    vertical-align:text-top !important;
    text-decoration:none !important;
}
.catalog_image_viewer {
    height:555px;
    width:739px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
    /*
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3);
    photobucket_contribution_image_viewer
    catalog_image_viewer_dislike_frame
    photobucket_contribution_image_viewer_like_frame
    */
    background: url(../images/bkgrounds/empty_cloud.png) rgba(239,239,239, 0.3);
    background-size: 739px 555px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
event_compliance_button_save
url(../images/buttons/sys_pin_default.png) rgba(204,204,204, 0.8)
*/
/* ################################# */
.catalog_image_viewer_menu_event{
	z-index: 1;
   	height:60px !important;
	width:60px !important;
	display:inline-block;
	position:absolute;
	margin-left: 2px;
	margin-top: 2px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/sys_pin_default.png) rgba(204,204,204, 0.4);
    background-size: 55px 55px;
    background-repeat: no-repeat;
    background-position:50% 45%;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/* ################################# */
.catalog_image_viewer_menu_activity{
	z-index: 1;
   	height:30px !important;
	width:50px !important;
	display:inline-block;
	position:absolute;
	margin-left: 592px;
	margin-top: 2px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,233,233, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_image_viewer_menu_count{
	z-index: 1;
   	height:30px !important;
	width:90px !important;
	display:inline-block;
	position:absolute;
	margin-left: 645px;
	margin-top: 2px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,233,233, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
}
/* ################################# */
.catalog_image_viewer_like_frame{
    /*
    photobucket_contribution_image_viewer_like_frame
    */
	z-index: 1;
   	height:60px !important;
	width:100px !important;
	display:inline-block;
	position:absolute;
	margin-left: 617px;
	margin-top: 490px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color: rgba(233,233,233, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_image_viewer_dislike_frame{
    /*
    container_page_STANDARD_footer_frame_option_DOESLIKE_DEFAULT
    
    
    photobucket_view_dislike_disabled
    contribution_view_dislike_disabled
    photobucket_contribution_image_viewer_dislike_frame
    */
	z-index: 1;
   	height:60px !important;
	width:100px !important;
	display:inline-block;
	position:absolute;
	margin-left: 512px;
	margin-top: 490px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color: rgba(233,233,233, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}



/* ################################# */
.catalog_image_viewer_right {
	z-index: 100;
    height:85px;
    width:85px;
	position: absolute;
	margin-left: 657px;
	margin-top: 150px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_image_viewer_left {
	z-index: 100;
    height:85px;
    width:85px;
	position: absolute;
	margin-left: 5px;
	margin-top: 150px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_viewer_right_disabled {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_right_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_viewer_right_active {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_right_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_viewer_left_disabled {
   height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_viewer_left_active {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_left {
    height:515px;
    width:749px;
    padding:0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_right {
    height:515px;
    width:199px;
    padding:0px;
	vertical-align: top;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_middle_top {
    height:55px !important;
    width:199px;
    padding:0px;
	/**/
	position: absolute !important;
	top: 45px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_middle_top_left_div {
    height:52px !important;
    width:52px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    background: url(../images/buttons/sys_profile_buttonDefault.png) rgba(255,255,255, 0.7);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_middle_top_right_div {
    height:52px !important;
    width:144px;
    line-height: 24px; 
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 53px !important;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 0.75em;
	font-weight: 600;
    /*
	
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
    word-wrap: break-word !important;
	overflow-wrap: break-word;
}
.catalog_menu_middle_middle {
    height:70px  !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 100px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_middle_middle_div {
    height:67px  !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_tags_middle {
    height:205px  !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 168px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_tags_middle_div {
    height:202px  !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_middle_bottom {
    height:220px !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 340px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_middle_bottom_div {
    height:217px !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_middle_adv {
    height:20px !important;
    width:199px;
    padding:0px;
	position: absolute !important;
	top: 562px;
	margin-top: 0px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.catalog_menu_middle_adv_div {
    height:17px !important;
    width:196px;
    padding:0px;
	position: absolute !important;
	margin-top: 1px !important;
    margin-left: 1px !important;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/****************************************/
.Photobucket_Tag_Label_class{
    height:0px;
    width:0px;
}
.catalog_populate_tags_absolute {
    height:30px !important;
    /*
    height:140px !important;
    width:234px;
	width:228px;
    */
	position: relative;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
    display: inline-block;
	top: 80px;
    margin-top:2px;
    margin-left:3px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.catalog_populate_label{
    height:30px !important;
	/*
	feed_container_top_bar_td1
    width:70px !important;
	*/
    line-height:20px;
    border:hidden; 
    vertical-align:middle !important;
    text-align:center !important;
    /*
    position: absolute;
    */
    margin-top: 0px;
    margin-left: 2px;
    background-color:azure;
    border:3px solid #FFF;
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

}
.catalog_edit_tags_absolute {
    height:25px !important;
    /*
    height:140px !important;
    width:234px;
	width:228px;
    */
	position: relative;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
    display: inline-block;
	top: 1px;
    margin-top:1px;
    margin-left:3px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.catalog_edit_tags_label{
    height:25px !important;
	/*
	feed_container_top_bar_td1
    width:70px !important;
	*/
    line-height:20px;
    border:hidden; 
    vertical-align:middle !important;
    text-align:center !important;
    /*
    position: absolute;
    */
    margin-top: 0px;
    margin-left: 2px;
    background-color:azure;
    border:3px solid #FFF;
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

}
.catalog_edit_ajax_tags_absolute {
    height:25px !important;
	width: px;
    /*
    height:140px !important;
    
	width:228px;
    */
	position: relative;
	display: inline-block !important;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
	top: 1px;
    margin-top:1px;
    margin-left:3px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.catalog_edit_tags_remove{
	z-index: 100;
    height:20px !important;
    width:20px !important;
    line-height:20px;
	position: absolute;
	display: none;
	margin-top: 2px;
    margin-left: 2%;
    border:3px solid #FFF;
    border-radius: 25px;
	background: url(../images/buttons/sys_close_buttonDefault.png) rgba(252, 71, 71, 0.7);
    background-position: center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
 	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	
	*/
}
/****************************************/
/**************************************photosystem_image_viewer*/
.window_profilesystem_frame {
    z-index: 99999;
    background: rgba(255,255,255, 0.0);
    border:hidden;
    border-style: none !important;
    height:100% !important;
    width:100% !important;
    position: fixed;
    display:none;
}

.profilesystem_window_table{
    height:550px; 
    width:952px;
    border:thin #000;
}
.profilesystem_window_table_td_header{
    height:30px; 
    width:952px;
    position:relative;
    text-align:right;
    background-color:rgba(47,255,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.profilesystem_window_table_main{
    height:550px; 
    width:951px;
    background-color:rgba(255,255,255, 0.7);
    vertical-align:text-top;
    font-size:12px; 
    font-weight:700;
    text-decoration:none;
}
.profilesystem_window_table_td_footer{
    height:25px; 
    width:952px;
    vertical-align:text-top;
    background-color:rgba(255,255,47, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.profilesystem_window_close{
    right:0;
    position:absolute;
    z-index: 10;
}
.profilesystem_table{
	display:none;
    height:550px; 
    width:951px;
    background-color:rgba(255,255,255, 0.0);
    vertical-align:text-top;
    text-decoration:none;
}
.profilesystem_table_main{
    height:550px !important;
    width:951px !important;
	display:inline-block;
    background-color:rgba(239,239,239, 0.4) !important;
    vertical-align:text-top !important;
    text-decoration:none !important;
}
.profilesystem_image_viewer {
    height:500px !important;
    width:739px !important;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3);
    background-size: 739px 500px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profilesystem_image_viewer_right {
	z-index: 100;
    height:85px;
    width:85px;
	position: absolute;
	margin-left: 657px;
	margin-top: 150px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profilesystem_image_viewer_left {
	z-index: 100;
    height:85px;
    width:85px;
	position: absolute;
	margin-left: 5px;
	margin-top: 150px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profilesystem_viewer_right_disabled {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_right_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profilesystem_viewer_right_active {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_right_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profilesystem_viewer_left_disabled {
   height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profilesystem_viewer_left_active {
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profilesystem_menu_left {
    height:515px;
    width:749px;
    padding:0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profilesystem_menu_right {
    height:515px;
    width:199px;
    padding:0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(47,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profilesystem_menu_middle_top {
    height:255px;
    width:199px;
    padding:0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color: rgba(239,0,0, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profilesystem_menu_middle_bottom {
    height:255px;
    width:199px;
    padding:0px;
    border-left:1px solid #CCC;
    border-right:1px solid #CCC;
    border-top:1px solid #CCC;
    border-bottom:1px solid #CCC;
	background-color: rgba(0,239,10, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/****************************************/
/***************************************/
.window_notificationsystem_frame {
    z-index: 99999;
    height:100% !important;
    width:100% !important;
    display:none;
    position: fixed;
    border:hidden;
    border-style: none !important;    
    background: rgba(255,255,255, 0.0);
}
.notificationsystem_window_table{
    height:605px; 
    width:951px;
    border:hidden;
}
.notificationsystem_window_table_td_header{
    height:30px; 
    width:951px;
    position:relative;
    text-align:right;
    background-color:rgba(255,255,255, 0.1);
}
/*
window_view_UI_control_class
notificationsystem_table_main
notificationsystem_menu_action
*/
.notificationsystem_menu_header{
    height:60px; 
    width:951px;
    position:relative;
    text-align:right;
	border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.0);
    filter: Alpha(Opacity=0);
    opacity: 0.0;
}
.notificationsystem_menu_main{
    height:400px; 
    width:951px;
    position:relative;
    text-align:right;
	border: 1px solid rgba(204,204,204, 0.3);
    background-color:rgba(255,255,255, 0.99);
    filter: Alpha(Opacity=99);
    opacity: 0.99;
}
/* ################################# */
.notificationsystem_div_01{
    height:375px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.8);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.notificationsystem_div_02{
    height:375px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(8,8,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.notificationsystem_div_03{
    height:375px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,85,200, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.notificationsystem_div_04{
    height:375px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(100,255,50, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.notificationsystem_div_05{
    height:375px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,239, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.notificationsystem_div_06{
    height:375px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(200,100,239, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.notificationsystem_div_07{
    height:375px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(200,100,239, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.notificationsystem_div_08{
    height:375px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,239, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
/* ################################# */
.notificationsystem_menu_action{
    height:60px; 
    width:951px;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.2);
    background-color:rgba(239,239,239, 0.7);
}
.notificationsystem_window_table_main{
    height:550px; 
    width:951px;
    background-color:rgba(255,255,255, 0.7);
    vertical-align:text-top;
    font-size:12px; 
    font-weight:700;
    text-decoration:none;
}
/*
notificationsystem_menu_header
*/
.notificationsystem_window_table_td_footer{
	z-index: 1;
    height:25px; 
    width:951px;
    vertical-align:text-top;
    background-color:rgba(255,255,255, 0.0);
    filter: Alpha(Opacity=0);
    opacity: 0.0;
}
.notificationsystem_window_close{
    right:0;
    position:absolute;
    z-index: 10;
}
/*
event_edit_pop_td1
z-index:9999995;
*/
.notificationsystem_table{
    height:550px; 
    width:951px;
    background-color:rgba(255,255,255, 0.0);
    vertical-align:text-top;
    text-decoration:none;
}
/*
notificationsystem_window_table
notificationsystem_window_table_td_header
*/
.notificationsystem_table_main{
    height:550px !important;
    width:951px !important;
	display:inline-block;
    background-color:rgba(255,255,255, 0.1) !important;
    vertical-align:text-top !important;
    text-decoration:none !important;
}
.notificationsystem_image_viewer {
    height:500px !important;
    width:739px !important;
    padding:0px;
    border: hidden;
	margin-left: 4px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3);
    background-size: 739px 500px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/* ################################# */
/*******************/
.social_information_view_div{
    height:30px; 
    width:30px;
	line-height: 20px;
	display:inline-block;
	margin-top:0px;
    margin-left:0px;
	position: absolute; 
    border: 1px solid rgba(204,204,204, 0.0);
    background-color: rgba(255,255,255, 0.7);
    vertical-align:middle;
	text-align:center;
}

/*******************/
.information_button{
    height:26px; 
    width:26px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.3);
    /*
    background: url(../images/buttons/sys_refresh_button.png) rgba(255,255,255, 0.0);
    */
    background: url(../images/buttons/clear_img.png) rgba(255,255,41, 0.7);
    
	background-position:center;
    background-size: 60px 60px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.infocationsystem_table{
    height:550px; 
    width:951px;
    background-color:rgba(255,255,255, 0.0);
    vertical-align:text-top;
    text-decoration:none;
}
.infocationsystem_table_main{
    height:550px !important;
    width:951px !important;
	display:inline-block;
    background-color:rgba(255,255,255, 0.1) !important;
    vertical-align:text-top !important;
    text-decoration:none !important;
}
.infocationsystem_menu_header{
    height:20px; 
    width:951px;
    position:relative;
    text-align:right;
	border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.0);
    filter: Alpha(Opacity=0);
    opacity: 0.0;
}
.infocationsystem_menu_main{
    height:400px; 
    width:951px;
    position:relative;
    text-align:right;
	border: 1px solid rgba(204,204,204, 0.3);
    background-color:rgba(255,255,255, 0.99);
    filter: Alpha(Opacity=99);
    opacity: 0.99;
}
.infocationsystem_div_01{
    z-index:2004;
    height:600px; 
    width:951px;
	display: none;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.8);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
    vertical-align:top;
    text-align:left;
}
.infocationsystem_div_02{
    height:600px;
    width:951px;
	display: none;
    position:relative;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
    vertical-align:top;
    text-align:left;
}
.infocationsystem_div_03{
    height:600px;
    width:951px;
	display: none;
    position:relative;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
    vertical-align:top;
    text-align:left;
}
.infocationsystem_div_04{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
    vertical-align:top;
    text-align:left;
}
.infocationsystem_div_05{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
    vertical-align:top;
    text-align:left;
}
.infocationsystem_div_06{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
    vertical-align:top;
    text-align:left;
}
.infocationsystem_div_07{
    height:600px;
    width:951px;
	display: none;
    position:relative;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
    vertical-align:top;
    text-align:left;
}
.infocationsystem_div_08{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
    vertical-align:top;
    text-align:left;
}
.infocationsystem_div_09{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
    vertical-align:top;
    text-align:left;
}
.infocationsystem_div_10{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
    vertical-align:top;
    text-align:left;
}
.infocationsystem_div_11{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
    vertical-align:top;
    text-align:left;
}
.infocationsystem_div_12{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
    vertical-align:top;
    text-align:left;
}
/* ################################# */
.eventsystem_table_main{
    z-index:2003;
    height:550px !important;
    width:951px !important;
	display:inline-block;
    background-color:rgba(255,255,255, 0.1) !important;
    vertical-align:text-top !important;
    text-decoration:none !important;
}
.eventsystem_menu_header{
    height:20px; 
    width:951px;
    position:relative;
    text-align:right;
	border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.0);
    filter: Alpha(Opacity=0);
    opacity: 0.0;
}

.eventsystem_div_01{
    z-index:2004;
    height:600px; 
    width:951px;
	display: none;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(239,239,239, 0.8);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.eventsystem_div_02{
    height:600px;
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.eventsystem_div_03{
    height:600px;
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}
.eventsystem_div_04{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}
.eventsystem_div_05{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}
.eventsystem_div_06{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}
.eventsystem_div_01_map{
    /*
    z-index:10;
    */
    height:600px; 
    width:951px;
    margin-top: 1px;
	margin-left: 1px;
    position:absolute;
    vertical-align:top;
    text-align:left;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(239,239,239, 0.9);
}
.eventsystem_div_03_map{
    /*
    z-index:10;
    */
    height:600px; 
    width:951px;
    margin-top: 0px;
	margin-left: 0px;
    position:absolute;
    vertical-align:top;
    text-align:left;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(239,239,239, 0.9);
}
.eventsystem_div_01_search{
    /*map_event_key_div*/
    z-index:100;
    
    height:40px !important;
    width:900px;
    margin-top: 60px;
	margin-left: 175px;
    position:absolute;
	border-spacing: 0px;
    vertical-align:middle;
	text-align:left !important;
	border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.0);
}
.eventsystem_div_01_search_address_div_option{
    /*
    eventsystem_div_01_search
    */
    z-index:100;
    height:28px ;
    width: 48px;
	line-height: 28px !important;
	margin-top: 1px;
	margin-left: 460px;
    position: absolute !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    border-radius: 10px;
    background-color:rgba(252, 71, 71, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.eventsystem_div_01_search_address_div_close{
    z-index:1005;
    height:50px ;
    width: 100px;
	line-height: 50px !important;
	margin-top: 550px;
	margin-left: 847px;
    position: absolute !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    background-color:rgba(4, 144, 242, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.eventsystem_map_key_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:81%;
	/*
	margin-left:360px;
	*/
	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/* ################################################ */
/* ######        LANDSCAPE_BASE              ##### */	
	.base_header_frame{
		/*
		height:100px !important;
		*/
		height:38px !important;
		width:718px !important;
		border-spacing: 0px;
		margin:0;
		padding:0;
	}

	.base_header_table {
		/*
		height:98px !important;
		*/
		height:38px !important;
		width:718px !important;
		
		border-spacing: 0px;
		background-color:rgba(255,255,255, 0.5);
		border:1px solid #CCC;
		
		margin:0;
		padding:0;
		
		font-size:24px;
		font-weight:700;
		text-align:center;	
	}
/*
full_MGMT_query_metrics
ls_set-opt_pic_button

*/
.activity_status_header{
    height:38px !important;
    width:717px !important;
    line-height:38px;

    background-color:rgba(255,255,255, 0.3);
    border:1px solid rgba(233,233,233, 0.9);
    border-spacing: 0px;

    text-align:center !important;	
    vertical-align:text-top !important;
}
	.title_status_header{
		font-size:1.20em;
		font-weight:700;
		
		position: absolute;
		
		text-align:center !important;	
		vertical-align:middle !important;
		
		margin-top: -10px;
		margin-left: -120px;
	}
	.title_header_return_button{
		height:40px !important;
		width:70px !important;
		
		position: absolute;
		
		font-size:.85em;
		font-weight:700;
		text-align:center !important;	
		vertical-align:middle !important;
		
		margin-top: -10px;
		margin-left: -350px;
		
		border:1px solid #ccc;
		background: url(../images/buttons/butt_template.png);
		background-position:50% 70%; 
		background-size: 65px 35px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
/*
activity_status_header
setting_photo_image_window_td1
*/
.base_body_table {
    height:485px !important;
    width:715px !important;
    top:0;
    background-color:#FFF;
    margin:0;
    padding:0;	
}
.base_body_table_full {
    height:564px;
    width:715px;
    margin:0;
    padding:0;		
}

.base_body_table_td {
    height:485px !important;
    width:715px;
    text-align:left !important;
    vertical-align:top !important;
    background-color:#FFF;
    margin:0;
    padding:0;	
}
.base_functional_table {
    height:575px !important;
    width:715px;
    background-color:rgba(255,255,255, 0.3);
    border:1px solid rgba(233,233,233, 0.9);
    border-spacing: 0px;
} 
.base_functional_table_td {
    vertical-align: top;
} 
/*     HISTORIC     */
.base_settings_table {
    height:519px !important;
    width: 951px;
    background-color:rgba(255,255,255, 0.3);
    border:1px solid rgba(233,233,233, 0.9);
    border-spacing: 0px;
} 
.base_settings_table_td {
    vertical-align: top;
}

.base_error_table {
    height:519px !important;
    width: 951px;
    background-color:rgba(255,255,255, 0.3);
    border:1px solid rgba(233,233,233, 0.9);
    border-spacing: 0px;
} 
.base_error_table_td {
    vertical-align: top;
} 
/*

*/
.base_share_table_td1 {
    height:409px !important;
    width: 950px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(255,255,255, 0.7);
    vertical-align: top;
    text-align:left !important;
}
.base_share_table_td2 {
    height:110px !important;
    width: 950px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(255,255,255, 0.7);
    vertical-align: top;
    text-align:left !important;
} 

/*     NEW IMAGE EDIT WINDOW   background-color:#090; background-color:#090; background-color:#090;*/
.ie_functional_table {
    /*
    line-height: 623px !important;
    vertical-align:middle !important; 
    ie_functional_table_td_row_side_active
    ie_functional_table_td_row_side_active
    */
    height:623px !important;
	/*
	width:952px !important;
	*/
    width:948px !important;

    background:rgba(255,255,255, 0.4);
    border:1px solid rgba(239,239,239, 0.3);
    border-spacing: 0px;

} 
.ie_functional_table_td_corner_top {
    height:50px !important;
    width:226px !important;
    line-height: 50px !important;
    border:hidden;
    border-spacing: 0px;
	background: url(../images/bkgrounds/image_gid.png);
    background-size: 100px 100px;
} 
.ie_functional_table_td_corner_bottom {
    height:90px !important;
    width:226px !important;
    line-height: 90px !important;
    border:hidden;
    border-spacing: 0px;
	background: url(../images/bkgrounds/image_gid.png);
    background-size: 100px 100px;
} 

.ie_functional_table_td_corner_holder {
    height:90px !important;
    width:226px !important;
    line-height: 90px !important;
    text-align:right;
    vertical-align:middle;
    border:hidden;
    border-spacing: 0px;
	background: url(../images/bkgrounds/image_gid.png);
    background-size: 100px 100px;
} 

.ie_functional_table_td_col_top {
    width:497px !important;
    border:hidden;
    border-spacing: 0px;
	background: url(../images/bkgrounds/image_gid.png);
    background-size: 100px 100px;
} 
/* PORTRAIT background-color:#CCC;background-repeat: no-repeat;background-color:#090;background-color:#090;*/
.ie_functional_table_td_col_top_active {
    /*
    setting_photo_image_window_td1_sub
    ie_functional_table_td_row_side
    ie_functional_table_td_corner_top
    ie_functional_table_td_corner_bottom
    ie_functional_table_td_corner_holder
    ie_functional_submit_button
    event_compliance_button_save
    ie_functional_table_td_row_side_active
    */
    width:497px !important;
    border:hidden;
    border-spacing: 0px;
	background: url(../images/bkgrounds/image_gid.png);
    background-size: 100px 100px;
    background-repeat:repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=70);
    opacity: .77;
} 
.ie_functional_table_td_col_bottom {
    height:90px !important;
    width:495px !important;
    line-height: 90px !important;
    border:hidden;
    border-spacing: 0px;
	background: url(../images/bkgrounds/image_gid.png);
    background-size: 100px 100px;
    background-repeat:repeat;
} 

.ie_functional_table_td_row_side {
    height:373px !important;
    line-height: 373px !important;
    border:hidden;
    border-spacing: 0px;
	background: url(../images/bkgrounds/image_gid.png) rgba(180,255,255, 0.3);
    background-size: 100px 100px;
    background-repeat:repeat;
} 

.ie_functional_table_td_row_side_active {
    height:470px !important;
    max-height:470px !important;
    vertical-align: middle !important;
    border:hidden;
    border-spacing: 0px;
	background: url(../images/bkgrounds/image_gid.png);
    background-size: 100px 100px;
    background-repeat:repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=70);
    opacity: .77;
} 
.ie_functional_table_td_row {
    height:373px !important;
    width:497px !important;
    line-height: 373px !important;
    background-color:#EFEFEF;
    border:hidden;
    border-spacing: 0px;
} 

.ie_functional_submit_button {
    height:90px !important;
    width:130px !important;
    line-height: 90px !important;
    font-size: .75em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border:hidden;
    background-color: rgba(4, 144, 242, 0.7);
    background-size: 120px 85px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_reset_button {
    height:50px !important;
    width:110px !important;
    line-height: 50px !important;
    font-size: .75em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border:hidden;
    background-color: rgba(255, 255, 128, 0.7);
    background-size: 100px 45px;
    background-repeat: no-repeat;
    background-position:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_delete_button {
    height:90px !important;
    width:130px !important;
    line-height: 90px !important;
    font-size: .75em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border:hidden;
    background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(252, 71, 71, 0.7);
    background-size: 85px 85px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/**********************************************/
.ie_functional_table_td_meta_active {
    height:520px !important;
    border:hidden;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw1 {
    height:278px !important;
	width: 948px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw1td1 {
    height:278px !important;
	width: 394px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(209,209,209, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ie_functional_table_td_meta_rw1td1_div {
    height:279px !important;
	width: 383px !important;
	position: absolute;
	margin-top: 2px;
	margin-left: 1px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
	background-size: 383px 279px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ie_functional_table_td_meta_rw1td2 {
    height:278px !important;
	width: 550px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw1td2_compliance {
    height:100px !important;
	width: 550px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw1td2_top {
    height:45px !important;
	width: 550px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw1td2_middle {
    height:45px !important;
	width: 550px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw1td2_bottom {
    height:83px !important;
	width: 548px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw1td2_count_tr {
    height:25px !important;
	width: 100px;
	position: absolute;
	margin-top: -28px;
	margin-left: 453px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw1td2_count_td {
    height:25px !important;
	width: 100px;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.9);
	background-color:rgba(203,203,203, 0.7);
	vertical-align: middle;
	text-align: center;
	font-size: .85em;
	font-weight: 600;
    color:rgba(15, 5, 26, 0.7);
    text-decoration:none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw2 {
    height:295px !important;
	width: 948px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw2_left {
    height:293px !important;
	width: 746px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw2_left_map {
    height:293px !important;
	width: 742px;
	position: absolute;
	margin-top: 2px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:0px solid rgba(239,239,239, 0.0);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.map_image_key_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:78%;
	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.map_image_search_div {
	z-index: 100;
	height:32px !important;
	width:516px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:10px;
	border-spacing: 0px;
	border:hidden;
	vertical-align:text-top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.ie_functional_table_td_meta_rw2_right_top {
    height:230px !important;
	width: 198px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_rw2_right_top_div {
    height:228px !important;
	width: 195px;
	position: absolute;
	margin-top: 235px;
	margin-left: 357px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ie_functional_table_td_meta_submit_div {
	z-index: 50;
    height:50px !important;
	width: 100px;
	position: absolute;
	margin-top: 465px;
	margin-left: 400px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	event_compliance_button_save
	ie_functional_table_td_meta_rw2_right_bottom
	*/
	
}

.ie_functional_table_td_meta_field  {
	height:44px !important;
	width:192px;
	margin-top: 2px;
	margin-left: 0px;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
	
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.ie_functional_table_td_meta_rw2_right_bottom {
    height:60px !important;
	width: 198px;
    vertical-align: middle;
	text-align: center;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ie_functional_table_meta_corner_top {
    height:42px !important;
    width:224px !important;
    line-height: 40px !important;
    background-color:#EFEFEF;
    border:hidden;
    border-spacing: 0px;
} 

.ie_functional_table_meta_col_top {
    width:497px !important;
    border:hidden;
    border-spacing: 0px;
    background-color:#EFEFEF;
} 

.ie_functional_table_meta_corner_bottom {
    height:10px !important;
    width:224px !important;
    line-height: 10px !important;
    background-color:#EFEFEF;
    border:hidden;
    border-spacing: 0px;
} 
.ie_functional_table_meta_col_bottom {
    height:10px !important;
    width:497px !important;
    line-height: 10px !important;
    background-color:#EFEFEF;
} 
.ie_functional_table_meta_corner_holder {
    height:10px !important;
    width:224px !important;
    line-height: 10px !important;
    text-align:right;
    vertical-align:middle;
    background-color:#EFEFEF;
    border:hidden;
    border-spacing: 0px;
} 
/**********************************************/
/*
ls_form_half_field
*/
.ls_form_image_field{
    height:35px !important;
    width: 99%;
    line-height:20px !important;
	margin-left: 2px;
    background:rgba(238,238,238, 0.5);
    border-spacing: 0px;
    border:hidden;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
  }

.image_form_compliance_td{
    height:45px !important;
    width: 425px !important;
	line-height: 20px !important;
	margin-top: 0px;
	margin-left: 0px;
	
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background:rgba(255,255,255, 0.5);
}
.image_form_compliance_option{
    height:45px !important;
    width: 62px !important;
	line-height: 20px !important;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background:rgba(255,255,255, 0.5);
}
.image_compliance_view{
    border-spacing: 0px;
    border: 2px solid rgba(255, 255, 128, 0.7);
}
.event_compliance_button_save {
    height:50px;
    width:100px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background-color:rgba(255,255,255, 0.7);
    background-size: 95px 45px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	photobucket_populate_label
	ie_functional_table_td_meta_submit_div
	*/
}
.event_compliance_button_save_active {
    height:50px;
    width:100px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background-color:rgba(4, 144, 242, 0.7);
    background-size: 95px 45px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	photobucket_populate_label
	ie_functional_table_td_meta_submit_div
	*/
}
.event_compliance_button2 {
    height:40px;
    width:60px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 36px 36px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_compliance_button3 {
    height:40px;
    width:60px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 36px 36px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_compliance_button2_active {
    height:40px;
    width:60px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 36px 36px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_compliance_button3_active {
    height:40px;
    width:60px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 36px 36px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_compliance_button_active {
    height:40px;
    width:60px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 36px 36px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.image_form_compliance_select{
    height:50px !important;
    width: 109.5px !important;
	line-height: 20px !important;
	margin-top: 0px;
	margin-left: 0px;
	
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background:rgba(255,255,255, 0.5);
}

.event_compliance_select_button4 {
    height:45px;
    width:107px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 80px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.event_compliance_select_button5 {
    height:45px;
    width:107px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 80px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_compliance_select_button6 {
    height:45px;
    width:107px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 80px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_compliance_select_button7 {
    height:45px;
    width:107px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 80px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_compliance_select_button8 {
    height:45px;
    width:107px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 80px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_compliance_select_button4_active {
    height:45px;
    width:107px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 80px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.event_compliance_select_button5_active {
    height:45px;
    width:107px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 80px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_compliance_select_button6_active {
    height:45px;
    width:107px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 80px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_compliance_select_button7_active {
    height:45px;
    width:107px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 80px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_compliance_select_button8_active {
    height:45px;
    width:107px;
    display:inline-block;
    vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 80px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.image_form_compliance_meta_td{
    height:45px !important;
    width: 550px !important;
	line-height: 20px !important;
	margin-top: 0px;
	margin-left: 0px;
	
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background:rgba(255,255,255, 0.5);
}

.event_compliance_button7 {
    height:30px;
    width:90px;
    display:inline-block;
	position: absolute;
    vertical-align:top;
	margin-top: 2px;
	margin-left: 450px;
    border: 1px solid rgba(239,239,239, 0.0);
    background:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.image_form_messageboxinput_field{
    height:83px !important;
    width: 546px !important;
	line-height: 20px !important;
 
    background:rgba(238,238,238, 0.5);
	margin-top: 1px;
	margin-left: 1px;
	
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
    text-indent:5px;
	
	*/
    
}
/**********************************************/


/**********************************************/


/* ################################################ */
/* ######       LANDSCAPE_FORMS               ##### */	

/*
register_input_table_full
*/


	  /*

border-style:solid;
		border-width:thin;
		border-color:#090;
background: url(../images/buttons/butt_template.png) #EEEEEE;
		background-position:left;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	height:35px !important;
width: 100%;
		line-height:30px !important;
		border-spacing: 0px;
border-style:solid;
		border-width:thin;
		border-color:aqua;

	form_selected
	form_item_selected

*/
	  .date_form_full_field_active{
	  
		/*width: 345px;*/
		
		font-size: .85em;
		font-weight: 500;
		color:rgba(15, 5, 26, 0.6);
		text-align:left;
		text-decoration:none;
		text-indent:34px;
	  }
/*
 #32DC32

border-color: ;

*/
	.date_form_full_field_active_start_date_active{
	  	height:33px !important;
		/*width: 345px;*/
		width: 100%;
		line-height:30px !important;
		border-style:solid;
		border-width:thin;
		border-spacing: 0px;
		
		border-color:#EFEFEF;
		font-size: .85em;
		font-weight: 500;
		color:rgba(15, 5, 26, 0.6);
		text-align:left;
		text-decoration:none;
		text-indent:34px;
		background: url(../images/buttons/sys_menu_start_event.png);
		background-position:left;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
/*

 #32DC32
*/
	.date_form_full_field_active_start_time_active{
	  	height:35px !important;
		/*width: 345px;*/
		width: 100%;
		line-height:30px !important;
		border-spacing: 0px;
		border-style:solid;
		border-width:thin;
		border-color:#EFEFEF;
		
		font-size: .85em;
		font-weight: 500;
		color:rgba(15, 5, 26, 0.6);
		text-align:left;
		text-decoration:none;
		text-indent:34px;
		background: url(../images/buttons/butt_template.png);
		background-position:left;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
/*

 #F50206
*/
	.date_form_full_field_active_end_date_active{
	  	height:35px !important;
		/*width: 345px;*/
		width: 100%;
		line-height:30px !important;
		border-spacing: 0px;
		border-style:solid;
		border-width:thin;
		border-color:#EFEFEF;
		
		font-size: .85em;
		font-weight: 500;
		color:rgba(15, 5, 26, 0.6);
		text-align:left;
		text-decoration:none;
		text-indent:34px;
		background: url(../images/buttons/sys_menu_end_event.png);
		background-position:left;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }

/*

 #F50206
*/
	.date_form_full_field_active_end_time_active{
	  	height:35px !important;
		/*width: 345px;*/
		width: 100%;
		line-height:30px !important;
		border-spacing: 0px;
		border-style:solid;
		border-width:thin;
		border-color:#EFEFEF;
		
		font-size: .85em;
		font-weight: 500;
		color:rgba(15, 5, 26, 0.6);
		text-align:left;
		text-decoration:none;
		text-indent:34px;
		background: url(../images/buttons/butt_template.png);
		background-position:left;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
/******************/
	.date_form_full_field_active_start_date_inactive{
	  	height:33px !important;
		/*width: 345px;*/
		width: 100%;
		line-height:30px !important;
		border-style:solid;
		border-width:thin;
		border-spacing: 0px;
		
		border-color:#EFEFEF;
		font-size: .85em;
		font-weight: 500;
		color:#ccc;
		text-align:left;
		text-decoration:none;
		text-indent:34px;
		background: url(../images/buttons/butt_template.png);
		background-position:left;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
/*

 #32DC32
*/
	.date_form_full_field_active_start_time_inactive{
	  	height:35px !important;
		/*width: 345px;*/
		width: 100%;
		line-height:30px !important;
		border-spacing: 0px;
		border-style:solid;
		border-width:thin;
		border-color:#EFEFEF;
		
		font-size: .85em;
		font-weight: 500;
		color:#ccc;
		text-align:left;
		text-decoration:none;
		text-indent:34px;
		background: url(../images/buttons/butt_template.png);
		background-position:left;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
/*

 #F50206
*/
	.date_form_full_field_active_end_date_inactive{
	  	height:35px !important;
		/*width: 345px;*/
		width: 100%;
		line-height:30px !important;
		border-spacing: 0px;
		border-style:solid;
		border-width:thin;
		border-color:#EFEFEF;
		
		font-size: .85em;
		font-weight: 500;
		color:#ccc;
		text-align:left;
		text-decoration:none;
		text-indent:34px;
		background: url(../images/buttons/butt_template.png);
		background-position:left;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }

/*

 #F50206
*/
	.date_form_full_field_active_end_time_inactive{
	  	height:35px !important;
		/*width: 345px;*/
		width: 100%;
		line-height:30px !important;
		border-spacing: 0px;
		border-style:solid;
		border-width:thin;
		border-color:#EFEFEF;
		
		font-size: .85em;
		font-weight: 500;
		color:#ccc;
		text-align:left;
		text-decoration:none;
		text-indent:34px;
		background: url(../images/buttons/butt_template.png);
		background-position:left;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }


/*****************/
.setting_action_button {
    height: 30px;
    width: 30px;
    line-height:30px;
	position: absolute;
	margin-top: 1px;
	margin-left: -31px;
    text-align:center;
    text-decoration:none;
    border:1px solid #CCC;
    background: url(../images/buttons/sys_settings_option.png) #EFEFEF;
    background-position:center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.item_title_button {
    height: 30px;
    width: 30px;
    line-height:30px;
	position: absolute;
	margin-top: .4%;
	margin-left: 52%;
    text-align:center;
    text-decoration:none;
    border:1px solid #CCC;
    background: url(../images/buttons/sys_settings_option.png) #EFEFEF;
    background-position:center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.item_action_button {
    height: 30px;
    width: 30px;
    line-height:30px;
	position: absolute;
	margin-top: .9%;
	margin-left: 14%;
    text-align:center;
    text-decoration:none;
    border:1px solid #CCC;
    background: url(../images/buttons/sys_settings_option.png) #EFEFEF;
    background-position:center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.item_modify_button {
    height: 30px;
    width: 30px;
    line-height:30px;
	position: absolute;
	margin-top: .9%;
	margin-left: 23%;
    text-align:center;
    text-decoration:none;
    border:1px solid #CCC;
    background: url(../images/buttons/sys_settings_option.png) #EFEFEF;
    background-position:center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************/
.compliance_div01 {
    height: 35px;
    width: 60px;
    line-height:12px;
	margin-top: -10px;
	margin-left: 88px;
	position: absolute;
    text-align:center;
    text-decoration:none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contact_compliance_default {
    height: 35px;
    width: 75px;
    line-height:12px;
	margin-top: 0px;
	margin-left: 0px;/*103px*/
    text-align:center;
    text-decoration:none;
    border:1px solid #CCC;
    background: url(../images/buttons/sys_settings_option.png) rgba(238,238,238, 0.7);
    background-position:center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: .80em;
    font-weight: 500;
}
.contact_compliance_0 {
    height: 35px;
    width: 75px;
    line-height:12px;
	margin-top: 0px;
	margin-left: 0px;/*103px*/
    text-align:center;
    text-decoration:none;
    border:1px solid #CCC;
    background: url(../images/buttons/sys_settings_option.png) rgba(252, 71, 71, 0.7);
    background-position:center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: .80em;
    font-weight: 500;
}
.contact_compliance_15 {
    height: 35px;
    width: 75px;
    line-height:12px;
	margin-top: 0px;
	margin-left: 0px;/*103px*/
    text-align:center;
    text-decoration:none;
    border:1px solid #CCC;
    background: url(../images/buttons/sys_settings_option.png) rgba(255, 255, 128, 0.7);
    background-position:center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: .80em;
    font-weight: 500;
}
.contact_compliance_30 {
    height: 35px;
    width: 75px;
    line-height:12px;
	margin-top: 0px;
	margin-left: 0px;/*103px*/
    text-align:center;
    text-decoration:none;
    border:1px solid #CCC;
    background: url(../images/buttons/sys_settings_option.png) rgba(10, 245, 92, 0.7);
    background-position:center;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: .80em;
    font-weight: 500;
}
/*****************/
	  
	  .date_form_full_field_error{
	  	height:35px !important;
		/*width: 345px;*/
		width: 100%;
		line-height:30px !important;
		border-spacing: 0px;
		border-style:solid;
		border-width:thin;
		border-color:#F00;
		font-size: .85em;
		font-weight: 500;
		text-align:left;
		text-decoration:none;
		text-indent:34px;
		background: url(../images/buttons/butt_template.png) #EEEEEE;
		background-position:left;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
/* ################################################ */
/* ######    LANDSCAPE_REGISTER               ##### */
/*
reset_input_table_body
reset_form_full_instruction
reset_form_full_table
*/
.registration_message_td {
    height:40px !important;
    width:450px !important;
    line-height:12px !important;
    vertical-align:bottom!important;
    border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: 1px solid rgba(204,204,204, 0.3);
	border-bottom: 1px solid rgba(239,239,239, 0.7);
    background-color: rgba(239,239,239, 0.7);
}
.registration_message_error_td {
    height:38px !important;
    width:450px !important;
    line-height:12px !important;
    vertical-align:bottom!important;
    border-spacing: 0px;
    border:1px solid rgba(252, 71, 71, 0.3);
    background:rgba(239,239,239, 0.7);
    
}
.registration_option_td {
    height:55px !important;
    width:100px !important;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    vertical-align:middle!important;
    text-align:center;
    background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.registration_profile_default {
    height:55px !important;
    width:100px !important;
    line-height:12px !important;
    
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/sys_profile_buttonDefault.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
reg_check_button_default 
*/
.registration_profile_active {
    height:55px !important;
    width:100px !important;
    line-height:12px !important;
    
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/sys_profile_buttonActive.png) rgba(204,204,204, 0.7); 
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.registration_business_default {
    height:55px !important;
    width:100px !important;
    line-height:12px !important;
    
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/sys_option_default/default_building.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.registration_business_active {
    height:55px !important;
    width:100px !important;
    line-height:12px !important;
    
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/sys_option_default/default_building.png) rgba(10, 245, 92, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ls_form_temperature_button {
    height: 35px !important;
    /* width: 195px; 
    ls_form_half_field
    */
    width: 97%;
    line-height:20px !important;
    background:rgba(238,238,238, 0.5);
    border-spacing: 0px;
    border-style:solid;
    border-width:thin;
    border: hidden;
    /*
	 border-color:1px solid rgba(239,239,239, 0.0);
    font-size: .85em;
    font-weight: 500;
    */
    text-align: left;
    text-decoration: none;
    text-indent: 5px;
}
.ls_form_popup_button {
    height: 35px !important;
    /* width: 195px; */
    width: 100%;
    line-height: 20px !important;
    background-color: #EEEEEE;
    border-spacing: 0px;
    border: hidden;
	/*
    font-size: .85em;
    font-weight: 500;
	*/
    text-align: left;
    text-decoration: none;
    text-indent: 5px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    padding:0 !important;
}
.output_email_default {
    height: 44px !important;
    width: 160px !important;
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.output_email_correct {
    height: 44px !important;
    width: 160px !important;
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    background: url(../images/buttons/sys_option_correct_active.png) #FFF;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.output_email_error {
    height: 44px !important;
    width: 160px !important;
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    background: url(../images/buttons/sys_option_error_active.png) #FFF;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.output_password_default {
    height: 44px !important;
    width: 160px !important;
    /*
    width: 210px;
    */
    line-height:12px;
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    text-align:left;
    text-indent:5px;
    vertical-align:middle;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.output_password_correct {
    height: 44px !important;
    /*
    width: 210px;
    output_username_correct
    */
    width: 160px !important;
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    background: url(../images/buttons/sys_option_correct_active.png) #FFF;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.output_password_error {
    height: 44px !important;
    width: 160px !important;
    /*
    width: 210px;
    */
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    background: url(../images/buttons/sys_option_error_active.png) #FFF;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.output_match_error_top {
    height: 44px !important;
    width: 160px;
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    background: url(../images/buttons/sys_match_error_top.png) #FFF;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.output_match_error_bottom {
    height: 44px !important;
    width: 160px;
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    background: url(../images/buttons/sys_match_error_bottom.png) #FFF;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.output_username_default {
    text-align:left;
    text-indent:5px;
    vertical-align:middle;
}
.output_username_correct {
    height: 44px !important;
    width: 160px !important;
    /*
    width: 210px;
    output_email_default
    */
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    background: url(../images/buttons/sys_option_correct_active.png) #FFF;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.output_username_error {
    height: 44px !important;
    width: 210px;
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    background: url(../images/buttons/sys_option_error_active.png) #FFF;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.output_title_default {
    text-align:left;
    text-indent:5px;
    vertical-align:middle;
}
.output_title_correct {
   height:45px !important;
    width: 110px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    background: url(../images/buttons/sys_option_correct_active.png) #FFF;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.output_title_error {
    height:45px !important;
    width: 110px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: hidden;
    background: #FFF;
    background: url(../images/buttons/sys_option_error_active.png) #FFF;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.reg_check_button {
    height:57px;
    width: 188px;
    line-height:12px;
    margin-top: 1px;
	margin-left: 2px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(204,204,204, 0.7);
    font-size: 0.90em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
     
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	text-align:left !important;
    vertical-align:middle !important;
    text-decoration:none;
    text-indent:5px;
}
.reg_check_button_active {
    height:45px;
    width: 45px;
    line-height:12px;
    position: absolute;
    margin-top: 8px;
	margin-left: 140px;
    border: 1px solid  rgba(204,204,204, 0.7);
	background: url(../images/buttons/sys_option_correct_active.png) rgba(255,255,255, 0.7);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.reg_check_button_default {
    height:45px;
    width: 45px;
    line-height:12px;
    position: absolute;
    margin-top: 8px;
	margin-left: 140px;
    border: 1px solid  rgba(204,204,204, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.reg_check_button_error {
    /*
    reg_check_button
    */
    height:57px;
    width: 188px;
    line-height:12px;
    margin-top: 1px;
	margin-left: 2px;
    border-spacing: 0px;
    border:1px solid #F00;
    background-color: rgba(204,204,204, 0.7);
    font-size: 0.90em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
     
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	text-align:left !important;
    vertical-align:middle !important;
    text-decoration:none;
    text-indent:5px;
}
.ls_form_register_info_button {
    height: 50px;
    width: 165px;
    line-height:33px;
    font-size: .75em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    background-color:rgba(255,255,255, 0.2);
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.register_input_table_full_top{
    height:74px !important;
    width:650px !important;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-spacing: 0px;
    font-size:16px;
    font-weight:700;
    text-align:left;
    line-height:25px !important;
    text-decoration:none;
    background-color: rgba(255,255,255, 0.7);
}
.register_input_table_full{
    height:47px !important;
    width:650px !important;
    border-spacing: 0px;
    /*
    border: 1px solid rgba(204,204,204, 0.2);
    
    font-size:16px;
    font-weight:700;
    */
    text-align:left;
    line-height:25px !important;
    text-decoration:none;
    background-color: rgba(255,255,255, 0.7);
}
.register_command_bar{
    height:60px !important;
    width:650px;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(239,239,239, 0.7);
}  
.register_command_bar_td1{
    height:60px;
    width:195px !important;
    line-height:12px;
    border-spacing: 0px;
    vertical-align:middle !important;
    border: 1px solid rgba(204,204,204, 0.2);
}
.register_command_bar_td2{
    height:60px;
    width:195px !important;
    line-height:12px;
    vertical-align:middle !important;
    text-align:center;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
}
.register_command_bar_td3{
    height:60px;
    width:144px !important;
    line-height:12px;
    vertical-align:middle !important;
    text-align:center;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
}
.register_command_bar_td4{
    height:60px;
    width:144px !important;
    line-height:12px;
    border-spacing: 0px;
    vertical-align:middle !important;
    text-align:center;
    border: 1px solid rgba(204,204,204, 0.2);
}
.register_command_bottom{
    height:170px;
    width:650px !important;
    line-height:12px;
    border-spacing: 0px;
    vertical-align:middle !important;
    text-align:center;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(255,255,255, 0.7);
}
.register_command_bottom_td{
    height:164px;
    width:648px !important;
    line-height:12px;
    margin-top: 3px;
	margin-left: 1px;
    vertical-align:middle !important;
    text-align:center;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(204,204,204, 0.7);
}
.business_register_command_bottom{
    height:14px;
    width:650px !important;
    line-height:8px;
    border-spacing: 0px;
    vertical-align:middle !important;
    text-align:center;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(255,255,255, 0.7);
}
.business_register_command_bottom_td{
    height:13px;
    width:648px !important;
    line-height:8px;
    margin-top: 3px;
	margin-left: 1px;
    vertical-align:middle !important;
    text-align:center;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(255,255,255, 0.7);
}
.settingts_field_message_table_body{
    height:60px !important;
    width:717px !important;
    line-height:60px !important;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-spacing: 0px;
    background:#FFF;
    font-size:16px;
    font-weight:700;
    text-align:left;
    text-decoration:none;
    margin:0;
    padding:0;
}

.settingts_message_td {
	/*
	ls_input_error_td
	ls_form_reset_button
	reset_submit_input_table_body
	border:1px solid rgba(204,204,204, 0.3);
	*/
    height:55px !important;
    width:717px !important;
    line-height:47px !important;
    vertical-align:bottom!important;
    
	border-right: hidden;
	border-left: hidden;
	border-top: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    padding:0;
    margin:0;
}
.settingts_message_error_td {
    height:52px !important;
    width:717px !important;
    line-height:47px !important;
    vertical-align:bottom!important;
    border:1px solid rgba(252, 71, 71, 0.3);
    border-spacing: 0px;
    padding:0;
    margin:0;
}
.settingts_field_table_body{
    height:135px !important;
    width:717px !important;
    display:inline-block;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settingts_field_table_body02{
    height:230px !important;
    width:717px !important;
    display:inline-block;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settingts_field_table_body03{
	/*
		display_bottom_td04
	*/
    height:315px !important;
    width:717px !important;
    display:inline-block;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settingts_field_table_body04{
    height:575px !important;
    width:717px !important;
    display:inline-block;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settingts_field_table_body07_1{
    height:180px !important;
    width:720px !important;
    display:inline-block;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    settingts_field_command_ln
    */
}
.settingts_field_table_body07_2{
    height:220px !important;
    width:720px !important;
    display:inline-block;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    settingts_field_command_ln
    */
}
.settingts_field_pop_count_ln {
    height:40px !important;
    width:717px !important;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.5);
    display:inline-block;
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.settingts_field_pop_count_ln_div1 {
    height:40px !important;
    width:650px !important;
    display: inline-block;
    margin-top: 0;
    margin-left: 0;
    position: absolute;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.settingts_field_pop_count_ln_error_div1 {
	/*
	registration_message_error_td
	
	*/
    height:37px !important;
    width:645px !important;
    display: inline-block;
    margin-top: 0;
    margin-left: 0;
    position: absolute;

   	border:1px solid rgba(252, 71, 71, 0.5);
    border-spacing: 0px;

    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.settingts_field_pop_count_ln_div2 {
    height:40px !important;
    width:70px !important;
    display: inline-block;

    margin-top: 0;
    margin-left: 648px;
    position: absolute;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*

*/
.settingts_field_pop_body {
    height:45px !important;
    width:546px !important;
	line-height: 45px;
    border-spacing: 0px;
    border:hidden;
    display:inline-block;

    vertical-align:middle !important;
    text-align:center !important;
	border-top:1px solid rgba(239,239,239, 0.7);
    border-bottom:1px solid rgba(239,239,239, 0.7);
	border-left:1px solid rgba(239,239,239, 0.0);
	border-right:1px solid rgba(239,239,239, 0.7);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settingts_field_pop_body02 {
    height:45px !important;
    width:549px !important;
	line-height: 45px;
    border-spacing: 0px;
    border:hidden;
    display:inline-block;

    vertical-align:top!important;
    text-align:center !important;
	border:hidden;
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.settingts_field_pop_body_small {
    height:25px !important;
    width:180.3px !important;
	line-height: 25px;
    border-spacing: 0px;
    border:hidden;
    vertical-align:top  !important;
    text-align:center !important;
	border-top:1px solid rgba(239,239,239, 0.7);
    border-bottom:1px solid rgba(239,239,239, 0.7);
	border-left:1px solid rgba(239,239,239, 0.0);
	border-right:1px solid rgba(239,239,239, 0.7);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.settingts_field_pop_instruction {
    height:45px !important;
    width: 160px  !important;
    line-height:25px !important;
    text-align:center !important;
    vertical-align:middle !important;
    text-indent:5px;
    border-spacing: 0px;
	border-top:1px solid rgba(239,239,239, 0.7);
    border-bottom:1px solid rgba(239,239,239, 0.7);
	border-left:1px solid rgba(239,239,239, 0.0);
	border-right:1px solid rgba(239,239,239, 0.7);
    background-color:rgba(255,255,255, 0.2);
}
.settingts_field_command_ln {
    height:45px !important;
    width:717px !important;
    border-spacing: 0px;
    border:hidden;
    display:inline-block;
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.settingts_field_command_ln_div1 {
    height:45px !important;
    width:585px !important;
    display: inline-block;
    margin-top: 0;
    margin-left: 0;
    position: absolute;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.settingts_field_command_ln_div2 {
    height:45px !important;
    width:122px !important;
	line-height: 45px;
    display:inline-block;
    margin-top: 0;
    margin-left: 585px;
    position: absolute;

    vertical-align:middle !important;
    text-align:center !important;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settingts_field_command_ln_div3 {
    height:45px !important;
    width:445px !important;
    display: inline-block;
    margin-top: 0;
    margin-left: 0;
    position: absolute;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

} 
.settingts_field_command_ln_div4 {
    height:45px !important;
    width:50px !important;
	line-height: 12px;
    display:inline-block;
    margin-top: 0;
    margin-left: 446px;
    position: absolute;

    vertical-align:middle !important;
    text-align:center !important;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settingts_field_command_ln_div5 {
    height:45px !important;
    width:104px !important;
	line-height: 12px;
    display:inline-block;
    margin-top: 0;
    margin-left: 500px;
    position: absolute;

    vertical-align:middle !important;
    text-align:center !important;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settingts_field_command_ln_div6 {
    height:45px !important;
    width:104px !important;
	line-height: 12px;
    display:inline-block;
    margin-top: 0;
    margin-left: 604px;
    position: absolute;

    vertical-align:middle !important;
    text-align:center !important;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.settingts_field_return{
	/*
	display_top_td03
	*/
    height:50px !important;
    width: 160px !important;
    line-height: 50px;


    display: inline-block;
	margin-top: 0px;

    margin-left: 2px;

    background-color:transparent;
    text-align:left !important;
    vertical-align:top!important;
}
/*
settingts_field_table_body07
*/
.settingts_field_table_body07_1td{
    height:180px !important;
    width:240px !important;
    margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    settingts_field_command_ln
    */
}

.settingts_temp_field1{
    height:177px !important;
    width: 236px !important;
    line-height: 12px;
    display: inline-block;
	margin-top: 1px;
    margin-left: 1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(255,255,255, 0.7);
    text-align:left !important;
    vertical-align:top!important;
}
.settingts_temp_field1_active{
    height:177px !important;
    width: 236px !important;
    line-height: 12px;
    display: inline-block;
	margin-top: 1px;
    margin-left: 1px;
    border-spacing: 0px;
    border: 1px solid rgba(10, 245, 92, 0.7);
    background-color:rgba(255,255,255, 0.7);
    text-align:left !important;
    vertical-align:top!important;
}

.settingts_temp_field2{
    height:177px !important;
    width: 236px !important;
    line-height: 12px;
    display: inline-block;
	margin-top: 1px;
    margin-left: 1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(255,255,255, 0.7);
    text-align:left !important;
    vertical-align:top!important;
}
.settingts_temp_field2_active{
    height:177px !important;
    width: 236px !important;
    line-height: 12px;
    display: inline-block;
	margin-top: 1px;
    margin-left: 1px;
    border-spacing: 0px;
    border: 1px solid rgba(10, 245, 92, 0.7);
    background-color:rgba(255,255,255, 0.7);
    text-align:left !important;
    vertical-align:top!important;
}

.settingts_temp_field3{
    height:177px !important;
    width: 236px !important;
    line-height: 12px;
    display: inline-block;
	margin-top: 1px;
    margin-left: 1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(255,255,255, 0.7);
    text-align:left !important;
    vertical-align:top!important;
}

.settingts_temp_field3_active{
    height:177px !important;
    width: 236px !important;
    line-height: 12px;
    display: inline-block;
	margin-top: 1px;
    margin-left: 1px;
    border-spacing: 0px;
    border: 1px solid rgba(10, 245, 92, 0.7);
    background-color:rgba(255,255,255, 0.7);
    text-align:left !important;
    vertical-align:top!important;
}

.settingts_field_table_body07_2td{
    height:220px !important;
    width:360px !important;
    margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    settingts_field_command_ln
    */
}

.settingts_dist_field4{
    height:217px !important;
    width: 355px !important;
    line-height: 12px;
    display: inline-block;
	margin-top: 1px;
    margin-left: 1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(255,255,255, 0.7);
    text-align:left !important;
    vertical-align:top!important;
}

.settingts_dist_field4_active{
    height:217px !important;
    width: 355px !important;
    line-height: 12px;
    display: inline-block;
	margin-top: 1px;
    margin-left: 1px;
    border-spacing: 0px;
    border: 1px solid rgba(10, 245, 92, 0.7);
    background-color:rgba(255,255,255, 0.7);
    text-align:left !important;
    vertical-align:top!important;
}

.settingts_dist_field5{
    height:217px !important;
    width: 355px !important;
    line-height: 12px;
    display: inline-block;
	margin-top: 1px;
    margin-left: 1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(255,255,255, 0.7);
    text-align:left !important;
    vertical-align:top!important;
}

.settingts_dist_field5_active{
    height:217px !important;
    width: 355px !important;
    line-height: 12px;
    display: inline-block;
	margin-top: 1px;
    margin-left: 1px;
    border-spacing: 0px;
    border: 1px solid rgba(10, 245, 92, 0.7);
    background-color:rgba(255,255,255, 0.7);
    text-align:left !important;
    vertical-align:top!important;
}
/* ################################################ */
.social_media_active_class_absolute{
	height:125px !important;
	width:185px;
    display: inline-block;
	margin-top:3px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_class_absolute{
	height:115px !important;
	width:178px;
    display: inline-block;
	margin-top:3px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_border_ACTIVE_class{
	border:2px solid rgba(10, 245, 92, 0.7) !important;
}
.social_media_option_DEFAULT_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 70px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_ACTIVE_class{
	z-index: 10;
    height:111px; 
    width:25px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:155px;
	position: absolute;
	vertical-align:top;
	text-align:center;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(10, 245, 92, 0.7);
	background-position:center; 
    background-size: 25px 111px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.social_select_media_metrics {
	z-index: 10;
	height:45px !important;
	width:50px;
	position: absolute;
	display: inline-block;
	margin-top:2px;
	margin-left:130px;
	vertical-align:top;
	text-align:left;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background: url(../images/buttons/sys_option_correct_active.png) rgba(255,255,255, 0.0);
	background-size: 35px 35px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
social_select_profile_pic
social_media_class_absolute
*/
/*******************************/
.social_select_media_REMOVE {
	z-index: 10;
	height:40px !important;
	width:40px;
	position: absolute;
	display: inline-block;
	margin-top:2px;
	margin-left:140px;
	vertical-align:top;
	text-align:left;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(252, 71, 71, 0.7);
	background-size: 35px 35px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/******************************social_media_class_absolute*/
.social_select_media_PLINK {
	z-index: 10;
	height:40px !important;
	width:40px;
	position: absolute;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
	background-size: 35px 35px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: .90em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    vertical-align:center;
	text-align:middle;
}
.social_select_media_PSHARE {
	z-index: 10;
	height:40px !important;
	width:40px;
	position: absolute;
	display: inline-block;
	margin-top:43px;
	margin-left:1px;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
	background-size: 35px 35px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: .90em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    vertical-align:center;
	text-align:middle;
}


.social_media_select_media_PLINK {
	z-index: 10;
	height:40px !important;
	width:40px;
	position: absolute;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
	background-size: 35px 35px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: .90em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    vertical-align:center;
	text-align:middle;
}
.social_media_select_media_PSHARE {
	z-index: 10;
	height:40px !important;
	width:40px;
	position: absolute;
	display: inline-block;
	margin-top:43px;
	margin-left:1px;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
	background-size: 35px 35px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: .90em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    vertical-align:center;
	text-align:middle;
}
/*******************************/
/*******************************/
.social_select_profile_pic {
	z-index: 10;
	height:40px !important;
	width:40px;
	position: absolute;
	display: inline-block;
	margin-top:84px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
	background-size: 35px 35px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_select_profile_ptitle {
	z-index: 21;
	height:40px !important;
	width:140px;
	position: absolute;
	display: inline-block;
	margin-top:84px;
	margin-left:42px;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.9);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0); 
	background-size: 115px 35px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: .90em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    vertical-align:top;
	text-align:left;
}
.social_select_media_order {
	z-index: 10;
	height:45px !important;
	width:50px;
	position: absolute;
	display: inline-block;
	margin-top:2px;
	margin-left:2px;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.0);
	background-size: 35px 35px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.90em  !important;
    font-weight: 500;
    vertical-align:middle;
    text-align:center;
	text-indent: 0px;
}

.social_select_media_title{
	z-index: 10;
	height:35px !important;
	width:160px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:42px;
	margin-left:10px;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.05em  !important;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
	text-indent: 0px;
   
}
/* ################################################ */
.social_media_configuration_table_01{
    height:310px !important;
    width:950px !important;
	display:inline-block;
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.social_media_configuration_table_02{
    height:265px !important;
    width:950px !important;
	display:inline-block;
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(90,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.social_media_configuration_class_absolute{
	z-index: 10;
   	height:100px; 
    width:132px;
    line-height:12px  !important;
    border-spacing: 0px;
    border:hidden;
    display:inline-grid;
    margin-top:2px !important;
    margin-left:3px !important;
    vertical-align:top !important;
    text-align:center;
    background:rgba(239,239,239, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.social_media_configuration_DEFAULT_class{
   	height:100px; 
    width:132px;
	line-height: 12px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
	border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
	background-position: 5% 45%;
    background-size: 70px 70px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_configuration_TITLE{
	z-index: 12;
    height:30px; 
    width:130px;
	line-height: 20px;
	display:absolute;
	margin-top:50px;
    margin-left:1px;
	position: absolute;
	border: 1px solid rgba(204,204,204, 0.0);
    background-color: rgba(255,255,255, 0.2);
	background-position:center; 
    background-size: 30px 30px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
    font-size: 1.05em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
    text-indent:5px;
}
.social_media_configuration_ACTIVE{
	z-index: 10;
    height:98px; 
    width:20px;
	line-height: 20px;
	display:absolute;
	margin-top:2px;
    margin-left:110px;
	position: absolute;
	vertical-align:top;
	text-align:center;
	border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(10, 245, 92, 0.7);
	background-position:center; 
    background-size: 30px 30px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_configuration_REMOVE{
	z-index: 15;
    height:30px; 
    width:30px;
	line-height: 20px;
	display:absolute;
	margin-top:70px;
    margin-left:100px;
	position: absolute;
	vertical-align:top;
	text-align:center;
	border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(252, 71, 71, 0.7);
	background-position:center; 
    background-size: 30px 30px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/* ################################################ */
.social_media_field_table_body{
    height:190px !important;
    width:717px !important;
	display:inline-block;
	margin-top: 10px;
    margin-left: 0;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.7);
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.social_media_ln_div1 {
    height:40px !important;
    width:500px !important;
    display: inline-block;
    margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: hidden;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.social_media_ln_div1_2 {
    height:170px !important;
    width:210px !important;
    display: inline-block;
    margin-top: 0px;
    margin-left: 0px;
	vertical-align: top;
	text-align: center;
    border-spacing: 0px;
   	border:1px solid rgba(239,239,239, 0.0);
	background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.social_media_ln_error_div1 {
    height:37px !important;
    width:457px !important;
    display: inline-block;
    margin-top: 0px;
    margin-left: 0px;
   	border:1px solid rgba(252, 71, 71, 0.5);
    border-spacing: 0px;

    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.social_media_field_body {
    height:90px !important; 
    width:500px !important;
    border:hidden;
    display:inline-block;
    vertical-align:top!important;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.social_media_ln_div2_1 {
    height:40px !important;
    width:364px !important;
    display: inline-block;
    margin-top: 2px;
    margin-left: 0px;
	vertical-align: top;
	text-align: right;
    border-spacing: 0px;
    border:hidden;
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.social_media_ln_div2_2 {
    height:40px !important;
    width:132px !important;
    display: inline-block;
    margin-top: 2px;
    margin-left: 0px;
	vertical-align: top;
	text-align: center;
    border-spacing: 0px;
    border:hidden;
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.social_media_form_full_field{
  	height:85px !important;
    width: 99%;
    line-height:20px !important;
	background:rgba(238,238,238, 0.5);
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
	/*
    font-size: .85em;
    font-weight: 500;
    */
}
.social_media_search_window {
    height:166px !important;
    width:206px !important;
    display: inline-block;
    margin-top: 2px;
    margin-left: 2px;
	vertical-align: top;
	text-align: center;
    border-spacing: 0px;
   	border:1px solid rgba(239,239,239, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-position:center; 
    background-size: 180px 150px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

.social_media_form_approved{
  	height:35px !important;
    width: 35px;
	position: absolute;
	margin-top: 5px;
	margin-left: 170px;
	vertical-align: text-top;
	text-align: center;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.8);
	background-position:center; 
    background-size: 30px 30px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.social_media_form_pending{
  	height:35px !important;
    width: 35px;
	position: absolute;
	margin-top: 5px;
	margin-left: 170px;
	vertical-align: text-top;
	text-align: center;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.8);
	background-position:center; 
    background-size: 30px 30px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/* ################################################ */
.temp_share_div {
	z-index: 400;
	height:50px !important;
	
	width:100px !important;
	vertical-align:middle;
	position: absolute;
	border:hidden;
	margin-top: 20px;
	margin-left: 400px;
	background-color: rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_share_profile {
    height:50px !important;
    width:100px !important;
    line-height: 12px;
    display: inline-block;
    margin-top: 2px;
    margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-repeat: no-repeat;
    background-position: 95% 45%; 
    background-size: 30px 30px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
	text-align: left;
    text-indent:10px;
}
/*
social_share_control_submit
*/
.social_share_command_line {
    height:60px !important;
    width: 948px;
    border-spacing: 0px;
    margin-top:0px;
    margin-left:1px;
    border:1px solid rgba(233,233,233, 0.9);
    background-color: rgba(239,239,239, 0.7);
    vertical-align: top;
    text-align:left !important;
}
.social_share_command_line_td1 {
    height:60px !important;
    width: 548px;
    border-spacing: 0px;
    margin-top:0px;
    margin-left:1px;
    border:hidden;
    background-color: rgba(239,239,239, 0.0);
    vertical-align: top;
    text-align:left !important;
}
.social_share_command_line_td2 {
    height:60px !important;
    width: 548px;
    border-spacing: 0px;
    margin-top:0px;
    margin-left:1px;
    border:hidden;
    background-color: rgba(239,239,239, 0.0);
    vertical-align: top;
    text-align:left !important;
}
.social_share_options_td {
    height:100px !important;
    width: 948px;
    border-spacing: 0px;
    margin-top:0px;
    margin-left:1px;
    border:1px solid rgba(233,233,233, 0.9);
    background-color: rgba(255,255,255, 0.7);
    vertical-align: top;
    text-align:left !important;
}

.social_share_command_title {
    height:55px !important;
    width:400px !important;
    line-height: 12px;
    display: inline-block;
    margin-top: 2px;
    margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
	background-repeat: no-repeat;
    background-position: 95% 45%; 
    background-size: 30px 30px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.45em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-decoration:none;
    text-align: left;
    text-indent:10px;
}
.social_share_table_td1_1 {
    height:406px !important;
    width: 375px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(255,255,255, 0.7);
    vertical-align: top;
    text-align:left !important;
}
.social_share_control_image {
    height:160px !important;
    width: 370px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(255,255,255, 0.7);
    vertical-align: top;
    text-align:left !important;
}
.social_share_control_switch {
    z-index: 1;
    height:30px !important;
    width: 30px !important;
    position: absolute; 
    margin-top:8px;
    margin-left:325px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
	background-repeat: no-repeat;
    background-position: center; 
    background-size: 20px 20px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align:center !important;
}
.social_share_control_view {
    height:158px !important;
    width: 350px !important;
    margin-top:5px;
    margin-left:10px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.7);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align:center !important;
}
/*
social_share_command_line
*/
.social_share_tags_absolute {
    height:30px !important;
    /*
    height:140px !important;
    width:234px;
	width:228px;
    */
	position: relative;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
    display: inline-block;
	top: 80px;
    margin-top:2px;
    margin-left:3px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.social_share_label{
    height:30px !important;
	/*
	feed_container_top_bar_td1
    width:70px !important;
	*/
    line-height:20px;
    border:hidden; 
    vertical-align:middle !important;
    text-align:center !important;
    /*
    position: absolute;
    */
    margin-top: 0px;
    margin-left: 2px;
    background-color:azure;
    border:3px solid #FFF;
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

}
.social_share_control_tags {
    height:75px !important;
    width: 370px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(255,255,255, 0.7);
    vertical-align: top;
    text-align:left !important;
}
.social_share_control_text {
    height:165px !important;
    width: 370px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(255,255,255, 0.7);
    vertical-align: top;
    text-align:left !important;
}

.social_share_control_submit_default {
    height:50px !important;
    width:130px !important;
    line-height:12px !important;
    margin-top: 5px;
	margin-left: 350px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
	background-repeat: no-repeat;
    background-position:90% 45%;
    background-size: 40px 40px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.social_share_control_submit_active {
    height:50px !important;
    width:130px !important;
    line-height:12px !important;
    margin-top: 5px;
	margin-left: 350px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/butt_template.png) rgba(11,183,255, 0.7);
	background-repeat: no-repeat;
    background-position:90% 45%;
    background-size: 40px 40px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.social_share_control_count_div{
    height:25px !important;
    width: 50px;
    position:absolute;
    margin-top: 2px;
    margin-left: 318px;
    line-height:20px !important;
    border-spacing: 0px;
    border:hidden;
    text-align:center;
    vertical-align:top;
    text-decoration:none;
}
.social_share_control_count_field{
    height:25px !important;
    width: 50px;
    line-height:20px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
.social_share_table_td1_2 {
    height:406px !important;
    width: 572px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color: rgba(255,255,255, 0.7);
    vertical-align: top;
    text-align:left !important;
}
.social_share_class_absolute{
	height:127px !important;
	width:184px;
    display: inline-block;
	margin-top:10px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.social_share_frame{
	z-index: 1;
   	height:127px !important;
	width:184px;
    line-height:12px  !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.social_share_page_frame{
	z-index: 1;
   	height:155px !important;
	width:161px;
    line-height:12px  !important;
    position:absolute;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.social_share_page_background{
	height:175px !important;
	width:220px;
    display: inline-block;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color: rgba(239,239,239, 0.7);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_share_page_title{
	z-index: 1;
   	height:50px !important;
	width:165px;
    line-height:12px  !important;
    position:absolute;
    margin-top:120px;
	margin-left:2px;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.2);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.4);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
}
.social_share_page_frame_top{
	z-index: 1;
   	height:85px !important;
	width:65px;
    line-height:12px  !important;
    position:absolute;
    margin-top:1px;
	margin-left:155px;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.social_share_page_frame_bottom{
	z-index: 1;
   	height:85px !important;
	width:65px;
    line-height:12px  !important;
    position:absolute;
    margin-top:86px;
	margin-left:155px;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.social_share_page_left {
	height: 600px;
	width: 15px !important;
	position:absolute;
	margin-top: 0px;
    margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(204,204,204, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_share_page_right {
	height: 600px;
	width: 15px !important;
	position:absolute;
	margin-top: 0px;
    margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(204,204,204, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.social_share_page_left_active{
    z-index:10;
	height: 600px;
	width: 45px !important;
	position:absolute;
	margin-top: 0px;
    margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_share_page_right_active{
    z-index:10;
	height: 600px;
	width: 45px !important;
	position:absolute;
	margin-top: 0px;
    margin-left:-35px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/* ################################################ */
/* ######              LANDSCAPE_FORGOT_PASS  ##### */	
	

.reset_input_table_body{
    height:40px !important;
    width:640px !important;
    line-height:12px !important;
    margin-left:5px;
    border-spacing: 0px;
    border: hidden;
    background:rgba(239,239,239, 0.7);
}
.reset_submit_input_table_body{
    height:90px !important;
    width:648px !important;
    line-height:20px !important;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-spacing: 0px;
    background:#FFF;

    margin-left:5px;
    padding:0;
    /*
display_space_table_bottom07
    
    */
}
.reset_input_table_full{
    height:47px !important;
    width:640px !important;
    line-height:12px !important;
    margin-left:5px;
    text-align:left;
    text-decoration:none;
    background:rgba(255,255,255, 0.7);
}
.reset_form_label {
    height:47px !important;
    width:144px;
    line-height:12px !important;
    font-size: .90em;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255,255,255, 0.7);
    
}
.reset_form_full_table {
    height:47px !important;
    width: 305px !important;
    line-height:12px;
    vertical-align: middle;
    text-align: center;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255,255,255, 0.5);
}
.reset_form_full_field{
    height:35px !important;
    width: 98%;
    line-height:20px !important;
    border-spacing: 0px;
    border:hidden;
    text-indent:5px;
    background-color:rgba(238,238,238, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }
.reset_form_full_instruction {
    height:47px !important;
    width: 170px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
    background:rgba(255,255,255, 0.5)
}
.reset_input_table_body_td1{
    height:165px !important;
    width:640px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border:hidden;
    font-size:16px;
    font-weight:700;
    text-align:left;
    text-indent:5px;
    text-decoration:none;
    background:rgba(255,255,255, 0.7)
}
.reset_error_td {
    height:47px !important;
    width:640px !important;
    line-height:12px !important;
    vertical-align:bottom!important;
    border-spacing: 0px;
    border:hidden;
    /*
    
    reset_input_table_body
    */
}
	
.reset_form_command_bar{
    height:60px !important;
    width:640px !important;
    line-height:12px !important;
    margin-left:5px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.2);
    background:rgba(239,239,239, 0.7);
}  
.reset_form_command_bar_td1{
    height:60px !important;
    width:330px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.2);
}
.reset_form_command_bar_td2{
    height:60px !important;
    width:185px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.2);
}
.reset_form_command_bar_td3{
    height:60px !important;
    width:130px;
    line-height:41px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.2);
}

	.reset_submit_input_table_body_td1{
		height:71px !important;
		width:650px !important;
		line-height:71px !important;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom:hidden;
		border-spacing: 0px;
		background:#FFF;
		font-size:16px;
		font-weight:700;
		text-align:left;
		text-indent:5px;
		text-decoration:none;
		margin:0;
		padding:0;
	}
	.reset_submit_error_td {
		height:47px !important;
		width:650px !important;
		line-height:47px !important;
		vertical-align:middle !important;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
		border-spacing: 0px;
		padding:0;
		margin:0;
	}
/*
registration_message_td
reset_form_full_instruction
notificationsystem_window_table_td_footer
registration_message_error_td
*/
.confirm_form_command_bar{
    height:60px !important;
    width:640px !important;
    line-height:12px !important;
    margin-left:5px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.2);
    background:rgba(239,239,239, 0.7);
}  
.confirm_form_command_bar_td1{
    height:60px !important;
    width:330px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border-top:1px solid rgba(204,204,204, 0.2);
    border-bottom:1px solid rgba(204,204,204, 0.2);
    border-left:1px solid rgba(204,204,204, 0.2);
    border-right:hidden;
}
.confirm_form_command_bar_td2{
    height:60px !important;
    width:185px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border-top:1px solid rgba(204,204,204, 0.2);
    border-bottom:1px solid rgba(204,204,204, 0.2);
    border-left:hidden;
    border-right:hidden;
}
.confirm_form_command_bar_td3{
    height:60px !important;
    width:130px;
    line-height:41px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border-top:1px solid rgba(204,204,204, 0.2);
    border-bottom:1px solid rgba(204,204,204, 0.2);
    border-left:1px solid rgba(204,204,204, 0.2);
    border-right:1px solid rgba(204,204,204, 0.2);
}
.confirm_form_command_bar_td1_2{
    height:60px !important;
    width:330px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border-top:1px solid rgba(204,204,204, 0.2);
    border-bottom:1px solid rgba(204,204,204, 0.2);
    border-left:1px solid rgba(204,204,204, 0.2);
    border-right:hidden;
}
.confirm_form_command_bar_td2_2{
    height:60px !important;
    width:130px;
    line-height:41px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border-top:1px solid rgba(204,204,204, 0.2);
    border-bottom:1px solid rgba(204,204,204, 0.2);
    border-left:hidden;
    border-right:hidden;
}
.confirm_form_command_bar_td3_2{
    height:60px !important;
    width:185px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border-top:1px solid rgba(204,204,204, 0.2);
    border-bottom:1px solid rgba(204,204,204, 0.2);
    border-left:1px solid rgba(204,204,204, 0.2);
    border-right:1px solid rgba(204,204,204, 0.2);
}
/* ################################################ */
/* ######        LANDSCAPE_NOTIFICATION_ADV   ##### */	
.notify_table_body_main{
    /*min-height:200px;
    max-height:300px; */
    height:205px !important;
    background-color:#FFF;
    position: relative; 
    border-right: hidden;
    border-left: hidden;
    border-top: 1px solid #ccc;
    border-bottom: hidden;
}
.notify_table_body{
    width:650px;
    background:#FFF;
    position: absolute; 
    bottom: 0; 
}
.notify_table_td1{
    height:205px !important;
    width:355px;
    line-height:205px !important;
    border-right: hidden;
    border-left: hidden;
    border-top: 1px solid #ccc;
    border-bottom: hidden;
    border-spacing: 0px;
    background:#FFF;
    font-size:16px;
    font-weight:700;
    text-align:left;
    text-indent:5px;
    text-decoration:none;
    margin:0;
    padding:0;
}
.notify_table_td2{
    height:205px !important;
    width:355px;
    line-height:205px !important;
    border-right: hidden;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: hidden;
    border-spacing: 0px;
    background:#FFF;
    font-size:16px;
    font-weight:700;
    text-align:left;
    text-indent:5px;
    text-decoration:none;
    margin:0;
    padding:0;
}
.notification_table_body{
    height:195px !important;
    width:650px !important;
    line-height:12px !important;
    background:rgba(255,255,255, 0.3);
    margin:0;

}
.notification_table_td{
    height:300px;
    width:650px !important;
    line-height:300px !important;
    background:#FFF;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;
    border-spacing: 0px;
    background:#FFF;
    font-size:16px;
    font-weight:700;
    text-align:left;
    text-indent:5px;
    text-decoration:none;
}
.notification_table_body02{
    height:150px !important;
    width:650px !important;
    line-height:12px !important;
    background:rgba(255,255,255, 0.3);
    margin:0;
}
/* ################################################ */
/* ######    LANDSCAPE_ACCOUNT_STATUS         ##### */

	
	.account_general_table_td1{
		height:390px !important;
		width:718px !important;
		line-height:390px !important;
		background:#FFF;
		border:hidden;
		text-align:center;
		vertical-align:middle;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.account_general_table_td1_top{
		height:150px !important;
		width:718px !important;
		background:#FFF;
		border: 1px solid #ccc;
		text-align:center;
		vertical-align:middle;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.account_general_table_td1_top_options1{
		height:50px !important;
		width:548px !important;
		background:#FFF;
		border:hidden;
		text-align:center;
		vertical-align:middle;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.account_general_table_td1_top_options2{
		height:50px !important;
		width:170px !important;
		background:#FFF;
		border:hidden;
		text-align:center;
		vertical-align:middle;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.account_general_table_td1_top_options3{
		height:100px !important;
		width:359px !important;
		background:#FFF;
		border:hidden;
		text-align:center;
		vertical-align:middle;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.account_general_table_td1_top_options4{
		height:100px !important;
		width:359px !important;
		background:#FFF;
		border:hidden;
		text-align:center;
		vertical-align:middle;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.account_general_table_td1_bottom{
		height:66px !important;
		width:718px !important;
		line-height:66px !important;
		background:#FFF;
		border: 1px solid #ccc;
		text-align:center;
		vertical-align:middle;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.account_general_table_td1_bottom1{
		height:50px !important;
		width:578px !important;
		background:#FFF;
		border:hidden;
		text-align:center;
		vertical-align:middle;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.account_general_table_td1_bottom2{
		height:50px !important;
		width:140px !important;
		background:#FFF;
		border:hidden;
		text-align:center;
		vertical-align:middle;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.account_general_table_td2{
		height:127px !important;
		width:718px !important;
		line-height:390px !important;
		background:#CCC;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/************************************/
.account_main_table{
    height:125px !important;
    width:710px !important;
    line-height:125px !important;
    display:inline-block;
    text-align:center;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************************/
.account_main_table_b_row1{
    height:131px !important;
    width:710px !important;
    display:inline-block;
    text-align:center;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_main_table_b_row1_01{
    height:129px !important;
    width:300px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_main_table_b_row1_01_01{
    height:30px !important;
    width:300px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_main_table_b_row1_01_02{
    height:47px !important;
    width:300px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_main_table_b_row1_01_03{
    height:47px !important;
    width:300px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************************/
/*
account_main_table_b_row1_02
account_main_table_b_row1_01
account_main_table_b_row1_02_02
*/
.account_main_table_b_row1_02_full{
    height:129px !important;
    width:405px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.0);
    background: rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_main_table_b_row1_02_01A{
    height:55px !important;
    width:120px !important;
    line-height:12px;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
    background-color: rgba(239,239,45, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_main_social_label {
	height:53px !important;
    width:117px !important;
    line-height:12px;
    margin-top:1px;
    margin-left:1px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 500;
	/*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    text-align:center;
    vertical-align: middle;
    text-decoration:none;
    text-indent:0px;
}
.account_main_table_b_row1_02_01B{
    height:55px !important;
    width:285px !important;
    display:inline-block;
    text-align:center;
	vertical-align: middle;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.7);
    background: rgba(239,239,239, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_main_table_b_row1_02_02A{
    height:129px !important;
    width:201px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
form_settings_config_empty
*/
.social_media_account_button {
    height:127px !important;
    width:199px !important;
    line-height:12px;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.5);
    background:rgba(255,255,255, 0.9);
	font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_main_table_b_row1_02_02B{
    height:129px !important;
    width:201px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.search_engine_index_account_button {
    height:127px !important;
    width:199px !important;
    line-height:12px;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.5);
    background:rgba(255,255,255, 0.9);
	font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************************/
.account_main_table_b_row2{
    height:171px !important;
    width:710px !important;
    display:inline-block;
    text-align:center;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************************/
.account_main_table_b_row2_1{
    height:169px !important;
    width:244px !important;
    display:inline-block;
    text-align:center;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

/************************************/
.account_main_table_b_row2_2{
    height:169px !important;
    width:460px !important;
    display:inline-block;
    text-align:center;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_main_table_b_row2_2_1{
    height:30px !important;
    width:152px !important;
    display:inline-block;
    text-align:center;
    border:1px solid rgba(239,239,239, 0.7);
    background: rgba(239,239,239, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_main_table_b_row2_2_2{
    height:135px !important;
    width:152px !important;
    display:inline-block;
    text-align:center;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}



/************************************/
.account_main_type{
    height:124px !important;
    width:196px !important;
    line-height:12px !important;
    background:rgba(255,255,255, 0.9);
    border:1px solid rgba(239,239,239, 0.7);
    margin:0;
    padding:0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
/*
account_form_purchase_button


*/
}
.account_main_description{
    height:124px !important;
    width:514px !important;
    line-height:12px !important;
    background:rgba(255,255,255, 0.9);
    border:1px solid rgba(239,239,239, 0.7);
    margin:0;
    padding:0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

}
.account_main_description_td01{
    height:124px !important;
    width:387px !important;
    line-height:12px !important;
    background:rgba(255,255,255, 0.9);
    border:0px solid rgba(239,239,239, 0.0);
	vertical-align: top;
	text-align: left;
    margin:0;
    padding:0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

}

.account_main_description_td02{
    height: 124px !important;
    width: 125px !important;
    line-height:12px !important;
    background:rgba(255,255,255, 0.9);
    border:0px solid rgba(239,239,239, 0.0);
	vertical-align: top;
	text-align: left;
    margin:0;
    padding:0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

}
.account_form_purchase_button {
    height: 120px;
    width: 383px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 1px;
    background:rgba(255,255,255, 0.9);
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
	font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_form_upgrade_button {
    height: 120px;
    width: 120px;
    line-height:18px;
	margin-top: 2px;
	margin-left: 1px;
    background:rgba(255,255,255, 0.9);
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.7);
	font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
	
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/***************************************/
.account_form_purchase_frame_absolute {
	height:40px !important;
	width:383px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(233,233,233, 0.2);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_form_purchase_option1 {
    height: 37px;
    width: 80px;
    line-height:12px;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background:rgba(255,255,255, 0.9);
	font-size: .65em;
    font-weight: 500;
    text-align:left;
	vertical-align: text-top;
    text-decoration:none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_form_purchase_option2 {
    height: 37px;
    width: 120px;
    line-height:12px;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background:rgba(255,255,255, 0.9);   
	font-size: .65em;
    font-weight: 500;
    text-align:left;
	vertical-align: text-top;
    text-decoration:none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_form_purchase_option3 {
    height: 37px;
    width: 165px;
    line-height:12px;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background:rgba(255,255,255, 0.9);
	font-size: .65em;
    font-weight: 500;
    text-align:left;
	vertical-align: text-top;
    text-decoration:none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/***************************************/
.account_silver_type{
    height:124px !important;
    width:196px !important;
    line-height:124px !important;
    border-spacing:0;
    border:hidden;
    margin:0;
    padding:0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_default.png);
    background-size: 124px 124px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
}
/*
select_account_table

*/
.account_silver_type_active{
    height:124px !important;
    width:196px !important;
    line-height:124px !important;
    border-spacing:0;
    border:hidden;
    margin:0;
    padding:0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_silver.png);
    background-size: 124px 124px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
}
.account_gold_type{
    height:124px !important;
    width:196px !important;
    line-height:124px !important;
    border-spacing:0;
    border:hidden;
    margin:0;
    padding:0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_default.png);
    background-size: 124px 124px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
}
.account_gold_type_active{
    height:124px !important;
    width:196px !important;
    line-height:124px !important;
    border-spacing:0;
    border:hidden;
    margin:0;
    padding:0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_gold.png);
    background-size: 124px 124px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
}
.account_platinum_type{
    height:124px !important;
    width:196px !important;
    line-height:124px !important;
    border-spacing:0;
    border:hidden;
    margin:0;
    padding:0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_default.png);
    background-size: 124px 124px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
}
.account_platinum_type_active{
    height:124px !important;
    width:196px !important;
    line-height:124px !important;
    border-spacing:0;
    border:hidden;
    margin:0;
    padding:0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_platinum.png);
    background-size: 124px 124px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
}
	/*
	
	
	*/
.ls_set-opt_account_button {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_account_button_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_admgmt_button {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.ls_set-opt_admgmt_button_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_metrics_button_default_default {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.5);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_metrics_button_default_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.ls_set-opt_metrics_button_active_default {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.ls_set-opt_metrics_button_active_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;  
}
.ls_set-opt_bio_button_default_default  {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_bio_button_default_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;  
}
.ls_set-opt_bio_button_active_default {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_bio_button_active_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.ls_set-opt_geo_button_default_default {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_geo_button_default_active  {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.ls_set-opt_geo_button_active_default {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_geo_button_active_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.ls_set-opt_socio_button_default_default {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_socio_button_default_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.ls_set-opt_socio_button_active_default {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_socio_button_active_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;  
}

.ls_set-opt_default_button {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size:14px;
    font-weight:600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_default_button_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size:14px;
    font-weight:600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
ls_set-opt_general_button
background-color:#EFEFEF;
*/
.ls_set-opt_general_button {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_general_button_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ls_set-opt_pic_button {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_pic_button_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ls_set-opt_pin_button {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
   border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_pin_button_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ls_set-opt_security_button {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.ls_set-opt_security_button_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ls_set-opt_notifications_button {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.8);
    background-color:rgba(255,255,255, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_notifications_button_active {
    height: 30px !important;
    width: 113px !important;
    line-height:30px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(204,204,204, 0.7); 
	/*
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	*/
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/* ################################################ */
/* ################################################ */
.select_account_table_header01{
    height:90px  !important;
    width:237px  !important;
    text-align: left;
	vertical-align:top;
    border-spacing: 0px;
	border:0px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.select_account_table_header02{
    height:90px  !important;
    width:503px  !important;
    text-align: left;
	vertical-align:top;
    border-spacing: 0px;
	border:0px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.select_account_table_header03{
    height:90px  !important;
    width:210px  !important;
    text-align: left;
	vertical-align:top;
    border-spacing: 0px;
	border:0px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.select_account_table_header_monthly_default{
    height:50px  !important;
    width:100px  !important;
    border:1px solid rgba(233,233,233, 0.3);
    /*
    background-color: rgba(233,233,233, 0.7);
    */
    
    box-shadow: none !important;
    background: url(../images/buttons/clear_img.png) rgba(233,233,233, 0.7);
    background-size: 90px 40px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	vertical-align: middle;
	text-align:center;
    text-decoration:none;
	text-indent: 0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.20em;
    font-weight: 600;
}
.select_account_table_header_monthly_active{
    height:50px  !important;
    width:100px  !important;
    border:1px solid rgba(233,233,233, 0.3);
    /*
    background-color: rgba(10, 245, 92, 0.7);
    */
    
    box-shadow: none !important;
    background: url(../images/buttons/clear_img.png) rgba(4, 144, 242, 0.7) ;
    background-size: 90px 40px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	vertical-align: middle;
	text-align:center;
    text-decoration:none;
	text-indent: 0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.20em;
    font-weight: 600;
}
.select_account_table_header_yearly_default{
    height:50px  !important;
    width:100px  !important;
    border:1px solid rgba(233,233,233, 0.3);
    /*
    background-color: rgba(233,233,233, 0.7);
    */
    
    box-shadow: none !important;
    background: url(../images/buttons/clear_img.png) rgba(233,233,233, 0.7);
    background-size: 90px 40px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	vertical-align: middle;
	text-align:center;
    text-decoration:none;
	text-indent: 0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.20em;
    font-weight: 600;
}
.select_account_table_header_yearly_active{
    height:50px  !important;
    width:100px  !important;
    border:1px solid rgba(233,233,233, 0.3);
    /*
    background-color: rgba(10, 245, 92, 0.7);
    */
    
    box-shadow: none !important;
    background: url(../images/buttons/clear_img.png) rgba(4, 144, 242, 0.7) ;
    background-size: 90px 40px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	vertical-align: middle;
	text-align:center;
    text-decoration:none;
	text-indent: 0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.20em;
    font-weight: 600;
}

.select_account_table_header_silver{
    height:90px  !important;
    width:237px  !important;
    border:1px solid rgba(233,233,233, 0.3);
    background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_silver.png);
    background-size: 90px 90px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 50px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.20em;
    font-weight: 600;
}
.select_account_table_header_gold{
   	height:90px  !important;
    width:237px  !important;
    border:1px solid rgba(233,233,233, 0.3);
    background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_gold.png);
    background-size: 90px 90px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 50px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.20em;
    font-weight: 600;
}
.select_account_table_header_platinum{
    height:90px  !important;
    width:237px  !important;
    border:1px solid rgba(233,233,233, 0.3);
    background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_platinum.png);
    background-size: 90px 90px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 50px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.20em;
    font-weight: 600;
}
.bs_select_account_table_header_silver{
    height:90px  !important;
    width:237px  !important;
    border:1px solid rgba(233,233,233, 0.3);
    background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_silver.png);
    background-size: 90px 90px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 20px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.10em;
    font-weight: 600;
}
.bs_select_account_table_header_gold{
   	height:90px  !important;
    width:237px  !important;
    border:1px solid rgba(233,233,233, 0.3);
    background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_gold.png);
    background-size: 90px 90px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 20px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	ls_validate_button01_silver
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.10em;
    font-weight: 600;
}
.bs_select_account_table_header_platinum{
    height:90px  !important;
    width:237px  !important;
    border:1px solid rgba(233,233,233, 0.3);
    background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/account_type_platinum.png);
    background-size: 90px 90px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 20px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.10em;
    font-weight: 600;
}

.select_account_form_messagedisplayinput_field{
    height:90px  !important;
    width: 99%;
    /*
    width:237px  !important;
    */
    line-height:12px !important;
    margin-top:0px;
    margin-left:2px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
/*******************/
.select_account_label_table{
    height:10px;
    width:950px !important;
	text-align: left;
	vertical-align:top;
    border-spacing: 0px;
	border:0px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.select_account_label_table_td01{
    height:10px;
    width:350px !important;
	text-align: left;
	vertical-align:top;
    border-spacing: 0px;
	border:0px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.select_account_label_table_td02{
    height:10px;
    width:600px !important;
	text-align: left;
	vertical-align:top;
    border-spacing: 0px;
	border:0px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************/
.select_account_table{
    height:430px;
    width:950px !important;
	text-align: left;
	vertical-align:top;
    border-spacing: 0px;
	border:0px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.select_account_table_td_active{
    /*
    select_account_table_header03
    */
    border-spacing: 0px !important;
	border:2px solid rgba(4, 144, 242, 0.7) !important;
}
.select_account_table_td01{
    height:430px; 
    width:314px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.select_account_table_td02{
    height:430px; 
    width:314px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.select_account_table_td03{
    height:430px; 
    width:314px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
.security_blockedusers_table_td2
ls_select_button_action
*/

.ls_select_button01_silver {
    height: 120px;
    width: 310px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_silver.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.ls_select_button01_gold {
    height: 120px;
    width: 310px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_gold.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.ls_select_button01_platinum {
    height: 120px;
    width: 310px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_platinum.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.bs_select_button01_silver {
    height: 120px;
    width: 314px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 0px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_silver.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	bs_validate_button01_platinum
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.10em;
    font-weight: 600;
}
.bs_select_button01_gold {
    height: 120px;
    width: 314px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 0px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_gold.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	select_account_table_header_silver
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.10em;
    font-weight: 600;
}
.bs_select_button01_platinum {
    height: 120px;
    width: 314px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 0px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_platinum.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.10em;
    font-weight: 600;
}
.ls_select_button02 {
    height: 80px;
    width: 300px;
    line-height:20px;
	margin-top: 2px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.2);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.5);
    background-position:1% 45%; 
    background-size: 90px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.00em;
    font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:middle;
	text-align:center;
    text-indent:10px;
}
.ls_select_button03 {
    height: 55px;
    width: 300px;
    line-height:20px;
	margin-top: 2px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.2);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.5);
    background-position:1% 45%; 
    background-size: 90px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.00em;
    font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:middle;
	text-align:center;
    text-indent:10px;
}
.ls_select_button04 {
    height: 80px;
    width: 300px;
    line-height:20px;
	margin-top: 2px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.2);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.5);
    background-position:1% 45%; 
    background-size: 90px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.00em;
    font-weight: 700;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:middle;
	text-align:center;
    text-indent:10px;
}
.ls_select_button05 {
    height: 120px;
    width: 231px;
    line-height:20px;
	margin-top: 2px;
	margin-left: 4px;
    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_select_button_default {
    height: 115px;
    width: 135px;
    line-height:20px;
	margin-top: 1px;
	margin-left: 2px;
    border:1px solid rgba(233,233,233, 0.3);
    /*
    background: url(../images/buttons/clear_img.png) rgba(10, 245, 92, 0.7);
    */
    background: url(../images/buttons/clear_img.png) rgba(204, 204, 204, 0.7);
    
    background-position:10% 45%; 
    background-size: 90px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 700;
    /*
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    vertical-align:middle;
	text-align:left;
}
.ls_select_button_action {
    height: 115px;
    width: 135px;
    line-height:20px;
	margin-top: 1px;
	margin-left: 2px;
    border:1px solid rgba(233,233,233, 0.3);
    
    background: url(../images/buttons/clear_img.png) rgba(10, 245, 92, 0.7);
    /*
    background: url(../images/buttons/clear_img.png) rgba(255, 255, 255, 0.7);
    */
    background-position:10% 45%; 
    background-size: 90px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 700;
    /*
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    vertical-align:middle;
	text-align:left;
}
.ls_select_button_td_cost_row01 {
    /*
    
        messagedisplayinput
    black
    */
    height: 30px;
    width: 170px;
    line-height:20px;
	margin-top: 0px;
	margin-left: 0px;
    border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255, 255, 255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .90em;
    font-weight: 700;
    /*
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
     
    vertical-align:middle;
	text-align:left;
}
.ls_select_button_td_cost_row02 {
    height: 40px;
    width: 170px;
    line-height:20px;
	margin-top: 0px;
	margin-left: 0px;
    border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255, 255, 255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .90em;
    font-weight: 700;
    /*
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
     
    vertical-align:middle;
	text-align:left;
}
/*************************************/
.validate_account_table_header01{
    height:90px  !important;
    width:400px  !important;
    text-align: left;
	vertical-align:top;
    border-spacing: 0px;
	border:0px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_header02{
    height:90px  !important;
    width:400px  !important;
    text-align: left;
	vertical-align:top;
    border-spacing: 0px;
	border:0px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_header03{
    height:90px  !important;
    width:150px  !important;
    text-align: left;
	vertical-align:top;
    border-spacing: 0px;
	border:0px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_header_01_frame {
    height:90px; 
    width:400px !important;
    line-height:20px;
	margin-top: 1px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255, 255, 255, 0.0);
    
    background-position:10% 45%; 
    background-size: 300px 90px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 700;
    
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    vertical-align:middle;
	text-align:left;
}
.validate_header_02_frame {
    height:90px; 
    width:400px !important;
    line-height:20px;
	margin-top: 1px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255, 255, 255, 0.0);
    
    background-position:10% 45%; 
    background-size: 300px 90px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 700;
    
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    vertical-align:middle;
	text-align:left;
}
.validate_header_03_frame {
    height:90px; 
    width:150px !important;
    line-height:20px;
	margin-top: 1px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255, 255, 255, 0.0);
    
    background-position:10% 45%; 
    background-size: 300px 90px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 700;
    
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    vertical-align:middle;
	text-align:left;
}

.validate_account_table_td01{
    height:466px; 
    width:455px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_td01_label{
    height:45px; 
    width:100px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_td01_1{
    height:100px; 
    width:447px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_td01_1_temp_frame {
    /*
    ls_form_submit_button
    select_account_table_header01
    */
    height:97px; 
    width:443px !important;
    line-height:20px;
	margin-top: 1px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.0);
    /*
    background: url(../images/buttons/clear_img.png) rgba(10, 245, 92, 0.7);
    */
    background: url(../images/buttons/clear_img.png) rgba(255, 255, 255, 0.0);
    
    background-position:10% 45%; 
    background-size: 300px 90px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 700;
    
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    vertical-align:middle;
	text-align:left;
    
}

.validate_account_table_td01_2{
    height:45px; 
    width:350px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_td01_3{
    height:80px; 
    width:452px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_td01_4{
    height:60px; 
    width:452px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_td01_4_1{
    height:60px; 
    width:60px !important;
    vertical-align:middle;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_td01_4_2{
    height:60px; 
    width:130px !important;
    vertical-align:middle;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_td02{
    height:466px; 
    width:255px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_td02_1{
    height:50px; 
    width:252px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_td02_2{
    height:125px; 
    width:252px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.validate_account_table_td02_3{
    height:60px; 
    width:252px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.validate_account_form_messagedisplayinput_field{
    height:35px !important;
    width: 98%;
    line-height:35px !important;
    border-spacing: 0px;
    border:hidden;
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
  }

.ls_validate_button01_silver {
    height: 123px;
    width: 249px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_silver.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.ls_validate_button01_gold {
    height: 123px;
    width: 249px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_gold.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.ls_validate_button01_platinum {
    height: 123px;
    width: 249px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_platinum.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.bs_validate_button01_silver {
    height: 123px;
    width: 249px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_silver.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	bs_select_account_table_header_platinum
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.10em;
    font-weight: 600;
}
.bs_validate_button01_gold {
    height: 123px;
    width: 249px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_gold.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.10em;
    font-weight: 600;
}
.bs_validate_button01_platinum {
    height: 123px;
    width: 249px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_platinum.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	ls_confirmation_button01_silver
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.10em;
    font-weight: 600;
}
.validate_account_table_options1{
    height:57px; 
    width:249px !important;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*************************************/
.confirmation_account_table{
    height:615px;
    width:717px !important;
	text-align: left;
	vertical-align:top;
    border-spacing: 0px;
	border:0px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.confirmation_table_td01{
    height:382px; 
    width:714px !important;
	margin-top: 0px;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.confirmation_table_td01_1{
    height:378px; 
    width:415px !important;
	margin-top: 1px;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.confirmation_table_td01_1_1{
    height:200px; 
    width:413px !important;
	margin-top: 1px;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.confirmation_table_td01_1_2{
    height:174px; 
    width:413px !important;
	margin-top: 1px;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.confirmation_table_td01_1_2_frame {
    height:172px; 
    width:410px !important;
    line-height:20px;
	margin-top: 1px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255, 255, 255, 0.0);
    
    background-position:10% 45%; 
    background-size: 300px 90px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 700;
    
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    vertical-align:middle;
	text-align:left;
}
.confirmation_table_td01_2{
    height:378px; 
    width:300px !important;
	margin-top: 1px;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.confirmation_table_td02{
    height:230px; 
    width:714px !important;
	margin-top: 1px;
    vertical-align:top;
    text-align:center;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/

.ls_confirmation_button01_silver {
    height: 196px;
    width: 413px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_silver.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.ls_confirmation_button01_gold {
    height: 196px;
    width: 413px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_gold.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.ls_confirmation_button01_platinum {
    height: 196px;
    width: 413px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_platinum.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.bs_confirmation_button01_silver {
    height: 196px;
    width: 413px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_silver.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.bs_confirmation_button01_gold {
    height: 196px;
    width: 413px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_gold.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.bs_confirmation_button01_platinum {
    height: 196px;
    width: 413px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_type_platinum.png);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.confirmation_panel{
	height:229px !important;
	width: 714px !important;
	text-align:center !important;
	vertical-align:middle !important;
 	border:1px solid rgba(233,233,233, 0.8);
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(239, 239, 239, 0.0);
    
    background-position:10% 45%; 
    background-size: 300px 90px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 700;
    
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    vertical-align:middle;
	text-align:left;
}

/*
account_form_purchase_option2
	.alt1_account_table_footer_td2{
		height:65px; 
	  	width:239px !important;
		vertical-align:middle;
		text-align:center;
		background-color:#FFF;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:hidden;
		border-top:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.alt1_account_table_footer_td3{
		height:65px; 
	  	width:239px !important;
		vertical-align:middle;
		text-align:center;
		background-color:#FFF;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:hidden;
		border-top:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
*/
	/***********************************/
	/*  LANDSCAPE_ACCOUNT_VALIDATE     */
	.account_main_validate{
		height:254px !important; 
	  	width:718px !important;
		background-color:#FFF;
		vertical-align:text-top;
		padding:0;
		margin:0;
	}
	.ls_account_table_full_top{
		height:50px !important;
		width:712px !important;
		line-height:25px !important;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-spacing: 0px;
		background:#FFF;
		margin:0;
		padding:0;
	}
	.ls_account_table_full{
		height:47px !important;
		width:712px !important;
		line-height:25px !important;
		border-right: hidden;
		border-left: hidden;
		border-top: 1px solid #ccc;
		border-spacing: 0px;
		background:#FFF;
		margin:0;
		padding:0;
	}
	/*
	.ls_account_table_bottom{
		height:60px !important;
		width:710px !important;
		line-height:25px !important;
		border-right: hidden;
		border-left: hidden;
		border-top: 1px solid #ccc;
		border-bottom:1px solid #ccc;
		border-spacing: 0px;
		background:#FFF;
		margin:0;
		padding:0;
	}
	*/
	.ls_account_command_bar{
		height:60px !important;
		width:712px !important;
		line-height:60px !important;
		border-right: hidden;
		border-left: hidden;
		border-top: 1px solid #ccc;
		border-bottom:1px solid #ccc;
		border-spacing: 0px;
		background:#FFF;
		margin:0;
		padding:0;
	}
	.ls_account_command_bar_td1{
		height:60px !important;
		width:428px !important;
		line-height:60px !important;
		border: hidden;
		border-spacing: 0px;
		background:#FFF;
	}
	.ls_account_command_bar_td2{
		height:60px !important;
		width:140px !important;
		line-height:60px !important;
		border: hidden;
		border-spacing: 0px;
		background:#FFF;
	}
	.ls_account_command_bar_td3{
		height:60px !important;
		width:140px !important;
		line-height:60px !important;
		border: hidden;
		border-spacing: 0px;
		background:#FFF;
	}
	/***********************************/
	/*  LANDSCAPE_ACCOUNT_CONFIRMATION */
	.account_table_confirm{
		height:294px !important; 
	  	width:714px !important;
		background-color:#FFF;
		vertical-align:text-top;
		border-right: 1px solid #ccc;
		border-left: 1px solid #ccc;
		border-top: hidden;
		border-bottom:hidden;
		padding:0;
		margin:0;
	}
	.account_table_confirm_td1{
		height:292px !important; 
	  	width:712px !important;
		background-color:#FFF;
		vertical-align:text-top;
		border: hidden;
		padding:0;
		margin:0;
	}
	
	/***********************************/
	/*  LANDSCAPE_ACCOUNT_PURCHASE_HISTORY */
	.account_purchase_menu{
		height:50px !important; 
	  	width:718px !important;
		background-color:#FFF;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top: hidden;
		border-bottom:1px solid #ccc;
	}
	.account_purchase_menu_td1{
		height:50px !important; 
	  	width:211px !important;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
		border:hidden;
	}
	.account_purchase_menu_td2{
		height:50px !important; 
	  	width:211px !important;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
		border:hidden;
	}
	.account_purchase_menu_td3{
		height:50px !important; 
	  	width:78px !important;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
		border:hidden;
	}
	.account_purchase_menu_td4{
		height:50px !important; 
	  	width:211px !important;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
		border:hidden;
	}
	.account_purchase_filter_active {
		height: 48px !important;
		width: 99px !important;
		line-height:48px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color:#0C0; 
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.account_purchase_body{
	
	  	width:718px !important;
		line-height:280px;
		text-align:center !important;
		vertical-align:text-top;
	}
/*

ls_select_button01_silver
account_table_main
*/
.account_purchase_main{
    height:447px !important; 
    width:718px !important;
    line-height:447px  !important;

    background-color:rgba(255,255,255, 0.7);

    text-align:left;
    vertical-align:text-top  !important;
}
/*
account_purchase_main
account_purchase_main_td4_label
*/
.account_purchase_absolute {
    height:46px !important;
    width:690px !important;
    line-height:46px  !important;
    border-spacing: 0px;
    border:hidden;
    position: relative;
    padding: 0px !important;
    margin-top:9px;
    margin-left:5px !important;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.account_purchase_main_row{
    height:50px !important; 
    width:690px !important;
    text-align:center;
    vertical-align:text-top;
}
.account_purchase_main_td1{
    height:44px !important; 
    width:248px !important;
    line-height:44px;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
	background-color:rgba(255,255,255, 0.7);
    font-size: .95em;
    font-weight: 600;
    color: darkslateblue;
}
.account_purchase_main_td1_label{
    z-index: 8;
    height:40px !important; 
    width:244px !important;
    line-height:42px;

    position: absolute;
    margin-top:4px;
    margin-left:-255px !important;

    border:hidden;

    color:rgba(15, 5, 26, 0.6);
    font-size: .70em  !important;
    font-weight: 500;
    text-align:center;
    vertical-align:bottom;
    text-decoration:none;

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 200px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_purchase_main_td2{
    height:48px !important; 
    width:248px !important;
    line-height:48px;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
	background-color:rgba(255,255,255, 0.7);
    font-size: .75em;
    font-weight: 600;
    color: darkslateblue;
}
.account_purchase_main_td2_label{
    z-index: 8;
    height:44px !important; 
    width:244px !important;
    line-height:46px;

    position: absolute;
    margin-top:4px;
    margin-left:-208px !important;

    border:hidden;

    color:rgba(15, 5, 26, 0.6);
    font-size: .50em  !important;
    font-weight: 500;
    text-align:center;
    vertical-align:bottom;
    text-decoration:none;

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 200px 35px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.aph_silver_active{
    z-index: 8;
    height:42px !important; 
    width:242px !important;
    line-height:42px;
    position: absolute;
    margin-top:2px;
    margin-left:-250px !important;
    text-align:left;
    text-indent:35px;
    vertical-align:middle;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
	background-color:rgba(255,255,255, 0.7);
    background: url(../images/buttons/account_type_silver.png);
    background-size: 46px 46px;
    background-repeat: no-repeat;
    background-position: 80% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.aph_gold_active{
    z-index: 8;
    height:42px !important; 
    width:242px !important;
    line-height:42px;
    position: absolute;
    margin-top:2px;
    margin-left:-250px !important;
    text-align:left;
    text-indent:35px;
    vertical-align:middle;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
	background-color:rgba(255,255,255, 0.7);
    background: url(../images/buttons/account_type_gold.png);
    background-size: 46px 46px;
    background-repeat: no-repeat;
    background-position: 80% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.aph_platinum_active{
    z-index: 8;
    height:42px !important; 
    width:242px !important;
    line-height:42px;
    position: absolute;
    margin-top:2px;
    margin-left:-250px !important;
    text-align:left;
    text-indent:35px;
    vertical-align:middle;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
	background-color:rgba(255,255,255, 0.7);
    background: url(../images/buttons/account_type_platinum.png);
    background-size: 46px 46px;
    background-repeat: no-repeat;
    background-position: 80% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_purchase_main_td3{
    height:44px !important; 
    width:145px !important;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
	background-color:rgba(255,255,255, 0.7);
    font-size: .75em;
    font-weight: 600;
}
.account_purchase_main_td3_label{
    z-index: 8;
    height:42px !important; 
    width:141px !important;
    line-height:42px;

    position: absolute;
    margin-top:2px;
    margin-left:-145px !important;

    border:hidden;
    color:rgba(15, 5, 26, 0.6);
    font-size: .80em  !important;
    font-weight: 500;
    text-align:center;
    vertical-align:bottom;
    text-decoration:none;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 71px 35px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_purchase_main_td4{
    height:44px !important; 
    width:248px !important;
    position: absolute;
    margin-top:2px;
    margin-left:2px !important;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
	background-color:rgba(255,255,255, 0.7);
    font-size: .75em;
    font-weight: 600;
}
.account_purchase_main_td4_label{
    z-index: 8;
    height:42px !important; 
    width:242px !important;
    line-height:42px;

    position: absolute;
    margin-top:2px;
    margin-left:2px !important;
    border:hidden;
    color:rgba(15, 5, 26, 0.6);
    font-size: .50em  !important;
    font-weight: 500;
    text-align:center;
    vertical-align:bottom;
    text-decoration:none;

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 200px 35px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_purchase_pagination{
    height:56px !important; 
    width:714px !important;
    line-height:56px;
    background-color:#FFF;
    text-align:center;
    vertical-align:middle;

    border-top:1px solid #EFEFEF;
    border-bottom:1px solid #EFEFEF;
    border-left:1px solid #EFEFEF;
    border-right:1px solid #EFEFEF;
    padding:0;
    margin:0;
}

/* ################################################ */
/* ######    LANDSCAPE_ACCOUNT_SUMMARY   border:hidden;      ##### */

/*



*/
	.summary_main_table{
		height:127px !important;
		width:711px !important;
		line-height:127px !important;
		background:#EFEFEF;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.summary_main_table_td1{
		height:127px !important;
		width:175px !important;
		line-height:127px !important;
		position: absolute;
		margin-left: 0px;
		
		border-left:1px solid #DEDEDE;
		border-right: 1px solid #DEDEDE;
		border-top: hidden;
		border-bottom: hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.summary_main_table_td2{
		height:127px !important;
		width:175px !important;
		line-height:127px !important;
		position: absolute;
		margin-left: 182px;
		
		border-left: hidden;
		border-right: 1px solid #DEDEDE;
		border-top: hidden;
		border-bottom: hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.summary_main_table_td3{
		height:127px !important;
		width:175px !important;
		line-height:127px !important;
		position: absolute;
		margin-left: 360px;
		
		border-left: hidden;
		border-right: 1px solid #DEDEDE;
		border-top: hidden;
		border-bottom: hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.summary_main_table_td4{
		height:127px !important;
		width:175px !important;
		line-height:127px !important;
		position: absolute;
		margin-left: 538px;
		
		border-left: hidden;
		border-right: 1px solid #DEDEDE;
		border-top: hidden;
		border-bottom: hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
.summary_business_settings_status_button_inactive {
    height: 127px !important;
    width: 175px !important;
    line-height:127px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/sys_option_default/default_building.png) rgba(239,239,239, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.summary_business_settings_status_active_button {
    height: 127px !important;
    width: 175px !important;
    line-height:127px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/sys_option_default/default_building.png) rgba(239,239,239, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.summary_settings_status_button_inactive {
	/*
	../images/buttons/sys_profile_buttonDefault.png
	*/
    height: 127px !important;
    width: 175px !important;
    line-height:127px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.summary_settings_status_active_button {
	/*
	config
	url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7)
	../images/buttons/sys_profile_buttonActive.png
	*/
    height: 127px !important;
    width: 175px !important;
    line-height:127px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.summary_account_type_silver_button {
    height: 127px !important;
    width: 175px !important;
    line-height:127px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/account_type_silver.png) rgba(239,239,239, 0.3);
    background-size: 127px 127px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	 -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.summary_account_type_gold_button {
    height: 127px !important;
    width: 175px !important;
    line-height:127px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/account_type_gold.png) rgba(239,239,239, 0.3);
    background-size: 127px 127px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.summary_account_type_platinum_button {
    height: 127px !important;
    width: 175px !important;
    line-height:127px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/account_type_platinum.png) rgba(239,239,239, 0.3);
    background-size: 127px 127px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.summary_metrics_button {
    height: 127px !important;
    width: 175px !important;
    line-height:127px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.3);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
	.summary_metrics_bio_complete_button {
		height: 127px !important;
		width: 175px !important;
		line-height:127px;
		font-size: .83em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		background: url(../images/buttons/account_metrics_bio.png);
		background-size: 110px 110px;
   		background-repeat: no-repeat;
		background-position: 1% 45%; 
	}
	.summary_metrics_geo_complete_button {
		height: 127px !important;
		width: 175px !important;
		line-height:127px;
		font-size: .83em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		background: url(../images/buttons/account_metrics_geo.png);
		background-size: 110px 110px;
   		background-repeat: no-repeat;
		background-position: 1% 45%; 
	}
	.summary_metrics_socio_complete_button {
		height: 127px !important;
		width: 175px !important;
		line-height:127px;
		font-size: .83em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		background: url(../images/buttons/account_metrics_socio.png);
		background-size: 110px 110px;
   		background-repeat: no-repeat;
		background-position: 1% 45%; 
	}
	.summary_metrics_biogeo_complete_button {
		height: 127px !important;
		width: 175px !important;
		line-height:127px;
		font-size: .83em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		background: url(../images/buttons/account_metrics_biogeo.png);
		background-size: 110px 110px;
   		background-repeat: no-repeat;
		background-position: 1% 45%; 
	}
	.summary_metrics_biosocio_complete_button {
		height: 127px !important;
		width: 175px !important;
		line-height:127px;
		font-size: .83em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		background: url(../images/buttons/account_metrics_biosocio.png);
		background-size: 110px 110px;
   		background-repeat: no-repeat;
		background-position: 1% 45%; 
	}
	.summary_metrics_geosocio_complete_button {
		height: 127px !important;
		width: 175px !important;
		line-height:127px;
		font-size: .83em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		background: url(../images/buttons/account_metrics_geosocio.png);
		background-size: 110px 110px;
   		background-repeat: no-repeat;
		background-position: 1% 45%; 
	}
*/
.summary_compliance_button {
    height: 127px !important;
    width: 175px !important;
    line-height:127px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.3);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
}

/* ################################################ */
/* ######    LANDSCAPE_SETTINGS               ##### */

	.settings_general_table_td1{
		height:390px !important;
		width:718px !important;
		line-height:390px !important;
		background:#666;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.settings_general_table_td2{
		height:127px !important;
		width:718px !important;
		line-height:127px !important;
		background:#CCC;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* - ON TEXT ALIGNMENT
	   - BUTTON SIZE: HEIGHT AND WITH
	 */
	.custom-size-flipswitch.ui-flipswitch .ui-btn.ui-flipswitch-on{
		text-indent: -65px;  
		height:30px; 
		width:110px;
		line-height:30px;
	}
	/* - OFF TEXT ALIGNMENT
	   - BUTTON INHERT FROM ON SWITCH  
	 */
	.custom-size-flipswitch.ui-flipswitch .ui-flipswitch-off {
		text-align:right;
		text-indent: 55px;
		line-height:30px;
	}
	/* -THIS IS THE BACKGROUND COLOR GREY SIZE AND HEIGHT [MAIN SIZE] 
	   -THIS IS THE OFF SWITCH LENGTH 
	 */
	.custom-size-flipswitch.ui-flipswitch {
		height:30px; width:230px;
	}
	 /* -THIS IS THE FLIPSWITCH SIZE FORMAT*/
	.custom-size-flipswitch.ui-flipswitch.ui-flipswitch-active {
	  height:30px; width:100px;padding-left:130px;
	}
	
	.switch_status_mess{
		font-size:12px;
		font-weight:700;
		text-align:left;
		text-decoration:none;
	}
	.edit_profile_switch_label {
		height:50px;
		width: 125px;
		background:#FFF;
		font-size:12px;
		font-weight:700;
		text-align:left;
		text-decoration:none;
	}
	.edit_profile_switch_table {
		height:50px;
		width: 340px;
		background:#FFF;
	}
	.edit_profile_switch_filler {
		height:50px;
		width: 250px;
		background:#FFF;
		font-size:12px;
		font-weight:700;
		text-align:left;
		text-decoration:none;
	}
	.edit_profile_sys_not_filler {
	  	height:50px; 
	  	width:720px;
	 	padding:0px;
	}
	
	.settings_form_command_bar{
		height:51px !important;
		width:714px !important;
		line-height:50px !important;
		border-spacing: 0px;
		border-top:1px solid rgba(239,239,239, 0.9);
		border-bottom:1px solid rgba(239,239,239, 0.9);
		border-left:1px solid rgba(239,239,239, 0.9);
		border-right:1px solid rgba(239,239,239, 0.9);
		background:#FEF;
		font-size:16px;
		font-weight:700;
		text-decoration:none;
		margin:0;
		padding:0;
		/*
		ls_form_space_label
		*/
	}  
	.settings_form_command_bar_td1{
		height:50px;
		width:196px;
		line-height:50px;
		border-spacing: 0px;
		text-align:right;
		vertical-align:middle !important;
		border-right:hidden;
		border-left:hidden;
		
		background:#FFF;
		margin:0;
		padding:0;
	}
	.settings_form_command_bar_td2{
		height:50px;
		width:200px;
		line-height:50px;
		border-spacing: 0px;
		text-align:center !important;
		vertical-align:middle !important;
		border-right:hidden;
		
		background:#FFF;
		margin:0;
		padding:0;
	}
	.settings_form_command_bar_td3{
		height:50px;
		width:144px;
		line-height:50px;
		border-spacing: 0px;
		text-align:center;
		vertical-align:middle !important;
		background:#FFF;
		margin:0;
		padding:0;
	}
	.settings_form_command_bar_td4{
		height:50px;
		width:144px;
		line-height:50px;
		border-spacing: 0px;
		border-right:hidden; 
		text-align:center;
		vertical-align:middle !important;
		background:#FFF;
		margin:0;
		padding:0;
	}
.ls_form_location_button {
    height: 40px;
    width: 201px;
    line-height:33px;
    text-align:left;
    text-indent:50px;
    text-decoration:none;
    background-color:#EFEFEF; 
    border:1px solid #CCC;
    background: url(../images/buttons/butt_template.png);
    background-size: 50px 35px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_identity_button {
    height: 40px;
    width: 350px;
    line-height:33px;
    text-align:left;
	text-indent:50px;
    text-decoration:none;
    background-color:#EFEFEF; 
    border:1px solid #CCC;
    background: url(../images/buttons/butt_template.png);
    background-size: 50px 35px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_identity_status_button {
    height: 95px;
    width: 148px;
    line-height:12px;
    text-align:center;
   	text-indent:0px;
    border:1px solid #CCC;
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 145px 92px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:rgba(15, 5, 26, 0.6);
    font-size: 0.95em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
}

.ls_form_identity_status_td01 {
    height: 97px;
    width: 156px;
    line-height:12px;
    text-align:left;
    border:1px solid rgba(204,204,204, 0.3);
	background-color: rgba(239,239,239, 0.7);
}
.ls_form_identity_status_td02 {
    height: 40px !important;
    width: 355px;
    line-height:12px;
    text-align:left;
	vertical-align: top;
	border-spacing: 0px;
   border:1px solid rgba(204,204,204, 0.3);
	background-color: rgba(239,239,239, 0.5);
}
.identity_container_bar {
	height:55px !important;
	width:560px !important;
	line-height:28px;
	
	
	vertical-align:top;
	text-align:left;
	background-color: rgba(255,255,255, 0.7);
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	/*
	identity_select_td02
	*/
}
.bs_form_identity_status_td01 {
    height: 70px;
    width: 156px;
    line-height:12px;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.7);
	/*
	identity_container_bar
	*/
}
.bs_form_identity_status_button {
    height: 68px;
    width: 154px;
    line-height:12px;
	vertical-align: top;
   	text-indent:0px;
    border:1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 152px 63px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:rgba(15, 5, 26, 0.6);
    font-size: 0.85em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
}
.bs_identity_list_bar {
	height:34px !important;
	width:554px !important;
	line-height:28px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.7);
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
}
.bs_identity_container_bar {
	height:34px !important;
	width:554px !important;
	line-height:28px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.7);
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	/*
	identity_select_td02
	*/
}

	.settings_title_type_bar_top{
		height:20px !important;
		width:718px !important;
		line-height:20px !important;
		border-spacing: 0px;
		border-top:hidden;
		border-bottom:1px solid #ccc;
		background:#F9F9F9;
		font-size:16px;
		font-weight:700;
		text-decoration:none;
		margin:0;
		padding:0;
	}  
	.settings_title_type_bar{
		height:20px !important;
		width:718px !important;
		line-height:20px !important;
		border-spacing: 0px;
		border-top:1px solid #ccc;
		border-bottom:hidden;
		background:#F9F9F9;
		font-size:16px;
		font-weight:700;
		text-decoration:none;
		margin:0;
		padding:0;
	}  
	
	.switch_form_label {
		height:25px !important;
		width:220px;
		line-height:25px !important;
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
		
		vertical-align:middle !important;
		border-spacing: 0px;
		border-right:1px solid #ccc;
		
	}
.switch_form_label_privacy {
    height:25px !important;
    width:147px;
    line-height:25px !important;

    font-size: .90em;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    text-indent:45px;

    vertical-align:middle !important;
    border-spacing: 0px;
    border-top:hidden;
    border-bottom:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
    border-left:hidden;
	background: url(../images/buttons/sys_security_option.png);
	background-size: 35px 35px;
	background-position: 1% 50%;
	background-repeat: no-repeat;
	webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	switch_form_half_table2
	privacy
	*/
}
.switch_form_label_ticketing {
    height:25px !important;
    width:147px;
    line-height:25px !important;

    font-size: .90em;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    text-indent:25px;

    vertical-align:middle !important;
    border-spacing: 0px;
    border-top:hidden;
    border-bottom:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
    border-left:hidden;
	background: url(../images/buttons/butt_template.png);
	background-size: 35px 35px;
	background-position: 1% 50%;
	background-repeat: no-repeat;
	webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	switch_form_half_table2
	*/
}
.switch_form_label_seating {
    height:25px !important;
    width:147px;
    line-height:25px !important;

    font-size: .90em;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    text-indent:10px;

    vertical-align:middle !important;
    border-spacing: 0px;
    border-top:hidden;
    border-bottom:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
    border-left:hidden;
	background: url(../images/buttons/butt_template.png);
	background-size: 35px 35px;
	background-position: 1% 50%;
	background-repeat: no-repeat;
	webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	switch_form_half_table2
	*/
}
.switch_form_item_table {
    height:45px !important;
    width: 125px;
    line-height:12px !important;
    border-left:hidden;
    border-top:hidden;
    border-bottom:hidden;
    border-right:hidden;
    border-left:hidden;
    text-align:center;
    background:rgba(255,255,255, 0.5);
    margin:0;
    padding:0;
    /*
    ls_form_half_table3
    */
}
/*
item_title_field
*/
.switch_form_button_active {
    height:42px !important;
    width: 135px;
    line-height:12px !important;
    margin-top:1px;
    margin-left:1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(10, 245, 92, 0.7);
    font-size: 1.10em;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
}
.switch_form_button_pending {
    height:42px !important;
    width: 135px;
    line-height:12px !important;
    margin-top:1px;
    margin-left:1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255, 255, 128, 0.7);
    font-size: 1.10em;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
}
.switch_form_button_disabled {
    height:42px !important;
    width: 135px;
    line-height:12px !important;
    margin-top:1px;
    margin-left:1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(204,204,204, 0.7);
    font-size: 1.10em;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
}
.switch_form_button_locked{
    height:42px !important;
    width: 135px;
    line-height:12px !important;
    margin-top:1px;
    margin-left:1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(252, 71, 71, 0.7);
    font-size: 1.10em;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
}
.switch_form_label_profile {
   
    height:25px !important;
    width:147px;
    line-height:12px !important;

    font-size: .90em;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    text-indent:25px;

    vertical-align:middle !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/buttons/sys_pro_default.png);
	background-size: 35px 35px;
	background-position: 1% 50%; 
	/*
	
	
	background: url('../images/SPRITE.png');
	background-position: 0 -1670px; 
	
	background-size: 0px 1205px;
	*/
	background-repeat: no-repeat;
  /*
	background-size: 50px 50px;
	background-position: 0 200px; 
	background-size: 100px 100px;
    
	
   
	
    -*/
	webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	switch_form_half_table_username
	*/
}
.switch_form_label_business {
   
    height:25px !important;
    width:147px;
    line-height:12px !important;

    font-size: .90em;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    text-indent:25px;

    vertical-align:middle !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/sys_option_default/default_building.png);
	background-size: 35px 35px;
	background-position: 1% 50%; 
	/*
	
	
	background: url('../images/SPRITE.png');
	background-position: 0 -1670px; 
	
	background-size: 0px 1205px;
	*/
	background-repeat: no-repeat;
  /*
	background-size: 50px 50px;
	background-position: 0 200px; 
	background-size: 100px 100px;
    
	
   switch_form_half_table2
	
    -*/
	webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	switch_form_half_table_username
	*/
}
.switch_form_label_event {
    height:25px !important;
    width:220px;
    line-height:12px !important;

    font-size: .90em;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    text-indent:45px;

    vertical-align:middle !important;
    border-spacing: 0px;
    border-right:1px solid #ccc;
    background: url(../images/buttons/sys_pin_default.png);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position: 1% 50%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
	.switch_form_label_system {
		height:25px !important;
		width:220px;
		line-height:25px !important;
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:45px;
		
		vertical-align:middle !important;
		border-spacing: 0px;
		border-right:1px solid #ccc;
		background: url(../images/buttons/sys_settings_option.png);
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position: 1% 50%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.switch_form_label_security {
		height:25px !important;
		width:220px;
		line-height:25px !important;
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:45px;
		
		vertical-align:middle !important;
		border-spacing: 0px;
		border-right:1px solid #ccc;
		background: url(../images/buttons/sys_security_option.png);
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position: 1% 50%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.switch_form_label_stats{
		height:25px !important;
		width:220px;
		line-height:25px !important;
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:45px;
		
		vertical-align:middle !important;
		border-spacing: 0px;
		border-right:1px solid #ccc;
		background: url(../images/buttons/sys_metrics_buttonDefault.png);
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position: 1% 50%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.switch_form_label_calendar{
		height:25px !important;
		width:220px;
		line-height:25px !important;
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:45px;
		
		vertical-align:middle !important;
		border-spacing: 0px;
		border-right:1px solid #ccc;
		background: url(../images/buttons/sys_metrics_buttonDefault.png);
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position: 1% 50%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.switch_form_half_table1 {
		height:47px !important;
		width: 125px;
		line-height:25px !important;
		background:#FFF;
		border-spacing: 0px;
		border-right:1px solid #ccc;
		text-align:center;
		margin:0;
		padding:0;
	}
.switch_form_half_table_username {
    height:47px !important;
    width: 425px;
    line-height:25px !important;
    background:#FFF;
    border-spacing: 0px;
    border-top:1px solid rgba(239,239,239, 0.9);
    border-bottom:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
    border-left:hidden;
    text-align:center;
    margin:0;
    padding:0;
}
	.switch_form_space_table {
		height:47px !important;
		width: 15px !important;
		line-height:25px !important;
		border-spacing: 0px;
		border-top:hidden;
		border-left:hidden;
		border-right:1px solid #ccc;
		border-bottom:hidden;
	}
.switch_form_space_gen_table {
    height:47px !important;
    width: 12px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
}
.switch_form_half_table2 {
    height:45px !important;
    width: 136px;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    text-align:center;
    background:rgba(255,255,255, 0.5);
}
.security_control_table {
    height:270px !important;
    width: 708px;
    background-color:rgba(255,255,255, 0.7);
    text-align:left!important;
	vertical-align: top !important;
	border-spacing: 0px;
    border:thin solid rgba(233,233,233, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	margin:0;
    padding:0;
	
	/*
	security_blockedusers_title
	security_blockedusers_table
	account_metrics_options01
	
	*/
}

.security_blockedusers_table {
    height:254px !important;
    width: 708px;
    background-color:#FFF;
    border-spacing: 0px;
    border:hidden;
    text-align:center;
    margin:0;
    padding:0;
}
.security_blockedusers_table_td1_1 {
    height:50px !important;
    width: 240px;
    background-color:#FFF;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.8);
    text-align:center;
    margin:0;
    padding:0;
	/*
	
	security_control_table
	*/
}
.security_blockedusers_table_td1_2 {
    height:50px !important;
    width: 145px;
    background-color:#FFF;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.8);
    text-align:left;
    margin:0;
    padding:0;
}
.security_blockedusers_table_td1_3 {
    height:50px !important;
    width: 305px;
    background-color:#FFF;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.8);
    text-align:right;
    margin:0;
    padding:0;
}
.security_blockedusers_table_td2 {
    height:248px !important;
    width: 708px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.8);
    text-align:top;
    vertical-align:top;
    margin:0;
    padding:0;
}

.security_blockedusers_table_td2_1row {
    height:248px !important;
    width:708px;
    border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.8);
    background: rgba(239,239,239, 0.7);
    vertical-align:top;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
	
.blocked_contribution_button {
    position:relative;
    width:100px !important;
    max-width: 100px !important;

    vertical-align:top !important;
    background-repeat: no-repeat;
    background-position: center;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.ls_form_med_option_blocked_frame_absolute {
    height:100px;
    width:100px;
    display:inline-block;
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    border-right:1px solid #ccc;
    border-left:1px solid #ccc;
    background-size: 98px 98px;
    background-color:#FFF;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.security_blockedusers_title {
    height:48px;
    width:130px;
    background-color:#FFF;
    display:inline-block;
    position:relative;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/sys_security_option.png);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
} 
.security_blockedusers_button {
    height:48px;
    width:145px;
    background-color:#FFF;
    display:inline-block;
    position:relative;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.security_blockedusers_count {
    height:50px;
    width:312px;
    background-color:#FFF;
    display:inline-block;
    position:relative;
    font-size: 1em;
    font-weight: 600;
    text-align:right;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*
base_functional_table

*/
.base_security_profile_table_empty {
    height:250px !important;
    width:715px !important;
    line-height:50px;
    border: hidden;
    position:absolute;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    background: url(../images/buttons/butt_template.png)  rgba(255,255,255, 0.5);
    background-size: 180px 100px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
	
	
/* ################################################ /*member_contribution_button*/
/* ######              LANDSCAPE_EDIT_PROFILE_PIC       #####  */  

	
	.setting_editphoto_space_table{
		height:51px !important;
		width:951px !important;
		border:0; 
		padding:0;
		margin:0;
	}

/*   
		
		base_settings_table_td 
		ie_functional_table
		line-height:515px !important;
		
	.setting_editphoto_image_window {   
		
		height:515px !important; 
		width:947px !important;
		
		resize: none;
		text-align:center !important;
	}
*/
	/*
	base_functional_table
	*/
	.setting_photo_image_window_td1 {
		height:535px !important; 
		width:516px !important;
		line-height:535px !important;
		resize: none;
		margin-top: 0px;
		
		vertical-align:text-top !important;
	}
	.setting_photo_manage_main {
		height:513px !important; 
		width:509px !important;
		line-height:513px !important;
		resize: none;
		text-align:center !important;
	}
	/****    DEFAULT PROFILE IMAGE WINDOW BACKGROUND   */
	.setting_photo_manage_image {
		height:400px !important; 
		width:508px !important;
		line-height:400px !important;
		
		position:relative;
		resize: none;
		
		margin-top: 30px;
		
		text-align:left !important;
		vertical-align:middle !important;
		
		background: url(../images/buttons/sys_pro_button.png);
		background-position: center;
		background-size: 400px 400px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		/*
		filter: Alpha(Opacity=70);
		opacity: .77;
		*/
	}
	/****    DEFAULT PIN IMAGE WINDOW BACKGROUND   */
	.setting_pin_manage_image {
		height:400px !important; 
		width:508px !important;
		line-height:400px !important;
		position:relative;
		resize: none;
		text-align:left !important;
		vertical-align:middle !important;
		background: url(../images/buttons/sys_pin_button.png) no-repeat center;
		background-size: 400px 400px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		/*
		filter: Alpha(Opacity=70);
		opacity: .77;
		*/
	}
	/****    ACTIVE IMAGE WINDOW BACKGROUND   */
/*
	ie_functional_table_td_row_side_active
setting_photo_image_window_td1_stats
*/
	.setting_photo_manage_image_active {
		height:500px !important; 
		width:508px !important;
		line-height:500px !important;
		
		display: none;
		position:relative;
		resize: none;
		
		text-align:left !important;
		vertical-align:middle !important;
		
		/*
		filter: Alpha(Opacity=100);
		opacity: .100;
		*/
	}
	
	/****    ACTIVE BUTTON ON IMAGE   */
	.manage_image_menu_active{
		top:5px;
		height:65px !important; 
		width:70px !important;
		position:absolute;
		border:hidden;
	}
	/****    ACTIVE IMAGE   */
/*
setting_photo_manage_image_active
*/
	.manage_image_active_image {
		position:relative;
		vertical-align:middle;
		text-align:center;
		border:hidden;
	}
	/****    ACTIVE BUTTON ON IMAGE CLASS  */
	.setting_photo_manage_edit_image_css {
		z-index:100;
		height:65px !important; 
		width:60px !important;
		line-height:65px !important;
		resize: none;
		text-align:center !important;
		vertical-align:top !important;
		background: url(../images/buttons/butt_template.png);
		background-size: 60px 60px;
   		background-repeat: no-repeat;
		background-position: 0% 5%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	/****    DEFAULT BUTTON ON IMAGE DIV  */
	.manage_image_menu_top {
		top:10px;
		height:65px !important; 
		width:70px !important;
		border:hidden;
		
	}
	/****    DEFAULT BUTTON ON IMAGE CLASS  */
	.setting_photo_manage_reset_profile {
		z-index:100;
		height:65px !important; 
		width:60px !important;
		line-height:65px !important;
		resize: none;
		text-align:center !important;
		vertical-align:top !important;
		background: url(../images/buttons/sys_refresh_button.png);
		background-size: 60px 60px;
   		background-repeat: no-repeat;
		background-position: 0% 5px; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
/*
*/
.setting_photo_image_window_td1_sub {
    /*
    ie_functional_table_td_col_top_active
    */
    height:470px !important; 
    width:516px !important;
    position: absolute;
    margin-top: -22px;
    line-height:470px !important;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    text-align:center !important;
    vertical-align:text-top !important;
    background: url(../images/bkgrounds/image_gid.png);
    background-size: 100px 100px;
    background-repeat:repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    resize: none;
}
	/*
	
	setting_photo_image_window_td1
	*/
	.setting_photo_image_window_td1_stats {
		height:112px !important; 
		width:105px !important;
		border:hidden;
		/* border-top:1px solid #CCC; 1px solid #CCC;  */
		line-height:112px !important;
		resize: none;
		text-align:center !important;
		vertical-align:middle !important;
		display:inline-block;
		margin-left:0px !important;
		position:absolute;
	}
.setting_photo_image_window_td1_menu {
    height:110px !important; 
    width:518px !important;
    line-height:112px !important;
    margin-top: 181px;
    position:absolute;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background-color: rgba(255,255,255, 0.7);
    text-align:center !important;
    vertical-align:top !important;
}
/*
setting_photo_manage_image
resize: none;
height:515px !important; 
setting_photo_image_window_td1
setting_photo_image_window_td2
*/
	.setting_photo_image_window_td2 {
		height:580px !important; 
		width:195px !important;
		line-height:580px !important;
		
		text-align:left !important;
		vertical-align:text-top !important;
		
		background-color: rgba(255,255,255, 0.2);
	}
/*


*/
	.setting_photo_image_window_td2_sub {
		height:95px !important; 
		width:95px !important;
		line-height:95px !important;
		resize: none;
		vertical-align:text-top !important;
		
	}
	/****    MANAGE TABLE CSS  Fpx 105px;*/
	.manage_stats_css {
		height: 109px;
		width: 112px;
		line-height:109px;
		
		border-top:1px solid #CCC;
		border-right:1px solid #CCC;
		border-left:hidden;
		border-bottom:1px solid #CCC;
		
		text-align:center;
		vertical-align:text-top;
		
		background-color:#FFF;
		background-position:center;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	
	*/
	
.manage_image_menu_css {
    /*
    setting_photo_image_window_td1_menu
    setting_photo_image_window_td1_sub
    */
    height: 105px;
    width: 303px;
    line-height:105px;
    margin-top: -110px;
    margin-left: 150px;
    position: absolute;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(255,255,255, 0.7);
    font-size:12px;
    font-weight:700;	
}
	.manage_stat_menu_css {
		height: 40px;
		width: 393px;
		border-top:1px solid #CCC;
		font-size:12px;
		font-weight:700;
		left:10px;
		padding-left:10px;
		
	}
	.manage_stat_menu_image {
		height: 40px;
		width: 380px;
		display:inline-block;
		background-color:#EFEFEF;
		background: url(../images/buttons/butt_template.png);
		background-position:left;
		background-size: 55px 35px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.manage_stat_menu_data {
		height: 40px;
		width: 250px;
		display:inline-block;
		vertical-align:middle;
	}
	
	.manage_stat_menu_data_input_css {
		height: 40px;
		width: 200px;
		float:left;
		border:hidden;
		vertical-align:middle;
		font-size:12px;
		font-weight:700;
		text-indent:5px;
		text-decoration:none;
	}
	
	
	/****    MENU BUTTON STRUCTURE   */
	.controlUI_control_class {
		height: 100px;
		width: 390px;
		border:none;
		font-size:12px;
		font-weight:700;
		/* 460 */
		visibility:hidden;
		display:none;
	}
	.controlbutton_control{
		height:60px !important;
		width:77px !important;
		line-height:60px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		padding:5px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		padding:0;
		margin:0;
	}
	/****    MENU BUTTON BACKGROUNDS   */
	.bkground_div_controlDelete{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_delete_buttonDefault.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlDeleteActive{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_delete_buttonActive.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlCancel{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_cancel_buttonDefault.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlCancelActive{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_cancel_buttonActive.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlReset{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_refresh_buttonDefault.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlResetActive{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_refresh_buttonActive.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlStats{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_metrics_buttonDefault.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlStatsActive{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_metrics_buttonActive.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	/*
	
	*/
	.bkground_div_controlMarketplace{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/butt_template.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlMarketplaceActive{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/butt_template.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlSave{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_profile_buttonSave.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlSaveActive{
		height:80px !important;
		width:80px !important;
		line-height:60px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_image_save_button.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlProfile{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_profile_buttonDefault.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlProfileActive{
		height:80px !important;
		width:80px !important;
		line-height:80px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_profile_buttonActive.png);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	/*
		


	*/
	.bkground_div_controlClear{
		
		display: none;
		
	}
	.bkground_div_controlClearActive{
		height:30px !important;
		width:30px !important;
		line-height:30px !important;
		display: inline-block;
		
		position: absolute;
		margin-top: -470px;
		margin-left: 64px;
		
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		text-decoration:none;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-size: 20px 20px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	.bkground_div_controlPin{
		height:60px !important;
		width:60px !important;
		line-height:60px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_pin_buttonDefault.png);
		background-size: 60px 60px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.bkground_div_controlPinActive{
		height:60px !important;
		width:60px !important;
		line-height:60px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_pin_buttonActive.png);
		background-size: 60px 60px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	/****    PROFILE MENU BUTTON BACKGROUNDS  border-v.gif  */
	.bkground_div_controlSaveProfile{
		height:60px !important;
		width:60px !important;
		line-height:60px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_profile_buttonSave.png);
		background-size: 60px 60px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	
	.setting_editphoto_image_window{
		height: 470px  !important;
		border-spacing: 0px;
		/*
		background: url(../images/buttons/sys_grid.png);
		background-size: 100%;
   		background-repeat: no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=95);
		opacity: .95;
	}
	

.map_item_window_view_UI_control_class{
	z-index: 10;
	height:230px; 
	width:650px;
	border:solid thin #FFF;
	background-color:transparent;
	cursor:pointer;
	position: absolute;
}
.map_item_window_view_UI_main_control_class{
	z-index: 10;
	height:230px; 
	width:650px;
	background-color:transparent;
	/*
	*/
	position:absolute;
	/*z-index: 10;
	border:solid thin #FFF;cursor:pointer; controlUI_control_class File_Modify_option_default*/
}
.map_notification_window_close{
	z-index: 1003;
	height:30px; 
	width:30px;
	right:0;
	position:absolute;
	
	top: 0px;
	/*
	window_view_close_button
	map_notification_window_table_main
	event_item_settings_main_table_td2
	*/
}
.map_notification_window_view_close_button{
	z-index: 1003;
	height:30px;
	width:30px;
	position: absolute;
	top: 0px;
	
	background: url(../images/buttons/sys_close_buttonDefault.png) center;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	filter: Alpha(Opacity=99);
	opacity: .99;	
}
.map_item_window_view{
	z-index: 1002;	
	height:230px; 
	width:650px;
	margin-top: 330px;
	/**/
	margin-left: 10px;
	position:absolute;

	border:thin #000;
	border-radius:7px; 
	vertical-align: top  !important;
	display: none;
	/*
	top:48;
	position: absolute;

	background: #000;
	*/
	background-color: rgba(255,255,54, 0.7);
}
/*--------*//*--------*/
.structured_pop_table_main{
    height:620px !important; 
    width:951px !important;
    margin-top: 0px;
	margin-left: 1px;
    background-color:rgba(255,255,255, 0.7);
    vertical-align:middle;
}
.structured_pop_table_empty{
    height:620px; 
    width:951px;
    text-align:left;
    vertical-align:text-top;
    background-color:#CF0;
    background: url(../images/buttons/butt_template.png) center;
    background-size: 700px 500px;
    background-repeat: no-repeat;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;	
}
	.pop_window_full_td {
		height:620px; 
	  	width:952px;
		text-align:left;
		vertical-align:text-top;
		display:block;
	}
	
	
	.Local_build_UI_control_class{
		z-index: 9;
		border:solid thin #FFF;
		text-align:center;
		vertical-align:middle;
		cursor:pointer;
		/* background-color:transparent; */
	}
	
	
	
	.horizontal_status_class {
		height:620px; 
	  	width:952px;
		background-color:#FFF;
		border:dashed thick #00F;
		vertical-align:text-top;
		font-size:12px; 
		font-weight:700;
		text-decoration:none;
	}
	.horizontal_status {
		width:40px;
		font-size:12px;
		font-weight:700;
		text-align:center;
		text-decoration:none;
		
	}
	/*	MAINVIEW *************************File_Modify_option_default

	 */
	
	.horizontal_image_td {
		height:565px !important; 
	  	width:755px !important;
		text-align:center;
		vertical-align:text-top;
		display:block;
		position:absolute;
		
	}
	/*
	File_Modify_option_image
	background: url(../images/buttons/butt_template.png) center;
		background-size: 170px 80px;
   		background-repeat: no-repeat;
		border:hidden;
		border:hidden;
		stat_rightside_mapmenu_td
		sys_report_button
		display:inline-block;
	*/
	
	.image_report_button {
		height:45px;
		width:50px;
		top:600;
		margin-left:5px;
		
		position:absolute;
		background: url(../images/buttons/sys_settings_button_large.png) rgba(211,211,211, 0.4);
		background-size: 45px 45px;
   		background-repeat: no-repeat;
		background-position:center;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top;
	}
	/*
	 background: rgba(211,211,211, 0.4) !important;
	*/
	.image_statcount_field{
		
	  	height:43px !important;
		width: 400;
		line-height:40px !important;
		top:600;
		margin-left:60px;
		border-spacing: 0px;
		border:1px solid #ccc;
		font-size: 1.05em;
		font-weight: 700;
		text-align:left;
		text-decoration:none;
		text-indent:50px;
		color:#000;
		background-color:rgba(211,211,211, 0.4) !important;
		
		position:absolute;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=90);
		opacity: .90;	
	  }
	/*position:absolute;
	background-color:#0F0;
	ls_form_half_field
	clear
	*/
	.horizontal_image {
		height:562px !important;
	  	width:755px !important;
		display:block;
		background: url(../images/buttons/clear_img.png);
		background-size: 750px 562px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	background-color:#F90;
	ol-viewport
	*/
	.horizontal_map {
		height:615px !important; 
	  	width:946px !important;
		margin-left:0;
		
	}
	.profile_map {
       	height:615px !important; 
        width: 100% !important;
     }
	.profile_map_default {
       	height:562px !important; 
        width: 755px !important;
		background-color:#FFF;
		background: url(../images/bkgrounds/global_default.png);
		background-size: 750px 562px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
     }
	/*	RIGHTSIDE ************************* 85   */
	
	/* -----MAP  -------*/
	
	/*
	
	background:#CCC !important;
	background: rgba(255, 255, 255, 0.4);
	top: 4.5em;
	top:200px;
	*/
	.ol-zoom-in {
		display:none !important;
		visibility:hidden !important;
	}
	.ol-zoom-out {
		display:none !important;
		visibility:hidden !important;
	}
	.ol-zoomslider {
        
		margin-top:10px !important;
		position: absolute;
		
		left: .5em;
	    background: rgba(211,211,211, 0.4) !important;
	    
		width: 25px !important;
		height: 400px !important;
		border:1px solid #ccc;
     }
	.custom_anchor:before {
    	content:"Cycle";
	}
		/*background:red;   */
	/*
	
	map_motion_control_north
	
	
	#myCustomControl {
		width:30px;
		height:30px;
	
	}
	.myControl {
		width:25px;
		height:25px;
		background:green;
	}
	.myCustomControl {
		width:35px;
		height:20px;
		background:yellow;
		border:1px solid black;
		padding:2px;
	}
	
	stat_spaceline_top_map_td
	stat_rightside_map_td
	
	ls_form_half_field_stat
	
	*/
	.map_key_control_table {
		height:100px !important;
		width:140px !important;
		border:hidden;
		background-color:transparent;
	}
	.map_key_control_td_space {
		height:5px !important;
		width:140px !important;
		line-height:5px;
		text-align:center;
		vertical-align:middle;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}		
	.map_key_control_td_title {
		height:35px !important;
		width:140px !important;
		line-height:35px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/butt_template.png);
		background-position:center;
		background-size: 140px 35px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}		
	.map_key_control_td1_likes_row1 {
		height:35px !important;
		width:35px !important;
		line-height:35px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/heatmap_like_low.png);
		background-position:center;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}	
	.map_key_control_td1_likes_row2 {
		height:35px !important;
		width:35px !important;
		line-height:35px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/heatmap_like_med.png);
		background-position:center;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}
	.map_key_control_td1_likes_row3 {
		height:35px !important;
		width:35px !important;
		line-height:35px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/heatmap_like_hi.png);
		background-position:center;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}		
	.map_key_control_td1_dislikes_row1 {
		height:35px !important;
		width:35px !important;
		line-height:35px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/heatmap_dislike_low.png);
		background-position:center;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}	
	.map_key_control_td1_dislikes_row2 {
		height:35px !important;
		width:35px !important;
		line-height:35px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/heatmap_dislike_med.png);
		background-position:center;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}
	.map_key_control_td1_dislikes_row3 {
		height:35px !important;
		width:35px !important;
		line-height:35px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/heatmap_dislike_hi.png);
		background-position:center;
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}		
	.map_key_control_td_info_row {
		height:35px !important;
		width:100px !important;
		line-height:35px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/butt_template.png);
		background-position:center;
		background-size: 100px 35px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}	
	.map_key_control_td_likes {
		height:48px !important;
		width:48px !important;
		line-height:48px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/sys_like_image.png);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}	
	.map_key_control_td_dislikes {
		height:48px !important;
		width:48px !important;
		line-height:48px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/sys_dislike_image.png);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}
	.map_key_control_td_likes_key {
		height:48px !important;
		width:100px !important;
		line-height:48px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/butt_template.png);
		background-position:center;
		background-size: 95px 40px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}	
	.map_key_control_td_dislikes_key {
		height:48px !important;
		width:100px !important;
		line-height:48px;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/butt_template.png);
		background-position:center;
		background-size: 95px 40px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}	
	.map_motion_control_table {
		width:145px !important;
		height:145px !important;
		border:hidden;
		background-color:transparent;
	}	
	.map_motion_control_td {
		width:48px !important;
		height:48px !important;
		line-height:48px;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		background-color:transparent;
		filter: Alpha(Opacity=90);
		opacity: .90;	
	}	
	.map_motion_control_north{
	  	width:48px !important;
		height:48px !important;
	  	text-align:center;
		background: url(../images/buttons/sys_map_up_arrow.png);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}
	.map_motion_control_south{
	  	width:48px !important;
		height:48px !important;
	  	text-align:center;
		background: url(../images/buttons/sys_map_down_arrow.png);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}
	.map_motion_control_east{
	  	width:48px !important;
		height:48px !important;
	  	text-align:center;
		background: url(../images/buttons/sys_map_right_arrow.png);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}
	.map_motion_control_west{
	  	width:48px !important;
		height:48px !important;
	  	text-align:center;
		background: url(../images/buttons/sys_map_left_arrow.png);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}
	.map_motion_control_locate{
	  	width:48px !important;
		height:48px !important;
	  	text-align:center;
		background: url(../images/buttons/sys_map_center_arrow.png);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}
	
	.horizontal_right_map_td {
		height:610px !important; 
	  	width:145px !important;
		text-decoration:none;
		text-align:center;
		padding:0;
		margin:0;
		display:block;
		position:absolute;
		margin-left:790;
		vertical-align:top;
	}
	/*
	border:1px solid #ccc;
	*/
	.stat_rightside_mapmenu_td{
	  	height:145px !important;
		width:152px !important;
		background-color:transparent;
		border:hidden;
	  	text-align:center;
		display:block;
		vertical-align:top;
		margin-left:1px;
		margin-top:1px;
		filter: Alpha(Opacity=99);
		opacity: .99;	
	}
	/*
	
	
	*/
	.stat_rightside_map_td{
	  	height:350px !important;
		width:140px !important;
		background-color:#FFF;
		border:1px solid #ccc;
	  	text-align:center;
		display:block;
		vertical-align:top;
		margin-left:1px;
		margin-top:10px;
		filter: Alpha(Opacity=70);
		opacity: .70;	
	}
	.stat_spaceline_top_map_td {
		height:100px !important; 
	  	width:145px !important;
		background-color:transparent;
		text-align:left !important;
		vertical-align:middle;
		text-decoration:none;
		display:block;
		margin-left:1px;
		margin-top:1px;
	}
	.stat_spaceline_bottom_map_td {
		height:5px !important; 
	  	width:145px !important;
		background-color:transparent;
		text-align:left !important;
		vertical-align:middle;
		text-decoration:none;
		display:block;
		margin-left:1px;
		margin-top:1px;
	}
	
	/* -----IMAGE -------*/
	.horizontal_right_td {
		height:610px !important; 
	  	width:185px !important;
		text-decoration:none;
		text-align:center;
		padding:0;
		margin:0;
		display:block;
		position:absolute;
		margin-left:760;
		vertical-align:top;
	}
	/*
	.stat_rightside{
		
	  	background-color:#666;
	  	top:0;
		right:5;
	  	height:565px;
		width:185px;
	  	color:#FFF;
	  	text-align:center;
	}
	*/
	.stat_rightside_td{
	  	height:181px !important;
		width:182px !important;
		background-color:#FFF;
	  	text-align:center;
		display:block;
		vertical-align:top;
		margin-left:1px;
		margin-top:1px;
	}
	/*
	
	*/
	.stat_baseline_right_td {
		height:68px !important; 
	  	width:182px !important;
		background-color:#FFF;
		text-align:left !important;
		vertical-align:middle;
		text-decoration:none;
		display:block;
		margin-left:1px;
		margin-top:1px;
	}
	/*
	 #0F0
	 #999
	 stat_baseline_right_td
	*/
	.stat_like_option_active{
	  	height:68px !important;
		width:90px !important;
		margin-left:92px;
		display:inline-block;
		position:absolute;
	  	text-align:center;
		background: url(../images/buttons/sys_like_image.png);
		background-position:center;
		background-size: 68px 68px;
   		background-repeat: no-repeat;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=90);
		opacity: .90;	
	}
	.stat_like_option_default{
	  	height:68px !important;
		width:90px !important;
		margin-left:92px;
		display:inline-block;
		position:absolute;
	  	text-align:center;
		background: url(../images/buttons/sys_like_image_default.png);
		background-position:center;
		background-size: 68px 68px;
   		background-repeat: no-repeat;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=90);
		opacity: .90;	
	}
	.stat_dislike_option_active{
	  	height:68px !important;
		width:90px !important;
		margin-left:0px;
		display:inline-block;
		position:absolute;
	  	text-align:center;
		background: url(../images/buttons/sys_dislike_image.png);
		background-position:center;
		background-size: 68px 68px;
   		background-repeat: no-repeat;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=90);
		opacity: .90;	
	}
	.stat_dislike_option_default{
	  	height:68px !important;
		width:90px !important;
		margin-left:0px;
		display:inline-block;
		position:absolute;
	  	text-align:center;
		background: url(../images/buttons/sys_dislike_image_default.png);
		background-position:center;
		background-size: 68px 68px;
   		background-repeat: no-repeat;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=90);
		opacity: .90;	
	}
	/*
	 #999
	 #F30
	  #0F0
	*/
	.stat_dislike_option_off{
	  	height:68px !important;
		width:90px !important;
		margin-left:0px;
		display:inline-block;
		position:absolute;
	  	text-align:center;
		background: url(../images/buttons/sys_dislike_image_default.png);
		background-position:center;
		background-size: 68px 68px;
   		background-repeat: no-repeat;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=90);
		opacity: .90;	
	}
	/*
	.stat_rightside_bio_div_default{
	  	height:184px !important;
		width:180px !important;
		background-color:#FF0;
	  	background: url(../images/bkgrounds/bio_piegraph_default.png);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.stat_rightside_geo_div_default{
	  	height:184px !important;
		width:180px !important;
		background-color:#FF0;
	  	background: url(../images/bkgrounds/geo_piegraph_default.png);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.stat_rightside_socio_div_default{
	  	height:184px !important;
		width:180px !important;
		background-color:#FF0;
	  	background: url(../images/bkgrounds/socio_piegraph_default.png);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=70);
		opacity: .77;
	}
	.stat_rightside_td_div_active{
	  	height:184px !important;
		width:180px !important;
		background-color:#CCC;
	  	text-align:center;
	}
	*/
	
	
	/*	BASELINE **************************/
	.horizontal_bottom_td {
		height:45px !important;
		line-height:45px !important;
	  	width:763px;
		text-decoration:none;
		text-align:left;
		vertical-align:bottom;
		margin-top:565px;
		display:inline-block;
		position:absolute;
	}/*
	.stat_baseline {
		height:42px !important; 
		line-height:42px !important;
	  	width:940px;
		
		background-color:#666;
		display:block;
		position:absolute;
		profile_view_control_td_visible_cell
	}
	*/
	.stat_baseline_report_td {
		height:45px !important; 
	  	width:50px !important;
		background-color:transparent;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		display:inline-block;
	}
	.sys_report_button {
		height:45px;
		width:50px;
		display:inline-block;
		position:relative;
		background: url(../images/buttons/sys_settings_button_large.png);
		background-size: 45px 45px;
   		background-repeat: no-repeat;
		background-position:center;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top;
	}
	/* -----MAP  -------*/
	.stat_baselinemap_left_td1 {
		height:45px !important; 
	  	width:406px !important;
		line-height:45px;
		background-color:transparent;
		text-align:center;
		vertical-align:top;
		text-decoration:none;
		display:inline-block;
	}
	.stat_baselinemap_left_td2 {
		height:45px !important; 
	  	width:289px !important;
		line-height:45px;
		background-color:transparent;
		vertical-align:top;
		text-decoration:none;
		display:inline-block;
		
	}
	/* -----IMAGE -------*/
	.stat_baseline_left_td1 {
		height:45px !important; 
	  	width:406px !important;
		line-height:45px;
		background-color:#FFF;
		text-align:center;
		vertical-align:top;
		text-decoration:none;
		display:inline-block;
	}
	.stat_baseline_left_td2 {
		height:45px !important; 
	  	width:289px !important;
		line-height:45px;
		background-color:#FFF;
		
		vertical-align:top;
		text-decoration:none;
		display:inline-block;
		padding:0;
		margin:0;
		
	}

	/*
	stat_dislike_option_default
	
	*/
	.stat_baseline_td_cell1 {
		height:45px !important; 
		line-height:45px;
	  	width:135px;
		text-align:right;
		display:inline-block;
		border:1px solid #ccc;
		margin-top:0;
		margin-left:0;
		position:absolute;
		background: url(../images/buttons/sys_dislike_image.png);
		background-size: 38px 38px;
   		background-repeat: no-repeat;
		background-position:0% 45%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	.stat_baseline_td_cell2 {
		height:45px !important; 
		line-height:45px;
	  	width:135px;
		text-align:right;
		display:inline-block;
		border:1px solid #ccc;
		margin-top:0;
		margin-left:135;
		position:absolute;
		background: url(../images/buttons/sys_like_image.png);
		background-size: 38px 38px;
   		background-repeat: no-repeat;
		background-position:0% 45%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	.ls_form_half_field_stat{
	  	height:35px !important;
		/*width: 195px;*/
		width: 65%;
		line-height:20px !important;
		background-color:#FFF;
		border-spacing: 0px;
		border:hidden;
		font-size: .85em;
		font-weight: 500;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
	  }
	.stat_baseline_div_default {
		height:35px !important; 
	  	width:47px;
		background-color:#CCC;
	}
	.stat_baseline_div_active {
		height:35px !important; 
	  	width:47px;
		background-color:#FC0;
	}
	.stat_baseline_div_active_img {
		height:31px; 
	  	width:41px;
		margin-top:2px;
	}
    /*
	#Left,
	#Right{
		height:35px;
		width:47px;
		background:#fff;
		text-align:center;
		
		cursor:pointer;
		
	}
	#Left{
		
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	#Right{
		
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	*/
	/*	IMAGE VIEW DISPLAY CONTROL ************************** horizontal_image/
	/*	IMAGE VIEW DISPLAY CONTROL **************************/
	.Page,
	.Swiper{
		
	  position:relative;
	  height:100%;
	}
	.Swiper{
		
		height:620px;
		width:952px;
	  	background:#666;
	  	overflow:hidden;
	}
	.Swiper.animate > .Page{
	  transition:all .3s;
	  -webkit-transition:all .3s;
	}
	.Page{
		
	  	height:620px;
		width:952px;
		position:absolute;
	  	display:inline-block;
	  	color:#00F;
	 	text-align:left;
	}
	.Page:nth-child(odd) {
		/*z-index:120;z-index:120;z-index:120;z-index:120;z-index:120;
		 background:#b00; */
	}
	.Page:nth-child(even) {
		/*z-index:120;
		 background:#58c;  Swiper*/
	}
	/*
		background: url(../images/buttons/butt_template.png) center;
	clear
	*/
	.File_Modify_option_default{
		top:20;
		height:90px;
		width:180px;
		background-color:transparent !important;
		position:absolute;
		cursor:pointer;
		background: url(../images/buttons/clear_img.png) #FFF;
		background-position:center;
		background-size: 170px 80px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=90);
		opacity: .90;	
		/*   */
	}
	.File_Modify_option_image{
		top:20;
		height:90px;
		width:180px;
		background-color:transparent !important;
		position:absolute;
		cursor:pointer;
		background: url(../images/buttons/butt_template.png) center;
		background-size: 170px 80px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=90);
		opacity: .90;	
		/*   */
	}
	.File_Modify_option_map{
		top:20;
		height:90px;
		width:180px;
		background-color:transparent !important;
		position:absolute;
		cursor:pointer;
		background: url(../images/buttons/sys_map_view.png) center ;
		background-size: 170px 80px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		filter: Alpha(Opacity=90);
		opacity: .90;	
		/*   */
	}
	
	
	
/*	WINDOW VIEW NOTIFICATION ****************************/
.notification_window_table{
    height:675px; 
    width:952px;
    border:thin #000;
}
.notification_window_table_td_header{
    height:30px; 
    width:952px;
    position:relative;
    text-align:right;
    background-color:#FFF;
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.notification_window_table_main{
    height:620px; 
    width:951px;
    background-color:#FFF;
    vertical-align:text-top;
    font-size:12px; 
    font-weight:700;
    text-decoration:none;
}
.notification_window_table_td_footer{
    height:25px; 
    width:952px;
    vertical-align:text-top;
    background-color:#FFF;
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
/*
map_notification_window_close

*/
	.map_notification_window_table{
		height:230px; 
	  	width:650px;
		border:thin #000;
		background-color: aqua;
		position: absolute;
		vertical-align: top;
		margin-top: 0px;
		top: 0px;
	}
	.map_notification_window_table_td_header{
		height:30px; 
	  	width:620px;
		position:relative;
		text-align:right;
		background-color:#FFF;
		filter: Alpha(Opacity=80);
		opacity: 0.8;
	}
	.map_notification_window_table_main{
		height:170px; 
	  	width:620px;
		background-color:#FFF;
		vertical-align:text-top;
		font-size:12px; 
		font-weight:700;
		text-decoration:none;
		vertical-align: top;
	}
	.map_notification_window_table_td_footer{
		height:30px; 
	  	width:620px;
		vertical-align:text-top;
		background-color:#FFF;
		filter: Alpha(Opacity=80);
		opacity: 0.8;
	}
	.map_notification_window_data{
		height:170px; 
	  	width:620px;
		line-height: 20;
		display: inline-block;
		position: absolute;
		background-color:#FFF;
		vertical-align:text-top;
		font-size:12px; 
		font-weight:700;
		text-decoration:none;
	}
	/*	
	.window_view{
		height:675px; 
	  	width:955px;
		position: absolute;
		
		
		top:0;
		border:thin #000;
		display: none;
		
		background: #000;
		border-radius:7px; 
		z-index: 10;	
		margin-top: 0px !important;
		
		
	position:absolute; 
  		top:0 !important;
		
		margin-bottom: 0px;
		margin-right: 0px;
		margin-left: -3px;
		display:block;
		top:0px;
		 padding: 0px;
		
		 position:absolute;
		left:0;
		margin:-145px 0 0 -16px; /* [-(height/2)px 0 0 -(width/2)px] 
		
	
	}
	*/	
	.progress_popup_table{
		height:675px; 
	  	width:952px;
		border:thin #000;
	}
	
	.progress_popup_table_td1{
		height:100px; 
	  	width:952px;
		background-color:#FFF;
		opacity: 0.8;
		
	}
	.progress_popup_table_td2{
		height:155px; 
	  	width:952px;
		background-color:#FFF;
		opacity: 1;
	}
	.progress_popup_table_td3{
		height:300px; 
	  	width:952px;
		background-color:#FFF;
		opacity: 0.8;
	}
	.progress_popup_table_main{
		height:220px; 
	  	width:952px;
		font-size:12px; 
		font-weight:700;
		text-decoration:none;
		box-shadow: none !important;
		opacity: 1;
	}
	.ERROR_FILE_TYPE{
		height:400px; 
	  	width:952px;
		border:thin #000;
		font-size:24px; 
		font-weight:700;
		text-decoration:none;
		border:thin #000;
		background-color:#FFC !important;
	}
	.ERROR_FILE_TYPE_TD1{
		height:200px; 
	  	width:952px;
		border:thin #000;
		font-size:24px; 
		font-weight:700;
	}
	.ERROR_FILE_TYPE_TD2{
		height:200px; 
	  	width:952px;
		border:thin #000;
		font-size:24px; 
		font-weight:700;
		background-color:#9FF;
	}
	.ERROR_POP_TD {
		height: 50px;	
		width: 180px;
		font-size:12px;
		font-weight:700;
		text-align:center;
		text-decoration:none;
	}
	.ERROR_POP_TYPE_TD1{
		height:100px; 
	  	width:952px;
		border:thin #000;
		font-size:24px; 
		font-weight:700;
	}
	.ERROR_POP_TYPE_TD2{
		height:300px; 
	  	width:952px;
		border:thin #000;
		font-size:24px; 
		font-weight:700;
		background-color:#9FF;
		text-align:center;
	}
	.ERROR_POP_TYPE_BUTTON {
		height: 150px;	
		width: 300px;
		font-size:14px;
		font-weight:700;
		text-decoration:none;
	}
	.POP_UP_CONF_TD_SPACE{
		height: 150px;	
		width: 60px;
	}
	/*  -------------------------------------------------- edit_profile_header_td1  */
	.option_window_main_table_top_button{
		height:275px; 
	  	width:390px;
	}
	.option_window_main_table_bottom_button{
		height:125px; 
	  	width:390px;
	}
	
	.notification_popup_table{
		height:675px; 
	  	width:952px;
		border:thin #000;
	}
	.notification_popup_table_td1{
		height:100px; 
	  	width:852px;
		background-color:#FFF;
		opacity: 0.8;
		
	}
	.notification_popup_table_td2{
		height:100px; 
	  	width:100px;
		vertical-align:text-top;
		text-align:center;
		opacity: 1;	
	}
	.notification_popup_table_td3{
		height:75px; 
	  	width:852px;
		vertical-align:text-top;
		background-color:#FFF;
		opacity: 0.8;
	}
	.notification_popup_table_td4{
		height:100px; 
	  	width:952px;
		vertical-align:text-top;
		background-color:#FFF;
		opacity: 0.8;
	}
	.notification_popup_table_main{
		height:400px; 
	  	width:951px;
		vertical-align:text-top;
		font-size:12px; 
		font-weight:700;
		text-decoration:none;
	}
	
	.upload_status_css{
		height:260px;
		width:950px; 
		
	}
	/*
	#progressBar {
		height: 50px;
		width: 860px;
	
	}
	*/
	.loaded_n_total {  
		font-size:12px;
		font-weight:700;
		text-decoration:none;
		box-shadow: none !important;
		padding-top:100px;
	}  
	progress{  
   	 	
		border: 0;  
		height: 18px;  
		width: 860px;
		border-radius: 9px;
		padding-top:100px;
	}  
	progress::-webkit-progress-bar {  
		
		border: 0;  
		height: 18px;  
		width: 860px;
		border-radius: 9px;  
	}  
	progress::-webkit-progress-value {  
		
		border: 0;  
		height: 18px;  
		width: 860px;
		border-radius: 9px;  
	}  
	progress::-moz-progress-bar {  
		
		border: 0;  
		height: 18px; 
		width: 860px; 
		border-radius: 9px;  
	}  
	.ui-input-text{
		border: none !important;
		box-shadow: none !important;
	}

	.side_target_label {
		height:100px;
		width:100px;	
	}
	.side_target_pop_label {
		height:245px;
		width:300px;	
	}
	.side_target_menu{
		height:205px;
		width:205px;
	}
	.side_target_menu_button {
		height:100px;
		width:100px;
		font-size:12px;
		font-weight:700;
		text-decoration:none;
		box-shadow: none !important;	
	}
	.button_assign_main_table{
		height:100px !important;
		width:100px !important;
	}
	.button_assign_main_td{
		height:50px;
		width:50px;
	}
	.button_assign_main_td_add{
		height:95px !important;
		width:95px !important;
	}
	.button_assign_main_button{
		height:40px;
		width:40px;
		box-shadow: none !important;
		text-align:center;
		vertical-align:middle;
		background-color:#FFF;
		opacity: 0.5;
	}
	.button_assign_main_button_notify{
		height:25px;
		width:25px;
	}
	
	
	
	.edit_profile_pic_main_table {
		height:420px; 
		width:480px;
	}
	.edit_profile_pic_main_image_table{
		height:420px;
		width:490px;
		background-color:#EFEFEF;
	
	}
	.edit_profile_dyn_control_update_button {
		height: 50px;	
		width: 117px;
		font-size:12px;
		font-weight:700;
		text-align:center;
		text-decoration:none;
	}
	.edit_profile_dyn_control_update_button_table {
		height: 50px;	
		width: 115px;
		font-size:12px;
		font-weight:700;
		text-align:center;
		text-decoration:none;
		padding:0;
	}
	.edit_profile_dyn_control_update_button_td1 {
		height: 48px;	
		width: 25px;
		font-size:15px;
		font-weight:700;
		text-align:left;
		text-decoration:none;
	}
	.edit_profile_dyn_control_update_button_td1_image{
		height: 25px;	
		width: 25px;
	
	}
	.edit_profile_dyn_control_update_button_td2 {
		height: 48px;	
		width: 90px;
		font-size:14px;
		font-weight:700;
		text-decoration:none;
	}
	.edit_profile_main_image_window {
		height:420px; 
		width:490px;
		resize: none;
	}
	.upload_displaysize{
		max-height: 420px;
		max-width: 490px;
		
	}
	.reset_default_profile_pic_div{
		height:100px; 
		width:100px;
		border:#CCCCCC 1px solid;
		position:absolute; 
	}
	.reset_default_profile_pic_img{
		height:100px; 
		width:100px;
	}
	.reset_default_profile_pin_div{
		height:100px; 
		width:100px;
		border:#CCCCCC 1px solid;
		position:absolute; 
	}
	.reset_default_profile_pin_img{
		height:100px; 
		width:100px;
	}
	.edit_profile_pic_main_image_template{
		height:420px;
		width:490px;
	}
	.edit_profile_pic_right_side_table {
		height:430px;
		width:210px;
	}
	
	.edit_profile_pic_footer_filler {
		height: 0px;
		width: 500px;
		background-color:#FFF;
		border:none;
		font-size:12px;
		font-weight:700;
		text-align:left;
		text-decoration:none;
	}


.imgareaselect-border1 {
    background: url(../images/buttons/border-v.gif) repeat-y left top;
}

.imgareaselect-border2 {
    background: url(../images/buttons/border-h.gif) repeat-x left top;
}

.imgareaselect-border3 {
    background: url(../images/buttons/border-v.gif) repeat-y right top;
}

.imgareaselect-border4 {
    background: url(../images/buttons/border-h.gif) repeat-x left bottom;
}

.imgareaselect-border1, .imgareaselect-border2,
.imgareaselect-border3, .imgareaselect-border4 {
    filter: alpha(opacity=50);
    opacity: 0.5;
}

.imgareaselect-handle {
	height:10px !important;
	width:10px !important;
    background-color: #fff;
    border: solid 1px #000;
    filter: alpha(opacity=50);
    opacity: 0.5;
}

.imgareaselect-outer {
    border-color: rgba(15, 5, 26, 0.6);
    filter: alpha(opacity=50);
    opacity: 0.5;
}

.imgareaselect-selection {  
	
}

div#imgareaselect-selection{
	filter: alpha(opacity=00);
    opacity: 0.0;
		
	}
/* ################################################ */
/* ######              LANDSCAPE_ACCOUNT           ##### */

	.account_table {
		height:485px;
		width:720px;
		font-size:32px;
		font-weight:700;
		text-align:left;
		text-decoration:none;
	} 
	.account_status_survey_visuallyhidden {
		display:none;
		visibility:hidden;
	}
		
	



/* ################################################ */
/* ######              LANDSCAPE_RIGHT_PANEL                ##### */
	site_orientation_window_landscape{
		height:624px  !important;
		width:220px; 
	}
/*
	window_view
mainBodyTable_td
vertical-align:top !important;mainBodyTable
*/
.window_panel_Table_td{
    height:624px;
    width:216px  !important;
    vertical-align: text-bottom;



}

.window_panel_land {
    height:624px;
    width:200px  !important;
    /*
    width:214px  !important;
    */
    position: absolute;
    background-color:transparent;
    vertical-align: text-top;
    text-align: left;
    top: 0px;
    margin-top: 2px;
    margin-left: 10px;
}
.window_panel_td1_land  {
    height:200px;
    width:200px  !important;
    background-color:#CCC;
}
.window_panel_td2_land {
    height:200px;
    width:200px !important;
    background-color:#CCC; 
}
.window_panel_td3_land  {
    height:200px;
    width:200px !important; 
    background-color:#CCC;
}
.window_wide_panel_land {
    height:624px;
    width:290px  !important;
    position: absolute;
    background-color:transparent;
    vertical-align: text-top;
    text-align: left;
    top: 0px;
    margin-top: 2px;
    margin-left: 0px;
}
.window_wide_panel_td1_land  {
    height:200px;
    width:290px  !important;
    background-color:#CCC;
}
.window_wide_panel_td2_land {
    height:200px;
    width:290px !important;
    background-color:#CCC; 
}
.window_wide_panel_td3_land  {
    height:200px;
    width:290px !important; 
    background-color:#CCC;
}
.site_orientation_window_portrait{
    border:hidden;
    visibility:collapse;
    height:0px;
    width:0px; 
}
.orientation_control_portrait{
    display:none;
}	
.window_panel_port {
    visibility:collapse;
    height:0px;
    width:0px; 
}
.window_panel_td1_port {
    visibility:collapse;
    height:0px;
    width:0px;
}
.window_panel_td2_port {
    visibility:collapse;
    height:0px;
    width:0px;
}
.window_panel_td3_port {
    visibility:collapse;
    height:0px;
    width:0px; 
}


.business_panel_Table_td{
    height:624px;
    width:210px  !important;
    vertical-align: text-top;
    position:absolute;

}
.business_panel_land {
	/*
	feed_panel_Table_td
	window_panel_td1_land
	*/
    height:620px;
    width:215px  !important;
    position: absolute;
	border-left: 1px solid #CCC;
    background-color:transparent;
    vertical-align: text-top;
    text-align: left;
    top: 0px;
    margin-top: 0px;
    margin-left: 10px;
}
.business_panel_td1_land  {
    height:200px;
    width:215px  !important;
    background-color:#CCC;
}
.business_panel_td2_land {
    height:200px;
    width:215px !important;
    background-color:#CCC; 
}
.business_panel_td3_land  {
    height:200px;
    width:215px !important; 
    background-color:#CCC;
}

/* ################################################ */
/* ######             LANDSCAPE_RATING_OPTION		  ##### */
	.clear{clear: both;}
	.tuto-cnt{width: 480px; background-color: #fff; border:#ccc 1px solid; height:auto; min-height: 400px; margin: 40px auto; padding: 40px; overflow: auto; }
	
	hr{ margin: 10px 0; border:none; border-top: #ccc 1px dotted;}
	
	/*  First Example with big stars */
	.member_feedback_rating-div{
		width:225px; height: 40px;
		border:#e9e9e9 1px solid;
		background-color:  #fefefe;
	}
	.member_feedback_rating-div .member_feedback_rating-btn{
		width: 45px; height:40px;
		float: left;
		background: url(../images/img/rate-btn.png) no-repeat;
		cursor: pointer;
	}
	.member_feedback_rating-div .member_feedback_rating-btn:hover, .member_feedback_rating-div  .member_feedback_rating-btn-hover, .member_feedback_rating-div  .member_feedback_rating-btn-active{
		background: url(../images/img/rate-btn-hover.png) no-repeat;
	}
	/* End first Example with big stars */
	
	
	/*  Second Example with big stars */
	.rate-ex2-cnt{
		width:150px; height: 30px;
		border:#e9e9e9 1px solid;
		background-color:  #f6f6f6;
	}
	.rate-ex2-cnt .rate-btn{
		width: 30px; height:30px;
		float: left;
		background: url(../images/img/rate-btn2.png) no-repeat;
		cursor: pointer;
	}
	.rate-ex2-cnt .rate-btn:hover, .rate-ex2-cnt  .rate-btn-hover, .rate-ex2-cnt  .rate-btn-active{
		background: url(../images/img/rate-btn2-hover.png) no-repeat;
	}
	/* End second Example with big stars */
	
	/*  Third Example with big stars */
	.rate-ex3-cnt{
		width:85px; height: 17px;
		border:#e9e9e9 1px solid;
		background-color:  #f6f6f6;
	}
	.rate-ex3-cnt .rate-btn{
		width: 17px; height:17px;
		float: left;
		background: url(../images/img/rate-btn3.png) no-repeat;
		cursor: pointer;
	}
	.rate-ex3-cnt .rate-btn:hover, .rate-ex3-cnt  .rate-btn-hover, .rate-ex3-cnt  .rate-btn-active{
		background: url(../images/img/rate-btn3-hover.png) no-repeat;
	}
	/* End third Example with big stars */
	
	
	/* rate result */
	.rate-result-cnt{
		width: 82px; height: 18px;
		position: relative;
		background-color: #ccc;
		border: #ccc 1px solid;
	}
	.rate-stars{
		width: 82px; height: 18px;
		background: url(../images/img/rate-stars.png) no-repeat;
		position: absolute;
	}
	.rate-bg{
		height: 18px;
		background-color: #ffbe10;
		position: absolute;
	}

/* ################################################ */
/* ######             LANDSCAPE_INFO_GUIDE		  ##### */
	.info_guide_editable_table {
		height:60px;
		width:720px;
		font-size:32px;
		font-weight:700;
		text-align:left;
		text-decoration:none;
	} 
	.info_guide_editable {
		font-size:32px;
		font-weight:700;
		text-align:center;
		text-decoration:none;
	}
	.info_guide_dyn_body {
		height:495px;
		width:150px;
		vertical-align:text-top;
	}
	.info_guide_main_image_window {
		height:503px; 
		width:573px;
		resize: none;
		background-color:#FFF;
	}
	.info_guide_main_image_window_td {
		height:495; 
		width:550px;
		background-color:#FFF;
	}
	.info_guide_dyn_body_form {
		vertical-align:text-top;
	}
	.info_guide_dyn_control_menu {
	/*	height:50px;  */
		width:150px;
		vertical-align:text-top;
	}
	.info_guide_dyn_control_menu_td {
		height:55px;
		width:149px;
	}
	.info_guide_dyn_control_update_button {
		height: 50px;	
		width: 148px;
		font-size:12px;
		font-weight:700;
		text-align:center;
		text-decoration:none;
	}
	.info_guide_dyn_control_update_button_table {
		height: 50px;	
		width: 148px;
		font-size:12px;
		font-weight:700;
		text-align:center;
		text-decoration:none;
		padding:0;
	}
	.info_guide_dyn_control_update_button_td1 {
		height: 48px;	
		width: 50px;
		font-size:15px;
		font-weight:700;
		text-align:left;
		text-decoration:none;
	}
	.info_guide_dyn_control_update_button_td1_image{
		height: 48px;	
		width: 48px;
	
	}
	.info_guide_dyn_control_update_button_td2 {
		height: 48px;	
		width: 98px;
		font-size:14px;
		font-weight:700;
		text-decoration:none;
	}
/* ################################################ */
/* ######             LANDSCAPE_DATEPICKER		  ##### */
	.reg_calendar_pop{
		height:400px;
		width:600px;
		margin-left:171px !important;
		border:thick #CCCCC solid;
	}
	
	/*  --------------------------- */
	/*  ---  DATE PICKER TOTAL  ---- */
	.ui-datepicker {
		width: 600px !important;
		/*display:inline !important;
		  display: none; */
		
		padding: .2em .2em 0;
	}
	/*  --------------------------- */
	/*  ---  DATE PICKER HEADER  ---- */
	.ui-widget-header {
	  	border: 1px solid #CCCCCC;
		background:#FEFEFE !important;
	  	color: #ffffff;
	  	font-weight: bold;
	}
	.ui-datepicker .ui-datepicker-header {
		height: 50px !important;
		padding: .2em 0;
		position: relative;
		background-color:#3F0;
	}
	/*  --------------------------- */
	/*  -------  DEFAULT ICON ----- */
	.ui-widget-header .ui-icon {
	  background:#CCCCCC;
	 /*   background-image: url(images/ui-icons_ffffff_256x240.png);  */
	}
	
	.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
		height: 47px!important;
		width: 40px !important;
		border:thin #CCCCCC solid;
		position: absolute;
		top: 2px;
	}
	
	/*  --------------------------- */
	/*  ------   HOVER ICON  ------ */
	.ui-state-hover .ui-icon,.ui-state-focus{
	  background:#CCCCCC;
	 /*   background-image: url(images/ui-icons_ffffff_256x240.png);  */
	}
	
	.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover { 
		top: 2px;
		
	}

	.ui-datepicker .ui-datepicker-prev { 
		/*  background:#6F3;  */
		left: 2px;		 
	}
	

	.ui-datepicker .ui-datepicker-next { 
		/*  background:#6F3;  */
		right: 2px; 
	}
	
	.ui-datepicker .ui-datepicker-prev-hover {
		left: 2px; 
	}
	
	.ui-datepicker .ui-datepicker-next-hover {
		right: 2px; 
	}
	
	.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
		display: block;
		left: 50%;
		margin-left: -8px;
		margin-top: -8px;
		position: absolute;
		top: 50%;

	}
	
	.ui-datepicker .ui-datepicker-title {
		line-height: 45px !important;
		margin: 0 2.3em;
		text-align: center;
	}
	
	.ui-datepicker .ui-datepicker-title select {
		height: 40px  !important;
		font-size: 24;
		margin: 1px 0;
	}
	
	.ui-datepicker select.ui-datepicker-month-year {  
		height: 40px  !important; 
		width: 90%  !important; 
	}
	
	.ui-datepicker select.ui-datepicker-month ,
	.ui-datepicker select.ui-datepicker-year { 
		height: 50px  !important; 
		width: 45%  !important; 
	}
	
	/*  --------------------------- */
	/*  ---  DATE PICKER TABLE  ---- */
	
	.ui-datepicker table {
		background:#EFEFEF;
		width: 100%;
		border-collapse: collapse;
		font-size: .9em;
		margin: 0 0 .4em;
		
	}
	
	.ui-datepicker th {
		border: 0;
		font-weight: bold;
		padding: .7em .3em;
		text-align: center;
	}
	
	.ui-datepicker td {
		height:45px;
		border: 0;
		padding: 1px;
	}
	
	.ui-datepicker td span, .ui-datepicker td a {
		height:45px;
		display: block;
		padding: .2em;
		text-align: right;
		text-decoration: none;
	}
	/*  --------------------------- */
	/*  ---  DATE PICKER FONT  ---- */
	.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
	  	border: 1px solid #cccccc;
	  	background:#FEFEFE;
	  	font-weight: bold;
		font-size: 16px;
	  	color: #1c94c4;
	}
	/*  -----     HOOVER     ----- */
	.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover {
	  	border: 1px solid #cccccc;
	  	background:#0F0;
	  	font-weight: bold;
		font-size: 16px;
	  	color: #3F0;
	}
	.ui-datepicker .ui-datepicker-buttonpane {
		height:45px;
		background-image: none;
		border-bottom: 0;
		border-left: 0;
		border-right: 0;
		margin: .7em 0 0 0;
		padding: 0 .2em;
	}
	
	.ui-datepicker .ui-datepicker-buttonpane button {
		height:45px;
		cursor: pointer;
		float: right;
		margin: .5em .2em .4em;
		overflow: visible;
		padding: .2em .6em .3em .6em;
		width: auto;
	}
	
	.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current { float: left; }
	
	/* with multiple calendars */
	
	.ui-datepicker.ui-datepicker-multi { width: auto; }
	
	.ui-datepicker-multi .ui-datepicker-group { float: left; }
	
	.ui-datepicker-multi .ui-datepicker-group table {
		margin: 0 auto .4em;
		width: 95%;
	}
	
	.ui-datepicker-multi-2 .ui-datepicker-group { width: 50%; }
	
	.ui-datepicker-multi-3 .ui-datepicker-group { width: 33.3%; }
	
	.ui-datepicker-multi-4 .ui-datepicker-group { width: 25%; }
	
	.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header { border-left-width: 0; }
	
	.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header { border-left-width: 0; }
	
	.ui-datepicker-multi .ui-datepicker-buttonpane { clear: left; }
	
	.ui-datepicker-row-break {
		clear: both;
		font-size: 0em;
		width: 100%;
	}
	
	/* RTL support */
	
	.ui-datepicker-rtl { direction: rtl; }
	
	.ui-datepicker-rtl .ui-datepicker-prev {
		left: auto;
		right: 2px;
	}
	
	.ui-datepicker-rtl .ui-datepicker-next {
		left: 2px;
		right: auto;
	}
	
	.ui-datepicker-rtl .ui-datepicker-prev:hover {
		left: auto;
		right: 1px;
	}
	
	.ui-datepicker-rtl .ui-datepicker-next:hover {
		left: 1px;
		right: auto;
	}
	
	.ui-datepicker-rtl .ui-datepicker-buttonpane { clear: right; }
	
	.ui-datepicker-rtl .ui-datepicker-buttonpane button { float: left; }
	
	.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current { float: right; }
	
	.ui-datepicker-rtl .ui-datepicker-group { float: right; }
	
	.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
		border-left-width: 1px;
		border-right-width: 0;
	}
	
	.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
		border-left-width: 1px;
		border-right-width: 0;
	}
	
	/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
	
	.ui-datepicker-cover {
		filter: mask(); /*must have*/
		height: 200px; /*must have*/
		left: -4px; /*must have*/
		position: absolute; /*must have*/
		top: -4px; /*must have*/
		width: 200px; /*must have*/
		z-index: -1; /*must have*/
	}
	
	
	.datepicker_popup_table{
		height:675px; 
	  	width:952px;
		
	
		
		border:thin #000;
	}
	.datepicker_popup_table_td1{
		height:100px; 
	  	width:852px;
		background-color:#FFF;
		opacity: 0.8;
		
	}
	.datepicker_popup_table_td2{
		height:100px; 
	  	width:100px;
		vertical-align:text-top;
		text-align:center;
		opacity: 1;	
	}
	/*.datepicker_popup_table_td3{
		height:75px; 
	  	width:852px;
		vertical-align:text-top;
		background-color:#0F0;
		opacity: 0.8;
	}*/
	.datepicker_popup_table_td4{
		height:100px; 
	  	width:952px;
		vertical-align:text-top;
		background-color:#FFF;
		opacity: 0.8;
	}
	.datepicker_popup_table_main{
		height:475px; 
	  	width:951px;
		background-color:#FFF;
		
		font-size:12px; 
		font-weight:700;
		text-decoration:none;
	}
/* ################################################ */
/* ######          LANDSCAPE_GENDERPICKER	  ##### */
	.reg_gender_pop{
		height:400px;
		width:600px;
		margin-left:171px !important;
		border:thick #CCCCC solid;
	}
	/*
	.genderpicker_popup_table{
		height:675px; 
	  	width:952px;
		border:thin #000;
	}
	.genderpicker_popup_table_td1{
		height:100px; 
	  	width:852px;
		background-color:#FFF;
		opacity: 0.8;
		
	}
	.genderpicker_popup_table_td2{
		height:100px; 
	  	width:100px;
		vertical-align:text-top;
		text-align:center;
		opacity: 1;	
	}
	.datepicker_popup_table_td3{
		height:75px; 
	  	width:852px;
		vertical-align:text-top;
		background-color:#0F0;
		opacity: 0.8;
	}
	.genderpicker_popup_table_td4{
		height:100px; 
	  	width:952px;
		vertical-align:text-top;
		background-color:#FFF;
		opacity: 0.8;
	}
	.genderpicker_popup_table_main{
		height:475px; 
	  	width:951px;
		background-color:#FFF;
		padding:5px;
		
		font-size:12px; 
		font-weight:700;
		text-decoration:none;
	}*/
	.gender_table_main{
		height:620px !important; 
	  	width:951px !important;
		background-color:#FFF;
		vertical-align:middle;
		padding:0;
		margin:0;
	}
	
	.gender_table_main_td{
		height:92px; 
	  	width:92px;
		background-color:#FFF;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	/*
	
	*/
.genderpicker_popup_table_button{
    height:87px; 
    width:111px;
    vertical-align:top;
    font-size: .70em;
    white-space: normal;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color:#FFF; 
    border:thin #CCC solid;
    display:inline-block;
    margin-left:3px;
    margin-right:0px;
    margin-top:5px;
    margin-bottom:5px;
}
/*
base_functional_sys_search_tr
sys_search_bar_field_default
*/
.feed_pagination_container_td_search1 {
	height: 50px;
	width: 175px !important;
	position:relative;
	margin-top: 0px;
    margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_pagination_container_td_search2 {
	height: 50px;
	width: 656px !important;
	position:relative;
	margin-top: 0px;
    margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.popup_table_button_active{
	z-index: 100;
	border: 2px solid rgba(10, 245, 92, 0.7) !important;
}
.businesstype_popup_table_button{
    height:93px; 
    width:125px;
    white-space: normal;
    display:inline-block;
    margin-top:3px;
    margin-left:2px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(239,239,239, 0.7);
    vertical-align:middle;
	text-align: center;
	font-size: 0.80em;
	font-weight: 500;
    
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
    text-decoration:none;
}
.businesstype_search_bar_field{
    height:40px !important;
    width: 550px;
   /* width: 99%;*/
    line-height:25px !important;
	position: absolute;
    margin-top: 5px;
    margin-left: 2px;
    background-color:rgba(239,239,239, 0.7);
    background-position: -850px 0px;
    background-size: 28px 25px;
    
    border-spacing: 0px;
	border-radius: 05px;
    border: 2px solid rgba(203,203,203, 0.3);
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:33px;
    /*
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    
    -webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/* ============================================================
  COMMON    switch
============================================================ */
#wrapper {
  min-width: 600px;
}

.settings {
  display: table;
  width: 100%;
}
.settings .row {
  display: table-row;
}
.settings .question,
.settings .switch {
	/*
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
	
  */
  height: 30px !important;
  width: 130px !important;
  display:inline-block;
  text-align:center;
  padding: 5px;
  
}
.settings .question {
  width: 600px;
  font-family: "Roboto Slab", serif;
  font-size: 20px;
}
/*
switch
*/
/* ============================================================
  COMMON
============================================================ */
.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* ============================================================
  SWITCH BLANK - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat + label:before, input.cmn-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "--";
}

input.cmn-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 52px;
  background-color: #dddddd;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat:checked + label {
  background-color: #8ce196;
}
input.cmn-toggle-round-flat:checked + label:after {
  margin-left: 60px;
  background-color: #8ce196;
}
/* ============================================================
  SWITCH activityStatus - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-activityStatus + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-activityStatus + label:before, input.cmn-toggle-round-flat-activityStatus + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-activityStatus + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-activityStatus + label:after {
  	display: block;
  	position: absolute;
  	text-align:center;
  	font-size:9px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-activityStatus + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-activityStatus + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 52px;
  background-color: #dddddd;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-activityStatus:checked + label {
  background-color: #8ce196;
}
input.cmn-toggle-round-flat-activityStatus:checked + label:after {
  margin-left: 60px;
  background-color: #8ce196;
}
/* ============================================================
  SWITCH privacySetting - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-privacySetting + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-privacySetting + label:before, input.cmn-toggle-round-flat-privacySetting + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-privacySetting + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-privacySetting + label:after {
  display: block;
  position: absolute;
  text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-privacySetting + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-privacySetting + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 52px;
  background-color: #dddddd;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-privacySetting:checked + label {
  background-color: #8ce196;
}
input.cmn-toggle-round-flat-privacySetting:checked + label:after {
  margin-left: 60px;
  background-color: #8ce196;
}
/* ============================================================
  SWITCH viewerRestrictions - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-viewerRestrictions + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-viewerRestrictions + label:before, input.cmn-toggle-round-flat-viewerRestrictions + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-viewerRestrictions + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-viewerRestrictions + label:after {
  display: block;
  position: absolute;
  text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-viewerRestrictions + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-viewerRestrictions + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 52px;
  background-color: #dddddd;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-viewerRestrictions:checked + label {
  background-color: #8ce196;
}
input.cmn-toggle-round-flat-viewerRestrictions:checked + label:after {
  margin-left: 60px;
  background-color: #8ce196;
}
/* ============================================================
  SWITCH userReqNotification - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-userReqNotification + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-userReqNotification + label:before, input.cmn-toggle-round-flat-userReqNotification + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-userReqNotification + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-userReqNotification + label:after {
  display: block;
  position: absolute;
 text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-userReqNotification + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-userReqNotification + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 52px;
  background-color: #dddddd;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-userReqNotification:checked + label {
  background-color: #8ce196;
}
input.cmn-toggle-round-flat-userReqNotification:checked + label:after {
  margin-left: 60px;
  background-color: #8ce196;
}

/* ============================================================
  SWITCH userMessageNotification - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-userMessageNotification + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-userMessageNotification + label:before, input.cmn-toggle-round-flat-userMessageNotification + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-userMessageNotification + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-userMessageNotification + label:after {
  display: block;
  position: absolute;
  	text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-userMessageNotification + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-userMessageNotification + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 52px;
  background-color: #dddddd;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-userMessageNotification:checked + label {
  background-color: #8ce196;
}
input.cmn-toggle-round-flat-userMessageNotification:checked + label:after {
  margin-left: 60px;
  background-color: #8ce196;
}
/* ============================================================
  SWITCH dislikeOption - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-dislikeOption + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-dislikeOption + label:before, input.cmn-toggle-round-flat-dislikeOption + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-dislikeOption + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-dislikeOption + label:after {
  display: block;
  position: absolute;
  	text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-dislikeOption + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-dislikeOption + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 52px;
  background-color: #dddddd;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-dislikeOption:checked + label {
  background-color: #8ce196;
}
input.cmn-toggle-round-flat-dislikeOption:checked + label:after {
  margin-left: 60px;
  background-color: #8ce196;
}
/* ============================================================
  SWITCH eventReqNotification - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-eventReqNotification + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-eventReqNotification + label:before, input.cmn-toggle-round-flat-eventReqNotification + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-eventReqNotification + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-eventReqNotification + label:after {
  display: block;
  position: absolute;
 text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-eventReqNotification + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-eventReqNotification + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 52px;
  background-color: #dddddd;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-eventReqNotification:checked + label {
  background-color: #8ce196;
}
input.cmn-toggle-round-flat-eventReqNotification:checked + label:after {
  margin-left: 60px;
  background-color: #8ce196;
}
/* ============================================================
  SWITCH proximityNotification - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-proximityNotification + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-proximityNotification + label:before, input.cmn-toggle-round-flat-proximityNotification + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-proximityNotification + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-proximityNotification + label:after {
  display: block;
  position: absolute;
  text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-proximityNotification + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-proximityNotification + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 52px;
  background-color: #dddddd;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-proximityNotification:checked + label {
  background-color: #8ce196;
}
input.cmn-toggle-round-flat-proximityNotification:checked + label:after {
  margin-left: 60px;
  background-color: #8ce196;
}
/* ============================================================
  SWITCH calendarNotification - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-calendarNotification + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-calendarNotification + label:before, input.cmn-toggle-round-flat-calendarNotification + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-calendarNotification + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-calendarNotification + label:after {
  display: block;
  position: absolute;
  text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-calendarNotification + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-calendarNotification + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 52px;
  background-color: #dddddd;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-calendarNotification:checked + label {
  background-color: #8ce196;
}
input.cmn-toggle-round-flat-calendarNotification:checked + label:after {
  margin-left: 60px;
  background-color: #8ce196;
}
/* ============================================================
  SWITCH systemNotification - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-systemNotification + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-systemNotification + label:before, input.cmn-toggle-round-flat-systemNotification + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-systemNotification + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-systemNotification + label:after {
  display: block;
  position: absolute;
  text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-systemNotification + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-systemNotification + label:after {
  	top: 4px;
  	left: 4px;
  	bottom: 4px;
 	 width: 52px;
  	background-color: #dddddd;
  	-webkit-border-radius: 52px;
  	-moz-border-radius: 52px;
  	-ms-border-radius: 52px;
  	-o-border-radius: 52px;
 	 border-radius: 52px;
  	-webkit-transition: margin 0.4s, background 0.4s;
  	-moz-transition: margin 0.4s, background 0.4s;
 	-o-transition: margin 0.4s, background 0.4s;
  	transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-systemNotification:checked + label {
  	background-color: #8ce196;
}
input.cmn-toggle-round-flat-systemNotification:checked + label:after {
	margin-left: 60px;
	background-color: #8ce196;
}
/* ============================================================
  SWITCH followingNotification - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-followingNotification + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-followingNotification + label:before, input.cmn-toggle-round-flat-followingNotification + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-followingNotification + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-followingNotification + label:after {
  display: block;
  position: absolute;
  text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-followingNotification + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-followingNotification + label:after {
  	top: 4px;
  	left: 4px;
  	bottom: 4px;
 	 width: 52px;
  	background-color: #dddddd;
  	-webkit-border-radius: 52px;
  	-moz-border-radius: 52px;
  	-ms-border-radius: 52px;
  	-o-border-radius: 52px;
 	 border-radius: 52px;
  	-webkit-transition: margin 0.4s, background 0.4s;
  	-moz-transition: margin 0.4s, background 0.4s;
 	-o-transition: margin 0.4s, background 0.4s;
  	transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-followingNotification:checked + label {
  	background-color: #8ce196;
}
input.cmn-toggle-round-flat-followingNotification:checked + label:after {
	margin-left: 60px;
	background-color: #8ce196;
}
/* ============================================================
  SWITCH followersNotification - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-followersNotification + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-followersNotification + label:before, input.cmn-toggle-round-flat-followersNotification + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-followersNotification + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-followersNotification + label:after {
  display: block;
  position: absolute;
  text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-followersNotification + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-followersNotification + label:after {
  	top: 4px;
  	left: 4px;
  	bottom: 4px;
 	 width: 52px;
  	background-color: #dddddd;
  	-webkit-border-radius: 52px;
  	-moz-border-radius: 52px;
  	-ms-border-radius: 52px;
  	-o-border-radius: 52px;
 	 border-radius: 52px;
  	-webkit-transition: margin 0.4s, background 0.4s;
  	-moz-transition: margin 0.4s, background 0.4s;
 	-o-transition: margin 0.4s, background 0.4s;
  	transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-followersNotification:checked + label {
  	background-color: #8ce196;
}
input.cmn-toggle-round-flat-followersNotification:checked + label:after {
	margin-left: 60px;
	background-color: #8ce196;
}
/* ============================================================
  SWITCH recommendersNotification - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-recommendersNotification + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-recommendersNotification + label:before, input.cmn-toggle-round-flat-recommendersNotification + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-recommendersNotification + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-recommendersNotification + label:after {
  display: block;
  position: absolute;
  text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-recommendersNotification + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-recommendersNotification + label:after {
  	top: 4px;
  	left: 4px;
  	bottom: 4px;
 	 width: 52px;
  	background-color: #dddddd;
  	-webkit-border-radius: 52px;
  	-moz-border-radius: 52px;
  	-ms-border-radius: 52px;
  	-o-border-radius: 52px;
 	 border-radius: 52px;
  	-webkit-transition: margin 0.4s, background 0.4s;
  	-moz-transition: margin 0.4s, background 0.4s;
 	-o-transition: margin 0.4s, background 0.4s;
  	transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-recommendersNotification:checked + label {
  	background-color: #8ce196;
}
input.cmn-toggle-round-flat-recommendersNotification:checked + label:after {
	margin-left: 60px;
	background-color: #8ce196;
}
/* ============================================================
  SWITCH agendaNotification - ROUND FLAT
============================================================ */
input.cmn-toggle-round-flat-agendaNotification + label {
  padding: 2px;
  width: 120px;
  height: 25px;
  background-color: #dddddd;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-agendaNotification + label:before, input.cmn-toggle-round-flat-agendaNotification + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-round-flat-agendaNotification + label:before{
  display: block;
  position: absolute;
  
}
input.cmn-toggle-round-flat-agendaNotification + label:after {
  display: block;
  position: absolute;
  text-align:center;
  	font-size:10px;
	font-weight:600;
	text-decoration:none;
  	content: "";
}

input.cmn-toggle-round-flat-agendaNotification + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat-agendaNotification + label:after {
  	top: 4px;
  	left: 4px;
  	bottom: 4px;
 	 width: 52px;
  	background-color: #dddddd;
  	-webkit-border-radius: 52px;
  	-moz-border-radius: 52px;
  	-ms-border-radius: 52px;
  	-o-border-radius: 52px;
 	 border-radius: 52px;
  	-webkit-transition: margin 0.4s, background 0.4s;
  	-moz-transition: margin 0.4s, background 0.4s;
 	-o-transition: margin 0.4s, background 0.4s;
  	transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat-agendaNotification:checked + label {
  	background-color: #8ce196;
}
input.cmn-toggle-round-flat-agendaNotification:checked + label:after {
	margin-left: 60px;
	background-color: #8ce196;
}
/* ################################################ */
/* ######          LANDSCAPE_MARKETPLACE	 
*/

	.pin_upload{
		display:none;
		visibility:hidden;
		border:0; 
		padding:0;
		margin:0;
	}
	/*    text-align:center;   */
	.marketplace_general_table_td1{
		height:514px !important;
		width:718px !important;
		line-height:514px !important;
		background:#FFF;
		border:hidden;
		vertical-align:top;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_menu{
		height:50px !important; 
	  	width:718px !important;
		line-height:50px !important;
		background-color:#FFF;
		vertical-align:middle;
		text-align:center;
		border-right:hidden;
		border-left:hidden;
		border-top: hidden;
		border-bottom:1px solid #ccc;
	}
	.marketplace_menu_td_basic{
		height:50px !important; 
	  	width:75px !important;
		line-height:50px !important;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
		border:hidden;
	}
	/*
	marketplace_option_custom_active
	
	marketplace_main
	
	.marketplace_menu_td2{
		height:50px !important; 
	  	width:75px !important;
		line-height:50px !important;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
		border:hidden;
	}
	.marketplace_menu_td3{
		height:50px !important; 
	  	width:75px !important;
		line-height:50px !important;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
		border:hidden;
	}
	.marketplace_menu_td4{
		height:50px !important; 
	  	width:75px !important;
		line-height:50px !important;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
		border:hidden;
	}
	.marketplace_menu_td5{
		height:50px !important; 
	  	width:318px !important;
		line-height:50px !important;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
		border:hidden;
	}
	.marketplace_menu_td6{
		height:50px !important; 
	  	width:100px !important;
		line-height:50px !important;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle;
		border:hidden;
	}
	*/
	.marketplace_option_default {
		height: 48px !important;
		width: 73px !important;
		line-height:48px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/sys_pin_marketplaceDefault.png) ;
		background-size: 46px 46px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	border:hidden;
	
	*/
	.marketplace_option_default_active {
		height: 48px !important;
		width: 73px !important;
		line-height:48px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		border:1px solid #CCC;
		background: url(../images/buttons/sys_pin_marketplaceActive.png) ;
		background-size: 46px 46px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_option_block {
		height: 48px !important;
		width: 73px !important;
		line-height:48px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/sys_block_marketplaceDefault.png);
		background-size: 46px 46px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	border:hidden;
	
	*/
	.marketplace_option_block_active {
		height: 48px !important;
		width: 73px !important;
		line-height:48px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		border:1px solid #CCC;
		background: url(../images/buttons/sys_block_marketplaceActive.png);
		background-size: 46px 46px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_option_styled {
		height: 48px !important;
		width: 73px !important;
		line-height:48px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/sys_styled_marketplaceDefault.png);
		background-size: 46px 46px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	border:hidden;
	
	*/
	.marketplace_option_styled_active {
		height: 48px !important;
		width: 73px !important;
		line-height:48px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		border:1px solid #CCC;
		background: url(../images/buttons/sys_styled_marketplaceActive.png);
		background-size: 46px 46px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_option_custom {
		height: 48px !important;
		width: 73px !important;
		line-height:48px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/sys_basket_marketplaceDefault.png);
		background-size: 46px 46px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	border:hidden;
	
	*/
	.marketplace_option_custom_active {
		height: 48px !important;
		width: 73px !important;
		line-height:48px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		border:1px solid #CCC;
		background: url(../images/buttons/sys_basket_marketplaceActive.png);
		background-size: 46px 46px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_filter_active {
		height: 48px !important;
		width: 98px !important;
		line-height:48px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 90px 46px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	
	.ls_default_results_main{
		height:404px !important; 
	  	width:718px !important;
		line-height:404px !important; 
		
		text-align:left;
		vertical-align:text-top !important; 
	}
	
	.ls_default_results_main_row{
	  	width:717px !important;
		display:block;
		text-align:left;
		vertical-align:text-top;
		
	}
	.ls_default_results_button {
		width:139px !important;
		max-width: 141px !important;
		text-align:center !important;
		position:relative;
   		background-repeat: no-repeat;
    	background-position: center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.ls_default_results_button_div {
		height:30px !important;
		width:137px !important;
		max-width: 141px !important;
		position:absolute;
		top:90px;
		left:3;
		text-align:center !important;
		background-color: rgba(255,255,255,0.3);
   		background-repeat: no-repeat;
    	background-position: center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	marketplace_option_default
	
	
	*/
	.marketplace_main{
		height:418px !important; 
	  	width:718px !important;
		line-height:418px !important; 
		
		text-align:left;
		vertical-align:text-top !important; 
	}
	/*  height:50px !important;  background-color:#FFF;  */
	.marketplace_main_row{
	  	width:717px !important;
		display:block;
		text-align:left;
		vertical-align:text-top;
	}

	.marketplace_pin_button {
		width:139px !important;
		max-width: 141px !important;
		text-align:center !important;
		position:relative;
   		background-repeat: no-repeat;
    	background-position: center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_pin_button_div {
		height:30px !important;
		width:137px !important;
		max-width: 141px !important;
		position:absolute;
		top:90px;
		left:3;
		text-align:center !important;
		background-color: rgba(255,255,255,0.3);
   		background-repeat: no-repeat;
    	background-position: center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.marketplace_pin_td_frame {
		height: 130px;
		width: 141px !important;
		line-height:130px;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/clear_img.png) #FFF;
		background-size: 120px 120px;
   		background-repeat: no-repeat;
		background-position:center;
		background-color:#FFF;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_pagination{
		height:58px !important; 
	  	width:714px !important;
		line-height:58px;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle; 
		border-top:1px solid #EFEFEF;
		border-bottom:1px solid #EFEFEF;
		border-left:1px solid #EFEFEF;
		border-right:1px solid #EFEFEF;
		padding:0;
		margin:0;
	}
	/********************w 951 h620 */
	.marketplace_pop_table_top1{
		height:500px !important; 
	  	width:751px !important;
		background-color:#FFF;
		text-align:center !important;
		vertical-align:middle !important;
	}
	.marketplace_image_div{
		height:450px !important; 
	  	width:751px !important;
		text-align:center !important;
		vertical-align:middle;
		background-repeat: no-repeat;
    	background-position: center;
		background-size: 60%;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_pop_table_top2{
		height:500px !important; 
	  	width:200px !important;
		background-color:#FFF;
		text-align:center !important;
		vertical-align:middle;
	}
	.marketplace_pop_table_top2_1{
		height:168px !important; 
	  	width:198px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:hidden;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
	}
	.marketplace_pop_table_top2_2{
		height:166px !important; 
	  	width:198px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:hidden;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
	}
	.marketplace_pop_table_top2_3{
		height:166px !important; 
	  	width:198px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:hidden;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
	}
	.marketplace_pop_table_base{
		height:120px !important; 
	  	width:951px !important;
		background-color:#FFF;
		text-align:center !important;
		vertical-align:middle;
	}
	.marketplace_pop_table_base_1{
		height:120px !important; 
	  	width:751px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:hidden;
		border-top:1px solid #CCC;
	}
	.marketplace_pop_table_base_2{
		height:120px !important; 
	  	width:198px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
	}
	
	.pin_submit_form_button{
		height:118px !important; 
	  	width:196px !important;
		text-align:center !important;
		vertical-align:middle;
		background: url(../images/buttons/butt_template.png) center;
		background-size: 100px 100px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	/*	***********	settings_general_table_td1  settings_general_table_td2  pagination_td1*/
	
	.marketplace_edit_table{
		height:390px !important;
		width:718px !important;
		line-height:390px !important;
		background:#FFF;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_edit_table_td1{
		height:390px !important;
		width:418px !important;
		line-height:390px !important;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_edit_table_td2{
		height:390px !important;
		width:300px !important;
		line-height:390px !important;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_edit_table_td2_row1{
		height:50px !important;
		width:300px !important;
		line-height:50px !important;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_edit_table_td2_row2{
		height:200px !important;
		width:300px !important;
		line-height:200px !important;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_edit_table_td2_row3{
		height:140px !important;
		width:300px !important;
		line-height:140px !important;
		text-align:center;
		vertical-align:middle;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_edit_table_command{
		height:60px !important;
		width:718px !important;
		line-height:60px !important;
		background:#CCC;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_edit_table_command_underline{
		height:67px !important;
		width:718px !important;
		line-height:67px !important;
		background:#CCC;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_edit_table_command_line_td1{
		height:60px !important;
		width:179px !important;
		line-height:60px !important;
		text-align:right;
		background:#CCC;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_edit_table_command_line_td2{
		height:60px !important;
		width:179px !important;
		line-height:60px !important;
		text-align:center;
		background:#CCC;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_edit_table_command_line_td3{
		height:60px !important;
		width:152px !important;
		line-height:60px !important;
		text-align:left;
		background:#CCC;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.marketplace_edit_table_command_line_td4{
		height:60px !important;
		width:206px !important;
		line-height:60px !important;
		text-align:left;
		background:#CCC;
		border:hidden;
		margin:0;
		padding:0;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.mp_form_name_field{
	  	height:40px !important;
		width: 280;
		line-height:40px !important;
		background-color:#EEEEEE;
		border-spacing: 0px;
		border:hidden;
		font-size: .85em;
		font-weight: 500;
		text-align:left;
		text-decoration:none;
		text-indent:5px;

	  }
	 .mp_form_tags_field{
	  	height:130px !important;
		width: 280;
		line-height:20px !important;
		background-color:#EEEEEE;
		border-spacing: 0px;
		border:hidden;
		font-size: .85em;
		font-weight: 500;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
		vertical-align:top;
		resize: none;
	 	overflow: auto;
	  }
	  .mp_form_description_field{
	  	height:180px !important;
		width: 280;
		line-height:20px !important;
		
		background-color:#EEEEEE;
		border-spacing: 0px;
		border:hidden;
		font-size: .85em;
		font-weight: 500;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
		vertical-align:top;
		resize: none;
		overflow: auto;
	  }
	/*	***********	 marketplace_edit_table_td1 */
	
/* ################################################ */
/* ######          LANDSCAPE_PROFILE_MEMBER	  ######### pic_member_member activity_status_header	base_functional_table	base_functional_sys_search_tr height:617px !important; submit File_Upload_Label_class */


	/*
	width:720px !important;
	base_cont_profile_table_empty
	
	*/
	.base_rec_profile_table_empty {
		height:254px !important;
		width:231px !important;
		line-height:254px;
		position:absolute;
		border: hidden;
		text-align:center;
		vertical-align:middle;
		border-spacing: 0px;
		background: url(../images/buttons/butt_template.png) ;
		background-size: 180px 100px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	} 
	.base_cont_empty_table_image {
		height:254px !important;
		width:720px !important;
		line-height:254px;
		position:absolute;
		border: hidden;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/butt_template.png);
		background-size: 180px 100px;
   		background-repeat: no-repeat;
		background-position:center;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	} 
	.base_cont_profile_table_empty {
		height:120px !important;
		width:720px !important;
		line-height:120px;
		background:#999;
		border: hidden;
		text-align:center;
		vertical-align:middle;
		background: url(../images/buttons/butt_template.png);
		background-size: 180px 100px;
   		background-repeat: no-repeat;
		background-position:center;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	} 

	.base_functional_full_table {
		/*
		base_functional_table
		*/
		height:614px !important;
		width:714px !important;
		background-color:rgba(255,255,255, 0.3);
		border:1px solid rgba(233,233,233, 0.8);
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	} 
	.base_functional_full_table_td {
		width:715px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.base_functional_extended_full_table {
		height:617px !important;
		width:950px !important;
		background:#FFF;
		border-right: 1px solid #ccc;
		border-left: 1px solid #ccc;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	} 
	/*
	select_account_table_header01
	*/ 
	.base_functional_left_panel_full_table_td {
		width:720px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.base_functional_right_panelfull_table_td {
		height:615px;
		width:230px;
		border-right: hidden;
		border-left: 1px solid #ccc;
		border-top: hidden;
		border-bottom: hidden;
		border-spacing: 0px;
		position:absolute;
		text-align:center;
		max-height: 615px;
		table-layout:fixed;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
.profile_standard_reporting_active{
	z-index: 2000;
	height:14px !important;
	width:14px !important;

	border-spacing: 0px;
	border:hidden;
	position:absolute;
	
	margin-top:-15px;
	margin-left:146px;

	background: url(../images/buttons/butt_template.png) rgba(240,239,136, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}


.profile_pic_edit {
	height: 28px;
	width: 28px;
	display:inline-block;
	position:absolute;

	vertical-align:top;
	top:5px;
	margin-left: 130px;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.profile_settings_edit {
	height: 28px;
	width: 28px;
	display:inline-block;
	position:absolute;
	vertical-align:top;
	top:5px;
	margin-left: 345px;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.profile_recommendations_edit {
	height: 28px;
	width: 28px;
	display:inline-block;
	position:absolute;

	vertical-align:top;
	margin-top: 1px;
	margin-left: 690px;

	border:1px solid #ccc;
	background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
event_standard_frame_rec_large_td2
event_standard_frame_rec_long_td1
base_functional_profile_table_td_r2
recommendation_long_edit
recommendation_large_edit

*/
.profile_standard_frame_rec_large_td1{
	height:102px !important;
	width:141.0px !important;
	line-height: 102px;
	margin-top: 0px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.profile_standard_frame_rec_large_td2{
	height:102px !important;
	width:141.0px !important;
	line-height: 102px;
	margin-top: 0px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.profile_standard_frame_rec_large_td3{
	height:102px !important;
	width:141.0px !important;
	line-height: 102px;
	margin-top: 0px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.profile_standard_frame_rec_large_td4{
	height:102px !important;
	width:141.0px !important;
	line-height: 102px;
	margin-top: 0px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.profile_standard_frame_rec_long_td1{
	height:59px !important;
	width:187.0px !important;
	line-height: 57px;
	margin-top: 0px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.profile_standard_frame_rec_long_td2{
	height:59px !important;
	width:187.0px !important;
	line-height: 58px;
	margin-top: 0px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.profile_standard_frame_rec_long_td3{
	height:59px !important;
	width:187.0px !important;
	line-height: 58px;
	margin-top: 0px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.profile_standard_frame_rec_long_td4{
	height:59px !important;
	width:187.0px !important;
	line-height: 58px;
	margin-top: 0px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.profile_standard_frame_rec_outstand_td1{
	height:122px !important;
	width:165px !important;
	line-height: 12px;
	margin-top: 0px;
	position: absolute;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.profile_standard_frame_rec_outstand_td2{
	height:122px !important;
	width:165px !important;
	line-height: 12px;
	margin-top: 0px;
	margin-left: 168px;
	position: absolute;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
	recommendation_long_edit
	profile_pic_edit
	profile_standard_frame_rec_large_td1
	profile_standard_frame_rec_long_td
profile_standard_frame_rec_outstand_td1
```profile_standard_frame_rec_out_default
business_block_frame_rec_large_default
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
profile_standard_frame_rec_out_default


*/
.profile_standard_frame_rec_large_default{
	height:102px !important;
	width:140.0px !important;
	line-height: 100px;
	margin-top: 0px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 100px 100px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}
.profile_standard_frame_rec_large_active{
	height:102px !important;
	width:140.0px !important;
	line-height: 100px;
	margin-top: 0px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 100px 100px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.80em;
    font-weight: 500;
}
.profile_standard_frame_rec_long_default{
	height:60px !important;
	width:186.0px !important;
	line-height: 12px;
	margin-top: 0px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 168px 57px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
	
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}
.profile_standard_frame_rec_long_active{
	height:49px !important;
	width:170.0px !important;
	line-height: 59px;
	margin-top: 5px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,85, 0.3); 
	background-size: 168px 57px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.80em;
    font-weight: 500;
}
/*
	profile_standard_frame_rec_outstand_td1
*/
.profile_standard_frame_rec_out_default{
	height:121px !important;
	width:163.0px !important;
	line-height: 15px;
	margin-top: 0px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 121px 121px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}
.profile_standard_frame_rec_out_active{
	height:110px !important;
	width:140.0px !important;
	line-height: 100px;
	margin-top: 5px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,85, 0.3); 
	background-size: 110px 80px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.80em;
    font-weight: 500;
}
/***************************************/
/***************************************/
/*
*/
.profile_identity_default{
	height:33px !important;
	width:40px !important;
	line-height: 12px;
	margin-top: 2px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 38px 30px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.profile_identity_active{
	height:33px !important;
	width:40px !important;
	line-height: 12px;
	margin-top: 2px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,85, 0.3); 
	background-size: 38px 30px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/***************************************/
	/*  	font-weight: bold;
profile_view_control_td2
    	color: #000000; 
	.profile_view_control_td_visible_cell_structure{
		height:45px;
		width:45px;
		display:inline-block;
		position:relative;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top;
	}
	.profile_view_control_td_visible_cell {
		height: 28px;
		width: 28px;
		display:inline-block;
		position:relative;
		vertical-align:top;
		
		margin-top: 0;
		margin-left: 0px;
		
		border:1px solid #ccc;
		background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
		background-size: 23px 23px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}



	.profile_view_control_td_visible_cell_stucture {
		height:45px;
		width:200px;
		display:inline-block;
		position:relative;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

*/
/*
	.profile_view_control_td_visible_cell_title {
		height:45px;
		width:202px;
		display:inline-block;
		position:relative;
		background: url(../images/buttons/butt_template.png);
		background-size: 180px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
base_functional_profile_table_td_r1_3
pic_member_member
results_frame_option_3
*/

/*
	.profile_view_control_title_edit_structure {
		
		display:inline-block;
		position:absolute;
		left:350px;
		font-size: 1em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.member_view_control_rec_con_title {
		height:47px;
		width:254px;
		display:inline-block;
		position:relative;
		font-size: 1em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.profile_view_control_td_visible_header_cell_title {
		height:60px;
		width:395px;
		display:inline-block;
		
		
		border:1px solid #ccc;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
*/
	/*
	
	position:relative;
	background: url(../images/buttons/butt_template.png);
		background-size: 330px 40px;
   		background-repeat: no-repeat;
		background-position:center;
	*/
	.visible_cell_title{
		height:40px !important;
		width:200px !important;
		font-size: 1em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:0;
		padding:0;
		margin:0;
	
	}
/*   */
/*   */
.base_functional_profile_table_menu {
    height:40px !important;
    width:715px;
    vertical-align: top;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(255,255,255, 0.2);
} 
.base_functional_profile_table_menu_td {
    height:40px !important;
    width:176px !important;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(255,255,255, 0.2);
    vertical-align:top;
} 
.base_functional_profile_table_menu_td_end {
    height:40px !important;
    width:176px !important;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(255,255,255, 0.2);
    vertical-align:top;
} 
.contributions_button {
    height: 40px !important;
    width: 176px !important;
    line-height:50px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-color:rgba(255,255,255, 0.2);
}
.contributions_avtive_button {
    height: 40px !important;
    width: 176px !important;
    line-height:50px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-color:rgba(204,204,204, 0.7);
}
.following_button {
    height: 40px !important;
    width: 176px !important;
    line-height:50px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-color:rgba(255,255,255, 0.2);
}
.following_avtive_button {
    height: 40px !important;
    width: 176px !important;
    line-height:50px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-color:rgba(204,204,204, 0.7);
}
.followers_button {
    height: 40px !important;
    width: 176px !important;
    line-height:50px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-color:rgba(255,255,255, 0.2);
}
.followers_avtive_button {
    height: 40px !important;
    width: 176px !important;
    line-height:50px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-color:rgba(204,204,204, 0.7);
}
.recommendations_button {
    height: 40px !important;
    width: 176px !important;
    line-height:50px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-color:rgba(255,255,255, 0.2);
}
.recommendations_avtive_button {
    height: 40px !important;
    width: 176px !important;
    line-height:50px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-color:rgba(204,204,204, 0.7);
}
	/*   */
	/*   */
.base_functional_profile_table_td_r1 {
    height:161px !important;
    width:720px;
    vertical-align: top;
} 
.base_functional_profile_table_td_r1_1 {
    height:161px !important;
    width:161px;
    border-spacing: 0px;
    vertical-align:top;
    border-spacing: 0px;
    border:1px thin rgba(209,209,209, 0.3);
    background-color: rgba(239,239,239, 0.5);
} 
.profile_view_control_td1_1{
    height:45px !important;
    width: 45px !important;
    background-color:#FFF;
    top:5px;
    left:116px;
    position:absolute;
    text-align:right !important;
    vertical-align:middle !important;

    opacity: 0.9;
    filter: alpha(opacity=90); /* For IE8 and earlier */
}
/*
sys_option_default
*/

	
	
.base_functional_profile_table_td_r1_2 {
    height:161px !important;
    width:180px;
    vertical-align:top;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.3);
	/*
	base_functional_profile_table_td_r4_1
	profile_settings_edit
	*/
} 
.profile_view_control_td1_2{
    height:45px !important;
    width: 45px !important;
    background-color:rgba(255,255,255, 0.7);
    left:116px;
    text-align:right !important;
    vertical-align:middle !important;
    position:relative;
    opacity: 0.9;
    filter: alpha(opacity=90); /* For IE8 and earlier */
}
.profile_activity_percent {
	height:161px !important;
	width:178px;
	border-spacing: 0px;
	top:4px;
	vertical-align:middle;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: 2.25em;
	font-weight: 600;
	
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3);
	background-size: 165px 135px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.profile_activity_percent_increase {
	height:161px !important;
	width:178px;
	border-spacing: 0px;
	top:4px;
	
    background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3);
    /*
	background: url(../images/buttons/object_score_increase.png) rgba(239,239,239, 0.3);
    */
	background-size: 178px 161px;
    /*
	background-size: 165px 135px;
    */
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    
    text-indent: 0px;
    color:#000;
    font-size: 2.25em !important;
    font-weight: 700 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
.profile_activity_percent_decrease {
	height:161px !important;
	width:178px;
	border-spacing: 0px;
	top:4px;
    /*
    padding-top: 5px;
    
    */
    padding-left: 15px;
    background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3);
    /*
	background: url(../images/buttons/object_score_decrease.png) rgba(239,239,239, 0.3);
    */
    background-size: 178px 161px;
    /*
	background-size: 165px 135px;
    */
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    
    text-indent: 0px;
    color:#000;
    font-size: 2.25em !important;
    font-weight: 700 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}	
	
.base_functional_profile_table_td_r1_3 {
    height:161px !important;
    width:370px;
    border:hidden;
    border-spacing: 0px;
    vertical-align:top;
} 
.profile_view_control_td1_3{
    height:70px !important;
    width: 370px !important;
    background-color:rgba(255,255,255, 0.7);
    left:0px;
    text-align:center;
    vertical-align:top !important;
    position:relative;
    opacity: 0.9;
    filter: alpha(opacity=90); /* For IE8 and earlier */
}
.base_functional_profile_table_td_r1_3_r1 {
    height:70px !important;
    width:370px;
	border-spacing: 0px;
    border:hidden;
    vertical-align:top;
} 
.base_functional_profile_table_td_r1_3_r2 {
    height:50px !important;
    width:370px;
	vertical-align:top;
	border-spacing: 0px;
    border:1px solid rgba(209,209,209, 0.2);
	
    
}
.base_functional_profile_table_td_r1_3_r3 {
    height:40px !important;
    width:370px;
    vertical-align:top;
	margin-top: 1px;
	border-spacing: 0px;
    border:1px solid rgba(209,209,209, 0.2);
	
} 


/*
	
*/
/*
	.base_functional_profile_table_td_r1_3_td3 {
		height:50px !important;
		width:131px;
		border-right:1px solid #CCC;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
		border-spacing: 0px;
		vertical-align:top;
	} 
	.base_functional_profile_table_td_r1_3_tdend {
		height:50px !important;
		width:131px;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
		border-spacing: 0px;
		vertical-align:top;
	} 
*/
	/*   */	
	/* height:157px !important;
	
	312px !important;
	
	  */
/*
.base_functional_profile_table_td_r2 {
    height:309px !important;
    width:720px;
    vertical-align: top;
    text-align: left;
    border-right:hidden;
    border-left:hidden;
    border-top:1px solid #CCC;
    border-bottom:hidden;
} 
	.base_functional_profile_table_td_r2_1 {
		height:309px !important;
		width:720px !important;
		border:hidden;
		border-spacing: 0px;
		vertical-align:top;
	} 
	.base_functional_profile_table_td_r2_1row {
		height:309px !important;
		width:720px;
		border-spacing: 0px;
		position:absolute;
		border:hidden;
		vertical-align:top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
*/
/*
	.profile_view_control_td2{
		height:48px !important;
		width: 155px !important;
		background-color:rgba(255,255,24, 0.9);
		left:562px;
		text-align:right !important;
		vertical-align:top !important;
		position:relative;
		
	}/*opacity: 0.9;
    	filter: alpha(opacity=90); 
		*//* For IE8 and earlier */
/*
	.base_functional_profile_table_td_r2_2row {
		height:38px !important;
		width:720px;
		border-spacing: 0px;
		border:hidden;
		vertical-align:top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.base_functional_profile_table_td_r3 {
		height:155px !important;
		width:720px;
		
		vertical-align: top;
	
		border-right:hidden;
		border-left:hidden;
		border-top:1px solid #CCC;
		border-bottom:1px solid #CCC;
	} 
	.base_functional_profile_table_td_r3_1 {
		height:155px !important;
		width:720px !important;
		border:hidden;
		border-spacing: 0px;
		vertical-align:top;
	} 
	.base_functional_profile_table_td_r3_1row {
		height:155px !important;
		width:720px;
		border-spacing: 0px;
		position:absolute;
		border:hidden;
		vertical-align:top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
*/
	.profile_contribution_button {
		width:141px !important;
		max-width: 141px !important;
   		background-repeat: no-repeat;
    	background-position: center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	
	*/ 
	.member_contribution_button {
		position:relative;
		width:141px !important;
		max-width: 141px !important;
		vertical-align:top !important;
		border:hidden;
		background-size: 130px 130px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*
base_functional_profile_table_td_r1_3_r2
*/
/*****************************/
.base_functional_identity_td {
	height:40px !important;
	width:393px;
	/*
	border:1px solid #CCC;
	*/
	border-spacing: 0px;
	vertical-align:middle;
}
.base_functional_identity_td1 {
	height:38px !important;
	width:49px;
	border-right:hidden;
	border-left:hidden;
	border-top:hidden;
	border-bottom:hidden;
	border-left:hidden;
	border-spacing: 0px;
	vertical-align:middle;
	text-align: center;
}
.base_functional_identity_td2 {
	height:38px !important;
	width:49px;
	border-right:hidden;
	border-left:hidden;
	border-top:hidden;
	border-bottom:hidden;
	border-left:1px solid #CCC;
	border-spacing: 0px;
	vertical-align:middle;
	text-align: center;
} 
/*****************************/
.base_functional_report_td1 {
	height:30px !important;
	width:127px;
	border-right:hidden;
	border-left:hidden;
	border-top:hidden;
	border-bottom:hidden;
	border-left:hidden;
	border-spacing: 0px;
	vertical-align:middle;
	text-align: center;
} 
.base_functional_report_td2 {
	height:30px !important;
	width:67px;
	border-right:hidden;
	border-left:hidden;
	border-top:hidden;
	border-bottom:hidden;
	border-left:1px solid #CCC;
	border-spacing: 0px;
	vertical-align:middle;
	text-align: center;
} 
.base_functional_report_td3 {
	height:30px !important;
	width:67px;
	border-right:hidden;
	border-left:hidden;
	border-top:hidden;
	border-bottom:hidden;
	border-left:1px solid #CCC;
	border-spacing: 0px;
	vertical-align:middle;
	text-align: center;
}
.base_functional_report_td4 {
	height:30px !important;
	width:134px;
	border-right:hidden;
	border-left:1px solid #CCC;
	border-top:hidden;
	border-bottom:hidden;
	border-spacing: 0px;
	vertical-align:middle;
	text-align: center;
} 
/*****************************/
.base_functional_action_td1 {
	height:50px !important;
	width:300px !important;
	line-height: 50px;
	vertical-align:middle;
	text-align: center;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
	/*
	base_functional_profile_table_td_r4_1
	*/
} 
.base_functional_action_td1_BLOCK{
	height:50px !important;
	width:40px !important;
	line-height: 50px;
	vertical-align:middle;
	text-align: center;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
	/*
	base_functional_profile_table_td_r4_1
	*/
} 
.base_functional_action_td2 {
	height:50px !important;
	width:22px !important;
	line-height: 50px;
	vertical-align:middle;
	text-align: center;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
} 
.base_functional_action_td3 {
	height:50px !important;
	width:102px !important;
	line-height: 50px;
	vertical-align:middle;
	text-align: center;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
} 
.base_functional_action_td4 {
	height:50px !important;
	width:102px !important;
	/*
	width:100%;
	
	*/
	line-height: 50px;
	vertical-align:middle;
	text-align: center;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
}
.base_functional_action_td5 {
	height:50px !important;
	width:102px !important;
	line-height: 50px;
	border-spacing: 0px;
	vertical-align:middle;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
	text-align: center;
}
.base_functional_action_td5_BLOCK {
	height:50px !important;
	width:205px !important;
	line-height: 50px;
	border-spacing: 0px;
	vertical-align:middle;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
	text-align: center;
}
.base_functional_action_td8 {
	height:50px !important;
	width:102px !important;
	line-height: 50px;
	border-spacing: 0px;
	vertical-align:middle;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
	text-align: center;
} 
.base_functional_action_td6 {
	height:50px !important;
	width:92px !important;
	line-height: 50px;
	border-spacing: 0px;
	vertical-align:middle;
	text-align: center;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
}
.base_functional_action_td7 {
	height:50px !important;
	width:495px !important;
	line-height: 50px;
	border-spacing: 0px;
	vertical-align:middle;
	text-align: center;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
} 
/*****************************/
.member_follow_button_active {
	height:50px !important;
	width:95px !important;
	display: inline-block;
	vertical-align:middle;
	background-color:rgba(239,239,239, 0.7);
	/*
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 90px 40px;
	background-repeat: no-repeat;
	background-position:center;
	*/
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.member_unfollow_button_active {
	height:50px !important;
	width:95px !important;
	display: inline-block;
	vertical-align:middle;
	background-color:rgba(252, 71, 71, 0.5);
	/*
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.5);
	background-size: 90px 40px;
	background-repeat: no-repeat;
	background-position:center;
	*/
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.member_pending_button_active {
	height:50px !important;
	width:95px !important;
	display: inline-block;
	vertical-align:middle;
	background-color:rgba(255, 255, 128, 0.7);
	/*
	background: url(../images/buttons/butt_template.png) rgb(255, 255, 128, 0.7);
	background-size: 90px 40px;
	background-repeat: no-repeat;
	background-position:center;
	*/
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.member_message_button_active {
	height:50px !important;
	width:95px !important;
	display: inline-block;
	vertical-align:middle;
	background-color:rgba(10, 245, 92, 0.4);
	/*
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.4);
	background-size: 90px 40px;
	background-repeat: no-repeat;
	background-position:center;
	*/
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
base_functional_action_td5

*/
/*****************************/
.member_follower_button {
	height:50px !important;
	width:95px !important;
	display: inline-block;
	vertical-align:middle;
	background-color:rgba(252, 71, 71, 0.5);
	/*
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.5);
	background-size: 90px 40px;
	background-repeat: no-repeat;
	background-position:center;
	*/
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.member_not_follower_button {
	height:50px !important;
	width:95px !important;
	display: inline-block;
	vertical-align:middle;
	background-color:rgba(239,239,239, 0.7);
	/*
	background: url(../images/buttons/butt_template.png) rgb(239,239,239, 0.7);
	background-size: 90px 40px;
	background-repeat: no-repeat;
	background-position:center;
	*/
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.member_approve_follower_button {
	height:50px !important;
	width:95px !important;
	display: inline-block;
	vertical-align:middle;
	background-color:rgba(10, 245, 92, 0.5);
	/*
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.5);
	background-size: 90px 40px;
	background-repeat: no-repeat;
	background-position:center;
	*/
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.member_deny_follower_button {
	height:50px !important;
	width:95px !important;
	display: inline-block;
	vertical-align:middle;
	background-color:rgba(252, 71, 71, 0.5);
	/*
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.5);
	background-size: 90px 40px;
	background-repeat: no-repeat;
	background-position:center;
	*/
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*****************************/
.basic_recommend_div {
	z-index: 300;
	height:45px !important;
	
	width:175px !important;
	vertical-align:middle;
	position: absolute;
	border:hidden;
	margin-top: 5px;
	margin-left: 5px;
	background-color: rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.member_recommend_action_approve {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:5px;
	margin-left:90px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.member_recommend_action_deny {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:5px;
	margin-left:5px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(252, 71, 71, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.member_recommend_button_active {
	height:50px !important;
	width:80px !important;
	
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.9);
	background-size: 70px 40px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.member_unrecommend_button_active {
	height:50px !important;
	width:80px !important;
	
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.9);
	background-size: 70px 40px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.member_pending_recommend_button_active {
	height:50px !important;
	width:80px !important;
	
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.9);
	background-size: 70px 40px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*****************************/
.member_block_button_active {
	height:28px !important;
	width:65px !important;
	vertical-align:middle;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 50px 26px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.member_unblock_button_active {
	height:28px !important;
	width:65px !important;
	vertical-align:middle;
	background: url(../images/buttons/butt_template.png) rgb(10, 245, 92, 0.7);
	background-size: 50px 26px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.member_report_button_active {
	height:28px !important;
	width:65px !important;
	vertical-align:middle;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 50px 26px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
/*****************************/
/*
	.profile_pending_button_active {
		height:50px !important;
		width:131px !important;
   		background: url(../images/buttons/butt_template.png) #C00;
		background-size: 128px 48px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}  
	.member_non_option_button_active {
		height:50px !important;
		width:131px !important;
   		background: url(../images/buttons/butt_template.png) #C00;
		background-size: 128px 48px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.member_blocked_button_active {
		height:50px !important;
		width:131px !important;
   		background: url(../images/buttons/butt_template.png) #C00;
		background-size: 128px 48px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.member_cancel_button_active {
		height:50px !important;
		width:131px !important;
   		background: url(../images/buttons/butt_template.png) #C00;
		background-size: 128px 48px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
*/
.profile_private_active {
	height:28px !important;
	width:131px !important;
	background: url(../images/buttons/butt_template.png) #C00;
	background-size: 128px 26px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.profile_public_active {
	height:28px !important;
	width:131px !important;
	background: url(../images/buttons/butt_template.png) #C00;
	background-size: 128px 26px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
/*
	.profile_city_active {
		height:50px !important;
		width:131px !important;
   		background: url(../images/buttons/butt_template.png) #FFF;
		background-size: 128px 48px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
*/
	/*
	.marketplace_pin_button {	marketplace_pin_button	marketplace_pin_td_frame
		width:131px !important;
		max-width: 141px !important;
		text-align:center !important;
		position:relative;
   		background-repeat: no-repeat;
    	background-position: center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.marketplace_pin_td_frame {
		height: 120px;
		width: 141px !important;
		line-height:120px;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/clear_img.png) #FFF;
		background-size: 110px 110px;
   		background-repeat: no-repeat;
		background-position:center;
		background-color:#FFF;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	ls_default_results_button */
	.ls_form_med_option_frame {
		height:130px !important;
		width:141px;
		border-spacing: 0px;
		top:4px;
		background: url(../images/buttons/clear_img.png);
		background-size: 120px 120px;
   		background-repeat: no-repeat;
		background-position:center;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	/*	member_contribution_button
	height:130px !important;
		width:141px;
		border-spacing: 0px;
		top:4px;
		position:absolute;
		background: url(../images/buttons/clear_img.png);
		background-size: 120px 120px;
   		background-repeat: no-repeat;
		background-position:center;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
	*/
	.ls_form_med_option_frame_absolute {
		height:130px !important;
		width:141px;
		border-spacing: 0px;
		top:4px;
		position:absolute;
		background: url(../images/buttons/clear_img.png);
		background-size: 120px 120px;
   		background-repeat: no-repeat;
		background-position:center;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
	} 
	.profile_contribution_frame {
		height:130px !important;
		width:141px;
		border-spacing: 0px;
		top:4px;
		position:absolute;
		background: url(../images/buttons/butt_template.png);
		background-size: 120px 120px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	/*text-align:left !important;height:130px !important;
	.base_functional_profile_table_td_r3_2  marketplace_pin_button {
		height:152px !important;
		width:400px !important;
		border:hidden;
		border-spacing: 0px;
		vertical-align:top;
		base_functional_profile_table_menu
	} 
	*/
	.profile_view_control_td3{
		height:45px !important;
		width: 255px !important;
		background-color:#FFF;
		left:465px;
		text-align:right !important;
		vertical-align:middle !important;
		position:relative;
		opacity: 0.9;
    	filter: alpha(opacity=90); /* For IE8 and earlier */
	}
/*   */
/*  base_functional_profile_table_menu_td */
.base_functional_profile_table_td_r4 {
    height:63px !important;
    width:720px;
    line-height: 63px;
	vertical-align:top;
    text-align: center;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
} 
.base_functional_profile_table_td_r4_1 {
    height:55px !important;
    width:400px !important;
	vertical-align:top;
    text-align: center;
    border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
} 
.base_functional_profile_table_td_r4_2 {
    height:55px !important;
    width:70px !important;
	vertical-align:top;
    text-align: center;
    border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
} 
.base_functional_profile_table_td_r4_3 {
    height:55px !important;
    width:130px !important;
    vertical-align:top;
    text-align: center;
	border-spacing: 0px;
	border:1px thin rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.2);
} 
/*
	base_functional_action_td1

*/
	.base_functional_calendar_table_td_r1 {
		height:0px !important;
		width:720px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
		background-color:#666;
	} 
	.base_functional_calendar_table_td_r2 {
		height:517px !important;
		width:720px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
	} 
	.base_functional_calendar_table_td_r3 {
		height:100px !important;
		width:720px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
	} 
	.base_functional_calendar_table_td_r3_1 {
		height:100px !important;
		width:320px !important;
		border:hidden;
		border-spacing: 0px;
		vertical-align:top;
	} 
	.base_functional_calendar_table_td_r3_2 {
		height:100px !important;
		width:400px !important;
		border:hidden;
		border-spacing: 0px;
	} 
	/*   */
	/*  marketplace_filter_active  */
	.base_functional_listview_filter_menu {
		height:36px !important;
		width:720px;
		display:inline-block;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}

	.base_functional_listview_filter_option {
		height:36px !important;
		width:100px !important;
		display:inline-block;
		
		position: absolute;
		left: 10px;
		top: 10px;
		
		border-right:1px solid #CCC;
		border-left:1px solid #CCC;
		border-top:1px solid #CCC;
		border-bottom:1px solid #CCC;
		border-spacing: 0px;
		
		background: url(../images/buttons/clear.png);
		background-size: 90px 32px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
.return_listview_filter_option {
    height:36px !important;
    width:98px !important;
    display:inline-block;
    border:hidden;
	margin-top: 2px;
	border-spacing: 0px;
    border:1px solid #CCC;
    
    background: url(../images/buttons/system_back.png) rgba(239,239,239, 0.9);
    background-size: 90px 32px;
    background-repeat: no-repeat;
    background-position:center;
    
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.home_return_option {
    height:36px !important;
    width:58px !important;
    display:inline-block;
    /*
    position: absolute;
    */
    left: 0px;
    top: 0px;

    border-right:1px solid #CCC;
    border-left:1px solid #CCC;
    border-top:1px solid #CCC;
    border-bottom:1px solid #CCC;
    border-spacing: 0px;

    background: url(../images/buttons/butt_template.png) ;
    background-size: 50px 32px;
    background-repeat: no-repeat;
    background-position:center;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_return_option {
	/*
	event_container_td_left_item_top1
	*/
    height:73px !important;
    width:95px !important;
    display:inline-block;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 2px;
	/*
    font-size: .85em;
    font-weight: 500;
	*/
    text-align:left;
    text-decoration:none;
    text-indent:35px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background: url(../images/buttons/standard_parent_home.png) rgba(239,239,239, 0.9);
    background-size: 65px 65px;
    background-repeat: no-repeat;
	background-position: center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.special_event_item_return_option {
    height:73px !important;
    width:95px !important;
    display:inline-block;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 2px;
	/*
    font-size: .85em;
    font-weight: 500;
	*/
    text-align:left;
    text-decoration:none;
    text-indent:35px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background: url(../images/buttons/standard_parent_home.png) rgba(239,239,239, 0.9);
    background-size: 65px 65px;
    background-repeat: no-repeat;
	background-position: center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.base_functional_listview_return_option {
    height:36px !important;
    width:98px !important;
    display:inline-block;

    background-color: transparent;

    left: 10px;
    top: 10px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.base_functional_listview_filter_menutd1 {
    height:36px !important;
    width:505px;
    display:inline-block;
    /*
    pagination_count
    ls_default_results_button
    */
    background-color: transparent;

    vertical-align: top;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
}
.base_functional_listview_filter_menutd2 {
    height:36px !important;
    width:105px !important;
    display:inline-block;

    background-color: transparent;

    vertical-align: top;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
}
.base_functional_listview_search_menutd1 {
    height:38px !important;
    width:532px;
	line-height: 38px;
    display:inline-block;
    background-color: transparent;

    vertical-align: top;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
}
.base_functional_listview_search_menutd2 {
    height:38px !important;
    width:180px !important;
	line-height: 38px;
    display:inline-block;

    background-color: transparent;

    vertical-align: top;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
}
.base_functional_listview_search_menutd3 {
    height:36px !important;
    width:714px;
    display:inline-block;
    /*
    pagination_count
    ls_default_results_button
    */
    background-color: transparent;

    vertical-align: top;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
}

.base_functional_listview_pagination_tr {
    /*
    base_functional_listview_filter_menutd1
    */
    height:36px !important;
    width:610px;
    display:inline-block;
    position: absolute;
    margin-top: -40px;
    margin-left: 170px;

    background-color:aquamarine;

    vertical-align: top;
    text-align: center;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.7);
}
.base_functional_listview_search_tr {
	/*
	base_functional_listview_filter_menutd1
	ls_search_bar_field
	return_listview_filter_option
	*/
	height:36px !important;
	width:535px;
	display:inline-block;
	
	margin-top: 0px;
	margin-left: 1px;

	background-color:rgba(239,239,239, 0.2);

	vertical-align:top;
	text-align:left;
	border-right:1px solid rgba(239,239,239, 0.7);
	border-left:1px solid rgba(239,239,239, 0.7);
	border-top:1px solid rgba(239,239,239, 0.7);
	border-bottom:1px solid rgba(239,239,239, 0.7);
}
.base_functional_sys_search_tr {
	/*
    activate_div
	profile_header_table_td2
    sys_search_bar_field_default
	*/
	height:45px !important;
	width:560px;
	display:inline-block;
	
	margin-top: 2px;
	margin-left: 5px;

	background-color: transparent;

	vertical-align:top;
	text-align:left;
	border:1px solid rgba(239,239,239, 0.7);
	/*
	background: url(../images/buttons/butt_template.png) rgba(239,239,47, 0.7);
	background-size: 90px 32px;
   	background-repeat: no-repeat;
	background-position:center;
	*/
}
.base_functional_listview_filter_tr {
	/*
	base_functional_listview_filter_menutd1
	feed_profile_add_control
	*/
	height:36px !important;
	width:235px;
	display:inline-block;
	
	margin-top: 1px;
	margin-left: 2px;

	background-color:rgba(255,255,255, 0.7);

	vertical-align: top;
	text-align: left;
	border-right:1px solid rgba(239,239,239, 0.7);
	border-left:1px solid rgba(239,239,239, 0.7);
	border-top:1px solid rgba(239,239,239, 0.7);
	border-bottom:1px solid rgba(239,239,239, 0.7);
}
.base_functional_listview_micro_filter_tr {
	/*
	base_functional_listview_filter_menutd1
	feed_profile_add_control
	*/
	height:36px !important;
	width:175px;
	display:inline-block;
	
	margin-top: 1px;
	margin-left: 2px;

	background-color:rgba(255,255,255, 0.7);

	vertical-align: top;
	text-align: left;
	border-right:1px solid rgba(239,239,239, 0.7);
	border-left:1px solid rgba(239,239,239, 0.7);
	border-top:1px solid rgba(239,239,239, 0.7);
	border-bottom:1px solid rgba(239,239,239, 0.7);
}
.base_functional_sys_disabled_logo {
	height:45px !important;
	width:560px;
	display:inline-block;
	margin-top: 0px;
	margin-left: 5px;
	vertical-align:top;
	text-align:left;
	border:1px solid rgba(239,239,239, 0.0);
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}


	.listview_filter_active {
		height: 36px !important;
		width: 98px !important;
		line-height:36px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 90px 32px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	/*   */
	/*   */
	.base_functional_listview_table_td_r1 {
		height:480px !important;
		width:720px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
		
	} 
	/* background-color:#FFF; pagination */
	.base_functional_listview_table_td_r2 {
		height:56px !important;
		width:720px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
	} 
	/*   */
	/*   */
	/*   */
	/*  position:fixed; */
	.profile_view_control{
		height:100px !important;
		width: 320px !important;
		background-color:transparent;
		text-align:left !important;
		vertical-align:middle !important;
	}
	.profile_view_control_cell{
		height:99px !important;
		width: 99px !important;
		background-color:transparent;
		text-align:center !important;
		vertical-align:middle !important;
		display:inline-block;
	}
	.profile_calendar{
		height:466px !important;
		width: 718px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #FFF;
		background-size: 550px 350px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.profile_view_profile_default{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #CCC;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.profile_view_profile_active{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.profile_view_calendar_default{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #CCC;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.profile_view_calendar_active{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.profile_view_activity_default{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #CCC;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.profile_view_activity_active{
		height:99px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 99px 99px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*   */	
	/*   */
	.base_functional_member_table_td_r2 {
		height:274px !important;
		width:720px;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:1px solid #CCC;
		border-bottom:hidden;
	} 
	.base_functional_member_table_td_r2_1 {
		height:274px !important;
		width:720px !important;
		border:hidden;
		border-spacing: 0px;
		vertical-align:top;
	} 
	/*
	
	*/
	.base_functional_member_table_td_r2_1row {
		height:274px !important;
		width:720px;
		border-spacing: 0px;
		position:absolute;
		border:hidden;
		vertical-align:top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.base_functional_member_table_td_r3 {
		height:140px !important;
		width:720px;
		
		vertical-align: top;
	
		border-right:hidden;
		border-left:hidden;
		border-top:1px solid #CCC;
		border-bottom:hidden;
	} 
	.base_functional_member_table_td_r3_1 {
		height:140px !important;
		width:720px !important;
		border:hidden;
		border-spacing: 0px;
		vertical-align:top;
	} 
	.base_functional_member_table_td_r3_1row {
		height:140px !important;
		width:720px;
		border-spacing: 0px;
		position:absolute;
		border:hidden;
		vertical-align:top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	pin_submit_form_button
	*/
	.member_opt_pop_table_top1{
		height:540px !important; 
	  	width:751px !important;
		background-color:#FFF;
		text-align:center !important;
		vertical-align:middle !important;
	}
	.member_opt_pop_table_top1_1{
		height:477px !important; 
	  	width:751px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:hidden;
		border-left:1px solid #CCC;
		border-right:hidden;
		border-top:1px solid #CCC;
	}
	.member_opt_pop_table_top1_2{
		height:100px !important; 
	  	width:751px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:hidden;
		border-left:1px solid #CCC;
		border-right:hidden;
		border-top:1px solid #CCC;
	}
	.member_opt_submit_form_button{
		height:98px !important; 
	  	width:176px !important;
		text-align:center !important;
		vertical-align:middle;
		background: url(../images/buttons/butt_template.png) center;
		background-size: 166px 95px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.member_small_opt_submit_form_button{
		height:35px !important; 
	  	width:120px !important;
		text-align:center !important;
		vertical-align:middle;
		background: url(../images/buttons/butt_template.png) center;
		background-size: 115px 30px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.member_opt_image_div{
		height:420px !important; 
	  	width:751px !important;
		text-align:center !important;
		vertical-align:middle;
		background-repeat: no-repeat;
    	background-position: center;
		background-size: 60%;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.member_opt_pop_table_top1_1td1{
		height:477px !important; 
	  	width:374px !important;
		background-color:#CC0;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:hidden;
		border-left:1px solid #CCC;
		border-right:hidden;
		border-top:hidden;
	}
	.member_opt_pop_table_top1_1td2{
		height:477px !important; 
	  	width:374px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:hidden;
		border-left:1px solid #CCC;
		border-right:hidden;
		border-top:hidden;
	}
	.member_opt_pop_table_top2{
		height:500px !important; 
	  	width:200px !important;
		background-color:#FFF;
		text-align:center !important;
		vertical-align:middle;
	}
	.member_opt_pop_table_top2_1{
		height:168px !important; 
	  	width:198px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:hidden;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
	}
	.member_opt_pop_table_top2_2{
		height:166px !important; 
	  	width:198px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:hidden;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
	}
	.member_opt_pop_table_top2_3{
		height:246px !important; 
	  	width:198px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:hidden;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
	}
	/*
	.member_opt_pop_table_base{
		height:120px !important; 
	  	width:951px !important;
		background-color:#FFF;
		text-align:center !important;
		vertical-align:middle;
	}
	*/
	.member_opt_pop_table_base_1{
		height:40px !important; 
	  	width:751px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:hidden;
		border-top:1px solid #CCC;
	}
	.member_opt_pop_table_base_1td{
		height:40px !important; 
	  	width:250px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:hidden;
		border-left:1px solid #CCC;
		border-right:hidden;
		border-top:hidden;
	}
	.member_opt_pop_table_base_2{
		height:40px !important; 
	  	width:198px !important;
		text-align:center !important;
		vertical-align:middle;
		border-bottom:1px solid #CCC;
		border-left:1px solid #CCC;
		border-right:1px solid #CCC;
		border-top:1px solid #CCC;
	}
	
/*	
@media all and (orientation:landscape) { 	
}

 All Smartphones in portrait and landscape ----------- 
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {*/
/* YOUR STYLE GOES HERE 
}*/

/* All Smartphones in landscape ----------- 
@media only screen 
and (min-width : 321px) {*/
/* YOUR STYLE GOES HERE 
}*/

/* All Smartphones in portrait -----------
@media only screen 
and (max-width : 479px) { */
/* YOUR STYLE GOES HERE 
}*/

/***** ANDROID DEVICES *****/

/* Android 240 X 320 ----------- 
@media only screen
and (max-width: 241px){*/
/* YOUR STYLE GOES HERE 
}*/

/* Android(Samsung Galaxy) in portrait 380 X 685 ----------- 
@media only screen
and (min-width: 375px)
and (max-width: 385px){*/
/* YOUR STYLE GOES HERE 
}*/

/* Android(Samsung Galaxy) in Landscape 685 X  380 -----------
@media only screen
and (min-width: 680px)
and (max-width: 690px){ */
/* YOUR STYLE GOES HERE 
}*/

/* Kindle Portrait 600 X 1024 ----------- 
@media only screen
and (min-width: 595px)
and (max-width: 610px){*/
/* YOUR STYLE GOES HERE
} */

/* Kindle Landscape 1024 X 600 ----------- 
@media only screen
and (min-width: 1000px)
and (max-width: 1030px){*/
/* YOUR STYLE GOES HERE *
}/

/***** ALL GENERATION IPADS *****/

/* iPads in portrait and landscape----------- 
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {*/
/* YOUR STYLE GOES HERE   
}*/

/* iPad in landscape----------- 
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {*/
/* YOUR STYLE GOES HERE 
}*/

/* iPad in portrait----------- 
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait){*/
/* YOUR STYLE GOES HERE 
}*/



/***** Retina IPAD 3 & 4*****/

/* Retina iPad 3 & 4 in portrait and landscape----------- 
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)
and (-webkit-min-device-pixel-ratio: 2){*/
/* YOUR STYLE GOES HERE 
}*/

/* Retina iPad 3 & 4 in landscape----------- 

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 2){}*/
/* YOUR STYLE GOES HERE */


/* Retina iPad 3 & 4 in landscape----------- 

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait)
and (-webkit-min-device-pixel-ratio: 2){*/
/* YOUR STYLE GOES HERE 
}*/




/***** IPAD 1 & 2 (ALSO IPAD MINI)*****/

/* iPad 1 & 2 in portrait and landscape ----------- 
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (-webkit-min-device-pixel-ratio: 1){*/
/* YOUR STYLE GOES HERE 
}*/

/* iPad 1 & 2 in landscape ----------- 
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape)
and (-webkit-min-device-pixel-ratio: 1)  {*/
/* YOUR STYLE GOES HERE
} */

/* iPad 1 & 2 in portrait ----------- 
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) 
and (-webkit-min-device-pixel-ratio: 1){*/
/* YOUR STYLE GOES HERE 
}*/

/* Desktops and laptops ----------- 
@media only screen 
and (min-width : 1224px) {*/
/* YOUR STYLE GOES HERE 
}*/

/* Large screens ----------- 
@media only screen 
and (min-width : 1824px) {*/
/* YOUR STYLE GOES HERE 
}*/

/* Only iPhone 4 ----------- 
@media
only screen and (-webkit-min-device-pixel-ratio : 1.5),
only screen and (min-device-pixel-ratio : 1.5) {*/
/* YOUR STYLE GOES HERE 
}*/

/*   -------------------------------------------------- */
/*   -------------------------------------------------- */
/*   ------------    main_system_form  ---------------- */
/*   
/*
1) RGB(11,183,255);

2) RGB(232, 232, 232)

3) RGB(255, 68, 255)

4) RGB(255, 255, 255) 
/*
/*  
/*  
/*
/*  
/*
/*  PAGINATION_FORM_FRAME BY MODULE
/* 
/*      STANDARD_PAGINATION
/*
/*
/*		
/*      SOCIAL_POPULATE_WINDOW
/*      SOCIAL_LOGIN
/*
/*  
/*      ACTIVITY_FEED	
/*
/*  
/* 
/*
/*
/*		
/*  
/*
/*
/*  
/*  	
/*  FORM_CLASS_DECLARATION
/*  
/* 
/*
/*
/*		
/*  
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 ls_form_submit_button
CONTRUCTION
    BORDERS
        TABLE OUTSIDE   border: 1px solid rgba(209,209,209, 0.7);
        TD OUTSIDE      border: 1px solid rgba(204,204,204, 0.7);

        TABLE INSIDE    border: 1px solid rgba(204,204,204, 0.0);
        TD INSIDE       border: 1px solid rgba(204,204,204, 0.2);

        BUTTON          border: 1px solid rgba(204,204,204, 0.3);    
		
    BACKGROUND
        MAIN            background-color: rgba(255,255,255, 0.7);   
        PAGE            background-color: rgba(255,255,255, 0.2);
        FORM PAGE       background-color: rgba(239,239,239, 0.7);
        MENU            background-color: rgba(239,239,239, 0.7);  
        BUTTON          background-color: rgba(204,204,204, 0.7);   
		
    rgba(255,255,255, 0.7) = #FFF (white)
    rgba(0,0,0, 0.7) =  (black)
    rgba(239,239,239, 0.7) = #EFEFEF (grey)
    rgba(204,204,204, 0.7) = #CCC (dark border)
    rgba(0,255,0, 0.7)  =(green)
    rgba(255,255,0, 0.7) =(yellow)
    rgba(255,0,0, 0.7)  =(red)
    rgba(15,10,222,0.7)  =(blue)


    rgba(15, 5, 26, 0.7) =  (black scheme)
    rgba(10, 245, 92, 0.7)  =(green scheme)
    rgba(4, 144, 242, 0.7)  =(blue scheme)
    rgba(255, 28, 255, 0.7)  =(pink scheme)
    rgba(204, 204, 204, 0.7)  =(grey scheme)
    rgba(252, 71, 71, 0.7)  =(red scheme)
    rgba(255, 255, 128, 0.7) =(yellow scheme)
    204,204,204
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001
    MESSAGE INPUT HEADERS
                        background:rgba(239,239,239, 0.1);
                        border-spacing: 0px;
                        border:hidden;
                        font-size: 1.05em;
                        font-weight: 500;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
    
    LABELS: ls_form_label   reset_form_label    ls_form_full_field
                        
                        font-size: .90em;
                        font-weight: 600;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.2);
                        background:rgba(239,239,239, 0.7);


    BUTONS:
                        font-size: .90em;
                        font-weight: 600;
                        text-align:center;
                        text-indent:0px;
                        text-decoration:none;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.3);
                        background-color: rgba(204,204,204, 0.7);
                        -webkit-box-shadow: none !important;
                        -moz-box-shadow: none !important;
                        box-shadow: none !important;

    INPUT FIELDS        ls_form_full_field_error 
                        height:35px !important;
                        width: 98%  !important;
                        line-height:12px !important;
                        text-align:left;
                        vertical-align:middle;
                        font-size: .80em;
                        font-weight: 500;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
                        border-spacing: 0px;
                        border:hidden;
                        background-color:rgba(239,239,239, 0.7);

ls_form_submit_button   login_submit_default    ls_notification_submit_button   eventsystem_div_04   social_select_item_frame
                                                                                                    
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */

/* 010101010101010101010101010101010101010101010101010101010101010100101010110010110101010110101001 */
/*/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 *//*
/************************//*sprite_STANDARD

ROW 1 - ITEM 1 || -5px X -5px
ROW 1 - ITEM 2 || -5px X -145px
ROW 1 - ITEM 3 || -5px X -285px
ROW 1 - ITEM 4 || -5px X -425px
ROW 1 - ITEM 5 || -5px X -565px
ROW 1 - ITEM 6 || -5px X -705px


*/
/************************//*	BUTTON TEMPLATE	
.base_full_profile_table_empty, .event_item_container_panel_empty, .standard_private_action, .standard_public_action, .standard_swap_action, .standard_frame_start_option, .standard_frame_end_option, .File_PUpload_UI_class, .File_Upload_Label1_class,.File_Upload_Label2_class,.File_Upload_Label3_class,.File_Upload_Label4_class,.File_Upload_Label5_class, .File_Upload_Label6_class, .File_Upload_Label7_class,.File_Upload_Label8_class,.File_Upload_Label9_class,.File_Upload_Label10_class, .File_Upload_Label_class, .File_Upload_Label_class_profile, .Pin_Upload_Label_class{
  background-image: url('../images/buttons/sprite_STANDARD.png');
  background-repeat: no-repeat;
}
*/



.standardsystem_div_01{
    z-index:2004;
    height:500px; 
    width:951px;
	display: none;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(239,239,239, 0.8);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.standardsystem_div_02{
    height:500px;
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.standardsystem_div_03{
    height:500px;
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}
.standardsystem_div_04{
    height:500px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}
.standardsystem_div_05{
    height:500px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}
.standardsystem_div_06{
    height:500px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}

/************************//************************//************************/
/************************//*	MAP 	*/
.stadiamaps_text{
    font-size: 0.70em;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
}
.stadiamaps_attribution_SMALL1{
	z-index: 100;
   	height:30px !important;
	width:100px;
    line-height:30px  !important;
    position:absolute;
    margin-top:-35px;
	margin-left:5px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
    text-align:center;
}
.stadiamaps_attribution_SMALL2{
	z-index: 100;
   	height:30px !important;
	width:130px;
    line-height:30px  !important;
    position:absolute;
    margin-top:-35px;
	margin-left:107px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
    text-align:center;
}
.stadiamaps_attribution_LARGE1{
	z-index: 100;
   	height:30px !important;
	width:200px;
    line-height:30px  !important;
    position:absolute;
    margin-top:-35px;
	margin-left:239px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
    text-align:center;
}
/************************//************************//************************/
/************************//*	PAGINATION_FORM_FRAME BY MODULE	*/

/************************//*	STANDARD_PAGINATION	*/
.base_full_profile_table_empty {
    height:95% !important;
    width:99% !important;
    border-spacing: 0px;
    border: hidden;
    background: url(../images/buttons/butt_template.png);
    background-size: 90% 50%;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:center;
    vertical-align:middle;
} 
.event_item_container_panel_empty {
    height:95% !important;
    width:99% !important;
    position: absolute;
    border-spacing: 0px;
    border: hidden;
    background: url(../images/buttons/butt_template.png);
    background-size: 90% 50%;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:center;
    vertical-align:middle;
}




/************************************/
/************************//************************//************************/
	/************************//*	RESULTS_MANAGER	*/
	/********//*	RESULTS_MANAGER_MAIN	*/

/*


*/

.standard_frame_absolute {
	height:145px !important;
	width:231px;
	border-spacing: 0px;
	/*
	
	*/
	position: absolute;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.sp_frame_absolute {
	height:233px !important;
	width:231px;
	border-spacing: 0px;
	/*
	
	*/
	position: absolute;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/******** PRIVACY OPTION      *********/
.standard_private_action {
	z-index: 10;
	height:35px !important;
	width:35px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:5px;
	margin-left:5px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/account_summary_private.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_public_action {
	z-index: 10;
	height:35px !important;
	width:35px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:5px;
	margin-left:5px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/account_summary_public.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/******** SWAP OPTION      *********/
.standard_swap_action {
	z-index: 50;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/sys_stay_logged_default.png) rgba(147,255,0, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_manage_action {
	z-index: 50;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(4, 144, 242,0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_start_option {
	z-index: 10;
	height:25px !important;
	width:195px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:35px;
	margin-left:0px;
	
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.5);
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	
	vertical-align:middle;
	text-align:left;
	
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
	text-indent: 0px;
	
}
.standard_frame_end_option {
	z-index: 10;
	height:25px !important;
	width:195px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:60px;
	margin-left:0px;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.5);
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	
	vertical-align:middle;
	text-align:left;
	
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
	text-indent: 0px;
}
/******** APPROVE DENY OPTION *********/
.standard_frame_action_approve {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_deny {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:65px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(252, 71, 71, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.standard_frame_action_remove {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(252, 71, 71, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_block {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:65px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(252, 71, 71, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.standard_frame_action_cancel {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(252, 71, 71, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_follow {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(233,233,233, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_following {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_left_positive {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:65px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_left_negative {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:65px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(252, 71, 71, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_left_neutral {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:65px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(255, 255, 128, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_right_positive {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_right_negative {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(252, 71, 71, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_right_neutral {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:105px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(255, 255, 128, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_right_sp_positive {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:195px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_right_sp_neutral {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:195px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(255, 255, 128, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.standard_frame_action_right_sp_action {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:145px;
	margin-left:150px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(2010, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************//************************//************************/
/************************//*	FORM_CLASS_DECLARATION	*/
.Listview_options_category_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
    display:hidden;
}

.Page_Load_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
    display:hidden;
}
.Profile_member_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Profile_view_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}	
.Profile_contribution_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}	
.Profile_recommendation_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}	
.Profile_info_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}	
.Profile_control_Activity_UI_class{
    height:48 !important;
    border:0; 
    padding:0;
    margin:0;
    vertical-align:top !important;
}	
.Profile_filter_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Profile_return_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
    display:inline-block;
    width:100px
}
.Listview_options_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Listview_media_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Listview_items_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Profile_username_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Profile_following_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Profile_privacy_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Profile_report_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Profile_city_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Media_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Cohost_Search_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Location_Search_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}

.Notification_Activity_UI_class{
	border:0; 
	padding:0;
	margin:0;
}
.Pop_search_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Interaction_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Standard_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Return_create_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Listviewform_new_date_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Confirm_Submit_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Confirm_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}

.Description_MGMT_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Shares_MGMT_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Followers_MGMT_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Event_Media_Streaming_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}	
.Compatibility_MGMT_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}	
.Invites_MGMT_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Score_MGMT_Activity_UI_class{
    /*
    
    */
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Action_Activity_UI_class{
    /*
    
    */
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Social_media_Activity_UI_class{
    /*
    
    */
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Attendees_MGMT_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Event_create_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Options_create_Activity_UI_class{
    height: ;
    width: ;

    border:0; 
    padding:0;
    margin:0;
}
.Dates_MGMT_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Type_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Occurrence_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Environment_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Theme_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Guideline_profile_selection_Activity_UI_class{
    height:0px;
    width:0px;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Guideline_contribution_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Guideline_sharing_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Guideline_opt1_selection_Activity_UI_class{
    height: ;
    width: ;
    border:0; 
    padding:0;
    margin:0
}
.Guideline_opt2_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Guideline_opt3_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Location_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection_Activity_UI_control{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection1_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection2_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection3_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection4_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection5_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection6_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection7_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection8_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection9_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_selection10_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Event_options_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Event_items_settings_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Metrics_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Dates_selection_Activity_UI_class{
    height: ;
    width: ;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0
}
.Image_display_Activity_UI_class{
    height:100%;
    width:100%;
    border:0; 
    padding:0;
    margin:0
}
.Image_display_slice_Activity_UI_class{
    height:100%;
    width:100%;
    border:0; 
    padding:0;
    margin:0
}
.Image_display_showroom_Activity_UI_class{
    height:100%;
    width:100%;
    border:0; 
    padding:0;
    margin:0
}
.Term_selection_Activity_UI_class{
    height: 0px;
    width: 0px;
    display:inline-block;
    border:0; 
    padding:0;
    margin:0;
}
.Language_selection_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Profile_search_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Settings_Menu_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Settings_General_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Settings_Profile_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Security_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Notifications_UI_class{
    border:0; 
    padding:0;
    margin:0;
}	
.Settings_Activity_UI_class{
    height:47px !important;
    border:0; 
    padding:0;
    margin:0;
}
.Profile_blocked_menu_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Profile_blocked_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.File_UploadReset_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.File_Upload1_UI_class, .File_Upload2_UI_class, .File_Upload3_UI_class, .File_Upload4_UI_class, .File_Upload5_UI_class, .File_Upload6_UI_class, .File_Upload7_UI_class, .File_Upload8_UI_class, .File_Upload9_UI_class, .File_Upload10_UI_class, .File_Upload11_UI_class, .File_Upload12_UI_class{
    border:0; 
    padding:0;
    margin:0;
}

.File_PUpload_UI_class1,.File_PUpload_UI_class2,.File_PUpload_UI_class3,.File_PUpload_UI_class4,.File_PUpload_UI_class5,.File_PUpload_UI_class6,.File_PUpload_UI_class7,.File_PUpload_UI_class8,.File_PUpload_UI_class9,.File_PUpload_UI_class10{
    height:100px !important;
    width:100px !important; 
    border:0; 
    padding:0;
    margin:0;
}
.File_PUpload_UI_class_main{
    height:94px !important;
    width:98px !important;
    padding-top:4px;
    padding-left:3px;
    padding-right:0;
    padding-bottom:0;
    border:0;
}
.File_PUpload_UI_class{
    height:98px !important;
    width:98px !important; 
    background: url(../images/buttons/plus_button.png); /* add_button  file_upload1*/
    background-size: 96px 96px;
    background-repeat: no-repeat;
    border: 1px solid rgba(204,204,204, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    padding:0;
    margin:0;
}
.file_upload_hidden{
    display:none;
    visibility:hidden;
    border:0; 
    padding:0;
    margin:0;
}
.file_upload{
    display:none;
    visibility:hidden;
    border:0; 
    padding:0;
    margin:0;
}
.File_Upload_Label1_class,.File_Upload_Label2_class,.File_Upload_Label3_class,.File_Upload_Label4_class,.File_Upload_Label5_class, .File_Upload_Label6_class, .File_Upload_Label7_class,.File_Upload_Label8_class,.File_Upload_Label9_class,.File_Upload_Label10_class{
    height:98px !important;
    width:98px !important;
    line-height:100px !important;
    display: inline-block;
    font-size:20px;
    font-weight:700;
    text-align:center  !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    text-decoration:none;
    background-color: rgba(204,204,204, 0.7);
    background: url(../images/buttons/plus_button.png); 
    background-size: 96px 96px;
    background-repeat: no-repeat;
    border: 1px solid rgba(204,204,204, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    padding:0;
    margin:0;
}
.File_Upload_Label_class_main{
    height:94px !important;
    width:93px !important;
    position:relative;
    margin-top: 1px;
    margin-left: 3px;
    border:hidden;
    display:block;
}
.File_Upload_Label_class{
    height:93px !important;
    width:93px !important;
    line-height:93px !important;
    display: inline-block;
    margin-top: 0px;
    margin-left: 0px;
    position: absolute;
    font-size:20px;
    font-weight:700;
    text-align:center  !important;
    vertical-align:text-top !important;
    border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    text-decoration:none;
    background: url(../images/buttons/plus_button.png) rgba(239,239,239, 0.7); 
    background-size: 93px 93px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    padding:0;
    margin:0;
}
.File_Upload_Label_class_profile{
    height:107px !important;
    width:107px !important;
    line-height:109px !important;
    display: inline-block;
    margin-top: 0px;
    margin-left: 0px;
    left: -1px;
    position: absolute;
    font-size:20px;
    font-weight:700;
    text-align:center  !important;
    vertical-align:text-top !important;
    border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    text-decoration:none;
    background: url(../images/buttons/plus_button.png) rgba(239,239,239, 0.7); 
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.Window_Imagecontrol_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Marketplace_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Marketplace_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Marketplace_Upload_UI_class{
    height:58px !important;
    border:0; 
    padding:0;
    margin:0;		
}
.Pin_Upload_UI_class{
    height:58px !important;
    width:58px !important; 
    border:0; 
    padding:0;
    margin:0;
}
.Pin_Upload_Label_class{
    height:58px !important;
    width:64px !important;
    line-height:58px !important;
    display: inline-block;
    text-align:center  !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    text-decoration:none;

    background: url(../images/buttons/plus_button.png); 
    background-size: 56px 56px;
    background-position:center;
    background-repeat: no-repeat;
    border-top:hidden;
    border-bottom:hidden;
    border-left:1px solid #EFEFEF;
    border-right:hidden;		
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    padding:0;
    margin:0;
}
.Marketplace_Editor_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Pagination_UI_class_main{
    border:0; 
    padding:0;
    margin:0;
}
.Pagination_Shift_UI_class_main{
    width: 480px !important;
    border:0; 
    padding:0;
    margin:0;
}
.Dashboard_Active_UI_class{
    border:0; 
    padding:0;
    margin:0;
    background-color: transparent;
}
.Photosystem_Active_UI_class{
    border:0; 
    padding:0;
    margin:0;
    background-color: transparent;
}
.Photobucket_Active_UI_class{
    border:0; 
    padding:0;
    margin:0;
    background-color: transparent;
}
.Photosystem_Active_UI_class{
    border:0; 
    padding:0;
    margin:0;
    background-color: transparent;
}
.HeaderMenu_UI_class{
    border:0; 
    padding:0;
    margin:0;
    background-color: transparent;
}
.LoginRequest_UI_class{
    border:0; 
    padding:0;
    margin:0;
    background-color: transparent;
}
.Menu_Menu_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Registration_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Reset_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Reset_submit_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Account_Type_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Social_Register_Type_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Search_Index_Register_Type_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Summary_Menu_UI_class{

    border:0; 
    padding:0;
    margin:0;
}
.Modify_Menu_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Errors_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Footer_Language_UI_class{
    height:30px !important;
    border:0; 
    padding:0;
    margin:0;
}
.Footer_Menu_UI_class{
    height:30px !important;
    border:0; 
    padding:0;
    margin:0;
}
.Select_Profile_Activity_UI_class{
	height:0px;
	width:0px ;
}
.Identity_Activity_UI_class{
	height:;
	width: ;
}
.Logo1_Activity_UI_class{
	height:;
	width: ;
}
.Logo2_Activity_UI_class{
	height:;
	width: ;
}
.Recommendation_selection_Activity_UI_class{
	height:;
	width: ;
}

.Follow_selection_Activity_UI_class{
	height:;
	width: ;
}
.Recommend_selection_Activity_UI_class{
	height: ;
	width: ;
}
.Block_selection_Activity_UI_class{
	height: ;
	width: ;
}
.Media_Listview_selection_Activity_UI_class{
    height:449px;
    width: ;
    display:inline-block !important;
    text-align: left;
    vertical-align: top;
    border:0; 
    padding:0;
    margin:0;
}
.Exit_Activity_UI_class{
    height: ;
    width: ;
    border:0; 
    padding:0;
    margin:0;
}
.Listview_selection_Activity_UI_class{
    height: ;
    width: ;
    border:0; 
    padding:0;
    margin:0;
}
.Media_Control_selection_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Status_Control_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Description_MGMT_selection_Activity_UI_class{
	height:0px;
    width:0px;
    border:0; 
    padding:0;
    margin:0;
}
.Title_MGMT_selection_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Select_Item_View_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Map_Activity_UI_class{
	border:0; 
	padding:0;
	margin:0;
}
.Filter_Activity_UI_class{
	border:0; 
	padding:0;
	margin:0;
}
.Map_marker_active{
	z-index: 1234782904789;
	border: thick #1B30E5;
}
.Results_Activity_UI_class{
	border:0; 
	padding:0;
	margin:0;
}
.Sbar_Activity_UI_class{
	border:0; 
	padding:0;
	margin:0;
}
.Swipe_View_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Entity_upload_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Business_upload_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Date_Item_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Media_View_Activity_UI_class{
	border:0; 
	padding:0;
	margin:0;
}
.Return_create_Activity_UI_class{
	height: 0px;
	width: 0px;
	border:0; 
	padding:0;
	margin:0;
}
.Select_Activity_UI_class{
	height: 0px;
	width: 0px;
	border:0; 
	padding:0;
	margin:0;
}
.Swipe_menu_pagination_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Media_pagination_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Media_menu_pagination_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Checkin_Activity_UI_class{
	border:0; 
	padding:0;
	margin:0;
}
.Register_Activity_UI_class{
	border:0; 
	padding:0;
	margin:0;
}
.Switch_1_Activity_UI_class .Switch_2_Activity_UI_class .Switch_3_Activity_UI_class .Switch_4_Activity_UI_class .Switch_5_Activity_UI_class .Switch_6_Activity_UI_class .Switch_7_Activity_UI_class .Switch_8_Activity_UI_class .Switch_9_Activity_UI_class .Switch_10_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.Event_Manager_Activity_UI_class{
	border:0; 
	padding:0;
	margin:0;
}
.Admin_Manager_Activity_UI_class{
	border:0; 
	padding:0;
	margin:0;
}
.Scroll_Activity_UI_class{
	border:0; 
	padding:0;
	margin:0;
}
.Report_Concern_Activity_UI_class{
    border:0; 
    padding:0;
    margin:0;
}
.EventForm01_MGMT_Activity_UI_class{
	height:0px;
	width:0px ;
}
/*********************************************/
/*********************************************/



/*********************************************/
/*********************************************/



/*********************************************/
/*********************************************/



/*********************************************/
/*********************************************/
/*
ls_form_full_table
ls_form_full_instruction
ls_form_full_field
ls_form_full_table
*/
.ls_form_label {
    height:25px !important;
    width:144px !important;
    line-height:12px !important;
    font-size: .90em;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255,255,255, 0.7);
}
.event_input_table_full{
    height:47px !important;
    width:666px !important;
    border-right: hidden;
    border-left: hidden;
    border-top: 1px solid #ccc;
    border-spacing: 0px;
    background-color:rgba(255,255,255, 0.7);
    font-size:16px;
    font-weight:700;
    text-align:left;
    line-height:25px !important;
    text-decoration:none;
    margin:0;
    padding:0;
}

.ls_input_table_full{
    height:47px !important;
    width:714px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.7);
}
.ls_input_table_survey_full{
    height:51px !important;
    width:718px !important;
    border-right: hidden;
    border-left: hidden;
    border-top: 1px solid #ccc;
    border-spacing: 0px;
    font-size:16px;
    font-weight:700;
    text-align:left;
    line-height:25px !important;
    text-decoration:none;
    margin:0;
    padding:0;
}
.ls_input_table_survey_full_first{
    height:54px !important;
    width:718px !important;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-spacing: 0px;
    font-size:16px;
    font-weight:700;
    text-align:left;
    line-height:25px !important;
    text-decoration:none;
    margin:0;
    padding:0;
}
.ls_input_table_survey_full_last{
    height:54px !important;
    width:718px !important;
    border-right: hidden;
    border-left: hidden;
    border-top: 1px solid #ccc;
    border-bottom: hidden;
    border-spacing: 0px;
    font-size:16px;
    font-weight:700;
    text-align:left;
    line-height:25px !important;
    text-decoration:none;
    margin:0;
    padding:0;
}
/*
ls_form_full_table
*/
.ls_social_form_label {
    height:25px !important;
    width:60px !important;
    line-height:12px !important;
    font-size: .90em;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255,255,255, 0.7);
}
.ls_social_form_full_table {
    height:47px !important;
    width: 370px !important;
    line-height:12px;
    vertical-align: middle;
    text-align: center;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255,255,255, 0.5);
}
.ls_social_input_table_full{
    height:47px !important;
    width:580px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background:rgba(255,255,255, 0.7);
}
.ls_social_message_table_full{
    height:55px !important;
    width:580px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background:rgba(255,255,255, 0.7);
}

.ls_form_space_label {
    height:25px !important;
    width:177px !important;
    line-height:25px !important;

    background:rgba(255,255,255, 0.5);
    border-spacing: 0px;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
    border-top:1px solid rgba(239,239,239, 0.9);
    border-left:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
}
.bs_form_location_td {
    height:43px !important;
    width:209px !important;
    line-height:47px !important;
    
    background:rgba(255,255,255, 0.5);
    border-spacing: 0px;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
    border-top:1px solid rgba(239,239,239, 0.9);
    border-left:hidden;
    border-right:hidden;
}
.ls_form_location_td {
    height:43px !important;
    width:298px !important;
    line-height:47px !important;

    background:rgba(255,255,255, 0.5);
    border-spacing: 0px;
    border-right:hidden;
    text-align:center;
    text-decoration:none;
	margin:0;
    padding:0;
    /*
    display:inline-block;
    font-size: .90em;

    */
}
.ls_date_field{
   height:30px !important;
    width: 250px;

    line-height:30px !important;
    background:rgba(238,238,238, 0.5);

    border-spacing: 0px;
    border:hidden;
	margin-left: 50px;
    /*
    font-size: .85em;
    font-weight: 500;
	text-indent:5px;
    */
    text-align:left;
    text-decoration:none;
  }
.ls_time_div{
	z-index: 1000;
    height:30px !important;
    width: 200px !important;
    line-height:30px !important;
	margin-left: 400px !important;
	display: inline-block;
	position: absolute;
    background:rgba(238,238,238, 0.5);
    border-spacing: 0px;
	border-top:1px solid rgba(239,239,239, 0.9);
	border-bottom:1px solid rgba(239,239,239, 0.9);
	border-right:1px solid rgba(239,239,239, 0.9);
	border-left:1px solid rgba(239,239,239, 0.9);
  }
.ls_time_field{
    height:30px !important;
    width: 250px;

    line-height:20px !important;
    background:rgba(238,238,47, 0.5);

    border-spacing: 0px;
    border:hidden;
    /**/
    font-size: .85em;
    font-weight: 500;
    
    text-align:left;
    text-decoration:none;
    text-indent:5px;
	
  }
.ls_form_long_table {
    height:47px !important;
    width: 390px !important;
    line-height:25px;

    vertical-align: middle;
    text-align: center;

    background:rgba(255,255,255, 0.5);
    border-spacing: 0px;
    border-right:1px solid #ccc;
    margin:0;
    padding:0;
}
.ls_form_full_field{
    height:35px !important;
    width: 98%;
    line-height:12px !important;
    text-align:left;
    vertical-align:middle;
    font-size: .80em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
    border-spacing: 0px;
    border:hidden;
    background-color:rgba(239,239,239, 0.7);
}

.ls_form_full_field_active{
  height:34px !important;
  width: 98%;
  text-align:left;
  vertical-align:middle;
  font-size: .80em;
  font-weight: 500;
  text-align:left;
  text-decoration:none;
  text-indent:5px;
  border-spacing: 0px;
  border:1px solid rgba(10, 245, 92, 0.7);
  background-color:rgba(239,239,239, 0.7);

}
.ls_form_full_field_error{
  height:34px !important;
  width: 98%;
  line-height:12x !important;
  text-align:left;
  vertical-align:middle;
  font-size: .80em;
  font-weight: 500;
  text-align:left;
  text-decoration:none;
  text-indent:5px;
  border-spacing: 0px;
  border:1px solid rgba(252, 71, 71, 0.7);
  background-color:rgba(239,239,239, 0.7);
}
/*
ls_form_half_field
*/
.ls_form_full_field_check_positive{
    height:25px !important;
    width: 30px;
    line-height:12px !important;
    position: absolute;
    margin-top: 5px;
    margin-left: 275px;
    background-color:rgba(10, 245, 92, 0.7);
    border-spacing: 0px;
    border:hidden;
}
.ls_form_full_field_check_negative{
    height:25px !important;
    width: 30px;
    line-height:12px !important;
    position: absolute;
    margin-top: 5px;
    margin-left: 275px;
    background-color:rgba(252, 71, 71, 0.7);
    border-spacing: 0px;
    border:hidden;
}
.date_form_full_field{
  height:35px !important;
  /*width: 345px;*/
  width: 100%;
  line-height:30px !important;
  border-spacing: 0px;
  border-style:solid;
  border-width:thin;
  border:hidden;
  font-size: .85em;
  font-weight: 500;
  color:darkgrey;
  text-align:left;
  text-decoration:none;
  text-indent:35px;
  background: url(../images/buttons/butt_template.png) #EEEEEE;
  background-position:left;
  background-size: 30px 30px;
  background-repeat: no-repeat;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;

}
.es_form_full_table {
	height:47px !important;
	width: 378px !important;
	line-height:35px;

	vertical-align:middle;
	text-align:left;

	background:rgba(255,255,255, 0.5);
	border-spacing: 0px;
	border-right:1px solid rgba(204,204,204, 0.5);
	border-bottom:1px solid rgba(204,204,204, 0.5);
	margin:0;
	padding:0;
	/*
		
	item_title_edit
	switch_form_half_table2
	ls_form_half_table2
	
	*/
}
.es_form_full_instruction {
	height:47px !important;
	width: 110px !important;
	line-height:25px !important;

	border-spacing: 0px;
	border-right:1px solid #ccc;

	background:rgba(255,255,255, 0.5);

	font-size: .85em;
	font-weight: 500;
	text-align:left;
	text-decoration:none;
	text-indent:5px;
}
.es_switch_form_label_event {
	height:25px !important;
	width:165px;
	line-height:25px !important;
/**/
	font-size: .90em;
	font-weight: 600;
	

	text-align:left;
	text-decoration:none;
	text-indent:45px;

	vertical-align:middle !important;
	border-spacing: 0px;
	border-bottom:1px solid rgba(239,239,239, 0.9);
	border-left:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position: 1% 50%; 
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.es_form_command_bar{
    height:47px !important;
    width:664px !important;
    line-height:50px !important;
    border-spacing: 0px;
    border-top:1px solid rgba(239,239,239, 0.9);
    border-bottom:1px solid rgba(239,239,239, 0.9);
    border-left:hidden;
    border-right:hidden;
    background-color:rgba(255,255,255, 0.9);
    font-size:16px;
    font-weight:700;
    text-decoration:none;
    margin:0;
    padding:0;
    /*
    ls_form_space_label
	es_switch_form_label_event
    */
}  
.es_form_command_bar_td1{
    height:47px;
    width:125px;
	line-height:25px !important;
/*
	item_action_button
*/
	font-size: .90em;
	font-weight: 600;
	

	text-align:left;
	text-decoration:none;
	text-indent:45px;

	vertical-align:middle !important;
	border-spacing: 0px;
	border-bottom:1px solid rgba(239,239,239, 0.9);
	border-left:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position: 1% 50%; 
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.es_form_command_bar_td2{
    height:47px;
    width:113px;
    line-height:45px;
    border-spacing: 0px;
    text-align:center !important;
    vertical-align:middle !important;
    border-bottom:1px solid rgba(239,239,239, 0.9);
	border-left:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.9);
    margin:0;
    padding:0;
}
.es_form_command_bar_td3{
    height:47px;
    width:53px;
    line-height:45px;
    border-bottom:1px solid rgba(239,239,239, 0.9);
	border-left:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
    text-align:center;
    vertical-align:middle !important;
    background-color:rgba(255,255,255, 0.9);
    margin:0;
    padding:0;
}
.es_form_command_bar_td4{
    height:47px;
    width:166px;
    line-height:45px;
    border-bottom:1px solid rgba(239,239,239, 0.9);
	border-left:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
    text-align:center;
    vertical-align:middle !important;
    background-color:rgba(255,255,255, 0.9);
    margin:0;
    padding:0;
}
.es_form_command_bar_space{
    height:47px;
    width:90px;
    line-height:45px;
    border-bottom:1px solid rgba(239,239,239, 0.9);
	border-left:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
    text-align:center;
    vertical-align:middle !important;
    background-color:rgba(255,255,255, 0.9);
    margin:0;
    padding:0;
}
.es_form_command_activity_default{
    height:45px;
    width:51px;
    line-height:45px;
	border-top: 1px solid rgba(239,239,239, 0.9);
    border-bottom:1px solid rgba(239,239,239, 0.9);
	border-left:1px solid rgba(239,239,239, 0.9);
    border-right:1px solid rgba(239,239,239, 0.9);
    text-align:center;
    vertical-align:middle !important;
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
    background-position:left;
    background-size: 46px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.es_form_command_activity_active{
    height:45px;
    width:51px;
    line-height:45px;
    border-bottom:1px solid rgba(239,239,239, 0.9);
	border-left:hidden;
    border-right:1px solid rgba(239,239,239, 0.9);
    text-align:center;
    vertical-align:middle !important;
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.9);
    background-position:left;
    background-size: 46px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_survey_label {
    width:300px;
    display:inline-block;
    border-right:1px solid #ccc;
    line-height:25px !important;
    font-size:12px;
    font-size: .83em;
    font-weight: 400;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
.ls_form_total_table {
    height:47px !important;
    width: 415px !important;
    line-height:47px;
    background:#FFF;
    border-spacing: 0px;
    border-right:hidden;
    text-align:center;
    vertical-align:middle;
    margin:0;
    padding:0;
}
.ls_form_total_table_td {
    height:43px !important;
    width: 80px !important;
    line-height:43px;
    background:#FFF;
    text-align:center;
    vertical-align:middle;
    margin:0;
    padding:0;
}
.ls_form_full_table {
    height:47px !important;
    width: 290px !important;
    line-height:12px;
    vertical-align: middle;
    text-align: center;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255,255,255, 0.5);
}
/*
ls_input_table_full

*/
.ls_form_messagedisplayinput_field_small{
  height:24px !important;
  /*width: 345px;*/
  width: 98%;
  line-height:12px !important;
  background:rgba(239,239,239, 0.1);
  border-spacing: 0px;
  border:hidden;
  font-size: 1.05em;
  font-weight: 500;
  text-align:left;
  text-decoration:none;
  text-indent:5px;
}
.ls_form_messageboxinput_field{
    height:175px !important;
    /*
    
	feedback_cancel_button
	feedback_space_td
	vertical-align: top !important;
	display: inline-block;
    */
    width: 712px !important;
	line-height: 20px !important;
 
    background:rgba(239,239,239, 0.5);

    border-spacing: 0px;
    border-top:1px solid rgba(239,239,239, 0.9);
	border-bottom:1px solid rgba(239,239,239, 0.9);
	border-left:1px solid rgba(239,239,239, 0.9);
	border-right:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
    resize: none;
    
 }
/*

*/
.ls_form_messageboxinput_field_share{
    height:140px !important;
    width: 350px !important;
	line-height: 20px !important;
    margin-top:20px;
    margin-left:10px;
    background:rgba(239,239,239, 0.1);
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
    resize: none;
 }
.ls_form_messageboxinput_tags_share{
    height:60px !important;
    width: 350px !important;
	line-height: 20px !important;
    margin-top:5px;
    margin-left:10px;
    background:rgba(239,239,239, 0.1);
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
    resize: none;
 }

.ls_form_messagedisplayinput_field{
    height:35px !important;
    width: 98%;
    line-height:12px !important;
    background:rgba(239,239,239, 0.1);
    border-spacing: 0px;
    border:hidden;
    font-size: 1.05em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
    resize: none;
  }
.ls_form_full_instruction {
    height:47px !important;
    width: 160px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
    background:rgba(255,255,255, 0.5);
}
.ls_form_long_instruction {
    height:47px !important;
    width: 110px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255,255,255, 0.5);
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
.ls_form_half_table1 {
    height:47px !important;
    width: 154px;
    line-height:12px !important;
    background:rgba(255,255,255, 0.5);
    vertical-align: middle;
    text-align: center;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
}
.ls_form_half_table2 {
    height:47px !important;
    width: 152px;
    line-height:12px !important;
    background:rgba(255,255,255, 0.5);
    vertical-align: middle;
    text-align: center;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
}
.ls_form_half_table3 {
    height:47px !important;
    width: 232px;
    line-height:12px !important;
    background:rgba(255,255,255, 0.5);
    vertical-align: middle;
    text-align: center;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
}
.ls_form_half_field{
    height:35px !important;
    /*width: 195px;*/
    width: 97%;
    line-height:12px !important;
    background:rgba(238,238,238, 0.5);
    border-spacing: 0px;
    border:hidden;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
.ls_form_acccount_cost_select_field{
    height:25px !important;
    /*width: 195px;*/
    width: 97%;
    line-height:20px !important;
    background:rgba(238,238,238, 0.5);
    border-spacing: 0px;
    border:hidden;
    text-align:right;
    text-decoration:none;
    text-indent:5px;
}
.ls_form_acccount_cost_total_select_field{
    height:35px !important;
    /*width: 195px;*/
    width: 97%;
    line-height:20px !important;
    background:rgba(238,238,238, 0.5);
    border-spacing: 1px;
    border:1px solid rgba(204,204,204, 0.8) !important;
    border:hidden;
    text-align:right;
    text-decoration:none;
    text-indent:5px;
    font-size: 1.10em;
    font-weight: 600;
    text-decoration:none;
}
.ls_form_acccount_cost_select_label{
    height:25px !important;
    width: 100px;
    position:absolute;
    margin-top:0px;
    margin-left:2px;
    line-height:20px !important;
    background:rgba(238,238,238, 0.0);
    border-spacing: 0px;
    border:hidden;
    vertical-align: center  !important;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.ls_form_acccount_cost_total_select_label{
    height:35px !important;
    width: 100px;
    position:absolute;
    margin-top:0px;
    margin-left:2px;
    line-height:20px !important;
    background:rgba(238,238,238, 0.0);
    border-spacing: 0px;
    border:hidden;
    vertical-align: center  !important;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
}

.ls_form_half_field_check_positive{
    height:25px !important;
    width: 30px;
    line-height:12px !important;
    position: absolute;
    margin-top: 5px;
    margin-left: 130px;
    background-color:rgba(10, 245, 92, 0.7);
    border-spacing: 0px;
    border:hidden;
}
.ls_form_half_field_check_negative{
    height:25px !important;
    width: 30px;
    line-height:12px !important;
    position: absolute;
    margin-top: 5px;
    margin-left: 130px;
    background-color:rgba(252, 71, 71, 0.7);
    border-spacing: 0px;
    border:hidden;
}
.ls_form_seat_field{
    height:35px !important;
    width: 97%;
    line-height:12px !important;
    background:rgba(238,238,238, 0.5);
    border-spacing: 0px;
    border:hidden;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
.ls_form_catalog_field{
    height:56px !important;
    width: 119.3px;
    line-height:12px !important;
	background:rgba(238,238,238, 0.5);
    border-spacing: 0px;
    border: thin solid rgba(239,239,239, 0.7);
    font-size: 0.95em;
	font-weight: 700;
    /*
    event_container_data8_1_1
    */
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
  .ls_form_half_field_error{
    height:35px !important;
    /*width: 345px;*/
    width: 97%;
    line-height:20px !important;

    background:rgba(238,238,238, 0.5);

    border-spacing: 0px;
    border-style:solid;
    border-width:thin;
    border-color:#F00;
    /*
    font-size: .85em;
    font-weight: 500;
     */
    text-align:left;
    text-decoration:none;
    text-indent:5px;

  }
.ls_form_hc_field{
    height:35px !important;
    /*width: 280px;*/
    width: 100%;
    line-height:20px !important;
    background-color:#EEEEEE;
    border-spacing: 0px;
    border:hidden;
    /*
    font-size: .85em;
    font-weight: 500;
    */
    text-align:left;
    text-decoration:none;
    text-indent:5px;
  }
.ls_form_command_bar{
    height:80px !important;
    width:718px;
    line-height:80px !important;
    border-spacing: 0px;
    border-top:1px solid #ccc;
    border-bottom:1px solid #ccc;
    background:#FEF;
    margin:0;
    padding:0;
}  
.ls_form_command_bar_td1{
    height:80px;
    width:408px;
    line-height:80px;
    border-spacing: 0px;
    text-align:right;
    vertical-align:middle !important;
    border-right:1px solid #CCC;
    border-left:hidden;

    background:#FFF;
    margin:0;
    padding:0;
}
.ls_form_command_bar_td3{
    height:80px;
    width:100px;
    line-height:80px;
    border-spacing: 0px;
    text-align:center !important;
    vertical-align:middle !important;
    border-right:1px solid #CCC;

    background:#FFF;
    margin:0;
    padding:0;
}
.ls_form_command_bar_td4{
    height:80px;
    width:144px;
    line-height:80px;
    border-spacing: 0px;
    text-align:center;
    vertical-align:middle !important;
    background:#FFF;
    margin:0;
    padding:0;
}
.ls_form_command_bar_td5{
    height:80px;
    width:144px;
    line-height:80px;
    border-spacing: 0px;
    /* border-right:1px solid #CCC; */
    text-align:center;
    vertical-align:middle !important;
    background:#FFF;
    margin:0;
    padding:0;
} 
.ls_form_reset_button {
    height: 40px;
    width: 125px;
    line-height:30px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color: rgba(204,204,204, 0.7); 
    border: 1px solid rgba(204,204,204, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.ls_set-opt_command_bar{
    height:38px !important;
    width:716px !important;
    line-height:38px !important;
    border-spacing: 0px;
    border:hidden;
    text-align:center;
    text-decoration:none;
    background-color:rgba(255,255,255, 0.5); 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    margin:0;
    padding:0;
}  
.ls_set-opt_command_bar_title{
    height:60px !important;
    width:718px !important;
    line-height:60px !important;
    border-spacing: 0px;
    border:hidden;
    font-size:20px;
    font-weight:700;
    text-align:center;
    text-decoration:none;
    margin:0;
    padding:0;
}  
.ls_set-opt_command_bar_td{
    height:38px !important;
    width:115px !important;
    line-height:38px !important;
    border-spacing: 0px;
    border:hidden;
} 
.ls_form_submit_min_button {
    height: 40px;
    width: 75px;
    line-height:33px;
    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    background-color: rgba(204,204,204, 0.7); 
    border: 1px solid rgba(204,204,204, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ls_error_submit_button {
    height: 605px;
    width: 952px;
    line-height:33px;
    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/sys_option_error_active.png) rgba(255,255,255, 0.7) center;
    background-size: 400px 400px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ls_form_login_full_field{
    height:35px !important;
    width: 98%  !important;
    line-height:12px !important;
    text-align:left;
    vertical-align:middle;
    font-size: .80em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
    border-spacing: 0px;
    border:hidden;
    background-color:rgba(239,239,239, 0.7);
  }

.ls_notification_submit_button {
    height: 148px;
    width: 638px;
    line-height:12px;
    margin-top:0px;
    margin-left:0px;
    font-size: 1em;
    font-weight: 700;
    text-align:left;
    text-decoration:none;
    border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/sys_option_correct_active.png) rgba(239,239,239, 0.7) center;
    background-size: 300px 150px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    ls_form_half_table1
    */
}

.ls_form_submit_button {
    height: 40px;
    width: 125px;
    line-height:12px;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(204,204,204, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_submit_button_error {
    height: 40px;
    width: 125px;
    line-height:12px;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(252, 71, 71, 0.7);
    background-color:rgba(11,183,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_submit_button_active {
    height: 40px;
    width: 125px;
    line-height:12px;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color:rgba(11,183,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_submit_button_submit {
    height: 40px;
    width: 125px;
    line-height:12px;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(10, 245, 92, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_submit_button_cancel {
    height: 40px;
    width: 125px;
    line-height:12px;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(252, 71, 71, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_submit_long_button {
    height: 40px;
    width: 180px;
    line-height:12px;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(204,204,204, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_submit_long_button_error {
    height: 40px;
    width: 180px;
    line-height:12px;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(252, 71, 71, 0.7);
    background-color: rgba(204,204,204, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_submit_long_button_active {
    height: 40px;
    width: 180px;
    line-height:33px;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(04,204,204, 0.3);
    background-color:rgba(11,183,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_set-opt_submit_button {
    height: 30px;
    width: 115px;
    line-height:30px;
    font-size:14px;
    font-weight:600;
    text-align:center;
    text-decoration:none;
    background-color: rgba(204,204,204, 0.7);
    border: 1px solid rgba(204,204,204, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_comp_verify_button {
    height: 40px;
    width: 100px;
    line-height:12px;
    font-size: .85em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color: rgba(204,204,204, 0.7); 
    border: 1px solid rgba(204,204,204, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_sys_button {
    height: 40px;
    width: 50px;
    line-height:12px;
	
    border: 1px solid rgba(204,204,204, 0.3);
	background: url(../images/buttons/sys_settings_option.png) #EFEFEF;
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_picture_button {
    height:95px !important;
    width:95px !important;
    line-height:95px !important;
    display: inline-block;
    margin-top: 0px;
    margin-left: 0px;
    position: absolute;
    border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    background-color: rgba(239,239,239, 0.7);
    background-size: 95px 95px;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_pin_button {
    height: 96px;
    width: 96px;
    line-height:96px;
    text-align:center;
    text-decoration:none;
    background-color: rgba(204,204,204, 0.7);
    background-size: 96px 96px;
    background-repeat: no-repeat;
    background-position: center;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_survey_button {
    height: 40px !important;
    width: 75px !important;
    line-height:40px;
    font-size: .75em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color: rgba(204,204,204, 0.7);
    border: 1px solid rgba(204,204,204, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_survey_button_active {
    height: 40px !important;
    width: 75px !important;
    line-height:40px;
    font-size: .75em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color:#0F0;
    border: 1px solid rgba(204,204,204, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_geo_location_button {
    height: 50px;
    width: 150px;
    line-height:30px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color:rgba(100,5,239, 0.3); 
    border: 1px solid rgba(204,204,204, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_form_login_reset_password_button {
    height: 80px;
    width: 115px;
    line-height:80px;
    font-size: .75em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    background-color:#FFF; 
    border:hidden;
    background: url(../images/buttons/butt_template.png);
    background-size: 100px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ls_system_error_button {
    height: 618px;
    width: 950px;
    line-height:12px;
    margin-top:0px;
    margin-left:0px;
    border: 1px solid rgba(204,204,204, 0.0);
    /*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7) center;
    */
    background: url(../images/bkgrounds/default_error_handling.png) rgba(255,255,255, 0.0) center;
    background-size: 400px 400px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.75em;
    font-weight: 700;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
    text-indent:0px;
}
.form_errors_form{
    display:none;
    visibility:hidden;
}

.ls_table_full{
    height:517px !important;
    width:718px !important;
    line-height:517px !important;
    background:#FFF;
    border:hidden;
    text-align:center;
    vertical-align:text-top;
    margin:0;
    padding:0;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_input_td {
    height:24px !important;
    width:718px !important;
    line-height:47px !important;
    vertical-align:middle !important;
    border:1px solid rgba(204,204,204, 0.7);
    border-spacing: 0px;
    padding:0;
    margin:0;
}
.ls_input_error_td {
    height:22px !important;
    width:718px !important;
    line-height:47px !important;
    vertical-align:middle !important;
    border:1px solid rgba(252, 71, 71, 0.7);
    border-spacing: 0px;
    padding:0;
    margin:0;
}
.ls_input_table_full_top{
    height:50px !important;
    width:708px !important;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-spacing: 0px;
    background:#FFF;
    font-size:16px;
    font-weight:700;
    text-align:left;
    line-height:25px !important;
    text-decoration:none;
    margin:0;
    padding:0;
}
.es_form_control_div {
    height: 42px;
    width: 130px;
    line-height:42px;
	display: inline-block;
    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    background-color:rgba(100,5,239, 0.3); 
    border:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.es_delete_button {
    height: 40px;
    width: 125px;
    line-height:33px;
    text-align:center;
    text-decoration:none;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:97% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.es_activate_button {
    height: 40px;
    width: 125px;
    line-height:33px;
    text-align:center;
    text-decoration:none;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:97% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.es_activate_status_pending {
    height: 40px;
    width: 125px;
    line-height:33px;
    text-align:center;
    text-decoration:none;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:97% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.es_activate_status_active {
    height: 40px;
    width: 125px;
    line-height:33px;
    text-align:center;
    text-decoration:none;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:97% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.es_activate_status_complete {
    height: 40px;
    width: 125px;
    line-height:33px;
    text-align:center;
    text-decoration:none;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:97% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************************/
/*********************************************/
.ls_notification_middle_td01{
    height:148px !important;
    width:638px;
    line-height:12px;
    text-align:left;
    vertical-align:top;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.2);
}

/*********************************************/
/*********************************************/
.account_social_order{
	height:25px !important;
	width:21px;
    line-height:12px;
	display: inline-block;
    position: absolute;
	margin-top: 10px;
	margin-left: 10px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
	background-size: 21px 25px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 0.80em;
    font-weight: 600;
	
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	/*
	color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    text-align:center;
    vertical-align: middle;
    text-decoration:none;
    text-indent:0px;
}

.entity_social_listing{
	height:40px !important;
	width:40px;
	display: inline-block;
	margin-top: 1px;
	margin-left: 0px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
/************************//************************//**********************  **/
	/************************//*	SOCIAL_POPULATE_WINDOW	*/
	/********//*	SOCIAL_POPULATE_WINDOW	*/
.structured_pop_social_table_main{
    height:620px !important; 
    width:951px !important;
    margin-top: 0px;
	margin-left: 1px;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background-color:rgba(255,255,255, 0.7);
    vertical-align:top;
}

.social_select_page_frame {
    /*
	height:150px !important;
    width:230px;
    */
    display: inline-block;
    margin-top:2px;
	margin-left:3px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_select_item_frame {
	height:150px !important;
    width:230px;
    display: inline-block;
    position:absolute;
    margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    background-position:left;
    background-size: 140px 140px;
    background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.social_select_item_title_01 {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 150.150px 50px;
    background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.social_select_item_title_02 {
	height:30px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:55px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    background-position:left;
    background-size: 10px 10px;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.40em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:top;
	text-align:center;
}

.social_select_profile_title {
	height:30px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:90px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    background-position:left;
    background-size: 10px 10px;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 0.80em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:top;
	text-align:center;
}

/************************//************************//************************/
.social_media_table_bottom_data{
    height:300px !important;
    width:650px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:left;
    vertical-align:top;
    border-spacing: 0px;
    border:0px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.7);
}

.social_view_page_frame {
    /*
	height:150px !important;
    width:230px;
    */
    display: inline-block;
    margin-top:2px;
	margin-left:3px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_view_item_frame {
	height:145px !important;
    width:212px;
    display: inline-block;
    position:absolute;
    margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    background-position:left;
    background-size: 140px 140px;
    background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_view_item_title_01 {
    /*
    
    
    */
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 150.150px 50px;
    background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.social_view_item_title_02 {
	height:30px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:55px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    background-position:left;
    background-size: 10px 10px;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.40em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:top;
	text-align:center;
}

.social_view_profile_title {
	height:30px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:55px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    background-position:left;
    background-size: 10px 10px;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 0.80em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:top;
	text-align:center;
}

.social_view_profile_delete{
    height:50px !important; 
    width:50px !important;
    position:absolute;
    margin-top:90px;
	margin-left:150px;
    border-spacing: 0px;
    border:0px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(252, 71, 71, 0.9);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
    vertical-align:top;
}

/************************//************************//************************/
	/************************//*	SOCIAL_LOGIN	*/
	/********//*	SOCIAL_LOGIN	*/
/*
FACEBOOK
INSTAGRAM
GOOGLE
TWITTER
LINKEDIN
TWITCH
VENMO
YOUTUBE
VIMEO
WEBSITE
ETSY
TIKTOK
SNAPCHAT
PHONE
EMAIL
*/
/*********************************************/
.login_with_facebook {
    height:46px !important;
    width:135px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 5px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.login_with_google {
    height:46px !important;
    width:135px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 5px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.login_with_instagram {
    height:46px !important;
    width:135px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 5px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.login_with_twitch {
    height:46px !important;
    width:135px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 5px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.login_with_github {
    height:46px !important;
    width:135px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 5px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.login_with_microsoft {
    height:46px !important;
    width:135px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 5px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.login_with_apple {
    height:46px !important;
    width:135px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 5px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

/*********************************************/
/*********************************************/
.account_social_listing_default{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
/*********************************************/
/*********************************************/


/*
FACEBOOK
INSTAGRAM
GOOGLE
TWITTER
LINKEDIN
TWITCH
VENMO
YOUTUBE
VIMEO
WEBSITE
ETSY
TIKTOK
SNAPCHAT
PHONE
EMAIL

PINTEREST
REDDIT
PATREON
NEXTDOOR
DAILYMOTION
AMAZON
CLUBHOUSE
ONLYFANS
CASHAPP
FANBASE
APPLE

*/
/************************//************************//************************/
	/************************//*	SOCIAL_SELECT_VIEW_WINDOW	*/
	/********//*	SOCIAL_SELECT_VIEW_WINDOW	*/

.social_media_table{
    height:560px !important; 
    width:950px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    border:0px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7);
}
/************************/
.social_media_table_td_left{
    height:560px !important; 
    width:654px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
	background-color:rgba(255,255,255, 0.7);
}
/************************/
.social_media_table_title{
    height:75px !important; 
    width:654px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:top;
    border-spacing: 0px;
    border:0px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7);
}
/************************/
.social_media_table_top{
    
    height:175px !important; 
    width:654px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    border:0px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7);
}
.social_media_table_td_left1{
    height:175px !important; 
    width:400px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:top;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7);
}
/*******/
.social_media_table_td_left1_label_title{
    height:75px !important; 
    width:100px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}
.social_media_table_td_left1_title_field_td{
    height:75px !important; 
    width:391px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:left;
    vertical-align:top;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}
.social_media_table_td_left1_title_field_field{
    /*
    */
    height:72px !important; 
    width:388px !important;
    margin-top:1px;
    margin-left:1px !important;
    border-spacing: 0px;
    border:0px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.3);
    font-size: 1.00em;
    font-weight: 500;
	text-align:left;
    vertical-align:middle;
    text-decoration:none;
    text-indent:0px;
}
/********/
.social_media_table_td_left1_label{
    height:50px !important; 
    width:100px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}
/*******/
.social_media_table_td_left1_smallfield_td{
    height:50px !important; 
    width:145.5px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:left;
    vertical-align:middle;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}
.social_media_table_td_left1_smallfield_field{
    height:47px !important; 
    width:142px !important;
    margin-top:1px;
    margin-left:1px !important;
    border-spacing: 0px;
    border:0px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.3);
    font-size: 1.00em;
    font-weight: 500;
	text-align:center;
    vertical-align:middle;
    text-decoration:none;
    text-indent:0px;
}
/********/
.social_media_table_td_left1_largefield_td{
    height:50px !important; 
    width:391px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:left;
    vertical-align:middle;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}
.social_media_table_td_left1_largefield_field{
    height:47px !important; 
    width:388px !important;
    margin-top:1px;
    margin-left:1px !important;
    border-spacing: 0px;
    border:0px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.3);
    font-size: 1.00em;
    font-weight: 500;
	text-align:left;
    vertical-align:middle;
    text-decoration:none;
    text-indent:0px;
}
/******************************/
.social_media_table_td_left2{
    height:175px !important; 
    width:98px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:top;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.7);
}
.social_media_table_profile_image{
    height:98px !important; 
    width:98px !important;
    margin-top:5px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:top;
    border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-image:url(../images/buttons/clear_img.png) rgba(255,255,255, 0.4);
    background-position:50% 45%; 
    background-size: 96px 96px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.social_media_table_td_left2_td1{
    height:100px !important; 
    width:98px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:top;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.7);
}
.social_media_table_td_left2_td2{
    height:75px !important; 
    width:98px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:top;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.7);
}
.social_media_table_private_active{
    height:70px !important; 
    width:95px !important;
    margin-top:0px;
    margin-left:0px !important;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
	background-color:rgba(252, 71, 71, 0.7);
    font-size: 1.00em;
    font-weight: 500;
	text-align:center;
    vertical-align:middle;
    text-decoration:none;
    text-indent:0px;
}
.social_media_table_private_default{
    height:70px !important; 
    width:95px !important;
    margin-top:0px;
    margin-left:0px !important;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
	background-color:rgba(10, 245, 92, 0.7);
    font-size: 1.00em;
    font-weight: 500;
	text-align:center;
    vertical-align:middle;
    text-decoration:none;
    text-indent:0px;
}
/*****************************/
.social_media_table_td_left3{
    height:175px !important; 
    width:50px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:top;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.7);
}
.social_media_table_delete{
    /*
    
    
    */
    height:60px !important; 
    width:47px !important;
    margin-top:1px;
    margin-left:1px !important;
    border-spacing: 0px;
    border:0px solid rgba(204,204,204, 0.0);
	background-color:rgba(10, 245, 92, 0.7);
    background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(252, 71, 71, 0.9);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.00em;
    font-weight: 500;
	text-align:center;
    vertical-align:top;
    text-decoration:none;
    text-indent:0px;
}
/*****************************/
.social_media_table_bottom{
    height:350px !important; 
    width:650px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:top;
    border-spacing: 0px;
    border:0px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7);
}
.social_media_table_bottom_menu{
    height:50px !important;
    width:650px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:right;
    vertical-align:middle;
    border-spacing: 0px;
    border:0px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,239, 0.7);
}

.social_media_table_bottom_menu_button_googlecontacts{
    height:50px !important;
    width:100px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
	background-color: rgba(204,204,204, 0.7);
    font-size: 1.00em;
    font-weight: 500;
	text-align:center;
    vertical-align:top;
    text-decoration:none;
    text-indent:0px;
}
/************************/
.social_media_table_td_right{
    height:560px !important; 
    width:295px !important;
    margin-top:0px;
    margin-left:0px !important;
    text-align:center;
    vertical-align:middle;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.7);
}



/************************//************************//************************/





/************************//************************//************************/
.social_media_action_frame_div {
	z-index:5;
	height: 130px;
	width: 130px !important;
    display:inline-block;
	position:absolute;
	margin-top: 425px;
	margin-left: 5px;
    border-radius: 100px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(4, 144, 242, 0.3);
	font-size: 1em;
	font-weight: 600;
    vertical-align:top;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_upload_frame_div {
	z-index:4;
	display:inline-block;
	height: 125px;
	width: 125px !important;
    line-height:125px;
	margin-top: 1px;
	margin-left: 1px;
    border-radius: 100px;
    border:hidden;
    /*
	background-color:rgba(255,255,255, 0.0);
    */
    background: url(../images/bkgrounds/upload_background.png) rgba(255,255,255, 0.0);
    background-size: 100px 100px;
	background-repeat: repeat;
	background-position:center;
	font-size: 1em;
	font-weight: 600;
	vertical-align:top;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_upload_button {
    z-index:5;
	height: 124px;
	width: 125px !important;
	display:inline-block;
    margin-top: 0px;
	margin-left: 0px;
	border-radius: 100px;
    border:hidden;
	background-color:rgba(255,255,255, 0.0);
	background: url(../images/buttons/plus_button.png) rgba(255,255,255, 0.0);
    /*
    background: url(../images/buttons/sys_like_image.png) rgba(255,255,255, 0.0);
    */
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_refresh_view_div{
    height:60px; 
    width:60px;
	line-height: 20px;
	display:inline-block;
	margin-top:5px;
    margin-left:550px;
	position: absolute; 
    border: 1px solid rgba(204,204,204, 0.0);
    background-color: rgba(255,255,255, 0.7);
    vertical-align:middle;
	text-align:center;
}
.social_media_refresh_button{
    height:60px; 
    width:60px;
	line-height: 20px;
	display:inline-block;
	margin-top:0px;
    margin-left:0px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.3);
    /*
    background: url(../images/buttons/sys_refresh_button.png) rgba(255,255,255, 0.0);
    */
    background: url(../images/buttons/sys_refresh_buttonActive.png) rgba(255,255,255, 0.0);
    
	background-position:center;
    background-size: 60px 60px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_empty_item_frame {
	height:620px !important;
    width:940px;
    display: inline-block;
    position:absolute;
    margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    background-position:left;
    background-size: 350px 350px;
    background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.social_empty_refresh_button{
    height:620px !important;
    width:940px;
	line-height: 20px;
	display:inline-block;
	margin-top:0px;
    margin-left:0px;
	position: absolute;
	border: 1px solid rgba(204,204,204, 0.3);
    /*
    background: url(../images/buttons/sys_refresh_button.png) rgba(255,255,255, 0.0);
    background: url(../images/buttons/sys_refresh_buttonActive.png) rgba(255,255,255, 0.0);
    */
    background: url(../images/buttons/sys_refresh_buttonDefault.png) rgba(255,255,255, 0.0);
	background-position:center;
    background-size: 350px 350px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    white-space: normal;
    text-align:center;
	vertical-align: middle;
    box-shadow: none !important;
	font-size: 1.50em;
	font-weight: 700;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
}

/*
    FACEBOOK
    INSTAGRAM
    THREADS
    GOOGLE
    TWITTER
    LINKEDIN
    TWITCH
            VENMO
    YOUTUBE
    VIMEO
    WEBSITE
            ETSY
            TIKTOK
            SNAPCHAT
    PHONE
    EMAIL
            PINTEREST
            REDDIT
    PATREON
    NEXTDOOR
    DAILYMOTION
            AMAZON
    DISCORD
CLUBHOUSE
    ONLYFANS
            CASHAPP
            FANBASE
    APPLE
    

(9)
select_XXX_options1
account_social_XXX_active
share_XXX_options
social_media_select_XXX_lettermark_title
social_media_view_XXX_lettermark_title
social_media_option_XXX_lettermark_title
social_media_option_XXX_lettermark
social_media_option_XXX_class
social_media_option_XXX_active_class

*/
/*******************************************************/
/*******************************************************/
.select_FACEBOOK_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/facebook_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_FACEBOOK_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/facebook_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.share_FACEBOOK_options{
	height:95px !important;
	width:112px;
    display: inline-block;
	margin-top:1px;
	margin-left:2px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/facebook_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_FACEBOOK_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/facebook_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_FACEBOOK_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/facebook_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_FACEBOOK_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/facebook_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_FACEBOOK_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/facebook_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_FACEBOOK_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/facebook_brand.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 70px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_FACEBOOK_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/facebook_brand.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.select_INSTAGRAM_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/instagram_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_INSTAGRAM_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/instagram_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.share_INSTAGRAM_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/instagram_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_INSTAGRAM_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/instagram_brand.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 70px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_INSTAGRAM_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/instagram_brand.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_INSTAGRAM_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/instagram_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_INSTAGRAM_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/instagram_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_INSTAGRAM_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/instagram_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_INSTAGRAM_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/instagram_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_THREADS_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/threads_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_THREADS_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/threads_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_THREADS_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/threads_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_THREADS_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/threads_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_THREADS_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/threads_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_THREADS_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/threads_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_THREADS_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/threads_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_THREADS_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/threads_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_THREADS_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/threads_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_GOOGLE_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/google_active.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_GOOGLE_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/google_active.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_GOOGLE_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/google_active.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.social_media_option_GOOGLE_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/google_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_GOOGLE_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/google_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_GOOGLE_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/google_active.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_GOOGLE_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/google_active.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_GOOGLE_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/google_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_GOOGLE_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/google_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/.
.share_TWITTER_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/x_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_TWITTER_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/x_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_TWITTER_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/x_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.social_media_option_TWITTER_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/x_brand.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 70px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_TWITTER_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/x_brand.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_TWITTER_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/x_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_TWITTER_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/x_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_TWITTER_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/x_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_TWITTER_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/x_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_LINKEDIN_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/linkedin_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_LINKEDIN_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/linkedin_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_LINKEDIN_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/linkedin_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.social_media_option_LINKEDIN_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/linkedin_brand.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 70px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_LINKEDIN_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/linkedin_brand.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_LINKEDIN_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/linkedin_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_LINKEDIN_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/linkedin_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_LINKEDIN_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/linkedin_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_LINKEDIN_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/linkedin_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_TWITCH_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/twitch_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_TWITCH_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/twitch_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_TWITCH_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/twitch_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.social_media_option_TWITCH_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/twitch_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_TWITCH_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/twitch_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_TWITCH_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/twitch_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_TWITCH_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/twitch_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_TWITCH_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/twitch_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_TWITCH_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/twitch_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/

.share_VENMO_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_VENMO_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_VENMO_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_VENMO_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 70px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_VENMO_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_YOUTUBE_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/youtube_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_YOUTUBE_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/youtube_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_YOUTUBE_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/youtube_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_YOUTUBE_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/youtube_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_YOUTUBE_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/youtube_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_YOUTUBE_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/youtube_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_YOUTUBE_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/youtube_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.social_media_select_YOUTUBE_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/youtube_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_YOUTUBE_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/youtube_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_VIMEO_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/vimeo_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_VIMEO_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/vimeo_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_VIMEO_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/vimeo_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_VIMEO_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/vimeo_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_VIMEO_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/vimeo_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_VIMEO_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/vimeo_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_VIMEO_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/vimeo_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.social_media_select_VIMEO_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/vimeo_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_VIMEO_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/vimeo_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_PATREON_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/patreon_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_PATREON_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/patreon_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_PATREON_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/patreon_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_PATREON_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/patreon_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_PATREON_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/patreon_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_PATREON_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/patreon_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_PATREON_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/patreon_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_PATREON_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/patreon_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_PATREON_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/patreon_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_NEXTDOOR_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/nextdoor_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_NEXTDOOR_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/nextdoor_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_NEXTDOOR_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/nextdoor_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_NEXTDOOR_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/nextdoor_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_NEXTDOOR_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/nextdoor_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_NEXTDOOR_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/nextdoor_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_NEXTDOOR_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/nextdoor_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_NEXTDOOR_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/nextdoor_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_NEXTDOOR_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/nextdoor_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_DAILYMOTION_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/dailymotion_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_DAILYMOTION_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/dailymotion_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_DAILYMOTION_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/dailymotion_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_DAILYMOTION_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/dailymotion_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_DAILYMOTION_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/dailymotion_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_DAILYMOTION_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/dailymotion_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_DAILYMOTION_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/dailymotion_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_DAILYMOTION_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/dailymotion_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_DAILYMOTION_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/dailymotion_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_DISCORD_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/discord_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_DISCORD_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/discord_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_DISCORD_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/discord_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_DISCORD_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/discord_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_DISCORD_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/discord_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_DISCORD_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/discord_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_DISCORD_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/discord_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_DISCORD_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/discord_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_DISCORD_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/discord_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.social_media_option_WEBSITE_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 70px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_WEBSITE_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_ETSY_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/etsy_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_ETSY_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/etsy_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_ETSY_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/etsy_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_ETSY_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/etsy_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_ETSY_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/etsy_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_ETSY_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/etsy_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_ETSY_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/etsy_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_ETSY_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/etsy_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_ETSY_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/etsy_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_TIKTOK_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/tiktok_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_TIKTOK_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/tiktok_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_TIKTOK_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/tiktok_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_TIKTOK_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/tiktok_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_TIKTOK_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/tiktok_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_TIKTOK_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/tiktok_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_TIKTOK_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/tiktok_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_TIKTOK_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/tiktok_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_TIKTOK_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/tiktok_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_SNAPCHAT_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/snapchat_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_SNAPCHAT_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/snapchat_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_SNAPCHAT_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/snapchat_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_SNAPCHAT_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/snapchat_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_SNAPCHAT_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/snapchat_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_SNAPCHAT_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/snapchat_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_SNAPCHAT_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/snapchat_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_SNAPCHAT_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/snapchat_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_SNAPCHAT_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/snapchat_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.social_media_option_PHONE_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 70px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_PHONE_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.social_media_option_EMAIL_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 70px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_EMAIL_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_PINTEREST_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/pinterest_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_PINTEREST_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/pinterest_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_PINTEREST_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/pinterest_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_PINTEREST_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/pinterest_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_PINTEREST_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/pinterest_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_PINTEREST_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/pinterest_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_PINTEREST_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/pinterest_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_PINTEREST_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/pinterest_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_PINTEREST_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/pinterest_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_REDDIT_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/reddit_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_REDDIT_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/reddit_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_REDDIT_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/reddit_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_REDDIT_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/reddit_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_REDDIT_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/reddit_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_REDDIT_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/reddit_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_REDDIT_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/reddit_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_REDDIT_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/reddit_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_REDDIT_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/reddit_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_AMAZON_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/amazon_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_AMAZON_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/amazon_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_AMAZON_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/amazon_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_AMAZON_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/amazon_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_AMAZON_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/amazon_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_AMAZON_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/amazon_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_AMAZON_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/amazon_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_AMAZON_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/amazon_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_AMAZON_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/amazon_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_FANBASE_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/fanbase_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_FANBASE_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/fanbase_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_FANBASE_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/fanbase_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_FANBASE_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/fanbase_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_FANBASE_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/fanbase_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_FANBASE_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/fanbase_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_FANBASE_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/fanbase_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_FANBASE_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/fanbase_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_FANBASE_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/fanbase_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_CASHAPP_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/cashapp_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_CASHAPP_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/cashapp_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_CASHAPP_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/cashapp_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_CASHAPP_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/cashapp_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_CASHAPP_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/cashapp_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_CASHAPP_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/cashapp_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_CASHAPP_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/cashapp_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_CASHAPP_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/cashapp_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_CASHAPP_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/cashapp_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/

/*******************************************************/
/*******************************************************/

/*******************************************************/
/*******************************************************/

/*******************************************************/
/*******************************************************/
.share_YAHOO_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/yahoo_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_YAHOO_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/yahoo_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_YAHOO_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/yahoo_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_YAHOO_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/yahoo_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_YAHOO_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/yahoo_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_YAHOO_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/yahoo_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_YAHOO_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/yahoo_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_YAHOO_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/yahoo_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_YAHOO_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/yahoo_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_MICROSOFT_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/microsoft_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_MICROSOFT_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/microsoft_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_MICROSOFT_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/microsoft_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_MICROSOFT_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/microsoft_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_MICROSOFT_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/microsoft_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_MICROSOFT_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/microsoft_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_MICROSOFT_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/microsoft_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_MICROSOFT_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/microsoft_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_MICROSOFT_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/microsoft_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_TELEGRAM_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/telegram_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_TELEGRAM_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/telegram_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_TELEGRAM_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/telegram_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_TELEGRAM_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/telegram_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_TELEGRAM_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/telegram_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_TELEGRAM_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/telegram_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_TELEGRAM_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/telegram_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_TELEGRAM_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/telegram_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_TELEGRAM_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/telegram_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_MASTODON_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/mastodon_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_MASTODON_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/mastodon_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_MASTODON_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/mastodon_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_MASTODON_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/mastodon_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_MASTODON_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/mastodon_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_MASTODON_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/mastodon_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_MASTODON_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/mastodon_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_MASTODON_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/mastodon_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_MASTODON_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/mastodon_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
.share_MEETUP_options{
	height:95px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:10px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/meetup_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_social_MEETUP_active{
	height:45px !important;
	width:41px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/social_logos/meetup_brand.png) rgba(10, 245, 92, 0.3); 
	background-size: 38px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.select_MEETUP_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/social_logos/meetup_brand.png) rgba(10, 245, 92, 0.3);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_MEETUP_lettermark_title{
    height:60px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:7px;
    margin-left:1px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/meetup_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 180/60 = .333  */
    background-size: 180px 60px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
}
.social_media_option_MEETUP_lettermark{
    height:30px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:80px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/meetup_letter.png) rgba(255,255,255, 0.0);
	background-position:center; 
    /* 900/300 = .333  */
    background-size: 90px 30px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_MEETUP_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/meetup_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_option_MEETUP_active_class{
    height:115px; 
    width:180px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	position: absolute;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/social_logos/meetup_brand.png) rgba(255,255,255, 0.0);
	background-position:center; 
    background-size: 100px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_select_MEETUP_lettermark_title {
	height:50px !important;
    width:220px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/meetup_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_media_view_MEETUP_lettermark_title {
	height:50px !important;
    width:205px;
    display: inline-block;
    position:absolute;
    margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/social_logos/meetup_letter.png) rgba(255,255,255, 0.0);
    /* 180/60 = .333  */
    background-position:center;
    background-size: 150.150px 50px;
	background-repeat: no-repeat;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/


/*******************************************************/
/*******************************************************/



/*********************************************/
/*********************************************/
.share_social_listing_default{
	height:95px !important;
	width:112px;
    display: inline-block;
	margin-top:1px;
	margin-left:2px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
	background-size: 80px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.share_ACTIVE_options{
    z-index: 10;
    height:94px !important;
	width:111px;
    display: inline-block;
	margin-top:1px;
	margin-left:2px;
	border-spacing: 0px;
	border:2px solid rgba(4, 144, 242,0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************************************/
/*********************************************/


/*********************************************/
/*********************************************/



/*********************************************/
/*********************************************/



/*********************************************/
/*********************************************/



/*********************************************/
/*********************************************/

/*   -------------------------------------------------- */
/*   -------------------------------------------------- */
/*   ------------    main_profile      ---------------- */
/*   
1) RGB(11,183,255);

2) RGB(232, 232, 232)

3) RGB(255, 68, 255)

4) RGB(255, 255, 255) 
/*
/*  
/*  
/*
/*  
/*
/*  
/* 
/*      
/*
/*
/*		
/*  
/*
/*
/*  
/*     
/*
/*  
/* 
/*
/*
/*		
/*  
/*
/*
/*  
/*  	
/*  
/*  
/* 
/*
/*
/*		
/*  
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 account_compliance_main_table
CONTRUCTION
    BORDERS
        TABLE OUTSIDE   border: 1px solid rgba(209,209,209, 0.7);
        TD OUTSIDE      border: 1px solid rgba(204,204,204, 0.7);

        TABLE INSIDE    border: 1px solid rgba(204,204,204, 0.0);
        TD INSIDE       border: 1px solid rgba(204,204,204, 0.2);

        BUTTON          border: 1px solid rgba(204,204,204, 0.3);    
		
    BACKGROUND
        MAIN            background-color: rgba(255,255,255, 0.7);   
        PAGE            background-color: rgba(255,255,255, 0.2);
        FORM PAGE       background-color: rgba(239,239,239, 0.7);
        MENU            background-color: rgba(239,239,239, 0.7);  
        BUTTON          background-color: rgba(204,204,204, 0.7);   
		
    rgba(255,255,255, 0.7) = #FFF (white)
    rgba(0,0,0, 0.7) =  (black)
    rgba(239,239,239, 0.7) = #EFEFEF (grey)
    rgba(204,204,204, 0.7) = #CCC (dark border)
    rgba(0,255,0, 0.7)  =(green)
    rgba(255,255,0, 0.7) =(yellow)
    rgba(255,0,0, 0.7)  =(red)
    rgba(15,10,222,0.7)  =(blue)


    rgba(15, 5, 26, 0.7) =  (black scheme)
    rgba(10, 245, 92, 0.7)  =(green scheme)
    rgba(4, 144, 242, 0.7)  =(blue scheme)
    rgba(255, 28, 255, 0.7)  =(pink scheme)
    rgba(204, 204, 204, 0.7)  =(grey scheme)
    rgba(252, 71, 71, 0.7)  =(red scheme)
    rgba(255, 255, 128, 0.7) =(yellow scheme)
    204,204,204
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001
    MESSAGE INPUT HEADERS
                        background:rgba(239,239,239, 0.1);
                        border-spacing: 0px;
                        border:hidden;
                        font-size: 1.05em;
                        font-weight: 500;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
    
    LABELS: ls_form_label   reset_form_label    ls_form_full_field
                        
                        font-size: .90em;
                        font-weight: 600;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.2);
                        background:rgba(239,239,239, 0.7);


    BUTONS:
                        font-size: .90em;
                        font-weight: 600;
                        text-align:center;
                        text-indent:0px;
                        text-decoration:none;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.3);
                        background-color: rgba(204,204,204, 0.7);
                        -webkit-box-shadow: none !important;
                        -moz-box-shadow: none !important;
                        box-shadow: none !important;

    INPUT FIELDS        ls_form_full_field_error 
                        height:35px !important;
                        width: 98%  !important;
                        line-height:12px !important;
                        text-align:left;
                        vertical-align:middle;
                        font-size: .80em;
                        font-weight: 500;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
                        border-spacing: 0px;
                        border:hidden;
                        background-color:rgba(239,239,239, 0.7);

ls_form_submit_button   login_submit_default    showroom_theme_image3   business_container_frame   sys_option_default
                                                                                                    
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */

/*********************************************/
/*********************************************/
/************************************/

.mtitle{
    word-wrap: break-word !important;
	overflow-wrap: break-word !important;
}
.btitle{
    word-wrap: break-word !important;
	overflow-wrap: break-word !important;
}
.pic_member_member {
    height:161px !important;
    width:161px;
    top:4px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);    
    /*
    background: url(../images/buttons/clear_img.png);
    */
    background: url(../images/sys_option_default/sys_pro_default.png) rgba(255,255,255, 0.3); 
    background-position:center;
    background-size: 155px 155px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/* BUSINESS VIEW */
.business_showroom_compliance_frame_div{
	z-index: 100;
	height: 364px;
	width: 945px !important;
	line-height: 364px;
	position:absolute;
	
	margin-top: -370px !important;
	margin-left: 1px !important;
	
	vertical-align:middle;
	text-align:center;
	border-spacing: 0px;
	border-left: 1px solid rgba(204,204,204, 0.7);
	border-right: 1px solid rgba(204,204,204, 0.7);
	border-bottom: 1px solid rgba(204,204,204, 0.7);
	border-top: 1px solid rgba(204,204,204, 0.7);
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 1.0);
	background-size: 40% 50%;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_basic_compliance_frame_div{
	height: 365px;
	width: 234px !important;
	line-height: 365px;
	position:relative;
	margin-top: 0px;
	
	vertical-align:middle;
	text-align:center;
	border: hidden;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 1.0);
	background-size: 40% 50%;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_basic_compliance_frame_div{
	height: 364px;
	width: 950px !important;
	line-height: 365px;
	position:relative;
	margin-top: 0px;
	
	vertical-align:middle;
	text-align:center;
	border-left: 1px solid rgba(204,204,204, 0.0);
	border-right: 1px solid rgba(204,204,204, 0.0);
	border-bottom: 1px solid rgba(204,204,204, 0.0);
	border-top: 1px solid rgba(204,204,204, 0.0);
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 1.0);
	background-size: 40% 50%;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*****************/
.business_slice_compliance_frame_div{
	height: 365px;
	width: 234px !important;
	line-height: 364px;
	position:relative;
	
	margin-top: 0px;
	
	vertical-align:middle;
	text-align:center;
	border: hidden;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 1.0);
	background-size: 40% 50%;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_slice_compliance_notification{
	z-index: 100;
	height: 100px;
	width: 200px !important;
	line-height: 30px;
	position:absolute;
	margin-top: 160px;
	margin-left: 255px;
	
	vertical-align:middle;
	text-align:center;
	border: hidden;
}
.business_slice_business_container_td1{
	height: 364px;
	width: 124px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.0);
	
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border: hidden;
	border-spacing: 0px;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
business_container_td1_2_td3_td1
*/
.business_slice_business_container_td2{
	height: 355px !important;
	width: 240px !important;
	line-height: 355px;
	
	background-color:rgba(255,255,255, 0.0);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 0.70em;
    font-weight: 600;
	/*
    text-align:center;
	*/
    text-decoration:none;
}
.business_slice_business_container_td3{
	height: 355px;
	width: 355px !important;
	
	background-color:rgba(255,255,255, 0.0);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************//************************************/
.account_compliance_main_table{
    height:125px !important;
    width:710px !important;
    line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_td01{
    height:123px !important;
    width:498px !important;
	line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,100,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_td01-01{
    height:123px !important;
    width:298px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.3);
    background: rgba(255,100,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_td01-02{
    height:123px !important;
    width:200px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.3);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_td01-02-top{
    height:59px !important;
    width:198px !important;
    line-height:12px !important;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_td01-02-bottom{
    height:59px !important;
    width:198px !important;
    line-height:12px !important;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
account_compliance_main_table_b_row1
*/
.account_compliance_main_table_td02{
    height:123px !important;
    width:208px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(47,255,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_td02-top{
    height:59px !important;
    width:206px !important;
    line-height:12px !important;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_td02-bottom{
    height:59px !important;
    width:206px !important;
    line-height:12px !important;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_notification_main_table{
    height:445px !important;
    width:710px !important;
    line-height:25px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_notification_main_table01{
    height:185px !important;
    width:710px !important;
    line-height:25px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_notification_main_table02{
    height:125px !important;
    width:710px !important;
    line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************************/
.account_compliance_main_table_b_row0{
    height:50px !important;
    width:710px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,107, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_b_row0_td{
    /*
    account_security_manager01
    */
    height:47px !important;
    width:296px !important;
	border-spacing: 0px;
	
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
    border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_security_main_table_b_row0_td1{
    height:47px !important;
    width:136px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_security_main_table_b_row0_td2{
    height:47px !important;
    width:136px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_security_main_table_b_row0_td3{
    height:47px !important;
    width:136px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.account_security_safesearch_positive {
	z-index: 10;
	height:44px !important;
	width:293px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_safesearch_high_manage {
	z-index: 9;
	height:44px !important;
    width:134px !important;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_safesearch_high_negative {
	z-index: 9;
	height:44px !important;
    width:134px !important;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_safesearch_moderate_manage {
	z-index: 9;
	height:44px !important;
    width:134px !important;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(255,255,0, 0.5);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_safesearch_moderate_negative {
	z-index: 9;
	height:44px !important;
    width:134px !important;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_safesearch_low_manage {
	z-index: 9;
	height:44px !important;
    width:134px !important;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(255,0,0, 0.5);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_safesearch_low_negative {
	z-index: 9;
	height:44px !important;
    width:134px !important;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/*
account_security_manager01
*/
.account_compliance_main_table_b_row1{
    height:156px !important;
    width:710px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,47, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_b_td{
    height:73px !important;
    width:141.6px !important;
	line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_security_main_table_b_td1{
    height:140px !important;
    width:280px !important;
	line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_security_main_table_b_td2{
    height:140px !important;
    width:200px !important;
	line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_security_main_table_b_td3{
    height:140px !important;
    width:225px !important;
	line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************************/
.account_compliance_main_table_b_row2{
    height:151px !important;
    width:710px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(100,74,0, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.account_compliance_main_table_b_row2-01{
    height:151px !important;
    width:512px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.3);
    background: rgba(255,100,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_b_row2-02{
    height:151px !important;
    width:196px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.3);
    background: rgba(255,100,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_b_row2-02-top{
    height:48.5px !important;
    width:194px !important;
    line-height:12px !important;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_b_row2-02-middle{
    height:48.5px !important;
    width:194px !important;
    line-height:12px !important;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_compliance_main_table_b_row2-02-bottom{
    height:48.5px !important;
    width:194px !important;
    line-height:12px !important;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************************/
/*

*/
.account_compliance_positive {
	z-index: 10;
	height:118px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_negative {
	z-index: 10;
	height:118px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_terms {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_terms_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_policy {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_policy_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_view01 {
	z-index: 10;
	height:30px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:23px;
	margin-left:135px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 500;
	text-indent: 0px;
	background-color: rgba(203,203,203, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_data_manage {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_data_manage_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_anon_interactions {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_anon_interactions_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************************************/
.account_security_positive {
	z-index: 10;
	height:118px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_privacy_manage {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_data_privacy_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_sensitivity_manage {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_data_sensitivity_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************************************/
.account_schedule_positive {
	z-index: 10;
	height:118px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_schedule_notification_manage {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_schedule_notification_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_schedule_manage {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_schedule_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_holiday_notification_manage {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_holiday_notification_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_schedule_occurance {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_schedule_occurance_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_schedule_stat_manager01{
    height:135px !important;
    width:275px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	position: absolute;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_schedule_stat_manager02{
    height:135px !important;
    width:195px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	position: absolute;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_schedule_stat_manager03{
   height:135px !important;
    width:220px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	position: absolute;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************************************/
.account_compliance_view02 {
	z-index: 10;
	height:30px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:23px;
	margin-left:147px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 500;
	text-indent: 0px;
	background-color: rgba(203,203,203, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_options_count {
	z-index: 10;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 1.20em  !important;
    font-weight: 700;
	text-indent: 40px;
	background-color: rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_options {
	z-index: 9;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_download_log {
	z-index: 10;
	height:46px !important;
	width:192px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_disable_account {
	z-index: 10;
	height:46px !important;
	width:192px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_delete_account {
	z-index: 10;
	height:46px !important;
	width:192px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************************************/
.account_security_manager01{
    height:90px !important;
    width:275px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_security_manager01_negative{
    height:90px !important;
    width:275px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_security_manager02{
    height:90px !important;
    width:210px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_security_manager02_negative{
    height:90px !important;
    width:210px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_security_manager03{
   height:90px !important;
    width:220px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_security_manager03_negative{
   height:90px !important;
    width:220px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/****************************/
/*

account_compliance_main_table_td01-02
*/
.account_email_positive {
	z-index: 10;
	height:178px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_email_negative {
	z-index: 10;
	height:178px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_account {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_account_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_engagement {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_engagement_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_following {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_following_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_recommendations {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_recommendations_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_event_host {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_event_host_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_business_host {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_business_host_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/*
account_compliance_notification_main_table01
*/
.account_compliance_sms_positive {
	z-index: 10;
	height:118px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_sms_negative {
	z-index: 10;
	height:118px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_sms_account {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_sms_account_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_sms_engagement {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_sms_engagement_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_sms_following {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_sms_following_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_sms_recommendations {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_sms_recommendations_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/*
account_compliance_push_following
*/
.account_compliance_push_positive {
	z-index: 10;
	height:118px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_push_negative {
	z-index: 10;
	height:118px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_push_account {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_push_account_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_push_engagement {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_push_engagement_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_push_following {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_push_following_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_compliance_push_recommendations {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_compliance_push_recommendations_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/*

*/
.account_metrics_main_table{
    height:300px !important;
    width:710px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_metrics_main_table01{
    height:296px !important;
    width:706px !important;
    display:inline-block;
	margin-top: 1px;
	margin-left: 1px;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(239,239,239, 0.3);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.account_metrics_settings_canvas{
    height:296px !important;
    width:706px !important;
}
.ls_metrics_button01_silver {
    height: 116px;
    width: 296px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    border:1px solid rgba(233,233,233, 0.8);
	background: url(../images/buttons/account_type_silver.png) rgba(233,233,233, 0.5);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.ls_metrics_button01_gold {
    height: 116px;
    width: 296px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    border:1px solid rgba(233,233,233, 0.8);
	background: url(../images/buttons/account_type_gold.png) rgba(233,233,233, 0.5);
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
.ls_metrics_button01_platinum {
    height: 116px;
    width: 296px;
    line-height:12px;
	margin-top: 2px;
	margin-left: 4px;
    border:1px solid rgba(233,233,233, 0.8);
	background: url(../images/buttons/account_type_platinum.png)rgba(233,233,233, 0.5) ;
    background-size: 110px 110px;
    background-repeat: no-repeat;
    background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle;
	text-align:left;
    text-decoration:none;
	text-indent: 25px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.50em;
    font-weight: 600;
}
/*****************/
.account_metrics_option01 {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_option01_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_option02 {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_option02_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}


/*****************/
.account_metrics_option03 {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_option03_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_option04 {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_option04_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_load_full {
	z-index: 10;
	height:40px !important;
	width:70px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:73px;
	margin-left:220px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 500;
	text-indent: 0px;
	background-color: rgba(203,203,203, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_view01 {
	z-index: 10;
	height:30px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:23px;
	margin-left:141px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 500;
	text-indent: 0px;
	background-color: rgba(203,203,203, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_view02 {
	z-index: 10;
	height:30px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:23px;
	margin-left:147px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 500;
	text-indent: 0px;
	background-color: rgba(203,203,203, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_metrics_data_set {
	z-index: 10;
	height:30px !important;
	width:30px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:5px;
	margin-left:105px;
	vertical-align:middle;
	text-align:center;
	background-color: rgba(203,203,203, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_metrics_options_count {
	z-index: 10;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 1.20em  !important;
    font-weight: 700;
	text-indent: 40px;
	background-color: rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_options01 {
	z-index: 9;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_options02 {
	z-index: 9;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_options03 {
	z-index: 9;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_options04 {
	z-index: 9;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_options05 {
	z-index: 9;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_options06 {
	z-index: 9;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_options07 {
	z-index: 9;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_options08 {
	z-index: 9;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_options09 {
	z-index: 9;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_options10 {
	z-index: 9;
	height:71px !important;
	width:139px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/***********************************/
.base_functional_window_table {
    height:614px !important;
    width:948px !important;
    background-color:rgba(255,255,255, 0.3);
    border:1px solid rgba(233,233,233, 0.8);
    border-spacing: 0px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

} 
.full_menu_td {
	height: 40px;
	width: 948px !important;
	position:relative;
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:middle;
	text-align:left;
	border:1px solid rgba(233,233,233, 0.8);
	background-color:rgba(239,239,239, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_metrics_window_menu_td{
    height:72px !important;
    width:206px !important;
    line-height:12px !important;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.account_metrics_window_main_table{
    height:355px !important;
    width:946px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_metrics_window_main_table01{
    height:353px !important;
    width:944px !important;
    display:inline-block;
	margin-top: 1px;
	margin-left: 1px;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(239,239,239, 0.3);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.account_metrics_window_settings_canvas{
    height:350px !important;
    width:934px !important;
	border:1px solid rgba(239,239,239, 0.2);
    background: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_metrics_window_settings_map{
    height:350px !important;
    width:934px !important;
	display: none;
	border:1px solid rgba(239,239,239, 0.2);
    background: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************/
.account_metrics_window_view01 {
	z-index: 10;
	height:30px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:38px;
	margin-left:101px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 500;
	text-indent: 0px;
	background-color: rgba(203,203,203, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_metrics_window_option01 {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_option01_negative {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_option02 {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_option02_negative {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}


/*****************/
.account_metrics_window_option03 {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_option03_negative {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_option04 {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_option04_negative {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_option05 {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_option05_negative {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_option06 {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_option06_negative {
	z-index: 9;
	height:70px !important;
	width:153px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_metrics_window_main_table_b_row1{
    height:151px !important;
    width:946px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,47, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.account_metrics_window_main_table_b_td{
    height:73px !important;
    width:134.6px !important;
	line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.account_metrics_window_data_set {
	z-index: 10;
	height:30px !important;
	width:30px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:5px;
	margin-left:100px;
	vertical-align:middle;
	text-align:center;
	background-color: rgba(203,203,203, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.account_metrics_window_options01 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options02 {
	z-index: 9;
	height:71px !important;
	width:132px;;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options03 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options04 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options05 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options06 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options07 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options08 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options09 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options10 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options11 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options12 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options13 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.account_metrics_window_options14 {
	z-index: 9;
	height:71px !important;
	width:132px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************************************/
/*********************************************/
.member_view_control_td_visible_cell_title {
    height:70px;
    width:370px;
    display:inline-block;
    position:absolute;
    left:0px;
    border:hidden;
	text-align:center;
	text-decoration:none;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.5em;
    font-weight: 600;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    word-wrap: break-word !important;
}

/*********************************************/
/*********************************************/
/*********************************************/
/*********************************************/
/************************//************************//************************/
.cohost_menu_command_bar{
    height:38px !important;
    width:950px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    text-align:center;
    vertical-align:middle !important;
    text-decoration:none;
    background-color:rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.cohost_menu_command_bar_td1{
    height:38px !important;
    width:100px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border:hidden;
    text-align:center;
    vertical-align:middle !important;
    background-color:rgba(255,255,255, 0.5);
} 
.cohost_menu_command_bar_td2{
    height:38px !important;
    width:145px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border:hidden;
    text-align:center;
    vertical-align:middle !important;
    background-color:rgba(255,255,255, 0.5);
} 

.cohost_menu_1_button {
    height: 37px !important;
    width: 100px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.cohost_menu_1_button_active {
    height: 37px !important;
    width: 145px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.cohost_menu_2_button {
    height: 37px !important;
    width: 145px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.cohost_menu_2_button_active {
    height: 37px !important;
    width: 145px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.cohost_menu_3_button {
    height: 37px !important;
    width: 145px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.cohost_menu_3_button_active {
    height: 37px !important;
    width: 145px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.cohost_menu_4_button {
    height: 37px !important;
    width: 145px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}
.cohost_menu_4_button_active {
    height: 37px !important;
    width: 145px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;  
}
.cohost_menu_5_button  {
    height: 37px !important;
    width: 145px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.cohost_menu_5_button_active {
    height: 37px !important;
    width: 145px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;  
}
.cohost_menu_6_button {
    height: 37px !important;
    width: 145px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.cohost_menu_6_button_active {
    height: 37px !important;
    width: 145px !important;
    line-height:12px;
    font-size: .83em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important; 
}

/**************************************************/
/*
business_container_td1_2_td3_td1_top
business_showroom_activity_frame_div
business_container_block_td4_3_td_menu
*/
.business_container_td1_showroom_top{
	height: 117px;
	width: 355px !important;
	margin-top: 0px;
	position: absolute;
	border: hidden;
	background-color:rgba(255,255,255, 0.6);

	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/**************************************************/
.business_container_td1_2_td3_td2{
	height: 99px;
	width: 356px !important;
	position:relative;
	margin-top: 0px;
	background-color:rgba(255,255,255, 0.3);

	vertical-align:top;
	text-align:left;
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_container_td2{
	height: 580px;
	width: 225px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-left: 1px solid rgba(204,204,204, 0.8);
	border-right: 1px solid rgba(204,204,204, 0.0);
	border-bottom: 1px solid rgba(204,204,204, 0.0);
	border-top: 1px solid rgba(204,204,204, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.basic_theme_settings_div {
    z-index:2;
    display:inline-block;
    position:absolute;

    top:-3px;
    left:903px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;

    border:hidden;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.basic_theme_settings {
    height:45px;
    width:45px;
    display:inline-block;
    position:relative;
    vertical-align:top;
    border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
.block_theme_image1 {
	height: 251px !important;
	width: 718px !important;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
*/
.business_guide_td1{
    height:97px !important;
    width:117px !important;
    line-height: 97px;
	position: absolute;
    vertical-align: top;
	margin-left: 0px;
	margin-top: 1px;
    border-spacing: 0px;
    border: hidden;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_guide_td2{
    height:97px !important;
    width:117px !important;
    line-height: 97px;
	position: absolute;
    vertical-align: top;
	margin-left: 119px;
	margin-top: 1px;
    border-spacing: 0px;
    border: hidden;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.business_guide_td3{
    height:97px !important;
    width:117px !important;
    line-height: 97px;
	position: absolute;
    vertical-align: top;
	margin-left: 238px;
	margin-top: 1px;
    border-spacing: 0px;
    border: hidden;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}/*
.business_report_options1 {
	height:120px !important;
	width:120px !important;
	display:inline-block;
	position: absolute;
	margin-top: 0px;
	
	border:1px solid rgba(209,209,209, 0.3);
	
	vertical-align: top;
	border-spacing: 0px;
	background-color: rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_report_options2 {
	height:120px !important;
	width:120px !important;
	display:inline-block;
	position: absolute;
	margin-top: 121px;
	
	border:1px solid rgba(209,209,209, 0.3);
	
	vertical-align: top;
	border-spacing: 0px;
	background-color: rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.business_report_options3 {
	height:120px !important;
	width:120px !important;
	display:inline-block;
	position: absolute;
	margin-top: 241px;
	
	border:1px solid rgba(209,209,209, 0.3);
	
	vertical-align: top;
	border-spacing: 0px;
	background-color: rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
*/
.business_title_basic_view_div{
	z-index: 100;
    height:125px !important;
    width:620px !important;
	/*
    line-height:125px;
	business_title_field
	*/
    position:absolute;
    left: 95px;
    top: 115px;
    margin:0;
    text-align:center;
    vertical-align:middle !important;
    border:hidden;
    background-color: rgba(255,255,255, 0.1);
    padding:0;
	/*
		business_title_field
	*/
}
.business_title_slice_view_div{
	z-index: 100;
    height:125px !important;
    width:620px !important;
	/*
    line-height:125px;
	*/
    position:absolute;
    left: 95px;
    top: 115px;
    margin:0;
    text-align:center;
    vertical-align:middle !important;
    border:hidden;
    background-color: rgba(255,255,255, 0.1);
    padding:0;
	/*
		business_title_field
	*/
}
.business_title_block_view_div{
	z-index: 100;
    height:125px !important;
    width:620px !important;
	/*
    line-height:125px;
	*/
    position:absolute;
    left: 110px;
    top: 95px;
    margin:0;
    text-align:center;
    vertical-align:middle !important;
    border:hidden;
    background-color: rgba(255,255,255, 0.2);
    padding:0;
}

.business_title_field{
    z-index: 10;
    height:125px !important;
    width: 530px !important;
    /*width: 100%;*/
    line-height:25px !important;
    margin-top: -35px;
	margin-left: 60px;
    /*
    
    margin-left: 50px;
	margin-top: 5px;
    */
    background:rgba(255,255,255, 0.0);
    border-spacing: 0px;
    border:hidden;
    font-size: 2.45em;
    /*
    font-weight: 500;
    */
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    member_view_control_td_visible_cell_title
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    vertical-align:top !important; 
    text-align:left;
    text-decoration:none;
    text-indent:0px;
    word-wrap: break-word !important;
  }
.business_title_edit {
    z-index: 5;
    height: 28px;
    width: 28px;
    display:inline-block;
    position:absolute;
    vertical-align:top;
    margin-top: 1px;
    margin-left: 96%;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
    background-size: 23px 23px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************//************************/
	/********//*	BUSINESS_VIEW_CLASS_BLOCK_FRAME	*/
.business_container_block_td1{
	height: 251px !important;
	width: 950px !important;
	position:relative;
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td1_1{
	height: 248px;
	width: 300px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td1_2{
	height: 248px;
	width: 500px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td1_3{
	height: 248px;
	width: 150px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td2{
	height: 50px;
	width: 950px !important;
	position:relative;
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,180,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td3{
	height: 1px;
	width: 950px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,1, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td4{
	height: 364px;
	width: 950px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td4_1{
	height: 364px;
	width: 125px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: hidden;
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/****************************/
.business_container_block_td4_2{
	height: 365px;
	width: 464px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7); 
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td4_2_1_top{
	height: 50px;
	width: 464px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7); 
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td4_2_1_buttom{
	height: 126px;
	width: 464px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7); 
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td4_2_2{
	height: 180px;
	width: 464px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7); 
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/****************************/
.business_container_block_td4_3{
	height: 360px;
	width: 356px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-left: 1px solid rgba(204,204,204, 0.0);
	border-right: 1px solid rgba(204,204,204, 0.0);
	border-bottom: 1px solid rgba(204,204,204, 0.0);
	border-top: 1px solid rgba(204,204,204, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td4_3_td_menu{
	height: 50px;
	width: 357px !important;
	position:relative;
	background-color:rgba(220,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-left: 1px solid rgba(204,204,204, 0.0);
	border-right: 1px solid rgba(204,204,204, 0.0);
	border-bottom: 1px solid rgba(204,204,204, 0.2);
	border-top: 1px solid rgba(204,204,204, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td4_3_td1{
	height: 211px;
	width: 357px !important;
	position:relative;
	background-color:rgba(255,255,180, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_block_td4_3_td2{
	height: 99px;
	width: 356px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.block_theme_settings_div {
    z-index:2;
    display:inline-block;
    position:absolute;

    top:120px;
    left:903px;
    /*
    margin-left: -10px;
    margin-top: 0px;
    */
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;

    border:hidden;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.block_theme_settings {
    height:45px;
    width:45px;
    display:inline-block;
    position:absolute;
    vertical-align:top;
    border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
.block_theme_image2 {
    height: 246px;
    width: 297px;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.block_theme_image3 {
    height: 246px;
    width: 497px;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.block_theme_image4 {
    height: 246px;
    width: 149px;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.other_theme_image5 {
    height: 257px;
    width: 718px;

    position:absolute;
    display:inline-block;

    top: 5px !important;

    background-color:rgba(204,204,204, 0.7);
    background-position:center;
    background-size:  cover;
    background-repeat: no-repeat;
    border:thin solid rgba(233,233,233, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
*/
.business_upload_frame_div1 {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px !important;
	width: 45px !important;
	margin-top: 2px;
	margin-left: 675px;

	background-color:rgba(204,204,204, 0.7);

	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_upload_frame_div2 {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px !important;
	width: 45px !important;
	margin-top: 2px;
	margin-left: 255px;

	background-color:rgba(24,185,204, 0.7);

	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_upload_frame_div3 {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px !important;
	width: 45px !important;
	margin-top: 2px;
	margin-left: 752px;

	background-color:rgba(204,204,74, 0.7);

	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_upload_frame_div4 {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px !important;
	width: 45px !important;
	margin-top: 2px;
	margin-left: 903px;

	background-color:rgba(14,204,204, 0.7);

	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_upload_frame_div5 {
	z-index:100;
	display:inline-block;
	position:absolute;
	height: 45px !important;
	width: 45px !important;
	margin-top: 2px;
	margin-left: 825px;

	background-color:rgba(204,204,204, 0.7);

	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_upload_frame_div6 {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px !important;
	width: 45px !important;
	margin-top: 0px;
	margin-left: 247px;

	background-color:rgba(24,185,204, 0.7);

	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_upload_frame_div7 {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px !important;
	width: 45px !important;
	margin-top: 0px;
	margin-left: 542px;

	background-color:rgba(204,204,74, 0.7);

	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_upload_frame_div8 {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px !important;
	width: 45px !important;
	margin-top: 0px;
	margin-left: 855px;

	background-color:rgba(14,204,204, 0.7);

	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/************************//************************//************************/
	/************************//*	BUSINESS_SETTINGS	*/
	/********//*	BUSINESS_SETTINGS_MAIN	*/

.business_settings_container {
	height:571px !important;
    width:712px;
    top: 0px !important;
    background-color:rgba(255,255,255, 0.7);
	border-spacing: 0px;
   	border:thin solid rgba(233,233,233, 0.2);
} 
.business_settings_general_row {
	height:439px ;
    width:712px;
    top: 0px !important;
    background-color:rgba(255,255,255, 0.7);
	border-spacing: 0px;
   	border:hidden;
	vertical-align: top;
	padding: 0px;
	margin: 0px;
}
.business_settings_general_container {
	height:439px;
    width:712px;
    top: 0px !important;
	vertical-align: top;
	text-align: left;
    background-color:rgba(255,255,255, 0.7);
	border-spacing: 0px;
   	border:thin solid rgba(233,233,233, 0.2);
	
}
.business_settings_quick_menu {
	height:127px !important;
    width:712px !important;
	position: absolute;
    top: 0px !important;
    background-color:rgba(255,255,255, 0.7);
	border-spacing: 0px;
   	border:thin solid rgba(233,233,233, 0.2);
} 


/*****************************************/
.business_settings_general_theme_td1 {
	height:105px !important;
    width:712px;
    top: 0px !important;
    background-color:rgba(255,255,255, 0.7);
	border-spacing: 0px;
   	border:thin solid rgba(233,233,233, 0.2);
	
}
.business_settings_general_theme_td1_1 {
	height:103px !important;
    width:195px;
    top: 0px !important;
    background-color:rgba(255,255,255, 0.7);
	border-spacing: 0px;
   	border:thin solid rgba(233,233,233, 0.5);
}
.business_settings_general_theme_td1_2 {
	height:103 !important;
    width:127px;
    top: 0px !important;
	margin-left: 1px;
	margin-top: 1px;
    background-color:rgba(255,255,255, 0.7);
	border-spacing: 0px;
   	border:thin solid rgba(233,233,233, 0.5);
	
}

.business_settings_general_theme_button{
    height:103px !important;
    width:186px !important;

    display: inline-block;
    line-height: 20px;
    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 0px;
    margin-left: 5px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.85em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
}
.business_settings_general_theme_basic_default{
    height:103px !important;
    width:123px !important;

    display: inline-block;
    line-height: 20px;
    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 0px;
    margin-left: 0px;

    color:rgba(15, 5, 26, 0.6);
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    background: url(../images/business_details/profile_standard_logo.png) rgba(255,255,255, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_general_theme_basic_active{
    height:103px !important;
    width:123px !important;

    display: inline-block;
    line-height: 20px;
    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 0px;
    margin-left: 0px;

    color:rgba(15, 5, 26, 0.6);
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    background: url(../images/business_details/profile_standard_logo.png) rgba(0,255,0, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_general_theme_block_default{
    height:103px !important;
    width:123px !important;

    display: inline-block;
    line-height: 20px;
    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 0px;
    margin-left: 0px;

    color:rgba(15, 5, 26, 0.6);
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    background: url(../images/business_details/profile_block_logo.png) rgba(255,255,255, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_general_theme_block_active{
    height:103px !important;
    width:123px !important;

    display: inline-block;
    line-height: 20px;
    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 0px;
    margin-left: 0px;

    color:rgba(15, 5, 26, 0.6);
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    background: url(../images/business_details/profile_block_logo.png) rgba(0,255,0, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_general_theme_showroom_default{
    height:103px !important;
    width:123px !important;

    display: inline-block;
    line-height: 20px;
    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 0px;
    margin-left: 0px;

    color:rgba(15, 5, 26, 0.6);
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    background: url(../images/business_details/profile_showroom_logo.png) rgba(255,255,255, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_general_theme_showroom_active{
    height:103px !important;
    width:123px !important;

    display: inline-block;
    line-height: 20px;
    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 0px;
    margin-left: 0px;

    color:rgba(15, 5, 26, 0.6);
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    background: url(../images/business_details/profile_showroom_logo.png) rgba(0,255,0, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_general_theme_slice_default{
    height:103px !important;
    width:123px !important;

    display: inline-block;
    line-height: 20px;
    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 0px;
    margin-left: 0px;

    color:rgba(15, 5, 26, 0.6);
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    background: url(../images/business_details/profile_slice_logo.png) rgba(255,255,255, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_general_theme_slice_active{
    height:103px !important;
    width:123px !important;

    display: inline-block;
    line-height: 20px;
    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 0px;
    margin-left: 0px;

    color:rgba(15, 5, 26, 0.6);
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    background: url(../images/business_details/profile_slice_logo.png) rgba(0,255,0, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}



.business_settings_general_theme_other_default{
    height:123px !important;
    width:123px !important;

    display: inline-block;
    line-height: 20px;
    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 0px;
    margin-left: 0px;

    color:rgba(15, 5, 26, 0.6);
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_general_theme_other_active{
    height:123px !important;
    width:123px !important;

    display: inline-block;
    line-height: 20px;
    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 0px;
    margin-left: 0px;

    color:rgba(15, 5, 26, 0.6);
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    background: url(../images/buttons/butt_template.png) rgba(0,255,0, 0.3);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************//************************/
	/********//*	BUSINESS_VIEW_CLASS_SHOWROOM_FRAME	*/

/*
business_upload_frame_div6
*/
.showroom_container_td1{
	height: 620px;
	width: 294px !important;
	line-height: 620px;
	position:relative;
    margin-top: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.showroom_container_td2{
	height: 620px;
	width: 294px !important;
	line-height: 620px;
	position:relative;
    margin-top: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.showroom_container_td3{
	height: 620px;
	width: 358px !important;
	line-height: 620px;
	position:relative;
    margin-top: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.showroom_theme_image1 {
    height: 618px;
	width: 290px !important;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	/*
    background-repeat: no-repeat;
	*/
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.showroom_theme_image2 {
    height: 618px;
	width: 292px !important;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	/*
    background-repeat: no-repeat;
	*/
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.showroom_theme_image3 {
    /*
    business_showroom_display_DIV01
    
    */
    height: 618px;
	width: 357px !important;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    
    background-repeat: repeat repeat;
	/*
    background-repeat: no-repeat;
	*/
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.showroom_screen_image1 {
    height: 618px;
	width: 292px !important;

    position:absolute;
    display:inline-block;

    top: 0px !important;
	margin-top: 0px;
    background-color:rgba(255,255,255, 0.6);
    background-position:center;
    background-size:  cover;
    background-repeat: no-repeat;
    border:1px thin solid rgba(233,233,233, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.showroom_screen_image2 {
    height: 618px;
	width: 293px !important;
    position:absolute;
    display:inline-block;
    top: 0px !important;
	border:1px thin solid rgba(233,233,233, 0.5);
    background-color:rgba(255,255,255, 0.2);
    background-position:center;
    background-size:  cover;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.showroom_screen_image3 {
    height: 618px;
	width: 356px !important;
    position:absolute;
    display:inline-block;
    top: 0px !important;
	border:1px thin solid rgba(233,233,233, 0.5);
    background-color:rgba(255,255,255, 0.6);
    background-position:center;
    background-size:  cover;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_title_showroom_view_div{
	z-index: 100;
    height:75px !important;
    width:620px !important;
    line-height:125px;
    position:absolute;
    left:105px;
    top:108px;
    margin:0;
    border:hidden;
    background-color: rgba(255,255,255, 0.0);
	/*
    padding:0;
	*/
    text-align:center;
    vertical-align:middle !important;
}
.business_showroom_reporting_frame_div{
    z-index: 20;
	height: 330px;
	width: 160px !important;
	position:absolute;
	margin-top: 293px !important;
	margin-left: 1px !important;
	vertical-align:top;
	text-align:left;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_showroom_guide_frame_div{
    z-index: 20;
	height: 99px;
	width: 356px !important;
	position:absolute;
    border: hidden;
	background-color:rgba(255,255,255, 0.3);
	margin-top: 520px !important;
	margin-left: 590px !important;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*****************************************/
.business_settings_general_theme_td2 {
	height:320px !important;
    width:718px;
    top: 0px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}
/*
logo_count
*/
.business_settings_general_theme_td2_1 {
	height:318px !important;
    width:353px;
    top: 0px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}
.business_settings_general_theme_td2_2 {
	height:318px !important;
    width:350px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}

.business_container_data_menu_catalog_default {
	height: 56px !important;
	width: 56px !important;
	line-height: 50px;
	position: absolute;
	margin-top: 130px;
	margin-left: 5px;
	border: 1px solid rgba(239,239,239, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-position:center; 
    background-size: 40px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.business_container_data_menu_catalog_active {
	height: 56px !important;
	width: 56px !important;
	line-height: 50px;
	position: absolute;
	margin-top: 130px;
	margin-left: 5px;
	border: 1px solid rgba(239,239,239, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-position:center; 
    background-size: 40px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.item_row{
	height:60px !important;
	width:260px !important;
	line-height: 20px;
}
.business_item_recomm {
	z-index: 20;
	height:56px !important;
	width:65px !important;
	line-height: 20px;

	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	
	border-spacing: 0px;
	border: thin solid rgba(239,239,239, 0.7);
	vertical-align: top;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
   	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-position:center; 
    background-size: 40px 40px;
    background-repeat:no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
		event_container_data8_1
	business_settings_rec_button04
	*/
}
.business_item_recomm_active {
	z-index: 20;
	height:56px !important;
	width:65px !important;
	line-height: 20px;

	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	
	border-spacing: 0px;
	border: thin solid rgba(239,239,239, 0.7);
	vertical-align: top;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-position:center; 
    background-size: 40px 40px;
    background-repeat:no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
		event_container_data8_1
	*/
}
.business_item_recommlist {
	z-index: 18;
	height:56px !important;
	width:72px !important;
	line-height: 20px;
	display:inline-block;
	vertical-align: top;
    text-align:center;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border: hidden;
	
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.4);
	background-size: 185px 56px;
	background-repeat: no-repeat;
	background-position:center;
	
	font-size: 1.05em;
	font-weight: 700; 
    /*
    
    */
    /*
   	color: #000;
	text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
		event_container_data8_1
	*/
}
/************************//************************//************************/
	/********//*	BUSINESS_VIEW_CLASS_SLICE_FRAME	*/
.slice_container_td{
	height: 620px;
	width: 950px !important;
	line-height: 620px;
	position:relative;
    margin-top: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.slice_container_td1{
	height: 250px;
	width: 947px !important;
	line-height: 620px;
	position:relative;
    margin-top: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.slice_container_td2{
	height: 160px;
	width: 947px !important;
	position:relative;
    margin-top: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.slice_container_td3{
	height: 210px;
	width: 947px !important;
	position:relative;
    margin-top: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.slice_theme_image1 {
    height: 250px;
	width: 940px !important;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	/*
    background-repeat: no-repeat;
	*/
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.slice_theme_image2 {
    height: 160px;
	width: 940px !important;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	/*
    background-repeat: no-repeat;
	*/
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.slice_theme_image3 {
    /*
    business_showroom_display_DIV01
    
    */
    height: 210px;
	width: 940px !important;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    
    background-repeat: repeat repeat;
	/*
    background-repeat: no-repeat;
	*/
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************//************************/
	/********//*	BUSINESS_VIEW_CLASS_BLOCK_FRAME	*/

.block_container_td{
	height: 620px;
	width: 950px !important;
	line-height: 620px;
	position:relative;
    margin-top: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.block_container_td1{
	height: 617px;
	width: 947px !important;
	line-height: 617px;
	position:relative;
    margin-top: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}

.block_theme_image1 {
    /*
    business_showroom_display_screen_DIV
    */
    z-index:0;
    height: 617px;
	width: 940px !important;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	/*
    background-repeat: no-repeat;
	*/
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.block_theme_image2_div {
    z-index:1;
    height: 400px;
	width: 675px !important;
	position:absolute;
    display:inline-block;
    margin-top: 75px;
    margin-left: 250px;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	/*
    background-repeat: no-repeat;
	*/
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.block_theme_image2 {
    height: 400px;
	width: 675px !important;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	/*
    background-repeat: no-repeat;
    business_theme_showroom_settings_div
	*/
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_theme_block_settings_div {
    /*

    */
    z-index:100 !important;
    height: 45px;
	width: 45px !important;
    display:inline-block;
    position:absolute;
    margin-top: 0px;
	margin-left: 525px;
    border:hidden;
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************//************************/
	/********//*	BUSINESS_VIEW_CLASS_STANDARD_FRAME	*/
.standard_container_td{
	height: 620px;
	width: 950px !important;
	line-height: 620px;
	position:relative;
    margin-top: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.standard_container_td1{
	height: 250px;
	width: 947px !important;
	line-height: 620px;
	position:relative;
    margin-top: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_theme1_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_theme_standard_settings_div {
    /*

    */
    z-index:100 !important;
    height: 45px;
	width: 45px !important;
    display:inline-block;
    position:absolute;
    margin-top: 0px;
	margin-left: 625px;
    border:hidden;
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.standard_theme_image1 {
    height: 300px;
	width: 940px !important;
	position:absolute;
    display:inline-block;
	border: hidden;
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: repeat repeat;
	/*
    background-repeat: no-repeat;
	*/
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************************************/
.business_settings_general_theme_media_menu {
	height:127px !important;
    width:711px !important;
    line-height:127px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_general_theme_media_menu_td1 {
	height:124px !important;
    width:262px !important;
    line-height:127px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_general_theme_media_menu_td2 {
	height:124px !important;
    width:442px !important;
    line-height:22px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
	text-align: left;
	vertical-align: top;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.business_settings_rec_button01 {
	height:68px !important;
    width:153px !important;
    line-height:22px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.4);
	text-align: left;
	vertical-align: top;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_rec_button01_count {
	z-index: 10;
	height:60px !important;
    width:80px !important;
    line-height:22px !important;
	position: absolute;
	margin-left:70px;
	margin-top: 4px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
    background-position:center;
    background-size: 60px 45px;
    background-repeat: no-repeat;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}
.business_settings_rec_button02 {
	height:68px !important;
    width:153px !important;
    line-height:22px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.4);
	text-align: left;
	vertical-align: top;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_rec_button02_count {
	z-index: 10;
	height:60px !important;
    width:80px !important;
    line-height:22px !important;
	position: absolute;
	margin-left:70px;
	margin-top: 4px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
    background-position:center;
    background-size: 60px 45px;
    background-repeat: no-repeat;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}
.business_settings_rec_button03 {
	height:68px !important;
    width:130px !important;
    line-height:22px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.4);
	text-align: center;
	vertical-align: top;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_settings_rec_button04 {
	height:52px !important;
    width:109px !important;
    line-height:22px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	text-align: left;
	vertical-align: top;
	/*
	recommendation_manager_menu1_active
	*/
	background: url(../images/business_details/business_global_metrics_icon.png) rgba(255,255,255, 0.4);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:10% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_settings_rec_button04_count {
	z-index: 10;
	height:45px !important;
    width:48px !important;
    line-height:22px !important;
	position: absolute;
	margin-left:58px;
	margin-top: 4px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
    background-position:center;
    background-size: 45px 40px;
    background-repeat: no-repeat;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.70em;
    font-weight: 500;
}
.business_settings_rec_button05 {
	height:52px !important;
    width:109px !important;
    line-height:22px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	text-align: left;
	vertical-align: top;
	/*
	recommendation_manager_menu2_default
	
	*/
    background: url(../images/buttons/sys_pro_default.png) rgba(255,255,255, 0.4);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:10% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_settings_rec_button05_count {
	z-index: 10;
	height:45px !important;
    width:48px !important;
    line-height:22px !important;
	position: absolute;
	margin-left:58px;
	margin-top: 4px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
    background-position:center;
    background-size: 45px 40px;
    background-repeat: no-repeat;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.70em;
    font-weight: 500;
}
.business_settings_rec_button06 {
	height:52px !important;
    width:109px !important;
    line-height:22px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	text-align: left;
	vertical-align: top;
	/*
	recommendation_manager_menu3_default
	*/
    background: url(../images/sys_option_default/default_building.png) rgba(255,255,255, 0.4);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:10% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_settings_rec_button06_count {
	z-index: 10;
	height:45px !important;
    width:48px !important;
    line-height:22px !important;
	position: absolute;
	margin-left:58px;
	margin-top: 4px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
    background-position:center;
    background-size: 45px 40px;
    background-repeat: no-repeat;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.70em;
    font-weight: 500;
}
.business_settings_rec_button07 {
	height:52px !important;
    width:109px !important;
    line-height:22px !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	text-align: left;
	vertical-align: top;
	/*
	recommendation_frame_action_slot
	*/
    background: url(../images/sys_option_default/sys_pin_default.png) rgba(255,255,255, 0.4);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:10% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_settings_rec_button07_count {
	z-index: 10;
	height:45px !important;
    width:48px !important;
    line-height:22px !important;
	position: absolute;
	margin-left:58px;
	margin-top: 4px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
    background-position:center;
    background-size: 45px 40px;
    background-repeat: no-repeat;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.70em;
    font-weight: 500;
}
/*****************************************/
.business_settings_general_theme_td_logo_menu1 {
	height:50px !important;
    width:77px;
    
	margin-left: 1px;
	vertical-align: top  !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}
.business_settings_general_theme_td_logo_menu2 {
	height:50px !important;
    width:77px;
    
	margin-left: 1px;
	vertical-align: top  !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}
.business_settings_general_theme_td_logo_menu3 {
	height:50px !important;
    width:77px;
   
	margin-left: 1px;
	vertical-align: top  !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}
.business_settings_general_theme_td_logo_menu4 {
	height:50px !important;
    width:108px;
   
	margin-left: 1px;
	vertical-align: top  !important;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}
.business_settings_general_theme_td_logo_frame {
	height:260px !important;
    width:349px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.7);
}

.logo_file_upload_button {
	z-index: 10;
	height:43px !important;
	width:43px !important;
	display:inline-block;
	position:absolute;
	margin-left: 305px;
	margin-top: 2px;
	vertical-align:top;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/buttons/sys_upload_graphic.png) rgba(255,255,255, 0.7);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.logo_file_delete_button {
	z-index: 10;
	height:43px !important;
	width:43px !important;
	display:inline-block;
	position:absolute;
	margin-left: 305px;
	margin-top: 216px;
	vertical-align:top;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(255,255,255, 0.7);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.logo_file_profile_button {
	/*
	star
	
	*/
	z-index: 10;
	height:44px !important;
	width:44px !important;
	display:inline-block;
	position:absolute;
	margin-left: 259px;
	margin-top: 2px;
	vertical-align:top;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/buttons/star-on.svg) rgba(255,255,255, 0.0);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/*
select_brand_options1
*/
.brandmark_logo_file_theme {
	z-index: 9;
    height: 260px;
    width: 349px;
    position:relative;
    display:inline-block;
    top: 0px !important;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/business_details/default_brandmark_logo.png) rgba(204,204,204, 0.0);
    background-position:center;
    background-size: 160px 160px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.lettermark_logo_file_theme {
	z-index: 9;
    height: 260px;
    width: 349px;
    position:relative;
    display:inline-block;
    top: 0px !important;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/business_details/default_lettermark_logo.png) rgba(204,204,204, 0.0);
    background-position:center;
    background-size: 325px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.logo_profile_label {
	z-index: 9;
    height:48px !important;
    width:78px;
    position:absolute;
    display:inline-block;
    margin-left: 0px;
	margin-top: 0px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/sys_option_default/sys_pro_default.png) rgba(255,255,255, 0.3); 
    background-position:05% 50%;
    background-size: 33px 33px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.logo_business_label {
	z-index: 9;
    height:48px !important;
    width:78px;
    position:absolute;
    display:inline-block;
    margin-left: 0px;
	margin-top: 0px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/sys_option_default/default_building.png) rgba(255,255,255, 0.3); 
    background-position:05% 50%;
    background-size: 33px 33px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.logo_event_label {
	z-index: 9;
    height:48px !important;
    width:78px;
    position:absolute;
    display:inline-block;
    margin-left: 0px;
	margin-top: 0px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
	background: url(../images/sys_option_default/sys_pin_default.png) rgba(255,255,255, 0.3); 
    background-position:05% 50%;
    background-size: 33px 33px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.logo_count1 {
	z-index: 9;
    height:48px !important;
    width:78px;
    position:absolute;
    display:inline-block;
    margin-left: 0px;
	margin-top: 0px;
	background-color:rgba(255,255,255, 0.0);
    background-position:05% 50%;
    background-size: 120px 15px;
    background-repeat: no-repeat;
    border:thin solid rgba(233,233,233, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	color:rgba(15, 5, 26, 0.6);
	text-indent: 5px;
    font-size: 1.00em  !important;
    font-weight: 500;
    text-align:right;
    vertical-align:middle;
    text-decoration:none;
}
.logo_count2 {
	z-index: 9;
    height:48px !important;
    width:78px;
    position:absolute;
    display:inline-block;
    margin-left: 0px;
	margin-top: 0px;
	background-color:rgba(255,255,255, 0.0);
    background-position:05% 50%;
    background-size: 120px 15px;
    background-repeat: no-repeat;
    border:thin solid rgba(233,233,233, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	color:rgba(15, 5, 26, 0.6);
	text-indent: 5px;
    font-size: 1.00em  !important;
    font-weight: 500;
    text-align:right;
    vertical-align:middle;
    text-decoration:none;
}
.logo_count3 {
	z-index: 9;
    height:48px !important;
    width:78px;
    position:absolute ;
    display:inline-block;
    margin-left: 0px;
	margin-top: 0px;
	background-color:rgba(255,255,255, 0.0);
    background-position:05% 50%;
    background-size: 120px 15px;
    background-repeat: no-repeat;
    border:thin solid rgba(233,233,233, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	color:rgba(15, 5, 26, 0.6);
	text-indent: 5px;
    font-size: 1.00em  !important;
    font-weight: 500;
    text-align:right;
    vertical-align:middle;
    text-decoration:none;
}
/*
business_settings_general_theme_td_logo_menu3
*/
.logo_data {
	z-index: 9;
    height:48px !important;
    width:108px;
    position:absolute ;
    display:inline-block;
    margin-left: 0px;
	margin-top: 0px;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3); 

    background-position:05% 50%;
    background-size: 40px 30px;
    background-repeat: no-repeat;
    border:thin solid rgba(233,233,233, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	color:rgba(15, 5, 26, 0.6);
	text-indent: 5px;
    font-size: 1.00em  !important;
    font-weight: 500;
    text-align:right;
    vertical-align:middle;
    text-decoration:none;
}
.logo_lettermark_active {
	z-index: 9;
    height:48px !important;
    width:66px;
    position:relative;
    display:inline-block;
    top: 0px !important;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3); 
    background-position:50% 50%;
    background-size: 52px 27px;
    background-repeat: no-repeat;
    border:thin solid rgba(233,233,233, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.logo_brandmark_active {
	z-index: 9;
    height:48px !important;
    width:66px;
    position:relative;
    display:inline-block;
    top: 0px !important;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3); 
    background-position:50% 50%;
    background-size: 50px 42px;
    background-repeat: no-repeat;
    border:thin solid rgba(233,233,233, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}


/************************************/
/************************//************************//************************/
	/************************//*	BUSINESS_RECOMMENDATION	*/
	/********//*	BUSINESS_RECOMMENDATION_MAIN	*/

.business_recommendations_edit {
	height: 28px;
	width: 28px;
	display:inline-block;
	position:absolute;
	vertical-align:top;
	margin-top: 1px;
	margin-left: 690px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_recommendations_block_small_edit {
	height: 28px;
	width: 28px;
	display:inline-block;
	position:absolute;
	vertical-align:top;
	margin-top: 1px;
	margin-left: 50px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_recommendations_block_long_edit {
	z-index: 100;
	height: 28px;
	width: 28px;
	display:inline-block;
	margin-top: 1px;
	margin-left: 158px;
	position: absolute;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_recommendations_block_large_edit { 
	z-index: 100;
	height: 28px;
	width: 28px;
	display:inline-block;
	margin-top: 1px;
	margin-left: 158px;
	position: absolute;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_recommendations_basic_small_edit {
	height: 28px;
	width: 28px;
	display:inline-block;
	position:absolute;
	vertical-align:top;
	margin-top: 1px;
	margin-left: 33px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_recommendations_basic_long_edit {
	z-index: 100;
	height: 28px;
	width: 28px;
	display:inline-block;
	margin-top: 1px;
	margin-left: 140px;
	position: absolute;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*****************************************//*****************************************/
/*****************************************//*****************************************/
/*********************************************** */
.business_basic_frame_table{
	height:190px !important;
	width:238.0px !important;
	line-height: 190px !important;
	position: relative;
	margin-top: 0px;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:hidden;
	background-color: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_basic_frame_table1{
	height:60px !important;
	width:238.0px !important;
	line-height: 60px !important;
	position: relative;
	margin-top: 0px;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:hidden;
	background-color: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_basic_frame_rec_long_td1{
	height:59px !important;
	width:170.0px !important;
	line-height: 57px;
	position: relative;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.5);
	background-color: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_basic_frame_rec_long_default{
	height:57px !important;
	width:168.0px !important;
	line-height: 12px;

	margin-top: 0px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 168px 57px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}
.business_basic_frame_rec_small_td1{
	height:59px !important;
	width:63.0px !important;
	line-height: 57px;
	position: relative;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.5);
	background-color: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_basic_frame_rec_small_default{
	height:57px !important;
	width:61.0px !important;
	line-height: 15px;
	margin-top: 0px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 61px 57px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}
/************************************************/
.business_block_frame_rec_long_td1{
	height:59px !important;
	width:187.0px !important;
	line-height: 57px;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.5);
	background-color: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
recommendation_results_frame_letter
*/
.business_block_frame_rec_long_default{
	height:57px !important;
	width:185.0px !important;
	line-height: 12px;
	margin-top: 0px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 146.3px 55px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}

.business_block_frame_rec_small_td1{
	height:59px !important;
	width:80.0px !important;
	line-height: 57px;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.5);
	background-color: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_block_frame_rec_small_default{
	height:57px !important;
	width:78.0px !important;
	line-height: 15px;
	margin-top: 0px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 55px 55px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}

.business_block_frame_rec_large_td1{
	height:120px !important;
	width:187.0px !important;
	line-height: 102px;
	position: absolute;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.5);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_block_frame_rec_large_default{
	height:119px !important;
	width:185.0px !important;
	line-height: 15px;
	margin-top: 0px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 117px 117px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}
/*****************************************//*****************************************/
/*****************************************//*****************************************/
.business_showroom_display_DIV01 {
    z-index:0;
	height: 622px;
	width: 947px !important;
    position: absolute;
    margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(239,239,239, 0.9);
	background: url(../images/bkgrounds/business_header_default.png) rgba(255,255,255, 0.3);
    /*
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    showroom_theme_image2
    showroom_theme_image3
    media_menu_business_basic_display
    */
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_showroom_display_screen_DIV {
    z-index:0;
	height: 624px;
	width: 950px !important;
    position: absolute;
    top:0px;
    margin-top: -2px;
	margin-left: -2px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_block_display_screen_DIV {
    z-index:3;
	height: 624px;
	width: 950px !important;
    position: absolute;
    top:0px;
    margin-top: -2px;
	margin-left: -2px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/*****************************************/
.business_theme_showroom_settings_div {
    /*

    */
    z-index:100 !important;
    height: 45px;
	width: 45px !important;
    display:inline-block;
    position:absolute;
    margin-top: 0px;
	margin-left: 615px;
    border:hidden;
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************************************/
.business_mgmt_showroom_DIV {
    z-index:100;
	height: 45px;
	width: 575px !important;
    position: absolute;
    margin-top: 5px;
	margin-left: 280px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_mgmt_showroom_DIV1 {
    /*
    event_mgmt_display3_DIV
    event_display2_action_frame_div
    event_theme_display3_settings_div
    event_theme_display3_1
    */
    z-index:100;
	height: 45px;
	width: 130px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_mgmt_showroom_DIV2 {
    /*
    
    */
    z-index:100;
	height: 45px;
	width: 130px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_mgmt_showroom_DIV3 {
    z-index:100;
	height: 45px;
	width: 130px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_mgmt_action_DIV4 {
    z-index:100;
	height: 45px;
	width: 200px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:right;
}
/*****************************************/
/***************/
.business_mgmt_social_display_DIV {
    /*
    business_mgmt_showroom_content_DIV
    */
    z-index:100;
	height: 45px;
	width: 580px !important;
    position: absolute;
    margin-top: 195px;
	margin-left: 175px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_mgmt_social_display_DIV1 {
    /*
    child_standard_social_div
    
    */
    z-index:100;
	height: 45px;
	width: 230px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.1);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_mgmt_social_display_DIV2 {
    z-index:100;
	height: 45px;
	width: 0px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(239,45,239, 0.0);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_mgmt_social_display_DIV3 {
    z-index:100;
	height: 45px;
	width: 135px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.1);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_mgmt_social_display_DIV4 {
    /*
    event_item_register_active
    */
    z-index:100;
	height: 45px;
	width: 160px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.1);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************/
.business_mgmt_showroom_content_DIV {
    /*
    
    business_title_field
    */
    z-index:105;
	height: 153px;
	width: 581px !important;
    position: absolute;
    margin-top: 240px;
	margin-left: 175px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_mgmt_showroom_description_DIV{
    z-index:100;
	height: 150px;
	width: 370px !important;
    position: absolute;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_media_showroom_DIV {
    z-index:100;
	height: 150px;
	width: 160px !important;
    position: absolute;
    margin-top: 2px;
	margin-left: 418px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_media_display_DIV_td01 {
    /*
    media_menu_event_pb_item_third_option
    */
    z-index:100;
	height: 48px;
	width: 160px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/* business_container_basic_description_options_settings
.media_menu_event_pb_item_third_option{
    
    media_menu_business_basic_display
    
	z-index:100;
	height: 95px;
	width: 155px !important;
	margin-top: 0px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.0);
	background:  rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
*/
.media_menu_business_basic_display{
    /*
    business_container_basic_description_frame
    media_menu_event_item_half_display_other
    */
	z-index:2;
	height: 145px;
	width: 157px !important;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color:rgba(255,255,255, 0.9);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ls_menu_business_basic_display_field{
    /*
    ls_menu_event_item_half_display_field_other
    */
    z-index:1;
    height: 145px;
	width: 120px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 1px;
    border-spacing: 0px;
    border: thin solid rgba(239,239,239, 0.0);
	background:rgba(255,255,255, 0.9);
    font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
/*
.media_menu_business_basic_option{
	height: 117px;
	width: 100px !important;
	margin-top: 0px;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);

	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.6);
    background-position:center;
    background-size: 80px 80px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
*/
.ls_menu_business_basic_option_field{
    /*
    ls_menu_event_item_half_option_field_other
    */
    z-index:2;
    height: 145px;
	width: 55px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 100px;
    background:rgba(238,238,238, 0.0);
    border-spacing: 0px;
    border: thin solid rgba(255,255,255, 0.9);
    font-size: 1.25em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
}
.media_menu_business_showroom_option{
    /*
    media_menu_event_item_half_option_other
    media_menu_business_basic_display
    */
    z-index:100;
	height: 145px;
	width: 157px !important;
    position: absolute;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.0);
	background:  rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.ls_menu_business_showroom_option_field{
    height:117px !important;
    width: 100px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;
    background:rgba(238,238,238, 0.5);
    border-spacing: 0px;
    border: thin solid rgba(239,239,239, 0.7);
    font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
}
/*****************************************/
/***********************************/
.business_theme7_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_theme8_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_theme9_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*****************************************/
/***********************************/
/*****************************************/
/***********************************/
.business_theme1_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_theme2_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_theme3_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_theme4_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_theme5_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_theme6_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/************************//************************//************************/
	/************************//*	BUSINESS_TYPE_CLASS	*/
	/********//*	BUSINESS_TYPE_CLASS_MAIN	*/

.business_messagedisplayselection_type_default{
    z-index: 1;
    height:60px !important;
    width:212px !important;

    display: inline-block;
    line-height:20px;
    position:absolute;

    border-spacing: 0px;
    border:1px solid #CCC;

    color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    margin-top: 3px;
    margin-left: -218px;
    margin-right: 0;
    padding: 0;

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:5% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_messagedisplaychild_type_flare{
    z-index: 1;
    height:138px !important;
    width:80px !important;

    display: inline-block;
    line-height: 20px;
    position:absolute;

    border-spacing: 0px;
    border:1px solid #CCC;

    margin-top: 2px;
    margin-left: -82px;

    color:rgba(15, 5, 26, 0.6);
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

}
.business_messagedisplayselection_type_bl{
	z-index: 1;
	height:30px !important;
	width:70px !important;

	display: inline-block;
	line-height:20px;
	position:absolute;

	border-spacing: 0px;
	border:1px solid #CCC;

	color:#888888;
	font-size: 0.60em  !important;
	font-weight: 700;
	text-align:center;
	vertical-align:top;
	text-decoration:none;

	margin-top: 110px;
	margin-left: -180px;
	margin-right: 0;
	padding: 0;

	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.5);
	background-size: 1px 10px;
	background-repeat: no-repeat;
	background-position:5% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_messagedisplayselection_type_br{
	z-index: 1;
	height:30px !important;
	width:70px !important;

	display: inline-block;
	line-height:20px;
	position:absolute;

	border-spacing: 0px;
	border:1px solid #CCC;

	color:#888888;
	font-size: 0.60em  !important;
	font-weight: 700;
	text-align:center;
	vertical-align:top;
	text-decoration:none;

	margin-top: 110px;
	margin-left: -100px;
	margin-right: 0;
	padding: 0;

	background: url(../images/buttons/butt_template.png) rgba(45,255,255, 0.5);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:5% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_selection_type_1{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype1.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_2{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype2.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_3{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype3.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_4{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype4.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.business_selection_type_5{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype5.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_6{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype6.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_7{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype7.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_8{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype8.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_9{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype9.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_10{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype10.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_11{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype11.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_12{
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid #CCC;
    background: url(../images/event_types/eventtype12.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_13{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype13.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_14{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype14.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_15{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype15.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_16{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype16.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_17{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype17.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_18{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype18.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_19{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype19.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_selection_type_20{
    z-index: 0;
    height:140px !important;
    width:220px !important;
    margin-top: 2px;
    margin-left: 4px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/event_types/eventtype20.png) rgba(255,255,255, 0.5);
    background-position:50% 45%;
    background-size: 90px 130px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}


/************************************/
/************************//************************//************************/
	/************************//*	BUSINESS_VIEW_CLASS	*/
	/********//*	BUSINESS_VIEW_CLASS_MAIN	*/

.business_container_frame{
	height: 622px;
	width: 950px !important;
	position:relative;
	margin-top: 0px;
	border: 1px solid rgba(239,239,239, 0.3);
    background-color:rgba(255,255,255, 0.3);
    padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
	/********//*	BUSINESS_VIEW_CLASS_BASIC_FRAME	*/
.business_container_td1{
	height: 620px;
	width: 725px !important;
	line-height: 24px;
	position:relative;
	background-color:rgba(255,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-left: 1px solid rgba(204,204,204, 0.0);
	border-right: 1px solid rgba(204,204,204, 0.0);
	border-bottom: 1px solid rgba(204,204,204, 0.0);
	border-top: 1px solid rgba(204,204,204, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_td1_1{
	height: 249px;
	width: 721px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-left: 1px solid rgba(204,204,204, 0.0);
	border-right: 1px solid rgba(204,204,204, 0.0);
	border-bottom: 1px solid rgba(204,204,204, 0.0);
	border-top: 1px solid rgba(204,204,204, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_basic_td1_1{
	height: 249px;
	width: 718px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-left: 1px solid rgba(204,204,204, 0.0);
	border-right: 1px solid rgba(204,204,204, 0.0);
	border-bottom: 1px solid rgba(204,204,204, 0.0);
	border-top: 1px solid rgba(204,204,204, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_td1_2{
	height: 365px;
	width: 234px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_td1_2_td1{
	height: 364px;
	width: 124px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);
	
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border:1px solid rgba(209,209,209, 0.3);
	border-spacing: 0px;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_td1_2_td2{
	height: 355px !important;
	width: 240px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 0.70em;
    font-weight: 600;
	/*
    text-align:center;
	*/
    text-decoration:none;
}
.business_container_td1_2_td2_1_top{
	height: 50px !important;
	width: 238px !important;
	line-height: 50px !important;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_td1_2_td2_1_bottom{
	height: 118px !important;
	width: 238px !important;
	line-height: 118px !important;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.0);
	/*
	background-color:rgba(255,255,255, 0.3);
	*/
	background-color:rgba(233,233,233, 0.6);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_td1_2_td2_2{
	height: 190px !important;
	width: 238px !important;
	line-height: 190px !important;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/**************************************/
.business_container_td1_2_td3{
	height: 355px;
	width: 355px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.business_container_td1_2_td3_menu{
	height: 50px;
	width: 357px !important;
	position:relative;
	background-color:rgba(220,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(204,204,204, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
business_container_td1_2_td3_td1_bottom
business_container_td1_2_td2_1_bottom
business_showroom_activity_frame_div
ls_menu_business_basic_display_field
business_container_block_td4_2_1_buttom

*/
.business_container_basic_description_options_settings {
    /*
    event_messagedisplaytype_event_view_options_item
    
    */
	z-index: 5;
	height:28px;
	width:28px;
	display:inline-block;
	/**/
	position:absolute;
	vertical-align:top;
	margin-top: 3px;
	margin-left: 385px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;

}
.business_container_basic_description_frame{
    /*
    event_messagedisplaytype_event_view_options_item
    
    */
	z-index: 0;
    height:147px !important;
    width: 415px !important;
    line-height: 12px;
    padding-bottom:95px;
    margin-top: 1px;
    margin-left: 1px;
	border: 1px solid rgba(209,209,209, 0.3);
    background-color:rgba(255,255,255, 0.9);
    border-spacing: 0px;
    color:#888888;
    font-size: 1.20em;
    font-weight: 500;
    text-align:left;
    vertical-align:text-top !important;
    text-indent: 5px;
    overflow: visible;
}
.business_container_block_description_options_settings {
	z-index: 5;
	height:28px;
	width:28px;
	display:inline-block;
	/**/
	position:absolute;
	vertical-align:top;
	margin-top: 3px;
	margin-left: 427px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;

}
.business_container_block_description_frame{
	height: 120px;
	width: 460px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.0);
	background: url(../images/sys_option_default/default_building.png) rgba(239,239,239, 0.3);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: 2% 45%; 
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
business_edit_pop_td2_1_1_count_div1
*/
/**************************************************/
.business_container_td1_2_td3_td1{
	height: 211px;
	width: 357px !important;
	position:relative;
	margin-top: 0px;
	background-color:rgba(255,255,255, 0.0);
	vertical-align:top;
	text-align:left;
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_td1_2_td3_td1_top{
	height: 117px;
	width: 355px !important;
	margin-top: 0px;
	border: hidden;
	/**/
	background-color:rgba(255,255,255, 0.6);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_td1_2_td3_td1_bottom{
	height: 93px;
	width: 355px !important;
	margin-top: 0px;
	border: hidden;
	/*
	background-color:rgba(255,255,255, 0.6);
	*/
	background-color:rgba(239,239,239, 0.6);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_container_bottom_map{
	height: 91px;
	width: 351px !important;
	margin-top: 1px;
	margin-left: 1px;
	border: hidden;
	background-color:rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/***********************************/
/***********************************/
.business_edit_title_field1_1{
    /*
    business_edit_pop_td2_1_1_count_div1
    event_title_field
    */
    height:75px !important;
    width: 550px;
    line-height:25px !important;
    margin-top:10px;
	margin-left:140px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/sys_option_default/default_building.png) rgba(255,255,255, 0.7);
    background-position:1% 20%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 2.45em;
    resize: none;
    /*
    font-weight: 500;
    */
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    vertical-align:top !important; 
    text-align:left;
    text-decoration:none;
    text-indent:10px;
    word-wrap: break-word !important;
}
/***********************************/
/***********************************/
.business_edit_pop_td2_1 {
    height:598px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_edit_pop_td2_1_1_count_div1 {
    /*
        business_edit_pop_td2_2_1_div2
        business_edit_description_field1_1
        business_edit_pop_td2_1_1_count_div1
    */
    height:35px !important;
    width:45px !important;
    display: inline-block;

    margin-top: 5;
    margin-left: 640px;
    position: absolute;

    border-spacing: 0px;
    border: hidden;

    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_edit_pop_td2_1_1_count_div2 {
    height:35px !important;
    width:45px !important;
    display: inline-block;

    margin-top: 10;
    margin-left: 545px;
    position: absolute;

    border-spacing: 0px;
    border: hidden;

    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_edit_pop_td2_1_1_count_div3 {
    height:35px !important;
    width:45px !important;
    display: inline-block;

    margin-top: 5;
    margin-left: 545px;
    position: absolute;

    border-spacing: 0px;
    border: hidden;

    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_edit_pop_td2_1_top1{
    height:30px !important;
    width:600px !important;
    display:inline-block;
    position:absolute;
    margin-top: 10px;
    margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.business_edit_pop_td2_1_top2{
    height:30px !important;
    width:600px !important;
    display:inline-block;
    position:absolute;
    margin-top: 5px;
    margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.business_edit_pop_td2_1_top3{
    height:30px !important;
    width:600px !important;
    display:inline-block;
    position:absolute;
    margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
/**************************************//**************************************/
/**************************************//**************************************/
.business_edit_pop_td2_1_TT{
    /*
    
    business_edit_title_field1_1
    business_edit_pop_td2_2_ST
    */
    height:0px !important;
    width:706px !important;
    display:inline-block;
    margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
/**************************************/
/**************************************/
.business_edit_pop_td2_1_ST{
    height:182px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.business_edit_pop_td2_1_ST_top_td{
    height:90px !important;
    width:703px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.business_edit_pop_td2_1_ST_bottom_td{
    height:92px !important;
    width:703px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.business_edit_pop_td2_1_ST_bottom_td_1{
    height:90px !important;
    width:150px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top !important;
	text-align:left;
}
.business_edit_pop_td2_1_ST_bottom_td_2{
    height:90px !important;
    width:550px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top  !important;
	text-align:left;
}
.business_display_qr_code{
    height:120px !important;
    width:120px !important;
    display:inline-block;
    position: absolute;
    margin-top: -70px;
	margin-left: 20px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,255,100, 0.8);
    background-size: 120px 120px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.business_display_bs_logo{
    height:90px !important;
    width:90px !important;
    display:inline-block;
    position: absolute;
    margin-top: 0px;
	margin-left: 100px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,100,255, 0.8);
    background-size: 90px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.business_display_bs_description{
    height:90px !important;
    width:360px !important;
    display:inline-block;
    position: absolute;
    margin-top: 0px;
	margin-left: 190px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(100,255,255, 0.8);
    background-size: 360px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.business_description_edit_count_div{
    z-index:10;
	height: 33px;
	width: 50px !important;
	position:absolute;
	margin-top:10px;
	margin-left:610px;
    border: hidden;
	background-color:rgba(255,255,255, 0.4);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_description_edit_div{
    z-index:5;
	height: 160px;
	width: 640px !important;
	position:absolute;
	margin-top:20px;
	margin-left:0px;
    border: hidden;
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/*
business_edit_pop_td2_3_ST_td_1
*/
.business_edit_description_field1_1{
    height:160px !important;
    width: 642px;
    line-height:25px !important;
    margin-top:0px;
	margin-left:0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 2.00em;
    font-weight: 600;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
/**************************************/
/**************************************/
.business_edit_pop_td2_2_ST{
    /*
    business_edit_pop_td2_2_1
    */
    height:232px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.business_edit_pop_td2_2_ST_td_stats_1{
    height:40px !important;
    width:233px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.business_edit_pop_td2_2_ST_td_stats_2{
    height:40px !important;
    width:233px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.business_edit_pop_td2_2_ST_td_stats_3{
    height:40px !important;
    width:233px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}

.business_edit_pop_td2_2_ST_td_1{
    height:190px !important;
    width:30px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.business_edit_pop_td2_2_ST_td_2{
    height:190px !important;
    width:642px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.business_edit_pop_td2_2_ST_td_3{
    height:190px !important;
    width:30px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
/**************************************/
/**************************************/
.business_edit_pop_td2_3_ST{
    height:140px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_edit_pop_td2_3_ST_td_1{
    height:138px !important;
    width:430px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_edit_pop_td2_3_ST_td_2{
    /*
    business_display_rating
    
    */
    height:138px !important;
    width:136px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_edit_pop_td2_3_ST_td_3{
    height:138px !important;
    width:138px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.1);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.business_display_rating{
    height:132px !important;
    width:132px !important;
    display:inline-block;
    position: absolute;
    margin-top: 3px;
	margin-left: 2px;
    border-radius: 100px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    background-size: 132px 132px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.business_messagedisplaydatalabel_field_disabled{
    z-index: 4;
    height:132px !important;
    width:132px !important;
    position: absolute;
    margin-top: 3px;
	margin-left: 2px;
    background-color:transparent;
    border-spacing: 0px;
    border:hidden;
	color:#000;
    font-size: 1.8em !important;
    font-weight: 700 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}

.business_display_map{
    height:138px !important;
    width:430px !important;
    display:inline-block;
    position: absolute;
    margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.7);
    background-size: 415px 138px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.business_display_guide{
    height:138px !important;
    width:138px !important;
    display:inline-block;
    position: absolute;
    margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-size: 116px 97px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
/**************************************//**************************************/
/**************************************//**************************************/
.business_edit_pop_td2_1_TB{
    height:122px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
/**************************************/
/**************************************/
.business_edit_pop_td2_2_TB{
    /*
        business_edit_description_field2_2
    */
    height:342px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
/**************************************//**************************************/
/**************************************//**************************************/
.business_edit_pop_td2_1_B{
    /*
        business_edit_description_field3_1
    */
    height:464px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}


/**************************************/
/**************************************/
.business_edit_pop_td2_2 {
    height:598px !important;
    width:239px !important;
    display:inline-block;
    margin-top: 0px;
	margin-left: 2px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(239,239,239, 0.0);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.business_edit_pop_td2_2_1 {
    height:70px !important;
    width:239px !important;
    border-spacing: 0px;
    border:hidden;
    display:inline-block;
    background-color:rgba(239,239,100, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.business_edit_pop_td2_2_2 {
    height:520px !important;
    width:238px !important;
    border-spacing: 0px;
    border:hidden;
    display:inline-block;

    vertical-align:top !important;
    text-align:left !important;

    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_edit_pop_td2_3_div_button1 {
    height:42px !important;
    width:130px !important;
    display:inline-block;
    margin-top: 0;
    margin-left: 580;
    position: absolute;
    vertical-align:middle !important;
    text-align:center !important;
    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.business_edit_pop_td2_3_div_button2 {
    height:42px !important;
    width:130px !important;
    line-height: 42px;
    display:inline-block;
    margin-top: 0;
    margin-left: 130;
    position: absolute;

    vertical-align:middle;
    text-align:center !important;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*--------*//*--------*/
.business_edit_pop_td3 {
    height:120px !important;
    width:951px !important;
    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;
    display:inline-block;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

/***********************************/


/***********************************/


/***********************************/


/***********************************/




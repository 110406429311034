/*   -------------------------------------------------- */
/*   -------------------------------------------------- */
/*   ------------    main_system_frame ---------------- */
/*   
/*
1) RGB(11,183,255);

2) RGB(232, 232, 232)

3) RGB(255, 68, 255)

4) RGB(255, 255, 255)
/*
/*  
/*   
/*
/*  ********* HEADER_MENU_PANEL *********    window_load_cover.jpg 
/*
/*  
/*  
/*  	
/*
/*  
/* 
/*
/*
/*		
/*  
/*
/*
/*  
/*  	
/*
/*  
/* 
/*
/*
/*		
/*  
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 .menu_logout_button
CONTRUCTION
    BORDERS
        TABLE OUTSIDE   border: 1px solid rgba(209,209,209, 0.7);
        TD OUTSIDE      border: 1px solid rgba(204,204,204, 0.7);

        TABLE INSIDE    border: 1px solid rgba(204,204,204, 0.0);
        TD INSIDE       border: 1px solid rgba(204,204,204, 0.2);

        BUTTON          border: 1px solid rgba(204,204,204, 0.3);
		
    BACKGROUND
        MAIN            background-color: rgba(255,255,255, 0.7);
        PAGE            background-color: rgba(255,255,255, 0.2);
        FORM PAGE       background-color: rgba(239,239,239, 0.7);
        MENU            background-color: rgba(239,239,239, 0.7);
        BUTTON          background-color: rgba(204,204,204, 0.7);
		
    rgba(255,255,255, 0.7) = #FFF (white)
    rgba(0,0,0, 0.7) =  (black)
    rgba(239,239,239, 0.7) = #EFEFEF (grey)
    rgba(204,204,204, 0.7) = #CCC (dark border)
    rgba(0,255,0, 0.7)  =(green)
    rgba(255,255,0, 0.7) =(yellow)
    rgba(255,0,0, 0.7)  =(red)
    rgba(15,10,222,0.7)  =(blue)


    rgba(15, 5, 26, 0.7) =  (black scheme)
    rgba(10, 245, 92, 0.7)  =(green scheme)
    rgba(4, 144, 242, 0.7)  =(blue scheme)
    rgba(255, 28, 255, 0.7)  =(pink scheme)
    rgba(204, 204, 204, 0.7)  =(grey scheme)
    rgba(252, 71, 71, 0.7)  =(red scheme)
    rgba(255, 255, 128, 0.7) =(yellow scheme)
    204,204,204
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */
/************************//*sprite_STANDARD HEIGHT 30

ROW 1 - ITEM 1 || -1px X -1px       || CLEAR                | clear_img.png
ROW 1 - ITEM 2 || -1px X -145px     || BUTTON TEMPLATE      | butt_template.png
ROW 1 - ITEM 3 || -1px X -285px     || LOADING              | loader.gif
ROW 1 - ITEM 4 || -1px X -435px     || SETTINGS             | sys_settings_button_large.png - sys_settings_option.png - results_frame_absolute.png
ROW 1 - ITEM 5 || -1px X -580px     || RECYCLE ACTIVE       |
ROW 1 - ITEM 6 || -1px X -725px     || RECYCLE DEFAULT      |



*/
/************************//*	BUTTON TEMPLATE	
.CUL_button_1, .CUL_button_02, .CUL_button_2, .sys_search_bar_field_default{
    background-image: url('../images/buttons/sprite_STANDARD_HEIGHT30.png');
    background-repeat: no-repeat;
    background-size: 1603px 190px, cover;
}*/
/*********************************************/
/*********************************************/
/* -----------------------------------------------------------------------------------------/   */

body {
	height:730px;
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    font-family: Arial, Helvetica, sans-serif;
    /*
    font-family: "Times New Roman", Times, serif;
    */
    /*overflow-y: hidden;  Hide vertical scrollbar */
    overflow-x: hidden;/* Hide horizontal scrollbar */
    
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar { 
    display: none; 
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
    /*
    loader_active
    stop-scrolling
    
    background:url(../images/loader/ThePeoplesForm-Logo04-GIF.gif);
    background-size: 300px 300px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
    */
}
/*********************************************/
/*********************************************/
.any_visuallyhidden {
    display:none;
    visibility:hidden;
    margin:0;
    padding:0;
    text-align:left;
}

.default{background-color:#FFF;}/* DEFAULT  */
.weak{
    width: 160px !important;
    background-color:#F00; 
    color:#FFF;
    font-weight:700;
}/* WEAK  */
.strong{
    width: 160px !important;
    background-color:#09F;
    color:#FFF;
    font-weight:700;
}/* AVERAGE  */
.stronger{
    width: 160px !important;
    background-color:#0C0; 
    color:#FFF;
    font-weight:700;
}/* STRONGER  */
.strongest{
    width: 160px !important;
    background-color:#FC0;
    color:#FFF;
    font-weight:800;
}/* SECURE  */
/* ################################# */
h1 { 
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    /*  32px  */
}

h2 {

    font-weight: bold;
    text-decoration:none;
    /*  24px  font-size: 1.5em;*/
}

h3 { 

    font-weight: bold;
    text-decoration:none;
    /*  18px  font-size: 1.17em;*/
}

h4 { 
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

h5 { 
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    /*  13px  */
}

h6 { 
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    /*  10px  */
}
.ui-checkbox {
        display:none;
        visibility:hidden;
}
.ui-radio {
        display:none;
        visibility:hidden;
}

.ui-overlay-a, .ui-page-theme-a, .ui-page-theme-a .ui-panel-wrapper{

}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    font-weight: normal;
    color: #555 !important;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

.ui-input-text, .ui-input-search {
  margin: 0;
  padding:0;
  border-width: 1px;
  border-style: solid;
}
.ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset {
  margin: 0;
  padding:0;
  border-width: 1px;
  border-style: solid;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: rgba(4, 144, 242,0.7) !important;
    background-image: none;
    color: rgb(0, 0, 0);
}
.brightRed {
    color: rgba(252, 71, 71, 0.7);
    width:5px !important;
    display:inline;
}


.form_error_border {
    border-width:thin;
    border-style:solid;
    border-color:rgba(252, 71, 71, 0.7);
}
.window_load_action {
    z-index: 999999;
    background: rgba(255,255,255, 0.0);
    border:hidden;
    border-style: none !important;
    height:100% !important;
    width:100% !important;
    position: fixed;
    display:none;
}	
.window_load_cover {
    z-index: 999999;
    border:hidden;
    border-style: none !important;
    height:100% !important;
    width:100% !important;
    position:fixed;
    display:block;
    filter: Alpha(Opacity=99);
    opacity: .99;
    /*
    background: rgba(255,45,255, 0.4);
    */
}
.loader_active {
    position:absolute;
    z-index:99999;
    height:300px !important;
    width:300px !important;
    /**/
    margin-left:34%;
    margin-top:22%;
    
    border:hidden;
    border-style: none !important;
    /*
    background-image:url(../images/bkgrounds/loader.gif) ;
    */
    background:url(../images/loader/ThePeoplesForm-Logo04-GIF.gif);
    background-size: 300px 300px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
}

.pop_loader_active {
    position:absolute;
    z-index:150;
    height:620px !important;
    width:952px !important;
    line-height:620px;
    text-align:center;
    vertical-align:middle;
    background-color:transparent;
    border:hidden;
    border-style: none !important;
    /*
    background-image:url(../images/bkgrounds/loader.gif) ;
    */
    background:url(../images/loader/ThePeoplesForm-Logo04-GIF.gif) ;
    background-position:center;
    background-size: 300px 300px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
}
.loader_default {
    visibility:hidden;
    display:none;
}
.progress_loader_default {
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #ddd;
    visibility:hidden;
    display:none;
}
.progress_loader_active {
    /*
    background-color: #ddd;
    */
    margin-left:25%;
    margin-top:25%;
    position: absolute;
    z-index:150;
    height:30px !important;
    width:500px !important;
    line-height:30px;
    text-align:center;
    vertical-align:middle;

    background-color:#9CF;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
}
.progress_loader_text {
    z-index:150;
    position:absolute;
    width: 500px;
    height: 30px;
    background-color:transparent;
   -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
}
.progress_loader_bar {
    position:relative;
    /**/
    width: 1%;
    height: 30px;
    background-color: #4CAF50;
   -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
}
.label {
    height:25px ;
    line-height:25px ;
    font-size: .90em;
    font-weight: 600;
    text-decoration:none;
    background:#FFF;
}
/*********************************************/
/*********************************************/
/************************//************************//************************/
/************************//*	WINDOW_OVERLAY	*/
/********//*	WINDOW_OVERLAY_MAIN	*/
#window_overlay{
    z-index: 900;
    display: none;
    position:fixed;
    left: 0px;
    background-color: rgba(255,255,255, 0.9);
    width: 100%;
    height: 100%;

}
.photo_overlay{
    z-index: 900;
    display: none;
    position:fixed;
    left: 0px;
    background-color: rgba(255,255,255, 0.7);
    width: 100%;
    height: 100%;
}

#window_view{
    z-index: 2002;	
    height:100%; 
    width:955px;
    margin-top: 48px;		
    position: absolute;
    border:thin #000;
    border-radius:7px; 
    display: none;
    background-color: rgba(255,255,255, 0.7);
}
.window_view_UI_control_class{
    z-index: 10;
    border:hidden;
    background-color:transparent;
    cursor:pointer;
}
.window_view_UI_main_control_class{
    z-index: 10;
    background-color:transparent;
    position:relative;
}
.notification_window_close{
    right:0;
    position:absolute;
    z-index: 10;
	background-color:transparent;
}
.window_view_close_button{
    height:50px;
    width:50px;
    background: url(../images/buttons/sys_close_buttonDefault.png) rgba(255,255,255, 0.3);
    background-position: center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    filter: Alpha(Opacity=99);
    opacity: .99;
    */
}
/*********************************************/
/*********************************************/
/************************//************************//************************/
.full_MGMT_query_metrics {
	z-index: 100;
    height:12px !important;
    width:180px;
    display:inline-block;
    line-height:12px;
    position:absolute;
    left:760px;
    top:635px;
	vertical-align: middle;
    text-align:center;
    font-size: 1em;
	font-weight: 700;
    color:rgba(203,203,203, 0.9);
    text-decoration:none;
    background:rgba(255,255,255, 0.9);
    border:1px solid rgba(239,239,239, 0.7);
}
.feed_MGMT_query_metrics {
	z-index: 100;
    height:12px !important;
    width:180px;
    display:inline-block;
    line-height:12px;
    position:absolute;
    left:110px;
    top:635px;
	vertical-align: middle;
    text-align:center;
    font-size: 1em;
	font-weight: 700;
    color:rgba(203,203,203, 0.9);
    text-decoration:none;
    background:rgba(255,255,255, 0.9);
    border:1px solid rgba(239,239,239, 0.7);
}
/*********************************************/
/*****************************/
/* COOKIE */
#cookie-consent {
    display: none;

    width:100%;
    height: 100%;

    position:fixed;
    bottom:0px;

    background-color: rgba(15, 5, 26,0.8);

    z-index: 100000;
}

.cookie-consent-inner {
    width:100%;

    padding: 20px;

    position:fixed;
    bottom:0px;

    background-color: rgba(0,74,119,1);
    color: rgba(255,209,0,1);
}

.cookie {
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;

    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.cookie-msg {
    width: calc(100% - 120px);
    margin-right: 20px;
    float: left;
}
.cookie-accept {
    width: 100px;
    float: right;
}
.cookie-button {
    font-size: 16px;
    line-height: 40px;
    padding: 0px;
    color: #FFD100;
    width: 100px;
    background-color: #337ab7;
    cursor: pointer;
    border: 1px solid white!important;
    -o-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
    -ms-transition: all 0.4s linear;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
    text-align: center;
}
.cookie-button:hover {
    background-color: #0072AE;
}
/* END COOKIE */
/*****************************/
/*****************************/
.crop_image {
  	
  	/* This rule is very important, please don't ignore this */
  	max-width: 100%;
	
}
/* Thumbnail */
.thumbnail{
    width: 80px;
    height: 80px;
    padding: 2px;
    border: 2px solid lightgray;
    border-radius: 3px;
    float: left;
}
/*********************************************/
/************************//************************//************************/
.QR_CODE_DIV_BUSINESSS {
	z-index: 1 !important;
    height: 102px !important;
    width: 102px !important;
	padding: 0px  !important;
	position: absolute;
	margin-top: 0px;
	margin-left: -80px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);
	background-size: 138px 138px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.QR_CODE_DIV_BUSINESSS_BASIC {
	z-index: 1 !important;
    height: 102px !important;
    width: 102px !important;
	padding: 0px  !important;
	position: absolute;
	margin-top: -7px;
	margin-left: -80px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);
	background-size: 138px 138px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.QR_CODE_DIV_BUSINESSS_SLICE {
	z-index: 1 !important;
    height: 102px !important;
    width: 102px !important;
	padding: 0px  !important;
	position: absolute;
	margin-top: -7px;
	margin-left: -80px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);
	background-size: 138px 138px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.QR_CODE_DIV_BUSINESSS_SHOWROOM {
	z-index: 1 !important;
    height: 102px !important;
    width: 102px !important;
	padding: 0px  !important;
	position: absolute;
	margin-top: -7px;
	margin-left: -80px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);
	background-size: 138px 138px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.QR_CODE_DIV_EVENT {
	z-index: 1000 !important;
    height: 102px !important;
    width: 102px !important;
	/*
	line-height: 0px !important;
	*/
	padding: 0px  !important;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align: top;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.0);
	background-size: 138px 138px;
   	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.QR_CODE_DIV_EVENT_ITEM {
	z-index: 1000 !important;
    height: 102px !important;
    width: 102px !important;
	/*
	line-height: 0px !important;
	display: inline-block;
	*/
	padding: 0px  !important;
	position: absolute;
	margin-top: -28px;
	margin-left: 0px;
	vertical-align: top;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.0);
	background-size: 138px 138px;
   	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}




















/*********************************************/
/*********************************************/
.site_system_notification_thepeoplesform_logo{
    /*
	
    */
    height:100% !important;
	table-layout: fixed;
    width:100%;
    
    margin-top: 0px;
    margin-left: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background: url(../images/logo/THEPEOPLESFORM/ThePeoplesForm-Logo-FINAL-200PX.png) rgba(255,255,255, 0.0);
    background-size: 150px 150px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 1.40em;
    font-weight: 600;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:middle;
	text-align:center;
}
/*********************************************/
/*********************************************/
/* ################################# */
.defaultBodyTable {
    height:630px !important;
    width:743px !important;
    position: relative;
    background-color:rgba(255,255,255, 0.0);
    border:0; 
    align:left;
    padding:0;
    margin-top: 51px;
}
.defaultbody_table {
    height:624px !important;
    width:715px !important;
    top:0;
    background-color:rgba(255,255,255, 0.0);
    margin-top: 2px;
    padding:0;	
}
.defaultbody_table_full {
    height:600px !important;
    width:715px !important;
    margin:0;
    padding:0;		
}
.defaultbody_table_td {
    height:624px !important;
    width:715px !important;
    text-align:left !important;
    vertical-align:top !important;
    background-color:rgba(255,255,255, 0.5);
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
    margin:0;
    padding:0;	
}
.defaultFooter_table {
    height:30px !important;
    width:720px !important;
    top:0;
    background-color:rgba(255,255,255, 0.0);
    margin:0;
    padding:0;	
    border-bottom: 1px solid #CCC;
    border-top: 1px solid #CCC;
    border-left: 1px solid #CCC;
    border-right: 1px solid #CCC;
}
.defaultfunctional_table {
    height:627px !important;
    width:720px !important;
    background-color:rgba(255,255,255, 0.5);
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-spacing: 0px;
} 
.defaultfunctional_table_td {
    vertical-align: top;
} 

.defaultFooter_table_td {
    height:28px !important;
    width:715px !important;
    line-height: 28px;
    top:0;
    background-color:rgba(255,255,255, 0.0);
    margin:0;
    padding:0;
    border: hidden;
}
.defaultFooter_table_td1 {
    height:28px;
    width:112px;
    line-height: 28px;
    background-color:rgba(255,255,255, 0.4);
    border-top: hidden;
	border-buttom: hidden;
	border-left: 1px solid rgba(204,204,204, 0.2);
	border-right: 1px solid rgba(204,204,204, 0.2);
    padding: 0px;
	vertical-align: top;
	text-align: center;
}
.defaultFooter_table_td2 {
    height:28px;
    width:605px;
    line-height: 28px;
    background-color:rgba(255,255,255, 0.4);
    border-top: hidden;
	border-buttom: hidden;
	border-left: 1px solid rgba(204,204,204, 0.2);
	border-right: hidden;
    padding: 0px;
	vertical-align: top;
	text-align:left !important;
}
.defaultFooter_table_td3 {
    height:28px;
    width:10px;
    line-height: 28px;
    background-color:rgba(255,255,47, 0.4);
    border-top: hidden;
	border-buttom: hidden;
	border-left: hidden;
	border-right: 1px solid rgba(204,204,204, 0.2);
    padding: 0px;
	vertical-align: top;
	text-align: center;
}
/*********************************************/
/*********************************************/
.site_footer_table {
    height:30px;
    width:720px;
    line-height:30px;
    background-color:#FFF; 
}	

.site_footer_table_td1 {
    height:30px;
    width:110px;
    border-left:1px solid #cccccc;
    border-right:1px solid #cccccc;
    padding: 0px;

}
.site_footer_table_td2 {
    height:30px;
    width:384px;
    padding: 0px; 

}	
.site_footer_table_td2_filler{
    width:384px;
} 	
.site_footer_table_td3 {
    height:30px;
    width:110px;
    border-left:1px solid #cccccc;
    padding: 0px;

}	 
.site_footer_table_td4 {
    height:30px;
    width:110px;
    border-left:1px solid #cccccc;
    /*border-right:1px solid #cccccc;*/
    padding: 0px;

}
/* ########################################## */	
.site_footer_table_full {
    height:30px !important;
    line-height: 30px !important;
    width:955px;

}
.site_footer_table_full_main {
    height:30px !important;
    line-height: 30px !important;
    width:955px;
}
.site_footer_member_feedback_button {
    height:30px !important;
    width:150px;
    background-color:transparent;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.site_footer_links{
    height:20px !important;
    width:110px;

    text-align:center;
    text-decoration:none;
    border:hidden;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.site_footer_links_lite{
    height:20px !important;
    width:110px;

    text-align:center;
    text-decoration:none;
    border:hidden;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.site_feedback_footer_button{
    /*
    .profile_header_table
    */
    height:30px !important;
    width:110px;
	line-height: 15px;
    text-decoration:none;
    border:hidden;
	display: inline-block;
    /*
    
	background-image:url(../images/buttons/clear_img.png) rgba(4, 144, 242, 0.7)!important;
    background-size: 110px 30px;
    background-repeat: no-repeat;
    background-position:center;
    */
    background-color:rgba(255, 255, 255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: .90em;
    font-weight: 500;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:top;
	text-align:center;
}
.site_copyright_footer_button{
    height:30px !important;
    width:300px;
	line-height: 15px;
    position:absolute;
	margin-left: 245px;
    text-decoration:none;
    border:hidden;
	background-color: rgba(255,255,255, 0.3); 
	display: inline-block;
	vertical-align:top;
	text-align:left;
	font-size: .70em;
    font-weight: 500;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
}

.site_open_footer_table_full_main {
    /*
    height:76px;
    */
    height:100px;
    width: 957px !important;
    line-height: 20px !important;
}
.site_open_footer_table_full_phrase {
    /*
    height:76px;
    */
    height:25px;
    width: 957px !important;
    line-height: 25px !important;
    background-color:rgba(255,255,255, 0.0);
	border: 1px solid rgba(255,255,255, 0.4);
    vertical-align:middle;
	text-align:center;
	font-size: .70em;
    font-weight: 600;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    color: rgba(0, 0, 0, 0.9);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
}
.site_open_footer_table_full_td {
   	height:76px;
	width:239px;
	line-height: 20px;
    display:inline-block;
	background-color:rgba(255,255,255, 0.0);
	border: 1px solid rgba(255,255,255, 0.4);
	padding: 0px;
	vertical-align: top;
	text-align: left;
    text-decoration:none;
}
.site_open_footer_table_full_td2 {
   	height:76px;
	width:185px;
	line-height: 20px;
    display:inline-block;
	background-color:rgba(255,255,255, 0.0);
	border: 1px solid rgba(255,255,255, 0.4);
	padding: 0px;
	vertical-align: top;
	text-align: left;
    text-decoration:none;
}
.site_open_footer_table_full_td3 {
   	height:76px;
	width:290px;
	line-height: 20px;
    display:inline-block;
	background-color:rgba(255,255,255, 0.0);
	border: 1px solid rgba(255,255,255, 0.4);
	padding: 0px;
	vertical-align: top;
	text-align: left;
    text-decoration:none;
}
.site_open_footer_links{
    height:23px !important;
    width:75px;
    
    margin-top: 2px;
    margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.1);
    background-color:transparent;
    vertical-align: middle;
    text-align:center;
    text-decoration:none;
    
    font-size: .65em;
    font-weight: 600;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    /*
    color: rgba(15, 5, 26, 0.9);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
	color: #fff;
}
.site_open_footer_thepeoplesform_logo{
    /*
    ls_system_error_button
    dashboard_menu_right
    */
    height:74px !important;
    width:74px;
    
    margin-top: 0px;
    margin-left: 100px;
    border: 1px solid rgba(204,204,204, 0.1);
	background: url(../images/logo/THEPEOPLESFORM/ThePeoplesForm-Logo-FINAL-200PX.png) rgba(255,255,255, 0.0);
    background-size: 74px 74px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.site_open_footer_thepeoplesform{
    height:74px !important;
    width:190px;
    
    margin-top: 0px;
    margin-left: 0px;
    border: 1px solid rgba(204,204,204, 0.1);
	background: url(../images/logo/THEPEOPLESFORM/THEPEOPLESFORM-LETTERMARK-FRAME-BLUE.png) rgba(255,255,255, 0.0);
    background-size: 180px 73px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.site_open_footer_madewiththeapplicationoflove{
    height:74px !important;
    width:85px;
    
    margin-top: 0px;
    margin-left: 0px;
    border: 1px solid rgba(204,204,204, 0.1);
	background: url(../images/logo/MADEWITHTHEAPPLICATIONOFLOVE/MADEWITH-LOGO-01-BLUE.png) rgba(255,255,255, 0.0);
    background-size: 73px 73px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.site_footer_table_full_td1 {
    /*
    site_footer_table_full
    site_footer_table_full_main
    */
   	height:28px;
	width:112px;
	line-height: 28px;
	background-color:rgba(255,255,255, 0.4);
	border-top: hidden;
	border-buttom: hidden;
	border-left: 1px solid rgba(204,204,204, 0.2);
	border-right: hidden;
	padding: 0px;
	vertical-align: top;
	text-align: center;
}
.site_footer_table_full_td2 {
    height:30px !important;
    width:840px !important;
    padding: 0px;
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:left !important;
	border-top: hidden;
	border-buttom: hidden;
	border-left: hidden;
	border-right: 1px solid rgba(204,204,204, 0.2);
}	
.site_footer_table_td2_filler_full{
    width:625px;
} 	   	 
.site_footer_table_full_td3 {
    height:30px !important;
    width:110px; 
    padding: 0px;
	background-color: rgba(255,255,47, 0.3);
    border-top: hidden;
	border-buttom: hidden;
	border-left: 1px solid rgba(204,204,204, 0.2);
	border-right: 1px solid rgba(204,204,204, 0.2);
}	 
.site_footer_table_full_td4 {
    height:30px !important;
    width:110px;
    /* border-right:1px solid #cccccc; */
    padding: 0px;
    border-top: hidden;
	border-buttom: hidden;
	border-left: 1px solid rgba(204,204,204, 0.2);
	border-right: 1px solid rgba(204,204,204, 0.2);
}
.frameBodyTable {
    height:610px !important;
    width:743px !important;
    position: fixed;
    background-color:rgba(255,255,255, 0.0);
    border:0; 
    align:left;
    padding:0;
    margin-top: 51px;
}

.framebody_table {
    height:608px !important;
    width:720px !important;
    top:0;
    background-color:rgba(255,255,255, 0.0);
    margin-top: 2px;
    padding:0;	
}
.framebody_table_td {
    height:608px !important;
    width:720px !important;
    text-align:left !important;
    vertical-align:top !important;
    background-color:rgba(255,255,255, 0.0);
    margin:0;
    padding:0;	
}
/* ################################# */
.mainBodyTable {
    height:630px !important;
    width:955px !important;
    position: relative;
    /*
    position: absolute;
    */
	display: block;
    background-color:rgba(255,255,255, 0.0);
    border:0; 
    align:left;
    padding:0;
    margin-top: 51px;
	margin-left: 0px  !important;
    /*
    loader_active
    stop-scrolling
    
    background:url(../images/loader/ThePeoplesForm-Logo04-GIF.gif);
    background-size: 300px 300px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
    */

}
.mainBodyTable_td{
    height:630px !important;
    width:955px !important;
    vertical-align:top !important;
	background-color:rgba(255,255,255, 0.0);
    /*
    position: absolute;
    */
}
.container {
    /*
    
    */
    height:791px;
    width: 955px;
    position:relative;
    background-color:rgba(255,255,255, 0.0);
    border: 1px solid rgba(209,209,209, 0.4);
    padding:0;
    margin: auto;
    
}
.header_container {
    height:50px;
    width: 955px !important;
    line-height:50px ;
    display: inline-block;
    position:absolute;
    margin-left: -480px;
    top: 0px;
    background-color:rgba(239,239,239, 0.0);
    border: 1px solid rgba(209,209,209, 0.7);
    padding:0px;
}
.foot_container {
    height:80px;
    width: 955px !important;
    line-height:20px ;
    display: inline-block;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(204,204,204, 0.4);
    border: 1px solid rgba(209,209,209, 0.0);
    padding:0px;
}
.mainBodyTable_regleft{
    height:625px !important;
    width:654px;
    display: inline-block;
    background-color:rgba(255,255,255, 0.0);
    vertical-align: top;
    border: 1px solid rgba(204,204,204, 0.2);
    padding:0;
    margin:0;
}
.mainBodyTable_regright{
    height:625px !important;
    width:295px;
    display: inline-block;
    background-color:rgba(255,255,255, 0.0);
    border: 1px solid rgba(209,209,209, 0.7);
    padding:0;
    margin:0;
}
.mainBodyTable_regleft_base {
    height:625px !important;
    width:654px;
    position: absolute;
    border:hidden;
    border-spacing: 0px;
} 
.mainBodyTable_regleft_base_td {
    vertical-align: text-top;
}

.mainBodyTable_regright_base{
    height:623px !important;
    width:293px;
    display: inline-block;
    background-color:rgba(239,239,239, 0.4);
    border: 1px solid rgba(239,239,239, 0.0);
    padding:0;
    margin:0;
}

.mainBodyTable_identityleft{
    height:625px !important;
    width:650px;
    display: inline-block;
    position:absolute;
    margin-top: 0px;
    margin-left: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(255,255,255, 0.0);
    vertical-align: top;
}
.mainBodyTable_identityleft_base {
    height:625px !important;
    width:650px;
    position: absolute;
    border:hidden;
    border-spacing: 0px;
} 
.mainBodyTable_identityright{
    height:625px !important;
    width:300px;
    display: inline-block;
    position:absolute;
    margin-top: 0px;
    margin-left: 653px;
    border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(255,255,255, 0.0);
    vertical-align: top;
}
/* ################################# */
/* ################################# 
.index_body_table{
    height:630px;
    width:726px;
    border:0; 
    padding:0;
    margin:0;
}
.index_body_table_td{
    height:630px;
    width:930px;
    border:0; 
    padding:0;
    margin:0;
}
.index_body_table_full{
    height:588px;
    width:930px;
    border:0; 
    padding:0;
    margin:0;
}
.index_body_table_full_td{
    height:588px;
    width:958px;
    border:0; 
    padding:0;
    margin:0;
}

*/
	
.profile_header_background {
    z-index: 9999;
	/*
    position: absolute;
	*/
	position:fixed;
    height:52px !important; 
    line-height:52px !important;
    width:100%; 
    border-bottom:#999 1px solid;
    background-repeat:repeat; 
    background-color:rgba(239,239,239, 1); 
    text-align: center !important;
    vertical-align:text-top !important;
}
.profile_header_table {
    /*
    
    sys_search_bar_field_default
    profile_header_active_table_td2
    base_functional_sys_search_tr
    */
    z-index: 999;
    height:45px !important;
    width:960px; 
    line-height: 45px;
    position: absolute;
    background-color:rgba(239,239,239, 0.7);
    background-color: transparent;
    text-align: center !important;
    vertical-align: text-top !important;
    /* THEPEOPLESFORM-01.png - ration: 10.54   
    background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
    background-size: 316.2px 30px;
    */
    /* THEPEOPLESFORM-02.png - ration: 9.069   
    background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
    background-size: 272.07px 30px;
    */
    /* THEPEOPLESFORM-03.png - ration: 9.069   
    background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
    background-size: 272.07px 30px;
    */
    /* THEPEOPLESFORM-01.png - ration: 10.54  */ 
    background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);
    background-size: 316.2px 30px;
    
    background-position:center;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profile_header_table_td1 {
    /*
    
    profile_header_table_td2
    */
    height:45px !important;
    width:103px !important;
    line-height: 45px;
    background-color: transparent;
    text-align: left;
    vertical-align: text-top;
}
.profile_header_menu_button_default{
    height:43px;
    width:100px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color:rgba(239,239,239, 0.0);
    border:hidden;
    margin-left: 3px;
    background-image:url(../images/buttons/clear_img.png);
    background-position:10% 45%; 
    background-size: 90px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profile_header_menu_button{
    height:45px;
    width:100px;

    font-size: 1em;
    font-weight: 600;

    text-align:center;
    text-decoration:none;
    background-color:rgba(239,239,239, 0.0);
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    border-radius: 05px;
    margin-top: 3px;
    margin-left: 3px;
    position: absolute;
    /*
    background-image:url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    */
    background: url(../images/buttons/system_menu.png) rgba(239,239,239, 0.7);
    background-position:center; 
    background-size: 90px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profile_header_table_td2 {
    height:45px !important;
    width:565px !important;
    line-height:45px;
    background-color: transparent;
    vertical-align: top;
}
.profile_header_home_button {
    height:43px;
    width:300px;
    line-height: 43px;
    font-size: 1.05em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:1px solid #CCC;
    margin-top: -27px;
    margin-left: -150px;
    position: absolute;
    padding: 0;
    background-color:rgba(239,239,239, 0.7);
    background-image:url(../images/buttons/clear_img.png);
    background-position:10% 45%; 
    background-size: 290px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profile_header_table_td3 {
    height:45px !important;
    width:290px;
    background-color: transparent;
}
.profile_header_table_td3_button1 {
    height:43px;
    width:100px
}
.profile_header_table_td3_button2 {
    height:43px;
    width:100px;
}
.profile_header_table_td3_button3 {
    height:43px;
    width:100px;
}
/*

*/
.profile_header_active_table_td1 {
    height:45px !important;
    width:103px !important;
    line-height: 45px;
    background-color: transparent;
    text-align: left;
    vertical-align: text-top;
}
.profile_header_active_table_td2 {
    height:45px !important;
    width:619px !important;
    line-height:45px;
    background-color: transparent;
}
.profile_header_active_table_td3 {
    height:45px !important;
    width:239px;
    background-color: transparent;
}

/*  ********* HEADER_MENU_PANEL *********     */
.CUL_login_table {
    height:43px !important;
    width:285px;
    line-height:43px;
    padding:0;
    margin-top: 0px;
    margin-left: 0px;
    position: relative;
    background-color: rgba(239,239,239, 0.7);
}
.CUL_login_table_td1 {
    height:43px;
    width:140px;
    line-height:43px;
    text-align: right;
}
.CUL_login_table_td_empty {
    height:43px;
    width:5px;
    line-height:43px;
    text-align: right;
}
.CUL_login_button_1 {
    height:43px;
    width:100px;
    line-height:43px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:1px solid #CCC;
    background-color:rgba(239,239,239, 0.7);
    background-image:url(../images/buttons/clear_img.png) ;
    background-position:10% 45%; 
    background-size: 85px 43px;
    background-repeat:no-repeat;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.CUL_login_table_td2 {
    height:43px;
    width:140px;
    line-height:43px;
}
.CUL_login_button_2 {
    height:43px;
    width:150px;
    line-height:43px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:1px solid #CCC;
    background-color:rgba(239,239,239, 0.7);
    background-image:url(../images/buttons/clear_img.png);
    background-position:10% 45%; 
    background-size: 150px 43px;
    background-repeat:no-repeat;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profile_access_table_td {
    height:43px !important;
    width:230px;
	line-height:23px !important;
}
.profile_access_table_td1 {
    height:43px;
    width:23px !important;
	vertical-align: top;
	text-align: left;
    padding:0;

	}
.CUL_button_1 {
    height:43px;
    width:43px;
    margin-top: 5px;
    margin-left: 0px;
    padding:0px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(239,239,239, 0.7);
    background-position: 6px -127px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
select_frame_absolute

*/
.CUL_button_02 {
    height:43px;
    width:43px;
    margin-top: 5px;
    margin-left: 1px;
    padding:0px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/system_feed.png) rgba(239,239,239, 0.7);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.profile_access_table_td2 {
    height:43px;
    width:45px !important;
    line-height:23px;
	vertical-align: top;
	text-align: left;
    padding:0;
}
.CUL_button_2 {
    height:43px;
    width:43px;
    margin-top: 5px;
    margin-left: 1px;
    padding:0px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/system_calendar.png) rgba(239,239,239, 0.7);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.CUL_button_2_textarea {
    height: 33px;
    width: 38px ;
    line-height:33px;
    top:7px;
    margin-top: 3px;
    margin-left:5px;
    position:absolute;
    font-size:14px;
    font-weight:600;
    text-align:center;
    background-color: transparent;
}
	
.profile_access_table_td3 {
    height:43px !important;
    width:95px !important;
    line-height:23px;
	vertical-align: top;
	text-align: left;
    padding:0;
	background-color: rgba(239,239,239, 0.0);
}

.CUL_button_3 {
    height:43px;
    width:45px;
    line-height:23px;
    margin-top: 5px;
    margin-left: 1px;
    padding:0px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 28px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.CUL_button_3_textarea {
    height: 33px;
    width: 38px ;
    line-height:33px;
    top:7px;
    margin-top: 3px;
    margin-left:5px;
    position:absolute;
    font-size:14px;
    font-weight:600;
    text-align:center;
    background-color:transparent;
}
	
.profile_access_table_td4 {
    height:43px;
    width:45px !important;
    line-height:23px;
    vertical-align: top;
    text-align: left;
    padding:0;
}

.CUL_button_4 {
    height:43px;
    width:45px;
    line-height:43px;
    margin-top: 5px;
    margin-left: 0px;
    padding:0px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border-left:hidden;
    border-right:1px solid #CCC;
    border-top:1px solid #CCC;
    border-bottom:1px solid #CCC;
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 28px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.CUL_button_4_textarea {
    height: 33px;
    width: 38px ;
    line-height:33px;
    top:7px;
    margin-top: 3px;
    margin-left:5px;
    position:absolute;
    font-size:14px;
    font-weight:600;
    text-align:center;
    background-color:transparent;
}
/* ################################################ */
/* ######        LANDSCAPE_MENU_PANEL         ##### */	
div#menu_panel{

    height:670px;
    width:268px;
    background-color:#EFEFEF;
}
.menu_panel_table{
    z-index: 99999999;
    height:670px !important; 
    width:268px !important;
    text-align:center;
    top: 0px;
    vertical-align: text-top !important;
    position: absolute;
}
.menu_panel_header_td{
    height:80px; 
    width:268px;
}
.menu_panel_header_text{
    height:20px; 
    width:268px;
    font-size:20px;
    font-weight:700;
    text-align:center;
}
.menu_panel_button{
    height:55px; 
    width:268px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color:#EFEFEF; 
    border:1px solid #CCC;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-image:url(../images/buttons/butt_template.png);
    background-position:10% 45%; 
    background-size: 60px 40px;
    background-repeat:no-repeat;
}
.menu_header_button{
    height:48px; 
    width:268px;
    font-size: 1.10em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color:#EFEFEF; 
    border:1px solid #CCC;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-image:url(../images/buttons/clear_img.png);
    background-position:10% 45%; 
    background-size: 260px 46px;
    background-repeat:no-repeat;
}
.menu_settings_button{
    height:55px; 
    width:268px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color:#EFEFEF; 
    border:1px solid #CCC;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-image:url(../images/buttons/sys_settings_menu_button.png);
    background-position:10% 45%; 
    background-size: 60px 40px;
    background-repeat:no-repeat;
}
.menu_account_button{
    height:55px; 
    width:268px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color:#EFEFEF; 
    border:1px solid #CCC;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background-image:url(../images/buttons/sys_account_menu_button.png);
    background-position:10% 45%; 
    background-size: 60px 40px;
    background-repeat:no-repeat;
}
.menu_logout_button{
    height:55px; 
    width:268px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color:#EFEFEF; 
    border:1px solid #CCC;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
    background-image:url(../images/buttons/butt_template.png);
    background-position:10% 45%; 
    background-size: 60px 40px;
    background-repeat:no-repeat;
	*/
}
.menu_panel_image_link_td{
    height:121px !important; 
    width:268px !important;
    vertical-align:middle;
     background-color:rgba(120,120,120, 0.8) !important;
    display:inline-block !important;
}
.menu_panel_pro_pic{
    height:120px !important;
    width:128px !important;
    display:inline-block;
    text-align:center !important;
    vertical-align:middle !important;
    background-position:1% 45%; 
    background-size: 120px 120px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.menu_panel_pro_pin{
    height:120px !important;
    width:128px !important;
    display:inline-block;
    text-align:center !important;
    vertical-align:middle !important;
    background-position:1% 45%; 
    background-size: 120px 120px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.menu_panel_event_btt{
    height:120px;
    width:130px;
    border:1px solid rgba(204,204,204, 0.2);
    display:inline-block;
    /*
        pin.png
    */
    background: url(../images/buttons/sys_pin_default.png) rgba(204,204,204, 0.8);
    background-size: 125px 115px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.menu_panel_profile_btt{
    height:120px;
    width:130px;
    border:1px solid rgba(204,204,204, 0.2);
    display:inline-block;
	background-color: rgba(204,204,204, 0.8);
    background-size: 125px 115px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.menu_panel_small_label{
    height:15px !important; 
    width:268px !important;
	border:1px solid rgba(204,204,204, 0.2);
    display:inline-block;
    background-color: rgba(204,204,204, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.menu_panel_space{
    height:10px !important; 
    width:268px !important;
	border:1px solid rgba(204,204,204, 0.2);
    display:inline-block;
    background-color: rgba(204,204,204, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.menu_panel_small_td{
    height:48px !important; 
    width:268px !important;
	border:1px solid rgba(204,204,204, 0.2);
    display:inline-block;
    background-color: rgba(204,204,204, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************************/
/*********************************************/
.menu_frame_close_button{
    height:50px;
    width:50px;
	margin-left: 50%;
	position: absolute;
    background: url(../images/buttons/sys_close_buttonDefault.png) rgba(255,255,255, 0.3);
    background-position: center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    filter: Alpha(Opacity=99);
    opacity: .99;
    */
}
/*********************************************/
/*********************************************/	
	/* Slide Panel */
  	.cb_slide_panel {
		background:#666;
        border-right: 1px solid #E0E0E0;
        box-shadow: 1px 1px 23px rgba(0, 0, 0, 0.17), -1px -1px 0 rgba(255, 255, 255, 0.85) inset;
        position: fixed;
        z-index: 65000;
        display: none;
     }
    .cb_slide_panel.panel_left, .cb_slide_panel.panel_right {
       	width: 278px;
       	height: 100%;
	}
    .cb_slide_panel.panel_right {
        box-shadow: 1px 1px 23px rgba(0, 0, 0, 0.17), 1px 1px 0 rgba(255, 255, 255, 0.85) inset;
    }
    .cb_slide_panel.panel_top, .cb_slide_panel.panel_bottom {
        height: 278px;
        width: 100%;
    }
    .cb_slide_panel.panel_top {
        box-shadow: 1px 1px 23px rgba(0, 0, 0, 0.17), -1px -1px 0 rgba(255, 255, 255, 0.85) inset;
    }
    .cb_slide_panel.panel_bottom {
        box-shadow: 1px 1px 23px rgba(0, 0, 0, 0.17), 1px 1px 0 rgba(255, 255, 255, 0.85) inset;
    }
    .cb_slide_panel a.close {
        background: url(../images/panel/panel_close_default.png) no-repeat;
        border: 1px solid #E0E0E0;
        height: 40px;
        position: absolute;
        text-indent: -9999em;
        width: 40px;
        display: none;
    }
    .cb_slide_panel.panel_left a.close {
        right: -42px;
        top: 20px;
    }
    .cb_slide_panel.panel_right a.close {
        left: -42px;
        top: 20px;
   	}
    .cb_slide_panel.panel_top a.close {
        bottom: -41px;
        left: 20px;
    }
   .cb_slide_panel.panel_bottom a.close {
        top: -41px;
        left: 20px;
   	}
   .cb_slide_panel a.close:hover {
        background-position: -40px 0;
   	}
        
   .cb_slide_panel .inner {
        padding: 5px 0 0 5px;
        width: 248px;
    }
   .cb_slide_panel.loading .inner {
        min-height: 300px;
		background-color:transparent;
        background: url(../images/bkgrounds/loader.gif);
		background-position:50% 70%; 
		background-size: 80px 80px;
		background-repeat:no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
        }
    .cb_slide_panel h1 {
        color: #FFFFFF;
        margin: -0.15em 0 20px;
        text-shadow: 0 1px 0 #000000;
    }
    .cb_slide_panel h2 {
        border-bottom: 1px solid #276BB3;
        color: #FFFFFF;
        margin: 0;
        padding: 1px 0 0;
       	text-decoration: none;
        text-shadow: 0 1px 0 #1F5287;
	}
  	.cb_slide_panel ul {
        padding: 0 0 0 10px;
        list-style-type: none;
    }
   	.cb_slide_panel ul li {
    }
    .cb_slide_panel ul li a {
        color: #91aac4;
        text-decoration: none;
    }
    .cb_slide_panel ul li a:hover {
        color: #fff;
    }

/*********************************************/
/*********************************************/




/*********************************************/
/*********************************************/
/************************//************************//************************/
	/************************//*	SEARCH_BAR_SETTINGS	*/
	/********//*	SEARCH_BAR_SETTINGS_MAIN	*/

.ls_search_bar_low {
	z-index: 100;
    height: 24px;
    width: 40px;
    line-height:24px;

    position: absolute;
    margin-top: 3px;
    margin-left: 474px;
	border-radius: 25px;
    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(204,204,204, 0.7);
 	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.5);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.div_search_bar_low_safesearch{
	z-index: 90;
    height:38px !important;
    width: 78px;
    line-height:38px !important;
    position: absolute;
    margin-top: 0px;
    margin-left: 1px;
    border-spacing: 0px;
    border:1px solid rgba(203,203,203, 0.2);
    background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.5);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.45em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.ls_search_bar_moderate {
	z-index: 100;
    height: 24px;
    width: 40px;
    line-height:24px;

    position: absolute;
    margin-top: 3px;
    margin-left: 474px;
	border-radius: 25px;
    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(204,204,204, 0.7);
 	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.5);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.div_search_bar_moderate_safesearch{
	z-index: 90;
    height:38px !important;
    width: 78px;
    line-height:38px !important;
    position: absolute;
    margin-top: 0px;
    margin-left: 1px;
    border-spacing: 0px;
    border:1px solid rgba(203,203,203, 0.2);
    background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.5);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.45em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.ls_search_bar_high {
	z-index: 100;
    height: 24px;
    width: 40px;
    line-height:24px;

    position: absolute;
    margin-top: 3px;
    margin-left: 474px;
	border-radius: 25px;
    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(204,204,204, 0.7);
 	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.5);
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.div_search_bar_high_safesearch{
	z-index: 90;
    height:38px !important;
    width: 78px;
    line-height:38px !important;
    position: absolute;
    margin-top: 0px;
    margin-left: 1px;
    border-spacing: 0px;
    border:1px solid rgba(203,203,203, 0.2);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.5);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.45em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.ls_search_bar_clear {
    z-index: 100;
    height: 24px;
    width: 30px;
    line-height:24px;
	border-radius: 25px;
    position: absolute;
	/*
	display: none;
	*/
    margin-top: 3px;
    margin-left: 5px;

    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    background-color:rgba(239,239,239, 0.7); 
    border:1px solid rgba(204,204,204, 0.7);
    background-image:url(../images/buttons/sys_close_buttonDefault.png) rgba(2,255,255, 0.7);
    background-size: 28px 25px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.ls_search_bar_field{
	z-index: 90;
    height:30px !important;
    width: 515px;
   /* width: 99%;
	ls_search_bar_submit
	
	*/
    line-height:25px !important;
	position: absolute;
    margin-top: 0px;
    margin-left: 2px;
    /* ratio .555 */ 
    background: url(../images/buttons/search_glass.png) rgba(255,255,255, 0.5);
    background-size: 13.87px 25px;
    background-repeat: no-repeat;
    background-position:8% 45%;
    border-spacing: 0px;
    border:2px solid rgba(203,203,203, 0.7);
	border-radius: 25px;
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:33px;
}
.sys_search_bar_field_default{
    z-index:90;
    height:40px !important;
    width: 605px;
   /* width: 99%;*/
    line-height:25px !important;
	position: absolute;
    margin-top: 0px;
    margin-left: 2px;
    /* ratio .555 */ 
    background: url(../images/buttons/search_glass.png) transparent;
    background-size: 19.42px 35px;
    background-repeat: no-repeat;
    background-position:1% 45%;
    /*
    background-color: transparent;
    background-position: -850px 0px;
    background-size: 28px 25px;
    */
    border-spacing: 0px;
	border-radius: 05px;
    border: 2px solid rgba(203,203,203, 0.2);
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:33px;
    /*
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    
    -webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.sys_search_bar_roll_active{
    z-index:91;
    border-spacing: 0px;
	border-radius: 5px;
    border: 2px solid rgba(4, 144, 242, 0.7);
    background-color:transparent;
}
.sys_search_bar_field_active{
    z-index:90;
    height:40px !important;
    width: 605px;
   /* width: 99%;*/
    line-height:25px !important;
	position: absolute;
    margin-top: 0px;
    margin-left: 2px;
    
    border-spacing: 0px;
	border-radius: 05px;
    border: 2px solid rgba(4, 144, 242, 0.7);
    background-color:rgba(239,239,239, 1.0);
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:33px;
    
    color: rgba(15, 5, 26, 0.9);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    
    -webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.sys_search_bar_submit {
	z-index: 100;
    height: 28px;
    width: 40px;
    line-height:28px;

    position: absolute;

    margin-top: 10px;
    margin-left: 445px;

    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    background-color:rgba(239,239,239, 0.7); 
    border:1px solid rgba(204,204,204, 0.7);
	border-radius: 25px;
 	background: url(../images/buttons/butt_template.png) rgba(255,255,5, 0.7);
    background-size: 30px 22px;
    background-repeat: no-repeat;
    background-position:5% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.sys_search_bar_clear {
    z-index: 100;
    height: 28px;
    width: 30px;
    line-height:28px;

    position: absolute;
	/*
	display: none;
	ls_search_bar_clear
	*/
    margin-top: 10px;
    margin-left: 5px;

    font-size: 1em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    background-color:rgba(239,239,239, 0.7); 
    border:1px solid rgba(204,204,204, 0.7);
	border-radius: 25px;
    background-image:url(../images/buttons/sys_close_buttonDefault.png) rgba(2,255,255, 0.7);
    background-size: 28px 25px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/********//*	SEARCH_RESULTS_SETTINGS_MAIN	*/

.holiday_frame_absolute {
	height:114px !important;
	width:231px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:7px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_frame_option_2 {
	height:60px !important;
	width:65px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:164px;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    background-size: 60px 55px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 2.25em  !important;
    font-weight: 700;
	text-indent: 0px;
}

.holiday_tag_result_frame_absolute {
	/*
	event_container_data3
	
	*/
    height:45px !important;
    border-spacing: 0px;
    border:hidden;
    display: inline-block;
    margin-top:2px;
    margin-left:2px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

.holiday_tag_result_label{
    height:45px !important;
    line-height:12px;
    margin-top: 0px;
    margin-left: 0px;
	border-spacing: 0px;
	border:3px solid rgba(10, 245, 92, 0.7);
    border-radius: 25px;
    background-color:rgba(239,239,239, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
}

.holiday_tag_frame_absolute {
	height:45px !important;
	width:113px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color: rgba(233,233,233, 0.0);
	display: inline-block;
	margin-top:7px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
event_holiday_result_list_label

.holiday_tag_frame_option_2 {
	height:45px !important;
	width:113px;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border:3px solid rgba(204,204,204, 0.5);
    border-radius: 25px;
	background-color: rgba(239,239,239, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	font-size: 0.75em  !important;
    font-weight: 600;
}
.holiday_tag_frame_option_2_active{
   	height:45px !important;
	width:113px;
    margin-top: 0px;
    margin-left: 0px;
	border-spacing: 0px;
    border:3px solid rgba(10, 245, 92, 0.7);
    border-radius: 25px;
	background-color:rgba(239,239,239, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	color:#888888;
    font-size: 0.75em  !important;
    font-weight: 600;
}
*/
.search_result_frame_absolute {
    height:30px !important;
    /*
    height:140px !important;
    width:234px;
	width:228px;
    */
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
    display: inline-block;
    margin-top:2px;
    margin-left:.5px;
    vertical-align:middle;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}


.search_result_label{
    height:30px !important;
    line-height:20px;
    /*
    position: absolute;
	ss_frame_absolute
    */
    margin-top: 0px;
    margin-left: 1px;
    background-color:rgba(20,100,239, 0.1);
    border:3px solid rgba(255,255,255, 0.9);
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;
}
.search_result_close{
	z-index: 10;
    height:25px !important;
    width:25px !important;
    line-height:25px;
    position: absolute;
	/*
	display: none;
	*/
    margin-top: 1px;
    margin-left: -20px;
    background-color:rgba(239,239,239, 0.3);
    border:1px solid rgba(255,255,255, 0.3);
    border-radius: 25px;

    background-image:url(../images/buttons/sys_close_buttonDefault.png);
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position:center;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;
}
.results_frame_list5_absolute {
	height:145px !important;
	width:183px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.8);
	display: inline-block;
	margin-top:7px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_frame_absolute {
	height:145px !important;
	width:231px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:7px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_frame_l1_absolute {
	height:145px !important;
	width:231px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:3px;
	margin-left:4px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_frame_holiday_list_absolute {
	/*
	ls_default_results_main_row
	settings_container_td
	results_frame_option_sp_listview_1
	*/
	height:120px !important;
	width:138px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(255,255,255, 0.5);
	display: inline-block;
	margin-top:3px;
	margin-left:4px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_frame_option_cb_1 {
	z-index: 1;
    height:143px !important;
    width:229px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:1px;
	margin-left:1px;
    background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.2);
    background-size: 220px 143px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.results_frame_option_sp_1 {
	z-index: 1;
    height:233px !important;
    width:143px;
	position: absolute;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/buttons/clear_img.png);
    background-size: 143px 143px;
    background-repeat: no-repeat;
    background-position:50% 1%;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

.results_frame_option_1 {
	z-index: 1;
    height:143px !important;
    width:143px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:1px;
	margin-left:1px;
    background: url(../images/buttons/clear_img.png);
    background-size: 143px 143px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.results_frame_option_2 {
	height:80px !important;
	width:85px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:145px;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    background-size: 80px 75px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 2.25em  !important;
    font-weight: 700;
	text-indent: 0px;
}
.results_frame_option_3{
	z-index: 10;
	height:25px !important;
	width:220px;
	line-height: 25px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:77px;
	margin-left:5px;
	vertical-align:top;
	text-align:left;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 1.25em  !important;
    font-weight: 500;
	text-indent: 1px;
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_frame_option_sp_4{
	z-index: 10;
	/*
	height:100px !important;
	*/
	width:220px;
	/*
	line-height: 12px;
	*/
	display: inline;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	margin-top:110px;
	margin-left:5px;
	vertical-align: top !important;
	text-align:left !important;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.85em  !important;
    font-weight: 500;
	text-indent: 1px;
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_frame_option_sp_settings_1 {
	/*
	results_frame_holiday_list_absolute
	standard_frame_action_right_sp_positive
	*/
	z-index: 1;
    height:116px !important;
	width:134px;
	position: absolute;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.6);
    background: url(../images/buttons/clear_img.png) rgba(233,233,233, 0.5);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:1% 1%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

.results_frame_option_sp_settings_2 {
	height:50px !important;
	width:55px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:80px;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    background-size: 50px 45px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 1.55em  !important;
    font-weight: 700;
	text-indent: 0px;
}
.results_frame_option_sp_settings_3{
	z-index: 10;
	height:25px !important;
	width:132px;
	line-height: 25px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:47px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.75em  !important;
    font-weight: 500;
	text-indent: 1px;
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_frame_option_sp_settings_4{
	z-index: 10;
	/*
	height:100px !important;
	*/
	width:130px;
	/*
	line-height: 12px;
	*/
	display: inline;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	margin-top:70px;
	margin-left:5px;
	vertical-align: top !important;
	text-align:left !important;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.65em  !important;
    font-weight: 500;
	text-indent: 1px;
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.results_frame_action_right {
	z-index: 10;
	height:45px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:99px;
	margin-left:180px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(5,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_frame_action_left {
	z-index: 10;
	height:45px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:99px;
	margin-left:127px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,8, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.results_item_absolute {
	height:175px !important;
	width:231px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	display: inline-block;
	margin-top:2px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_item_option_1 {
	z-index: 1;
    height:175px !important;
    width:143px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:1px;
	margin-left:1px;
    background: url(../images/buttons/clear_img.png);
    background-size: 143px 143px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.results_item_option_2 {
	height:80px !important;
	width:85px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:145px;
	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    background-size: 80px 75px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_item_option_3{
	z-index: 10;
	height:35px !important;
	width:160px;
	line-height: 35px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:62px;
	margin-left:10px;
	
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	
	color:#888888;
    font-size: 1.25em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.results_item_action_right {
	z-index: 10;
	height:45px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:139px;
	margin-left:180px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(5,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_item_action_left {
	z-index: 10;
	height:45px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:139px;
	margin-left:127px;
	vertical-align:top;
	text-align:left;background-color:rgba(255,255,8, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.results_frame_option_business_cohost1 {
	z-index: 1;
    height:125px !important;
    width:143px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:1px;
	margin-left:1px;
    background: url(../images/buttons/clear_img.png);
    background-size: 122px 122px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

.results_frame_option_business_cohost3{
	z-index: 10;
	height:25px !important;
	width:140px;
	line-height: 25px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:77px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.85em  !important;
    font-weight: 500;
	text-indent: 15px;
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_frame_absolute_special {
	height:235px !important;
	width:231px;
	/*
	position:absolute;
	*/
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:7px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.results_share_frame_absolute {
	height:175px !important;
	width:222.5px;
    display: inline-block;
	margin-top:7px;
	margin-left:2px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.5);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
results_share_frame_absolute
*/
.event_manager_result_frame_absolute {
	height:155px !important;
	width:231px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:7px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*

*/
.event_manager_standard_frame_absolute {
	height:155px !important;
	width:231px;
	border-spacing: 0px;
	position: absolute;
	
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	/**/
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/*

*/

.event_manager_display_active {
	z-index: 0;
	height:155px !important;
	width:20px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 210px;
	background-color: rgba(0,240,0, 0.3);
	/*
	background: url(../images/buttons/butt_template.png) rgba(0,240,0, 0.3);
	background-size: 63px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	*/
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_manager_display_pending {
	z-index: 0;
	height:155px !important;
	width:20px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 210px;
	background-color: rgba(255, 255, 128, 0.3);
	/*
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.3);
	background-size: 55px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	*/
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.event_manager_display_complete {
	z-index: 0;
	height:155px !important;
	width:20px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 210px;
	background-color: rgba(252, 71, 71, 0.3);
	/*
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.3);
	background-size: 55px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	*/
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_manager_attendee_form{
	z-index: 10;
	height:45px !important;
	width:85px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:105px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 1.25em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.event_manager_followers_form{
	z-index: 10;
	height:45px !important;
	width:85px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:105px;
	margin-left:92px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 1.25em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.event_manager_child_count_form{
	z-index: 10;
	height:45px !important;
	width:50px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:105px;
	margin-left:177px;
	border-spacing: 0px;
	border:4px solid rgba(0,100,255, 0.5);
	border-radius: 45px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.5);
    background-size: 80px 45px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 1.25em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.event_manager_edit_form{
	z-index: 10;
	height:40px !important;
	width:40px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	/*border-radius: 45px;*/
	border:1px solid rgba(233,233,233, 0.8);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.2);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.event_manager_sp_attendee_form{
	z-index: 10;
	height:25px !important;
	width:100px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:160px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
    background: url(../images/buttons/butt_template.png) rgba(255,200,255, 0.5);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:5% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
	text-align:left;
	text-decoration:none;
	/*
	results_frame_option_sp_4
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.90em  !important;
    font-weight: 500;
	text-indent: 10px;
}
.event_manager_sp_followers_form{
	z-index: 10;
	height:25px !important;
	width:100px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:160px;
	margin-left:102px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
    background: url(../images/buttons/butt_template.png) rgba(25,255,255, 0.5);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:5% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
	text-align:left;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.90em  !important;
    font-weight: 500;
	text-indent: 10px;
}
.event_manager_sp_child_count_form{
	z-index: 10;
	height:50px !important;
	/*
	width:80px;
	*/
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:180px;
	margin-left:5px;
	border-spacing: 0px;
	border:4px solid rgba(0,100,255, 0.5);
	border-radius: 45px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.5);
    background-size: 60px 45px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 1.25em  !important;
    font-weight: 500;
	text-indent: 0px;
}
/************************************/
/*

*/
.results_mailboxframe_absolute {
	height:48px !important;
	width:945px;
	border-spacing: 0px;
	/*
	border:1px solid rgba(255,255,255, 0.1);
	*/
	border:1px thin rgba(204,204,204, 0.3);
	background-color: rgba(233,233,233, 0.4);
	display: inline-block;
	margin-top:.2px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_mailbox_frame_option_1 {
	z-index: 1;
    height:45px !important;
    width:945px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:1px;
	margin-left:1px;
	border:1px solid rgba(233,233,233, 0.0);
    background: url(../images/buttons/clear_img.png);
    background-size: 143px 43px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.results_mailbox_frame_im_option_2 {
	z-index: 1;
    height:45px !important;
    width:70px;
	position: absolute;
	margin-top:1px;
	margin-left:1px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/sys_option_default/sys_pro_default.png) rgba(255,255,255, 0.5);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.results_mailbox_frame_im_option_3 {
	z-index: 1;
    height:45px !important;
    width:200px;
	position: absolute;
	margin-top:1px;
	margin-left:75px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
}
/******/
.results_mailbox_frame_im_option_3_div {
	z-index: 1;
    height:36px !important;
    width:36px;
	display: inline-block;
	margin-top:2px;
	margin-left:1px;
	text-align: left;
	vertical-align: text-top; 
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.2);
	background: url(../images/buttons/clear_img.png) rgba(233,233,233, 0.5);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/******/
.results_mailbox_frame_im_option_4 {
	z-index: 1;
    height:45px !important;
    width:380px;
	line-height:12px;
	position: absolute;
	margin-top:1px;
	margin-left:280px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
}
.results_mailbox_frame_im_option_3_default {
	z-index: 1;
    height:45px !important;
    width:200px;
	position: absolute;
	margin-top:1px;
	margin-left:75px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 300;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
}
.results_mailbox_frame_im_option_4_default {
	z-index: 1;
    height:45px !important;
    width:380px;
	line-height:12px;
	position: absolute;
	margin-top:1px;
	margin-left:280px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 300;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
}
.results_mailbox_frame_im_option_5 {
	z-index: 1;
    height:45px !important;
    width:65px;
	position: absolute;
	margin-top:1px;
	margin-left:662px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.results_mailbox_frame_option_6 {
	z-index: 1;
    height:45px !important;
    width:50px;
	position: absolute;
	margin-top:1px;
	margin-left:729px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(255,255,255, 0.5);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_mailbox_frame_option_7_default {
	z-index: 1;
    height:45px !important;
    width:50px;
	position: absolute;
	margin-top:1px;
	margin-left:781px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.results_mailbox_frame_option_7_active {
	z-index: 1;
    height:45px !important;
    width:50px;
	position: absolute;
	margin-top:1px;
	margin-left:781px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	/*
	
	results_mailbox_frame_im_option_3
	*/
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.5);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.results_mailbox_frame_option_8 {
	z-index: 1;
    height:45px !important;
    width:111px;
	line-height: 12px;
	position: absolute;
	margin-top:1px;
	margin-left:833px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.70em;
	font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
}

/*****************************************/
.results_mailbox_frame_em_option_2 {
	z-index: 1;
    height:45px !important;
    width:70px;
	position: absolute;
	margin-top:1px;
	margin-left:1px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/sys_pin_default.png) rgba(255,255,255, 0.5);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.results_mailbox_frame_em_option_3 {
	z-index: 1;
    height:45px !important;
    width:200px;
	position: absolute;
	margin-top:1px;
	margin-left:75px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.results_mailbox_frame_em_option_4 {
	z-index: 1;
    height:45px !important;
	line-height:12px;
    width:380px;
	position: absolute;
	margin-top:1px;
	margin-left:280px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
	/*
	dashboard_menu_option_17_active
	results_mailbox_frame_option_8
	form_filter_index1
	*/
}
.results_mailbox_frame_em_option_3_default {
	z-index: 1;
    height:45px !important;
    width:200px;
	position: absolute;
	margin-top:1px;
	margin-left:75px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 300;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.results_mailbox_frame_em_option_4_default {
	z-index: 1;
    height:45px !important;
	line-height:12px;
    width:380px;
	position: absolute;
	margin-top:1px;
	margin-left:280px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 300;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
	/*
	dashboard_menu_option_17_active
	results_mailbox_frame_option_8
	form_filter_index1
	*/
}
.results_mailbox_frame_em_option_5 {
	z-index: 1;
    height:45px !important;
    width:65px;
	position: absolute;
	margin-top:1px;
	margin-left:662px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************************************/
.results_mailbox_frame_en_option_2 {
	z-index: 1;
    height:45px !important;
    width:70px;
	position: absolute;
	margin-top:1px;
	margin-left:1px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/sys_pin_default.png) rgba(255,255,255, 0.5);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.results_mailbox_frame_en_option_3 {
	z-index: 1;
    height:45px !important;
    width:200px;
	position: absolute;
	margin-top:1px;
	margin-left:75px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-size: 185px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.results_mailbox_frame_en_option_4 {
	z-index: 1;
    height:45px !important;
	line-height:12px;
    width:380px;
	position: absolute;
	margin-top:1px;
	margin-left:280px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
	/*
	
	results_mailbox_frame_option_8
	results_mailbox_frame_en_option_3_default
	*/
}
.results_mailbox_frame_en_option_3_default {
	z-index: 1;
    height:45px !important;
    width:200px;
	position: absolute;
	margin-top:1px;
	margin-left:75px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.5);
    background-size: 185px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 300;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.results_mailbox_frame_en_option_4_default {
	z-index: 1;
    height:45px !important;
	line-height:12px;
    width:380px;
	position: absolute;
	margin-top:1px;
	margin-left:280px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 300;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
	/*
	
	results_mailbox_frame_option_8
	*/
}
.results_mailbox_frame_en_option_5 {
	z-index: 1;
    height:45px !important;
    width:65px;
	position: absolute;
	margin-top:1px;
	margin-left:662px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************************************/
.results_mailbox_frame_bm_option_2 {
	z-index: 1;
    height:45px !important;
    width:70px;
	position: absolute;
	margin-top:1px;
	margin-left:1px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/sys_option_default/default_building.png) rgba(255,255,255, 0.5);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.results_mailbox_frame_bm_option_3 {
	z-index: 1;
    height:45px !important;
    width:200px;
	position: absolute;
	margin-top:1px;
	margin-left:75px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.results_mailbox_frame_bm_option_4 {
	z-index: 1;
    height:45px !important;
    width:380px;
	line-height:12px;
	position: absolute;
	margin-top:1px;
	margin-left:280px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
    box-shadow: none !important;
}
.results_mailbox_frame_bm_option_3_default {
	z-index: 1;
    height:45px !important;
    width:200px;
	position: absolute;
	margin-top:1px;
	margin-left:75px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 300;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.results_mailbox_frame_bm_option_4_default {
	z-index: 1;
    height:45px !important;
    width:380px;
	line-height:12px;
	position: absolute;
	margin-top:1px;
	margin-left:280px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 300;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
}
.results_mailbox_frame_bm_option_5 {
	z-index: 1;
    height:45px !important;
    width:65px;
	position: absolute;
	margin-top:1px;
	margin-left:662px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************************************/
.results_mailbox_frame_sm_option_2 {
	z-index: 1;
    height:45px !important;
    width:70px;
	position: absolute;
	margin-top:1px;
	margin-left:1px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.5);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.results_mailbox_frame_sm_option_3 {
	z-index: 1;
    height:45px !important;
    width:200px;
	position: absolute;
	margin-top:1px;
	margin-left:75px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-size: 185px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.results_mailbox_frame_sm_option_4 {
	z-index: 1;
    height:45px !important;
    width:380px;
	line-height:12px;
	position: absolute;
	margin-top:1px;
	margin-left:280px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
}
.results_mailbox_frame_sm_option_3_default {
	z-index: 1;
    height:45px !important;
    width:200px;
	position: absolute;
	margin-top:1px;
	margin-left:75px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-size: 185px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 300;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.results_mailbox_frame_sm_option_4_default {
	z-index: 1;
    height:45px !important;
    width:380px;
	line-height:12px;
	position: absolute;
	margin-top:1px;
	margin-left:280px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.75em;
	font-weight: 300;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: text-top;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
}
.results_mailbox_frame_sm_option_5 {
	z-index: 1;
    height:45px !important;
    width:65px;
	position: absolute;
	margin-top:1px;
	margin-left:662px;
    border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************************/
.results_mail_item_notification_frame_absolute {
	height:400px !important;
	width:650px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color: rgba(233,233,233, 0.0);
	display: inline-block;
	margin-top:2px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_mail_item_frame_absolute {
	height:69px !important;
	width:650px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color: rgba(233,233,233, 0.0);
	display: inline-block;
	margin-top:2px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.results_mail_logged_frame_option_1 {
	z-index: 1;
    height:65px !important;
    width:400px;
	position: absolute;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:247px;
    background-color:  rgba(233,233,233, 0.3);
    background-size: 67px 67px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.0);
	border-radius: 15px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*
mail_form_command_bar_td1_td1
*/
/************************************/
.results_mail_other_frame_option_1_alter {
	z-index: 5;
    height:47px !important;
    width:47px;
	position: absolute;
    border-spacing: 0px;
	margin-top:5px;
	margin-left:5px;
    background: url(../images/buttons/clear_img.png);
    background-size: 47px 47px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.results_mail_other_frame_option_2_alter{
	z-index: 1;
    height:65px !important;
    width:400px;
	position: absolute;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background-color: rgba(0,0,255, 0.2);
    border:1px solid rgba(233,233,233, 0.0);
	border-radius: 15px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.results_mail_other_frame_option_1_default {
	z-index: 1;
    height:65px !important;
    width:400px;
	position: absolute;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background-color: rgba(0,0,255, 0.2);
    border:1px solid rgba(233,233,233, 0.0);
	border-radius: 15px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.results_mail_notification_frame_option_1_default {
	z-index: 1;
    height:350px !important;
    width:630px;
	position: absolute;
    border-spacing: 0px;
	margin-top:10px;
	margin-left:10px;
    background-color: rgba(0,0,255, 0.2);
    border:1px solid rgba(233,233,233, 0.0);
	border-radius: 15px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*********************************************/
/*********************************************/


/*********************************************/
/*********************************************/



/*********************************************/
/*********************************************/

/*********************************************/
/*********************************************/












/*********************************************/
/*********************************************/

/*   -------------------------------------------------- */
/*   -------------------------------------------------- */
/*   ------------    main_profile      ---------------- */
/*   
/*
/*  
/*
/*  
/*   
/*
/*  
/*
/*  
/*  
/*  	
/*
/*  
/* 
/*
/*
/*		
/*  
/*
/*
/*  
/*  	
/*
/*  
/* 
/*
/*
/*		
/*  
/*
/*
		
    rgba(255,255,255, 0.7) = #FFF (white)
    rgba(0,0,0, 0.7) =  (black)
    rgba(239,239,239, 0.7) = #EFEFEF (grey)
    rgba(204,204,204, 0.7) = #CCC (dark border)
    rgba(0,255,0, 0.7)  =(green)
    rgba(255,255,0, 0.7) =(yellow)
    rgba(255,0,0, 0.7)  =(red)
    rgba(15,10,222,0.7)  =(blue)

    rgba(15, 5, 26, 0.7) =  (black scheme)
    rgba(10, 245, 92, 0.7)  =(green scheme)
    rgba(4, 144, 242, 0.7)  =(blue scheme)
    rgba(255, 28, 255, 0.7)  =(pink scheme)
    rgba(204, 204, 204, 0.7)  =(grey scheme)
    rgba(252, 71, 71, 0.7)  =(red scheme)
    rgba(255, 255, 128, 0.7) =(yellow scheme)
    204,204,204

/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */

/*********************************************/
/*********************************************/

/*********************************************/
/*******************************************              word-wrap        form_profile_date_ajax_view     event_mgmt_display2_DIV1  **/
/************************************/
/************************//************************//************************/
	/************************//*	EVENT_CREATOR_MANAGER	*/
	/********//*	EVENT_CREATOR_MANAGER_MAIN	*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 
/*********************************************/
/*********************************************/
/* -----------------------------------------------------------------------------------------/   */
/* ----------------------------------------------------------------------------------------- */
@media screen and (max-device-width: 500px) and (min-device-width: 200px) {

.etitle{
    word-wrap: break-word !important;
	overflow-wrap: break-word !important;
}
.event_index_body_table_full{
    height:618px !important;
    width:950px;
    border: hidden;
	background-color:rgba(255,255,255, 0.3);
    margin-top: 0px;
	vertical-align:top;
	text-align:left;
}
.event_index_body_table_full_td{
    height:618px !important;
    width:950px;
    border: hidden;
	background-color:rgba(255,255,255, 0.3);
    margin-top: 0px;
	vertical-align:top;
	text-align:left;
}

.event_container_table {
	height: 598px;
	width: 948px !important;
	margin-top: 0px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_container_type_td {
	height: 118px;
	width: 950px !important;

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: 1px solid rgba(239,239,239, 0.3);
	border-left: 1px solid rgba(239,239,239, 0.3);
	border-right: 1px solid rgba(239,239,239, 0.3);
	border-bottom: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,47, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_td {
	height: 599px;
	width: 950px !important;

	background-color:rgba(255,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_td_left {
	height: 599px!important;
    /*
    item_form_map_field
    height: 658px !important;
    */
	width: 712px !important;
	position:relative;
	border: 1px solid rgba(239,239,239, 0.3);
	
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_td_right {
	height: 599px;
	width: 234px !important;
	position:relative;
	border: 1px solid rgba(239,239,239, 0.6);
	
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_td_right_menu {
	height: 30px;
	width: 234px !important;
	position:relative;
	border: hidden;
	
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(47,255,233, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_td_right_data {
	height: 569px;
	width: 234px !important;
	position:relative;
	border: hidden;
	
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.5);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_item_td {
	height: 599px;
	width: 950px !important;
	position:relative;
	border: 1px solid rgba(239,239,239, 0.6);

	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,233,233, 0.3);
	
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_left_main_table{
    height: 599px;
	width: 709.5px !important;
    line-height:599px;
    border: hidden;

    padding:0;
    margin:0;
    vertical-align:top;
}
.event_left_main_table_td1{
    height:256px !important;
    width: 709.5px !important;
    line-height:256px;

    border: hidden;
    padding:0;
    margin:0;
    vertical-align:text-bottom !important;
}
.event_left_main_table_td2{
    height:343px !important;
    width: 709.5px !important;
    line-height:343px;

    border-top:1px solid rgba(239,239,239, 0.3); 
    border-bottom:hidden;  
    border-left:hidden; 
    border-right:hidden; 

    padding:0;
    margin:0;
    vertical-align:top !important;
}
/************************************/
.event_container_td_left_top {
	height: 111px;
	width: 712px !important;
	position:relative;
	border: 1px solid rgba(239,239,239, 0.3);
	
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(233,233,233, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_td_left_item_top {
	height: 81px !important;
	width: 712px !important;
	margin-top: 0px;
    position:relative;
	vertical-align:top;
	text-align:left;
	border: hidden;
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_td_left_item_top1 {
	height: 81px !important;
	width: 100px !important;
	margin-top: 0px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.event_container_td_left_item_home_div {
	height: 73px !important;
	width: 95px !important;
	margin-top: 0px;
    margin-left: 0px;
    position:absolute;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
}
.event_container_td_left_item_top2 {
    /*
        event_container_td_left_top_td2
    */
	height: 81px !important;
	width: 612px !important;
	border: hidden;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(233,233,233, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_td_left_middle {
	height:200px !important;
	width: 712px !important;
	position:relative;
	border: 1px solid rgba(239,239,239, 0.3);
	
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_td_left_bottom {
	height: 289px;
	width: 712px !important;
	position:relative;
	border: 1px solid rgba(239,239,239, 0.3);
	
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_register_div_manager {
    z-index:20;
	height:100px;
	width: 712px !important;
    display: inline-block;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.0);
	
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_register_div_blocker {
    z-index:3001;
	height:400px;
	width: 712px !important;
    display: hidden;
	position:absolute;
	margin-top: 100px;
    margin-left: 0px;
	vertical-align:top;
	text-align:left;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_register_div0 {
    z-index:25;
	height:97px;
	width: 712px !important;
    display: inline-block;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.3);
	
	margin-top: 1px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_register_div1 {
    /*
    event_container_text_div3
    */
    z-index:25;
	height:97px;
	width: 270px !important;
    display: inline-block;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.3);
	
	margin-top: 1px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_register_div2 {
    z-index:25;
	height:97px;
	width: 270px !important;
    display: inline-block;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.3);
	
	margin-top: 1px;
    margin-left: 271px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_register_div3 {
    z-index:25;
	height:97px;
	width: 168px !important;
    display: inline-block;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.3);
	
	margin-top: 1px;
    margin-left: 542px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
.event_container_td_left_top_td1 {
	height: 60px;
	width: 707px !important;
	position:relative;
	border: 1px solid rgba(239,239,239, 0.3);
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_td_left_top_td2 {
	height: 50px !important;
	width: 707px !important;
	border: 1px solid rgba(239,239,239, 0.3);
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_td_left_top_td3 {
	height: 30px;
	width: 707px !important;
	line-height: 48px;
	position:relative;
	border: hidden;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/************************************/
.event_container_td_left_middle_td1 {
	height: 48px;
	width: 612px !important;
	line-height: 48px;
	position:relative;
	border: 1px solid rgba(239,239,239, 0.3);
	
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_td_left_middle_td2 {
	height: 48px;
	width: 712px !important;
	position:relative;
	margin-top: 0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_container_td_left_middle_td3 {
	height: 100px;
	width: 712px !important;
	line-height: 100px;
	position:relative;
	margin-top: 0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_container_td_left_middle_manager {
	height: 47px;
	width: 105px !important;
	line-height: 47px;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.9);
	margin-top: 1px;
	margin-left: 600px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/*

*/
.event_container_header_div {
	height: 60px;
	width: 525px !important;
	line-height: 60px;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.9);
	margin-top: 0px;
	margin-left: 1px;
	vertical-align:middle;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_settings_div {
	height: 60px;
	width: 60px !important;
	line-height: 60px;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.9);
	margin-top: 0px;
	margin-left: 521px;
	vertical-align:middle;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_delete_div {
	height: 60px;
	width: 60px !important;
	line-height: 60px;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.9);
	margin-top: 0px;
	margin-left: 584px;
	vertical-align:middle;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_save_div {
	height: 60px;
	width: 60px !important;
	line-height: 60px;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.9);
	margin-top: 0px;
	margin-left: 647px;
	vertical-align:middle;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/*
event_seat_default
event_container_header_div
*/
.event_container_switch_frame_div {
	z-index: 10;
    height:46px !important;
    width: 280px;
    line-height:46px !important;
	position: absolute;
	margin-top: 63px;
	margin-left: 432px;
    border-spacing: 0px;
    text-align:center;
    background-color:rgba(255,255,255, 0.2);
}

.event_container_activity_header_div {
	height: 57px;
	width: 518px !important;
	line-height: 12px;
	position:absolute;
	padding:0;
	margin-top: 1px;
	margin-left: 1px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.8);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_activity_div {
	height: 47px;
	width: 280px !important;
	line-height: 47px;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.9);
	margin-top: 0px;
	margin-left: 428px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_activity_address_div_error {
    height: 42px;
	width: 490px !important;
	line-height: 12px;
	padding:0;
	margin-top: 1px;
	margin-left: 1px;
	vertical-align:top;
	text-align:left;
	border: 2px solid rgba(252, 71, 71, 0.7);
	background-color:rgba(255,255,255, 0.8);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_activity_address_div {
	height: 42px;
	width: 490px !important;
	line-height: 12px;
	padding:0;
	margin-top: 1px;
	margin-left: 1px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.8);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_activity_address_div_option{
    /*
    eventsystem_div_01_search
    event_container_add_button
    */
    z-index:10;
    height:38px ;
    width: 48px;
	line-height: 38px !important;
	margin-top: 1px;
	margin-left: 430px;
    position: absolute !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    border-radius: 10px;
    background-color:rgba(252, 71, 71, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
/************************************/
.event_container_label_div {
    /*
    item_address_field
    */
	height: 47px;
	width: 99px !important;
	line-height: 47px;
	position:absolute;
	margin-top: 0px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/event_details/event_item_location_icon.png) rgba(255,255,255, 0.8);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
    text-indent:0px;
}
.event_container_long_div {
	height: 47px;
	width: 494px !important;
	line-height: 47px;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.9);
	margin-top: 0px;
	margin-left: 105px;
	vertical-align:middle;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_end_div {
	height: 47px;
	width: 105px !important;
	line-height: 47px;
	position:absolute;
	border: 1px solid rgba(239,239,239, 0.9);
	margin-top: 0px;
	margin-left: 603px;
	vertical-align:middle;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/*

*/
.event_container_label_div1 {
	height: 47px;
	width: 99px !important;
	line-height: 47px;
	position:absolute;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/event_details/event_item_start_icon.png) rgba(255,255,255, 0.8);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
    text-indent:0px;
}
.event_container_label_div2 {
	height: 47px;
	width: 99px !important;
	line-height: 47px;
	position:absolute;
	margin-top: 1px;
	margin-left: 303px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/event_details/event_item_stop_icon.png) rgba(255,255,255, 0.8);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
    text-indent:0px;
}
.event_container_label_description {
    /*
    event_container_text_div3
    event_container_social_media_button
    */
    z-index:10;
	height: 98px;
	width: 348px !important;
    line-height: 98px;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    text-align:center;
	vertical-align: middle;
	font-size: 1.20em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    
    text-indent:0px;
}
.event_container_label_sei {
    /*
    event_container_short_div_data2_sei
    event_container_td_left
    */
    z-index:10;
	height: 98px;
	width: 208px !important;
    line-height: 98px;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    text-align:center;
	vertical-align: middle;
	font-size: 1.20em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    
    text-indent:0px;
}
.event_container_short_div1_error {
    height: 45px;
	width: 193px !important;
	line-height: 47px;
	position:absolute;
	margin-top: 1px;
	margin-left: 105px;
    border: 2px solid rgba(252, 71, 71, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.8);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:left;
}
.event_container_short_div2_error {
    height: 45px;
	width: 193px !important;
	line-height: 47px;
	position:absolute;
	margin-top: 1px;
	margin-left: 406px;
    border: 2px solid rgba(252, 71, 71, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.8);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:left;
}

.event_container_short_div1 {
    /*
    event_container_label_div1
    event_container_short_div_data1_label
    event_container_activity_address_div
    */
	height: 45px;
	width: 193px !important;
	line-height: 47px;
	position:absolute;
	margin-top: 1px;
	margin-left: 105px;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.8);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:left;
}
.event_container_short_div2 {
    /*
    event_container_label_div
    */
	height: 45px;
	width: 193px !important;
	line-height: 47px;
	position:absolute;
	margin-top: 1px;
	margin-left: 406px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.8);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:left;
}
.event_container_text_div3 {
	/*
	event_container_label_div3
    event_display_messageboxinput_field
    event_container_short_div_data2_social
	*/
	height: 100px;
	width: 350px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 1px;
	margin-left: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_container_short_div_data1_label {
	height: 41px;
	width: 99% !important;
	display: inline-block;
	line-height: 41px;
	border: hidden;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align:middle;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_short_div_data1 {
	height: 41px;
	width: 87px !important;
    position:absolute;
	display: inline-block;
	line-height: 41px;
	margin-top: 0px;
	margin-left: 100px;
	vertical-align:middle;
	text-align:left;
    border: 0px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_short_div_data2 {
    z-index:10;
	height: 98px;
	width: 101px !important;
	display: inline-block;
    position: absolute;
	line-height: 47px;
	border: 1px solid rgba(239,239,239, 0.0);
	margin-top: 0px;
	margin-left: 602px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_short_div_data2_social {
    /*
    event_container_social_media_button
    event_container_short_div_data2_social
    event_container_label_description
    */
    z-index:5;
	height: 100px;
	width: 150px !important;
    line-height: 47px;
	display: inline-block;
    position: absolute;
	margin-top: 0px;
	margin-left: 350px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_container_short_div_data2_sei {
    z-index:5;
	height: 100px;
	width: 210px !important;
    line-height: 47px;
	display: inline-block;
    position: absolute;
	margin-top: 0px;
	margin-left: 500px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_itinerary_child_button {
	height: 85px;
	width: 90px !important;
	display: inline-block;
	line-height: 12px;
	
	margin-top: 5px;
	margin-left: 5px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,45,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.event_container_data3 {
	height: 100px !important;
	width: 602px !important;
	/*
	line-height: 100px !important;
	*/
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_container_data3_menu {
	height: 100px !important;
	width: 105px !important;

	line-height: 100px !important;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;

}
.event_container_data4 {
	height: 180px;
	width: 530px !important;
	line-height: 180px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	
	
	event_container_settings_div
	*/
}
.event_container_data5 {
	height: 180px;
	width: 180px !important;
	line-height: 180px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_data6 {
	height: 180px;
	width: 180px !important;
	line-height: 180px;
	position: absolute;
	margin-top: -91px;
	margin-left: -74px;
	/*
	margin-top: 415px;
	margin-left: 527px;
	*/
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data6_holiday {
	height: 180px;
	width: 180px !important;
	line-height: 180px;
	position: absolute;
	margin-top: -91px;
	margin-left: -180px;
	/*
	margin-top: 415px;
	margin-left: 527px;
	*/
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_data7 {
	height: 50px;
	width: 707px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data7_1 {
	height: 50px;
	width: 537px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:left;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data7_1_1 {
	height: 50px;
	width: 217px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:left;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data7_1_2 {
	height: 50px;
	width: 217px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:center;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data7_1_1_holiday {
	/*
		event_parent_request_dashboard
	
	*/
	height: 50px;
	width: 200px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:left;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data7_1_2_holiday {
	height: 50px;
	width: 450px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:center;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data7_1_3 {
	height: 50px;
	width: 100px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:left;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*****************************/
.event_parent_seat_dashboard {
	height: 56px;
	width: 179px !important;
	line-height: 23px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	event_container_data3
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
.event_parent_request_dashboard {
	height: 56px;
	width: 179px !important;
	line-height: 23px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
.holiday_parent_category_dashboard {
	/*
	event_container_data7_1_1_holiday
	holiday_parent_dashboard_active
	*/
	height: 56px;
	width: 200px !important;
	line-height: 23px;
	border: 1px solid rgba(204,204,204, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position:1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
.holiday_parent_occurance_dashboard {
	height: 56px;
	width: 335px !important;
	line-height: 23px;
	border: 1px solid rgba(204,204,204, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position:1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
.holiday_parent_tag_dashboard {
	/*
	holiday_tag_frame_option_2
	event_container_data3_menu
	holiday_parent_tag_dashboard
	*/
	height: 102px;
	width: 105px !important;
	line-height: 23px;
	border: 1px solid rgba(204,204,204, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 70px 70px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 1.65em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
}
.holiday_parent_tag_dashboard_div {
	height: 100px;
	width: 197px !important;
	border: 1px solid rgba(204,204,204, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 70px 70px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 1.85em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
}
.seat_parent_dashboard_active {
    /*
    inbox_parent_dashboard_active
    */
    height:47px;
    width:60px;
    line-height:23px;
	position: absolute;
    margin-top: 5px;
    margin-left: 117px;
	
    padding:0px;
	text-align:center!important;
	vertical-align: top;
    font-size: .85em;
    font-weight: 600;
    
    text-decoration:none;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.request_parent_dashboard_active {
    height:47px;
    width:60px;
    line-height:23px;
	position: absolute;
    margin-top: 5px;
    margin-left: 117px;
	
    padding:0px;
	text-align:center!important;
	vertical-align: top;
    font-size: .85em;
    font-weight: 600;
    
    text-decoration:none;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_category_dashboard_active {
    height:47px;
    width:90px;
    line-height:23px;
	position: absolute;
    margin-top: 5px;
    margin-left: 105px;
	
    padding:0px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);

    background: rgba(255,255,255, 0.7);
    background-size: 45px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
}
.holiday_parent_dashboard_active {
    height:47px;
    width:210px;
    line-height:23px;
	position: absolute;
    margin-top: 5px;
    margin-left: 120px;
	
    padding:0px;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);

    background: rgba(255,255,255, 0.7);
    background-size: 45px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
}

.event_parent_inbox_dashboard {
	height: 56px;
	width: 179px !important;
	line-height: 23px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
.inbox_parent_dashboard_active {
    height:47px;
    width:60px;
    line-height:23px;
	position: absolute;
    margin-top: 5px;
    margin-left: 117px;
	
    padding:0px;
	text-align:center!important;
	vertical-align: top;
    font-size: .85em;
    font-weight: 600;
    
    text-decoration:none;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 55px 38px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_parent_metrics_dashboard {
	height: 56px;
	width: 160px !important;
	line-height: 23px;
	border: 1px solid rgba(204,204,204, 0.3);
	padding:0;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 70px 45px;
    background-repeat: no-repeat;
    background-position:93% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
/*****************************/
.event_seat_dashboard {
	height: 46px;
	width: 180px !important;
	line-height: 23px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	event_container_data3
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
.event_request_dashboard {
	height: 46px;
	width: 180px !important;
	line-height: 23px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:5px;
}
.seat_dashboard_active {
    height:37px;
    width:60px;
    line-height:23px;
	position: absolute;
    margin-top: 7px;
    margin-left: 115px;
	
    padding:0px;
	text-align:center!important;
	vertical-align: top;
    font-size: .85em;
    font-weight: 600;
    
    text-decoration:none;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 55px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.request_dashboard_active {
    height:37px;
    width:60px;
    line-height:23px;
	position: absolute;
    margin-top: 7px;
    margin-left: 115px;
	
    padding:0px;
	text-align:center!important;
	vertical-align: top;
    font-size: .85em;
    font-weight: 600;
    
    text-decoration:none;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 55px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_dashboard_active {
    height:37px;
    width:50px;
    line-height:23px;
	position: absolute;
    margin-top: 7px;
    margin-left: 70px;
	
    padding:0px;
	text-align:center!important;
	vertical-align: top;
    font-size: .85em;
    font-weight: 600;
    
    text-decoration:none;
	border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.3);

    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 45px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_seat_default {
	height: 46px;
	width: 170px !important;
	line-height: 12px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
}
.event_seat_active {
	height: 46px;
	width: 170px !important;
	line-height: 12px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(10, 245, 92, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
}
/*
event_item_photolist
*/
.event_item_itinerary_default {
	height: 46px;
	width: 100px !important;
	line-height: 12px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:left;
    text-decoration:none;
    text-indent:10px;
}
/*****************************/
.event_container_data7_2 {
	height: 50px;
	width: 167px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(45,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data7_2_1 {
	height: 50px;
	width: 30px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(45,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data7_2_2 {
	height: 50px;
	width: 100px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	vertical-align:top;
	text-align:center;
	background-color:rgba(45,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data7_2_3 {
	height: 50px;
	width: 30px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(45,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_data8 {
	height: 58px;
	width: 707px !important;
	line-height: 23px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data8_0 {
	height: 58px;
	width: 65px !important;
	line-height: 23px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	event_item_seat_add
	event_item_photobank
	*/
}

.event_item_container_data7_2 {
	height: 50px;
	width: 105px !important;
	line-height: 12px;
	padding:0;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.event_item_container_data7_3 {
	height: 50px;
	width: 54px !important;
	line-height: 12px;
	padding:0;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
/******************************************event_container_settings_button
.event_container_data8_image_div {
	z-index: 50;
	height: 56px !important;
	width: 56px !important;
	position: absolute;
	margin-top: 286px;
	margin-left: 2px;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data8_catelog_div {
	z-index: 50;
	height: 56px !important;
	width: 56px !important;
	position: absolute;
	margin-top: 286px;
	margin-left: 257px;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data8_stream_div {
	z-index: 50;
	height: 56px !important;
	width: 56px !important;
	position: absolute;
	margin-top: 286px;
	margin-left: 510px;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data8_stream_type_div {
	z-index: 50;
	height: 56px;
	width: 137px !important;
	position: absolute;
	margin-top: 286px;
	margin-left: 568px;
	
	border: 1px solid rgba(239,239,239, 0.7);
	background-color:rgba(239,239,239, 0.3);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	
}
*/
/*
event_container_data8_2
event_container_data8_0
*/
/*******************************************/
.event_container_data_menu_image_default {
	height: 56px !important;
	width: 62px !important;
	line-height: 50px;
	margin-top: 0px;
	margin-left: 0px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background: url(../images/event_details/event_item_active_icon.png) rgba(239,239,239, 0.7);
    background-position:center; 
    background-size: 40px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_data_menu_image_active {
	height: 56px !important;
	width: 62px !important;
	line-height: 50px;
	margin-top: 0px;
	margin-left: 0px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background: url(../images/event_details/event_item_active_icon.png) rgba(10, 245, 92, 0.7);
    background-position:center; 
    background-size: 40px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.event_container_data_menu_catalog_default {
	height: 56px !important;
	width: 62px !important;
	line-height: 50px;
	margin-top: 0px;
	margin-left: 0px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background: url(../images/event_details/event_item_active_icon.png) rgba(239,239,239, 0.7);
    background-position:center; 
    background-size: 40px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_data_menu_catalog_active {
	height: 56px !important;
	width: 62px !important;
	line-height: 50px;
	margin-top: 0px;
	margin-left: 0px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
    background: url(../images/event_details/event_item_active_icon.png) rgba(10, 245, 92, 0.7);
    background-position:center; 
    background-size: 40px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.event_container_data_menu_stream_default {
	height: 56px !important;
	width: 62px !important;
	line-height: 50px;
	margin-top: 0px;
	margin-left: 0px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background: url(../images/event_details/event_item_active_icon.png) rgba(239,239,239, 0.7);
    background-position:center; 
    background-size: 40px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_data_menu_stream_active {
	height: 56px !important;
	width: 62px !important;
	line-height: 50px;
	margin-top: 0px;
	margin-left: 0px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background: url(../images/event_details/event_item_active_icon.png) rgba(10, 245, 92, 0.7);
    background-position:center; 
    background-size: 40px 40px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************************/
.event_stream_option_default {
	z-index: 18;
	height:56px !important;
	width:130px !important;
	line-height: 45px;
	position: absolute;
	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border: hidden;
	/*
		event_item_streamsystem
	window_view_UI_control_class
	*/
	background: url(../images/buttons/STREAM.png) rgba(255,255,255, 0.4);
	background-size:contain;
	background-repeat: no-repeat;
	background-position:center;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_stream_option_YOUTUBE {
	z-index: 18;
	height:56px !important;
	width:130px !important;
	line-height: 45px;
	position: absolute;
	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border: hidden;
	/*
		event_item_streamsystem
	*/
	background: url(../images/buttons/YOUTUBE.png) rgba(255,255,255, 0.4);
	background-size:contain;
	background-repeat: no-repeat;
	background-position:center;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_stream_option_VIMEO {
	z-index: 18;
	height:56px !important;
	width:130px !important;
	line-height: 45px;
	position: absolute;
	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border: hidden;
	
	background: url(../images/buttons/VIMEO.png) rgba(255,255,255, 0.4);
	background-size:contain;
	background-repeat: no-repeat;
	background-position:center;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_stream_option_TWITCH {
	z-index: 18;
	height:56px !important;
	width:130px !important;
	line-height: 45px;
	position: absolute;
	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border: hidden;
	
	background: url(../images/buttons/TWITCH.png) rgba(255,255,255, 0.4);
	background-size:contain;
	background-repeat: no-repeat;
	background-position:center;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************/
.event_stream_option_class_absolute{
	z-index: 10;
   	height:120px; 
    width:185px;
    line-height:70px  !important;
    border-spacing: 0px;
	background:rgba(239,239,239, 0.5);
    border:hidden;
    display:inline-grid;
    margin-top:4px !important;
    margin-left:2px !important;
    vertical-align:top !important;
    text-align:center;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_stream_option_ACTIVE_class{
	border:2px solid rgba(10, 245, 92, 0.7) !important;
}
.event_stream_option_DEFAULT_class{
    height:117px; 
    width:182px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.4);
	background-position:center; 
    background-size: 150px 100px;
	background-repeat: no-repeat;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_stream_option_YOUTUBE_class{
    height:117px; 
    width:182px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/buttons/YOUTUBE.png) rgba(255,255,255, 0.4);
	background-size:contain;
	background-repeat: no-repeat;
	background-position:center;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_stream_option_VIMEO_class{
    height:117px; 
    width:182px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/buttons/VIMEO.png) rgba(255,255,255, 0.4);
	background-size:contain;
	background-repeat: no-repeat;
	background-position:center;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_stream_option_TWITCH_class{
    height:117px; 
    width:182px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:1px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/buttons/TWITCH.png) rgba(255,255,255, 0.4);
	background-size:contain;
	background-repeat: no-repeat;
	background-position:center;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*******************************************/
.stream_container_option_td {
	height: 270px;
	width: 950px !important;
	border: 1px solid rgba(239,239,239, 0.0);
	margin-top: 50px;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.stream_container_menu_td {
	height: 210px;
	width: 950px !important;
	border: 1px solid rgba(239,239,239, 0.7);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.stream_container_menu_td_left {
	height: 205px;
	width: 650px !important;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.stream_container_menu_td_right {
	height: 205px;
	width: 300px !important;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
event_stream_option_VIMEO

*/
.stream_container_menu_td_video_screen_div {
	z-index: 10;
	height: 200px;
	width: 294px !important;
	
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(239,239,239, 0.0);
	background-image:url(../images/buttons/clear_img.png) rgba(255,255,255, 0.4);
    background-position:50% 45%; 
    background-size: 100px 100px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.stream_container_menu_td_video_media_div {
	height: 200px;
	width: 294px !important;
	
	position: relative;
	margin-top: 2px;
	margin-left: 2px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(239,239,239, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.stream_form_full_table {
    height:47px !important;
    width:500px !important;
    text-align:left;
    line-height:25px;
    background:rgba(255,255,255, 0.3);
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    margin:0;
    padding:0;
}
.stream_form_third_table {
    height:47px !important;
    width:247px !important;
    text-align:left;
    line-height:25px;
    background:rgba(255,255,255, 0.3);
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    margin:0;
    padding:0;
}
.stream_form_half_table {
    height:47px !important;
    width:173px !important;
    text-align:left;
    line-height:25px;
    background:rgba(255,255,255, 0.3);
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    margin:0;
    padding:0;
}
.stream_form_label_full {
    height:47px !important;
    width:150px !important;
    line-height:47px !important;
    text-align:center;
    text-decoration:none;
    background:rgba(255,255,255, 0.3);
    display:inline-block;
    vertical-align:middle !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);

}
.stream_form_label_half {
    height:47px !important;
    width:76px !important;
    line-height:47px !important;
    text-align:center;
    text-decoration:none;
    background:rgba(255,255,255, 0.3);
    display:inline-block;
    vertical-align:middle !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);

}
/*******************************************/


/*******************************************/
.event_container_data8_1 {
	height: 58px;
	width: 200px !important;
	line-height: 12px;
	padding:0;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	event_item_seat_add
	event_item_photobank
	*/
}
.event_container_data8_1_1 {
	height: 58px;
	width: 120px !important;
	line-height: 12px;
	position: absolute;
	padding:0;
	margin-top: 0px;
	margin-left: 0px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.7);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data8_1_2 {
	height: 58px;
	width: 72px !important;
	line-height: 23px;
	position: absolute;
	padding:0;
	margin-left: 120px;
	border: 1px solid rgba(239,239,239, 0.3);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.7);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	
	
	*/
}
.event_container_data8_2 {
	height: 58px;
	width: 134px !important;
	line-height: 23px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data8_2_1 {
	height: 58px;
	width: 134px !important;
	line-height: 23px;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;
	border: 1px solid rgba(239,239,239, 0.3);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	event_item_streamsystem
	event_container_data_menu_catalog_active
	*/
}
.event_container_data8_3 {
	height: 58px;
	width: 100px !important;
	line-height: 23px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:middle;
	text-align:left;
	background-color:rgba(255,255,170, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_data9 {
	height: 220px;
	width: 707px !important;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.4);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data9_1 {
	height: 197px;
	width: 200px !important;
	border: 1px solid rgba(239,239,239, 0.0);
	position: absolute;
	vertical-align:middle;
	text-align:center;
	background-color:rgba(255,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*

*/
.event_container_data9_2 {
	height: 197px;
	width: 506px !important;
	line-height: 197px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	item_form_map_field
	*/
}

.event_container_header_frame {
	height: 30px;
	width: 280px !important;
	line-height: 30px;

	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,47,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_startDate_frame {
	height: 124px;
	width: 280px !important;
	line-height: 124px;

	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_endDate_frame {
	height: 124px;
	width: 280px !important;
	line-height: 124px;
	
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_bottom_frame {
	height: 242px;
	width: 280px !important;
	line-height: 130px;
	
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_bottom_frame_alternate {
	height: 375px;
	width: 280px !important;
	line-height: 130px;
	padding:0;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(239,239,239, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}

.event_container_sd_frame_r1 {
	height: 38px;
	width: 280px !important;
	line-height: 58px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_sd_frame_r2 {
	height: 40px;
	width: 280px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_sd_frame_r3 {
	height: 40px;
	width: 280px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_ed_frame_r1 {
	height: 38px;
	width: 280px !important;
	line-height: 58px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_ed_frame_r2 {
	height: 40px;
	width: 280px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_ed_frame_r3 {
	height: 40px;
	width: 280px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
event_container_bottom_frame
*/
.event_container_st_frame_r1_1 {
	height: 40px !important;
	width: 50px !important;
	line-height: 20px;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.8);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 0.30em;
    font-weight: 500;
    vertical-align:middle;
    text-align:left;
    text-decoration:none;
    text-indent:1px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
}
.event_container_st_frame_r1_2 {
	height: 40px;
	width: 230px !important;
	line-height: 40px;
	background-color:rgba(255,255,255, 0.8);
	border: 1px solid rgba(239,239,239, 0.9);
	padding:0;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_st_frame_r2_1 {
	height: 40px;
	width: 50px !important;
	line-height: 20px;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.8);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 0.30em;
    font-weight: 500;
    vertical-align:middle;
    text-align:left;
    text-decoration:none;
    text-indent:1px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
}
.event_container_st_frame_r2_2 {
	height: 40px;
	width: 230px !important;
	line-height: 40px;
	background-color:rgba(255,255,255, 0.8);
	border: 1px solid rgba(239,239,239, 0.9);
	padding:0;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_st_frame_r3_1 {
	height: 40px;
	width: 50px !important;
	line-height: 20px;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.8);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 0.30em;
    font-weight: 500;
    vertical-align:middle;
    text-align:left;
    text-decoration:none;
    text-indent:1px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
}
.event_container_st_frame_r3_2 {
	height: 40px;
	width: 230px !important;
	line-height: 40px;
	background-color:rgba(255,255,255, 0.8);
	border: 1px solid rgba(239,239,239, 0.9);
	padding:0;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_st_frame_r4 {
	height: 122px;
	width: 280px !important;
	line-height: 122px;
	padding:0;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
/************************************/
/************************************/
/*
occurrence_table_td
xdsoft_datetimepicker
*/
.holiday_date_view{
	z-index: 100;
    height:533px; 
    width:668px;
    display:inline-block;	
    vertical-align:top;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color:transparent;
    border: hidden;
}
.holiday_form_view{
	z-index: 100;
    height:533px; 
    width:278px;
    display:inline-block;	
    vertical-align:top;
	border: hidden;	
    background-color:transparent;
}
.holiday_container_date_now_div {
	height:36px !important;
	width:50px;
	border-spacing: 0px;
	position: absolute;
	border:hidden;
	margin-top: 40px;
	margin-left: 220px;
	vertical-align:top;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.holiday_container_date_now_div {
	height:36px !important;
	width:50px;
	border-spacing: 0px;
	position: absolute;
	border:hidden;
	margin-top: 40px;
	margin-left: 220px;
	vertical-align:top;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_container_date_now  {
	height:32px !important;
	width:50px;
	border-spacing: 0px;
	border:hidden;
	margin-top: 3px;
	margin-left: 0px;
	border-radius: 25px;
	vertical-align:top;
	text-align:center;
	background: url(../images/buttons/butt_template.png) rgba(47,209,209, 0.7); 
	background-size: 30px 20px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_container_startDate_frame {
	height: 124px;
	width: 240px !important;
	line-height: 12px;

	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.holiday_container_endDate_frame {
	height: 124px;
	width: 200px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.holiday_container_bottom_frame {
	height: 300px;
	width: 280px !important;
	line-height: 15px;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.4);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.holiday_container_bottom_frame_alternate {
	height: 375px;
	width: 280px !important;
	line-height: 130px;
	padding:0;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(239,239,239, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}

.holiday_container_sd_frame_r1 {
	height: 38px;
	width: 280px !important;
	line-height: 58px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_container_sd_frame_r2 {
	height: 40px;
	width: 280px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_container_sd_frame_r3 {
	height: 40px;
	width: 280px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_container_ed_frame_r1 {
	height: 38px;
	width: 280px !important;
	line-height: 58px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_container_ed_frame_r2 {
	height: 40px;
	width: 280px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_container_ed_frame_r3 {
	height: 40px;
	width: 280px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*

*/
.holiday_container_st_frame_r1_1 {
	height: 40px;
	width: 50px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.8);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: .85em;
    font-weight: 500;
	vertical-align:middle;
	text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.holiday_container_st_frame_r1_2 {
	height: 40px;
	width: 230px !important;
	line-height: 40px;
	background-color:rgba(255,255,255, 0.8);
	border: 1px solid rgba(239,239,239, 0.9);
	padding:0;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_container_st_frame_r2_1 {
	height: 40px;
	width: 50px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.8);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: .85em;
    font-weight: 500;
	vertical-align:middle;
	text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.holiday_container_st_frame_r2_2 {
	height: 40px;
	width: 230px !important;
	line-height: 40px;
	background-color:rgba(255,255,255, 0.8);
	border: 1px solid rgba(239,239,239, 0.9);
	padding:0;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_container_st_frame_r3_1 {
	height: 40px;
	width: 50px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.8);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: .85em;
    font-weight: 500;
	vertical-align:middle;
	text-align:left;
    text-decoration:none;
    text-indent:0px;
}
.holiday_container_st_frame_r3_2 {
	height: 40px;
	width: 230px !important;
	line-height: 40px;
	background-color:rgba(255,255,255, 0.8);
	border: 1px solid rgba(239,239,239, 0.9);
	padding:0;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_container_st_frame_r4 {
	height: 122px;
	width: 280px !important;
	line-height: 122px;
	padding:0;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
/************************************/
/************************************/
/*

event_display_home_div
select_frame_absolute
*/
.event_home_div{
	z-index: 100;
	height: 45px;
	width: 45px !important;
	line-height: 45px;
	position: absolute;
	top: 0px;
	margin-top: 7px;
	margin-left: 3px;
	border: hidden;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_home_display_div{
	z-index: 100;
	height: 45px;
	width: 45px !important;
	line-height: 45px;
	position: absolute;
	top: 0px;
	margin-top: 1px;
	margin-left: 1px;
	border: hidden;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_home_button {
    height:45px;
    width:45px;
    display:inline-block;
    position:relative;
    vertical-align:top;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/standard_parent_home.png) rgba(255,255,255, 0.0);
    /*
        ../images/buttons/standard_child_home.png
    */
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_form_messageboxinput_field{
    height:97% !important;
    width: 99% !important;
	line-height: 12px !important;
 
    background:rgba(239,239,239, 0.5);
	margin-top: 1px;
	margin-left: 1px;
	
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
    text-indent:5px;
	
	*/
    
}
.event_display_messageboxinput_field{
    height:97% !important;
    width: 99% !important;
	line-height: 12px !important;
 
	margin-top: 1px;
	margin-left: 1px;
	
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    background-color:rgba(255,255,255, 0.8);
   	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
    text-indent:5px;
	
	*/
    
}
.event_form_half_field{
  	height:35px !important;
    width: 97%;
    line-height:20px !important;
    margin-top: 3px;
	margin-left: 1px;
    background:rgba(4, 144, 242, 0.2);/*238,238,238,*/
    border-spacing: 0px;
    border:hidden;
    /*
    event_container_short_div_data1
    font-size: .85em;
    font-weight: 500;
    */
    text-align:left;
    text-decoration:none;
    text-indent:1px;
}
.event_form_host_field{
    height:99px !important;
    width: 700px !important;
	line-height: 30px !important;
    margin-top: 1px;
	margin-left: 2px;
 	position: absolute;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    background:rgba(239,239,239, 0.5);
}
.event_parent_standard_discription_view_map_field{
    z-index:1000;
    height: 75px;
	width: 75px !important;
	line-height: 30px !important;
    margin-top: 370px;
	margin-left: 465px;
    position: absolute;
    border-spacing: 0px;
    border: 2px solid rgba(239,239,239, 0.9);
    background:rgba(239,239,239, 0.5);
}
.event_form_map_field{
    height:175px !important;
    width: 520px !important;
	line-height: 30px !important;
    background:rgba(239,239,239, 0.5);
	margin-top: 1px;
	margin-left: 2px;
	
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);

}
.item_form_map_field{
    height:99% !important;
    width: 100% !important;
	line-height: 30px !important;
    background:rgba(239,239,239, 0.5);
	margin-top: 1px;
	margin-left: 1px;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	/*
	map_event_key_div
    event_container_activity_address_div
	*/
}
.item_form_parent_map_option{
    z-index:2000;
    height:40px ;
    width: 40px;
	line-height: 20px !important;
	margin-top: 130px;
	margin-left: 470px;
    position: absolute !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.9);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.item_form_child_map_option{
    z-index:2000;
    height:40px ;
    width: 40px;
	line-height: 20px !important;
	margin-top: 145px;
	margin-left: 455px;
    position: absolute !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.9);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.item_view_map_field{
    height: 293px;
	width: 238px !important;
	line-height: 30px !important;
    background:rgba(239,239,239, 0.5);
	margin-top: 0px;
	margin-left: 0px;
	
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	/*
	map_event_key_div
	*/
}

.event_date_full_field{
    height:35px !important;
    width: 98%  !important;
    line-height:20px !important;
    text-align:left;
    text-decoration:none;
    text-indent:35px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.2);
	background: url(../images/buttons/default_calendar.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_date_text_field{
    height:35px !important;
    width: 97%  !important;
    line-height:20px !important;
    border-spacing: 0px;
    border:hidden;
	background-color: rgba(239,239,239, 0.9);
	text-align:left;
    text-decoration:none;
    color:#888888;
    font-size: 0.85em  !important;
    font-weight: 500;
	text-indent: 0px;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_time_full_field{
    height:35px !important;
    width: 98%  !important;
    line-height:20px !important;
    text-align:left;
    text-decoration:none;
    text-indent:35px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.2);
	background: url(../images/buttons/default_clock.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_host_settings_absolute{
	z-index: 10;
    height:99px !important;
    width:102px;
    line-height:99px  !important;
    border-spacing: 0px;
	background:rgba(239,239,239, 0.5);
    border:hidden;
    display:inline-grid;
    margin-top:1px !important;
    margin-left:4px !important;
    vertical-align:top !important;
    text-align:center;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_host_frame{
	z-index: 1;
    height:99px !important;
    width:102px;
    line-height:99px  !important;

    background-size: 98px 98px;
    background-repeat: no-repeat;
    background-position:center;

    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.host_list_title{
	z-index: 3;
    height:33px !important;
    width:98px;
    line-height:33px  !important;

    margin-top: 65px;
    margin-left: 2px;
    position: absolute;
	background:  rgba(255,255,255, 0.7);
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
	
	 /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
	color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.75em;
    font-weight: 500;
    
	/*
	.member_view_control_td_visible_cell_title
	*/
}
.host_list_default_title{
	z-index: 1;
    height:33px !important;
    width:102px;
    line-height:33px  !important;

    margin-top: 66px;
    margin-left: 1px;
    position: absolute;
	background:  rgba(255,255,255, 0.7);
    border-spacing: 0px;
    border:hidden;
	 /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
	color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.75em;
    font-weight: 400;
    
} 
/*
.host_request_super_admin{
	z-index: 1;
    height:50px !important;
    width:70px;
    line-height:33px  !important;

    margin-top: 50px;
    margin-left: 110px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(239,41,239, 0.9);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.55em;
    font-weight: 400;
}
.host_request_secondary_admin{
	z-index: 1;
    height:40px !important;
    width:75px;
    line-height:12px  !important;

    margin-top:100px;
    margin-left:150px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(200,239,47, 0.9);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.55em;
    font-weight: 400;
} 
*/
.event_host_list_admin_remove{
	z-index: 1;
    height:33px !important;
    width:33px;
    line-height:33px  !important;

    margin-top: 2px;
    margin-left: 70px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);

    background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(252, 71, 71, 0.9);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_creator_list_admin{
	z-index: 1;
    height:33px !important;
    width:33px;
    line-height:12px  !important;

    margin-top: 2px;
    margin-left: 55px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);

    background: url(../images/buttons/butt_template.png) rgba(47,180,255, 0.9);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_host_list_super_admin_active{
	z-index: 3;
    height:33px !important;
    width:50px;
    line-height:12px  !important;

    margin-top: 2px;
    margin-left: 2px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);

    background: url(../images/buttons/butt_template.png) rgba(255,255,45, 0.9);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	 /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
	color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.55em;
    font-weight: 400;
}
.event_host_list_admin_active{
	z-index: 1;
    height:33px !important;
    width:33px;
    line-height:12px  !important;

    margin-top: 2px;
    margin-left: 2px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);

    background: url(../images/buttons/butt_template.png) rgba(13,255,255, 0.9);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	 /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
	color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.55em;
    font-weight: 400;
}
/*
.event_host_list_pending{
	z-index: 1;
    height:33px !important;
    width:100px;
    line-height:33px  !important;

    margin-top: 40px;
    margin-left: 2px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);

    background: url(../images/buttons/butt_template.png) rgba(255,255,160, 0.6);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
*/
/************************************/
/*
event_create_file_upload_form_button required
event_home_button
*/
.event_container_colorcode  {
	height:35px !important;
	width:60px;
	border-spacing: 0px;
	border:hidden;
	margin-top: 0px;
	margin-left: 2px;
	vertical-align:middle;
	text-align:center;
	background: url(../images/buttons/butt_template.png) rgba(209,209,209, 0.7); 
	background-size: 30px 20px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_colorkey  {
	height:35px !important;
	width:100px;
	border-spacing: 0px;
	border:hidden;
	margin-top: 0px;
	margin-left: 2px;
	vertical-align:middle;
	text-align:center;
	background: url(../images/buttons/butt_template.png) rgba(209,209,209, 0.7); 
	background-size: 30px 20px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_save_button  {
	height:60px !important;
	width:60px;
	border-spacing: 0px;
	border:hidden;
	vertical-align:middle;
	text-align:center;
    /*
    standard_parent_home
    standard_parent_description
    */
	background: url(../images/buttons/standard_parent_description.png) rgba(209,209,209, 0.7);
	background-size: 55px 55px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_settings_button  {
	height:60px !important;
	width:60px;
	vertical-align:middle;
	text-align:center;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(209,209,209, 0.7);
	background-size: 55px 55px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_container_settings_button  {
	height:46px !important;
	width:46px;
	vertical-align:middle;
	text-align:center;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(209,209,209, 0.7);
	background-size: 43px 43px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_delete_button  {
	height:60px !important;
	width:60px;
	border-spacing: 0px;
	border:hidden;
	vertical-align:middle;
	text-align:center;
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(209,209,209, 0.7);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_add_button  {
	height:92px !important;
	width:100px;
	border-spacing: 0px;
	border:hidden;
	vertical-align:top;
	text-align:center;
	background: url(../images/buttons/plus_button.png) rgba(209,209,209, 0.7); 
	background-size: 60px 60px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_add_button_active  {
	height:92px !important;
	width:100px;
	border-spacing: 0px;
	border:hidden;
	vertical-align:top;
	text-align:center;
	background: url(../images/buttons/plus_button.png) rgba(10, 245, 92, 0.7); 
	background-size: 60px 60px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_social_media_button{
    /*
    
    */
	z-index: 20;
	height: 98px;
	width: 148px !important;
	position: absolute; 
	margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    text-align:center;
	vertical-align: middle;
	font-size: 1.20em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    
    text-indent:0px;
}
.profile_container_social_media_button{
	z-index: 20;
	height:40px !important;
	width:80px;
	position: absolute; 
	margin-top: 40px;
	margin-left: 240px;
	border-spacing: 0px;
	border:hidden;
	vertical-align:top;
	text-align:center;
	background: url(../images/buttons/plus_button.png) rgba(0,100,255, 0.4); 
	background-size: 50px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.business_block_container_social_media_button{
	z-index: 20;
	height:40px !important;
	width:80px;
	position: absolute; 
	margin-top: 260px;
	margin-left: 240px;
	border-spacing: 0px;
	border:hidden;
	vertical-align:top;
	text-align:center;
	background: url(../images/buttons/plus_button.png) rgba(0,100,255, 0.4); 
	background-size: 50px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/***********************************************/
/***********************************************/

.parent_event_social_div {
	z-index: 20;
	width:260px !important;
    position:absolute;
	/*
	parent_event_social_div
	height:45px !important;
	
	width:110px !important;
	event_standard_frame_table01_td_menu01
	position: absolute;
	*/
	
	margin-top: 0px;
	margin-left: 0px;
	line-height: 32px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(239,239,239, 0.3);
	vertical-align:middle;
	text-align: right  !important;
}
.child_standard_social_div {
	z-index: 20;
    height:40px !important;
	width:225px !important;
    position:absolute;
	top: 0px;
	/*
	
	*/
	
	margin-top: 0px;
	margin-left: 1px;
	
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(239,239,47, 0.0);
	vertical-align:top;
	text-align: right  !important;
}
.parent_event_social_listing{
	height:32px !important;
	width:41px;
	display: inline-block;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/buttons/butt_template.png) rgba(209,209,209, 0.3); 
	background-size: 38px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
.profile_social_div {
	z-index: 20;
	height:50px !important;
	/*
	width:110px !important;
	*/
	margin-top: 2px;
	margin-left: 2px;
	line-height: 48px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:middle;
	text-align: left;
}
.business_social_div {
	z-index: 20;
	/*
	height:45px !important;
	
	width:110px !important;
	business_container_block_td4_2_1
	business_recommendations_block_long_edit
	business_container_td1_2_td2_1
	base_functional_action_td1
	*/
	position: absolute;
	margin-top: 320px;
	margin-left: 240px;
	line-height: 45px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(239,239,239, 0.3);
	vertical-align:middle;
	text-align: left;
} 
.business_basic_social_div {
	z-index: 20;
	width:260px !important;
	/*
	position: absolute;
	*/
	margin-top: 0px;
	margin-left: 0px;
	line-height: 45px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:middle;
	text-align: right  !important;
} 
.business_block_social_div {
	z-index: 20;
	width:260px !important;
	/*
	position: absolute;
	*/
	margin-top: 0px;
	margin-left: 0px;
	line-height: 45px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:middle;
	text-align: right  !important;
}
.business_showroom_social_div {
	z-index: 20;
	width:103px !important;
	/*
	position: absolute;
	*/
	margin-top: 0px;
	margin-left: 0px;
	line-height: 45px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:middle;
	text-align: right  !important;
} 

.metrics_social_div {
	z-index: 20;
	/*
	height:45px !important;
	
	width:110px !important;
	feed_profile_view_control
	*/
	display: inline-block;
	margin-top: 1px;
	margin-left: 400px;
	line-height: 38px;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.2);
	background-color:rgba(239,239,239, 0.3);
	vertical-align:middle;
	text-align: left;
} 
.metrics_social_listing{
	height:36px !important;
	width:65px;
	display: inline-block;
	margin-top: 2px;
	margin-left: 1px;
	border-spacing: 0px;
	border:hidden;
	background: url(../images/buttons/butt_template.png) rgba(209,209,209, 0.3); 
	background-size: 60px 32px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
}
/***********************************************/
/*
item_form_map_field

*/
/***********************************************/
.event_container_getlocation  {
	height:97px;
	width: 270px !important;
	border-spacing: 0px;
	position: absolute;
	border:hidden;
	margin-top: 0px;
	margin-left: 0px;
	background: url(../images/buttons/butt_template.png) rgba(209,209,209, 0.7); 
	background-size: 120px 70px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.25em;
    font-weight: 600;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.event_container_virtuallocation  {
	height:97px;
	width: 270px !important;
	border-spacing: 0px;
	border:hidden;
	margin-top: 0px;
	margin-left: 0px;
	background: url(../images/buttons/butt_template.png) rgba(209,209,209, 0.7); 
	background-size: 120px 70px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.25em;
    font-weight: 600;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.event_container_time_now  {
	height:97px !important;
	width:168px;
	border-spacing: 0px;
	position: absolute;
	border:hidden;
	margin-top: 0px;
	margin-left: 0px;
	background: url(../images/buttons/butt_template.png) rgba(209,209,209, 0.7); 
	background-size: 70px 70px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.25em;
    font-weight: 600;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.event_container_parent_archived_status  {
	height:97px !important;
	width:168px;
	border-spacing: 0px;
	position: absolute;
	border:hidden;
	margin-top: 0px;
	margin-left: 0px;
	background: url(../images/buttons/butt_template.png) rgba(209,209,209, 0.7); 
	background-size: 70px 70px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.25em;
    font-weight: 600;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.event_container_parent_pending_status  {
	height:97px !important;
	width:168px;
	border-spacing: 0px;
	position: absolute;
	border:hidden;
	margin-top: 0px;
	margin-left: 0px;
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7); 
	background-size: 70px 70px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.25em;
    font-weight: 600;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.event_container_parent_active_status  {
	height:97px !important;
	width:168px;
	border-spacing: 0px;
	position: absolute;
	border:hidden;
	margin-top: 0px;
	margin-left: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7); 
	background-size: 70px 70px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.25em;
    font-weight: 600;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.event_container_child_delete_option  {
	height:96px !important;
	width:99px;
	border-spacing: 0px;
	position: absolute;
	border:hidden;
	margin-top: 1px;
	margin-left: 1px;
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(252, 71, 71, 0.7); 
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.25em;
    font-weight: 600;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.event_container_date_now_div {
	height:36px !important;
	width:50px;
	border-spacing: 0px;
	position: absolute;
	border:hidden;
	margin-top: 40px;
	margin-left: 220px;
	vertical-align:top;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_date_now  {
	height:32px !important;
	width:50px;
	border-spacing: 0px;
	border:hidden;
	margin-top: 3px;
	margin-left: 0px;
	border-radius: 25px;
	vertical-align:top;
	text-align:center;
	background: url(../images/buttons/butt_template.png) rgba(47,209,209, 0.7); 
	background-size: 30px 20px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_date_field_selected{
    border-spacing: 0px;
    border: 1px solid #58F231 !important;
}
.event_date_calendar_selected{
	/*
    border-spacing: 0px;
    border: 2px solid rgba(47,209,209, 0.7) !important;
	*/
	border: 1px solid rgba(255,255,255, 0.7) !important;
	background-color: rgba(100,150,209, 0.3) !important; 
}
/*
event_create_file_upload_form_button
../images/buttons/sys_upload_graphic.png
../images/buttons/sys_pin_default.p
background: url(../images/buttons/sys_pin_default.png) rgba(255,255,255, 0.2);
background: url(../images/buttons/butt_template.png) rgba(209,209,209, 0.7);
*/
.event_create_file_upload_form_button {
	z-index: 10;
	height:177px !important;
	width:176px !important;
	display:inline-block;
	position:absolute;
	margin-left: 1px;
	margin-top: 1px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/sys_pin_default.png) rgba(209,209,209, 0.7);
	background-size: 140px 140px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_file_delete_form_button {
	z-index: 10;
	height:35px !important;
	width:35px !important;
	display:inline-block;
	position:absolute;
	margin-top: 3px;
	margin-left: 142px;
	vertical-align:top;
	border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(255,255,255, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*

event_register_div1
*/
.map_event_key_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:50%;
	/*
	margin-left:360px;
	*/
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_item_key_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:40%;
	/*
	margin-left:190px;
	*/

	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_item_containter_absolute {
  	height:79px !important;
  	width:232px;
  	line-height:20px  !important;
	
  	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.7);
    display:block;
    margin-top:2px !important;
    margin-left:1px !important;
    vertical-align:top!important;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

}
.event_item_status_active_absolute {
	z-index: 50;
  	height:5px !important;
  	width:234px;
  	line-height:20px  !important;
	position: absolute;
  	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.7);
    display:block;
    margin-top:73px !important;
    margin-left:0px !important;
    vertical-align:top!important;
    text-align:left;
	background-color: rgba(0,240,0, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
		item_event_display_active
	event_item_containter_absolute
	*/

}
.event_item_status_pending_absolute {
	z-index: 50;
  	height:5px !important;
  	width:234px;
  	line-height:20px  !important;
	position: absolute;
  	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.7);
    display:block;
    margin-top:73px !important;
    margin-left:0px !important;
    vertical-align:top!important;
    text-align:left;
	background-color: rgba(255, 255, 128, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
		item_event_display_pending
	*/

}
.event_item_status_complete_absolute {
	z-index: 50;
  	height:5px !important;
  	width:234px;
  	line-height:20px  !important;
	position: absolute;
  	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.7);
    display:block;
    margin-top:73px !important;
    margin-left:0px !important;
    vertical-align:top!important;
    text-align:left;
	background-color: rgba(252, 71, 71, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
		item_event_display_complete
	*/

}
.event_multi_containter_absolute {
  	height:79px !important;
  	width:232px;
  	line-height:20px  !important;
	
  	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.7);
    display:block;
    margin-top:2px !important;
    margin-left:1px !important;
    vertical-align:top!important;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

}

.event_item_settings_label{
  height:68px !important;
  width:70px !important;
  line-height:68px;
  vertical-align:middle;
  text-align:center;
  margin-top: 4px;
  margin-left: 8px;
  background-color:azure;
  border:3px solid #FFF;
  border-radius: 25px;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;

}
/*
url(../images/buttons/sys_delete_buttonDefault.png) rgba(255,255,255, 0.7)
background-image:url(../images/buttons/sys_close_buttonDefault.png);//
*/
.event_item_close{
	z-index: 10;
 	height:25px !important;
    width:25px !important;
    line-height:25px;
    vertical-align:middle;
    text-align:center;
    position: absolute;
    margin-top: 2px;
    margin-left: 130px;
    border:1px solid rgba(255,255,255, 0.3);
	/*
    border-radius: 25px;
	*/
    background:url(../images/buttons/sys_delete_buttonDefault.png) rgba(255,255,255, 0.3);
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position:center;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.event_item_brandmark{
  height:75px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 2px;
  margin-left: 0px;
  background-color:azure;
  border:1px solid rgba(209,209,209, 0.7);
  background: url(../images/buttons/sys_pin_default.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
/*

*/
.event_item_containter_pagination_listview_01 {
    height:34px !important;
    width:232px;
    display:inline-block;
    background-color: transparent;
    vertical-align:top;
	text-align:center;
    border: 1px solid rgba(239,239,239, 0.5);
}
.event_item_containter_pagination_listview_02 {
    height:34px !important;
    width:232px;
    display:inline-block;
    vertical-align:top;
	text-align:center;
    border: 1px solid rgba(239,239,239, 0.5);
    background-color: rgba(239,239,239, 0.7);
}

.event_item_containter_listview_main_previous {
    height: 30px !important;
    width: 28px !important;
    line-height:30px;
    display:inline-block;
    text-align:center;
    vertical-align:middle;
    border:hidden;
    background-image:url(../images/buttons/sys_menu_left_arrow.png);
    background-position:50% 45%; 
    background-size: 26px 25px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_containter_listview_main_previous_disabled {
    height: 30px !important;
    width: 28px !important;
    line-height:30px;
    display:inline-block;
    text-align:center;
    vertical-align:middle;
    border:hidden;
    background-image:url(../images/buttons/sys_menu_left_arrow_disabled.png);
    background-position:50% 45%; 
    background-size: 26px 25px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_containter_listview_main_next {
    height: 30px !important;
    width: 28px !important;
    line-height:30px;
    display:inline-block;
    text-align:center;
    vertical-align:middle;
    border:hidden;
    background-image:url(../images/buttons/sys_menu_right_arrow.png);
    background-position:50% 45%; 
    background-size: 26px 25px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_containter_listview_main_next_disabled {
    height: 30px !important;
    width: 28px !important;
    line-height:30px;
    display:inline-block;
    text-align:center;
    vertical-align:middle;
    border:hidden;
    background-image:url(../images/buttons/sys_menu_right_arrow_disabled.png);
    background-position:50% 45%; 
    background-size: 26px 25px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_containter_listview_main_option {
    height: 26px !important;
    width: 20px !important;
    line-height:26px;
    display:inline-block;
    font-size: .85em;
    font-weight: 500;
    color:#000;
    text-decoration:none;
    text-align:center;
    vertical-align:middle;
    border:hidden;
    background: url(../images/buttons/sys_menu_pagination_opt.png);
    background-position:50% 45%; 
    background-size: 18px 20px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_containter_listview_main_option_active {
    height: 26px !important;
    width: 20px !important;
    line-height:26px;
    display:inline-block;
    font-size: .85em;
    font-weight: 500;
    color:#000;
    text-decoration:none;
    text-align:center;
    vertical-align:middle;
    border:hidden;
    background: url(../images/buttons/sys_menu_pagination_opt_active.png);
    background-position:50% 45%; 
    background-size: 18px 20px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
	event_container_td_left

*/
/*********************************************/
/*********************************************/
.item_event_display_color {
	z-index: 0;
	height:78px !important;
	width:25px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 108px;
	background-color: rgba(209,209,209, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************************************/
/*********************************************/
.item_event_data01 {
	z-index: 0;
	height:25px !important;
	width:54px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(209,209,209, 0.3);
	background-color: rgba(209,209,209, 0.3);
	text-align: left;
	vertical-align: top;
	font-size: .75em;
    font-weight: 700;
    color:#000;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_event_data02 {
	z-index: 0;
	height:25px !important;
	width:52px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 53px;
	border-spacing: 0px;
	border:1px solid rgba(209,209,209, 0.3);
	background-color: rgba(209,209,209, 0.3);
	text-align: left;
	vertical-align: top;
	font-size: .75em;
    font-weight: 500;
    color:#000;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_event_data03 {
	z-index: 0;
	height:25px !important;
	width:54px !important;
	position: absolute;
	margin-top: 26px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(209,209,209, 0.3);
	background-color: rgba(209,209,209, 0.3);
	text-align: left;
	vertical-align: top;
	font-size: .75em;
    font-weight: 700;
    color:#000;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_event_data04 {
	z-index: 0;
	height:25px !important;
	width:52px !important;
	position: absolute;
	margin-top: 26px;
	margin-left: 53px;
	border-spacing: 0px;
	border:1px solid rgba(209,209,209, 0.3);
	background-color: rgba(209,209,209, 0.3);
	text-align: left;
	vertical-align: top;
	font-size: .75em;
    font-weight: 500;
    color:#000;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_event_data05 {
	z-index: 0;
	height:25px !important;
	width:104px !important;
	position: absolute;
	margin-top: 52px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(209,209,209, 0.3);
	background-color: rgba(209,209,209, 0.3);
	text-align: left;
	vertical-align: top;
	font-size: .75em;
    font-weight: 500;
    color:#000;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************************************/
/********************************************
.event_item_theme_standard_phys{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  border:1px solid rgba(209,209,209, 0.3);
  background: url(../images/bkgrounds/STANDARD_physical.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_standard_virt{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  background-color:azure;
  border:1px solid rgba(209,209,209, 0.7);
  background: url(../images/bkgrounds/STANDARD_virtual.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_popup_phys{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  background-color:azure;
  border:1px solid rgba(209,209,209, 0.7);
  background: url(../images/bkgrounds/POPUP_physical.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_popup_virt{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  background-color:azure;
  border:1px solid rgba(209,209,209, 0.7);
  background: url(../images/bkgrounds/POPUP_virtual.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_notice_phys{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  background-color:azure;
  border:1px solid rgba(209,209,209, 0.7);
  background: url(../images/bkgrounds/NOTICE_physical.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_notice_virt{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  background-color:azure;
  border:1px solid rgba(209,209,209, 0.7);
  background: url(../images/bkgrounds/NOTICE_virtual.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_media_phys{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  background-color:azure;
  border:1px solid rgba(209,209,209, 0.7);
  background: url(../images/bkgrounds/MEDIA_physical.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_media_virt{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  background-color:azure;
  border:1px solid rgba(209,209,209, 0.7);
  background: url(../images/bkgrounds/MEDIA_virtual.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_other_phys{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  background-color:azure;
  border:1px solid rgba(209,209,209, 0.7);
  background: url(../images/buttons/sys_pin_default.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_other_virt{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  background-color:azure;
  border:1px solid rgba(209,209,209, 0.7);
  background: url(../images/buttons/butt_template.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
event_container_standard_bottom_left
*/
/*********************************************/
/*********************************************/
.event_display_active {
	z-index: 0;
	height:290px !important;
	width:5px !important;
	position: absolute;
	margin-top: -117px;
	margin-left: 670px;
    border-spacing: 0px;
	border:hidden;
	background-color: rgba(0,240,0, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_display_pending {
	z-index: 0;
	height:290px !important;
	width:5px !important;
	position: absolute;
	margin-top: -117px;
	margin-left: 670px;
    border-spacing: 0px;
	border:hidden;
	background-color: rgba(255, 255, 128, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_display_complete {
	z-index: 0;
	height:290px !important;
	width:5px !important;
	position: absolute;
	margin-top: -117px;
	margin-left: 670px;
    border-spacing: 0px;
	border:hidden;
	background-color: rgba(252, 71, 71, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_display_active {
	z-index: 0;
	height:295px !important;
	width:5px !important;
	position: absolute;
	margin-top: -117px;
	margin-left: 670px;
    border-spacing: 0px;
    border:hidden;
	background-color: rgba(0,240,0, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_display_pending {
	z-index: 0;
	height:295px !important;
	width:5px !important;
	position: absolute;
	margin-top: -117px;
	margin-left: 670px;
    border-spacing: 0px;
    border:hidden;
	background-color: rgba(255, 255, 128, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_display_complete {
	z-index: 0;
	height:295px !important;
	width:5px !important;
	position: absolute;
	margin-top: -117px;
	margin-left: 675px;
    border-spacing: 0px;
	border:hidden;
	background-color: rgba(252, 71, 71, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_view_display_active {
	z-index: 1000;
    height:620px !important;
	width:5px !important;
	position: absolute;
	margin-top: -117px;
	margin-left: 675px;
    border-spacing: 0px;
	border:hidden;
	background-color: rgba(0,240,0, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_view_display_pending {
	z-index: 1000;
	height:620px !important;
	width:5px !important;
	position: absolute;
	margin-top: -117px;
	margin-left: 675px;
    border-spacing: 0px;
	border:hidden;
	background-color: rgba(255, 255, 128, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_view_display_complete {
	z-index: 1000;
	height:620px !important;
	width:5px !important;
	position: absolute;
	margin-top: -117px;
	margin-left: 675px;
    border-spacing: 0px;
	border:hidden;
	background-color: rgba(252, 71, 71, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
/*********************************************/
/*********************************************/
.event_theme_delete_div{
    /*
    event_display1_action_frame_div
    event_item_file_upload_button
    */
	z-index: 100;
	height: 45px;
	width: 45px !important;
	line-height: 45px;
	position: absolute;
	top: 0px;
	margin-top: 5px;
	margin-left: 190px;
	border: hidden;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_theme_delete {
    height:45px;
    width:45px;
    display:inline-block;
    position:relative;
    vertical-align:top;
    border: 1px solid rgba(239,239,239, 0.6);
    background: url(../images/buttons/sys_delete_buttonDefault.png)  rgba(239,239,239, 0.6);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
    
	*/
}

/*
.event_theme_Media2 {
    height: 545px !important;
	width: 357px !important;
	position:absolute;
    display:inline-block;
	margin-top: -50px;
	margin-left: 590px;
	border: hidden;
	background: url(../images/bkgrounds/event_header_small.png) rgba(255,255,255, 0.3);
	background-size:contain;
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
*/
.event_theme_Popup1 {
    height: 257px !important;
    width: 590px !important;
    position:absolute;
    display:inline-block;
    top: 1px !important;
	border: hidden;
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
	background-size:contain;
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_Popup2 {
    height: 545px !important;
	width: 357px !important;
	position:absolute;
    display:inline-block;
	margin-top: -50px;
	margin-left: 590px;
	border: hidden;
	background: url(../images/bkgrounds/event_header_small.png) rgba(255,255,255, 0.3);
	background-size:contain;
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_Notification1 {
    height: 257px !important;
    width: 948px !important;
    position:absolute;
    display:inline-block;
    top: 1px !important;
	border: hidden;
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
	background-size:contain;
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_Notification2 {
	z-index: -2;
    height: 327px !important;
    width: 948px !important;
    position:absolute;
	margin-top: 298px;
    display:inline-block;
    top: 1px !important;
	border: hidden;
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.7);
	background-size:contain;
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_Notification2_screen {
	z-index: -1;
    height: 327px !important;
    width: 948px !important;
    position:absolute;
	margin-top: 298px;
    display:inline-block;
    top: 1px !important;
	border: hidden;
	background-color: rgba(255,255,255, 0.4);
	background-size:contain;
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_standard1_upload_frame_div {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px;
	width: 45px !important;
	margin-top: 0px;
	margin-left: 615px;
	background-color:transparent;
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_media1_upload_frame_div {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px;
	width: 45px !important;
	margin-top: 0px;
	margin-left: 540px;
	background-color:transparent;
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_media2_upload_frame_div {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px;
	width: 45px !important;
	margin-top: 0px;
	margin-left: 855px;
	background-color:transparent;
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_notification1_upload_frame_div {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px;
	width: 45px !important;
	margin-top: 0px;
	margin-left: 855px;
	background-color:transparent;
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_notification2_upload_frame_div {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px;
	width: 45px !important;
	margin-top: 295px;
	margin-left: 900px;
	background-color:transparent;
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_popup1_upload_frame_div {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px;
	width: 45px !important;
	margin-top: 0px;
	margin-left: 540px;
	background-color:transparent;
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_popup2_upload_frame_div {
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px;
	width: 45px !important;
	margin-top: 0px;
	margin-left: 855px;
	background-color:transparent;
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_theme_standard_settings_div {
    z-index:2;
    display:inline-block;
    position:absolute;
    top:5px;
    left:670px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_Media1_settings_div {
    z-index:2;
    display:inline-block;
    position:absolute;
    top:5px;
    left:910px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_Popup1_settings_div {
    z-index:2;
    display:inline-block;
    position:absolute;
    top:5px;
    left:910px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.theme_view_control_title_edit_structure {
    z-index:2;
    display:inline-block;
    position:absolute;
    top:2px;
    left:640px;
    font-size: 1em;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.theme_view_control_td_visible_cell {
    height:45px;
    width:45px;
    display:inline-block;
    position:relative;
    vertical-align:top;
    border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;

}
.entity_event_upload_frame_div {
	z-index:5;
	display:inline-block;
	position:absolute;
	width: 45px !important;
	margin-top: 2px;
	margin-left: 585px;
	background-color:transparent;
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.swipe_item_file_upload_button {
	height:43px !important;
	width:43px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	vertical-align:top;
	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_upload_graphic.png) rgba(255,255,255, 0.7);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************************************/
/*********************************************/
.event_standard_frame_table01_left {
	height:353px !important;
	width:118px !important;
	line-height: 353px;
	display:inline-block;
	vertical-align: top;
	border-spacing: 0px;
	border:1px solid rgba(209,209,209, 0.3);

	background-color: rgba(255,255,255, 0.6);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
/*
event_standard_frame_table01_td1


*/
.event_standard_frame_table01 {
	height:360px !important;
	width:590px !important;
	vertical-align: top !important;
	border-spacing: 0px;
	border: hidden;
	background-color: rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_standard_frame_table01_td_menu {
	height:37px !important;
	width:590px !important;
	border-spacing: 0px;
	border:1px solid rgba(209,209,209, 0.0);
	background-color: rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_standard_frame_table01_td_menu01 {
	height: 34px;
	width: 497px !important;
	line-height: 30px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
.event_standard_frame_table01_td_menu02 {
	height: 34px;
	width: 50px !important;
	line-height: 30px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:center;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
*/
.event_standard_frame_table01_td_menu02 {
	height: 34px;
	width: 50px !important;
	line-height: 30px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:center;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_frame_table01_td_menu03 {
	height: 34px;
	width: 50px !important;
	line-height: 30px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:center;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_standard_frame_table01_td1 {
	height:156px !important;
	width:590px !important;
	border-spacing: 0px;
	border:1px solid rgba(209,209,209, 0.0);
	background-color: rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_frame_table01_td1_01 {
	height:154px !important;
	width:438px !important;
	line-height: 0px;
	vertical-align: top  !important;
	text-align: left !important;
	border-spacing: 0px;
	border:1px solid rgba(209,209,209, 0.3);
	background-color: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_frame_table01_td1_02 {
	height:154px !important;
	width:150px !important;
	vertical-align: top !important;
	border-spacing: 0px;
	border:1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_standard_frame_table01_td2{
	height:159px !important;
	width:590px !important;
	line-height: 50px;
	position: absolute;
	text-align: left !important;
	vertical-align: top;
	border-spacing: 0px;
	border: hidden;
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/***********************************event_container_standard_bottom_guidelines_td*/
.event_swipe_temp_stat_shares_default {
    z-index: 4;
    height: 98px;
    width: 140px;
    line-height:25px;
    position:relative;
    margin-top: 0px;
    margin-left: 5px;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_swipe_temp_stat_invites_default {
    z-index: 4;
    height: 98px;
    width: 140px;
    line-height:25px;
    position:relative;
    margin-top: 5px;
    margin-left: 5px;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_swipe_temp_stat_followers_default {
    /*
    event_temp_stat_followers_default
    */
    z-index: 4;
    height: 98px;
    width: 140px;
    line-height:25px;
    position:relative;
    margin-top: 5px;
    margin-left: 5px;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_swipe_temp_stat_compatability_default {
    z-index: 4;
    height: 98px;
    width: 140px;
    line-height:25px;
    position:relative;
    margin-top: 5px;
    margin-left: 5px;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_swipe_temp_stat_score_default {
    z-index: 4;
    height: 140px;
    width: 160px;
    line-height:25px;
    position:relative;
    margin-top: -25px;
    margin-left: 0px;
    border:hidden;
    background: rgba(255,255,255, 0.0);
    /*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    */
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_temp_stat_background {
    /*
    event_temp_guideline_background
    */
    z-index: 4;
    height: 88px;
    width: 120px;
    position:absolute;
    margin-top: 10px;
    margin-left: 15px;
    border:hidden;
    border-radius: 5px;
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_temp_score_background {
    z-index: 4;
    height: 80px;
    width: 100px;
    position:absolute;
    margin-top: 15px;
    margin-left: 20px;
    border:hidden;
    border-radius: 50px;
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_swipe_temp_stat_attendees_default {
    z-index: 4;
    height: 98px;
    width: 140px;
    line-height:25px;
    position:relative;
    margin-top: 0px;
    margin-left: 5px;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

/************************************/
/*
event_mgmt_display_DIV
media_menu_pagination_month_option
event_messagedisplaystatlabel_field_disabled
event_messagedisplaydatalabel_field_disabled
*/
.event_swipe_messagedisplaystatlabel_field_disabled{
    z-index: 4;
    height:40px !important;
    /*width: 345px;*/
    width: 150px !important;
    line-height:10px;

    position: absolute;
    margin-top: 10px;
    margin-left: 0px;
    background-color:transparent;
    border-spacing: 0px;
    border:hidden;
    color:#000;
    font-size: .75em !important;
    font-weight: 700 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
  }

.event_swipe_messagedisplaydatalabel_field_disabled{
    z-index: 4;
    height:100px !important;
    width: 150px !important;
    /*width: 345px;

    */
    width: 100%;
    line-height:100%;
    position: absolute;
    margin-top: 5px;
    margin-left: 0px;
    background-color:transparent;
    border-spacing: 0px;
    border:hidden;
	color:#000;
    font-size: 1.8em !important;
    font-weight: 700 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}

	/********//*	EVENT_VIEW_MAIN	*/

.event_temp_stat_shares_default {
    z-index: 4;
    height: 98px;
    width: 140px;
    line-height:25px;
    position:relative;
    margin-top: 5px;
    margin-left: 5px;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_temp_stat_invites_default {
    z-index: 4;
    height: 98px;
    width: 140px;
    line-height:25px;
    position:relative;
    margin-top: 5px;
    margin-left: 5px;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_temp_stat_score_default {
    /*
    event_swipe_temp_stat_score_default
    */
    z-index: 4;
    height: 140px;
    width: 160px;
    line-height:25px;
    position:relative;
    margin-top: -25px;
    margin-left: 0px;
    border:hidden;
    background: rgba(255,255,255, 0.0);
    /*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    */
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_temp_stat_attendees_default {
    z-index: 4;
    height: 98px;
    width: 140px;
    line-height:25px;
    position:relative;
    margin-top: 5px;
    margin-left: 5px;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_temp_stat_followers_default {
    /*
    event_swipe_temp_stat_followers_default
    */
    z-index: 4;
    height: 98px;
    width: 140px;
    line-height:25px;
    position:relative;
    margin-top: 5px;
    margin-left: 5px;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_temp_stat_compatability_default {
    /*
    event_temp_stat_followers_default
    */
    z-index: 4;
    height: 98px;
    width: 140px;
    line-height:25px;
    position:relative;
    margin-top: 5px;
    margin-left: 5px;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.0);
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_messagedisplaystatlabel_field_disabled{
    /*
    event_swipe_messagedisplaystatlabel_field_disabled
    */
    z-index: 4;
    height:40px !important;
    /*width: 345px;*/
    width: 150px !important;
    line-height:10px;
    position: absolute;
    margin-top: 10px;
    margin-left: 0px;
    background-color:transparent;
    border-spacing: 0px;
    border:hidden;
    color:#000;
    font-size: .75em !important;
    font-weight: 700 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
.event_messagedisplaydatalabel_field_disabled{
  /*

  event_swipe_messagedisplaydatalabel_field_disabled
  */
  z-index: 4;
  height:100px !important;
  width: 150px !important;
  width: 100%;
  line-height:100%;
  position: absolute;
  margin-top: 5px;
  margin-left: 0px;
  background-color:transparent;
  border-spacing: 0px;
  border:hidden;
  color:#000;
  font-size: 1.8em !important;
  font-weight: 700 !important;
  text-align:center;
  vertical-align:middle;
  text-decoration:none;
}
/************************************/
/************************//************************//************************/
	/************************//*	EVENT_SETTINGS	*/
	/********//*	EVENT_SETTINGS_MAIN	*/




	.es_table_row {
		height:25px !important;
		width:359px;
		line-height:25px !important;
		
		
		border-spacing: 0px;
		border:hidden;
		/*
		background:#FFF;
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
		
		display:inline-block;
		font-size: .90em;
		
		*/
		
	}

	.es_block_table_left {
		height:336px !important;
		width:359px;
		line-height:288px !important;
		position: absolute;
		
		border-spacing: 0px;
		border:hidden;
		background-color: rgba(255,74,255, 0.2);
		
		vertical-align:text-top !important;
		/*
		
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
		
		display:inline-block;
		font-size: .90em;
		
		*/
		
	}
	.es_block_table_right {
		height:336px !important;
		width:346px;
		line-height:288px !important;
		
		border-spacing: 0px;
		border:hidden;
		background-color: rgba(255,255,25, 0.2);
		
		vertical-align:text-top !important;
		
		/*
		
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
		
		display:inline-block;
		font-size: .90em;
		
		*/
		
	}

	.es_form_label {
		height:25px !important;
		width:75px;
		line-height:25px !important;
		
		background:#FFF;
		
		border-spacing: 0px;
		
		border-right:1px solid #ccc;
		border-bottom:1px solid #ccc;
		
		font-size: .90em;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		text-indent:5px;
		/*
		display:inline-block;
		font-size: .90em;
		
		*/
		
	}
	.es_form_description_label {
		height:175px !important;
		width:75px;
		line-height:175px !important;
		
		background:#FFF;
		
		border-spacing: 0px;
		
		border-right:1px solid #ccc;
		border-bottom:1px solid #ccc;
		
		font-size: .90em;
		font-weight: 600;
		
		text-align:left;
		vertical-align:text-top !important;
		
		text-decoration:none;
		text-indent:5px;
		/*
		display:inline-block;
		font-size: .90em;
		
		*/
		
	}
	.switch_form_half_table_title {
		height:47px !important;
		width: 276px;
		line-height:47px !important;
		background:#FFF;
		border-spacing: 0px;
		
		border-right:1px solid #ccc;
		border-bottom:1px solid #ccc;
		
		text-align:left;
		vertical-align: text-top;
		
		margin:0;
		padding:0;
	}

	.switch_form_half_table_other {
		height:47px !important;
		width: 201px;
		line-height:25px !important;
		background:#FFF;
		border-spacing: 0px;
		border-right:1px solid #ccc;
		text-align:center;
		margin:0;
		padding:0;
	}
	.switch_form_half_table_event {
		height:47px !important;
		width: 127px;
		line-height:47px !important;
		
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom:1px solid #ccc;
		
		text-align:left;
		vertical-align:text-top;
		background-color:rgba(255,255,255, 0.2);
		
		margin:0;
		padding:0;
	}
	.switch_form_label_event {
		height:25px !important;
		width:218px;
		line-height:25px !important;
		
		font-size: .90em;
		font-weight: 600;
		
		text-align:left;
		text-decoration:none;
		text-indent:45px;
		
		vertical-align:middle !important;
		
		border-spacing: 0px;
		border-right: 1px solid #ccc;
		border-left: hidden;
		border-top: hidden;
		border-bottom:1px solid #ccc;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position: 1% 50%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	/*


*/
	.event_messagedisplaytype_eventsettings_view{
		z-index: 0;
	  	height:173px !important;
		width: 280px !important;
		line-height:24px !important;
		position: absolute;
		
		
		margin-top: 0px;
		margin-left: 0px;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border: hidden;
		
		/*
		border-right:1px solid #ccc;
		border-bottom:1px solid #ccc;
		*/
		
		color:#888888;
		font-size: 1.20em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}

	.event_titletype_eventsettings_view{
		z-index: 0;
	  	height:45px !important;
		width: 280px !important;
		/*width: 100%;*/
		line-height:45px !important;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border: hidden;
		
		color:#888888;
		
		
		font-size: 1.20em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:middle !important;
		text-decoration:none;
		text-indent:2px;
		
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;

	  }
	.event_titletype_eventsettings_view_error{
		z-index: 0;
	  	height:45px !important;
		width: 280px !important;
		/*width: 100%;*/
		line-height:75px !important;
		
		background-color:rgba(255,255,255, 0.7);
		
		border-spacing: 0px;
		border-style:solid;
		border-width:thin;
		border-color:#F00;
		
		color:#888888;
		
		
		font-size: 1.20em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:text-bottom !important;
		text-decoration:none;
		text-indent:2px;
		
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}

/*********************************************/
/*********************************************/
/*
event_display_DIV01

*/
.event_item_type_absolute{
	z-index: 10;
    height:75px !important;
    width:115px;
    line-height:70px  !important;
    border-spacing: 0px;
	background:rgba(239,239,239, 0.5);
    border:hidden;
    display:inline-grid;
    margin-top:1px !important;
    margin-left:1px !important;
    vertical-align:top !important;
    text-align:center;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_type_active{
	border:4px solid rgba(4, 144, 242, 0.7) !important;
    /*
    border:1px solid rgba(209,41,209, 0.7) !important;
    background-color: green  !important;
    background:rgba(4, 144, 242, 0.7)  !important;
    */
    background-color: rgba(239,239,239, 0.7)  !important;
	color: white !important;
}
/*
#$CONTROL_MGMT_ARRAY['FORM_MENU_BUTTONS'] = array('STANDARD','POPUP','NOTIFICATION','MEDIA','OTHER');
$CONTROL_MGMT_ARRAY['FORM_MENU_BUTTONS'] = array('STANDARD','DISPLAY-ONE','DISPLAY-TWO','DISPLAY-THREE','UNDEFINED');
*/
.event_item_standard_type_button{
	/*
	event_item_theme_standard_phys
	event_item_theme_standard_virt
	*/
    height:73px; 
    width:115px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:4px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/event_details/standard_event_logo.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
	background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_standard_TWO_type_button{
	/*
	event_item_theme_standard_phys
	event_item_theme_standard_virt
	*/
    height:73px; 
    width:115px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:4px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/event_details/standard2_event_logo.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
	background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_DISPLAY_ONE_type_button{
	/*
	event_item_theme_popup_phys
	event_item_theme_popup_virt
	*/
    height:73px; 
    width:115px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:4px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/event_details/display1_event_logo.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
	background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_DISPLAY_TWO_type_button{
	/*
	event_item_theme_notice_phys
	event_item_theme_notice_virt
	*/
    height:73px; 
    width:115px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:4px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/event_details/display2_event_logo.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
	background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_DISPLAY_THREE_type_button{
	/*
	event_item_theme_media_phys
	event_item_theme_media_virt
	*/
   height:73px; 
    width:115px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:4px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/event_details/display3_event_logo.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
	background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_UNDEFINED_type_button{
	/*
	event_item_theme_other_phys
	event_item_theme_other_virt
	*/
    height:73px; 
    width:115px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:4px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
	background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/***************************************event_theme_display1_settings_div*/
.event_item_theme_STANDARD_list{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  border:1px solid rgba(209,209,209, 0.3);
  background: url(../images/event_details/standard_event_logo.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_STANDARD_TWO_list{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  border:1px solid rgba(209,209,209, 0.3);
  background: url(../images/event_details/standard2_event_logo.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_DISPLAY_ONE_list{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  border:1px solid rgba(209,209,209, 0.3);
  background: url(../images/event_details/display1_event_logo.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_DISPLAY_TWO_list{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  border:1px solid rgba(209,209,209, 0.3);
  background: url(../images/event_details/display2_event_logo.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_DISPLAY_THREE_list{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  border:1px solid rgba(209,209,209, 0.3);
  background: url(../images/event_details/display3_event_logo.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.event_item_theme_UNDEFINED_list{
  height:78px !important;
  width:75px !important;
  line-height:68px;
  vertical-align:top;
  text-align:center;
  margin-top: 1px;
  margin-left: 0px;
  border:1px solid rgba(209,209,209, 0.3);
  background: url(../images/buttons/butt_template.png) rgba(209,209,209, 0.7);
  background-size: 70px 70px;
  background-repeat: no-repeat;
  background-position:center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
/****************************************//****************************************/
/****************************************//***************************************
.event_item_popup_type_button{
    height:73px; 
    width:115px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:4px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/bkgrounds/POPUP_physical.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
	background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_notice_type_button{
    height:73px; 
    width:115px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:4px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/bkgrounds/NOTICE_physical.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
	background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_media_type_button{
   height:73px; 
    width:115px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:4px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/bkgrounds/MEDIA_physical.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
	background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_other_type_button{
    height:73px; 
    width:115px;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
    margin-left:4px;
	vertical-align:top;
	text-align:center;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-decoration:none;
	border:1px solid rgba(209,209,209, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
	background-position: 5% 45%; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
*/
/*********************************************/
/*********************************************/
/*
event_title_field
event_theme_default0
event_standard_DIV00
event_theme_default1
event_display_active
event_messagedisplaydatalabel_field_disabled
event_swipe_messagedisplaydatalabel_field_disabled
event_container_notification_bottom_div

event_mgmt_standard_content_background_DIV
event_mgmt_standard_description_content_background_DIV
*/
.event_multiple_top_left {
	height: 295px;
	width: 710px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_multiple_bottom_left {
	height: 330px !important;
    width: 710px !important;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}

.event_multiple_bottom_left_td1 {
	height: 328px !important;
    width: 162px !important;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_multiple_bottom_left_td2 {
	height: 328px !important;
    width: 548px !important;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
    /*
    event_standard_frame_rec_large_default
    event_standard_frame_rec_long_default
    event_recommendation_large_edit
    event_recommendation_long_edit
    */
}
.event_multiple_bottom_left_td2_top {
	height: 155px !important;
    width: 548px !important;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_multiple_bottom_left_td2_bottom {
	height: 173px !important;
    width: 548px !important;
	border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/*********************************************/
/*********************************************/
/*

*/
.event_container_event_top {
	height: 250px;
	width: 950px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_event_top_left {
	height: 290px;
	width: 710px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_event_top_right {
	height: 290px;
	width: 240px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_container_event_top_right_top {
    /*
    item_view_map_field
    event_messagedisplaytype_event_view_options_item_other
    event_mgmt_standard_content_background_DIV
    */
	height: 250px;
	width: 240px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_date_view_div{
	z-index: 200;
	height: 46px;
	width: 227px;
	line-height: 46px;
	position: absolute;
	top: 0px;
	margin-top: 7px;
	margin-left: 711px;
	border: hidden;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_date_view {
    height: 46px;
	width: 227px;
	line-height: 46px;
    display:inline-block;
    vertical-align:top;
	text-align: center;
    border: 1px solid rgba(239,239,239, 0.0);
    background-color:  rgba(239,239,239, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 1.50em;
	font-weight: 700;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	text-indent:0px;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;

    text-decoration:none;
    
}
/*
.event_container_event_top_right_bottom {
	height: 200px;
	width: 230px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.9);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
*/
/*********************************************/
/*********************************************/
.event_host_frame_absolute {
	height:41px !important;
	width:41px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	background-color: rgba(233,233,233, 0.5);
	display:inline-block;
	margin-top:1px;
	margin-left:3px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
event_host_option
*/
.event_host_option{
  	height:41px !important;
  	width:41px !important;
  	line-height:30px;
  	vertical-align:top;
  	text-align:left;
  	margin-top: 0px;
  	margin-left: 0px;
	border: hidden;
  	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3);
  	background-size: 40px 40px;
  	background-repeat: no-repeat;
	background-position: center;
  	-webkit-box-shadow: none !important;
  	-moz-box-shadow: none !important;
  	box-shadow: none !important;

}
/*********************************************/
/*********************************************/
/*
item_display_active

*/
.event_container_notification_top {
	height: 255px;
	width: 948px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_notification_parent_bottom_div {
	z-index: 20;
    margin-top: 3px;
  	margin-left: 2px;
	border: hidden;
  	background: rgba(255,255,255, 0.8);
}
.event_container_notification_bottom_div {
	z-index: 20;
    margin-top: 1px;
  	margin-left: 2px;
	border: hidden;
  	background: rgba(255,255,255, 0.8);
}
.event_container_notification_bottom_left {
	height: 363px;
	width: 588px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_notification_bottom_left_menu {
	height: 35px;
	width: 588px !important;
	line-height: 35px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_notification_bottom_right {
	height: 368px;
	width: 355px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_notification_bottom_right_other {
	height: 265px;
	width: 355px !important;
	line-height: 40px;
	border: hidden;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_notification_bottom_left_top_td {
    height:120px !important;
    width: 485px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 0px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_notification_bottom_ltt01 {
    height:120px !important;
    width: 363px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_notification_bottom_ltt02 {
    height:120px !important;
    width: 122px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_notification_bottom_left_bottom_td {
    height:204px !important;
    width: 484px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_notification_bottom_left_bottom_td01 {
    height:204px !important;
    width: 377px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(255,150,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_notification_bottom_left_bottom_td02 {
    height:204px !important;
    width: 105px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_notification_bottom_left_bottom_td02_01 {
    height:102px !important;
    width: 105px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_notification_bottom_left_bottom_td02_02 {
    height:102px !important;
    width: 105px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}




.event_container_notification_bottom_right {
	height: 368px;
	width: 355px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_notification_bottom_right_menu {
	height: 35px;
	width: 353px !important;
	line-height: 35px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,47, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_notification_bottom_right_other {
	height: 229px;
	width: 355px !important;
	line-height: 40px;
	border: hidden;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}


.event_container_notification_bottom_guidelines {
	height: 99px;
	width: 355px !important;
	line-height: 40px;
	border: 1px solid rgba(209,209,209, 0.3);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
		
	
	
	*/
}
.event_container_notification_bottom_guidelines_td{
    height:97px !important;
    width:116px !important;
    line-height: 97px;
    display:inline-block;
    vertical-align: top;
    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************************/
/*********************************************/
.event_container_standard_bottom_left {
	height: 330px;
    width: 610px !important;
    /*
    
    */
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}

.event_container_standard_bottom_left_stat_td{
	height: 105px !important;
	width: 100px !important;
	line-height: 40px;
	border: 0px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	
}
/*


.event_container_standard_bottom_left_menu {
	height: 35px !important;
	width: 588px !important;
	line-height: 35px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:middle;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_standard_bottom_left_menu01 {
	height: 34px;
	width: 485px !important;
	line-height: 30px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_standard_bottom_left_menu03 {
	height: 34px;
	width: 50px !important;
	line-height: 30px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:center;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_standard_bottom_left_menu02 {
	height: 34px;
	width: 50px !important;
	line-height: 30px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:center;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_standard_bottom_left_menu03 {
	height: 34px;
	width: 50px !important;
	line-height: 30px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:center;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
*/
/*********************************************/

.event_menu_location {
    height: 32px;
    width: 48px;
    line-height:30px;
    text-align:left;
    text-decoration:none;
    border:hidden;
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.8);
    background-position:center;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************************/
/*********************************************/
/*********************************************/
.checkin_compliance_main_table{
    height:332px !important;
    width:710px !important;
    line-height:60px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.checkin_compliance_main_table_a_row1{
    height:186px !important;
    width:710px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,47, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.checkin_compliance_main_table_b_row1{
    height:146px !important;
    width:710px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,47, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.checkin_compliance_main_table_b_td1{
    height:140px !important;
    width:280px !important;
	line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.checkin_compliance_main_table_b_td2{
    height:140px !important;
    width:200px !important;
	line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.checkin_compliance_main_table_b_td3{
    height:140px !important;
    width:225px !important;
	line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.checkin_compliance_main_half_table_td{
    height:60px !important;
    width:298px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.3);
    background: rgba(255,100,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.checkin_compliance_main_table_td{
    height:60px !important;
    width:298px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.3);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.checkin_compliance_main_table_td01-01{
    height:60px !important;
    width:200px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.3);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.checkin_compliance_main_table_td01-02{
    height:60px !important;
    width:200px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.3);
    background: rgba(255,100,255, 0.0);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.checkin_compliance_main_table_td-top{
    height:59px !important;
    width:206px !important;
    line-height:12px !important;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.checkin_compliance_main_table_td-bottom{
    height:59px !important;
    width:206px !important;
    line-height:12px !important;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.checkin_compliance_positive {
	z-index: 10;
	height:118px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_half_positive {
	z-index: 10;
	height:56px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_half_neutral {
	z-index: 10;
	height:56px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_notifications_positive {
	z-index: 10;
	height:118px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_notifications_neutral {
	z-index: 10;
	height:118px !important;
	width:296px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_anonymity_manage_positive {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_anonymity_manage_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_anonymity_manage_neutral {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.checkin_compliance_itinerary_manage_positive {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_itinerary_manage_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_itinerary_manage_neutral {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/*
checkin_compliance_half_positive
*/
.checkin_compliance_email_itinerary_manage_positive {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_email_itinerary_manage_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_email_itinerary_manage_neutral {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_email_messages_manage_positive {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_email_messages_manage_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
checkin_compliance_action_default
*/
.checkin_compliance_email_messages_manage_neutral {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_sms_itinerary_manage_positive {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_sms_itinerary_manage_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_sms_itinerary_manage_neutral {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_sms_messages_manage_positive {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_sms_messages_manage_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.checkin_compliance_sms_messages_manage_neutral {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.checkin_compliance_ticket_language{
	z-index: 10;
    height:40px !important;
    width:273px !important;
	line-height:12px !important;
	position: absolute;
	margin-top:95px;
	margin-left:2px;
	border:1px solid rgba(233,233,233, 0.5);
	background-color: rgba(255,255,255, 0.4);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.checkin_compliance_ticket_default{
    height:135px !important;
    width:275px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	position: absolute;
	margin-top:1px;
	margin-left:1px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 110px 110px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:#888888;
    font-size: 3.00em  !important;
    font-weight: 600;
	text-indent: 0px;
}
.checkin_compliance_ticket_active{
    height:135px !important;
    width:275px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	position: absolute;
	margin-top:1px;
	margin-left:1px;
	
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 110px 110px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 3.00em  !important;
    font-weight: 600;
	text-indent: 0px;
}
.checkin_compliance_seat_language{
	z-index: 10;
    height:40px !important;
    width:193px !important;
	line-height:12px !important;
	position: absolute;
	margin-top:95px;
	margin-left:2px;
	border:1px solid rgba(233,233,233, 0.5);
	background-color: rgba(255,255,255, 0.4);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.checkin_compliance_seat_default{
    height:135px !important;
    width:195px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	position: absolute;
	margin-top:1px;
	margin-left:1px;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 110px 110px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 3.00em  !important;
    font-weight: 600;
	text-indent: 0px;
}
.checkin_compliance_seat_active{
    height:135px !important;
    width:195px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	position: absolute;
	margin-top:1px;
	margin-left:1px;
	
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 110px 110px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 3.00em  !important;
    font-weight: 600;
	text-indent: 0px;
}
.checkin_compliance_count_language{
	z-index: 10;
    height:40px !important;
    width:217px !important;
	line-height:12px !important;
	position: absolute;
	margin-top:95px;
	margin-left:2px;
	border:1px solid rgba(233,233,233, 0.5);
	background-color: rgba(255,255,255, 0.4);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.checkin_compliance_count_default{
   height:135px !important;
    width:220px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	position: absolute;
	margin-top:1px;
	margin-left:1px;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
	background-size: 110px 110px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 3.00em  !important;
    font-weight: 600;
	text-indent: 0px;
}
.checkin_compliance_count_active{
   height:135px !important;
    width:220px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	position: absolute;
	margin-top:1px;
	margin-left:1px;
	
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 110px 110px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 3.00em  !important;
    font-weight: 600;
	text-indent: 0px;
}
.checkin_compliance_count_negative{
   height:135px !important;
    width:220px !important;
	line-height:12px !important;
    border:1px solid rgba(233,233,233, 0.5);
	position: absolute;
	margin-top:1px;
	margin-left:1px;
	
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 110px 110px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 3.00em  !important;
    font-weight: 600;
	text-indent: 0px;
}

.checkin_compliance_pending1 {
    height:46px;
    width:230px;
    display:inline-block;
    position:relative;
	background-color:rgba(255,255,255, 0.8);
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    background: url(../images/buttons/sys_security_option.png);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position: 1% 45%;
	font-size: 1em;
    font-weight: 600;
    text-align:center;
	vertical-align: middle;
    text-decoration:none;
} 
.checkin_compliance_cancel_checkin {
    height:46px;
    width:46px;
    display:inline-block;
    position:relative;
    border:hidden;
    text-align:center;
	vertical-align: middle;
    text-decoration:none;
	background: url(../images/buttons/sys_delete_buttonDefault.png)  rgba(239,239,239, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.checkin_compliance_action_default {
    height:46px;
    width:246px;
    display:inline-block;
    position:relative; 
    border:hidden;
	background-color:rgba(255, 255, 128, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 1em;
    font-weight: 600;
    text-align:center;
	vertical-align: middle;
    text-decoration:none;
}
.checkin_compliance_action_active {
    height:46px;
    width:246px;
    display:inline-block;
    position:relative; 
    border:hidden;
	background-color:rgba(10, 245, 92, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 1em;
    font-weight: 600;
    text-align:center;
	vertical-align: middle;
    text-decoration:none;
}
.checkin_compliance_action_closed {
    height:46px;
    width:246px;
    display:inline-block;
    position:relative; 
    border:hidden;
	background-color:rgba(252, 71, 71, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 1em;
    font-weight: 600;
    text-align:center;
	vertical-align: middle;
    text-decoration:none;
}
.checkin_compliance_action_time_requirement {
    height:46px;
    width:246px;
    display:inline-block;
    position:relative; 
    border:hidden;
	background-color:rgba(255, 255, 128, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 1em;
    font-weight: 600;
    text-align:center;
	vertical-align: middle;
    text-decoration:none;
}
.checkin_compliance_action_location_requirement {
    height:46px;
    width:246px;
    display:inline-block;
    position:relative; 
    border:hidden;
	background-color:rgba(255, 255, 128, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	font-size: 1em;
    font-weight: 600;
    text-align:center;
	vertical-align: middle;
    text-decoration:none;
}
.checkin_compliance_action_table {
    height:240px !important;
    width: 708px;
    margin:0;
    padding:0;
	text-align:left;
	vertical-align: top;
	background-color:rgba(255,255,255, 0.8);
	border-spacing: 0px;
    border:hidden;
}
.checkin_compliance_action_table_td1_1 {
    height:50px !important;
    width: 395px;
    margin:0;
    padding:0;
    text-align:center;
	vertical-align: middle;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.8);
}
.checkin_compliance_action_table_td1_2 {
    height:50px !important;
    width: 50px;
    margin:0;
    padding:0;
    text-align:center;
	vertical-align: middle;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.8);
}
.checkin_compliance_action_table_td1_3 {
    height:50px !important;
    width: 245px;
	margin:0;
    padding:0;
	text-align:center;
	vertical-align: middle;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.8);
}
.checkin_compliance_action_table_td2 {
    height:195px !important;
    width: 708px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.8);
    text-align:top;
    vertical-align:top;
    margin:0;
    padding:0;
}

.checkin_compliance_map_view{
    height:192px; 
    width:705px;
    display:inline-block;	
    vertical-align:top;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    background-color:rgba(239,239,239, 0.8);
    border: hidden;
}
.checkin_compliance_map_key_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:515px;

	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.checkin_compliance_map_search_div {
	z-index: 100;
	height:40px !important;
	width:510px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:5px;

	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.checkin_compliance_map_control_div {
	z-index: 10000;
	height:80px !important;
	width:100px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:400px;
	margin-left:10px;

	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(14,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.checkin_compliance_map_frame_div {
	z-index: 100000;
	height:60px !important;
	width:60px !important;
	/*
	display: inline-block;
	*/
	display: none;
	position: absolute;
	margin-top:300px;
	margin-left:0px;

	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,47, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

/*********************************************/
/*********************************************/
/*--------*//*--------*//*--------*//*--------*/
.event_edit_pop_td1 {
    height:10px !important;
    width:951px !important;
    border-spacing: 0px;
    border: hidden;
    display:inline-block;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*--------*//*--------*/
/*
event_edit_pop_td3
event_edit_pop_td2_1
event_edit_pop_td2_1
event_edit_pop_td2_1_ST
*/
.event_edit_pop_td2 {
    height:598px !important;
    width:951px !important;
    display:inline-block;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_pop_td2_1 {
    height:598px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_td2_1_1_count_div1 {
    /*
        event_edit_pop_td2_2_1_div2
        event_edit_description_field1_1
    
    */
    height:35px !important;
    width:45px !important;
    display: inline-block;

    margin-top: 15;
    margin-left: 545px;
    position: absolute;

    border-spacing: 0px;
    border: hidden;

    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_td2_1_1_count_div2 {
    height:35px !important;
    width:45px !important;
    display: inline-block;

    margin-top: 10;
    margin-left: 545px;
    position: absolute;

    border-spacing: 0px;
    border: hidden;

    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_td2_1_1_count_div3 {
    height:35px !important;
    width:45px !important;
    display: inline-block;

    margin-top: 5;
    margin-left: 545px;
    position: absolute;

    border-spacing: 0px;
    border: hidden;

    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_td2_1_top1{
    height:30px !important;
    width:600px !important;
    display:inline-block;
    position:absolute;
    margin-top: 10px;
    margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.event_edit_pop_td2_1_top2{
    height:30px !important;
    width:600px !important;
    display:inline-block;
    position:absolute;
    margin-top: 5px;
    margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.event_edit_pop_td2_1_top3{
    height:30px !important;
    width:600px !important;
    display:inline-block;
    position:absolute;
    margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
/**************************************/
/**************************************/
.event_edit_pop_td2_1_TT{
    /*
    event_edit_title_field1_1
    */
    height:80px !important;
    width:706px !important;
    display:inline-block;
    margin-top: 10px;
    margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
/**************************************/
/**************************************/
.event_edit_pop_td2_1_ST{
    height:122px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
/**************************************/
/**************************************/
.event_edit_pop_td2_2_ST{
    height:252px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
/**************************************/
/**************************************/
.event_edit_pop_td2_3_ST{
    height:90px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-top;
	text-align:center;
}

.event_edit_pop_td2_1_TB{
    height:122px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:text-bottom  !important;
	text-align:center;
}
.event_edit_pop_td2_2_TB{
    /*
        event_edit_description_field2_2
    */
    height:342px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.event_edit_pop_td2_1_B{
    /*
        event_edit_description_field3_1
    */
    height:464px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}




.event_edit_pop_td2_2 {
    height:598px !important;
    width:239px !important;
    display:inline-block;
    margin-top: 0px;
	margin-left: 2px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(239,239,239, 0.0);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_pop_td2_2_1 {
    height:70px !important;
    width:79px !important;
    border-spacing: 0px;
    border:hidden;
    display:inline-block;
    background-color:rgba(239,239,239, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

.event_edit_pop_td2_2_2 {
    height:520px !important;
    width:238px !important;
    border-spacing: 0px;
    border:hidden;
    display:inline-block;

    vertical-align:top !important;
    text-align:left !important;

    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

.event_edit_pop_map_td1 {
    height:598px !important;
    width:951px !important;
    display:inline-block;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_pop_map_td1_1 {
    height:598px !important;
    width:706px !important;
    display:inline-block;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(239,239,239, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_map_td1_2 {
    /*
    event_edit_pop_map_div1_2_1
    */
    
    height:588px !important;
    width:239px !important;
    display:inline-block;
    margin-top: 5px;
	margin-left: 670px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.0);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_map_td1_2_1 {
    height:137px !important;
    width:239px !important;
    display:inline-block;
    margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 1.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_map_div1_2_1 {
    height:592px !important;
    width:244px !important;
    display:none;
    margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border:3px solid rgba(204,204,204, 0.5);
    background-color:rgba(239,239,239, 1.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_map_div1_2_1_logo {
    height:65px !important;
    width:65px !important;
    display: inline-block;
    position: absolute;
    margin-top: 10px;
	margin-left: 10px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    vertical-align:middle;
    text-align:center;
    background: url(../images/buttons/sys_pin_buttonDefault.png) rgba(255,255,255, 1.0);
    background-size: 55px 55px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_map_div1_2_1_status {
    height:30px !important;
    width:130px !important;
    line-height:30px;
    display: inline-block;
    position: absolute;
    margin-top: 5px;
	margin-left: 100px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-align:center;
	text-decoration:none;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.95em;
    font-weight: 500;
}
.event_edit_pop_map_div1_2_1_title {
    height:85px !important;
    width:225px !important;
    line-height:20px;
    display: inline-block;
    position: absolute;
    margin-top: 40px;
	margin-left: 5px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-align:left;
	text-decoration:none;
    /*
    
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 1.45em;
    font-weight: 500;
    word-wrap: break-word !important;
}
.event_edit_pop_map_td1_2_2 {
    height:50px !important;
    width:239px !important;
    display:inline-block;
    margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 1.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_map_div1_2_2_time {
    height:47px !important;
    width:235px !important;
    line-height:47px;
    display: inline-block;
    position: absolute;
    margin-top: 1px;
	margin-left: 2px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-align:left;
	text-decoration:none;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.85em;
    font-weight: 500;
}
.event_edit_pop_map_td1_2_3 {
    height:40px !important;
    width:239px !important;
    display:inline-block;
    margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 1.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_map_div1_2_3_address1 {
    height:37px !important;
    width:235px !important;
    line-height:37px;
    display: inline-block;
    position: absolute;
    margin-top: 1px;
	margin-left: 2px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-align:left;
	text-decoration:none;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.85em;
    font-weight: 500;
}
.event_edit_pop_map_td1_2_4 {
    height:40px !important;
    width:239px !important;
    display:inline-block;
    margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 1.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_map_div1_2_3_address2 {
    height:37px !important;
    width:235px !important;
    line-height:37px;
    display: inline-block;
    position: absolute;
    margin-top: 1px;
	margin-left: 2px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-align:left;
	text-decoration:none;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.85em;
    font-weight: 500;
}
.event_edit_pop_map_td1_2_5 {
    height:40px !important;
    width:239px !important;
    display:inline-block;
    margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 1.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_map_div1_2_3_address3 {
    height:37px !important;
    width:235px !important;
    line-height:37px;
    display: inline-block;
    position: absolute;
    margin-top: 1px;
	margin-left: 2px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-align:left;
	text-decoration:none;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.85em;
    font-weight: 500;
}
.event_edit_pop_map_td1_2_6 {
    height:40px !important;
    width:239px !important;
    display:inline-block;
    margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 1.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_map_td1_2_7 {
    height:240px !important;
    width:239px !important;
    display:inline-block;
    margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 1.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_edit_pop_map_div1_2_7_recomm {
    height:113px !important;
    width:113px !important;
    line-height:47px;
    display: inline-block;
    margin-top: 2px;
	margin-left: 2px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.9);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-align:left;
}

.event_edit_pop_map_td2 {
    height:120px !important;
    width:951px !important;
    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;
    display:inline-block;
    background-color:rgba(255,255,255, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/****************************************/
/*
event_edit_description_button_default3
event_edit_pop_map_div1_2_1_logo
*/
.event_edit_description_button_default1 {
    height: 70px;
    width: 77px;
    line-height:12px;
    margin-top:0px;
	margin-left:1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(204,204,204, 0.7);
    background: url(../images/buttons/event_description_standard.png) rgba(255,255,255, 0.7) center;
    background-size: 60px 60px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
}
.event_edit_description_button_default2 {
    height: 70px;
    width: 77px;
    line-height:12px;
    margin-top:0px;
	margin-left:1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(204,204,204, 0.7);
    background: url(../images/buttons/event_description_title_body.png) rgba(255,255,255, 0.7) center;
    background-size: 60px 60px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
}
.event_edit_description_button_default3 {
    height: 70px;
    width: 77px;
    line-height:12px;
    margin-top:0px;
	margin-left:1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(204,204,204, 0.7);
    background: url(../images/buttons/event_description_body.png) rgba(255,255,255, 0.7) center;
    background-size: 60px 60px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
}
.event_edit_description_button_active1 {
    height: 70px;
    width: 77px;
    line-height:12px;
    margin-top:0px;
	margin-left:1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(204,204,204, 0.7);
    background: url(../images/buttons/event_description_standard.png) rgba(11,183,255, 0.7) center;
    background-size: 60px 60px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
}
.event_edit_description_button_active2 {
    height: 70px;
    width: 77px;
    line-height:12px;
    margin-top:0px;
	margin-left:1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(204,204,204, 0.7);
    background: url(../images/buttons/event_description_title_body.png) rgba(11,183,255, 0.7) center;
    background-size: 60px 60px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
}
.event_edit_description_button_active3 {
    height: 70px;
    width: 77px;
    line-height:12px;
    margin-top:0px;
	margin-left:1px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(204,204,204, 0.7);
    background: url(../images/buttons/event_description_body.png) rgba(11,183,255, 0.7) center;
    background-size: 60px 60px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .90em;
    font-weight: 600;
    text-align:center;
    text-indent:0px;
    text-decoration:none;
}
.event_edit_description_field{
    height:400px !important;
    width: 702px;
    line-height:25px !important;
    background-color:#EEEEEE;
    border-spacing: 0px;
    border:hidden;
    font-size: 1.20em;
    font-weight: 500;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
/****************************************/
.event_edit_title_field1_1{
    height:75px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:5px;
	margin-left:0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/standard_parent_description.png) rgba(255,255,255, 0.7);
    background-position:1% 20%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.40em;
    font-weight: 600;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
    word-wrap: break-word !important;
}
/****************************************/
.event_edit_description_field1_1{
    height:80px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:40px;
	margin-left:0px;
    
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 2.00em;
    font-weight: 600;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
.event_edit_description_field1_2{
    height:210px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:35px;
	margin-left:0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 500;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
.event_edit_description_field1_3{
    height:50px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:30px;
	margin-left:0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 500;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
/****************************************/
.event_edit_description_field2_1{
    height:80px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:40px;
	margin-left:0px;
    
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 2.00em;
    font-weight: 600;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
.event_edit_description_field2_2{
    height:297px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:35px;
	margin-left:0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 500;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
/****************************************/
.event_edit_description_field3_1{
    height:415px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:40px;
	margin-left:0px;
    
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 2.00em;
    font-weight: 600;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
/****************************************/
.item_event_edit_title_field1_1{
    height:75px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:5px;
	margin-left:0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/standard_child_description.png) rgba(255,255,255, 0.7);
    background-position:1% 20%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.40em;
    font-weight: 600;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
    word-wrap: break-word !important;
}
/****************************************/
.item_event_edit_description_field1_1{
    height:80px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:40px;
	margin-left:0px;
    
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 2.00em;
    font-weight: 600;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
.item_event_edit_description_field1_2{
    height:210px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:35px;
	margin-left:0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 500;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
.item_event_edit_description_field1_3{
    height:50px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:30px;
	margin-left:0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 500;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
/****************************************/
.item_event_edit_description_field2_1{
    height:80px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:40px;
	margin-left:0px;
    
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 2.00em;
    font-weight: 600;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
.item_event_edit_description_field2_2{
    height:297px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:35px;
	margin-left:0px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.20em;
    font-weight: 500;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
/****************************************/
.item_event_edit_description_field3_1{
    height:415px !important;
    width: 600px;
    line-height:25px !important;
    margin-top:40px;
	margin-left:0px;
    
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:1% 1%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 2.00em;
    font-weight: 600;

    text-indent: 5px;

    text-align:left;
    text-decoration:none;

    vertical-align:top;
    resize: none;
    overflow: auto;
}
/****************************************/
.event_edit_pop_td2_2_3 {
    height:42px !important;
    width:660px !important;
    border-spacing: 0px;
    border:hidden;
    display:inline-block;
    vertical-align: text-top !important;
    text-align: left !important;
    background-color:rgba(239,239,239, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_pop_td2_3 {
    height:354px !important;
    width:210px !important;
    border-spacing: 0px;
    border: hidden;
    display:inline-block;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_pop_td2_3_div1 {
    height:35px !important;
    width:575px !important;
    display: inline-block;
    margin-top: 0;
    border-spacing: 0px;
    border: hidden;

    background-color:rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_pop_td2_3_div_button1 {
    height:42px !important;
    width:130px !important;
    display:inline-block;
    margin-top: 0;
    margin-left: 0;
    position: absolute;

    vertical-align:middle !important;
    text-align:center !important;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_pop_td2_3_div_button2 {
    height:42px !important;
    width:130px !important;
    line-height: 42px;
    display:inline-block;
    margin-top: 0;
    margin-left: 130;
    position: absolute;

    vertical-align:middle;
    text-align:center !important;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*--------*//*--------*/
.event_edit_pop_td3 {
    height:120px !important;
    width:951px !important;
    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;
    display:inline-block;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*--------*//*--------*//*--------*//*--------*/
.event_edit_title_pop_td1 {
    height:220px !important;
    width:951px !important;
    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;
    display:inline-block;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    
} 
/*--------*//*--------*/
.event_edit_title_pop_td2 {
    height:154px !important;
    width:951px !important;
    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;
    display:inline-block;
    background-color:rgba(255,255,235, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_title_pop_td2_1 {
    height:154px !important;
    width:210px !important;
    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;
    display:inline-block;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_title_pop_td2_2 {
    height:154px !important;
    width:537px !important;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);
    display:inline-block;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_title_pop_td2_2_1 {
    height:35px !important;
    width:537px !important;
    border-spacing: 0px;
    border:hidden;
    display:inline-block;
    background-color:aqua;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_title_pop_td2_2_1_div1 {
    height:35px !important;
    width:495px !important;
    display: inline-block;
    margin-top: 0;
    margin-left: 0;
    position: absolute;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_title_pop_td2_2_1_div2 {
    height:35px !important;
    width:45px !important;
    display: inline-block;

    margin-top: 0;
    margin-left: 495px;
    position: absolute;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*


*/
.event_edit_title_pop_td2_2_2 {
    height:78px !important;
    width:537px !important;
    border-spacing: 0px;
    border:hidden;
    display:inline-block;

    vertical-align:middle !important;
    text-align:center !important;

    background-color:beige;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*

*/
.event_edit_title_field{
    height:78px !important;
    width: 535px;
    line-height:78px !important;
    background-color:#EEEEEE;
    border-spacing: 0px;
    border:hidden;
    font-size: 2.00em;
    font-weight: 500;
    text-align:left;
    text-decoration:none;
    text-indent:2px;
    vertical-align:middle;
    resize: none;
    overflow: auto;
    word-wrap: break-word !important;
  }
.event_edit_title_pop_td2_2_3 {
    height:42px !important;
    width:537px !important;
    border-spacing: 0px;
    border:hidden;
    display:inline-block;
    vertical-align: text-top !important;
    text-align: left !important;
    background-color:crimson;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_title_pop_td2_3 {
    height:154px !important;
    width:210px !important;
    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;
    display:inline-block;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_title_pop_td2_3_div1 {
    height:42px !important;
    width:277px !important;
    display: inline-block;
    margin-top: 0;
    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_title_pop_td2_3_div_button1 {
    height:42px !important;
    width:130px !important;
    display:inline-block;
    margin-top: 0;
    margin-left: 0;
    position: absolute;

    vertical-align:middle !important;
    text-align:center !important;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_edit_title_pop_td2_3_div_button2 {
    height:42px !important;
    width:130px !important;
    line-height: 42px;
    display:inline-block;
    margin-top: 0;
    margin-left: 130;
    position: absolute;

    vertical-align:middle;
    text-align:center !important;

    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;

    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*--------*//*--------*/
.event_edit_title_pop_td3 {
    height:220px !important;
    width:951px !important;
    border-spacing: 0px;
    border-right: hidden;
    border-left: hidden;
    border-top: hidden;
    border-bottom: hidden;
    display:inline-block;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
/*********************************************/
/*********************************************/
/*
DISPLAY FRAME STRUCTURE
event_container_event_top_left
item_display_active
item_event_edit_title_field1_1
background: url(../images/buttons/standard_parent_home.png) rgba(255,255,255, 0.7);
 event_messagedisplaytype_event_view_options_full
        ../images/buttons/standard_child_home.png

event_item_return_option
*/
.event_display_home_div{
	z-index: 100;
	height: 45px;
	width: 45px !important;
	line-height: 45px;
	position: absolute;
	top: 0px;
	margin-top: 1px;
	margin-left: 1px;
	border: hidden;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_display {
	height: 599px;
	width: 950px !important;
	background-color:rgba(255,255,255, 0.3);
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_container_event_display_left {
	height: 623px;
	width: 710px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_container_event_display_right {
	height: 623px;
	width: 240px !important;
	margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************//***************//***************/
/***************//***************//***************/
/*

event_theme_display1_settings_div
event_theme_display1
*/
.event_mgmt_display_DIV {
    z-index:100;
	height: 45px;
	width: 560px !important;
    position: absolute;
    margin-top: 5px;
	margin-left: 130px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_mgmt_display3_DIV {
    z-index:100;
	height: 45px;
	width: 575px !important;
    position: absolute;
    margin-top: 5px;
	margin-left: 100px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***********************************/
.event_mgmt_display1_DIV1 {
    z-index:100;
	height: 45px;
	width: 180px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_mgmt_display1_DIV2 {
    z-index:100;
	height: 45px;
	width: 120px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_mgmt_display1_DIV3 {
    z-index:100;
	height: 45px;
	width: 60px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.1);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***********************************/
.event_mgmt_display2_DIV1 {
    z-index:100;
	height: 45px;
	width: 90px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_mgmt_display2_DIV2 {
    /*
    event_theme_display2_settings_div
    event_display1_upload_frame_div
    */
    z-index:100;
	height: 45px;
	width: 135px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_mgmt_display2_DIV3 {
    z-index:100;
	height: 45px;
	width: 135px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***********************************/
.event_mgmt_display3_DIV1 {
    /*
    event_mgmt_display3_DIV
    event_display2_action_frame_div
    event_theme_display3_settings_div
    event_theme_display3_1
    */
    z-index:100;
	height: 45px;
	width: 130px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_mgmt_display3_DIV2 {
    /*
    
    */
    z-index:100;
	height: 45px;
	width: 130px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_mgmt_display3_DIV3 {
    z-index:100;
	height: 45px;
	width: 130px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***********************************/

/***********************************/
.event_mgmt_display1_DIV4 {
    z-index:100;
	height: 45px;
	width: 200px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:right;
}
.event_mgmt_content_background_DIV {
    /*
        event_checkin_frame_div
        event_social_display_DIV
        event_description_display_DIV
    event_mgmt_standard_description_content_background_DIV
        event_media_display_DIV
        item_view_display_active
    event_container_notification_bottom_div
    */
    z-index:100;
	height: 222px;
	width: 530px !important;
    position: absolute;
    /*
    margin-top: 193px;
    */
    margin-top: 215px;
	margin-left: 170px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_mgmt_standard_content_background_DIV {
    /*
    event_option_main_table_td_type_div
    */
    z-index:100;
	height: 75px;
	width: 530px !important;
    position: absolute;
    margin-top: 215px;
	margin-left: 170px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_mgmt_standard_description_content_background_DIV{
    /*
    event_description_display_DIV
    event_messagedisplaytype_event_view_options_item
    event_media_display_DIV_td01
    */
    z-index:100;
	height: 255px;
	width: 420px !important;
    position: absolute;
    margin-top: 300px;
	margin-left: 172px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************/
/***************/
.event_mgmt_standard_content_parent_background_DIV {
    /*
    event_social_standard_parent_DIV
    */
    z-index:100;
	height: 50px;
	width: 530px !important;
    position: absolute;
    margin-top: 215px;
	margin-left: 170px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_mgmt_standard_description_content_parent_background_DIV{
    /*
    event_description_standard_parent_DIV
    event_media_standard_parent_DIV
    */
    z-index:100;
	height: 155px;
	width: 420px !important;
    position: absolute;
    margin-top: 300px;
	margin-left: 172px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/*********************************************/
/*********************************************/
.event_checkin_frame_div {
    z-index:101 !important;
    height:25px !important;
    width: 525px !important;
	position: absolute;
    margin-top: 0px;
	margin-left: 0px;
	text-align:left;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_checkin_start {
	height:21px !important;
	width:264px !important;
	line-height: 20px;
	margin-top:1px;
	margin-left:0px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/event_details/event_item_start_icon.png) rgba(255,255,255, 0.8);
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    text-indent: 15px;
    text-align:left;
	text-decoration:none;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.90em;
    font-weight: 500;
}
.event_item_checkin_end {
	
	height:21px !important;
	width:264px !important;
	line-height: 20px;
	margin-top:1px;
	margin-left:264px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/event_details/event_item_stop_icon.png) rgba(255,255,255, 0.8);
	background-size: 20px 20px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    text-indent: 15px;
    text-align:left;
	text-decoration:none;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.90em;
    font-weight: 500;
}
.event_item_checkin_float_div {
	z-index: 20;
	height:84px !important;
	width:120px !important;
	line-height: 20px;
	margin-top:-90px;
	margin-left:360px;
	position: absolute;
    border-radius: 25px;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	text-align:left;
	vertical-align:top;
	color:rgba(15, 5, 26, 0.6);
	background: url(../images/buttons/butt_template.png) rgba(11,255,255, 0.4);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_item_checkin_default {
	z-index: 20;
	height:81px !important;
	width:117px !important;
	line-height: 20px;
	margin-top:1px;
	margin-left:1px;
    border-radius: 25px;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_checkin_pending {
	z-index: 20;
	height:81px !important;
	width:117px !important;
	line-height: 20px;
	margin-top:1px;
	margin-left:1px;
    border-radius: 25px;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_checkin_active {
	z-index: 20;
	height:81px !important;
	width:117px !important;
	line-height: 20px;
	margin-top:1px;
	margin-left:1px;
    border-radius: 25px;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_checkin_void {
	z-index: 20;
	height:81px !important;
	width:117px !important;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
	margin-left:1px;
    border-radius: 25px;
	border-spacing: 0px;
	border: thin solid rgba(252, 71, 71, 0.3);
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/***************/
/***************/
.event_standard_action_checkin_div {
	z-index: 2000;
	height:60px !important;
	width:60px !important;
	line-height: 20px;
	margin-top:310px;
	margin-left:460px;
	position: absolute;
    border-radius: 25px;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/clear_img.png) rgba(11,255,255, 0.4);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_action_stream_div {
	z-index: 2000;
	height:60px !important;
	width:60px !important;
	line-height: 20px;
	margin-top:380px;
	margin-left:460px;
	position: absolute;
    border-radius: 25px;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/clear_img.png) rgba(255,150,255, 0.4);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_display_action_checkin_div {
	z-index: 2000;
	height:60px !important;
	width:60px !important;
	line-height: 20px;
	margin-top:300px;
	margin-left:440px;
	position: absolute;
    border-radius: 25px;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/clear_img.png) rgba(11,255,255, 0.4);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_display_action_stream_div {
	z-index: 2000;
	height:60px !important;
	width:60px !important;
	line-height: 20px;
	margin-top:370px;
	margin-left:440px;
	position: absolute;
    border-radius: 25px;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/clear_img.png) rgba(255,150,255, 0.4);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_action_default {
	z-index: 20;
	height:57px !important;
	width:57px !important;
	line-height: 20px;
	margin-top:1px;
	margin-left:1px;
    border-radius: 25px;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    color:black;
    font-size: 0.70em;
    font-weight: 500;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
/***************/
/***************/
.event_social_standard_parent_DIV {
    z-index:100;
	height: 45px;
	width: 530px !important;
    position: absolute;
    margin-top: 1px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.1);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************/
.event_social_display_DIV {
    z-index:100;
	height: 45px;
	width: 530px !important;
    position: absolute;
    margin-top: 25px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.1);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************/
.event_social_display_DIV {
    z-index:100;
	height: 45px;
	width: 530px !important;
    position: absolute;
    margin-top: 25px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.1);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_social_display_DIV1 {
    /*
    child_standard_social_div
    
    */
    z-index:100;
	height: 45px;
	width: 230px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.1);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_social_display_DIV2 {
    z-index:100;
	height: 45px;
	width: 0px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(239,45,239, 0.0);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_social_display_DIV3 {
    z-index:100;
	height: 45px;
	width: 135px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.1);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_social_display_DIV4 {
    /*
    event_item_register_active
    */
    z-index:100;
	height: 45px;
	width: 160px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.1);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************/
/***************/
.event_description_standard_parent_DIV {
    z-index:100;
	height: 150px;
	width: 370px !important;
    position: absolute;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_description_display_DIV {
    z-index:100;
	height: 150px;
	width: 370px !important;
    position: absolute;
    margin-top: 70px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_description_standard_DIV {
    /*
    event_messagedisplaytype_event_view_options_item
    =
    */
    z-index:100;
	height: 150px;
	width: 415px !important;
    position: absolute;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************/
/***************/
.event_media_standard_parent_DIV {
    z-index:100;
	height: 150px;
	width: 160px !important;
    position: absolute;
    margin-top: 0px;
	margin-left: 370px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_media_standard_parent_DIV_td01 {
    /*
    ls_menu_event_item_half_display_field
    media_menu_event_item_half_display_other
    media_menu_event_item_half_option_other
    event_display_left_td_metrics
    event_host_frame_absolute
    */
    z-index:100;
	height: 48px;
	width: 160px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_media_standard_parent_DIV_td02 {
    /*
    media_menu_event_pb_item_third_display_other
    */
    z-index:100;
	height: 100px;
	width: 160px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_media_display_DIV {
    z-index:100;
	height: 150px;
	width: 160px !important;
    position: absolute;
    margin-top: 70px;
	margin-left: 370px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_media_display_DIV_td01 {
    /*
    ls_menu_event_item_half_display_field
    media_menu_event_item_half_display_other
    media_menu_event_item_half_option_other
    event_display_left_td_metrics
    event_host_frame_absolute
    */
    z-index:100;
	height: 48px;
	width: 160px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_media_display_DIV_td02 {
    /*
    media_menu_event_pb_item_third_display_other
    */
    z-index:100;
	height: 100px;
	width: 160px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}

.event_media_standard_DIV {
    z-index:100;
	height: 100px;
	width: 420px !important;
    position: absolute;
    margin-top: 151px;
	margin-left: 1px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_media_standard_DIV_td01 {
    /*
    event_description_standard_DIV
    event_display1_action_frame_div
    */
    z-index:100;
	height: 100px;
	width: 208px !important;
    margin-top: 5px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.4);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_media_standard_DIV_td02 {
    /*
    media_menu_event_pb_item_third_display_other
    */
    z-index:100;
	height: 100px;
	width: 208px !important;
    margin-top: 5px;
	margin-left: 1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.5);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************//***************//***************/
/***************//***************//***************/
.event_standard_DIV00 {
    z-index:0;
	height: 290px;
	width: 710px !important;
    position: absolute;
    margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(239,239,239, 0.9);
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
    /*
    event_container_event_top_left
    event_container_standard_bottom_left
    
	background-size:contain;
    event_theme_display1
    */
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************//***************//***************/
/***************//***************//***************/
.event_standard_DIV01 {
    z-index:0;
	height: 290px;
	width: 710px !important;
    position: absolute;
    margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(239,239,239, 0.9);
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
    /*
    event_container_event_top_left
    event_container_standard_bottom_left
    
	background-size:contain;
    event_theme_display1
    */
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************//***************//***************/
/***************//***************//***************/
.event_display_DIV01 {
    z-index:0;
	height: 620px;
	width: 705px !important;
    position: absolute;
    margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(239,239,239, 0.9);
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
    /*
	background-size:contain;
    event_theme_display1
    */
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_standard_screen_DIV00 {
    z-index:0;
	height: 295px;
	width: 710px !important;
    position: absolute;
    top:0px;
    /*
    margin-top: -1px;
    */
	margin-left: -1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_standard_screen_DIV01 {
    z-index:0;
	height: 300px;
	width: 710px !important;
    position: absolute;
    top:0px;
    /*
    margin-top: -1px;
    */
	margin-left: -1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_display_screen_DIV01 {
    z-index:0;
	height: 621px;
	width: 706px !important;
    position: absolute;
    top:0px;
    /*
    margin-top: -1px;
    */
	margin-left: -1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_display_screen_DIV02 {
    z-index:0;
	height: 621px;
	width: 706px !important;
    position: absolute;
    top:0px;
	margin-left: -1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************//***************//***************/
/***************/
.event_standard0_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard1_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard2_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard3_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_display1_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_display2_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_display3_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_display4_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_display5_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_display6_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_display7_action_frame_div {
	z-index:5;
	height: 52px;
	width: 128px !important;
    display:inline-block;
	position:absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(239,239,239, 0.8);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_display1_upload_frame_div {
    /*
    event_mgmt_display_DIV1
    event_mgmt_display_DIV2
    event_mgmt_display_DIV3
    */
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px;
	width: 50% !important;
	margin-top: 1px;
	margin-left: 1px;
	background-color:rgba(255,255,255, 0.0);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_display1_delete_frame_div {
    /*
    event_theme_delete
    */
	z-index:5;
	display:inline-block;
	position:absolute;
	height: 45px;
	width: 50% !important;
	margin-top: 1px;
	margin-left: 50%;
	background-color:rgba(255,255,255, 0.0);
	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_theme_standard1_settings_div {
    z-index:100 !important;
    height: 45px;
	width: 45px !important;
    display:inline-block;
    position:absolute;
    margin-top: 0px;
	margin-left: 339px;
    border:hidden;
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_standard2_settings_div {
    z-index:100 !important;
    height: 45px;
	width: 45px !important;
    display:inline-block;
    position:absolute;
    margin-top: 0px;
	margin-left: 429px;
    border:hidden;
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_display1_settings_div {
    z-index:100 !important;
    height: 45px;
	width: 45px !important;
    display:inline-block;
    position:absolute;
    margin-top: 0px;
	margin-left: 339px;
    border:hidden;
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_display2_settings_div {
    z-index:100 !important;
    height: 45px;
	width: 45px !important;
    display:inline-block;
    position:absolute;
    margin-top: 0px;
	margin-left: 429px;
    border:hidden;
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_display3_settings_div {
    /*

    */
    z-index:100 !important;
    height: 45px;
	width: 45px !important;
    display:inline-block;
    position:absolute;
    margin-top: 0px;
	margin-left: 548px;
    border:hidden;
    background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_file_upload_button {
	height:45px !important;
	width:100% !important;
	display:inline-block;
	position:absolute;
    margin-top: 1px;
	margin-left: 1px;
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.6);
	background: url(../images/buttons/sys_upload_graphic.png) rgba(255,255,255, 0.7);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_file_delete_button {
    height:45px;
    width:100% !important;
    display:inline-block;
    position:absolute;
    margin-top: 1px;
	margin-left: 1px;
    vertical-align:top;
    border: 1px solid rgba(239,239,239, 0.6);
    background: url(../images/buttons/sys_delete_buttonDefault.png)  rgba(255,255,255, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_file_settings_button {
    height:45px;
    width:45px;
    display:inline-block;
    position:relative;
    vertical-align:top;
    border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_settings {
    height:45px;
    width:45px;
    display:inline-block;
    position:relative;
    vertical-align:top;
    border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_default0 {
    /*
    event_multiple_top_left
    event_standard_DIV00
    event_standard_screen_DIV00
    event_container_event_top_left
    event_display_DIV01
    
    */
    height: 290px;
    width: 710px;
    position:absolute;
    display:inline-block;
    top: 1px !important;
    margin-top: -1px;
	margin-left: -1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_default1 {
    /*
    event_container_event_top_left
    event_display_DIV01
    */
    height: 290px;
    width: 710px;
    position:absolute;
    display:inline-block;
    top: 1px !important;
    margin-top: -1px;
	margin-left: -1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_default2 {
    /*
    event_container_event_top_left
    event_display_DIV01
    */
    height: 290px;
    width: 450px;
    position:absolute;
    display:inline-block;
    top: 1px !important;
    margin-top: -1px;
	margin-left: -1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_default3 {
    /*
    event_container_event_top_left
    event_display_DIV01
    */
    height: 290px;
    width: 255px;
    position:absolute;
    display:inline-block;
    top: 1px !important;
    margin-top: -1px;
	margin-left: 444px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_display1 {
    height: 620px;
	width: 705px !important;
    position:absolute;
    display:inline-block;
    top: 1px !important;
    margin-top: -1px;
	margin-left: -1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    /*
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
    */
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_display2_1 {
    height: 311px;
	width: 705px !important;
    position:absolute;
    display:inline-block;
    top: 1px !important;
    margin-top: -1px;
	margin-left: -1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    /*
    event_mgmt_display2_DIV2
    event_theme_display2_settings_div
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
    */
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_display2_2 {
    height: 311px;
	width: 705px !important;
    position:absolute;
    display:inline-block;
    margin-top: 297px;
	margin-left: -5px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    /*
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
    */
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_display3_1 {
    height: 207px;
	width: 705px !important;
    position:absolute;
    display:inline-block;
    top: 1px !important;
    margin-top: -1px;
	margin-left: -1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    /*
    event_mgmt_display2_DIV2
    event_theme_display2_settings_div
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
    */
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_display3_2 {
    height: 207px;
	width: 705px !important;
    position:absolute;
    display:inline-block;
    margin-top: 193px;
	margin-left: -5px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    /*
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
    */
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_display3_3 {
    height: 207px;
	width: 705px !important;
    position:absolute;
    display:inline-block;
    margin-top: 400px;
	margin-left: -5px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    /*
	background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
    */
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_theme_display_selected {
    z-index:-1;
    border: 3px solid rgba(4, 144, 242, 0.7);
}
/**************event_display_DIV01*/
/***************//***************//***************/
.event_display_left_td {
	height: 623px;
	width: 608px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************/
.event_display_left_td1 {
	height: 85px;
	width: 102px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_display_left_td_title {
	height: 210px;
	width: 102px !important;
    line-height:210px;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_display_left_td_metrics {
	height: 315px;
	width: 152px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
}
/***************/
.event_display_right_td {
	height: 623px;
	width: 240px !important;
	margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_display_right_td_map {
	height: 187px;
	width: 240px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_display_right_td2 {
	height: 15px;
	width: 240px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,2, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_display_right_td_ads {
	height: 332px;
	width: 240px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_display_right_td_guideline {
	height: 100px;
	width: 240px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,200, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/****************/
.item_display_map_field{
    height: 187px;
	width: 236px !important;
	line-height: 30px !important;
    background:rgba(239,239,239, 0.5);
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
}

.event_display_right_div_guideline {
    z-index:1000;
	height: 100px;
	width: 370px !important;
    position: absolute;
    margin-top: 0px;
	margin-left: -135px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:left;
}
/*********************************************/
/*********************************************/
/*
SPECIAL EVENTDISPLAY FRAME STRUCTURE
event_container_event_top_left

*/
.specialevent_container_display {
	height: 599px;
	width: 950px !important;
	background-color:rgba(255,255,255, 0.3);
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.specialevent_container_event_display_left {
	height: 623px;
	width: 710px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.specialevent_container_event_display_right {
	height: 623px;
	width: 240px !important;
	margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************/
.specialevent_display_DIV01 {
    z-index:0;
	height: 620px;
	width: 705px !important;
    position: absolute;
    margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(239,239,239, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************/
.specialevent_display_left_td {
	height: 623px;
	width: 608px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************/
.specialevent_display_left_td1 {
	height: 85px;
	width: 102px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.specialevent_display_left_td_title {
	height: 210px;
	width: 102px !important;
    line-height:210px;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.specialevent_display_left_td_metrics {
	height: 315px;
	width: 102px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/***************/
.specialevent_display_right_td {
	height: 623px;
	width: 240px !important;
	margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.9);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.specialevent_display_right_td1 {
	height: 170px;
	width: 240px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(2,255,255, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.specialevent_display_right_td2 {
	height: 50px;
	width: 240px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,2, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.specialevent_display_right_td3 {
	height: 300px;
	width: 240px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,2,255, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.specialevent_display_right_td_guideline {
	height: 100px;
	width: 240px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,200, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.specialevent_display_right_div_guideline {
    z-index:1000;
	height: 100px;
	width: 350px !important;
    position: absolute;
    margin-top: 0px;
	margin-left: -115px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:left;
}
/*
guideline_popup_profile1
*/
/************************//************************//************************/
	/************************//*	EVENT_GUIDELINES_OPTIONS	*/
	/********//*	EVENT_GUIDELINES_MAIN	*/
.event_temp_guideline_background {
    /*
    event_temp_stat_background
	event_guideline_edit
	event_guideline_age1
    */
    z-index: -1;
    height: 50px;
    width: 50px;
    position:absolute;
	top:0px;
    margin-top: 5px;
    margin-left: 7px;
    border:hidden;
    border-radius: 5px;
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

	.guideline_popup_view_options_absolute {
		height:178px !important;
		width:221px;
		border-spacing: 0px;
		border:1px solid #CCC;
		display: inline-block;
		margin-top:10px;
		margin-left:9px;
		vertical-align:middle;
		text-align:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.guideline_event_view_options_temp {
		height:147px !important;
		width:420px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) EFEFEF;
		background-size: 300px 250px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.guideline_popup_table_button_temp{
		height:178px; 
	  	width:221px;
		vertical-align:middle;
		text-align:center;
		font-size: .80em;
		
		font-weight: 600;
		text-decoration:none;
		 
		border:hidden;
		
		background: url(../images/buttons/butt_template.png);
		background-size: 200px 170px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_messagedisplayguideline_pop_default{
		z-index: 0;
	  	height:60px !important;
		width:218px !important;
		
		display: inline-block;
		line-height: 60px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: -61;
		margin-left: -109px;
		
		color:black;
		font-size: 2.00em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:5% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }

	.event_messagedisplayguideline_pop_flare{
		z-index: 0;
	  	height:50px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 50px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: -177;
		margin-left: 59px;
		
		color:black;
		font-size: 2.00em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
/*******************************************/
.event_guideline_edit {
	z-index: 200;
    height: 28px;
    width: 28px;
    display:inline-block;
    position:absolute;
	top: 0px;
    vertical-align:top;
    margin-top: 1%;
    margin-left: 45%;
    border-spacing: 0px;
    border:thin solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
    background-size: 23px 23px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_edit_wide {
    height: 28px;
    width: 28px;
    display:inline-block;
    position:absolute;
    vertical-align:top;
    margin-top: 3px;
    margin-left: 108px;
    border-spacing: 0px;
    border:thin solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
    background-size: 23px 23px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
	/************************//************************/
	/********//*	EVENT_GUIDELINES_SOCIAL	*/
	.event_guideline_social1 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline1.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social2 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline2.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social3 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline3.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social4 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline4.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social5 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline5.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social6 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline6.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social7 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline7.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social8 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline8.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social9 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline9.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social10 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline10.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social11 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline11.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social12 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline12.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social13 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline13.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social14 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline14.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social15 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline15.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social16 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline16.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social17 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline17.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social18 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline18.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social19 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline19.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_social20 {
		height: 97px;
		width: 117px;
		line-height:33px;
		
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline20.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

.event_guideline_list_profile1 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline1.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile2 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline2.png);
   	background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile3 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline3.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile4 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline4.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile5 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline5.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile6 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline6.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile7 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline7.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile8 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline8.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile9 {
   	z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline9.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile10 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline10.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile11 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline11.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile12 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline12.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile13 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline13.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile14 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline14.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile15 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline15.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile16 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline16.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile17 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline17.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile18 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline18.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile19 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline19.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_profile20 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline20.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social1 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline1.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social2 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline2.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social3 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline3.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social4 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline4.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social5 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline5.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social6 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline6.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social7 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline7.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social8 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline8.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social9 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline9.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social10 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline10.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social11 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline11.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social12 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline12.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social13 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline13.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social14 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline14.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social15 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline15.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social16 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline16.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social17 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline17.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social18 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline18.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social19 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline19.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_social20 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline20.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
	/************************//********************* position: absolute;!important***/
	/********//*	EVENT_GUIDELINES_PROFILE	*/
	.event_guideline_profile1 {
		/*
		event_guideline_age1
		*/
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline1.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile2 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline2.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile3 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline3.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile4 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline4.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile5 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline5.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile6 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline6.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile7 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline7.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile8 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline8.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile9 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline9.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile10 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline10.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile11 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline11.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile12 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline12.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile13 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline13.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile14 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline14.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile15 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline15.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile16 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline16.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile17 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline17.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile18 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline18.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile19 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline19.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_profile20 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline20.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/************************//************************/
	/********//*	EVENT_GUIDELINES_CONTRIBUTIONS	*/
	.event_guideline_contribution1 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline1.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution2 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline2.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution3 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline3.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution4 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline4.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution5 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline5.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution6 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline6.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution7 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline7.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution8 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline8.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution9 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline9.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution10 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline10.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution11 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline11.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution12 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline12.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution13 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline13.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution14 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline14.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution15 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline15.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution16 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline16.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution17 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline17.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution18 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline18.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution19 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline19.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_contribution20 {
		height: 99%;
		width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border:1px solid #CCC;
		
		background: url(../images/guidelines/guideline20.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/********//********/
/************************//************************/
	/********//*	EVENT_GUIDELINES_SHARING	*/
	.event_guideline_sharing1 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		top:0px;
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline1.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing2 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline2.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing3 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline3.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing4 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline4.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing5 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline5.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing6 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline6.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing7 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline7.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing8 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline8.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing9 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline9.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing10 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline10.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing11 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline11.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing12 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline12.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing13 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline13.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing14 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline14.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing15 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline15.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing16 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline16.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing17 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline17.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing18 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline18.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing19 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline19.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_guideline_sharing20 {
		height: 99%;
    	width: 99%;
		line-height:33px;
		position: absolute;
		margin-top: 1px;
		margin-left: 1px;
		
		vertical-align: top !important;
		
		color:black;
		font-size: 1.00em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		border: 1px solid rgba(209,209,209, 0.3);
		
		background: url(../images/guidelines/guideline20.png);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
.event_guideline_list_sharing1 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline1.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing2 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline2.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing3 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline3.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing4 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline4.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing5 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline5.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing6 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline6.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing7 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline7.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing8 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline8.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing9 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline9.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing10 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline10.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing11 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline11.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing12 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline12.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing13 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline13.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing14 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline14.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing15 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline15.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing16 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline16.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing17 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline17.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing18 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline18.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing19 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline19.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_sharing20 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline20.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************/
/********//*	EVENT_GUIDELINES_AGE	*/
/*

age_G.png
age_PG.png
age_PG13.png
age_R.png
age_NC17.png
age_E.png
age_E10.png
age_TEEN.png
age_MATURE.png
age_ADULT.png
age_EC.png
	event_guideline_sharing1
	event_temp_guideline_background
*/
.event_guideline_age1 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
	top:0px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_ALL.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age2 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_4_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age3 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_5_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age4 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_6_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age5 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_7_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age6 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_8_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age7 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_9_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age8 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_10_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age9 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_11_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age10 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_12_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age11 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_13_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age12 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_14_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age13 {
	/*
	event_temp_guideline_background
	*/
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
	top:0px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_15_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age14 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_16_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age15 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_17_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age16 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_18_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age17 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_19_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age18 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_20_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age19 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_21_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/***********************************/
.event_guideline_age20 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_22_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age21 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_23_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age22 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_25_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age23 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_29_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age24 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_30_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age25 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_33_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age26 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_35_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age27 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_39_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age28 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_40_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age29 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_41_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age30 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_42_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_age31 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_43_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age32 {
   height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_45_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age33 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_50_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age34 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_55_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age35 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_60_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age36 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_65_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age37 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_70_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age38 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_75_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age39 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_80_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age40 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_85_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_age41 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_90_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age42 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_95_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age43 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_100_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age44 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_110_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age45 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_G.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age46 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_PG.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age47 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_PG13.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age48 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_R.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age49 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_NC17.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age50 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_E.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_age51 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_E10.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age52 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_TEEN.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age53 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_MATURE.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age54 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_ADULT.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age55 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_EC.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age56 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_9_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age57 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_8_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age58 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_7_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age59 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_6_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age60 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_5_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_age61 {
    height: 99%;
    width: 99%;
    line-height:33px;
	position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_4_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/********************************************/
/********************************************/
.event_guideline_large_age1 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_ALL.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age2 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_4_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age3 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_5_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age4 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_6_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age5 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_7_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age6 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_8_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age7 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_9_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age8 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_10_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age9 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_11_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age10 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_12_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age11 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_13_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age12 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_14_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age13 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_15_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age14 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_16_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age15 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_17_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age16 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_18_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age17 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_19_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age18 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_20_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age19 {
    height: 117px;
    width: 136px;
    line-height:33px;

    margin-top: 1px;
    margin-left: 1px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_21_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/***********************************/
.event_guideline_large_age20 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_22_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age21 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_23_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age22 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_25_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age23 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_29_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age24 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_30_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age25 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_33_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age26 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_35_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age27 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_39_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age28 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_40_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age29 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_41_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age30 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_42_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_large_age31 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_43_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age32 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_45_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age33 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_50_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age34 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_55_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age35 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_60_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age36 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_65_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age37 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_70_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age38 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_75_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age39 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_80_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age40 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_85_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_large_age41 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_90_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age42 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_95_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age43 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_100_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age44 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_110_up.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age45 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_G.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age46 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_PG.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age47 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_PG13.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age48 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_R.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age49 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_NC17.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age50 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_E.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_large_age51 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_E10.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age52 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_TEEN.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age53 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_MATURE.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age54 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_ADULT.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age55 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_EC.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age56 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_9_under.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age57 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_8_under.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age58 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_7_under.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age59 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_6_under.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age60 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_5_under.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_large_age61 {
    height: 117px;
    width: 136px;
    line-height:33px;
    margin-top: 1px;
    margin-left: 1px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_4_under.png);
    background-position:center;
    background-size: 130px 110px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************************/
/*****************************/
.event_guideline_list_age1 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_ALL.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age2 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_4_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age3 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_5_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age4 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_6_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age5 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_7_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age6 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_8_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age7 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_9_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age8 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_10_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age9 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_11_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age10 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_12_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age11 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_13_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age12 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_14_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age13 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_15_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age14 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_16_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age15 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_17_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age16 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_18_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age17 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_19_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age18 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_20_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age19 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_21_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************************************/
.event_guideline_list_age20 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_22_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age21 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_23_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age22 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_25_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age23 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_29_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age24 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_30_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age25 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_33_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age26 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_35_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age27 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_39_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age28 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_40_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age29 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_41_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age30 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_42_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************************************/
.event_guideline_list_age31 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_43_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age32 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_45_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age33 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_50_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age34 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_55_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age35 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_60_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age36 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_65_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age37 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_70_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age38 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_75_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age39 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_80_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age40 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_85_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************************************/
.event_guideline_list_age41 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_90_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age42 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_95_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age43 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_100_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age44 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_110_up.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age45 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_G.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age46 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_PG.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age47 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_PG13.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age48 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_R.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age49 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_NC17.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age50 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_E.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*****************************************/
.event_guideline_list_age51 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_E10.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age52 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_TEEN.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age53 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_MATURE.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age54 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_ADULT.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age55 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_EC.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age56 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_9_under.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age57 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_8_under.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age58 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_7_under.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age59 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_6_under.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age60 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_5_under.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_age61 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/event_guidelines/age/age_4_under.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/******************************/
.guideline_popup_age1{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_ALL.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age2{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_4_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age3{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_5_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age4{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_6_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age5{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_7_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age6{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_8_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age7{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_9_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age8{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_10_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age9{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_11_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age10{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_12_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age11{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_13_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age12{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_14_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age13{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_15_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age14{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_16_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age15{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_17_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age16{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_18_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age17{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_19_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age18{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_20_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age19{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_21_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************/
.guideline_popup_age20{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_22_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age21{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_23_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age22{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_25_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age23{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_29_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age24{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_30_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age25{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_33_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age26{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_35_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age27{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_39_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age28{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_40_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age29{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_41_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************/
.guideline_popup_age30{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_42_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age31{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_43_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age32{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_45_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age33{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_50_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age34{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_55_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age35{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_60_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age36{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_65_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age37{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_70_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age38{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_75_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age39{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_80_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************/
.guideline_popup_age40{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_85_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age41{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_90_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age42{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_95_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age43{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_100_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age44{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_110_up.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age45{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_G.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age46{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_PG.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age47{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_PG13.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age48{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_R.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age49{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_NC17.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************/
.guideline_popup_age50{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_E.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age51{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_E10.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age52{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_TEEN.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age53{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_MATURE.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age54{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_ADULT.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age55{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_EC.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age56{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_9_under.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age57{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_8_under.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age58{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_7_under.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age59{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_6_under.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age60{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_5_under.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_age61{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/event_guidelines/age/age_4_under.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
/*    FEED AGE: */
/********************************************/
/********************************************/

.event_guideline_feed_large_age1 {
    z-index: 5;
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_ALL.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age2 {
    z-index: 5;
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_4_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age3 {
    z-index: 5;
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_5_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age4 {
    z-index: 5;
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_6_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age5 {
    z-index: 5;
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_7_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age6 {
    z-index: 5;
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_8_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age7 {
    z-index: 5;
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_9_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age8 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_10_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age9 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_11_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age10 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_12_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age11 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_13_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age12 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_14_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age13 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_15_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age14 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_16_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age15 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_17_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age16 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_18_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age17 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_19_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age18 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_20_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age19 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_21_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/***********************************/
.event_guideline_feed_large_age20 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_22_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age21 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_23_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age22 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_25_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age23 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_29_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age24 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_30_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age25 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_33_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age26 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_35_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age27 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_39_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age28 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_40_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age29 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_41_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age30 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_42_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_feed_large_age31 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_43_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age32 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_45_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age33 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_50_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age34 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_55_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age35 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_60_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age36 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_65_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age37 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_70_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age38 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_75_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age39 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_80_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age40 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_85_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_feed_large_age41 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_90_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age42 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_95_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age43 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_100_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age44 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_110_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age45 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_G.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age46 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_PG.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age47 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_PG13.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age48 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_R.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age49 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_NC17.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age50 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_E.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_feed_large_age51 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_E10.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age52 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_TEEN.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age53 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_MATURE.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age54 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_ADULT.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age55 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_EC.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age56 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_9_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age57 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_8_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age58 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_7_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age59 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_6_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age60 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_5_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_large_age61 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 475px;
	margin-left: 500px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_4_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/********************************************/
/********************************************/
.event_guideline_feed_small_age1 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_ALL.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age2 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_4_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age3 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_5_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age4 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_6_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age5 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_7_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age6 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_8_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age7 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_9_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age8 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_10_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age9 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_11_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age10 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_12_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age11 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_13_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age12 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_14_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age13 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_15_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age14 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_16_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age15 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_17_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age16 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_18_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age17 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_19_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age18 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_20_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age19 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;

    vertical-align: top !important;

    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;

    border: 1px solid rgba(209,209,209, 0.3);

    background: url(../images/event_guidelines/age/age_21_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/***********************************/
.event_guideline_feed_small_age20 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_22_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age21 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_23_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age22 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_25_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age23 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_29_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age24 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_30_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age25 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_33_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age26 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_35_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age27 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_39_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age28 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_40_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age29 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_41_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age30 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_42_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_feed_small_age31 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_43_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age32 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_45_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age33 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_50_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age34 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_55_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age35 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_60_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age36 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_65_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age37 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_70_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age38 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_75_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age39 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_80_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age40 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_85_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_feed_small_age41 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_90_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age42 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_95_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age43 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_100_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age44 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_110_up.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age45 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_G.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age46 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_PG.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age47 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_PG13.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age48 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_R.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age49 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_NC17.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age50 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_E.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************/
.event_guideline_feed_small_age51 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_E10.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age52 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_TEEN.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age53 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_MATURE.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age54 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_ADULT.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age55 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_EC.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age56 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_9_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age57 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_8_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age58 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_7_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age59 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_6_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age60 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_5_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_feed_small_age61 {
    height: 99%;
	width: 99%;
    line-height:33px;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    vertical-align: top !important;
    color:black;
    font-size: 1.00em;
    font-weight: 700;
    text-align:center;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/event_guidelines/age/age_4_under.png);
    background-position:center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*******************************************************/
/*******************************************************/
/******************************/
.event_guideline_list_contribution1 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline1.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution2 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline2.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution3 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline3.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution4 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline4.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution5 {
   z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline5.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution6 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline6.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution7 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline7.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution8 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline8.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution9 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline9.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution10 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline10.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution11 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline11.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution12 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline12.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution13 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline13.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution14 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline14.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution15 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline15.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution16 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline16.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution17 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline17.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution18 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline18.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution19 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline19.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guideline_list_contribution20 {
    z-index: 1;
    height:113px !important;
    width:123px;
    border-spacing: 0px;
	margin-top:1px;
	margin-left:1px;
    background: url(../images/guidelines/guideline20.png);
    background-size: 123px 113px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
GUIDELINE MENU TOP VIEW OPTION
guideline_popup_profile1
*/
.guideline_popup_social1{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline1.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social2{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline2.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social3{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline3.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social4{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline4.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social5{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline5.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social6{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline6.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social7{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline7.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social8{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline8.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social9{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline9.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social10{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline10.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social11{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline11.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social12{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline12.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social13{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline13.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social14{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline14.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social15{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline15.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social16{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline16.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social17{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline17.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social18{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline18.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social19{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline19.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_social20{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline20.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/********//********/
.guideline_popup_profile1{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline1.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile2{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline2.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile3{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline3.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile4{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline4.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile5{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline5.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile6{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline6.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile7{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline7.png);
   background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile8{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline8.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile9{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline9.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile10{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline10.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile11{
   height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline11.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile12{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline12.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile13{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline13.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile14{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline14.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile15{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline15.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile16{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline16.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile17{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline17.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile18{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline18.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile19{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline19.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_profile20{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline20.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.guideline_popup_contribution1{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline1.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution2{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline2.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution3{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline3.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution4{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline4.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution5{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline5.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution6{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline6.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution7{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline7.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution8{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline8.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution9{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline9.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution10{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline10.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution11{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline11.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution12{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline12.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution13{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline13.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution14{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline14.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution15{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline15.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution16{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline16.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution17{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline17.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution18{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline18.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution19{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline19.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_contribution20{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline20.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.guideline_popup_sharing1{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline1.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing2{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline2.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing3{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline3.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing4{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline4.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing5{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline5.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing6{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline6.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing7{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline7.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing8{
   	height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline8.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing9{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline9.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing10{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline10.png);
   	background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing11{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline11.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing12{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline12.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing13{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline13.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing14{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline14.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing15{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline15.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing16{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline16.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing17{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline17.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing18{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline18.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing19{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline19.png);
    background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.guideline_popup_sharing20{
    height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
    background: url(../images/guidelines/guideline20.png);
   background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

/*********************/


/******************************************** */
.event_container_standard_bottom_left_top_td {
    height:120px !important;
    width: 485px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 0px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_standard_bottom_ltt01 {
    height:120px !important;
    width: 363px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_standard_bottom_ltt02 {
    height:120px !important;
    width: 122px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_standard_bottom_left_bottom_td {
    height:204px !important;
    width: 484px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*

event_standard_frame_table01_td1
*/
.event_container_standard_bottom_left_bottom_td01 {
    height:204px !important;
    width: 363px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_standard_bottom_left_bottom_td02 {
    height:204px !important;
    width: 122px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_standard_bottom_left_bottom_td02_01 {
    height:99px !important;
    width: 121px !important;
    margin-top: 0px;
    vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(209,209,209, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_standard_bottom_left_bottom_td02_01_hidden {
    height:99px !important;
    width: 121px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(209,209,209, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_standard_bottom_left_bottom_td02_02 {
    height:99px !important;
    width: 121px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(209,209,209, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_standard_bottom_left_bottom_td02_02_hidden {
    height:99px !important;
    width: 121px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(209,209,209, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.event_standard_media_view_option {
    height: 200px;
    width: 480px;
    line-height:30px;
	position: absolute;
	margin-top: 0px;
	margin-left: 1px;
    text-align:left;
    text-decoration:none;
    border: 1px solid rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
		
	*/
}
.event_media_view_option_text {
    height: 90px;
    width: 200px;
    line-height:30px;
	position: absolute;
	margin-top: 53px;
	margin-left: 80px;
    border: 1px solid rgba(255,255,255, 0.2);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
	font-size: 1.85em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
    text-decoration:none;
}
.event_media_view_option_text_full {
    height: 90px;
    width: 200px;
    line-height:30px;
	position: absolute;
	margin-top: 53px;
	margin-left: 140px;
    border: 1px solid rgba(255,255,255, 0.2);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
	font-size: 1.85em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    text-decoration:none;
}
/****************************************/
/****************************************/
.event_media_view_option_media_full {
    height: 204px;
    width: 415px;
    line-height:30px;
    border: 2px solid rgba(209,209,209, 0.2)  !important;
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
    text-decoration:none;
}
.event_media_view_option_media_full_screen{
	height: 204px;
    width: 415px;
    line-height:30px;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;	
    border: 2px solid rgba(209,209,209, 0.2) !important;
    background-color:rgba(255,255,255, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
	/*
	event_media_view_option_media_full
	*/
}
.event_container_standard_bottom_left_bottom_td02_01_media_full {
    height:102px !important;
    width: 71px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(47,209,209, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_standard_bottom_left_bottom_td02_02_media_full {
    height:102px !important;
    width: 71px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(209,200,209, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/****************************************/
/****************************************/
.event_media_view_catalog_media_full {
    height: 198px;
    width: 478px;
    line-height:30px;
    border: 2px solid rgba(209,209,209, 0.2)  !important;
    background-color:rgba(255,255,255, 0.2);
	vertical-align:top;
	text-align: center;
    text-decoration:none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_media_view_catalog_media_full_screen{
	height: 198px;
    width: 478px;
    line-height:30px;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;	
    border: 2px solid rgba(209,209,209, 0.2)  !important;
    background-color:rgba(255,255,47, 0.3);
	vertical-align:top;
	text-align: center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;	
	/*
	event_media_view_option_media_full
	*/
}
/****************************************/
/****************************************/
.event_media_view_photo_media_full {
    height: 198px;
    width: 478px;
    line-height:30px;
    border: 1px solid rgba(255,255,255, 0.2);
    background-color:rgba(255,255,255, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
    text-decoration:none;
}
.event_media_view_photo_media_full_screen{
	height: 198px;
    width: 478px;
    line-height:30px;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;	
    border: 1px solid rgba(255,255,255, 0.2);
    background-color:rgba(255,255,47, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
	/*
	event_media_view_option_media_full
	*/
}
/****************************************/
.event_media_view_option {
    height: 198px;
    width: 357px;
    line-height:30px;
	position: absolute;
	margin-top: 0px;
	margin-left: 1px;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.8);
    background-position:center;
    background-size: 300px 90px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
	font-size: 5.00em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
    text-decoration:none;
	
}
.event_media_view_option_full {
    height: 198px;
    width: 480px;
    line-height:30px;
	position: absolute;
	margin-top: 0px;
	margin-left: 1px;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.8);
    background-position:center;
    background-size: 300px 90px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
	font-size: 5.00em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

    text-decoration:none;
}
/****************************************/
/****************************************/
.event_media_streaming_option_catalog {
    height: 95px;
    width: 60px;
    line-height:30px;
	position: absolute;
	margin-top: 5px;
	margin-left: 1px;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(1,239,239, 0.8);
    background-position:center;
    background-size: 50px 80px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
	font-size: 0.80em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

    text-decoration:none;
}

.event_media_streaming_option_upload {
    height: 85px;
    width: 60px;
    line-height:30px;
	position: absolute;
	margin-top: 5px;
	margin-left: 1px;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(1,239,239, 0.8);
    background-position:center;
    background-size: 50px 70px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
	font-size: 0.80em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

    text-decoration:none;
}

/****************************************/
/****************************************/



/****************************************/
/****************************************/
/****************************************/
/****************************************/
.event_media_view_option_catalog {
    height: 95px;
    width: 60px;
    line-height:30px;
	position: absolute;
	margin-top: 5px;
	margin-left: 1px;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(1,239,239, 0.8);
    background-position:center;
    background-size: 50px 80px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
	font-size: 0.80em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

    text-decoration:none;
}

.event_media_view_option_upload {
    height: 85px;
    width: 60px;
    line-height:30px;
	position: absolute;
	margin-top: 5px;
	margin-left: 1px;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(1,239,239, 0.8);
    background-position:center;
    background-size: 50px 70px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
	font-size: 0.80em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

    text-decoration:none;
}
/****************************************/
/****************************************/
/***************************************************/
/*


*/
.event_container_standard_bottom_right {
	height: 330px;
    /*
	width: 355px !important;
    event_container_standard_bottom_left
    */
    width: 365px !important;
	line-height: 40px;
	border: 1px solid rgba(203,203,203, 0.0);
	margin-top: 0px;
	margin-left: 0px;
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/*
.event_container_standard_bottom_right_menu {
	height: 35px;
	width: 353px !important;
	line-height: 35px;
	border: 1px solid rgba(203,203,203, 0.1);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_standard_bottom_right_menu01 {
	height: 33px;
	width: 100px !important;
	line-height: 33px;
	position: absolute;
	margin-left: 1px;
	border: 1px solid rgba(203,203,203, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.event_container_standard_bottom_right_menu02 {
	height: 33px;
	width: 250px !important;
	line-height: 33px;
	position: absolute;
	margin-left: 101px;
	border: 1px solid rgba(203,203,203, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.event_container_standard_bottom_right_menu02_01 {
	height:33px !important;
	width:250px !important;
	line-height: 13px;
	position: absolute;
	margin-left: 0px;
	border: 1px solid rgba(203,203,203, 0.0);
	background-color:rgba(255,255,255, 0.8);
	border-spacing: 0px;
	vertical-align:text-top;
	text-align: left;
} 

*/
.event_container_standard_bottom_right_other {
	height: 229px;
	width: 365px !important;
	line-height: 40px;
    border: hidden;
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_container_standard_bottom_guidelines {
	height: 99px;
	width: 365px !important;
	line-height: 40px;
	border: 1px solid rgba(209,209,209, 0.0);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_container_standard_bottom_guidelines_td{
    height:97px !important;
    width:116px !important;
    line-height: 97px;
    display:inline-block;
    vertical-align: top;
    border-spacing: 0px;
    border: hidden;
    background-color:transparent;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_standard_bottom_guidelines_td_display{
    /*
    event_display_right_div_guideline
    */
    height:97px !important;
    width:136px !important;
    line-height: 97px;
    display:inline-block;
    vertical-align: top;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(255,255,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_guidelines_display{
    height:117px !important;
    width:136px !important;
    position:absolute;
    margin-top:-20px;
	margin-left:0px;
    display:inline-block;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(255,255,255, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: top;
    text-align:left;
}
/*
event_guideline_edit
*/
/*********************************************/
/*********************************************/
/*
	event_theme_default1

*/
.event_container_event_media_top_left {
	height: 255px;
	width: 588px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_event_media_top_right {
	height: 254px;
	width: 355px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.9);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_media_bottom_left {
	height: 363px;
	width: 588px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_media_bottom_left_menu {
	height: 35px;
	width: 588px !important;
	line-height: 35px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_media_bottom_right {
	height: 368px;
	width: 355px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_media_bottom_right_other {
	height: 265px;
	width: 355px !important;
	line-height: 40px;
	border: hidden;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_media_bottom_left_top_td {
    height:120px !important;
    width: 485px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 0px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_media_bottom_ltt01 {
    height:120px !important;
    width: 363px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_media_bottom_ltt02 {
    height:120px !important;
    width: 122px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_media_bottom_left_bottom_td {
    height:204px !important;
    width: 484px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_media_bottom_left_bottom_td01 {
    height:204px !important;
    width: 377px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(255,150,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_media_bottom_left_bottom_td02 {
    height:204px !important;
    width: 105px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_media_bottom_left_bottom_td02_01 {
    height:102px !important;
    width: 105px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.3);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_media_bottom_left_bottom_td02_02 {
    height:102px !important;
    width: 105px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.3);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************************/
/*********************************************/
/*
	event_media1_upload_frame_div
event_container_event_top_left
*/
.event_container_event_popup_top_left {
	height: 255px;
	width: 588px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_event_popup_top_right {
	height: 254px;
	width: 355px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.9);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_popup_bottom_left {
	height: 363px;
	width: 588px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_popup_bottom_left_menu {
	height: 35px;
	width: 588px !important;
	line-height: 35px;
	border: 1px solid rgba(239,239,239, 0.0);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_popup_bottom_right {
	height: 368px;
	width: 355px !important;
	line-height: 40px;
	border: 1px solid rgba(239,239,239, 0.9);
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_popup_bottom_right_other {
	height: 265px;
	width: 355px !important;
	line-height: 40px;
	border: hidden;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_container_popup_bottom_left_top_td {
    height:120px !important;
    width: 485px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 0px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_popup_bottom_ltt01 {
    height:120px !important;
    width: 363px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_popup_bottom_ltt02 {
    height:120px !important;
    width: 122px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_popup_bottom_left_bottom_td {
    height:204px !important;
    width: 484px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_popup_bottom_left_bottom_td01 {
    height:204px !important;
    width: 377px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(255,150,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_popup_bottom_left_bottom_td02 {
    height:204px !important;
    width: 105px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: hidden;
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_popup_bottom_left_bottom_td02_01 {
    height:102px !important;
    width: 105px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.3);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_container_popup_bottom_left_bottom_td02_02 {
    height:102px !important;
    width: 105px !important;
    margin-top: 0px;
    vertical-align: top;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.3);
    background-color:rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************//************************/
	/************************//*	EVENT_EDIT_OPTIONS	*/
	/********//*	EVENT_EDIT_MAIN	*/
	/*
		
		event_type_edit
event_messagedisplayevent_multi_options_settings
		
	*/

.recommendation_deluxe_edit {
	z-index: 100;
	height: 28px;
	width: 28px;
	display:inline-block;
	margin-top: 0px;
	margin-left: 135px;
	position: absolute;
	vertical-align:top;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*

*/
.identity_edit {
	z-index: 100;
	height: 18px;
	width: 18px;
	display:inline-block;
	margin-top: 1px;
	margin-left: 28px;
	position: absolute;
	vertical-align:top;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 13px 13px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_large_edit {
	z-index: 100;
	height: 28px;
	width: 28px;
	display:inline-block;
	margin-top: 0px;
	margin-left: 78%;
	position: absolute;
	vertical-align:top;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_long_edit {
	z-index: 100;
	height: 28px;
	width: 28px;
	display:inline-block;
	margin-top: 1px;
	margin-left: 158px;
	position: absolute;
	vertical-align:top;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_recommendation_large_edit {
	z-index: 100;
	height: 28px;
	width: 28px;
	display:inline-block;
	margin-top: 1px;
	margin-left: 104px;
	position: absolute;
	vertical-align:top;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
event_standard_frame_rec_long_default
event_standard_frame_rec_large_default
*/
.event_recommendation_long_edit {
	z-index: 100;
	height: 28px;
	width: 28px;
	display:inline-block;
	margin-top: 1px;
	margin-left: 149px;
	position: absolute;
	vertical-align:top;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_recommendation_large_edit {
	z-index: 100;
	height: 28px;
	width: 28px;
	display:inline-block;
	margin-top: 1px;
	margin-left: 87px;
	position: absolute;
	vertical-align:top;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_recommendation_long_edit {
	z-index: 100;
	height: 28px;
	width: 28px;
	display:inline-block;
	margin-top: 1px;
	margin-left: 190px;
	position: absolute;
	vertical-align:top;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_date_edit {
    z-index: 100;
    height: 28px;
    width: 28px;
    display:inline-block;
    margin-top: -185px;
    margin-left: 105px;
    position: absolute;
    vertical-align:top;
    border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
    background-size: 23px 23px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

	.item_date_edit {
		height: 28px;
		width: 28px;
		display:inline-block;
		position:absolute;
		vertical-align:top;
		margin-top: 1px;
		margin-left: 80px;
		border-spacing: 0px;
		border:1px solid rgba(209,209,209, 0.7);
		background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
		background-size: 23px 23px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*

*/
.event_title_edit {
    z-index: 5;
    height: 28px;
    width: 28px;
    display:inline-block;
    position:absolute;
    vertical-align:top;
    margin-top: 3px;
    margin-left: 96%;
    border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
    background-size: 23px 23px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    word-wrap: break-word !important;
}
	.event_multi_title_edit {
		height: 28px;
		width: 28px;
		display:inline-block;
		position:absolute;
		
		vertical-align:top;
		
		margin-top: 21px;
		/*
		margin-left: 427px;
		*/
		margin-left: 96%;
		
		border:1px solid #ccc;
		background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
		background-size: 23px 23px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.event_multi_type_edit {
		height: 28px;
		width: 28px;
		display:inline-block;
		position:absolute;
		
		vertical-align:top;
		
		margin-top: 1;
		margin-left: 45px;
		
		border:1px solid #ccc;
		background: url(../images/buttons/sys_settings_button_large.png) #6CC;
		background-size: 23px 23px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*

*/
.item_title_edit {
	height: 30px;
	width: 30px;
	display:inline-block;
	position:absolute;
	vertical-align:top;
	margin-top: .4%;
	margin-left: 50%;
	border-spacing: 0px;
   	border:1px solid rgba(209,209,209, 0.7);
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_title_field{
	z-index: 0;
	height:99% !important;
	width: 99% !important;
	/*width: 100%;*/
	line-height:12px !important;
	margin-top: 0px;
	margin-left: 2px;
	border-spacing: 0px;
	border:hidden;
	resize: none;
	overflow: auto;
	background: url(../images/buttons/butt_template.png) rgba(238,238,238, 0.5);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position: 1% 50%; 
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.40em;
    font-weight: 600;
    vertical-align:middle;
    text-align:left;
    text-decoration:none;
    text-indent:25px;
	
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    word-wrap: break-word !important;
  }
.item_address_field{
	z-index: 0;
	height:99% !important;
	width: 99% !important;
	/*width: 100%;*/
	line-height:12px !important;
	vertical-align:middle;
	margin-top: 0px;
	margin-left: 0px;

	border-spacing: 0px;
	border:hidden;
	/*
	color:#888888;


	font-size: 0.22em;
	font-weight: 500;
	text-shadow: 0 0 0.5em #87F;
	*/
	text-align:left;
	text-decoration:none;
	text-indent:0px;

	resize: none;
	overflow: auto;
	background: rgba(238,238,238, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
  }
.item_text_field{
	z-index: 0;
	height:35px !important;
	width: 99% !important;
	/*width: 100%;*/
	line-height:20px !important;
	vertical-align:middle;
	margin-top: 0px;
	margin-left: 2px;

	background-color:rgba(238,238,238, 0.5);
	border-spacing: 0px;
	border:hidden;
	/*
	color:#888888;


	font-size: 0.22em;
	font-weight: 500;
	text-shadow: 0 0 0.5em #87F;
	*/
	text-align:left;
	text-decoration:none;
	text-indent:5px;

	resize: none;
	overflow: auto;

	

  }
.item_title_field_error{
	z-index: 0;
	height:99% !important;
	width: 99%  !important;
	/*width: 100%;*/
	line-height:12px !important;
	vertical-align:text-top;
	margin-top: 0px;

	background-color:rgba(255,255,255, 0.7);

	border-spacing: 0px;
	border-style:solid;
	border-width:thin;
	border-color:#F00;

	color:#888888;


	font-size: 2.0em;
	font-weight: 500;
	text-align:left;
	text-decoration:none;
	text-indent:2px;

	resize: none;
	overflow: auto;

	text-shadow: 0 0 0.5em #87F;
    word-wrap: break-word !important;
}
	.event_messagedisplayevent_multi_options_settings {
		z-index: 5;
		height:28px;
		width:28px;
		display:inline-block;
		position:absolute;
		
		vertical-align:top;
		
		margin-top: -13;
		margin-left: 262px;
		
		border:1px solid #ccc;
		background: url(../images/buttons/sys_settings_button_large.png) #6CC;
		background-size: 23px 23px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}

/*****************************/
/*


*/
.event_message_button_active {
	height:30px !important;
	width:115px !important;
	display: inline-block;
	vertical-align:top;
	margin-left: 5px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 113px 28px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_follow_button_active {
	height:30px !important;
	width:115px !important;
	display: inline-block;
	vertical-align:top;
	margin-left: 135px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 113px 28px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_unfollow_button_active {
	height:30px !important;
	width:115px !important;
	display: inline-block;
	vertical-align:top;
	margin-left: 5px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 113px 28px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_pending_button_active {
	height:30px !important;
	width:115px !important;
	display: inline-block;
	vertical-align:top;
	margin-left: 135px;
	background: url(../images/buttons/butt_template.png) rgb(255, 255, 128, 0.7);
	background-size: 113px 28px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_follower_action_div {
    /*
    event_parent_unfollow_button_active
    event_parent_message_button_active
    
    */
	z-index: 100;
	height: 40px;
	width: 195px;
	display:inline-block;
	margin-top: 3px;
	margin-left: 10px;
	position: absolute;
	vertical-align:top;
	border:1px solid rgba(204,204,204, 0.7);
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_follower_div {
	z-index: 100;
	height: 40px;
	width: 95px;
	display:inline-block;
	margin-top: 1px;
	margin-left: 105px;
	position: absolute;
	vertical-align:top;
	border:1px solid rgba(204,204,204, 0.7);
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_follower_div {
	z-index: 1000;
	height: 40px;
	width: 95px;
	display:inline-block;
	margin-top: 1px;
	margin-left: 0px;
	position: absolute;
	vertical-align:top;
	border:1px solid rgba(204,204,204, 0.7);
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	event_follower_action_div
	event_home_button
	base_functional_action_td1
	*/
}
/*****************************/
.event_parent_message_button_active {
	height:37px !important;
	width:92px !important;
	display: inline-block;
	vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    background-color:rgba(10, 245, 92, 0.7);
    /*
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 90px 34px;
	background-repeat: no-repeat;
	background-position:center;
    */
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_parent_follow_button_active {
	height:37px !important;
	width:92px !important;
	display: inline-block;
	vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    background-color:rgba(239,239,239, 0.7);
    /*
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    */
	background-size: 90px 34px;
	background-repeat: no-repeat;
	background-position:center;
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_parent_unfollow_button_active {
	height:37px !important;
	width:92px !important;
	display: inline-block;
	vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    background-color: rgba(10, 245, 92, 0.7);
    /*
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 90px 34px;
	background-repeat: no-repeat;
	background-position:center;
    */
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_parent_pending_button_active {
	height:37px !important;
	width:92px !important;
	display: inline-block;
	vertical-align:top;
	margin-top: 1px;
	margin-left: 1px;
    background-color:rgb(255, 255, 128, 0.7);
    /*
	background: url(../images/buttons/butt_template.png) rgb(255, 255, 128, 0.7);
	background-size: 90px 34px;
	background-repeat: no-repeat;
	background-position:center;
    */
	border:hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************//************************//************************/
	/************************//*	EVENT_MENU_OPTIONS	*/
	/********//*	EVENT_MENU_MAIN	*/

	.event_options_table {
		height:415px !important;
		width:948px;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.event_options_table_td {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:hidden;
		vertical-align:middle;
		text-align:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.multi_edit_status_true {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_settings_edit_active.png) rgba(239,239,239, 0.7);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.multi_edit_status_false {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_settings_edit_active.png) rgba(239,239,239, 0.7);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.multi_edit_status_inactive {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_settings_edit_active.png)#E01317;
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_options_marketplace {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:hidden;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_basket_marketplaceDefault.png)  rgba(239,239,239, 0.7);
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_options_marketplace_inactive {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:hidden;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_basket_marketplaceDefault.png) #E01317;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	/*
	File_Upload_Label_class
	
	.File_Upload_Label_class{
		height:96px !important;
		width:96px !important;
		line-height:100px !important;
		display: inline-block;
		font-size:20px;
		font-weight:700;
		text-align:center  !important;
		vertical-align:middle !important;
		border-spacing: 0px;
		text-decoration:none;
		background-color:#EFEFEF;
		background: url(../images/buttons/plus_button.png); 
		background-size: 96px 96px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		padding:0;
		margin:0;
	}
	
	*/
	
	.event_options_edit {
		height:207px !important;
		width:237px !important;
		line-height:200px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #15ECDE;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
.event_options_upload {
    height:207px !important;
    width:237px !important;
    line-height:200px !important;
    display: inline-block;
    border-spacing: 0px;
    border:1px solid #CCC;
    vertical-align:middle;
    text-align:center;
    background: url(../images/buttons/sys_upload_graphic.png)  rgba(239,239,239, 0.7);
    background-size: 180px 180px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
	.event_options_upload_inactive {
		height:207px !important;
		width:237px !important;
		line-height:200px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_upload_graphic.png) #E01317;
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.event_options_delete {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_delete_buttonDefault.png)  rgba(239,239,239, 0.7);
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.event_options_cancel {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #960;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*

*/
	.event_options_schedule {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_calendar_default.png) rgba(239,239,239, 0.7);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	/*
		event_option_main_submit_button
	*/
	.event_options_submit{
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_settings_submit.png)  rgba(239,239,239, 0.7);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_options_submit_inactive{
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_settings_submit.png) #E01317;
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.event_options_temp {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_options_temp_inactive {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #E01317;
		background-size: 200px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_options_settings {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	.event_options_settings_inactive {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_settings_button_large.png)#E01317;
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	.event_options_settings_unavailable {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_settings_button_unavailable.png) rgba(239,239,239, 0.7);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_options_edit_active {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_settings_edit_active.png) rgba(239,239,239, 0.7);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_options_edit_disabled {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_settings_edit_disabled.png) rgba(239,239,239, 0.7);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_options_edit_unavailable {
		height:207px !important;
		width:237px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/sys_settings_edit_unavailable.png) rgba(239,239,239, 0.7);
		background-size: 180px 180px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

/************************//************************//************************/

/************************//************************//************************/
	/************************//*	EVENT_MULTI_CHILD	*/
	/********//*	EVENT_MULTI_CHILD_MAIN	*/
/*
//event_messagedisplayguideline_pop_default
event_messagedisplayguideline_pop_default

position:absolute;
item_feed_frame
*/
	.multi_child_view_options_frame {
		height:140px !important;
		width:129px;
		line-height: 140px;
		
		
		/*
		margin-top: -175px;
		display: inline-block;
		*/
		position: absolute;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		vertical-align:top;
		text-align:left;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.multi_child_view_options_absolute {
		height:140px !important;
		width:129px;
		line-height: 140px;
		position: relative;
		
		/*
		margin-top: -175px;
		
		margin-top: -5px;
		margin-left:7px;
		
		*/
		margin-top:3px;
		margin-left:10px;
		display: inline-block;
		
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		vertical-align:top;
		text-align:left;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.multi_child_messagedisplayguideline_default{
		z-index: 1;
	  	height:90px !important;
		width:100px !important;
		line-height: 90px;
		
		display: inline-block;
		/**/
		position:absolute;
			
		margin-top: 25px !important;
		margin-left: 10px;
		/*
		margin-left: -118px;
		*/
		
		border:hidden;
		border-spacing: 0px;
		
		text-align:center;
		vertical-align:bottom;
		
		
		
		color:black;
		font-size: 2.70em  !important;
		font-weight: 700;
		
		text-decoration:none;
		/*
		border:1px solid #CCC;
		url(../images/buttons/butt_template.png)
		*/
		background:  rgba(255,255,255, 0.1);
		background-size: 80px 80px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }

	.multi_child_messagedisplayguideline_flare{
		z-index: 2;
	  	height:60px !important;
		width:127px !important;
		
		display: inline-block;
		line-height: 40px;
		/**/
		position:absolute;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top: 26;
		margin-left: 5px;
		/*
		margin-left: -132px;
		*/
		color:black;
		font-size: 1.80em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		/*
		border:1px solid #CCC;
		url(../images/buttons/butt_template.png)
		*/
		background:  rgba(255,255,255, 0.1);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.multi_child_messagedisplayguideline_status_pending{
		z-index: 1;
	  	height:40px !important;
		width:40px !important;
		
		display: inline-block;
		line-height: 40px;
		/**/
		position:absolute;
		
		border-spacing: 0px;
		border:5px solid #CCC;
		
		margin-top: 110;
		margin-left: 0px;
		/*
		margin-left: -132px;
		*/
		
		color:black;
		font-size: 1.50em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.multi_child_messagedisplayguideline_status_active{
		z-index: 1;
	  	height:40px !important;
		width:40px !important;
		
		display: inline-block;
		line-height: 40px;
		/**/
		position:absolute;
		
		border-spacing: 0px;
		border:5px solid #CCC;
		
		margin-top: 110;
		margin-left: 0px;
		/*
		margin-left: -132px;
		*/
		
		color:black;
		font-size: 1.50em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(0,128,0, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.multi_child_messagedisplayguideline_status_completed{
		z-index: 1;
	  	height:40px !important;
		width:40px !important;
		
		display: inline-block;
		line-height: 40px;
		/**/
		position:absolute;
		
		border-spacing: 0px;
		border:5px solid #CCC;
		
		margin-top: 110;
		margin-left: 0px;
		/*
		margin-left: -132px;
		*/
		color:black;
		font-size: 1.50em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.multi_child_messagedisplayguideline_status_error{
		z-index: 1;
	  	height:40px !important;
		width:40px !important;
		
		display: inline-block;
		line-height: 40px;
		/**/
		position:absolute;
		
		border-spacing: 0px;
		border:5px solid #CCC;
		
		margin-top: 110;
		margin-left: 0px;
		/*
		margin-left: -132px;
		*/
		
		color:black;
		font-size: 1.50em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(0,0,255, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }

	.multi_child_messagedisplayguideline_icon{
		z-index: 1;
	  	height:40px !important;
		width:40px !important;
		
		display: inline-block;
		line-height: 40px;
		/**/
		position:absolute;
		
		border-spacing: 0px;
		border:3px solid #CCC;
		
		margin-top: 10;
		margin-left: 90px;
		/*
		margin-left: -45px;
		*/
		
		color:black;
		font-size: 1.50em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
/************************//************************//************************/
	/************************//*	EVENT_ITEM_VIEW_WINDOW	*/
	/********//*	EVENT_ITEM_VIEW_MAIN	*/
/*

*/

/*


*/
	.event_item_swipe_main{
		z-index: 99999;
		height:648px !important;
		width:952px !important;

		display: inline-block;
		border-spacing: 0px;
		border: hidden;
		background-color:rgba(255,255,255, 1);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_item_window_main{
		z-index: 0;
		height:571px !important;
		width:690px !important;

		display: inline-block;
		text-align:left;
		vertical-align:top !important;
		
		border-spacing: 0px;
		border: hidden;
		
		text-decoration:none;
		background-color:rgba(255,255,255, 0.7);
		/*
		color: white;
		background: url(../images/buttons/butt_template.png) rgba(255,255,8, 0.4);
		
		background-size: 400px 400px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*
	
*/
	.event_item_window_right{
		z-index: 0;
		height:571px !important;
		width:222px !important;
		line-height: 571px;
		
		text-align:left;
		vertical-align:top !important;
		
		display: inline-block;
		border-spacing: 0px;
		border: hidden;	
		/*
		color: white;
		
		
		*/
		text-decoration:none;
		background-color:rgba(255,255,255, 0.1);
		/*
		background: url(../images/buttons/butt_template.png) rgba(255,255,8, 0.4);
		
		background-size: 400px 400px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*
item_media_panel_add 
event_item_window_data
*/
	.event_item_window_menu{
		z-index: 0;
		height:150px !important;
		width:222px !important;

		display: inline-block;
		border-spacing: 0px;
		border: hidden;	
		
		color: white;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		background-color:darkblue;
		/*
		background: url(../images/buttons/butt_template.png) rgba(255,255,8, 0.4);
		
		background-size: 400px 400px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_item_window_menu_td1{
		z-index: 0;
		height:58px !important;
		width:222px !important;
	
		text-align:left !important;
		vertical-align:text-top;
		
		margin-top: 0px;
		margin-left: 0px;
		position: absolute;
		
		display: inline-block;
		border-spacing: 0px;
		
		border-bottom:1px solid #CCC ;
		border-top:1px solid #CCC ;
		border-right:1px solid #CCC ;
		border-left:1px solid #CCC ;
		border:hidden;
		
		color: white;
		
		text-decoration:none;
		
		/*
		background: url(../images/buttons/butt_template.png) rgba(255,255,8, 0.4);
		
		background-size: 400px 400px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_item_window_menu_td2{
		z-index: 0;
		height:55px !important;
		width:222px !important;
		
		text-align:left !important;
		vertical-align:text-top;
		
		margin-top: 63px;
		margin-left: 0px;
		position: absolute;
		
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;	
		
		color: white;
		text-align:center;
		
		text-decoration:none;
		
		/*
		background: url(../images/buttons/butt_template.png) rgba(255,255,8, 0.4);
		
		background-size: 400px 400px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_item_window_month_form_field{
	  	height:58px !important;
		/*width: 195px;*/
		width: 100px;
		line-height:24px !important;
		
		position: absolute;
		margin-left: 0;
		
		background-color:rgba(255,255,255, 0.5);
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color: crimson  !important;
		font-size: .85em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle !important;
		text-decoration:none;
	  }
	.event_item_window_day_form_field{
	  	height:58px !important;
		/*width: 195px;*/
		width: 97px;
		line-height:35px !important;
		
		position: absolute;
		margin-left: 100px;
		
		
		background-color:rgba(255,255,255, 0.5);
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color: crimson  !important;
		font-size: 1.00em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle !important;
		text-decoration:none;
	  }
	.event_item_window_year_form_field{
	  	height:37px !important;
		/*width: 195px;*/
		width: 72px;
		line-height:15px !important;
		
		position: absolute;
		margin-left: 150px;
		margin-top: 30px;
		
		
		background-color:rgba(255,255,255, 0.5);
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color: crimson  !important;
		font-size: .65em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle !important;
		text-decoration:none;
	  }
	.event_item_window_status_div{
	  	height:58px !important;
		width: 220px;
		
		position: absolute;
		margin-left: 0px;
		margin-top: 0px;
		
		text-align: left;
		
		
		background-color:rgba(255,255,255, 0.5);
		border-spacing: 0px;
		border:1px solid #CCC;
		
		
	  }
	.item_status_pending{
	  	height:58px !important;
		width: 220px;
		
		display: inline-block;
		
		position: absolute;
		vertical-align: top;
		margin-left: -110px;
		
		border-spacing: 0px;
		border:2px solid #CCC;
		
		color:black;
		font-size: .50em  !important;
		font-weight: 700;
		
		text-decoration:none;
		/**/
		background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
		
	  }
	.item_status_active{
	  	height:58px !important;
		width: 220px;
		
		display: inline-block;
		
		position: absolute;
		vertical-align: top;
		margin-left: -110px;
		
		border-spacing: 0px;
		border:2px solid #CCC;
		
		color:black;
		font-size: .50em  !important;
		font-weight: 700;
		
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(0,128,0, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.item_status_completed{
		height:58px !important;
		width: 220px;
		
		display: inline-block;
		
		position: absolute;
		vertical-align: top;
		margin-left: -110px;
		
		border-spacing: 0px;
		border:2px solid #CCC;
		
		color:black;
		font-size: .50em  !important;
		font-weight: 700;
		
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.item_status_error{
		height:58px !important;
		width: 220px;
		
		display: inline-block;
		
		position: absolute;
		vertical-align: top;
		margin-left: -110px;
		
		border-spacing: 0px;
		border:2px solid #CCC;
		
		color:black;
		font-size: 0.50em  !important;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(0,0,255, 0.7);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
/*
item_status_completed
*/
	.item_date_edit_button{
		z-index: 9;
		height:58px !important;
		width: 220px;
		
		position: absolute;
		vertical-align:middle;
		text-align:center;
		
		margin-left: -110px;
		
		display: inline-block;
		
		border-spacing: 0px;
		border:4px solid #10DEE7;
		
		color:black;
		font-size: 0.50em  !important;
		font-weight: 700;
		
		text-decoration:none;
		
		background-color: transparent;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }

/*

item_status_pending
item_status_active
item_status_completed
item_status_error


margin-top: 120px;
		vertical-align:top;
*/
.event_item_window_data{
	z-index: 0;
	height:449px !important;
	width:222px !important;
	line-height: 54px;
	position: absolute;
	margin-top: 120px;
	vertical-align: top;


	border-spacing: 0px;
	border: hidden;	

	color: white;
	text-align:left;


	background-color:rgba(255,255,255, 0.1);
	/*
	background: url(../images/buttons/butt_template.png) rgba(255,255,8, 0.4);

	background-size: 400px 400px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	*/
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
swipe_panel_window
event_recommendation_large_edit
event_item_window_right
.event_swipe_main_table_td2
*/
.event_item_window_data01{
	z-index: 0;
	height:113px !important;
	width:222px !important;
	line-height: 24px;
	
	position: absolute;
	margin-top: 63px;
	vertical-align: top;

	border-spacing: 0px;
	border-top:1px solid rgba(204,204,204, 0.5);	
	border-bottom:1px solid rgba(204,204,204, 0.5);	

	background-color:rgba(255,255,255, 0.9);
/*
	
	font-size: .90em;
	font-weight: 400;
*/
	text-align:left;
	text-decoration:none;
	text-indent:1px;
	/*
	background: url(../images/buttons/butt_template.png) rgba(255,255,8, 0.4);

	background-size: 400px 400px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	*/
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_window_data02{
	z-index: 0;
	height:390px !important;
	width:222px !important;
	line-height: 54px;
	
	position: absolute;
	margin-top: 177px;
	vertical-align: top;

	border-spacing: 0px;
	border-top:hidden;	
	border-bottom:1px solid rgba(204,204,204, 0.5);	

	background-color:rgba(255,255,255, 0.9);
	
	font-size: .90em;
	font-weight: 400;
	text-align:left;
	text-decoration:none;
	text-indent:1px;
	/*
	background: url(../images/buttons/butt_template.png) rgba(255,255,8, 0.4);

	background-size: 400px 400px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	*/
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*

*/
	.item_media_panel_absolute {
		height:54px !important;
		width:70px !important;
		
		display:inline-block;
		margin-top:2px !important;
		margin-left:3px !important;
		vertical-align:top !important;
		text-align:left !important;
		
		border-spacing: 0px;
		border:hidden;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.item_media_panel_image{
		z-index: 0;
	  	height:54px !important;
		width:70px !important;
		
		position: absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
		background-size: 71px 52px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }

	.item_media_panel_count {
		height:40px !important;
		width:40px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.item_media_panel_button {
		height:40px !important;
		width:40px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*
event_item_window_data
*/
	.item_media_panel_add {
		height:118px !important;
		width:40px !important;
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		background: url(../images/buttons/butt_template.png) #999;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*

media_view_like_disabled
*/
	.event_item_div_like{
		z-index: 10;
		height:80px !important;
		width:80px !important;
		line-height: 80;
		vertical-align: middle;
		text-align: center;
		
		position: absolute;
	    margin-top: 10;
		margin-left: 865; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.event_item_div_dislike{
		z-index: 10;
		height:80px !important;
		width:80px !important;
		line-height: 80;
		vertical-align: middle;
		text-align: center;
		
		position: absolute;
	    margin-top: 10;
		margin-left: 775; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
/*
position:absolute;

*/
	.event_item_like_disabled{
		z-index: 0;
	  	height:80px !important;
		width:80px !important;
		
		display: inline-block;
		line-height: 12px;
		
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color:black;
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.event_item_like_enabled{
		z-index: 0;
	  	height:80px !important;
		width:80px !important;
		
		display: inline-block;
		line-height: 12px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color:black;
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.event_item_dislike_disabled{
		z-index: 0;
	  	height:80px !important;
		width:80px !important;
		
		display: inline-block;
		line-height: 12px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color:black;
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.event_item_dislike_enabled{
		z-index: 0;
	  	height:80px !important;
		width:80px !important;
		
		display: inline-block;
		line-height: 12px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color:black;
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.item_messagedisplaylistview_like_disabled{
		z-index: 0;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: -42;
		margin-left: 180px;
		
		color:black;
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.item_messagedisplaylistview_like_enabled{
		z-index: 0;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: -42;
		margin-left: 180px;
		
		color:black;
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.item_messagedisplaylistview_dislike_disabled{
		z-index: 0;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: -42;
		margin-left: 128px;
		
		color:black;
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.item_messagedisplaylistview_dislike_enabled{
		z-index: 0;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: -42;
		margin-left: 128px;
		
		color:black;
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	/*
	margin-top: -42;
		margin-left: 128px;
	position:absolute;

	*/
	

/************************//************************//************************/
	/************************//*	SWIPE_ITEM_VIEW_WINDOW	*/
	/********//*	SWIPE_ITEM_VIEW_MAIN	*/
/*
swipe_option_main_table_td


position:absolute;
*/
.carousel {
	height:606px !important;
	width:952px !important;
	background-color: rgba(255,255,255, 0.3);
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	margin-top: 30px;
	
}
/*
map_openlayer
background-color: #ddd;
height:589px !important;
*/
.select_pane-container {
	height:360px !important;
	width:950px !important;
	list-style: none;
	background-color: rgba(239,239,239, 0.0);
	
	margin-top: 0;
	margin-left: 0;
	padding: 0;
	position: relative;
}

	.pane-container {
		height:604px !important;
		width:952px !important;
		list-style: none;
		background-color: rgba(239,239,239, 0.0);
		margin: 0px;
		margin-top: 0px;
		padding: 0;
		position: relative;
	}

	.pane-container.transition {
	  	transition: left .2s ease-in-out;
	}

	.pane {
		height: 100%;
		width: 100%;
		text-align:left;
		vertical-align:text-top;
		background-color: rgba(239,239,239, 0.0);
	}
	.pane div {
		text-align: left;
		/*
		font-size: 3em;
		*/
		background-color: rgba(239,239,239, 0.0);
	}

	.vertical-center {
	  	position: relative;
	  	top: 50%;
	  	transform: translateY(-50%);
	}

	.carousel li.pane:nth-child(1) {
		background-color: rgba(239,239,239, 0.0);
	}
	.carousel li.pane:nth-child(2) {
	  	background: #4986e7;
	}

	.carousel li.pane:nth-child(3) {
	  	background: #d06b64;
	}

	.swipe_option_main_table{
		height:619px !important;
		width:952px !important;
		/*
		line-height:619px;
		*/
		border:1px solid #CCC; 
		background-color: rgba(239,239,239, 0.7);
		
		padding:0;
		margin:0;
		vertical-align:text-top;
	}
/*

*/
.swipe_option_return_div{
    z-index: 8;
    height:36px !important;
    width:100px !important;
    line-height: 36px;

    position: absolute;
    border:hidden;

    margin-top: 5px;
    margin-left: 0px;

    text-align:left;
    vertical-align:text-top!important;

    background-color:rgba(255,255,255, 0.1);
    padding:0;
}
.event_option_return_div{
    z-index: 8;
    height:36px !important;
    width:90px !important;
    line-height: 36px;

    position: absolute;
    border:hidden;

    margin-top: 5px;
    margin-left: 0px;

    text-align:left;
    vertical-align:text-top!important;

    background-color:rgba(255,255,255, 0.1);
    padding:0;
}
.home_option_return_div{
    z-index: 8;
    height:36px !important;
    width:100px !important;
    line-height: 36px;

    position: absolute;
    border:hidden;

    margin-top: 5px;
    margin-left: 100px;

    text-align:left;
    vertical-align:text-top!important;

    background-color:rgba(255,255,255, 0.1);
    padding:0;
}
/*

item_date_view
*/
.event_item_date_map_div{
    z-index: 20;
    height:32px !important;
    width:55px !important;
    line-height: 36px;

    position: absolute;
    border:hidden;

    margin-top: -20px;
    margin-left: 700px;

    text-align:left;
    vertical-align:text-top!important;

    background-color:rgba(255,180,255, 0.4);
    padding:0;
}
.select_date_active{
    height:30px !important;
    width: 50px !important;
	margin-left: 1px;
	margin-top: 1px;
	border:hidden;
    text-align:center !important;
    vertical-align:middle !important;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-size: 40px 20px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.select_map_active {
	height:30px !important;
    width: 50px !important;
	margin-left: 1px;
	margin-top: 1px;
	border:hidden;
    text-align:center !important;
    vertical-align:middle !important;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-size: 40px 20px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.select_date_map_view{
    height:515px; 
    width:668px;
    display:inline-block;	
    vertical-align:top;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-align:left;
    text-decoration:none;
    background-color:transparent;
    border: hidden;
}
.select_date_map_map_key_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:515px;

	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.select_date_map_map_search_div {
	z-index: 100;
	height:40px !important;
	width:510px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:5px;

	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.select_date_map_control_div {
	z-index: 10000;
	height:80px !important;
	width:100px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:400px;
	margin-left:10px;

	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(14,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.select_date_map_frame_div {
	z-index: 100000;
	height:60px !important;
	width:60px !important;
	/*
	display: inline-block;
	*/
	display: none;
	position: absolute;
	margin-top:300px;
	margin-left:0px;

	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,47, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
/*
map_key_div

*/
.select_profile_div{
	/*
	
	*/
    z-index: 8;
    height:70px !important;
    width:70px !important;
    line-height: 70px;

    position: absolute;
	margin-top: 0px;
    margin-left: 0px;
	
    border-right:1px solid rgba(239,239,239, 0.7);
	border-left:1px solid rgba(239,239,239, 0.7);
	border-top:1px solid rgba(239,239,239, 0.7);
	border-bottom:1px solid rgba(239,239,239, 0.7);
    background-color:rgba(255,255,255, 0.6);
    padding:0;
}
.select_profile_default{
    height:68px !important;
    width: 68px !important;
	position: absolute;
	margin-left: 1px;
	margin-top: 1px;
	border:hidden;
    text-align:center !important;
    vertical-align:middle !important;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-size: 68px 68px;
    background-repeat: no-repeat;
    
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.select_profile_active {
	z-index: 2000;
	height:14px !important;
	width:14px !important;

	border-spacing: 0px;
	border:hidden;
	position:absolute;
	
	margin-top:55px;
	margin-left:55px;

	background: url(../images/buttons/butt_template.png) rgba(240,239,136, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/****************************/
.map_key_select_option1 {
	height:25px !important;
	width:25px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	
	margin-top:0px;
	margin-left:0px;
	
	vertical-align:top;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.map_key_select_option2 {
	z-index: 100000;
	height:25px !important;
	width:25px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	
	margin-top:0px;
	margin-left:0px;
	
	vertical-align:top;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.map_key_select_option3 {
	z-index: 100000;
	height:25px !important;
	width:25px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	
	margin-top:0px;
	margin-left:0px;
	
	vertical-align:top;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.map_key_select_option4 {
	z-index: 100000;
	height:25px !important;
	width:25px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	
	margin-top:0px;
	margin-left:0px;
	
	vertical-align:top;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*****************************/
	.select_option_item_div{
		z-index: 8;
		height:36px !important;
		width:100px !important;
		line-height: 36px;
		
		position: absolute;
		border:hidden;
		
		margin-top: 50px;
		margin-left: 10px;
		
		text-align:left;
		vertical-align:text-top!important;
		
		background-color:rgba(255,255,22, 0.6);
		padding:0;
	}
	.select_listview_filter_option {
		height:36px !important;
		width:98px !important;
		display:inline-block;
		/*
		position: relative;
		
		left: 10px;
		top: 10px;
		*/
		border-right:1px solid #CCC;
		border-left:1px solid #CCC;
		border-top:1px solid #CCC;
		border-bottom:1px solid #CCC;
		border-spacing: 0px;
		
		background: url(../images/buttons/butt_template.png);
		background-size: 90px 32px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*


*/
	
	
	.swipe_option_main_table_td{
		height:606px !important;
		width:952px !important;

		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		/*
		background-color:#FFF;
		
		background-color: rgba(239,239,239, 0.7);
		*/
		padding:0;
		margin:0;
		text-align:right;
		vertical-align:middle !important;
	}
/*
swipe_view_window_menu_bottom
*/
.swipe_view_window{
    z-index: 0;
    height:606px !important;
    width:952px !important;
	position: absolute;
	top: 1px;

    border-top:hidden;
    border-bottom:hidden;
    border-left:hidden;
    border-right:hidden;
    /*
    background-color:#FFF;
    */
    background-color: rgba(239,239,239, 0.7);

    padding:0;
    margin:0;
    text-align:center;
    vertical-align:top !important;
}
/*
swipe_view_div_option_menu

*/
	.swipe_image_view_window {
		z-index: 100;
	  	height:587px !important;
		width:790px !important;
		line-height: 587px;
		
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 17px;
		margin-left: 0px;
		
		color: white;
		text-align:center;
		vertical-align:top;
		text-decoration:none;
		
		/*clear*/
		background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
		
		background-size: 790px 587px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*

media_view_map_swipe
select_date_map_map_key_div
canvas
*/
.swipe_map_view_window {
	/*
    z-index: 100;
	*/
	height:590px !important;
	
    width: 950px !important;	
	/*
    height:587px !important;
    width:790px !important;
    line-height: 587px;
	
	*/
	position:absolute !important;
	
	/*
    display: inline-block;
	
	display:none;
	*/
    border-spacing: 0px;
    border:hidden;
	/*
    margin-top: 17px;
	*/
	margin-top: 2px;
    margin-left: 2px !important;
	
	padding: 0px  !important;

    color: white;
    text-align:left !important;	
    vertical-align:top !important;	
    text-decoration:none;

    /*clear
    background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);

    background-size: 950px 587px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	pane
	*/
} 
#map_openlayer {
	height:590px !important;
    width:100% !important;
	position: absolute;
	text-align: left  !important;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 


.swipe_map_key_div {
	z-index: 10000;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute !important;
	margin-top:30px;
	margin-left:650px;

	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,58, 0.8);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
	.swipe_multi_view_window {
		z-index: 100;
	  	height:587px !important;
		width:830px !important;
		line-height: 587px;
		
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 17px;
		margin-left: 0px;
		
		color: white;
		text-align:center;
		vertical-align:top;
		text-decoration:none;
		
		/*clear*/
		background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.1);
		
		background-size: 790px 587px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*

line-height: 589px;
margin-top: 17px;
*/
	.swipe_multi_image_view_window {
		z-index: 0;
	  	height:589px !important;
		width:926px !important;
		
		
		position: relative;
		
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 17px;
		margin-left: 0px;
		
		padding: 0px !important;
		
		color: white;
		text-align:left !important;
		vertical-align:middle !important;
		text-decoration:none;
		
		/*clear*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
		
		background-size: 790px 589px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.swipe_image_menu_pagination_div{
		z-index: 0;
	  	height:589px !important;
		width:926px !important;
		
		
		position: relative;
		
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 17px;
		margin-left: 0px;
		
		padding: 0px !important;
		
		color: white;
		text-align:left !important;
		vertical-align:middle !important;
		text-decoration:none;
		
		/*clear*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
		
		background-size: 790px 589px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.swipe_stat_view_window {
		z-index: 0;
	  	height:604px !important;
		width:948px !important;
		line-height: 24;
		
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 0;
		margin-left: 0px;
		
		color: white;
		text-align:center;
		vertical-align:text-top;
		text-decoration:none;
		
		background: url(../images/buttons/clear_img.png) rgba(255,255,145, 0.7);
		
		
		background-size: 545px 389px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*

height:569px !important;
margin-top: 25px;
carousel
select_container_td
select_feed_container_td
select_pane-container
*/
.select_carousel {
	height:360px !important;
	width:950px !important;
	background-color: rgba(255,255,255, 0.3);
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin-left: 0px;
	margin-top: 5px;
	text-align:center !important;
	vertical-align:top !important;
}
.swipe_select_panel_window{
	z-index: 10;
	height:356px !important;
	width:910px !important;

	display: inline-block;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.2);
	
	margin-left: 0px;
	margin-top: 0px;
	padding:0;
	color: white;
	text-align:left !important;
	vertical-align:top !important;

	text-decoration:none;
	background-color: rgba(255,255,255, 0.3);

	/*
	background: url(../images/buttons/butt_template.png) rgba(255,255,8, 0.4);

	background-size: 400px 400px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	*/

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	
}
/*
.pane div

.event_item_window_main
*/
.swipe_panel_window{
	z-index: 0;
	height:576px !important;
	width:920px !important;

	display: inline-block;
	border-spacing: 0px;
	border:1px solid #CCC;
	/*

	margin-top: 20px;
margin-left: 2px;
	*/
	margin-left: 15px;
	margin-top: 0px !important;

	color:rgba(15, 5, 26, 0.6);
	text-align:center;
	vertical-align:top !important;

	text-decoration:none;
	background-color: rgba(255,44,255, 0.7);

	/*
	background: url(../images/buttons/butt_template.png) rgba(255,255,8, 0.4);
	
	background-size: 400px 400px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	*/

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

	.swipe_view_window_menu_top{
		z-index: 10;
		height:60px !important;
		width:950px !important;
		
		position: absolute;
	    margin-top: 0;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background-color:rgba(255,255,255, 0.4);
		padding:0;
		
		vertical-align:middle !important;
	}
/*
background-color:rgba(2,63,76, 0.7);

*/
.swipe_view_window_menu_bottom{
    z-index: 8;
    height:100px !important;
    width:950px !important;
	/*
    position: absolute;
	
    margin-top: 506;
	*/
	margin-top: 0px;
    margin-left: 0px; 

    border:1px solid #CCC;
    background-color:rgba(255,255,255, 0.7);
    padding:0;

    vertical-align:middle !important;
}
	/*
	item_media_panel_add
media_view_div_report
	*/
	.swipe_option_upload_div{
		z-index: 8;
		height:80px !important;
		width:80px !important;
		position: absolute;

		border:hidden;

		margin-top: -5px;
		margin-left: 200px;

		background-color:rgba(255,255,41, 0.7);
		padding:0;

		text-align:right;
		vertical-align:middle !important;
	}
/*


*/
	.swipe_media_panel_add {
		height:80px !important;
		width:80px !important;
		
		position: absolute;
		
		vertical-align:middle;
		text-align:center;
		
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		
		
		background: url(../images/buttons/plus_button.png) rgba(239,239,239, 0.7); 
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
/*
	ls_form_half_field
	background-color:rgba(239,239,239, 0.3);
	media_view_dislike_enabled

*/	
	

	.swipe_view_window_back{
		z-index: 8;
		height:400px !important;
		width:10px !important;
		
		position: absolute;
		top: 100px;
		/*
	    margin-top: 100;
		*/
		margin-left: 0px; 
	
		border:1px solid #CCC;
		background-color:lightgray;
		padding:0;
		
		vertical-align:text-top !important;
	}
	.swipe_view_window_forward{
		z-index: 8;
		height:400px !important;
		width:10px !important;
	
		position: absolute;
		top: 100px;
		/*
		margin-top: 100;
		*/
		margin-left: 942px; 
		
		border:1px solid #CCC;
		background-color:lightgray;
		padding:0;
		
		vertical-align:text-top !important;
	}
/*
	
*/
	.swipe_view_window_pagination_menu{
		z-index: 7;
		height:10px !important;
		width:600px !important;
		/*
		margin-top: 598;
		margin-left: 179px; 
		position: absolute;
		*/
		
		position: absolute;
		margin-top: -198;
		margin-left: 179px; 
		
		border:1px solid #CCC;
		background-color:lightgray;
		padding:0;
		
		vertical-align:middle !important;
	}
/*
	
*/
	
	.swipe_view_forward_button_active {
		z-index: 11;
	  	height:400px !important;
		width:90px !important;
		
		display: inline-block;
		line-height: 12px;
		
		position: absolute;
		top: 0px;
		left:-75px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		/*
		margin-left: 70;
		*/
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
		background-size: 30px 380px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.swipe_view_forward_button_disabled {
		z-index: 11;
	  	height:400px !important;
		width:90px !important;
		
		display: inline-block;
		line-height: 12px;
		
		position: absolute;
		top: 0px;
		left:-75px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		/*
		margin-left: -70;
		*/
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
		background-size: 30px 380px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.swipe_view_back_button_active {
		z-index: 11;
	  	height:400px !important;
		width:90px !important;
		
		display: inline-block;
		line-height: 12px;
		
		position: absolute;
		top: 0px;
		left:0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
		background-size: 30px 380px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.swipe_view_back_button_disabled {
		z-index: 11;
	 	height:400px !important;
		width:90px !important;
		
		display: inline-block;
		line-height: 12px;
		
		position: absolute;
		top: 0px;
		left:0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
		background-size: 30px 380px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/************************//************************//************************//************************/
	/************************//************************//************************//************************/
	
	/************************//************************//************************//************************/
	/************************//************************//************************//************************/
	/************************/
	/*	EVENT OCCURRENCE OPTIONS	
	
		event_option_main_table_td_occurrence1
		event_option_main_table_td_occurrence1
		event_option_main_table_td_guidelines
		
		border-top:hidden;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
	*/
	.event_occurrence_td{
		height: 90px;
		width: 130px;
		line-height:90px;
		border-top:hidden;
		background-color:transparent;
		vertical-align:middle;
		text-align:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_occurrence_once_active{
		height: 88px;
		width: 128px;
		line-height:88px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #39F;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_occurrence_once_default{
		height: 88px;
		width: 128px;
		line-height:88px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_occurrence_once_active_listview{
		height: 40px;
		width: 60px;
		line-height:40px;
		border:1px solid #CCC;
		margin-top:90px;
		margin-left:170px;
		
		position:absolute;
		font-size: .75em;
		font-weight: 400;
		color:#666;
		background: url(../images/buttons/butt_template.png) #39F;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_occurrence_reoccuring_active {
		height: 88px;
		width: 128px;
		line-height:88px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_occurrence_reoccuring_default {
		height: 88px;
		width: 128px;
		line-height:88px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_occurrence_reoccuring_active_listview {
		height: 40px;
		width: 60px;
		line-height:40px;
		border:1px solid #CCC;
		margin-top:90px;
		margin-left:170px;
		
		position:absolute;
		font-size: .75em;
		font-weight: 400;
		color:#666;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_occurrence_multi_active{
		height: 88px;
		width: 128px;
		line-height:88px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #FCF;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_occurrence_multi_default{
		height: 88px;
		width: 128px;
		line-height:88px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_occurrence_multi_active_listview{
		height: 40px;
		width: 60px;
		line-height:40px;
		border:1px solid #CCC;
		margin-top:90px;
		margin-left:170px;
		
		position:absolute;
		font-size: .75em;
		font-weight: 400;
		color:#666;
		background: url(../images/buttons/butt_template.png) #FCF;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_occurrence_add{
		height: 50px;
		width: 50px;
		line-height:33px;
		
		background: url(../images/buttons/plus_button.png) #FFF;
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	button_add_button .png
	form_date_ajax_view
	
	base_functional_listview_control_menutd1
	
	*/
	.event_description_add{
		height: 36px;
		width: 100px;
		line-height:33px;
		background: url(../images/buttons/plus_button.png) #FFF;
		background-position:center;
		background-size: 34px 34px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_description_remove{
		height: 50px;
		width: 118px;
		line-height:40px;
		background: url(../images/buttons/butt_template.png) #0CF;
		background-position:center;
		background-size: 34px 34px;
   		background-repeat: no-repeat;
		border:1px solid #CCC;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_description_oneoccurring{
		height: 50px;
		width: 118px;
		line-height:40px;
		border:1px solid #CCC;
		vertical-align:top;
		font-size: .80em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/butt_template.png) #9C0;
		background-position:right;
		background-size: 34px 34px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_description_reoccurring{
		height: 50px;
		width: 118px;
		line-height:40px;
		border:1px solid #CCC;
		vertical-align:top;
		font-size: .80em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/butt_template.png) #36C;
		background-position:center;
		background-size: 34px 34px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_description_multioccurring{
		height: 50px;
		width: 118px;
		line-height:40px;
		border:1px solid #CCC;
		vertical-align:top;
		font-size: .80em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/butt_template.png) #36C;
		background-position:center;
		background-size: 34px 34px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_description_pending{
		height: 50px;
		width: 118px;
		line-height:40px;
		border:1px solid #CCC;
		vertical-align:top;
		font-size: .80em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/butt_template.png) #FF9;
		background-position:center;
		background-size: 34px 34px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_description_cancel{
		height: 50px;
		width: 118px;
		line-height:40px;
		border:1px solid #CCC;
		vertical-align:top;
		font-size: .80em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background: url(../images/buttons/butt_template.png) #F30;
		background-position:center;
		background-size: 34px 34px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_description_v_space{
		height:243px; 
	  	width:952px;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background-color:#FC0; 
		border:hidden;
		display:inline-block;
	}
	.event_description_h_space{
		height:126px; 
	  	width:153px;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background-color:#FC0; 
		border:hidden;
		display:inline-block;
	}
	.event_description_main{
		height:126px; 
	  	width:952px;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		background-color:#FFF; 
		border:hidden;
		display:inline-block;
	}
	.event_description_title{
		height:60px; 
	  	width:500px;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background-color:#FFF; 
		border:thin #CCC solid;
		display:inline-block;
	}
	.event_description_dates{
		height:60px; 
	  	width:250px;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background-color:#FFF; 
		border:thin #CCC solid;
		display:inline-block;
	}
	.event_description_button{
		height:50px; 
	  	width:142px;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:left;
		text-decoration:none;
		background-color:#FFF; 
		border:thin #CCC solid;
		display:inline-block;
	}
	.event_description_pop_add {
		height: 125px;
		width: 142px;
		line-height:33px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		position:absolute;
		border:1px solid #CCC;
		background: url(../images/buttons/plus_button.png) #FFF;
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*
position: absolute;
margin-left:4px;
		margin-right:2px;
		margin-top:5px;
		margin-bottom:5px;	
font-size: .80em;
		white-space: normal;
		font-weight: 600;
form_item_disabled

*/
.form_date_ajax_view_td{
    height:558px; 
    width:735px;
    position: absolute;
    display:inline-block;
    vertical-align:middle;
    text-align: center;
    text-decoration:none;
    background-color:rgba(255,255,255, 0.9);
    border:hidden;
}

/*

	width:952px;
position: absolute;
margin-left:4px;
		margin-right:2px;
		margin-top:5px;
		margin-bottom:5px;
transparent
background-color:#93CD97; 

*/
.form_date_ajax_view{
    height:558px; 
    /*width:735px;*/
    display:inline-block;	
    vertical-align:top;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color: rgba(255,255,255, 0.7);
    border: 1px solid rgba(204,204,204, 0.3);
	table-layout: fixed;
	width: 96% !important;
}

.form_profile_date_ajax_view{
    
    display:inline-block;	
    vertical-align:top;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color: rgba(255,255,255, 0.7);
    border: 0px solid rgba(204,204,204, 0.0);
	table-layout: fixed;
	height:90% !important;
	width: 96% !important;
}
	
	#infowindow-content .title {
        font-weight: bold;
      }

      #infowindow-content {
        display: none;
      }

	#location_search_results #infowindow-content {
        display: inline;
      }

/*
margin-right:2px;
overflow-y:hidden;
margin-bottom:5px;
font-size: .80em;
		white-space: normal;
		font-weight: 600;
vertical-align:top;
text-align:center;

		
		text-decoration:none;

*/
	.form_date_ajax_listview_info{
		height:40 !important; 
	  	width:205px !important;
		display:inline-block;
		position: absolute;
		background-color:#FFFFFF;
		border:thin #CCC solid;
		
		margin-left:740px;
		margin-top:50px;
		
	}
/*

*/
	.form_date_ajax_listview_info_text{
		height:38 !important; 
	  	width:150px !important;
		line-height: 38px;
		display:inline-block;
		position: absolute;
		background-color:#FFFFFF;
		border:hidden;
		vertical-align:middle;
		font-size: 1.10em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		margin-left:1px;
		margin-top:1px;
	}
	.form_date_ajax_listview_info_count{
		height:36 !important; 
	  	width:52px !important;
		line-height: 38px;
		display:inline-block;
		position: absolute;
		background-color:#EFEFEF;
		border:thin #CCC solid;
		vertical-align:middle;
		color:#000000;
		font-size: 1.50em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		margin-left:150px;
		margin-top:1px;
	}
/*
height:515 !important;
overflow: auto;
ul_listview
*/
	.form_date_ajax_listview{
		z-index: 150;
		height:515 !important; 
	  	width:205px !important;
		display:inline-block;
		position: absolute;
		background-color:#FFFFFF;
		border:thin #CCC solid;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		margin-left:740px;
		margin-top:95px;
		
		
		
	}
/*
	z-index: 150;
	display:inline-block;
		position: absolute;
		background-color:transparent;
		border:thin #CCC solid;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		margin-left:740px;
		margin-top:95px;

*/
	.ul_listview{
		height:515 !important; 
	  	width:210px !important;
	}
/*
position: absolute;
margin-left:740px;
		
		margin-top:50px;
z-index: 140;
197
text-align: center;

*/
	.form_date_ajax_listview_item{
		height:50px !important; 
	  	width:197px !important;
		line-height: 50px;
		
		position:relative;
		
		border:thin #CCC solid;
		vertical-align:middle;
		
		text-decoration:none;	
		font-size: 1.25em;
		white-space: normal;
		font-weight: 600;
		display:inline-block;
		background-color:#EFEFEF;
		background-image:url(../images/buttons/butt_template.png);
		background-position:5% 45%; 
		background-size: 50px 40px;
		background-repeat:no-repeat;
	}

	
	.form_date_ajax_listview_item_frame{
		height:50px !important; 
	  	width:197px !important;
		line-height: 50px;
		
		position:relative;
		
		border:thin #CCC solid;
		vertical-align:middle;
		
		text-decoration:none;	
		font-size: 1.25em;
		white-space: normal;
		font-weight: 600;
		display:inline-block;
		background-color:#EFEFEF;
	}

	/*
	height:50px !important; 
	  	width:197px !important;
	line-height: 50px;


	*/
	.form_date_ajax_listview_item_pending{
		z-index: 99;
		height:35px !important; 
	  	width:35px !important;
		line-height: 50px;
		top: 5px;
		left: 2px;
		position:absolute;
		
		
		border:thin #CCC solid;
		vertical-align:middle;
		text-align: center;
		text-decoration:none;	
		display:inline-block;
		background-color:#EFEFEF;
		background-image:url(../images/buttons/pending_item.png);
		background-position:1% 45%; 
		background-size: 35px 35px;
		background-repeat:no-repeat;
		border-radius: 50%;
	}
	.form_date_ajax_listview_item_complete{
		z-index: 99;
		height:35px !important; 
	  	width:35px !important;
		line-height: 50px;
		top: 5px;
		left: 2px;
		position:absolute;
		
		
		border:thin #CCC solid;
		vertical-align:middle;
		text-align: center;
		text-decoration:none;	
		display:inline-block;
		background-color:#EFEFEF;
		background-image:url(../images/buttons/sys_option_correct_active.png);
		background-position:1% 45%; 
		background-size: 35px 35px;
		background-repeat:no-repeat;
		border-radius: 50%;
	}
	/*
	.form_date_ajax_listview_clr_div{
		z-index: 99;
		height:45px !important; 
	  	width:45px !important;
		line-height: 50px;
		top: 1px;
		left: 2px;
		background-color:white;
		position:absolute;
		
		border:thin #CCC solid;
		vertical-align:middle;
		text-align: center;
		text-decoration:none;	
		display:inline-block;
		
	}
*/
/*

*/
	.form_item_selected{
		z-index: 999 !important;
		border: hidden;
		border: thin #090 solid  !important;
		
	}
	.form_item_non_selected{
		z-index: 999 !important;
		border: thin #EFEFEF solid;
	}
	.form_item_disabled{
		z-index: 999 !important;
		border: thin #EFEFEF solid;
		color:#CCC !important;
	
	}
	.form_item_active{
		z-index: 100 !important;
		font-size: .85em;
		font-weight: 500;
		color:black !important;
		text-align:left;
		text-decoration:none;
		text-indent:34px;
	}
	/*
margin-top: 50px;
margin-left: -40px;
margin-left: -10px;
close

	*/
	.form_date_ajax_listview_item_div{
		z-index: 140;
		height:45px !important; 
	  	width:45px !important;
		line-height: 50px;
		top: 1px;
		left: 150px;
		
		position:absolute;
		
		border:thin #CCC solid;
		vertical-align:middle;
		text-align: center;
		text-decoration:none;	
		font-size: 1.25em;
		white-space: normal;
		font-weight: 600;
		display:inline-block;
		background-color:white;
		background-image:url(../images/buttons/sys_close_buttonDefault.png) ;
		background-position:70% 45%; 
		background-size: 45px 45px;
		background-repeat:no-repeat;
	}
	/*


*/
	.form_date_ajax_listview_text_div{
		z-index: 190;
		height:25px !important; 
	  	width:155px !important;
		line-height: 25px;
		top: 10px;
		left: 15px;
		
		position:absolute;
		
		border:thin #CCC solid;
		vertical-align:top;
		text-align: center;
		text-decoration:none;	
		font-size: 1.20em;
		white-space: normal;
		font-weight: 600;
		color:black;
		display:inline-block;
		background-color:transparent;
	}
	
	/*

	
	
	
	*/
	.form_occurrence_table{
		height:120px; 
	  	width:300px;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background-color:#CCF; 
		border:thin #CCC solid;
		display:inline-block;
		margin-left:4px;
		margin-right:2px;
		margin-top:5px;
		margin-bottom:5px;
	}
	.form_occurrence_add{
		height:45px; 
	  	width:45px;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background-color:#0C9;
		border:thin #CCC solid;
		display:inline-block;
		margin-left:4px;
		margin-right:2px;
		margin-top:5px;
		margin-bottom:5px;
	}
	.form_occurrence_modify{
		height:60px; 
	  	width:50px;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background-color:#F99; 
		border:thin #CCC solid;
		display:inline-block;
		margin-left:4px;
		margin-right:2px;
		margin-top:5px;
		margin-bottom:5px;
	}
	.form_occurrence_delete{
		height:60px; 
	  	width:50px;
		vertical-align:top;
		font-size: .80em;
		white-space: normal;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		background-color:#939; 
		border:thin #CCC solid;
		display:inline-block;
		margin-left:4px;
		margin-right:2px;
		margin-top:5px;
		margin-bottom:5px;
	}
	
	
	/************************/
	/*	EVENT THEME OPTIONS	
	
		
		
	*/
	
	.theme_view_control_title_select_structure {
		z-index:2;
		display:inline-block;
		position:absolute;
		top:7px;
		left:578px;
		font-size: 1em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.theme_view_control_td_visible_cell_follow {
		height:45px;
		width:140px;
		display:inline-block;
		position:relative;
		vertical-align:top;
		border:1px solid #ccc;
		background: url(../images/buttons/butt_template.png) #41B43F;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:99% 45%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	.theme_view_control_td_visible_cell_unfollow {
		height:45px;
		width:140px;
		display:inline-block;
		position:relative;
		vertical-align:top;
		border:1px solid #ccc;
		background: url(../images/buttons/butt_template.png) #ED191C;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:99% 45%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
/*
theme_view_control_title_edit_structure
event_option_main_table_td_command

*/
	.theme_view_control_multifunction_structure {
		z-index:2;
		display:inline-block;
		position:absolute;
		top:215px;
		left:641px;
		font-size: 1em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.theme_view_control_multifunction_map {
		height:45px;
		width:80px;
		display:inline-block;
		position:relative;
		vertical-align:top;
		border:1px solid #ccc;
		background: url(../images/buttons/butt_template.png) #4ED0B8;
		background-size: 60px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	.theme_view_control_multifunction_parent{
		height:45px;
		width:80px;
		display:inline-block;
		position:relative;
		vertical-align:top;
		border:1px solid #ccc;
		background: url(../images/buttons/butt_template.png) #F8AC35;
		background-size: 60px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}

	.theme_reset_control_multifunction_structure {
		z-index:2;
		display:inline-block;
		position:absolute;
		top:215px;
		left:590px;
		font-size: 1em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.theme_view_control_multifunction_map_reset{
		height:45px;
		width:45px;
		display:inline-block;
		position:relative;
		vertical-align:top;
		border:1px solid #ccc;
		background: url(../images/buttons/butt_template.png) rgba(45,255,74, 0.5);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%; 
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	/*


	*/
	.event_theme_error {
		height: 257px;
		width: 718px;
		position:absolute;
		display:inline-block;
		background-color:#999;
		background-position:center;
		background-size:  cover;
   		background-repeat: no-repeat;
		border-style:solid;
		border-width:thin;
		border-color:#F00;
		border-spacing:0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*


	
	.event_theme_default2 {
		height: 40px;
		width: 125px;
		line-height:33px;
		background: url(../images/buttons/butt_template.png) #09C;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_theme_default3 {
		height: 40px;
		width: 125px;
		line-height:33px;
		background: url(../images/buttons/butt_template.png) #FCF;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_theme_default4 {
		height: 40px;
		width: 125px;
		line-height:33px;
		background: url(../images/buttons/butt_template.png) #996;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_theme_default5 {
		height: 40px;
		width: 125px;
		line-height:33px;
		background: url(../images/buttons/butt_template.png) #F60;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
*/	
	/************************/
	/*	EVENT CLASS SETTINGS	*/

	
	/************************/
	/************************/
	.select_full_listview_filter_menu {
		height:36px !important;
		width:950px !important;
		display:inline-block;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.select_full_listview_table_td_r1 {
		height:582px !important;
		width:950px !important;
		border:hidden;
		border-spacing: 0px;
		vertical-align:middle;
		text-align:left;
	} 

	
	/*

	*/
	.event_add_create_default{
		height:185px !important;
		width:181px !important;
		margin-top: 5px;
		margin-left: 5px;
		display: inline-block;
		position:absolute;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #CCC;
		background-position:center;
		background-size: 170px 170px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_space_top{
		height:120px !important;
		width:951px !important;
		background-color:#EFEFEF;
		border:hidden;
		padding:0;
		margin:0;
	
	}
	
	.event_selection_space_bottom{
		height:120px !important;
		width:951px !important;
		background-color:#EFEFEF;
		border:hidden;
		padding:0;
		margin:0;
		
	}
	.event_selection_main_table{
		height:380px !important;
		width:951px !important;
		line-height:380px;
		border:0; 
		padding:0;
		margin:0;
		vertical-align:middle !important;
	}
	
	.event_selection_main_table_td{
		height:378px !important;
		width:315px !important;
		line-height:378px;
		border:1px solid #CCC;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	/************************/
	/*	EVENT OPTION    	*/
	/*
		width:951px !important;
		event_option_main_table_td_other
	
	*/
	.event_option_space_top{
		height:20px !important;
		width:720px !important;
		background-color:#EFEFEF;
		border:hidden;
		padding:0;
		margin:0;
	}
	.event_option_space_bottom{
		height:20px !important;
		width:720px !important;
		background-color:#EFEFEF;
		border:hidden;
		padding:0;
		margin:0;
	}
/*


*/
/************************************************/
	.event_option_full_main_table{
		height:618px !important;
		width:720px !important;
		line-height:618px;
		border:1px solid #CCC; 
		padding:0;
		margin:0;
		vertical-align:middle !important;
	}
	.event_option_full_main_table_td{
		height:618px !important;
		width:720px !important;
		line-height:618px;
		border:hidden; 
		padding:0;
		margin:0;
		vertical-align:middle !important;
	}

	
	
	/************************************************/
/*

*/
	.event_option_main_table{
		height:615px !important;
		width:718px !important;
		line-height:615px;
		border:1px solid #CCC; 
		
		padding:0;
		margin:0;
		vertical-align:top;
	}
	.event_option_main_table_td1{
		height:254px !important;
		width:718px !important;
		line-height:254px;
		
		border: hidden;
		padding:0;
		margin:0;
		vertical-align:text-bottom !important;
	}
/*
border:1px solid #CCC; 
*/
	.event_option_main_table_td2{
		height:361px !important;
		width:718px !important;
		line-height:361px;
		
		border-top:1px solid #CCC;  
		border-bottom:hidden;  
		border-left:hidden; 
		border-right:hidden; 
		
		padding:0;
		margin:0;
		vertical-align:top !important;
	}

	/*	EVENT event_option_main_table_td_type    	*/
	.event_option_main_table_td_type{
		height:150px !important;
		width:720px !important;
		line-height:170px;
		border:hidden;
		background-color:#6FF;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}

.event_standard_left_td_metrics {
	height: 315px;
	width: 160px !important;
    margin-top: 0px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 0px solid rgba(239,239,239, 0.0);
	background-color:rgba(255,255,255, 0.4);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:center;
}
/*
event_display_left_td_metrics
item_display_active
*/
.event_option_main_table_td_type_div{
    height:125px !important;
    width:670px !important;
    /*
    line-height:24px;
    */
    position:absolute;
    left:30px;
    top:126px;
    margin:0;
    border:hidden;
    background-color:transparent;
    text-align:center;
    vertical-align:middle !important;
}
.event_option_main_table_td_display_type_div{
    height:125px !important;
    width:670px !important;
    /*
    line-height:24px;
    */
    position:absolute;
    left:25px;
    top:120px;
    margin:0;
    border:hidden;
    background-color:transparent;
    text-align:center;
    vertical-align:middle !important;
}
/*


*/
.event_swipe_option_main_table_td_type_div{
    height:125px !important;
    width:670px !important;
    /*
    line-height:125px;
    */
    position:absolute;
    left:30px;
    top:120px;
    margin:0;
    text-align:center;
    vertical-align:middle !important;
    border:hidden;
    background-color:transparent;
    padding:0;
}
/*
	

*/
	.event_option_main_table_td_submit_div{
		z-index: 10;
		height:60px !important;
		width:210px !important;
		position:absolute;
		left:-75px;
		top:205px;
		line-height:12px;
		border:hidden;
		background-color:rgba(255,255,255, 0.1);
		padding:0;
		margin-left:585px;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_command_div{
		height:60px !important;
		width:110px !important;
		line-height:45px;
		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}

	.event_option_main_submit_button {
		height: 50px;
		width: 100px;
		line-height:33px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color:#EFEFEF; 
		border:1px solid #CCC;
		background: url(../images/buttons/sys_settings_submit.png) #99F;
		background-position:center;
		background-size: 90px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_option_main_contribute_button {
		height: 100px;
		width: 100px;
		line-height:33px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color:#EFEFEF; 
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #7FDF53;
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*

trash
delete
*/
	.event_option_main_delete_button {
		height: 50px;
		width: 100px;
		line-height:33px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color:#EFEFEF; 
		border:1px solid #CCC;
		background: url(../images/buttons/sys_delete_buttonDefault.png) #F51B1F;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_option_main_view_button {
		height: 50px;
		width: 100px;
		line-height:33px;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color:#EFEFEF; 
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #7FDF53;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	/*
event_option_main_table_td_type_div
	*/
.event_option_main_table_td_type1{
    z-index: 10;
    height:125px !important;
    width:520px !important;
    /*
    line-height:125px;
    */
    position:absolute;
    margin-left:143px;
    vertical-align:text-top!important;
    border:hidden;
    background-color:transparent;
    padding:0;
    text-align:left;
    text-indent:2px;
}
	/*
		event_date_edit
event_swipe_option_main_table_td_type_div
event_swipe_main_table_td2
event_option_main_table_td_type1
	*/
.event_option_main_table_td_type2{
    z-index: 10;
    height:125px !important;
    width:130px !important;
    /*
    line-height:125px;
    */
    position:absolute;
    margin-top: -6px;
    margin-left: 3px;
    border:hidden;
    background: rgba(255,255,255, 0.0);
    text-align:left;
    vertical-align:top!important;
}
	.event_option_main_table_td_type2_QR{
		height:100px !important;
		width:100px !important;
		line-height:100px;
		position: absolute;
		margin-top: 155px;
		margin-left: 345px;
		
		border:1px solid #CCC;
		background-color:aqua;
		padding:0;
	
		text-align:center;
		vertical-align:middle !important;
	}
	
	.event_item_settings_main_window{
		height:619px !important;
		width:952px !important;
		display: inline-block;
		
		line-height:619px;
		border:hidden; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top;
	}
/*
width:718px !important;	-50
width:668px !important;

*/
	.event_item_settings_main_table{
		height:620px !important;
		width:668px !important;
		line-height:620px;
		border:hidden; 
		
		padding:0;
		margin:0;
		vertical-align:top !important;
	}
	.event_item_settings_main_table_td1{
		height:49px !important;
		width:668px !important;
		line-height:49px;
		
		border: hidden;
		padding:0;
		margin:0;
		vertical-align:top !important;
	}

	.event_item_settings_main_table_td2{
		height:571px !important;
		width:668px !important;
		line-height:571px;
		
		border-top:1px solid #CCC;  
		border-bottom:1px solid #CCC;  
		border-left:hidden; 
		border-right:hidden; 
		/*
			event_item_geo_map
		*/
		padding:0;
		margin:0;
		vertical-align:top !important;
		text-align: left !important;
	
	}
	
	.event_item_settings_panel_table{
		height:620px !important;
		width:280px !important;
		line-height:610px;
		border:hidden; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top;
	}

	.event_item_settings_main_div{
		z-index: 1;
		height:49px !important;
		width:668px !important;
		line-height:49px;
		
		position: absolute;
		background-color: transparent;
		
		border: hidden;
		padding:0;
		margin:0;
		vertical-align:top !important;
	}
	.event_item_settings_main_td1{
		z-index: 2;
		height:49px !important;
		width:359px !important;
		line-height:49px;
		
		border: hidden;
		padding:0;
		margin:0;
		vertical-align:top !important;
		text-align: right;
	}
	.event_item_settings_main_td2{
		z-index: 2;
		height:49px !important;
		width:103px !important;
		line-height:49px;
		
		border: hidden;
		padding:0;
		margin:0;
		vertical-align:top !important;
		text-align: center;
	}
	.event_item_main_default{
		height:39px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #CCC;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_item_main_active{
		height:39px !important;
		width: 99px !important;
		text-align:center !important;
		vertical-align:middle !important;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.event_item_settings_panel_table_td1{
		height:60px !important;
		width:278px !important;
		line-height:49px;
		border-top:1px solid #CCC;
		border-right:1px solid #CCC; 
		border-left:1px solid #CCC; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top;
	}
	.event_item_settings_panel_table_td2{
		height:559px !important;
		width:278px !important;
		line-height:550px;
		border:1px solid #CCC; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top;
	}
	.event_item_settings_panel_div{
		height:556px !important;
		width:278px !important;
		
		border:hidden; 
		vertical-align:top !important;
		background-color:#EFEFEF;
		
		margin-top: 0px;
		
		/*
		position: relative !important;
		line-height:550px;
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		*/
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_item_settings_panel_rw{
		height:78px !important;
		width:278px !important;
		line-height:77px  !important;
		
		border:hidden; 
		
		
		vertical-align:middle !important;
		margin-top: 1px;
		
		position: relative;
		display: block;
		background-color:transparent;
		/*
		position: relative !important;
		line-height:550px;
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		*/
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_item_settings_panel_empty {
		height:530px !important;
		width:260px !important;
		line-height:530px;
		
		position: absolute;
		
		background:#999;
		border: hidden;
		text-align:center;
		vertical-align:middle;
		
		border-spacing: 0px;
		background: url(../images/buttons/butt_template.png);
		background-size: 180px 300px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_item_settings_absolute {
		height:76px !important;
		width:86px;
		line-height:76px  !important;
		border-spacing: 0px;
		border:1px solid #CCC;
		display:inline-grid;
		
		/*
		position:fixed !important;
		*/
		margin-top:0px !important;
		margin-left:1px !important;
		vertical-align:middle !important;
		text-align:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	} 
/*************************//************************/
/************************//************************/
/*
event_item_geo_mapkey
*/
.event_item_settings_mapkey_table{
	height:620px !important;
	width:950px !important;
	line-height:620px;
	border:hidden; 
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top !important;
}
.event_item_settings_mapkey_table_td1{
	height:49px !important;
	width:130px !important;
	line-height:49px;
	background-color:rgba(140,255,255, 0.4);
	border: hidden;
	vertical-align:top!important;
}
.event_item_settings_mapkey_table_td2{
	height:49px !important;
	width:550px !important;
	line-height:49px;
	background-color:rgba(140,255,255, 0.4);
	border: hidden;
	vertical-align:top!important;
}
.event_item_settings_mapkey_table_td3{
	height:49px !important;
	width:270px !important;
	line-height:49px;
	background-color:rgba(140,255,255, 0.4);
	border: hidden;
	vertical-align:top!important;
}
/*************************//************************/
/************************//************************/
/*
event_item_settings_label
*/
	.event_item_settings_main_table_theme01{
		height:570px !important;
		width:668px !important;
		line-height:570px;
		position: absolute;
		border:hidden; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top !important;
	}
	.event_item_settings_main_table_theme01_rw{
		height:30px !important;
		width:668px !important;
		line-height:30px;
		
		border:hidden; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top !important;
	}
	.event_item_settings_main_table_theme02_rw{
		height:540px !important;
		width:668px !important;
		line-height:540px;
		
		border:hidden; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top !important;
		text-align: left;
	}
.event_item_settings_setting02_01{
	height:200px !important;
	width:664px !important;
	line-height:200px;
	vertical-align:top !important;
	padding:0;
	margin:0;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 0.2);
}
.event_item_settings_setting02_02{
	height:324px !important;
	width:664px !important;
	line-height:324px;
	vertical-align:top !important;
	padding:0;
	margin:0;
	border-spacing: 0px;
	border:thin solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 0.2);
	color:black;
	font-size: 1.00em;
	font-weight: 700;
	text-align:center;
	text-decoration:none;
}
	.event_item_settings_main_table_empty{
		height:530px !important;
		width:668px !important;
		line-height:530px;
		
		border:hidden; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top !important;
		/*
		
		*/
		background:url(../images/buttons/butt_template.png) rgb(239,239,239, 0.5);
		background-size: 500px 400px;
   		background-repeat: no-repeat;
		background-position:50% 45%; 
	}

/*form_profile_date_ajax_view

*/
.item_date_view{
    height:515px; 
    width:668px;
    display:inline-block;	
    vertical-align:top;
    font-size: .80em;
    white-space: normal;
    font-weight: 600;
    text-align:center;
    text-decoration:none;
    background-color:transparent;
    border: hidden;


}
.xdsoft_datetimepicker .xdsoft_datepicker {
    height:515px !important;
    float: left;
    margin-left: 8px;
	table-layout: fixed;
	width: 80% !important;
}

.xdsoft_datetimepicker .xdsoft_calendar table {
    border-collapse: collapse;
    height: 480px  !important;
    line-height:20px !important;
    width: 100%;

}
.xdsoft_datetimepicker .xdsoft_calendar th {
    height: 15px !important;
    line-height:15px;
}
.xdsoft_datetimepicker .xdsoft_calendar td,.xdsoft_datetimepicker .xdsoft_calendar th {
    width: 14.2857142%;
    background: #f5f5f5;
    border: 1px solid #ddd;
    color: #666;
    font-size: 12px;
    text-align: right;
    vertical-align: middle;
    padding: 0;
    border-collapse: collapse;
    cursor: pointer;
    height: 15px !important;
    line-height:15px;
}
	/************************//************************/
	.event_item_settings_main_table_theme01_rw_td1{
		height:32px !important;
		width:160px !important;
		line-height:32px;
		
		border:hidden; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top !important;
	}
	.event_item_settings_main_table_theme01_rw_td1_1{
		height:32px !important;
		width:113px !important;
		line-height:32px;
		
		border:hidden; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top !important;
	}
	.event_item_settings_main_table_theme01_rw_td2{
		height:32px !important;
		width:54px !important;
		line-height:32px;
		
		border:hidden; 
		background-color:transparent;
		padding:0;
		margin:0;
		vertical-align:top !important;
	}
/*
event_theme_default1
*/
	.items_date_field_selected{
		border-spacing: 0px;
		border: 1px solid #58F231 !important;
	}
	.items_date_start_field{
	  	height:25px !important;
		/*width: 345px;
		width: 100%;
		*/
		width: 90%;
		line-height:25px !important;
		
		border-spacing: 0px;
		border:hidden;
		
		background-color:#EFEFEF;
		background-image:url(../images/buttons/butt_template.png);
		background-size: 22px 22px;
   		background-repeat: no-repeat;
		background-position:2% 45%; 

		color:#888888;
		font-size: .75em;
		font-weight: 700;
		text-align:left;
		text-indent: 25px;
		vertical-align:middle;
		text-decoration:none;
		
	  }
	.items_date_end_field{
	  	height:25px !important;
		/*width: 345px;
		width: 100%;
		*/
		width: 90%;
		line-height:25px !important;
		
		border-spacing: 0px;
		border:hidden;
		
		background-color:#EFEFEF;
		background-image:url(../images/buttons/butt_template.png);
		background-size: 22px 22px;
   		background-repeat: no-repeat;
		background-position:2% 45%; 

		color:#888888;
		font-size: .75em;
		font-weight: 700;
		text-align:left;
		text-indent: 25px;
		vertical-align:middle;
		text-decoration:none;
		
	  }
	.items_date_field{
	  	height:25px !important;
		/*width: 345px;
		width: 100%;
		*/
		width: 90%;
		line-height:25px !important;
		
		border-spacing: 0px;
		border:hidden;
		
		background-color:#EFEFEF;

		color:#888888;
		font-size: .75em;
		font-weight: 700;
		text-align:left;
		text-indent: 5px;
		vertical-align:middle;
		text-decoration:none;
		
	  }
	.items_date_delete{
	  	height:30px !important;
		width: 50px;
		line-height:30px !important;
		
		border-spacing: 0px;
		border:1px solid #ccc;
		
		background-color:#EFEFEF;
		background: url(../images/buttons/sys_delete_buttonDefault.png);
		background-size: 45px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%; 

	  }
	.items_date_home{
	  	height:30px !important;
		width: 50px;
		line-height:30px !important;
		
		border-spacing: 0px;
		border:hidden;
		
		background-color:#EFEFEF;
		background-image:url(../images/buttons/butt_template.png);
		background-size: 45px 30px;
   		background-repeat: no-repeat;
		background-position:50% 45%; 

	  }
	.event_item_theme_default1 {
		height: 239px;
		width: 664px;
		
		position:absolute;
		display:inline-block;
		
		top: 38px !important;
		
		background-color:#999;
		background-position:center;
		background-size:  cover;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

.event_option_personal_slide_menu{
    height:110px !important;
    width: 134px !important;
    text-align:center !important;
    vertical-align:middle !important;
    background: url(../images/sys_option_default/sys_pin_default.png) #CFF;
    background-position:center;
    background-size: 99px 99px;
    background-repeat: no-repeat;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_option_social_slide_menu{
    height:110px !important;
    width: 134px !important;
    text-align:center !important;
    vertical-align:middle !important;
    background: url(../images/sys_option_default/sys_pin_default.png) #99F;
    background-position:center;
    background-size: 99px 99px;
    background-repeat: no-repeat;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_option_exclusive_slide_menu{
    height:110px !important;
    width: 134px !important;
    text-align:center !important;
    vertical-align:middle !important;
    background: url(../images/sys_option_default/sys_pin_default.png) #6FF;
    background-position:center;
    background-size: 99px 99px;
    background-repeat: no-repeat;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_option_quick_slide_menu{
    height:110px !important;
    width: 134px !important;
    text-align:center !important;
    vertical-align:middle !important;
    background: url(../images/sys_option_default/sys_pin_default.png) #FC9;
    background-position:center;
    background-size: 99px 99px;
    background-repeat: no-repeat;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.business_button1_menu{
    height:100px !important;
    width: 134px !important;
    text-align:center !important;
    vertical-align:middle !important;
    background: url(../images/sys_option_default/default_building.png) #6FF;
    background-position:center;
    background-size: 99px 90px;
    background-repeat: no-repeat;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_button2_menu{
    height:100px !important;
    width: 134px !important;
    text-align:center !important;
    vertical-align:middle !important;
    background: url(../images/sys_option_default/default_building.png) #FC9;
    background-position:center;
    background-size: 99px 90px;
    background-repeat: no-repeat;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.business_button_recommendation_menu{
    height:100px !important;
    width: 134px !important;
    text-align:center !important;
    vertical-align:middle !important;
    background: url(../images/buttons/butt_template.png) #6FF;
    background-position:center;
    background-size: 99px 90px;
    background-repeat: no-repeat;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************/
	/*

	*/
	
.event_title_field{
    z-index: 10;
    height:125px !important;
    width: 530px !important;
    /*width: 100%;*/
    line-height:25px !important;
    margin-top: -35px;
	margin-left: 0px;
    background:rgba(255,255,255, 0.0);
    border-spacing: 0px;
    border:hidden;
    font-size: 2.45em;
    /*
    font-weight: 500;
    */
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    vertical-align:top !important; 
    text-align:left;
    text-decoration:none;
    text-indent:0px;
    word-wrap: break-word !important;
  }
.event_title_field_error{
    z-index: 0;
    height:125px !important;
    width: 530px !important;
    line-height:25px !important;
    margin-top: -35px;
	margin-left: 0px;
    background:rgba(255,255,255, 0.0);
    border-spacing: 0px;
    border-style:solid;
    border-width:thin;
    border-color:#F00;
    font-size: 2.45em;
    /*
    font-weight: 500;
    */
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;

    text-align:left;
    text-decoration:none;
    text-indent:0px;
    word-wrap: break-word !important;
}
.event_multi_title_field{
    z-index: 0;
    height:45px !important;
    width: 360px !important;
    /*width: 100%;*/
    line-height:45px !important;
    background:rgba(238,238,238, 0.2);
    border-spacing: 0px;
    border:hidden;
    font-size: 1.85em;
    /*
    font-weight: 500;
    */
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;

    text-align:left;
    text-decoration:none;
    text-indent:0px;
    word-wrap: break-word !important;
  }
.event_multi_title_field_error{
    z-index: 0;
    height:45px !important;
    width: 360px !important;
    /*width: 100%;*/
    line-height:45px !important;
    background:rgba(238,238,238, 0.2);
    border-spacing: 0px;
    border-style:solid;
    border-width:thin;
    border-color:#F00;
    font-size: 1.85em;
    /*
    font-weight: 500;
    */
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;

    text-align:left;
    text-decoration:none;
    text-indent:0px;
    word-wrap: break-word !important;
}
/*
	event_option_main_table_td_type1
event_multi_title_edit
event_full_base_table_td2
*/
.event_swipe_title_field{
    z-index: 0;
    height:75px !important;
    width: 525px !important;
    /*width: 100%;*/
    line-height:20px !important;

    margin-top: 0px;
    background:rgba(238,238,238, 0.2);
    border-spacing: 0px;
    border:hidden;
    font-size: 1.85em;
    /*
    font-weight: 500;
	member_view_control_td_visible_cell_title
    */
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;

    text-align:left;
    vertical-align:top;
    text-decoration:none;
    text-indent:0px;
    word-wrap: break-word !important;
  }
.event_swipe_title_field_error{
    z-index: 0;
    height:75px !important;
    width: 525px !important;
    /*width: 100%;*/
    line-height:75px !important;

    margin-top: 0px;
	background:rgba(238,238,238, 0.2);
    border-spacing: 0px;
    border-style:solid;
    border-width:thin;
    border-color:#F00;
    font-size: 1.85em;
    /*
    font-weight: 500;
    */
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    vertical-align:top;
    text-align:left;
    text-decoration:none;
    text-indent:0px;
    word-wrap: break-word !important;

}
	/************************************************/
	/*	
	*/
	.event_option_main_table_td_occurrence{
		height:90px !important;
		width:720px !important;
		line-height:90px;
		border-top:hidden;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	/*
	
		border:1px solid #CCC;
		event_option_main_table
	
	*/
	.event_option_main_table_td_occurrence1{
		height:90px !important;
		width:330px !important;
		line-height:90px;
		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_occurrence1td1{
		height:90px !important;
		width:280px !important;
		line-height:90px;
		border:hidden;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_occurrence1td2{
		height:90px !important;
		width:50px !important;
		line-height:90px;
		border:hidden;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_occurrence2{
		height:90px !important;
		width:390px !important;
		line-height:90px;
		border:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	/************************************************/
	/*	EVENT event_option_main_table_td_other    	*/
	/*
		83
	*/
	.event_option_main_table_td_other{
		height:257px !important;
		width:720px !important;
	
		border-top:hidden;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:left;
		vertical-align:top !important;
	}
	/************************************************/
	/*	EVENT event_option_main_table_td_environment    	*/
		
	
	/*
	event_option_main_table_td_environment
	event_option_main_table_td_environment1
	event_option_main_table_td_command
	
	border-top:hidden;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:hidden;
		border:1px solid #CCC;
		event_physical_button_active
	
	*/
	.event_option_main_table_td_environment{
		height:80px !important;
		width:720px !important;
		line-height:80px;
		border-top:hidden;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_environment1{
		height:40px !important;
		width:328px !important;
		line-height:40px;
		border:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_environment1_1{
		height:40px !important;
		width:326px !important;
		line-height:40px;
		border:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_environment1_2{
		height:40px !important;
		width:50px !important;
		line-height:40px;
		border:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_environment1_3{
		height:40px !important;
		width:108px !important;
		line-height:40px;
		border:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_environment1_4{
		height:40px !important;
		width:108px !important;
		line-height:40px;
		border:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_environment1_5{
		height:40px !important;
		width:108px !important;
		line-height:40px;
		border:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_environment2{
		height:80px !important;
		width:390px !important;
		line-height:80px;
		border:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	/************************/
	/*	EVENT ENVIRONMENT OPTIONS	
	
		event_option_main_table_td_environment2
		border-spacing: 0px;
		border-style:solid;
		border-width:thin;
		border-color:#F00;
	*/
	.event_environment_td{
		height: 80px;
		width: 130px;
		line-height:80px;
		border:hidden;
		background-color:transparent;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_evironment_button_error{
		height: 78px;
		width: 128px;
		line-height:78px;
		border-spacing: 0px;
		border-style:solid;
		border-width:thin;
		border-color:#F00;
		background: url(../images/buttons/butt_template.png) #39F;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_physical_button_active{
		height: 78px;
		width: 128px;
		line-height:78px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #39F;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_physical_button_default{
		height: 78px;
		width: 128px;
		line-height:78px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_virtual_button_active{
		height: 78px;
		width: 128px;
		line-height:78px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #0C0;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_virtual_button_default{
		height: 78px;
		width: 128px;
		line-height:78px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_button_active{
		height: 78px;
		width: 128px;
		line-height:78px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #66C;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_button_default{
		height: 78px;
		width: 128px;
		line-height:78px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #EFEFEF;
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
	
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/************************************************/
	/*	EVENT event_option_main_table_td_dates    	
	
	base_functional_listview_filter_menu
	height:107px !important;
	*/
	.event_option_main_table_td_dates{
		height:215px !important;
		width:720px !important;
		line-height:215px;
		border-top:hidden;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_set_dates{
		height:215px !important;
		width:300px !important;
		line-height:100px;
		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:aqua;
		text-align:center;
		vertical-align:middle !important;
	}
/*
	.event_option_main_table_td_set_times{
		height:127px !important;
		width:150px !important;
		line-height:100px;
		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFFFFF;
		text-align:center;
		vertical-align:middle !important;


	}
*/
/*
type_view_options_temp
height: 215px;
		width: 298px;

*/
	
/*
	.event_set_time_button_default{
		height: 120px;
		width: 148px;
		line-height:98px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #FFFFFF;
		background-position:center;
		background-size: 120px 100px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_set_time_button_active{
		height: 120px;
		width: 148px;
		line-height:98px;
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #5FDD29;
		background-position:center;
		background-size: 120px 100px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
*/
	.event_option_main_table_td_dates_descriptions{
		height:127px !important;
		width:420px !important;
		line-height:100px;
		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_dates_output{
		height:27px !important;
		width:150px !important;
		
		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		text-align:center;
		vertical-align:middle !important;
	}
	
	/************************************************/
	/*	EVENT event_option_main_table_td_guidelines    	*/
	
	/*
		event_option_main_table_td_environment
	height:240px !important;
	event_theme_default1
	
	*/
	.event_option_main_table_filler{
		height:220px !important;
		width:720px !important;
		line-height:50px;
		border-top:hidden;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	
	.event_option_main_table_td_guidelines{
		height:100px !important;
		width:720px !important;
		line-height:50px;
		border-top:hidden;
		border-bottom:1px solid #CCC;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}
	/************************************************/
	/*	EVENT event_option_main_table_td_command    	*/
	
	/*
	border:1px solid #CCC;

	
height:42px !important;
line-height:45px;


line-height:258px;
height:108px !important;
	*/
	.event_option_main_table_td_command_nonactive{
		
		width:720px !important;
		
		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:right;
		vertical-align:middle !important;
	}
/*
	height:258px !important;
*/
	.event_option_main_table_td_command{
	
		width:720px !important;
		
		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:right;
		vertical-align:middle !important;
	}
	.event_option_main_table_td_command_td{
		height:45px !important;
		width:130px !important;
		line-height:45px;
		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:middle !important;
	}

/************************//************************//************************/
	/************************//*	EVENT_SWIPE_MAIN	*/
	/********//*	EVENT_SWIPE_VIEW_MAIN	*/
	/*
		
		swipe_view_window	height:606px !important;;

line-height:248px;
	*/


	.event_swipe_main_table_td1{
		height:248px !important;
		width:686px !important;
		
		
		
		border: hidden;
		padding:0;
		margin:0;
		vertical-align:text-top !important;
	}

	.event_swipe_main_table_td2{
		height:313px !important;
		width:686px !important;
		/*
		line-height:20px;
		*/
		
		border-top:1px solid #CCC;  
		border-bottom:hidden;  
		border-left:hidden; 
		border-right:hidden; 
		
		padding:0;
		margin:0;
		/*
		vertical-align:text-top!important;
		*/
	}
	/*
	swipe_option_main_table


	*/

	.event_swipe_theme_default {
		height: 246px;
		width: 687px;
		position:absolute;
		
		display:inline-block;
		/*
		margin-left: -343px;
		*/
		
		background-color:#999;
		background-position:center;
		background-size:  cover;
   		background-repeat: no-repeat;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
	
	theme_view_control_title_select_structure
	
	
	*/

	.swipe_view_control_title_edit_structure {
		z-index:2;
		
		height:45px !important;
		width: 45px !important;
		
		display:inline-block;
		position:absolute;
		
		/*
		top:20px;
		left:635px;
		*/
		margin-top: 3px;
		margin-left: 617px;
		
		font-size: 1em;
		font-weight: 600;
		text-align:center;
		text-decoration:none;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.swipe_view_control_td_visible_cell {
		height:45px !important;
		width:45px !important;
		
		display:inline-block;
		position:absolute;
		
		vertical-align:top;
		border:1px solid #ccc;
		
		
		background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	/*
	swipe_view_control_title_edit_structure
	theme_view_control_td_visible_cell


	*/


.entity_item_upload_frame_div {
	z-index:5;


	height:44px !important;
	width: 45px !important;

	display:inline-block;
	position:absolute;
	/*
	
	top: 27px;
	left:610px;
	*/
	margin-top: 3px;
	margin-left: 590px;


	background-color:transparent;

	font-size: 1em;
	font-weight: 600;
	text-align:left;
	text-decoration:none;

	border:hidden;

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
item_event_display_complete
*/



	/*
	
	*/

	.event_swipe_full_base_table_td2_1 {
		height:320px !important;
		width:100px !important;
		line-height: 320px !important;
		
		margin-top: 0px;
		vertical-align: top;
		border-spacing: 0px;
		
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		
		display:inline-block;
		background-color:transparent;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_swipe_full_base_table_td2_1_1 {
		height:106px !important;
		width:100px !important;
		line-height: 106px;
		
		position: absolute;
		margin-top: 0px;
		vertical-align: top;
		border-spacing: 0px;
		
		border-right: hidden;
		border-left: 1px solid #ccc;
		border-top: hidden;
		border-bottom: 1px solid #ccc;
		
		display:inline-block;
		background-color: transparent;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_swipe_full_base_table_td2_1_2 {
		height:106px !important;
		width:100px !important;
		line-height: 106px;
		
		position: absolute;
		margin-top: 106px;
		vertical-align: top;
		border-spacing: 0px;
		
		border-right: hidden;
		border-left: 1px solid #ccc;
		border-top: hidden;
		border-bottom: 1px solid #ccc;
		
		display:inline-block;
		background-color: transparent;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_swipe_full_base_table_td2_1_3 {
		height:106px !important;
		width:100px !important;
		line-height: 106px;
		
		position: absolute;
		margin-top: 212px;
		vertical-align: top;
		
		border-spacing: 0px;
		
		border-right: hidden;
		border-left: 1px solid #ccc;
		border-top: hidden;
		border-bottom: 1px solid #ccc;
		
		display:inline-block;
		background-color: transparent;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_swipe_full_base_table_td2_2 {
		height:320px !important;
		width:586px !important;
		display: inline-block;
		line-height: 320px;
		
		position: absolute;
		margin-top: 0px;
		vertical-align:text-top;
		
		border-spacing: 0px;
		
		/*border:1px solid #ccc;*/
		
		border-right:hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		
		background-color: transparent;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	/*
	

	*/
	.event_swipe_full_base_table_td2_2_1 {
		height:220px !important;
		width:586px !important;
		line-height: 220px;
		
		display:inline-block;
		margin-top: 0px;
		vertical-align:text-top!important;
		
		position: absolute;
		
		border-spacing: 0px;
		
		border-right:1px solid #ccc;
		border-left: 1px solid #ccc;
		border-top: hidden;
		border-bottom: hidden;
		/*
		border:1px solid #ccc;
		*/
		
		
		background-color:transparent;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

/*

*/
.event_swipe_full_base_left {
	height:220px !important;
	width:434px !important;
	line-height: 20px;

	display:inline-block;
	margin-top: 0px;
	vertical-align:top!important;

	border-spacing: 0px;

	border-right:hidden;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;
	background-color:rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_swipe_full_base_right {
	height:220px !important;
	width:150px !important;
	line-height: 20px;

	display:inline-block;
	margin-top: 0px;
	vertical-align:top!important;
	
	border-spacing: 0px;

	border-right:hidden;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;
	background-color:rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_swipe_full_base_right_tr1 {
	height:30px !important;
	width:148px !important;
	line-height: 20px;

	display:inline-block;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align:middle;

	border-spacing: 0px;
	
	border: thin solid rgba(204,204,204, 0.7);
	background-color:rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	
	color:#888888;
	font-size: 0.40em;
	font-weight: 500;
	text-indent: 5px;
	text-align:left;
	resize: none;
	overflow: auto;
	text-shadow: 0 0 0.5em #87F;
}
.event_swipe_full_base_right_tr2 {
	height:30px !important;
	width:148px !important;
	line-height: 20px;

	display:inline-block;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align:middle;

	border-spacing: 0px;

	border: thin solid rgba(204,204,204, 0.7);
	background-color:rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	
	color:#888888;
	font-size: 0.40em;
	font-weight: 500;
	text-indent: 5px;
	text-align:left;
	resize: none;
	overflow: auto;
	text-shadow: 0 0 0.5em #87F;
}
.event_swipe_full_base_right_tr3 {
	height:153px !important;
	width:148px !important;
	line-height: 20px;

	display:inline-block;
	margin-top: 0px;
	margin-left: 0px;

	border-spacing: 0px;

	border: thin solid rgba(204,204,204, 0.7);
	background-color:rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	
	color:#888888;
	font-size: 0.40em;
	font-weight: 500;
	text-indent: 5px;
	resize: none;
	overflow: auto;
	text-shadow: 0 0 0.5em #87F;
} 
/************************************/
.event_item_register_default {
	z-index: 20;
	height:117px !important;
	width:117px !important;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
	margin-left:1px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 100px 100px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
}
.event_item_register_active {
	z-index: 20;
	height:117px !important;
	width:117px !important;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
	margin-left:1px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 100px 100px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
}
.event_item_register_pending {
	z-index: 20;
	height:117px !important;
	width:117px !important;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
	margin-left:1px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
	background-size: 100px 100px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
}
.event_item_register_default_other {
	z-index: 20;
	height:42px !important;
	width:157px !important;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
	margin-left:1px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
}
.event_item_register_active_other {
    /*
    event_item_checkin_parent_void
    */
	z-index: 20;
	height:42px !important;
	width:157px !important;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
	margin-left:1px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
}
.event_item_register_pending_other {
	z-index: 20;
	height:42px !important;
	width:157px !important;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
	margin-left:1px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
}
/*****************************/
.event_item_register_parent_default {
    /*
    event_item_register_active_other
    event_item_register_parent_default
    */
	z-index: 20;
	height:42px !important;
	width:157px !important;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
	margin-left:1px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(239, 239, 239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
}
.event_item_register_parent_active {
	z-index: 20;
	height:42px !important;
	width:157px !important;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
	margin-left:1px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
}
.event_item_register_parent_pending {
    z-index: 20;
	height:42px !important;
	width:157px !important;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
	margin-left:1px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
}
.event_item_checkin_parent_void {
    /*
    
    */
	z-index: 20;
	height:42px !important;
	width:157px !important;
	line-height: 20px;
	display:inline-block;
	margin-top:1px;
	margin-left:1px;
	position: absolute;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	vertical-align:middle;
	color:rgba(15, 5, 26, 0.6);
}
.event_item_seat_add {
	z-index: 20;
	height:45px !important;
	width:30px !important;
	line-height: 45px;

	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	
	border-spacing: 0px;
	border: thin solid rgba(252, 71, 71, 0.0);
	vertical-align:top;
	text-align:center;
	
	color:rgba(15, 5, 26, 0.6);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 25px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
		event_container_data7_4
	*/
} 
.event_item_seat_minus {
	z-index: 20;
	height:45px !important;
	width:30px !important;
	line-height: 45px;

	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	
	border-spacing: 0px;
	border: thin solid rgba(252, 71, 71, 0.0);
	vertical-align:top;
	text-align:center;
	
	color:rgba(15, 5, 26, 0.6);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 25px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.event_item_photosystem {
	z-index: 20;
	height:56px !important;
	width:70px !important;
	line-height: 12px;

	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	
	border-spacing: 0px;
	border: thin solid rgba(239,239,239, 0.7);
	vertical-align: top;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
	background:rgba(255,255,255, 0.4);
	font-size: 1.05em;
	font-weight: 700; 
    /*
	event_container_data9_2
	event_container_data8_1_1
    event_container_data8_1_2
    */
    /*
   	color: #000;
	text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	ls_form_catalog_field
		event_container_data8_1
	*/
}
.event_item_photolist {
	z-index: 18;
	height:56px !important;
	width:65px !important;
	line-height: 12px;
	position: absolute;
	display:inline-block;
	vertical-align: top;
	margin-top:1px;
	margin-left:2px;
	border-spacing: 0px;
	border: hidden;
	
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.4);
	background-size: 185px 56px;
	background-repeat: no-repeat;
	background-position:center;
	
	font-size: 1.05em;
	font-weight: 700; 
    /*
    
    */
    /*
   	color: #000;
	text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
		event_container_data8_1
	*/
}
/************************//************************//************************/
	/************************//*	EVENT_DESCRIPTION_OPTIONS	*/
	/********//*	EVENT_DESCRIPTION_MAIN	swipe*/
	.event_messagedisplayevent_view_Personal_options_settings {
		z-index: 5;
		height:28px;
		width:28px;
		display:inline-block;
		/**/
		position:absolute;
		
		vertical-align:top;
		
		margin-top: 2px;
		/*
		margin-left: 370px;
		*/
		margin-left: 92.5%;
		
		border:1px solid #ccc;
		background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
		background-size: 23px 23px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
.event_messagedisplayevent_view_Standard_options_settings {
	z-index: 5;
	height:28px;
	width:28px;
	display:inline-block;
	/**/
	position:absolute;

	vertical-align:top;

	margin-top: 2px;
	/*
	margin-left: 370px;
	*/
	margin-left: 93.5%;

	border:1px solid #ccc;
	background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;

}
	
	.event_messagedisplayevent_view_options_pop_flare{
		z-index: 0;
	  	height:80px !important;
		width:80px !important;
		
		display: inline-block;
		line-height: 80px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 2;
		margin-left: 217px;
		
		color:black;
		font-size: 0.40em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
/*
event_title_edit
*/

.event_messagedisplaytype_event_view_options_full{
    /*
    event_messagedisplaytype_event_view_options_item
    event_description_options_settings
    event_item_description_options_settings
    */
    z-index: 0;
    height:147px !important;
    width: 415px !important;
    line-height: 12px;
    padding-bottom:95px;
    margin-top: 1px;
    margin-left: 1px;
	border: 1px solid rgba(209,209,209, 0.3);
    background-color:rgba(255,255,255, 0.7);
    border-spacing: 0px;
    color:#888888;
    font-size: 1.20em;
    font-weight: 500;
    text-align:left;
    vertical-align:text-top !important;
    text-indent: 5px;
    overflow: visible;
}
.type_event_view_options_temp {
	z-index: 0;
    height:151px !important;
    width: 435px !important;
    line-height:24px !important;

    position: absolute;

    margin-top: -151px;
    margin-left: 0px;
    border-spacing: 0px;
    border:hidden;
    vertical-align:top;
    text-align:left;
    background: url(../images/event_descriptions/eventdescription_default.png);
    background-size: 360px 115px;
    background-position:50% 45%;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.event_description_options_settings {
    /*
    event_item_description_options_settings
    */
	z-index: 5;
	height:28px;
	width:28px;
	display:inline-block;
	position:absolute;

	vertical-align:top;

	margin-top: 3px;
	margin-left: 340px;

	border:1px solid #ccc;
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_messagedisplaytype_event_view_options_item{
    z-index: 0;
    height:147px !important;
    width: 415px !important;
    line-height: 12px;
    padding-bottom:95px;

    margin-top: 1px;
    margin-left: 1px;
	border: 1px solid rgba(209,209,209, 0.3);
    background-color:rgba(255,255,255, 0.7);
    border-spacing: 0px;
    
    color:#888888;
    font-size: 1.20em;
    font-weight: 500;
    text-align:left;
    vertical-align:text-top !important;
    text-indent: 5px;
    overflow: visible;
	/*
    event_mgmt_standard_description_content_background_DIV
    
	*/
}
.event_messagedisplaytype_event_view_options_item_other{
    z-index: 0;
    /*
    height:146px !important;
    
    */
    height:146px !important;
    width: 366px !important;
    line-height: 12px;
    padding-bottom:95px;

    margin-top: 1px;
    margin-left: 1px;
	border: 1px solid rgba(209,209,209, 0.3);
    background-color:rgba(255,255,255, 0.7);
    border-spacing: 0px;
    
    color:#888888;
    font-size: 1.20em;
    font-weight: 500;
    text-align:left;
    vertical-align:text-top !important;
    text-indent: 5px;
    overflow: visible;
/*
    resize: none;
    overflow: auto;
    
	*/
}
.type_event_view_options_temp_item {
    height:115px !important;
    width: 357px !important;
    border-spacing: 0px;
    border:hidden;
    vertical-align:top;
    text-align:left;
    background: url(../images/event_descriptions/eventdescription_default.png);
    background-size: 360px 115px;
    background-position:50% 45%;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

.event_item_description_options_settings {
    /*
    event_container_data_menu_catalog_active
    event_description_display_DIV
    */
	z-index: 5;
	height:28px;
	width:28px;
	display:inline-block;
	position:absolute;
	vertical-align:top;
	margin-top: 3px;
	margin-left: 385px;
	border:1px solid #ccc;
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_description_options_settings_other {
    /*
    event_container_data_menu_catalog_active
    */
	z-index: 5;
	height:28px;
	width:28px;
	display:inline-block;
	position:absolute;
	vertical-align:top;
	margin-top: 3px;
	margin-left: 92%;
	border:1px solid #ccc;
	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_messagedisplaytype_event_view_personal_options_full{
		z-index: 0;
	  	height:170px !important;
		width: 377px !important;
		line-height:24px !important;
		
		position: absolute;
		
		margin-top: 15px;
		margin-left: 9px;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border:1px solid #ccc;
		color:#888888;
		font-size: 1.20em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}

	.event_messagedisplaytype_event_view_options_left{
		z-index: 0;
	  	height:200px !important;
		width: 370px !important;
		line-height:24px !important;
		
		position: absolute;
		
		margin-top: 30px;
		margin-left: 10px;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border:1px solid #ccc;
		color:#888888;
		font-size: 1.20em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}
	.event_messagedisplaytype_event_view_options_right{
		z-index: 0;
	  	height:200px !important;
		width: 370px !important;
		line-height:24px !important;
		
		position: absolute;
		
		margin-top: 30px;
		margin-left: 70px;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border:1px solid #ccc;
		color:#888888;
		font-size: 1.20em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}

/**************************************************/
/**************************************************/
/*

*/
.media_menu_event_item_full_display{
	height: 202px;
	width: 326px !important;
	margin-top: 0px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color:rgba(239,239,239, 0.6);

	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ls_menu_event_item_full_display_field{
    height: 202px;
	width: 326px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: thin solid rgba(239,239,239, 0.0);
	background:rgba(255,255,255, 0.5);
    font-size: 0.95em;
	font-weight: 700;
    /*
	
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
.media_menu_event_item_full_option{
	height: 202px;
	width: 150px !important;
	margin-top: 0px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);

	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.6);
    background-position:center;
    background-size: 80px 80px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_menu_event_item_full_option_field{
    height: 202px;
	width: 150px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;
    background:rgba(238,238,238, 0.5);
    border-spacing: 0px;
    border: thin solid rgba(239,239,239, 0.7);
    font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
/******************************************************/
.media_menu_event_item_half_display{
    /*
    media_menu_event_pb_item_third_display_other
    event_item_description_options_settings
    event_media_standard_DIV_td01
    */
	z-index:2;
	height: 95px;
	width: 155px !important;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color:rgba(255,255,255, 0.1);

	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ls_menu_event_item_half_display_field{
    /*
    ls_menu_event_pb_item_third_display_field_other
    */
    z-index:1;
    height: 95px;
	width: 120px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 1px;
    border-spacing: 0px;
    border: thin solid rgba(239,239,239, 0.0);
	background:rgba(255,255,255, 0.9);
    font-size: 0.95em;
	font-weight: 700;
    /*
	
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
.media_menu_event_item_half_option{
    /*
    media_menu_event_pb_item_third_option_other
    */
	z-index:100;
	height: 95px;
	width: 155px !important;
	margin-top: 0px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.0);
	background:  rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_menu_event_item_half_option_field{
    /*
    ls_menu_event_pb_item_third_option_field_other
    */
    z-index:2;
    height: 96px;
	width: 55px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 100px;
    background:rgba(238,238,238, 0.0);
    border-spacing: 0px;
    border: thin solid rgba(255,255,255, 0.9);
    font-size: 1.25em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
}
/*********************/
.media_menu_event_item_half_display_other{
    /*
    event_media_display_DIV_td01
    */
    z-index:2;
	height: 45px;
	width: 157px !important;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color:rgba(255,255,255, 0.1);

	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ls_menu_event_item_half_display_field_other{
    z-index:1;
    height: 45px;
	width: 120px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 1px;
    border-spacing: 0px;
    border: thin solid rgba(239,239,239, 0.0);
	background:rgba(255,255,255, 0.9);
    font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }

.media_menu_event_item_half_option_other{
    z-index:100;
	height: 45px;
	width: 157px !important;
    position: absolute;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.0);
	background:  rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/*

*/
.ls_menu_event_item_half_option_field_other{
    z-index:2;
    height: 45px;
	width: 55px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 100px;
    background:rgba(238,238,238, 0.0);
    border-spacing: 0px;
    border: thin solid rgba(255,255,255, 0.9);
    font-size: 1.25em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
/**************************************************/
/**************************************************/
.media_menu_event_pb_stream_third_display{
	height: 99px;
	width: 150px !important;
	margin-top: 0px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.7);
	background-color:rgba(239,239,239, 0.6);

	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_media_view_option_media_stream_third_screen{
	z-index: 100;
	height: 99px;
	width: 150px !important;
    line-height:30px;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;	
    border: 2px solid rgba(209,209,209, 0.2) !important;
    background-color:rgba(255,255,55, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: center;
	/*
	event_media_view_option_media_full
	*/
}
/***********************************/
.media_menu_event_pb_item_third_display{
    /*
    media_menu_event_item_half_display
    */
	z-index:2;
	height: 95px;
	width: 155px !important;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color:rgba(255,255,255, 0.1);

	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ls_menu_event_pb_item_third_display_field{
    /*
    ls_menu_event_item_half_display_field
    ls_menu_event_item_half_display_field_other
    media_menu_event_item_half_option_other
    ls_menu_event_item_half_option_field_other
    */
    z-index:1;
    height: 95px;
	width: 120px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 1px;
    border-spacing: 0px;
    border: thin solid rgba(239,239,239, 0.0);
	background:rgba(255,255,255, 0.9);
    font-size: 0.95em;
	font-weight: 700;
    /*
	
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
.media_menu_event_pb_item_third_option{
    /*
    media_menu_event_item_half_option
    */
	z-index:100;
	height: 95px;
	width: 155px !important;
	margin-top: 0px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.0);
	background:  rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_menu_event_pb_item_third_option_field{
    /*
    ls_menu_event_item_half_option_field
    */
    z-index:2;
    height: 96px;
	width: 55px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 100px;
    background:rgba(238,238,238, 0.0);
    border-spacing: 0px;
    border: thin solid rgba(255,255,255, 0.9);
    font-size: 1.25em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
/********************************/
.media_menu_event_pb_item_third_display_other{
    z-index:2;
	height: 95px;
	width: 157px !important;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color:rgba(255,255,255, 0.1);

	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ls_menu_event_pb_item_third_display_field_other{
    /*
    ls_menu_event_item_half_display_field_other
    
    */
    z-index:1;
    height: 95px;
	width: 120px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 1px;
    border-spacing: 0px;
    border: thin solid rgba(239,239,239, 0.0);
	background:rgba(255,255,255, 0.9);
    font-size: 0.95em;
	font-weight: 700;
    /*
	
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
.media_menu_event_pb_item_third_option_other{
    z-index:100;
	height: 95px;
	width: 157px !important;
	margin-top: 0px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.0);
	background:  rgba(255,255,255, 0.4);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    
}
.ls_menu_event_pb_item_third_option_field_other{
    z-index:2;
    /*
    ls_menu_event_item_half_option_field_other
    */
    height: 98px;
	width: 55px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 100px;
    background:rgba(238,238,238, 0.0);
    border-spacing: 0px;
    border: thin solid rgba(255,255,255, 0.9);
    font-size: 1.25em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
/**********//*********/
.media_menu_event_pb_item_third_upload_option{
	height: 99px;
	width: 25px !important;
	margin-top: 0px;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);

	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/sys_upload_graphic.png)  rgba(239,239,239, 0.7);
    background-position:center;
    background-size: 80px 80px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.event_item_standard_third_contribution_div {
	z-index: 10;
    height: 100px;
    width: 100px;
    line-height:100px;
	position: absolute !important;
	margin-top: 15px;
	margin-left: 300px;
    border:hidden;
	background-color: rgba(239,239,239, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_contribution_third_upload {
    height:100px !important;
    width:50px !important;
    line-height:100px !important;
    display: inline-block;
    vertical-align:middle;
    text-align:center;
	border-spacing: 0px;
    border:1px thin rgba(209,209,209, 0.7);
    background: url(../images/buttons/sys_upload_graphic.png) rgba(239,239,239, 0.3);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/**************************************************/
.media_menu_event_pb_item_half_display{
	height: 99px;
	width: 200px !important;
	margin-top: 0px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.7);
	background-color:rgba(239,239,239, 0.6);

	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ls_menu_event_pb_item_half_display_field{
    height: 99px;
	width: 200px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;
    border-spacing: 0px;
    border: thin solid rgba(239,239,239, 0.0);
	background:rgba(255,255,255, 0.5);
    font-size: 0.95em;
	font-weight: 700;
    /*
	
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
.media_menu_event_pb_item_half_option{
	height: 99px;
	width: 172px !important;
	margin-top: 0px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.3);

	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.6);
    background-position:center;
    background-size: 120px 80px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.ls_menu_event_pb_item_half_option_field{
    height: 99px;
	width: 172px !important;
    line-height:30px !important;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;
    background:rgba(238,238,238, 0.5);
    border-spacing: 0px;
    border: thin solid rgba(239,239,239, 0.7);
    font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
  }
.media_menu_event_pb_item_upload_option{
	height: 99px;
	width: 125px !important;
	margin-top: 0px;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);

	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/sys_upload_graphic.png)  rgba(239,239,239, 0.7);
    background-position:center;
    background-size: 80px 80px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.event_item_standard_contribution_div {
	z-index: 10;
    height: 100px;
    width: 100px;
    line-height:100px;
	position: absolute !important;
	margin-top: 15px;
	margin-left: 300px;
    border:hidden;
	background-color: rgba(239,239,239, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_item_contribution_upload {
    height:100px !important;
    width:100px !important;
    line-height:100px !important;
    display: inline-block;
    vertical-align:middle;
    text-align:center;
	border-spacing: 0px;
    border:1px thin rgba(209,209,209, 0.7);
    background: url(../images/buttons/sys_upload_graphic.png) rgba(239,239,239, 0.3);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*--------*//*--------*//*--------*//*--------*/
/*--------*//*--------*//*--------*//*--------*//*--------*//*-----   40    ---*/	
/*
STANDARD EVENT
event_messagedisplayevent_view_options_settings:event_default_frame_table_td2_1;
*/
.event_default_frame_table_td2_1 {
	height:360px !important;
	width:180px !important;
	line-height: 360px;

	display:inline-block;

	vertical-align: top;

	border-spacing: 0px;
	border-right: 1px solid #ccc;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;

	background-color: rgba(255,100,255, 0.6);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_default_frame_table_td2_2 {
	height:360px !important;
	width:537px !important;

	display: inline-block;
	vertical-align: top !important;

	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;

	background-color: rgba(255,255,255, 0.0);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*--------*//*--------*//*--------*//*--------*//*--------*//*--------*/
/*
	event_standard_frame_rec_large_default
event_standard_frame_base_table_td2_1_2
*/
.event_standard_frame_table_td2_1 {
	height:360px !important;
	width:120px !important;
	line-height: 360px;

	display:inline-block;

	vertical-align: top;

	border-spacing: 0px;
	border-right: 0px solid #ccc;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;

	background-color: rgba(255,255,255, 0.6);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_standard_frame_table_td2_2 {
	height:360px !important;
	width:597px !important;

	display: inline-block;
	vertical-align: top !important;

	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;

	background-color: rgba(255,255,255, 0.0);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
/*

*/
.event_standard_frame_table_td2_2_1 {
	height:200px !important;
	width:595px !important;

	position: absolute;

	display:inline-block;

	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: hidden;
	border-bottom: 1px solid #ccc;


	background-color: rgba(255,255,45, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.event_standard_frame_table_td2_2_1_1 {
	height:200px !important;
	width:461px !important;

	position: absolute;

	display:inline-block;

	border-spacing: 0px;
	border-right: 1px solid #ccc;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;


	background-color: rgba(255,255,255, 0.0);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.event_standard_frame_table_td2_2_1_2{
	height:200px !important;
	width:133px !important;

	/*event_set_date_button_default*/
	display: inline-block;
	position:absolute;
	margin-left: 460px;


	vertical-align: top !important;

	border-spacing: 0px;
	border-right: hidden;
	border-left: 1px solid #ccc;
	border-top: hidden;
	border-bottom: hidden;

	background-color: rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.event_standard_frame_base_table_td2_1_1 {
	height:120px !important;
	width:120px !important;

	display:inline-block;
	position: absolute;
	margin-top: 1px;

	vertical-align: top;

	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: hidden;

	border-bottom: hidden;

	background-color: rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.event_standard_frame_base_table_td2_1_2 {
	height:118px !important;
	width:120px !important;

	display:inline-block;
	position: absolute;
	margin-top: 120px;

	vertical-align: top !important;

	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: 1px solid rgba(209,209,209, 0.3);

	border-bottom: 1px solid rgba(209,209,209, 0.3);

	background-color: rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.event_standard_frame_base_table_td2_1_3 {
	height:120px !important;
	width:120px !important;

	display:inline-block;
	position: absolute;
	margin-top: 240px;


	vertical-align: top !important;

	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: hidden;

	border-bottom: hidden;

	background-color: rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

/*
event_standard_frame_table_td2_1
event_standard_frame_table_td2_2_2
*/
.event_standard_frame_table_td2_2_2{
	height:159px !important;
	width:595px !important;
	line-height: 50px;

	position: absolute;
	margin-top: 201px;

	display:inline-block;

	text-align: left !important;
	vertical-align: top;

	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;

	background-color: rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_frame_rec_div{
	height:20px !important;
	width:144.0px !important;
	line-height: 20px;

	position: absolute;
	margin-top: 2px;
	margin-left: 448px;

	display:inline-block;

	text-align:center !important;
	vertical-align: top;

	border-spacing: 0px;
	border-right: 1px solid #ccc;
	border-left: 1px solid #ccc;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;

	background-color: rgba(239,239,239, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_frame_rec_default{
	height:20px !important;
	width:98% !important;
	line-height: 20px;

	margin-top: 0px;

	display:inline-block;

	text-align:center !important;
	vertical-align: top;
	color:black;
	font-size: 0.70em  !important;
	font-weight: 700;
	text-decoration:none;
	
	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;

	background-color: rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_frame_rec_active{
	height:20px !important;
	width:50% !important;
	line-height: 20px;

	margin-top: 0px;

	display:inline-block;

	text-align:center !important;
	vertical-align: top;
	color:black;
	font-size: 0.70em  !important;
	font-weight: 700;
	text-decoration:none;

	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;

	background-color: rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_frame_rec_large_td1{
	height:102px !important;
	width:132.0px !important;
	line-height: 100px;
	margin-top: 2px;
    margin-left: 5px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_frame_rec_large_td2{
	height:102px !important;
	width:132.0px !important;
	line-height: 100px;
	margin-top: 2px;
    margin-left: 1px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_frame_rec_long_td1{
	height:61px !important;
	width:177.0px !important;
	line-height: 59px;
	/*
	event_standard_frame_table01_td2
	event_standard_frame_rec_large_td2
	*/
	margin-top: 2px;
    margin-left: 5px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_frame_rec_long_td2{
	height:61px !important;
	width:177.0px !important;
	line-height: 59px;
	margin-top: 2px;
    margin-left: 1px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
eventsystem_div_01_search_address_div_close
event_standard_frame_rec_long_td1
*/
.event_standard_frame_rec_large_default{
    /*
    event_standard_frame_rec_large_td1
    event_standard_frame_rec_large_td2
    event_standard_frame_rec_long_default
    */
	height:97px !important;
	width:132.0px !important;
	line-height: 97px;
	margin-top: 1px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 95px 95px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}
.event_standard_frame_rec_long_default{
    /*
    event_standard_frame_rec_long_td1
    event_standard_frame_rec_long_td2
    event_standard_frame_rec_large_default
    */
	height:57px !important;
	width:177.0px !important;
	line-height: 57px;
	margin-top: 1px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 168px 57px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}


/********************//***********************/
/*

*/
.event_item_standard_frame_rec_large_td1{
	height:108px !important;
	width:116.5px !important;
	line-height: 108px;
	margin-top: 4px;
	margin-left: 1px;
	display:inline-block;
	text-align: left !important;
	vertical-align: top !important;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_standard_frame_rec_large_td2{
	height:100px !important;
	width:148.0px !important;
	line-height: 100px;
	margin-top: 0px;
	display:inline-block;
	text-align: left !important;
	vertical-align: top !important;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_standard_frame_rec_long_td1{
	height:60px !important;
	width:219px !important;
	line-height: 54px;
	margin-top: 0px;
	display:inline-block;
	text-align: left !important;
	vertical-align: top !important;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_standard_frame_rec_long_td2{
	height:59px !important;
	width:197.5px !important;
	line-height: 59px;
	margin-top: 0px;
	display:inline-block;
	text-align: center!important;
	vertical-align: middle;
	border-spacing: 0px;
	border: 1px solid rgba(209,209,209, 0.3);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_item_standard_frame_rec_large_default{
	height:106px !important;
	width:114px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 1px !important;
	margin-left: 1px !important;
	display:inline-block;
	padding:0;
	/*
	event_item_standard_frame_rec_large_td1
	event_item_recommendation_large_edit
	
	*/
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 97px 97px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}
.event_item_standard_frame_rec_long_default{
	height:57px !important;
	width:215px !important;
	line-height: 50px;
	
	position: absolute;
	margin-top: 1px !important;
	margin-left: 1px !important;

	display:inline-block;
	padding:0;
	border-spacing: 0px;
	border: 1px dashed #ccc;

	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.3); 
	background-size: 168px 57px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-align:center;
	text-decoration:none;
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	*/
    font-size: 0.80em;
    font-weight: 500;
}
/********************//***********************/
/*--------*//*--------*//*--------*//*--------*//*--------*//*--------*/
.event_default_frame_table_td2_2_1 {
	height:180px !important;
	width:537px !important;

	position: absolute;

	display:inline-block;

	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: hidden;
	border-bottom: 1px solid #ccc;


	background-color: rgba(255,255,45, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_default_frame_table_td2_2_1_1 {
	height:200px !important;
	width:401px !important;

	position: absolute;

	display:inline-block;

	border-spacing: 0px;
	border-right: 1px solid #ccc;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;


	background-color: rgba(255,255,255, 0.0);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_default_frame_table_td2_2_1_2{
	height:200px !important;
	width:135px !important;

	/*event_set_date_button_default*/
	display: inline-block;
	position:absolute;
	margin-left: 400px;


	vertical-align: top !important;

	border-spacing: 0px;
	border-right: hidden;
	border-left: 1px solid #ccc;
	border-top: hidden;
	border-bottom: hidden;

	background-color: rgba(255,255,255, 0.0);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.event_default_frame_table_td2_2_1{
	height:200px !important;
	width:535px !important;

	position: absolute;

	display:inline-block;

	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: hidden;
	border-bottom: 1px solid #ccc;


	background-color: rgba(255,255,255, 0.0);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.event_default_frame_table_td2_2_2{
	height:159px !important;
	width:535px !important;

	position: absolute;
	margin-top: 201px;

	display:inline-block;

	text-align: center;
	vertical-align: middle;

	border-spacing: 0px;
	border-right: hidden;
	border-left: hidden;
	border-top: hidden;
	border-bottom: hidden;

	background-color: rgba(100,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

/*--------*//*--------*//*--------*//*--------*//*--------*//*-----   40    ---*/	
/*
event_stat_data


*/
	.event_full_base_table_td2_1 {
		height:360px !important;
		width:100px !important;
		line-height: 360px;
		
		display:inline-block;
		
		vertical-align: top;
		
		border-spacing: 0px;
		border-right: 1px solid #ccc;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*


*/
	.event_full_base_table_td2_1_1 {
		height:120px !important;
		width:100px !important;
		
		display:inline-block;
		position: absolute;
		margin-top: 1px;
		
		vertical-align: top;
		
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		
		border-bottom: 1px solid #ccc;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_full_base_table_td2_1_2 {
		height:120px !important;
		width:100px !important;
		
		display:inline-block;
		position: absolute;
		margin-top: 120px;
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		
		border-bottom: 1px solid #ccc;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_full_base_table_td2_1_3 {
		height:120px !important;
		width:100px !important;
		
		display:inline-block;
		position: absolute;
		margin-top: 240px;
		
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		
		border-bottom: 1px solid #ccc;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	/* ########  STAT OPTIONS  ##########*/
/*
	margin-top:-121px;
event_messagedisplaystatlabel_field_disabled


*/
	


/*	.event_temp_stat_active{
		height: 121px;
		width: 100px;
		line-height:121px;
		
		color:#666;
		font-size: 1em;
		font-weight: 700;
		text-align:center;
		text-decoration:none;
		background-color: transparent;
		border:1px solid #CCC;
		
		background: url(../images/buttons/sys_metrics_buttonActive.png);
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.event_stat_label{
		z-index: 1;
		height: 40px;
		width: 100px;
		line-height:40px;
		position:relative;
		top: 0;
		background-color:transparent;
		
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
	}
*/
/*
margin-top: 0;


	.event_stat_data{
		z-index: 1;
		height: 120px;
		width: 100px;
		line-height:120px;
		position:relative;
		top: 0;
		margin-top: -40;
		background-color:transparent;
		
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
	}
*/

/*--------*//*--------*//*--------*//*--------*//*--------*//*--------*/
/*

*/
	.event_full_base_table_td2_2 {
		height:360px !important;
		width:617px !important;
		
		display: inline-block;
		vertical-align: top !important;
		
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*--------*//*--------*//*--------*/
/*
event_full_base_table_td2_2_2
event_full_base_table_td2_2_1_1
event_full_base_table_td2_2_2_1

*/
	.event_full_base_table_td2_2_1 {
		height:260px !important;
		width:617px !important;
		
		position: absolute;
		
		display:inline-block;
		
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: 1px solid #ccc;
		
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*--------*//*--------*//*--------*/
/*

*/
	.event_full_base_table_td2_2_1_1 {
		height:260px !important;
		width:451px !important;
		
		position: absolute;
		
		display:inline-block;
		
		border-spacing: 0px;
		border-right: 1px solid #ccc;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*
background: url(../images/event_descriptions/eventdescription_1.png);
event_messagedisplaydatalabel_field_disabled
*/

/*
.type_event_view_options_temp_error {
    height:176px !important;
    width:442px;
    border-spacing: 0px;
    border-style:solid;
    border-width:thin;
    border-color:#F00;
    vertical-align:top;
    text-align:left;
    background: url(../images/buttons/butt_template.png)#EFEFEF;
    background-size: 442px 176px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
*/
	.event_messagedisplaytype_event_view_disabled{
		z-index: 0;
	  	height:200px !important;
		width: 370px !important;
		line-height:24px !important;
		
		position: absolute;
		
		margin-top: 30px;
		margin-left: 60px;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border:1px solid #ccc;
		color:#888888;
		font-size: 1.20em;
		font-weight: 500;
		
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
		
		
		word-wrap: break-word !important;
		
	  }
/*
text-shadow: 0 0 0.5em #87F;

,0 0 0.2em #87F
, 0 0 0.2em #888888

*/
/*--------*//*--------*//*------- 40-*/
/*
event_full_base_table_td2_2_1_2_1

*/
	.event_full_base_table_td2_2_1_2 {
		height:260px !important;
		width:165px !important;
		
		/**/
		display: inline-block;
		position:absolute;
		margin-left: 452px;
		
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*--------*//*--------*//*------- 40-*/
/*
event_rating_label
event_full_base_table_td2_2_1_2_2
*/
	.event_full_base_table_td2_2_1_2_1 {
		height:54px !important;
		width:165px !important;
		line-height: 54;
		
		/**/
		display: inline-block;
		position:absolute;
		
		vertical-align: top !important;
		
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: 1px solid #ccc;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	/*
	.event_rating_label{
		z-index: 0;
		height: 20px;
		width: 65px;
		line-height:20px;
		position:relative;
		display: inline-block;
		
		top: 0;
		background-color:transparent;
		color:#666;
		font-size: .75em;
		font-weight: 700;
		text-align:right;
		vertical-align:middle;
		text-decoration:none;
	}
*//*
	.event_rating_count{
		z-index: 0;
		height: 20px;
		width: 95px;
		line-height:20px;
		position:relative;
		display: inline-block;
		
		top: 0;
		background-color:#EFEFEF;
		color:#666;
		font-size: .75em;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
	}
*/
	.event_messagedisplaylabel_field_disabled{
		z-index: 10;
	  	height:20px !important;
		width: 80px;
		line-height:20px !important;
		
		position: absolute;
		margin-left: 0px;
		/*width: 345px;
		width: 100%;
        itinerary_frame_absolute
		*/
		
		
		background-color:aqua;
		
		border-spacing: 0px;
		border:hidden;
		
		color:#888888;
		font-size: .75em;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
	  }

	.event_messagedisplayinput_field_disabled{
	  	height:20px !important;
		/*width: 345px;
		width: 100%;
		*/
		width: 80px;
		line-height:20px !important;
		
		position: absolute;
		margin-left: 80px;
		
		background-color:#EFEFEF;
		
		border-spacing: 0px;
		border:hidden;
		
		color:#888888;
		font-size: .75em;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
	  }
/*
event_full_base_table_td2_1
*/
	.feedback_event_rating_td{
		z-index: 1;
	  	height:55px !important;
		width: 33px;
		line-height:55px !important;
		/*
		display:inline-block;
		position:relative;
		vertical-align:middle;
		margin-top: 8 !important;
		position:relative;
		*/
		display: inline-block;
		
		
		vertical-align: top !important;
		
		
		
		border-spacing: 0px;
		border:hidden;
		
		text-align:center;
		
	  }
	 .feedback_event_rating_off{
	  	height:45px !important;
		width: 25px;
		line-height:45px !important;
		 
		display:inline-block;
		
		margin-top: 15px;
		 
		border-spacing: 0px;
		border:hidden;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		 
		background: url(../images/buttons/star-off.svg);
 		background-size: 25px 25px;
   		background-repeat: no-repeat;
		background-position:center;
	  }
	  
	  .feedback_event_rating_on{
	 	height:45px !important;
		width: 25px;
		line-height:45px !important;
		  
		display:inline-block;
		
		margin-top: 15px;
		 
		border-spacing: 0px;
		border:hidden;
		  
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		  
		background: url(../images/buttons/star-on.svg);
 		background-size:25px 25px;
   		background-repeat: no-repeat;
	  }

/*--------*//*--------*//*------- 40-*/
/*
	event_set_date_button_default
event_full_base_table_td2_2_2
feedback_event_rating_td
*/
	.event_full_base_table_td2_2_1_2_2 {
		height:205px !important;
		width:165px !important;
		
		/**/
		display: inline-block;
		position:absolute;
		margin-top: 55px;
		vertical-align: top !important;
		
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		
		background-color: rgba(255,255,255, 0.0);
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*

		top: 0;

	.event_set_date_label{
		z-index: 0;
		height: 205px;
		width: 165px;
		line-height:205px;
		position:absolute;
		display: inline-block;
		
		background-color:transparent;
		
	}

*/
/*
height: 205px;
		width: 165px;
		line-height:190px;
		
		border:1px solid #CCC;
*/
	.event_set_date_pending{
		background-image:url(../images/buttons/pending_item.png);
		background-position:50% 65%;
		background-size: 100px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_set_date_active{
		background-image:url(../images/buttons/sys_option_correct_active.png);
		background-position:50% 65%; 
		background-size: 100px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*


*/
	.event_messagedisplayset_date_default{
		z-index: 98;
	  	height:205px !important;
		/*width: 345px;
		100%;
		
		*/
		width:165px;
		line-height:30px !important;
		
		margin-top: -243px;
		position: absolute;
		
		text-align:center;
		vertical-align:text-top;
		
		background-color:transparent;
		border-spacing: 0px;
		
		border:hidden;
		color:#888888;
		font-size: 2.75em;
		font-weight: 700;
		text-decoration:none;
		
	  }
	/*
		.event_option_main_table_td_type2


	*/
.event_set_date_button_default{
    z-index: 99;
    height: 200px;
    width: 135px;
    line-height:190px;

    margin-top: -193px;
    position: absolute;

    border-spacing: 0px;
    border:1px solid #CCC;

    text-align:center;
    vertical-align:text-top;

    border:hidden;
    color:#888888;
    font-size: 2.75em;
    font-weight: 700;
    text-decoration:none;

    background: url(../images/buttons/sys_calendar_default.png) rgba(255,255,255, 0.2);
    background-position:center;
    background-size: 140px 190px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
	.event_set_date_button_active{
		height: 200px;
		width: 135px;
		line-height:210px;
		border:1px solid #CCC;
		background: url(../images/buttons/sys_calendar_active.png) #FFFFFF;
		background-position:center;
		background-size: 140px 190px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_set_date_button_temp_error{
		height: 200px;
		width: 135px;
		line-height:190px;
		border-spacing: 0px;
		border-style:solid;
		border-width:thin;
		border-color:#F00;
		background: url(../images/buttons/sys_calendar_error.png) #FFFFFF;
		background-position:center;
		background-size: 140px 190px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_get_media_button_default{
		height: 205px;
		width: 165px;
		line-height:190px;
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) #FFFFFF;
		background-position:center;
		background-size: 160px 190px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*********************************************/
/*********************************************/
.itinerary_compliance_control_table {
    height:80px !important;
    width: 950px;
   	margin:0;
    padding:0;
	border-spacing: 0px;
    border:hidden;
	background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:left!important;
	vertical-align: top !important;
}
.itinerary_compliance_main_table{
    height:80px !important;
    width:950px !important;
    line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_main_table_a_row1{
    height:76px !important;
    width:946px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
	margin-top: 1px;
	margin-left: 1px;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.itinerary_compliance_main_table_td1{
    height:76px !important;
    width:85px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.5);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_main_table_td2{
    height:76px !important;
    width:85px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.5);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_main_table_td3{
    height:76px !important;
    width:410px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.5);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_main_table_td4{
    height:76px !important;
    width:170px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.5);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_main_table_td4_row1{
    height:36px !important;
    width:166px !important;
    display:inline-block;
	margin-top: 1px;
	margin-left: 1px;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.5);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_main_table_td4_row2{
    height:36px !important;
    width:166px !important;
    display:inline-block;
	margin-top: 1px;
	margin-left: 1px;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.5);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_main_table_td5{
    height:76px !important;
    width:103px !important;
    display:inline-block;
    border:1px solid rgba(239,239,239, 0.5);
    background: rgba(255,255,255, 0.7);
		vertical-align: top;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_main_table_td6{
    height:76px !important;
    width:90px !important;
    display:inline-block;
    border:1px solid rgba(239,239,239, 0.5);
    background: rgba(255,255,255, 0.7);
	vertical-align: top;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_new_itinerary {
	z-index: 10;
	height:74px !important;
	width:80px;
	position: absolute;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/event_details/add_itinerary_button.png) rgba(255,255,255, 0.7);
	background-size: 60px 60px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_compliance_new_poll {
	z-index: 10;
	height:74px !important;
	width:80px;
	position: absolute;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/event_details/add_poll_button.png) rgba(255,255,255, 0.7);
	background-size: 60px 60px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}

.itinerary_compliance_event_logo {
	z-index: 10;
	height:74px !important;
	width:74px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size: 68px 68px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_compliance_event_title{
	z-index: 10;
	height:74px !important;
	width:332px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size: 68px 68px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_compliance_event_status_active{
	z-index: 10;
	height:74px !important;
	width:20px;
	display: inline-block;
	position: absolute;
	margin-top:1px;
	margin-left:310px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background-color:   rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.itinerary_compliance_event_status_disabled{
	z-index: 10;
	height:74px !important;
	width:20px;
	display: inline-block;
	position: absolute;
	margin-top:1px;
	margin-left:310px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background-color:   rgba(252, 71, 71, 0.7);;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.itinerary_compliance_event_start_date{
    height:34px !important;
    width:164px !important;
	line-height: 12px;
    display:inline-block;
	margin-top: 1px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/event_details/event_item_start_icon.png) rgba(255,255,255, 0.1);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 30px;
}
.itinerary_compliance_event_end_date{
    height:34px !important;
    width:164px !important;
	line-height: 12px;
    display:inline-block;
	margin-top: 1px;
	margin-left: 1px;
    border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/event_details/event_item_stop_icon.png) rgba(255,255,255, 0.1);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 30px;
}
.itinerary_compliance_event_recipients{
	z-index: 10;
	height:40px !important;
	width:103px;
	line-height: 12px;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size: 109px 38px;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_compliance_event_recipients_active{
	z-index: 10;
	height:44px !important;
	width:101px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:30px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.2);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 1.30em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_compliance_event_limit{
	z-index: 10;
	height:40px !important;
	width:88px;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size: 88px 38px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_compliance_event_limit_active{
	z-index: 10;
	height:44px !important;
	width:86px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:30px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.2);
	background-color: rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 1.30em  !important;
    font-weight: 500;
	text-indent: 0px;
}

.itinerary_frame_absolute {/*FRAME  HOLDER*/
	height:85px !important;
	width:940px;
	display: inline-block;
	margin-top:4px;
	margin-left:1px;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color:rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.itinerary_item_listview_1_NOTIFICATION {/*NOTIFICATION*/
	z-index: 1;
    height:85px !important;
    width:123px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:0px;
	margin-left:0px;
	border:1px solid rgba(233,233,233, 0.6);
    background: url(../images/event_details/itinerary_button.png) rgba(239,239,239, 0.5);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_item_listview_1_POLL {/*POLL*/
	z-index: 1;
    height:85px !important;
    width:123px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:0px;
	margin-left:0px;
	border:1px solid rgba(233,233,233, 0.6);
    background: url(../images/event_details/poll_button.png) rgba(239,239,239, 0.5);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
} 

.itinerary_item_listview_status_pending {
	z-index: 1;
    height:85px !important;
    width:20px;
	position: absolute;
    border-spacing: 0px;
	margin-top:0px;
	margin-left:125px;
	border:1px solid rgba(233,233,233, 0.6);
    background-color: rgba(255, 255, 128, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-decoration:none;
} 
.itinerary_item_listview_status_active {
	z-index: 1;
    height:85px !important;
    width:20px;
	position: absolute;
    border-spacing: 0px;
	margin-top:0px;
	margin-left:125px;
	border:1px solid rgba(233,233,233, 0.6);
    background-color: rgba(10, 245, 92, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-decoration:none;
} 
.itinerary_item_listview_status_disabled {
	z-index: 1;
    height:85px !important;
    width:20px;
	position: absolute;
    border-spacing: 0px;
	margin-top:0px;
	margin-left:125px;
	border:1px solid rgba(233,233,233, 0.6);
    background-color: rgba(252, 71, 71, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-decoration:none;
} 

.itinerary_item_option_2_1{
	z-index: 10;
	height:42px !important;
	width:150px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:0px;
	margin-left:147px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/default_calendar.png) rgba(43,44,170, 0.1);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:4% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_item_option_3_1{
	z-index: 10;
	height:42px !important;
	width:85px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:0px;
	margin-left:299px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/default_clock.png) rgba(43,44,170, 0.1);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:4% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_item_option_4_1{
	z-index: 10;
	height:42px !important;
	width:205px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:0px;
	margin-left:385px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/default_start_time.png) rgba(43,44,170, 0.1);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:4% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_item_listview_menu1_count_label {
	z-index: 10;
    height:30px !important;
    width:80px;
	position: absolute;
	margin-top:5px;
	margin-left:591px;
	border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.5);
	background-color: rgba(255,255,255, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
    text-align:center;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 600;
	text-indent: 0px;
    text-decoration:none;
} 
.itinerary_item_listview_menu1_count {
	z-index: 1;
    height:85px !important;
    width:80px;
	position: absolute;
	margin-top:0px;
	margin-left:591px;
	border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
    text-align:center;
	color:#888888;
    font-size: 1.50em  !important;
    font-weight: 500;
	text-indent: 0px;
    text-decoration:none;
}
.itinerary_item_listview_menu2_count_label {
	z-index: 10;
    height:30px !important;
    width:80px;
	position: absolute;
	margin-top:5px;
	margin-left:672px;
	border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.5);
	background-color: rgba(255,255,255, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
    text-align:center;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 600;
	text-indent: 0px;
    text-decoration:none;
} 
.itinerary_item_listview_menu2_count {
	z-index: 1;
    height:85px !important;
    width:80px;
	position: absolute;
	margin-top:0px;
	margin-left:672px;
	border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
    text-align:center;
	color:#888888;
    font-size: 1.50em  !important;
    font-weight: 500;
	text-indent: 0px;
    text-decoration:none;
} 
.itinerary_item_option_status{
	z-index: 10;
	height:85px !important;
	width:130px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:0px;
	margin-left:445px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/clear_img.png) rgba(45,255,255, 0.5);
    background-size: 130px 90px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_item_option_activate_default{
	z-index: 10;
	height:85px !important;
	width:105px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:0px;
	margin-left:754px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/event_details/event_item_active_icon.png) rgba(239,239,239, 0.7);
    background-size: 60px 70px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_item_option_activate_active{
	z-index: 10;
	height:85px !important;
	width:105px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:0px;
	margin-left:754px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/event_details/event_item_active_icon.png) rgba(10, 245, 92, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
    
    itinerary_item_option_delete
    */
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_item_option_delete{
	z-index: 10;
	height:85px !important;
	width:80px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:0px;
	margin-left:860px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(252, 71, 71, 0.7);
    background-size: 50px 60px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
/*
itinerary_item_listview_menu1_count_label
*/
.itinerary_compliance_create_main_table{
    height:580px !important;
    width:710px !important;
    line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.0);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.itinerary_compliance_create_menu_table{
    height:55px !important;
    width:710px !important;
    line-height:12px !important;
    border-bottom: 1px solid rgba(204,204,204, 0.0);
	background-color:  rgba(255,255,255, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.itinerary_compliance_create_menu_td1{
    height:55px !important;
    width:405px !important;
    line-height:12px !important;
    border: 1px solid rgba(204,204,204, 0.2);
	background-color:  rgba(255,255,255, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: top;
	text-align: left;
}
.itinerary_compliance_create_menu_td2{
    height:55px !important;
    width:150px !important;
    line-height:12px !important;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:  rgba(255,255,255, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: top;
	text-align: left;
}

.itinerary_compliance_create_options_table{
    height:100px !important;
    width:710px !important;
    line-height:12px !important;
    border-bottom: 1px solid rgba(204,204,204, 0.0);
	background-color:  rgba(255,255,255, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}




/*********************/
.itinerary_compliance_create_notification_options_table{
    height:105px !important;
    width:710px !important;
    line-height:12px !important;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color:  rgba(255,255,255, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_create_notification_options_td{
    height:52.5px !important;
    width:175px !important;
    line-height:12px !important;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color:  rgba(239,239,239, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_notification_option1_default{
	height:50px !important;
    width:172px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background-color: rgba(255,255,255, 0.7);
	/*
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 160px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	*/
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
/*********************/
.itinerary_compliance_create_poll_options_table{
    height:105px !important;
    width:710px !important;
    line-height:12px !important;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color:  rgba(255,255,255, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_create_poll_options_td{
    height:105px !important;
    width:175px !important;
    line-height:12px !important;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color:  rgba(239,239,239, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.itinerary_compliance_poll_option1_default{
	height:102px !important;
    width:172px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 160px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_compliance_poll_option1_active{
	height:102px !important;
    width:172px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	/*background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);*/
	background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 160px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_compliance_poll_option2_default{
	height:102px !important;
    width:172px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 160px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_compliance_poll_option2_active{
	height:102px !important;
    width:172px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	/*background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);*/
	background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 160px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_compliance_poll_option3_default{
	height:102px !important;
    width:172px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 160px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_compliance_poll_option3_active{
	height:102px !important;
    width:172px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	/*background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);*/
	background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 160px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_compliance_poll_option4_default{
	height:102px !important;
    width:172px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 160px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.itinerary_compliance_poll_option4_active{
	height:102px !important;
    width:172px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	/*background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);*/
	background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.7);
    background-size: 160px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
/*********************/
.itinerary_compliance_create_message_count_div{
    height:30px !important;
    width:55px !important;
	display: inline-block;
	position: absolute;
	margin-top: 2px;
	margin-left: 652px;
	background-color: rgba(239,239,239, 0.0);
	border: 1px solid rgba(204,204,204, 0.0);
	vertical-align: top;
}
.ls_form_messageboxinput_field_notification{
	resize: none;
    height:143px !important;
    width: 707px !important;
	line-height: 12px !important;
 	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
    background:rgba(239,239,239, 0.5);
   	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 10px;
 }
.itinerary_compliance_create_message_table{
    height:180px !important;
    width:710px !important;
	display: inline-block;
    
	background-color: rgba(239,239,239, 0.3);
    border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
    border-bottom: 1px solid rgba(204,204,204, 0.0);
    border-top: 1px solid rgba(204,204,204, 0.0);
}
.itinerary_item_option_select_date{
	height:51px !important;
    width:155px !important;
	line-height:12px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 3px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.2);
	background: url(../images/buttons/default_calendar.png) rgba(43,44,170, 0.1);
	/*
	../images/buttons/default_clock.png
	../images/buttons/sys_default_clock.png
	event_container_bottom_frame
	event_date_full_field
	*/
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:4% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_item_option_select_time{
	height:51px !important;
    width:85px !important;
	line-height:12px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 159px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.2);
	background: url(../images/buttons/default_clock.png) rgba(43,44,170, 0.1);
	/*
	../images/buttons/default_clock.png
	../images/buttons/sys_default_clock.png
	event_time_full_field
	event_container_bottom_frame_alternate
	itinerary_compliance_create_options_table
	*/
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:4% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_item_option_start_time{
	height:51px !important;
    width:159px !important;
	line-height:12px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 245px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.2);
	background: url(../images/buttons/default_start_time.png) rgba(43,44,170, 0.1);
	/*
	../images/buttons/default_clock.png
	../images/buttons/sys_default_clock.png
	event_container_bottom_frame
	event_date_full_field
	ls_form_messageboxinput_field_notification
	*/
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:4% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_compliance_create_menu_notification_default{
    height:52px !important;
    width:145px !important;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.5);
	background-size: 40px 38px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_compliance_create_menu_notification_active{
    height:52px !important;
    width:145px !important;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background: url(../images/buttons/clear_img.png) rgba(10, 245, 92, 0.7);
	background-size: 40px 38px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}

.itinerary_compliance_create_menu_poll_default{
    height:52px !important;
    width:145px !important;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.5);
	background-size: 40px 38px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_compliance_create_menu_poll_active{
    height:52px !important;
    width:145px !important;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background: url(../images/buttons/clear_img.png) rgba(10, 245, 92, 0.7);
	background-size: 40px 38px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
/*********************/
/*********************/
.itinerary_compliance_create_message_count_poll1_div{
    height:30px !important;
    width:55px !important;
	display: inline-block;
	position: absolute;
	margin-top: 2px;
	margin-left: 652px;
	background-color: rgba(239,239,239, 0.0);
	border: 1px solid rgba(204,204,204, 0.0);
	vertical-align: top;
}
.ls_form_messageboxinput_field_poll1{
	resize: none;
    height:142px !important;
    width: 708px !important;
	line-height: 20px !important;
 	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
    background:rgba(239,239,239, 0.5);
   	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	vertical-align:middle;
	text-align:center;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 0px;
 }

.ls_form_messageboxinput_field_poll1_answerbox{
	resize: none;
    height:98px !important;
    width: 174px !important;
	line-height: 20px !important;
 	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.2);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:4% 4%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
   	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	vertical-align:middle !important;
	text-align:center;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 0px;
 }
/*********************/
/*********************/
.itinerary_compliance_create_message_count_poll2_div{
    height:30px !important;
    width:55px !important;
	display: inline-block;
	position: absolute;
	margin-top: 2px;
	margin-left: 350px;
	background-color: rgba(239,239,239, 0.0);
	border: 1px solid rgba(204,204,204, 0.0);
	vertical-align: top;
}
.ls_form_messageboxinput_field_poll2{
	resize: none;
    height:243px !important;
    width: 406px !important;
	line-height: 12px !important;
 	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
    background:rgba(239,239,239, 0.5);
   	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 0px;
 }
.ls_form_messageboxinput_field_poll2_answerbox{
	resize: none;
    height:58px !important;
    width: 298px !important;
	line-height: 12px !important;
 	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.2);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:4% 4%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
   	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	vertical-align:middle;
	text-align:center;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 0px;
 }
/*********************/
/*********************/
.itinerary_compliance_create_message_count_poll3_div{
    height:30px !important;
    width:55px !important;
	display: inline-block;
	position: absolute;
	margin-top: 2px;
	margin-left: 652px;
	background-color: rgba(239,239,239, 0.0);
	border: 1px solid rgba(204,204,204, 0.0);
	vertical-align: top;
}
.ls_form_messageboxinput_field_poll3{
	resize: none;
    height:97px !important;
    width: 708px !important;
	line-height: 12px !important;
 	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
    background:rgba(239,239,239, 0.5);
   	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 0px;
 }
.ls_form_messageboxinput_field_poll3_answerbox{
	resize: none;
    height:46px !important;
    width: 174px !important;
	line-height: 12px !important;
 	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.2);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:4% 4%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
   	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	vertical-align:middle;
	text-align:center;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 0px;
 }
/*********************/
/*********************/
.itinerary_compliance_create_message_count_poll4_div{
    height:30px !important;
    width:55px !important;
	display: inline-block;
	position: absolute;
	margin-top: 2px;
	margin-left: 652px;
	background-color: rgba(239,239,239, 0.0);
	border: 1px solid rgba(204,204,204, 0.0);
	vertical-align: top;
}
.ls_form_messageboxinput_field_poll4{
	resize: none;
    height:142px !important;
    width: 708px !important;
	line-height: 12px !important;
 	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
    background:rgba(239,239,239, 0.5);
   	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 0px;
 }
.ls_form_messageboxinput_field_poll4_answerbox{
	resize: none;
    height:98px !important;
    width: 174px !important;
	line-height: 12px !important;
 	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.2);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:4% 4%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
   	word-wrap: break-word !important;
	overflow-wrap: break-word !important;
	vertical-align:middle;
	text-align:center;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 0px;
 }
/*********************/
/*********************/
.itinerary_compliance_poll1_table1{
    height:145px !important;
    width:708px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}
.itinerary_compliance_poll1_table2_td{
    height:100px !important;
    width:176px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}
/*********************/
/*********************/
.itinerary_compliance_poll2_table1{
    height:245px !important;
    width:408px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}
.itinerary_compliance_poll2_table2_td{
    height:61px !important;
    width:300px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}
/*********************/
/*********************/
.itinerary_compliance_poll3_table1{
    height:100px !important;
    width:708px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}
.itinerary_compliance_poll3_table2_td{
    height:48px !important;
    width:176px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}
/*********************/
/*********************/
.itinerary_compliance_poll4_table1{
    height:145px !important;
    width:708px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}
.itinerary_compliance_poll4_table2_td1{
    height:100px !important;
    width:176px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}
.itinerary_compliance_poll4_table2_td2{
    height:100px !important;
    width:176px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}
/*********************/
/*********************/


/*********************/
/*********************/
.itinerary_compliance_action_td1{
	/*
	itinerary_compliance_create_main_table
	*/
    height:50px !important;
    width:485px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}
.itinerary_compliance_action_td2{
	/*
	itinerary_compliance_create_main_table
	*/
    height:50px !important;
    width:110px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}
.itinerary_compliance_action_activate{
	height:47px !important;
    width:107px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	/*background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);*/
	background: rgba(10, 245, 92, 0.7);
	background-size: 40px 38px;
    background-position:4% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_compliance_action_deactivate{
	height:47px !important;
    width:107px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	/*background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);*/
	background: rgba(252, 71, 71, 0.7);
	background-size: 40px 38px;
    background-position:4% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.itinerary_compliance_action_delete{
	height:47px !important;
    width:107px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	/*background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);*/
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(252, 71, 71, 0.7);
	background-size: 40px 38px;
    background-position:4% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 500;
	text-indent: 0px;
}

/*********************/
/*********************/
.itinerary_compliance_results_td1{
	/*
	itinerary_compliance_create_main_table
	*/
    height:111px !important;
    width:235px !important;
	display: inline-block;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px;
    border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:center;
}


/*********************/
/*********************/
/*********************************************/
.settings_compliance_seating_table {
    height:125px !important;
    width: 950px;
   	margin:0;
    padding:0;
	border-spacing: 0px;
    border:hidden;
	background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: top !important;
	text-align:left!important;
	
}
.settings_compliance_seating_main_table{
    height:540px !important;
    width:946px !important;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px; 
    display:inline-block;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: top;
	text-align:left;
}

.settings_compliance_seating_main_table_a_row1{
    height:120px !important;
    width:946px !important;
    display:inline-block;
	margin-top: 0px;
	margin-left: 0px; 
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: top;
	text-align:left;
}

.settings_compliance_seating_main_table_b_row1{
    height:200px !important;
    width:946px !important;
	line-height: 12px;
    display:inline-block;
	margin-top: 0px;
	margin-left: 0px; 
    border:1px solid rgba(239,239,239, 0.8);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle !important;
	text-align:center !important;
}
.settings_compliance_seating_main_table_c_row1{
    height:220px !important;
    width:946px !important;
	line-height: 12px;
    display:inline-block;
	margin-top: 0px;
	margin-left: 0px; 
    border:1px solid rgba(239,239,239, 0.8);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: middle !important;
	text-align:center !important;
}
.settings_compliance_seating_table_td01-01{
    height:60px !important;
    width:645px !important;
    display:inline-block;
    border:1px solid rgba(239,239,239, 0.3);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: top;
	text-align:left;
}

.settings_compliance_seating_td-top{
    height:57px !important;
    width:200px !important;
    line-height:12px !important;
	margin-top: 1px;
	margin-left: 1px; 
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(0,0,255, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: top;
	text-align:left;
}

.settings_compliance_seating_status{
    height:57px !important;
    width:56px !important;
    display:inline-block;
	margin-top: 1px;
	margin-left: 1px; 
    border:1px solid rgba(239,239,239, 0.2);
   	background: rgba(255,255,255, 0.1);
  	-webkit-box-shadow: none !important;
  	-moz-box-shadow: none !important;
  	box-shadow: none !important;
	text-align:left;
	vertical-align: top;
}

.settings_compliance_seating_activity{
    height:57px !important;
    width:385px !important;
    display:inline-block;
	margin-top: 1px;
	margin-left: 1px;
    border:1px solid rgba(239,239,239, 0.2);
   	background: rgba(255,255,255, 0.1);
  	-webkit-box-shadow: none !important;
  	-moz-box-shadow: none !important;
  	box-shadow: none !important;
	text-align:left;
	vertical-align: top;
}
/*********************/
.settings_compliance_seating_manage_positive {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_compliance_seating_manage_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_compliance_ticketing_manage_positive {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_compliance_ticketing_manage_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************/
.settings_compliance_seating_mix_max1 {
	z-index: 9;
	height:57px !important;
    width:382px !important;
	
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(239,239,239, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 10px;
}
.settings_compliance_seating_mix_max1_active {
	z-index: 9;
	height:47px !important;
    width:360px !important;
	line-height: 12px;
	display: inline-block;
	position: absolute;
	margin-top:5px;
	margin-left:10px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 700;
	text-indent: 0px;
}
/*********************//*********************//*********************/
/*********************//*********************//*********************/
.event_container_data7_holiday_1 {
	height: 56px;
	width: 355px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:left;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data7_holiday_2 {
	height: 56px;
	width: 185px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:left;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data_holiday_category_button {
	height: 56px;
	width: 350px !important;
	line-height: 56px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:5px;
}

.event_container_data7_holiday_1_item {
	height: 45px;
	width: 355px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:left;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data7_holiday_2_item {
	height: 45px;
	width: 185px !important;
	line-height: 12px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:top;
	text-align:left;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_container_data_holiday_item_category_button {
	height: 45px;
	width: 350px !important;
	line-height: 45px;
	border: 1px solid rgba(239,239,239, 0.3);
	background-color:rgba(239,239,239, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 0.95em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
    
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    text-indent:5px;
}
/************************************/
/************************//************************//************************/
	/************************//*	HOLIDAY_CATEGORY_CLASS	*/
	/********//*	HOLIDAY_CATEGORY_CLASS_MAIN	*/

.holiday_selection_type_active{
	/*
	holiday_frame_option_2
	*/
	z-index: 10;
	position: absolute;
	height:112px !important;
    width:230px !important;
	background-color: transparent;
	border-spacing: 0px;
    border:2px solid rgba(10, 245, 92, 0.7);
}

.holiday_messagedisplayselection_type_default{
    z-index: 1;
    height:60px !important;
    width:224px !important;

    display: inline-block;
    line-height:20px;
    position:absolute;
	margin-top: 30px;
    margin-left: 3px;
    margin-right: 0;
    border-spacing: 0px;
    border:1px solid rgba(233,233,233, 0.0);

    color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;
    padding: 0;
	background-color: rgba(255,255,255, 0.0);
	/*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:5% 45%;
	*/
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/***********************************/
/***********************************/
.holiday_select_active {
    border-spacing: 0px !important;
	border: 3px solid rgba(11,183,255, 0.7) !important;
}
.holiday_select_frame_absolute {
	height:109px !important;
    width:126px;
    /*
    search_result_frame_absolute
	width:184px;
    */
    display: inline-block;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	margin-top:7px;
	margin-left:5px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.holiday_select_frame_title {
    z-index: 1;
	height:40px !important;
    width:124px;
    /*
    search_result_frame_absolute
	width:184px;
    */
    display: inline-block;
    position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
    background: rgba(255,255,255, 0.1);
	margin-top:35px;
	margin-left:1px;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.90em  !important;
    font-weight:600;
	text-indent: 0px;
}
.holiday_selection_type_1{
	/*
	holiday_frame_absolute
	event_manager_result_frame_absolute
	*/
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_2{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_3{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_4{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.holiday_selection_type_5{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_6{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_7{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_8{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_9{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_10{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_11{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_12{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_13{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_14{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_15{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_16{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_17{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_18{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_19{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_20{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_21{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_22{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_23{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_24{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_25{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_26{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_27{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_28{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_29{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_30{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_31{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_32{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_33{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_34{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_selection_type_35{
    z-index: 0;
    height:109px !important;
    width:126px;
    margin-top: 0px;
    margin-left: 0px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:center;
    background-size: 87px 83px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/***********************************/
/***********************************/
.holiday_parent_list_type_1{
	/*
	holiday_frame_absolute
	
	*/
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_2{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_3{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_4{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.holiday_parent_list_type_5{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_6{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_7{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_8{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_9{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_10{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_11{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_12{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_13{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_14{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_15{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_16{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_17{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_18{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_19{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_20{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_21{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_22{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_23{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_24{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_25{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_26{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_27{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_28{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_29{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_30{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_31{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_32{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_33{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_34{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_parent_list_type_35{
    z-index: 0;
    height:50px !important;
    width:60px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 50px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/***********************************/
/***********************************/
.holiday_item_list_type_1{
	/*
	holiday_frame_absolute
	
	*/
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_2{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_3{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_4{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.holiday_item_list_type_5{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_6{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_7{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_8{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_9{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_10{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_11{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_12{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_13{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_14{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_15{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_16{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_17{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_18{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_19{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_20{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_21{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_22{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_23{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_24{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_25{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_26{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_27{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_28{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_29{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_30{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_31{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_32{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_33{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_34{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.holiday_item_list_type_35{
    z-index: 0;
    height:45px !important;
    width:55px !important;
    margin-top: 2px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    font-size: 0;
    text-align:center !important;
    vertical-align:top !important;
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-position:50% 45%;
    background-size: 40px 35px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************************/
/************************//************************//************************/
	/************************//*	HOLIDAY_OCCURENCE_CLASS	*/
	/********//*	HOLIDAY_OCCURENCE_CLASS_MAIN	*/

.holiday_container_td1 {
	height: 100px;
	width: 400px !important;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.holiday_container_td2 {
	height: 100px;
	width: 550px !important;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.holiday_container_td3 {
	height: 50px;
	width: 950px !important;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.holiday_container_td4 {
	height: 400px;
	width: 950px !important;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.occurrence_div_01{
	z-index: 10;
	height: 533px;
	width: 950px !important;
	display: none;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(239,239,239, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.occurrence_table_td{
	z-index: 10;
	height: 500px !important;
	width: 950px !important;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.2);
	background-color:rgba(239,239,239, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.occurence_button_1{
    z-index: 0;
    height:98px !important;
    width:397px !important;
    margin-top: 1px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
   
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-position:1% 45%;
    background-size: 90px 90px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:left!important;
    vertical-align:top !important;
	color:#888888;
    font-size: 1.20em  !important;
    font-weight: 700;
    text-decoration:none;
}
.occurence_button_active{
    z-index: 1;
    height:48px !important;
    width:187px !important;
    margin-top: 1px;
    margin-left: 1px;
    border:2px solid rgba(10, 245, 92, 0.7);
    /*
	background-color: rgba(255,255,255, 0.5);
	*/
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-position:1% 45%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:center !important;
    vertical-align:middle!important;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 700;
    text-decoration:none;
}
.occurence_button_default{
    z-index: 1;
    height:48px !important;
    width:187px !important;
    margin-top: 1px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
    border:1px solid rgba(204,204,204, 0.5);
	/*
	background-color: rgba(255,255,255, 0.5);
	*/
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.5);
    background-position:1% 45%;
    background-size: 40px 40px;
    background-repeat: no-repeat;
	
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:center !important;
    vertical-align:middle!important;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 700;
    text-decoration:none;
}

.occurence_messagedisplayselection_type_default{
    z-index: 1;
    height: 98px;
	width: 547px !important;
    display: inline-block;
    line-height:20px;

    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.5);

    margin-top: 1px;
    margin-left: 1px;
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:5% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-align:center;
    vertical-align:top;
    text-decoration:none;

	
}
/**********************************/
/**********************************/
.occurrence_table_td_top1{
	/*
	occurrence_table_td
	*/
	z-index: 10;
	height: 100px !important;
	width: 947px !important;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.2);
	background-color:rgba(239,239,239, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.occurrence_table_td_bottom{
	/*
	occurrence_table_td
	*/
	z-index: 10;
	height: 397px !important;
	width: 947px !important;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.2);
	background-color:rgba(239,239,239, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}

.occurence_button_reoccur_selection{
    height:39px !important;
    width:273px !important;
    margin-top: 1px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
   
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:left!important;
    vertical-align:top !important;
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
    text-decoration:none;
	text-indent: 20px;
}
.occurence_button_reoccur_selection_active{
    height:39px !important;
    width:273px !important;
    margin-top: 1px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
   
    border:1px solid rgba(204,204,204, 0.5);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:left!important;
    vertical-align:top !important;
	color:#888888;
    font-size: 0.70em  !important;
    font-weight: 500;
    text-decoration:none;
	text-indent: 20px;
}
.occurence_button_reoccur_top_selection{
    height:48px !important;
    width:156px !important;
    margin-top: 1px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
   
    border:1px solid rgba(204,204,204, 0.5);
    /*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%;
    */
    background-color: rgba(255,255,255, 0.7);
    
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:center !important;
    vertical-align:middle !important;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
    text-decoration:none;
	text-indent: 0px;
}
.occurence_button_reoccur_top_selection_active{
    height:48px !important;
    width:156px !important;
    margin-top: 1px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
   
    border:1px solid rgba(204,204,204, 0.5);
    /*
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%;
    */
    background-color: rgba(10, 245, 92, 0.7);
    
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:center!important;
    vertical-align:middle !important;
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
    text-decoration:none;
	text-indent: 0px;
}
.occurence_button_reoccur_bottom_selection{
    height:78px !important;
    width:470px !important;
    margin-top: 1px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
   
    border:1px solid rgba(204,204,204, 0.5);
    background-color: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:left!important;
    vertical-align:top !important;
	color:#888888;
    font-size: 1.20em  !important;
    font-weight: 500;
    text-decoration:none;
	text-indent: 20px;
}
.occurence_button_reoccur_bottom_selection_active{
    height:78px !important;
    width:470px !important;
    margin-top: 1px;
    margin-left: 1px;
    display: inline-block;
    position:relative;
   
    border:1px solid rgba(204,204,204, 0.5);
    background-color: rgba(10, 245, 92, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:left!important;
    vertical-align:top !important;
	color:#888888;
    font-size: 1.20em  !important;
    font-weight: 500;
    text-decoration:none;
	text-indent: 20px;
}
/**********************************/
/**********************************/
.holiday_tags_container_td {
	/*
	holiday_tag_frame_absolute
	*/
	height: 100px;
	width: 948px !important;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.holiday_tags_container_td01 {
	/*
	
	*/
	height: 100px;
	width: 197px !important;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.holiday_tags_container_td02 {
	/*
	
	*/
	height: 100px;
	width: 748px !important;
	border-spacing: 0px;
	border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}

/*********************//*********************//*********************/
/*********************//*********************//*********************/


/*********************//*********************//*********************/
/*********************//*********************//*********************/




/*--------*//*--------*//*--------*//*--------*//*--------*//*--------*/
}

/*   -------------------------------------------------- */
/*   -------------------------------------------------- */
/*   ------------    main_metrics      ---------------- */
/*   
/*
1) RGB(11,183,255);

2) RGB(232, 232, 232)

3) RGB(255, 68, 255)

4) RGB(255, 255, 255) 
/*
/*  
/*  
/*
/*  
/*

/*  
/* 
/*
/*
/*		
/*  
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001
CONTRUCTION
    BORDERS
        TABLE OUTSIDE   border: 1px solid rgba(209,209,209, 0.7);
        TD OUTSIDE      border: 1px solid rgba(204,204,204, 0.7);

        TABLE INSIDE    border: 1px solid rgba(204,204,204, 0.0);
        TD INSIDE       border: 1px solid rgba(204,204,204, 0.2);

        BUTTON          border: 1px solid rgba(204,204,204, 0.3);    
		
    BACKGROUND
        MAIN            background-color: rgba(255,255,255, 0.7);   
        PAGE            background-color: rgba(255,255,255, 0.2);
        FORM PAGE       background-color: rgba(239,239,239, 0.7);
        MENU            background-color: rgba(239,239,239, 0.7);  
        BUTTON          background-color: rgba(204,204,204, 0.7);   
		
    rgba(255,255,255, 0.7) = #FFF (white)
    rgba(0,0,0, 0.7) =  (black)
    rgba(239,239,239, 0.7) = #EFEFEF (grey)
    rgba(204,204,204, 0.7) = #CCC (dark border)
    rgba(0,255,0, 0.7)  =(green)
    rgba(255,255,0, 0.7) =(yellow)
    rgba(255,0,0, 0.7)  =(red)
    rgba(15,10,222,0.7)  =(blue)


    rgba(15, 5, 26, 0.7) =  (black scheme)
    rgba(10, 245, 92, 0.7)  =(green scheme)
    rgba(4, 144, 242, 0.7)  =(blue scheme)
    rgba(255, 28, 255, 0.7)  =(pink scheme)
    rgba(204, 204, 204, 0.7)  =(grey scheme)
    rgba(252, 71, 71, 0.7)  =(red scheme)
    rgba(255, 255, 128, 0.7) =(yellow scheme)
    204,204,204
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001
    MESSAGE INPUT HEADERS
                        background:rgba(239,239,239, 0.1);
                        border-spacing: 0px;
                        border:hidden;
                        font-size: 1.05em;
                        font-weight: 500;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
    
    LABELS: ls_form_label   reset_form_label    ls_form_full_field
                        
                        font-size: .90em;
                        font-weight: 600;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.2);
                        background:rgba(239,239,239, 0.7);


    BUTONS:
                        font-size: .90em;
                        font-weight: 600;
                        text-align:center;
                        text-indent:0px;
                        text-decoration:none;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.3);
                        background-color: rgba(204,204,204, 0.7);
                        -webkit-box-shadow: none !important;
                        -moz-box-shadow: none !important;
                        box-shadow: none !important;

    INPUT FIELDS        ls_form_full_field_error 
                        height:35px !important;
                        width: 98%  !important;
                        line-height:12px !important;
                        text-align:left;
                        vertical-align:middle;
                        font-size: .80em;
                        font-weight: 500;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
                        border-spacing: 0px;
                        border:hidden;
                        background-color:rgba(239,239,239, 0.7);

ls_form_submit_button   login_submit_default    ls_notification_submit_button   eventsystem_div_04   metrics_container_td_center
                                                                                                    
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */

/* 010101010101010101010101010101010101010101010101010101010101010100101010110010110101010110101001 */
/*/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */

/*

*/
/*********************************************/
/*********************************************/

.metrics_container_td {
	height: 580px;
	width: 950px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.metrics_container_td_top {
	height: 50px;
	width: 950px !important;
	position:relative;
	margin-top: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/********/
.metrics_container_td_graph {
	z-index: 10;
	height:50px !important;
	width:154px;
    line-height:50px;
    display: inline-block;
    margin-top:0px;
	margin-left:3px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background-color:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.metrics_container_graph_pie {
	height:50px !important;
	width:154px;
    line-height:50px;
    display: inline-block;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	margin-top:0px;
	margin-left:0px;
	background-color: rgba(255, 255, 255,0.7);
    /*
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 255,0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:center;
    */
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
    color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
/*********************************************/
/*********************************************/
.metrics_container_td_main {
    height: 380px;
	width: 810px !important;
	position:relative;
	margin-top: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.1);
    background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.metrics_container_td_span {
    height: 150px;
	width: 810px !important;
	position:relative;
	margin-top: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.1);
    background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/************/
.metrics_container_1_SPAN {
	height: 150px;
	width: 810px !important;
	position:absolute;
    display:hidden;
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(4, 144, 242, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}

/*********************************************/
/*********************************************/
.metrics_container_td_left {
	height: 380px;
	width: 700px !important;
	position:relative;
	margin-top: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.1);
    /*
    metrics_container_1_DIV
    
	background: url(../images/bkgrounds/metrics01.png) rgba(255, 255, 255,0.4);
	background-size: 300px 300px;
	background-repeat: no-repeat;
	background-position:center;
    */
    background-color: rgba(255, 255, 255,0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/********/
.metrics_container_graph_DIV_active {
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(204, 204, 204, 0.7);
    /*
    rgba(4, 144, 242, 0.7);
    */
}
.metrics_container_default_DIV {
    z-index:5;
	height: 380px !important;
	width: 700px !important;
	position:absolute;
    display:block;
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/bkgrounds/metrics01.png) rgba(255, 255, 255,0.5);
	background-size: 300px 300px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.metrics_container_1_DIV {
    z-index:10;
	height: 380px !important;
	width: 700px !important;
	position:absolute;
    display:hidden;
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.metrics_container_2_DIV {
    z-index:10;
	height: 380px !important;
	width: 700px !important;
	position:absolute;
    display:hidden;
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.metrics_container_3_DIV {
    z-index:10;
	height: 380px !important;
	width: 700px !important;
	position:absolute;
    display:hidden;
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.metrics_container_4_DIV {
    z-index:10;
	height: 380px !important;
	width: 700px !important;
	position:absolute;
    display:hidden;
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.metrics_container_5_DIV {
    z-index:10;
	height: 380px !important;
	width: 700px !important;
	position:absolute;
    display:hidden;
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.metrics_container_6_DIV {
    z-index:10;
	height: 380px !important;
	width: 700px !important;
	position:absolute;
    display:hidden;
	margin-top: 0px;
    margin-left: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/*********************************************/
/*********************************************/
.metrics_container_td_center {
	height: 380px;
	width: 110px !important;
	position:relative;
	margin-top: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/********/

/*********************************************/
/*********************************************/
.metrics_container_td_right {
	height: 530px;
	width: 137px !important;
	position:relative;
	margin-top: 0px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/********/
.metrics_container_td_enity {
	z-index: 10;
	height:72px !important;
	width:135px;
    display: inline-block;
    margin-top:3px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background-color:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.metrics_container_enity_account {
	height:72px !important;
	width:135px;
    line-height:12px;
    display: inline-block;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	margin-top:0px;
	margin-left:0px;
    background-color: rgba(255, 255, 255,0.7);
    /*
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 255,0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:center;
    */
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
    color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.metrics_container_entity_SET_active {
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(204, 204, 204, 0.7);
    /*
    rgba(4, 144, 242, 0.7);
    */
}
/*********************************************/
/*********************************************/



/*********************************************/
/*********************************************/



/*********************************************/
/*********************************************/

/*   -------------------------------------------------- */
/*   -------------------------------------------------- */
/*   ------------    main_unique      ---------------- */
/*   
/*	LANDSCAPE_LOGIN
/*  	
/*	
/*	ON_PRESS_OPTIONS
/*  	ON_PRESS_MAIN

/*	EVENT_TYPE_OPTIONS
/*  	EVENT_TYPE_MAIN
/*  	EVENT_TYPE_CHILD
/*		EVENT_TYPE_MULTI
/*		EVENT_TYPE_SELECT
/*	
/*  
/*  EVENT_GUIDELINES_OPTIONS
/*  	EVENT_GUIDELINES_MAIN
/*  	EVENT_GUIDELINES_SOCIAL
/*		EVENT_GUIDELINES_PROFILE
/*   	EVENT_GUIDELINES_CONTRIBUTIONS
/*  	EVENT_GUIDELINES_SHARING
/*		EVENT_GUIDELINES_AGE
/*	
/* 
/*  EVENT_DESCRIPTION_OPTIONS
/*  	EVENT_DESCRIPTION_MAIN
/*  	EVENT_DESCRIPTION_EVENTVIEW
/*  	EVENT_DESCRIPTION_BASICVIEW
/*		EVENT_DESCRIPTION_LISTVIEW
/*  	EVENT_DESCRIPTION_MULTIVIEW
/*  
/*
/*  
/*  EVENT_EDIT_OPTIONS
/*  	EVENT_EDIT_MAIN
/*
/*  
/*  EVENT_MENU_OPTIONS
/*		EVENT_MENU_MAIN
/*  
/*  
/*  EVENT_MULTI_CHILD
/*		EVENT_MULTI_CHILD_MAIN 
/*  
/*  
/*  EVENT_ITEM_VIEW_WINDOW
/*	
/*
/*	EVENT_MAP_MANAGER
/*
/*
/*	EVENT_CREATOR_MANAGER
/*		EVENT_CREATOR_MANAGER_MAIN
/*  
/*  
/*  SWIPE_ITEM_VIEW_WINDOW
/*		SWIPE_ITEM_VIEW_MAIN
/*  	SWIPE_ITEM_PAGINATION
/*  	SWIPE_ITEM_PAGE_FORM
/*  
/*  
/*	SWIPE_IMAGE_VIEW_WINDOW
/*  	SWIPE_IMAGE_VIEW_MAIN
/*  	SWIPE_IMAGE_PAGINATION
/*  	SWIPE_IMAGE_PAGE_FORM
/*  
/*
/*	MEDIA_VIEW_WINDOW
/*  	MEDIA_VIEW_MAIN
/*  	
/*  	  
/*  EVENT_SWIPE_MAIN
/*		EVENT_SWIPE_VIEW_MAIN 
/*
/*  
/*  EVENT_SETTINGS
/*		EVENT_SETTINGS_MAIN 
/*
/*  
/*  
/*  PROFILE_FEED_SETTINGS
/*		PROFILE_FEED_SETTINGS_MAIN 
/*		PROFILE_FEED_STYLE_MAIN
/*		PROFILE_FEED_SERVER_MAIN
/*		PROFILE_FEED_NOW_MAIN
/*		PROFILE_FEED_HISTORIC_MAIN
/*		
/*  SEARCH_BAR_SETTINGS
/*		SEARCH_BAR_SETTINGS_MAIN 
/*		SEARCH_RESULTS_SETTINGS_MAIN
/*
/*	MAP_KEY
/*
/*  
/*  
/*  PROFILE_IDENTITY_CLASS
/*		PROFILE_IDENTITY_CLASS_MAIN 
/*
/*
/*  
/* 	BUSINESS_TYPE_CLASS
/*		BUSINESS_TYPE_CLASS_MAIN
/*  
/* 
/* 	BUSINESS_VIEW
/*		BUSINESS_VIEW_CLASS_MAIN
/*  	BUSINESS_VIEW_CLASS_BASIC_FRAME
/*		BUSINESS_VIEW_CLASS_BLOCK_FRAME
/*		BUSINESS_VIEW_CLASS_SLICE_FRAME
/*		BUSINESS_VIEW_CLASS_SHOWROOM_FRAME
/*
/*  
/* 	BUSINESS_SETTINGS
/*		BUSINESS_SETTINGS_MAIN
/*  
/* 
/*	BUSINESS_RECOMMENDATION
/*  	BUSINESS_RECOMMENDATION_MAIN
/*  
/*	RECOMMENDATIONS_FRAME_MANAGER
/* 
/*	IDENTITY_GEOGRAPHY
/*  	IDENTITY_GEOGRAPHY_MAIN
/*	
/*	SYSTEM_SEARCH	
/*		SYSTEM_SEARCH_MAIN
/*
/*	
/*	REPORT_CONCERNS	
/*		REPORT_CONCERNS_MAIN
/*
/*
/*	INFINITE_SCROLL_MANAGER	
/*		INFINITE_SCROLL_MANAGER_MAIN
/*	
/*
/*	COMPLIANCE_MANAGER	
/*		COMPIANCE_MANAGER_MAIN
/*
/*  
/*
/*	COMPLIANCE_MANAGER	
/*		COMPIANCE_MANAGER_MAIN
/*
/*
/*	HOLIDAY_CATEGORY_CLASS
/*	HOLIDAY_OCCURENCE_CLASS
/*	
/*		
/* 
/*
/*
/*		
/*  
/*
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001
CONTRUCTION
    BORDERS
        TABLE OUTSIDE   border: 1px solid rgba(209,209,209, 0.7)
        TD OUTSIDE      border: 1px solid rgba(204,204,204, 0.7)

        TABLE INSIDE    border: 1px solid rgba(204,204,204, 0.0)
        TD INSIDE       border: 1px solid rgba(204,204,204, 0.2)

        BUTTON          border: 1px solid rgba(204,204,204, 0.3)
		
    BACKGROUND
        MAIN            background-color: rgba(255,255,255, 0.7);
        PAGE            background-color: rgba(255,255,255, 0.2);
        FORM PAGE       background-color: rgba(239,239,239, 0.7);
        MENU            background-color: rgba(239,239,239, 0.7);
		
    rgba(255,255,255, 0.7) = #FFF (white)
    rgba(0,0,0, 0.7) =  (black)
    rgba(239,239,239, 0.7) = #EFEFEF (grey)
    rgba(204,204,204, 0.7) = #CCC (dark border)
    rgba(0,255,0, 0.7)  =(green)
    rgba(255,255,0, 0.7) =(yellow)
    rgba(255,0,0, 0.7)  =(red)
    rgba(15,10,222,0.7)  =(blue)

    rgba(15, 5, 26, 0.7) =  (black scheme)
    rgba(10, 245, 92, 0.7)  =(green scheme)
    rgba(4, 144, 242, 0.7)  =(blue scheme)
    rgba(255, 28, 255, 0.7)  =(pink scheme)
    rgba(204, 204, 204, 0.7)  =(grey scheme)
    rgba(252, 71, 71, 0.7)  =(red scheme)
    rgba(255, 255, 128, 0.7) =(yellow scheme)
    204,204,204
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 ls_form_acccount_cost_select_field
    MESSAGE INPUT HEADERS
                        background:rgba(239,239,239, 0.7);
                        border-spacing: 0px;
                        border:hidden;
                        font-size: 1.05em;
                        font-weight: 500;
                        text-align:left;
                        text-decoration:none;
                        text-indent:1px;
    
    LABELS: login_form_label_top   reset_form_label ls_form_full_field
                        
                        font-size: .90em;
                        font-weight: 600;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.2);
                        background:rgba(239,239,239, 0.7);


    BUTONS:
                        font-size: .90em;
                        font-weight: 600;
                        text-align:center;
                        text-indent:0px;
                        text-decoration:none;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.3);
                        background-color: rgba(204,204,204, 0.7);
                        -webkit-box-shadow: none !important;
                        -moz-box-shadow: none !important;
                        box-shadow: none !important;

/*  
/*	media_menu_pagination_ajax	temp_feed_time_recent_frame			QR_CODE_DIV_BUSINESSS	mobile_base_functional_table_td  
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 
/*********************************************/
/*********************************************/
/* -----------------------------------------------------------------------------------------/   */
/* ----------------------------------------------------------------------------------------- */
@media screen and (max-device-width: 1900px) and (min-device-width:501px){
/*********************************************/
/*********************************************/
/* ################################################ */
/* ######              LANDSCAPE_LOGIN        ##### */	
.login_message_table{
	height: 40px;
    width: 500px;
    line-height:40px;
    background-color:rgba(255,255,255, 0.2); 
    border:hidden;
	vertical-align: top;
	text-align: left;
}
.login_message{
	height: 40px;
    width: 500px;
    line-height:40px;
    font-size: 1.25em;
    font-weight: 600;
    text-align:left;
	vertical-align: middle;
    text-decoration:none;
	text-indent: 25px !important;
	color: black !important;
    background-color:rgba(255,255,255, 0.2);
    border:hidden;
}
.login_table_left{
	height: 155px !important;
    width: 648px;
    line-height:40px;
    background-color:rgba(255,255,255, 0.2); 
    border:hidden;
	vertical-align: top;
	text-align: left;
    padding:0;
    margin:0;
}
/*
.login_table_right{
	height: 155px;
    width: 148px;
    line-height:40px;
    background-color:rgba(255,255,255, 0.2); 
    border:hidden;
	vertical-align: top;
	text-align: left;
}
*/
.login_table_left_01{
    height:100px !important;
    width:648px !important;
    background-color:rgba(255,255,255, 0.7);
    border:hidden;
    padding:0;
    margin:0;
}
.login_table_left_02{
    height:55px !important;
    width:648px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0; 
    padding:0;
    margin:0;
}
/*
display_space_table_bottom
.login_table_right_01{
    height:50px !important;
    width:148px !important;
    background-color:rgba(255,255,255, 0.2);
    border:hidden; 
}
.login_table_right_02{
    height:50px !important;
    width:148px !important;
    background-color:rgba(255,255,255, 0.2);
    border:hidden;
}
.login_table_right_03{
    height:50px !important;
    width:148px !important;
    background-color:rgba(255,255,255, 0.2);
    border:hidden;
}
*/
.display_space_table_top{
    height:190px !important;
    width:650px !important;
    background-color:rgba(255,255,255, 0.1);
    border:0;
}
.display_top_td01{
    height:183px !important;
    width:640px !important;
    margin-top: 5px;
	margin-left: 5px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(203,203,203, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_space_table_top03{
    height:150px !important;
    width:720px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0;
}

.display_top_td03{
    height:143px !important;
    width:720px !important;
	line-height: 143px;
    margin-top: 0px;
	margin-left: 0px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(255,255,58, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.display_space_table_top04{
    height:50px !important;
    width:720px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0;
}
.display_top_td04{
    height:43px !important;
    width:720px !important;
	line-height: 43px;
    margin-top: 0px;
	margin-left: 0px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(255,255,58, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_space_table_top05{
    height:80px !important;
    width:718px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0;
}
.display_top_td05{
    height:73px !important;
    width:718px !important;
	line-height: 73px;
    margin-top: 0px;
	margin-left: 0px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(255,255,255, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_space_table_05{
    height:10px !important;
    width:718px !important;
	line-height: 20px;
    margin-top: 0px;
	margin-left: 0px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(255,255,255, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_space_table_bottom05{
    height:118px !important;
    width:718px !important;
	line-height: 20px;
    margin-top: 0px;
	margin-left: 0px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(255,255,255, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*

*/
.display_space_table_top06{
    height:190px !important;
    width:650px !important;
    background-color:rgba(255,255,255, 0.1);
    border:0;
}
.display_top_td06{
    height:183px !important;
    width:640px !important;
    margin-top: 5px;
	margin-left: 5px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(203,203,203, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_space_table_top09{
    height:70px !important;
    width:720px !important;
    vertical-align: top !important;
	text-align: left !important;
    background-color:rgba(255,255,255, 0.2);
    border:0;
}
.display_top_td09{
    height:70px !important;
    width:720px !important;
	line-height: 12px;
    margin-top: 0px;
	margin-left: 0px;
	vertical-align: top !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(203,203,203, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_space_table_bottom{
    height:225px !important;
    width:650px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0; 
    padding:0;
    margin:0;
	vertical-align: top;
	text-align: left;
}
.display_bottom_td01{
    height:218px !important;
    width:640px !important;
    margin-top: 5px;
	margin-left: 5px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(203,203,203, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_space_table_bottom02{
    height:187px !important;
    width:650px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0; 
    padding:0;
    margin:0;
	vertical-align: top;
	text-align: left;
}
.display_bottom_td02{
    height:187px !important;
    width:640px !important;
    margin-top: 0px;
	margin-left: 5px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(203,203,203, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_space_table_bottom03{
    height:340px !important;
    width:720px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0; 
    padding:0;
    margin:0;
	vertical-align: top;
	text-align: left;
}
.display_space_table_bottom04{
    height:245px !important;
    width:720px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0; 
    padding:0;
    margin:0;
	vertical-align: top;
	text-align: left;
}
.display_space_table_bottom33{
    height:160px !important;
    width:720px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0; 
    padding:0;
    margin:0;
	vertical-align: top;
	text-align: left;
}
.display_bottom_td03{
    height:335px !important;
    width:720px !important;
    margin-top: 0px;
	margin-left: 0px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(255,255,58, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_bottom_td04{
    height:240px !important;
    width:720px !important;
    margin-top: 0px;
	margin-left: 0px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(255,255,58, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_bottom_td33{
    height:155px !important;
    width:720px !important;
    margin-top: 0px;
	margin-left: 0px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(255,255,58, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.display_space_table_bottom06{
    height:230px !important;
    width:650px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0;
	vertical-align: top;
	text-align: left;
}
.display_bottom_td06{
    height:226px !important;
    width:640px !important;
    margin-top: 1px;
	margin-left: 5px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(203,203,203, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_space_table_bottom07{
    height:280px !important;
    width:650px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0;
	vertical-align: top;
	text-align: left;
}
.display_bottom_td07{
    height:276px !important;
    width:640px !important;
    margin-top: 1px;
	margin-left: 5px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(203,203,203, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_space_table_bottom08{
    height:190px !important;
    width:650px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0;
	vertical-align: top;
	text-align: left;
}
.display_bottom_td08{
    height:186px !important;
    width:640px !important;
    margin-top: 1px;
	margin-left: 5px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(203,203,203, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.display_space_table_bottom09{
    height:150px !important;
    width:720px !important;
    background-color:rgba(255,255,255, 0.2);
    border:0; 
    padding:0;
    margin:0;
	vertical-align: top;
	text-align: left;
}
.display_bottom_td09{
    height:146px !important;
    width:718px !important;
    margin-top: 1px;
	margin-left: 1px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.8) !important;
    background: rgba(203,203,203, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.registration_div1{
    /*
    registration_login_business_default
    reset_div
    login_form_label_top
    */
	height:95px !important;
    width:148px !important;
	position: absolute !important;
	top: 60px;
	left: 150px;
    border:0;
    background-color:rgba(255,255,255, 0.2);
}
.registration_div2{
	height:95px !important;
    width:148px !important;
	position: absolute !important;
	top: 60px;
	left: 350px;
    border:0;
    background-color:rgba(255,255,255, 0.2);
}
.reset_div{
	height:55px !important;
    width:98px !important;
	position: absolute !important;
	top: 330px;
	left: 357px;
    border:0;
    background-color:rgba(255,255,255, 0.2);
}
.activate_div{
	height:30px !important;
    width:98px !important;
	position: absolute !important;
	margin-top: 225px;
	margin-left: 5px;
    vertical-align:top!important;
    text-align:left;
    border:0;
    background-color:rgba(255,255,255, 0.2);
}
.registration_login_activate_default {
    height:26px !important;
    width:94px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 2px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.login_error_td {
    height:47px !important;
    width:355px !important;
    display:inline-block;
    line-height:47px !important;
    vertical-align:middle !important;
    border-right:hidden;
    border-left:hidden;
    border-top:hidden;
    border-bottom:hidden;
    border-spacing: 0px;
    padding:0;
    margin:0;
}
.login_form_label_blank {
    height:50px !important;
    width:100px !important;
}
/*
.login_table_01
*/
.login_form_label_top {
    height:47px !important;
    width:150px !important;
    line-height:12px !important;
    text-align:right;
    vertical-align:middle !important;
    font-size: .90em;
    font-weight: 600;
    text-decoration:none;
    text-indent:5px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255,255,255, 0.7);

}
.login_form_label_bottom {
    height:47px !important;
    width:150px !important;
    line-height:12px !important;
    text-align:right;
    vertical-align:middle !important;
    font-size: .90em;
    font-weight: 600;
    text-decoration:none;
    text-indent:5px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255,255,255, 0.7);
}
.login_form_full_table_top {
    height:47px !important;
    width:395px !important;
    text-align:center;
    vertical-align:middle !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255,255,255, 0.7);
}
.login_form_full_table_bottom {
    height:47px !important;
    width:395px !important;
    text-align:center;
    vertical-align:middle !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(255,255,255, 0.7);
}
/*	
.login_form_link_button1 {
    height: 25px !important;
    width: 130px !important;
    line-height:25px;
    font-size:8px;
    font-weight:500;
    text-align:center;
    text-decoration:none;
    background-color:rgba(239,239,239, 0.7);
    border-right:hidden;
    border-left:hidden;
    border-top:hidden;
    border-bottom:1px solid rgba(204,204,204, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.login_form_link_button2 {
    height: 25px !important;
    width: 130px !important;
    line-height:25px;
    font-size:8px;
    font-weight:500;
    text-align:center;
    text-decoration:none;
    background-color:rgba(239,239,239, 0.7);
    border-right:hidden;
    border-left:hidden;
    border-top:hidden;
    border-bottom:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
reset_div
*/
.login_form_command_bar_td1{
    height:50px !important;
    width:208px !important;
    line-height:12px;
    border-spacing:0px;
    border: 1px solid rgba(204,204,204, 0.0);
    text-align:center !important;
    vertical-align:middle !important;
}
.login_form_command_bar_td2{
    height:50px !important;
    width:200px !important;
    line-height:12px;
    border-spacing:0px;
    border: 1px solid rgba(204,204,204, 0.0);
    text-align:center !important;
    vertical-align:middle !important;
}
.login_form_command_bar_td3{
    height:50px;
    width:100px;
    line-height:12px;
    border-spacing:0px;
    border: 1px solid rgba(204,204,204, 0.2);
    text-align:center;
    vertical-align:middle !important;
}
.login_form_command_bar_td4{
    height:50px;
    width:50px;
    line-height:12px;
    border-spacing:0px;
    border: 1px solid rgba(204,204,204, 0.2);
    text-align:center;
    vertical-align:middle !important;
}
.login_form_command_bar_td5{
    height:50px;
    width:130px;
    line-height:50px;
    border-spacing:0px;
    border: 1px solid rgba(204,204,204, 0.2);
    text-align:center;
    vertical-align:middle !important;
    background:rgba(255,255,255, 0.3);
} 
.login_check_button_default {
    height:50px;
    width: 46px;
    margin-top: 0px;
	margin-left: 1px;
    font-size:12px;
    font-weight:700;
    text-decoration:none;
    line-height:50px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border-right:hidden;
    border-left:hidden;
    border-top:hidden;
    border-bottom:hidden;
    border-style:none;
    background: url(../images/buttons/sys_stay_logged_default.png) rgba(204,204,204, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center center; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.login_check_button_active {
    height:50px;
    width: 46px;
    margin-top: 0px;
	margin-left: 1px;
    font-size:12px;
    font-weight:700;
    text-decoration:none;
    line-height:50px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border-right:hidden;
    border-left:hidden;
    border-top:hidden;
    border-bottom:hidden;
    border-style:none;
    background: url(../images/buttons/sys_stay_logged_active.png) rgba(204,204,204, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center center; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
login_form_command_bar_td1
registration_div
reg_check_button_default 
reg_check_button_default  
ls_select_button_action
*/
.login_submit_default {
    height:50px !important;
    width:130px !important;
    line-height:12px !important;
    margin-top: 0px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.login_submit_active {
    height:50px !important;
    width:130px !important;
    line-height:12px !important;
    margin-top: 0px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/clear_img.png) rgba(11,183,255, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}


.registration_login_reset_default {
    height:50px !important;
    width:105px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 2px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.registration_login_profile_default {
    height:92px !important;
    width:145px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 2px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/buttons/sys_profile_buttonDefault.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 80px 80px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.registration_login_business_default {
    height:92px !important;
    width:145px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 2px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    font-size: .85em;
    font-weight: 500;
    vertical-align:middle!important;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
    background: url(../images/sys_option_default/default_building.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 80px 80px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.registration_confirmation_div1{
    height:370px; 
    width:300px;
    margin-top:2px;
    margin-left:2px;
    text-align:right;
	border:2px solid rgba(204,204,204, 0.7);
    background-color:rgba(255,255,255, 0.7);
}

.registration_confirmation_div2{
    height:370px; 
    width:300px;
    margin-top:2px;
    margin-left:2px;
    text-align:right;
	border:2px solid rgba(204,204,204, 0.7);
    background-color:rgba(255,255,255, 0.7);
}

.registration_confirmation_div3{
    height:370px; 
    width:348px;
    margin-top:2px;
    margin-left:2px;
    text-align:right;
	border:2px solid rgba(204,204,204, 0.7);
    background-color:rgba(255,255,255, 0.7);
}
.registration_confirmation_div3_1{
    height:122px; 
    width:346px;
    margin-top:0px;
    margin-left:0px;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(255,255,255, 0.7);
}
.registration_confirmation_div3_2{
    height:122px; 
    width:346px;
    margin-top:0px;
    margin-left:0px;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(255,255,255, 0.7);
}
.registration_confirmation_div3_3{
    height:122px; 
    width:346px;
    margin-top:0px;
    margin-left:0px;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.3);
    background-color:rgba(255,255,255, 0.7);
}

/* ################################################ */
/* ######          LANDSCAPE_MEMBER_FEEDBACK  ##### */


/************************//************************//************************/
/************************//************************//************************/
.admin_frame{
    z-index: 10;
    height:730px;
    width:300px;
	position:absolute;
    margin-top: 5px;
    margin-left: 960px;
    border: 2px solid  rgba(204,204,204, 0.3);
    background-color: rgba(142,156,242,0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align: center; 
}
.admin_frame_td{
    z-index: 10;
    height:50px;
    width:295px;
    margin-top: 1px;
    margin-left: 1px;
    border: 1px solid  rgba(204,204,204, 0.7);
	background: rgba(255,255,255, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
	text-align: center;
}
.admin_frame_td_label{
    z-index: 10;
    height:47px;
    width:292px;
    margin-top: 1px;
    margin-left: 1px;
    border: 1px solid  rgba(204,204,204, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.8);
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position:1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
	text-align: left;
	text-indent: 20px;
	font-size: 1.20em;
	font-weight: 600;
	/*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
}
.admin_frame_td_base{
    z-index: 10;
    height:40px;
    width:294px;
    margin-top: 1px;
    margin-left: 1px;
    border: 1px solid  rgba(204,204,204, 0.1);
    background-color: rgba(255,255,255,0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align: left; 
}
.admin_frame_td1{
    z-index: 10;
    height:90px;
    width:294px;
    margin-top: 1px;
    margin-left: 1px;
    border: 1px solid  rgba(204,204,204, 0.1);
    background-color: rgba(255,255,255,0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align: left; 
}
.admin_frame_td2{
    z-index: 10;
    height:90px;
    width:294px;
    margin-top: 1px;
    margin-left: 1px;
    border: 1px solid  rgba(204,204,204, 0.1);
    background-color: rgba(255,255,255,0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align: left; 
}
.admin_frame_td3{
    z-index: 10;
    height:90px;
    width:294px;
    margin-top: 1px;
    margin-left: 1px;
    border: 1px solid  rgba(204,204,204, 0.1);
    background-color: rgba(255,255,255,0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align: left; 
}
.admin_frame_td4{
    z-index: 10;
    height:90px;
    width:294px;
    margin-top: 1px;
    margin-left: 1px;
    border: 1px solid  rgba(204,204,204, 0.1);
    background-color: rgba(255,255,255,0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align: left; 
}

.admin_01_button{
    height:60px;
    width:70px;
    margin-top: 1px;
    margin-left: 2px;
    border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
    background-size: 65px 45px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align: center; 
}
.admin_frame_td_search{
    z-index: 10;
    height:35px;
    width:294px;
    margin-top: 1px;
    margin-left: 1px;
    border: 1px solid  rgba(204,204,204, 0.3);
    background-color: rgba(255,255,255,0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
	text-align: center; 
}


/************************//************************/
.admin_holiday_create_button{
    height:60px;
    width:120px;
    margin-top: 1px;
    margin-left: 2px;
    border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(100,255,255, 0.7);
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align: center; 
}
.admin_holiday_manager_button{
    height:60px;
    width:165px;
    margin-top: 1px;
    margin-left: 2px;
    border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
    background: url(../images/buttons/butt_template.png) rgba(255,255,100, 0.7);
    background-size: 45px 45px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align: center; 
}
/************************//************************/


/************************//************************/


/************************//************************/


/************************//************************/

/************************//************************/



/*
event_option_main_table_td_type2
swipe_panel_window
*/
/************************//************************//************************/
/************************//************************//************************/
	/************************//*	ON_PRESS_OPTIONS	*/
	/********//*	ON_PRESS_MAIN	*/
/*
	.on_press2{
		z-index: 99999;
		height: 75px;
		width: 75px;
		display:inline-block;
		
		position:absolute;
		
		vertical-align:top;
		
		margin-top: 0;
		margin-left: 0px;
		
		border:hidden;
		background: url(../images/buttons/OnPress-1.png) rgba(255,255,255, 0.0);
		background-size: 75px 75px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }

*/
	.on_press_init{
		z-index: 99999;
		height: 75px;
		width: 75px;
		display:inline-block;
		/**/
		position:absolute;
		
		vertical-align:top;
		
		margin-top: 0;
		margin-left: 0px;
		
		border:hidden;
		background: url(../images/buttons/OnPress-2.png) rgba(255,255,255, 0.0);
		background-size: 75px 75px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.on_press1{
		z-index: 99999;
		height: 75px;
		width: 75px;
		display:inline-block;
		/**/
		position:absolute;
		
		vertical-align:top;
		
		margin-top: 0;
		margin-left: 0px;
		
		border:hidden;
		background: url(../images/buttons/OnPress-1.png) rgba(255,255,255, 0.0);
		background-size: 75px 75px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.on_press0{
		z-index: 99999;
		height: 75px;
		width: 75px;
		display:inline-block;
		/**/
		position:absolute;
		
		vertical-align:top;
		
		margin-top: 0;
		margin-left: 0px;
		
		border:hidden;
		background: url(../images/buttons/OnPress-0.png) rgba(255,255,255, 0.0);
		background-size: 75px 75px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }

	.exit_press{
		z-index: 1;
	  	height:40px !important;
		width:40px !important;
		
		display: inline-block;
		line-height: 40px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 10px;
		margin-left: 10px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 0.50em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.9);
		background-size: 35px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }


/************************//************************//************************/
	/************************//*	EVENT_TYPE_OPTIONS	*/
	/********//*	EVENT_TYPE_MAIN	*/
	.event_type_default{
		
		height:283px !important;
		width:230px !important;
		
		position:relative;
		display: inline-block;
		
		border-spacing: 0px;
		border:hidden;
		
		padding: 0;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.event_type_edit {
		height: 28px;
		width: 28px;
		display:inline-block;
		position:absolute;
		
		vertical-align:top;
		
		margin-top: 1;
		margin-left: 102px;
		
		border:1px solid #ccc;
		background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
		background-size: 23px 23px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_type_1 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype1.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_2 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype2.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_3 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype3.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_4 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype4.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_5 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype5.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_6 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype6.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_7 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype7.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_8 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype8.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_9 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype9.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_10 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype10.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_11 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype11.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_12 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype12.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_13 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype13.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_14 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype14.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_15 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype15.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_16 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype16.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_17 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype17.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_18 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype18.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_19 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype19.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_type_20 {
		height: 125px;
		width: 130px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype20.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 125px 120px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/************************//************************/
	/********//*	EVENT_TYPE_CHILD	*/
	.event_messagedisplaychild_type_default{
		z-index: 1;
	  	height:60px !important;
		width:226px !important;
		
		display: inline-block;
		line-height: 60px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 225;
		margin-left: -231px;
		
		color:#888888;
		font-size: 2.00em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:5% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	 }
	
	.event_messagedisplaychild_type_pop_flare{
		z-index: 0;
	  	height:200px !important;
		width:80px !important;
		
		display: inline-block;
		line-height: 200px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 8;
		margin-left: -85px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 0.70em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}

	.event_child_type_1 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype1.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_2 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype2.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_3 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype3.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_4 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype4.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_5 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype5.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_6 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype6.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_7 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype7.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_8 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype8.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_9 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype9.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_10 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype10.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_11 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype11.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_12 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype12.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_13 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype13.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_14 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype14.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_15 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype15.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_16 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype16.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_17 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype17.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_18 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype18.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_19 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype19.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_child_type_20 {
		height: 70px;
		width: 70px;
		line-height:33px;
		position: absolute;
		margin-top: 60px;
		margin-left: 10px;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype20.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/************************//************************/
	/********//*	EVENT_TYPE_MULTI	*/
	.event_multi_type_1 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype1.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_2 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype2.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_3 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype3.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_4 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype4.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_5 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype5.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_6 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype6.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_7 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype7.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_8 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype8.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_9 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype9.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_10 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype10.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_11 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype11.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_12 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype12.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_13 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype13.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_14 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype14.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*
margin-top: 60px;
		margin-left: 10px;
position: absolute;

*/
	.event_multi_type_15 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype15.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_16 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype16.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_17 {
		hheight: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype17.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_18 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype18.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_19 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype19.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_multi_type_20 {
		height: 75px;
		width: 75px;
		line-height:33px;
		
		margin-top: 0px;
		margin-left: 0px;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype20.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/************************//************************/
	/********//*	EVENT_TYPE_FEED	2ROWS*/
	.event_feed_2rows_type_1 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype1.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_2 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype2.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_3 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype3.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_4 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype4.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_5 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype5.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_6 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype6.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_7 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype7.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_8 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype8.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_9 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype9.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_10 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype10.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_11 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype11.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_12 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype12.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_13 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype13.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_14 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype14.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_15 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype15.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_16 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype16.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_17 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype17.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_18 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype18.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_19 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype19.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_2rows_type_20 {
		height: 95px;
		width: 95px;
		line-height:33px;
		
		margin-top: 90px;
		margin-left: -522px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype20.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/************************//************************/
	/********//*	EVENT_TYPE_FEED DOUBLE	*/
	.event_feed_double_type_1 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype1.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_2 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype2.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_3 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype3.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_4 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype4.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_5 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype5.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_6 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype6.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_7 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype7.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_8 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype8.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_9 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype9.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_10 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype10.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_11 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype11.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_12 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype12.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_13 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype13.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_14 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype14.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_15 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype15.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_16 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype16.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_17 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype17.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_18 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype18.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_19 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype19.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_double_type_20 {
		height: 60px;
		width: 60px;
		line-height:33px;
		
		margin-top: 35px;
		margin-left: -262px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype20.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 55px 55px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/************************//************************/
	/********//*	EVENT_TYPE_FEED SINGLE	*/
	.event_feed_single_type_1 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype1.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_2 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype2.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_3 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype3.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_4 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype4.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_5 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype5.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_6 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype6.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_7 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype7.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_8 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype8.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_9 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype9.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_10 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype10.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_11 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype11.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_12 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype12.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_13 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype13.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_14 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype14.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_15 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype15.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_16 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype16.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_17 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype17.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_18 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype18.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_19 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype19.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_feed_single_type_20 {
		height: 55px;
		width: 55px;
		line-height:33px;
		
		margin-top: 30px;
		margin-left: -124px;
		
		position: absolute;
		
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype20.png) rgba(255,255,255, 0.5);
		background-position:center;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/************************//************************/
	/********//*	EVENT_TYPE_SELECT	*/
	.event_messagedisplayselection_type_default{
		z-index: 1;
	  	height:60px !important;
		width:212px !important;
		
		display: inline-block;
		line-height:20px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color:#888888;
		font-size: 0.60em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:top;
		text-decoration:none;
		
		margin-top: 3px;
		margin-left: -218px;
		margin-right: 0;
		padding: 0;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:5% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	.event_messagedisplaychild_type_flare{
		z-index: 1;
	  	height:138px !important;
		width:80px !important;
		
		display: inline-block;
		line-height: 20px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 2px;
		margin-left: -82px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 0.60em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:top;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}

.event_messagedisplayselection_type_bl{
	z-index: 1;
	height:30px !important;
	width:70px !important;

	display: inline-block;
	line-height:20px;
	position:absolute;

	border-spacing: 0px;
	border:1px solid #CCC;

	color:#888888;
	font-size: 0.60em  !important;
	font-weight: 700;
	text-align:center;
	vertical-align:top;
	text-decoration:none;

	margin-top: 110px;
	margin-left: -180px;
	margin-right: 0;
	padding: 0;

	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.5);
	background-size: 1px 10px;
	background-repeat: no-repeat;
	background-position:5% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_messagedisplayselection_type_br{
	z-index: 1;
	height:30px !important;
	width:70px !important;

	display: inline-block;
	line-height:20px;
	position:absolute;

	border-spacing: 0px;
	border:1px solid #CCC;

	color:#888888;
	font-size: 0.60em  !important;
	font-weight: 700;
	text-align:center;
	vertical-align:top;
	text-decoration:none;

	margin-top: 110px;
	margin-left: -100px;
	margin-right: 0;
	padding: 0;

	background: url(../images/buttons/butt_template.png) rgba(45,255,255, 0.5);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:5% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*

*/
.feed_container_activity_top_bar {
	height:45px !important;
	width:950px !important;
	border-spacing: 0px;
	border:hidden;
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.feed_container_top_bar {
	height:40px !important;
	width:948px !important;
	border-spacing: 0px;
	border:hidden;
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.feed_container_top_bar_td1 {
	height:70px !important;
	width:869px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_top_bar_td2 {
	height:70px !important;
	width:75px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,4, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	select_profile_default
	*/
} 
/*
feed_container_top_bar_medium
*/
.feed_container_top_bar_full {
	height:40px !important;
	width:700px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.1);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_safesearch_table{
	z-index: 90;
    height:40px !important;
    width: 80px !important;
   /* width: 99%;
	ls_search_bar_submit
	
	*/
    line-height:30px !important;
    margin-top: 0px;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(203,203,203, 0.2);
    font-size: .85em;
    font-weight: 500;
    text-align:left;
    vertical-align:top;
    text-decoration:none;
}
.feed_container_safesearch_td{
	z-index: 90;
    height:38px !important;
    width: 78px !important;
   /* 
    ls_search_bar_moderate
	feed_container_safesearch_td div_search_bar_high_safesearch
	*/
    line-height:38px !important;
   
    margin-top: 0px;
    margin-left: 1px;
    border-spacing: 0px;
    border:1px solid rgba(203,203,203, 0.2);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.5);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.45em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.feed_container_filter_table{
	z-index: 90;
    height:40px !important;
    width: 170px;
   /* width: 99%;
	ls_search_bar_submit
	
	*/
    line-height:30px !important;
    margin-top: 0px;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(203,203,203, 0.2);
    font-size: .85em;
    font-weight: 500;
    vertical-align:top;
    text-align:left;
    text-decoration:none;
}
.feed_container_filter_td{
	z-index: 90;
    height:18px !important;
    width: 82px;
   /* width: 99%;
	ls_search_bar_submit
	
	*/
    line-height:18px !important;
    margin-top: 1px;
    margin-left: 2px;
    background-color:rgba(239,239,239, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(203,203,203, 0.2);
    font-size: .85em;
    font-weight: 500;
    vertical-align:top;
    text-align:left;
    text-decoration:none;
    text-indent:2px;
}
.feed_container_top_bar_1_1 {
	height:40px !important;
	width:690px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.1);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_top_bar_1_2 {
	height:40px !important;
	width:258px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.1);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_top_bar_1_2_td01 {
	height:40px !important;
	width:42px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	background-color: rgba(239,239,239, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_top_bar_1_2_td02 {
	height:40px !important;
	width:62px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	background-color: rgba(239,239,239, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_top_bar_1_2_td03 {
	height:40px !important;
	width:84px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	background-color: rgba(239,239,239, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_top_bar_2_1 {
	height:40px !important;
	width:778px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.1);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_top_bar_2_2 {
	height:40px !important;
	width:170px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.1);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_container_td {
	height:459px !important;
	width:714px !important;
	line-height: 45px;
	border-spacing: 0px;
	border:hidden;
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:top;
	text-align:left;
	background-color: rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	
	
	*/
}
.settings_calendar_container_td {
	height:248px !important;
	width:714px !important;
	line-height: 45px;
	border-spacing: 0px;
	border:hidden;
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:top;
	text-align:left;
	background-color: rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_top_bar_medium {
	height:40px !important;
	width:462px !important;
	
	border-spacing: 0px;
	border:hidden;
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:top;
	text-align:left;
	background-color: rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	base_functional_listview_search_tr
	recommendation_results_container_top_bar_full
	*/
}
.feed_container_top_bar_medium_td {
	height:40px !important;
	width:462px !important;
	
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.1);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.type_select_event_absolute {
	height:145px !important;
	width:231px;
	border-spacing: 0px;
	border:1px solid #CCC;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
	.event_selection_type_1{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype1.png) rgba(255,255,255, 0.1);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_2{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype2.png) rgba(255,255,255, 0.1);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_3{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype3.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_4{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype4.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.event_selection_type_5{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype5.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_6{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype6.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_7{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype7.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_8{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype8.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_9{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype9.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_10{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype10.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_11{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype11.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_12{
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid #CCC;
		background: url(../images/event_types/eventtype12.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_13{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype13.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_14{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype14.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_15{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype15.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_16{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype16.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_17{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype17.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_18{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype18.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_19{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype19.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.event_selection_type_20{
		z-index: 0;
		height:140px !important;
		width:220px !important;
		margin-top: 2px;
		margin-left: 4px;
		display: inline-block;
		position:relative;
		font-size: 0;
		text-align:center !important;
		vertical-align:top !important;
		border:1px solid rgba(204,204,204, 0.5);
		background: url(../images/event_types/eventtype20.png) rgba(255,255,255, 0.5);
		background-position:50% 45%;
		background-size: 90px 130px;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}



/*
event_swipe_temp_stat_attendees_default
*/

	/************************//************************/
	/********//*	EVENT_DESCRIPTION_EVENTVIEW	*/
/*

	height:260px !important;
	width:451px;



*/
	.type_event_view_options_1 {
		height:180px !important;
		width:312px;
		
		border-spacing: 0px;
		border:hidden;
		
		vertical-align:top !important;
		
		text-align:center;
		
		background: url(../images/event_descriptions/eventdescription_1.png);
		background-size: 290px 160px;
		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_event_view_options_2 {
		height:180px !important;
		width:312px;
		
		border-spacing: 0px;
		border:hidden;
		
		vertical-align:top !important;
		
		text-align:center;
		
		background: url(../images/event_descriptions/eventdescription_2.png);
		background-size: 290px 160px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_event_view_options_3 {
		height:180px !important;
		width:312px;
		
		border-spacing: 0px;
		border:hidden;
		
		vertical-align:top !important;
		
		text-align:center;
		
		background: url(../images/event_descriptions/eventdescription_3.png);
		background-size: 290px 160px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_event_view_options_4 {
		height:180px !important;
		width:312px;
		
		border-spacing: 0px;
		border:hidden;
		
		vertical-align:top !important;
		
		text-align:center;
		
		background: url(../images/event_descriptions/eventdescription_4.png);
		background-size: 290px 160px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_event_view_options_5 {
		height:180px !important;
		width:312px;
		
		border-spacing: 0px;
		border:hidden;
		
		vertical-align:top !important;
		
		text-align:center;
		
		background: url(../images/event_descriptions/eventdescription_5.png);
		background-size: 290px 160px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_event_view_options_6 {
		height:180px !important;
		width:312px;
		
		border-spacing: 0px;
		border:hidden;
		
		vertical-align:top !important;
		
		text-align:center;
		
		background: url(../images/event_descriptions/eventdescription_6.png);
		background-size: 290px 160px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_event_view_options_7 {
		height:180px !important;
		width:312px;
		
		border-spacing: 0px;
		border:hidden;
		
		vertical-align:top !important;
		
		text-align:center;
		
		background: url(../images/event_descriptions/eventdescription_7.png);
		background-size: 290px 160px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_event_view_options_8 {
		height:180px !important;
		width:312px;
		
		border-spacing: 0px;
		border:hidden;
		
		vertical-align:top !important;
		
		text-align:center;
		
		background: url(../images/event_descriptions/eventdescription_8.png);
		background-size: 290px 160px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_event_view_options_9 {
		height:180px !important;
		width:312px;
		
		border-spacing: 0px;
		border:hidden;
		
		vertical-align:top !important;
		
		text-align:center;
		
		background: url(../images/event_descriptions/eventdescription_9.png);
		background-size: 290px 160px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_event_view_options_10 {
		height:180px !important;
		width:312px;
		
		border-spacing: 0px;
		border:hidden;
		
		vertical-align:top !important;
		
		text-align:center;
		
		background: url(../images/event_descriptions/eventdescription_10.png);
		background-size: 290px 160px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/************************//************************/
	/********//*	EVENT_DESCRIPTION_BASICVIEW	*/
	.type_list_view_options_select {
		height:100px !important;
		width:100px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
		
		margin-top:-102px;
		margin-left:12px;
		
		
		position:absolute;
		font-size: .75em;
		font-weight: 400;
		color:#666;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png)  rgba(239,239,239, 0.7);
		background-size: 90px 90px;
   		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.event_messagedisplaytype_view_options_full{
		z-index: 0;
	  	height:210px !important;
		width: 280px !important;
		line-height:24px !important;
		
		position: absolute;
		
		margin-top: 20px;
		margin-left: 10px;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border:1px solid #ccc;
		color:#888888;
		font-size: 1.10em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}

	.event_messagedisplaytype_view_options_left{
		z-index: 0;
	  	height:210px !important;
		width: 240px !important;
		line-height:24px !important;
		
		position: absolute;
		
		margin-top: 20px;
		margin-left: 10px;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border:1px solid #ccc;
		color:#888888;
		font-size: 1.10em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}
	.event_messagedisplaytype_view_options_right{
		z-index: 0;
	  	height:210px !important;
		width: 240px !important;
		line-height:24px !important;
		
		position: absolute;
		
		margin-top: 20px;
		margin-left: 50px;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border:1px solid #ccc;
		color:#888888;
		font-size: 1.10em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}
	.type_view_options_temp {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:hidden;
		position:fixed;
		
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_default.png);
		background-size: 300px 250px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.type_view_options_1 {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:hidden;
		position:fixed;
		
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_1.png);
		background-size: 300px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_view_options_2 {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:hidden;
		position:fixed;
		
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_2.png);
		background-size: 300px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_view_options_3 {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:hidden;
		position:fixed;
		
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_3.png);
		background-size: 300px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_view_options_4 {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:hidden;
		position:fixed;
		
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_4.png);
		background-size: 300px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_view_options_5 {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:hidden;
		position:fixed;
		
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_5.png);
		background-size: 300px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_view_options_6 {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:hidden;
		position:fixed;
		
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_6.png);
		background-size: 300px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_view_options_7 {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:hidden;
		position:fixed;
		
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_7.png);
		background-size: 300px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_view_options_8 {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:hidden;
		position:fixed;
		
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_8.png);
		background-size: 300px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_view_options_9 {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:hidden;
		position:fixed;
		
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_9.png);
		background-size: 300px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_view_options_10 {
		height:250px !important;
		width:300px;
		border-spacing: 0px;
		border:hidden;
		position:fixed;
		
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_10.png);
		background-size: 300px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/************************//************************/
	/********//*	EVENT_DESCRIPTION_LISTVIEW	*/
	.event_messagedisplaytype_search_panel{
		z-index: 0;
	  	height:120px !important;
		width: 949px !important;
		line-height:24px !important;
		
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border:1px solid #ccc;
		color:#888888;
		font-size: .85em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
		
		margin-top: 10px;
	}
/*

rgba(255,255,255, 0.7)
rgba(239,239,239, 0.7)
rgba(204,204,204, 0.7)
*/
	.event_messagedisplaylist_view_options_pop_flare{
		z-index: 0;
	  	height:80px !important;
		width:80px !important;
		
		display: inline-block;
		line-height: 20px;
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid rgba(204,204,204, 0.7);
		
		/*
		
		*/
		margin-top: -201px;
		margin-left: 33px !important;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 0.40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	}
	.event_messagedisplaytype_list_view_options_full{
		z-index: 0;
	  	height:153px !important;
		width: 210px !important;
		line-height:24px !important;
		position: absolute;
		
		
		margin-top: -173px;
		margin-left: -105px;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border:1px solid #ccc;
		color:#888888;
		font-size: .85em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}
	.event_messagedisplaytype_list_view_options_left{
		z-index: 0;
	  	height:153px !important;
		width: 180px !important;
		line-height:24px !important;
		position: absolute;
		
		
		margin-top: -173px;
		margin-left: -105px;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border:1px solid #ccc;
		color:#888888;
		font-size: .85em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}
	.event_messagedisplaytype_list_view_options_right{
		z-index: 0;
	  	height:153px !important;
		width: 180px !important;
		line-height:24px !important;
		position: absolute;
		
		
		margin-top: -173px;
		margin-left: -75px;
		
		background-color:rgba(255,255,255, 0.7);
		border-spacing: 0px;
		border:1px solid #ccc;
		color:#888888;
		font-size: .85em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		vertical-align:text-bottom !important;
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}
	
	 .type_list_options_absolute {
		height:203px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
		display: inline-block;
		margin-top:10px;
		margin-left:2px;
		vertical-align:middle;
		text-align:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.type_list_options_1 {
		height:203px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
	
		vertical-align:middle;
		text-align:center;
		background: url(../images/event_descriptions/eventdescription_1.png);
		background-size: 220px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.type_list_options_2 {
		height:203px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
	
		background: url(../images/event_descriptions/eventdescription_2.png);
		background-size: 220px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.type_list_options_3 {
		height:203px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
	
		background: url(../images/event_descriptions/eventdescription_3.png);
		background-size: 220px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.type_list_options_4 {
		height:203px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
	
		background: url(../images/event_descriptions/eventdescription_4.png);
		background-size: 220px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.type_list_options_5 {
		height:203px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
	
		background: url(../images/event_descriptions/eventdescription_5.png);
		background-size: 220px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_list_options_6 {
		height:203px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
	
		background: url(../images/event_descriptions/eventdescription_6.png);
		background-size: 220px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.type_list_options_7 {
		height:203px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
	
		background: url(../images/event_descriptions/eventdescription_7.png);
		background-size: 220px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.type_list_options_8 {
		height:203px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
	
		background: url(../images/event_descriptions/eventdescription_8.png);
		background-size: 220px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_list_options_9 {
		height:203px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
	
		background: url(../images/event_descriptions/eventdescription_9.png);
		background-size: 220px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.type_list_options_10 {
		height:203px !important;
		width:230px;
		border-spacing: 0px;
		border:1px solid #CCC;
		vertical-align:middle;
		text-align:center;
	
		background: url(../images/event_descriptions/eventdescription_10.png);
		background-size: 220px 170px;
   		background-position:50% 45%;
   		background-repeat: no-repeat;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/************************//************************/
	/********//*	EVENT_DESCRIPTION_MULTIVIEW	*/
/*
width:520px;
*/
	.event_messagedisplaytype_multiview_options_full{
		z-index: 1;
	  	height:205px !important;
		width:428px;
		line-height:25px !important;
		position: absolute;
		
		margin-top: -22px;
		margin-left: 1px;
		vertical-align:top !important;
		
		background-color:rgba(255,255,255, 0.2);
		border-spacing: 0px;
		
		border: hidden;
		
		color:#888888;
		font-size: 0.55em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}

	.event_messagedisplaytype_multiview_options_left{
		z-index: 1;
	  	height:205px !important;
		width:520px;
		line-height:25px !important;
		/**/
		position: absolute;
		
		margin-top: -122px;
		margin-left: 45px;
		vertical-align:top !important;
		
		
		background-color:rgba(255,255,255, 0.2);
		border-spacing: 0px;
		/*
		border:1px solid #ccc;
		event_messagedisplayevent_view_options_settings
		type_multiview_options_4
		*/
	
		border: hidden;
		
		color:#888888;
		font-size: 0.55em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}
	.event_messagedisplaytype_multiview_options_right{
		z-index: 1;
	  	height:205px !important;
		width:520px;
		line-height:25px !important;
		/**/
		position: absolute;
		
		margin-top: -122px;
		margin-left: 45px;
		vertical-align:top !important;
		
		
		background-color:rgba(255,255,255, 0.2);
		border-spacing: 0px;
		/*
		border:1px solid #ccc;
		event_messagedisplayevent_view_options_settings
		type_multiview_options_4
		*/
	
		border: hidden;
		
		color:#888888;
		font-size: 0.55em;
		font-weight: 500;
		
		text-indent: 5px;
		
		text-align:left;
		
		resize: none;
		overflow: auto;
		
		text-shadow: 0 0 0.5em #87F;
	}
/*

*/
.type_multiview_options_temp {
	z-index: 0;
	height:222px !important;
	width:432px;

	border-spacing: 0px;
	border:hidden;
	position:absolute;

	margin-top: -28px;
	margin-left: 0px;

	vertical-align:top;
	text-align:center;
	background: url(../images/event_descriptions/eventdescription_default.png);
	background-size: 430px 220px;
   	background-position:50% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.type_multiview_options_1 {
	z-index: 0;
	height:222px !important;
	width:432px;

	border-spacing: 0px;
	border:hidden;
	position:absolute;

	margin-top: -28px;
	margin-left: 0px;

	vertical-align:top;
	text-align:center;

	background: url(../images/event_descriptions/eventdescription_1.png);
	background-size: 430px 220px;
	background-position:50% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.type_multiview_options_2 {
	z-index: 0;
	height:222px !important;
	width:432px;

	border-spacing: 0px;
	border:hidden;
	position:absolute;

	margin-top: -28px;
	margin-left: 0px;

	vertical-align:top;
	text-align:center;

	background: url(../images/event_descriptions/eventdescription_2.png);
	background-size: 430px 220px;
	background-position:50% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.type_multiview_options_3 {
	z-index: 0;
	height:222px !important;
	width:432px;

	border-spacing: 0px;
	border:hidden;
	position:absolute;

	margin-top: -28px;
	margin-left: 0px;

	vertical-align:top;
	text-align:center;

	background: url(../images/event_descriptions/eventdescription_3.png);
	background-size: 430px 220px;
	background-position:50% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.type_multiview_options_4 {
	z-index: 0;
	height:222px !important;
	width:432px;

	border-spacing: 0px;
	border:hidden;
	position:absolute;

	margin-top: -28px;
	margin-left: 0px;

	vertical-align:top;
	text-align:center;

	background: url(../images/event_descriptions/eventdescription_4.png);
	background-size: 430px 220px;
	background-position:50% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.type_multiview_options_5 {
	z-index: 0;
	height:222px !important;
	width:432px;

	border-spacing: 0px;
	border:hidden;
	position:absolute;

	margin-top: -28px;
	margin-left: 0px;

	vertical-align:top;
	text-align:center;

	background: url(../images/event_descriptions/eventdescription_5.png);
	background-size: 430px 220px;
	background-position:50% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.type_multiview_options_6 {
	z-index: 0;
	height:222px !important;
	width:432px;

	border-spacing: 0px;
	border:hidden;
	position:absolute;

	margin-top: -28px;
	margin-left: 0px;

	vertical-align:top;
	text-align:center;

	background: url(../images/event_descriptions/eventdescription_6.png);
	background-size: 430px 220px;
	background-position:50% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.type_multiview_options_7 {
	z-index: 0;
	height:222px !important;
	width:432px;

	border-spacing: 0px;
	border:hidden;
	position:absolute;

	margin-top: -28px;
	margin-left: 0px;

	vertical-align:top;
	text-align:center;

	background: url(../images/event_descriptions/eventdescription_7.png);
	background-size: 430px 220px;
	background-position:50% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.type_multiview_options_8 {
	z-index: 0;
	height:222px !important;
	width:432px;

	border-spacing: 0px;
	border:hidden;
	position:absolute;

	margin-top: -28px;
	margin-left: 0px;

	vertical-align:top;
	text-align:center;

	background: url(../images/event_descriptions/eventdescription_8.png);
	background-size: 430px 220px;
	background-position:50% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.type_multiview_options_9 {
	z-index: 0;
	height:222px !important;
	width:432px;

	border-spacing: 0px;
	border:hidden;
	position:absolute;

	margin-top: -28px;
	margin-left: 0px;

	vertical-align:top;
	text-align:center;

	background: url(../images/event_descriptions/eventdescription_9.png);
	background-size: 430px 220px;
	background-position:50% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.type_multiview_options_10 {
	z-index: 0;
	height:222px !important;
	width:432px;

	border-spacing: 0px;
	border:hidden;
	position:absolute;

	margin-top: -28px;
	margin-left: 0px;

	vertical-align:top;
	text-align:center;

	background: url(../images/event_descriptions/eventdescription_10.png);
	background-size: 430px 220px;
	background-position:50% 45%;
	background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 


/************************************/
/************************************/
/*

*/
.select_container_td {
	height: 108px;
	width: 950px !important;
	
	position:relative;
	
	background-color:rgba(255,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border-left: hidden;
	border-right: hidden;
	border-bottom: 1px solid #CCC;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_container_td1 {
	height: 108px;
	width: 248px !important;
	background-color:rgba(255,255,255, 0.3);
	margin-top: 0px;
	margin-left: 2px;
	vertical-align:middle;
	text-align:left;
	border-top: hidden;
	border-left: hidden;
	border-right: hidden;
	border-bottom: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_container_td2 {
	height: 108px;
	width: 460px !important;
	line-height: 24px;
	background-color:rgba(255,255,47, 0.3);
	margin-top: 0px;
	margin-left: 2px;
	vertical-align:middle;
	text-align:left;
	border-top: hidden;
	border-left: hidden;
	border-right: hidden;
	border-bottom: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_container_td3 {
	height: 108px;
	width: 250px !important;
	background-color:rgba(255,255,255, 0.3);
	margin-top: 0px;
	margin-left: 2px;
	vertical-align:middle;
	text-align:left;
	border-top: hidden;
	border-left: hidden;
	border-right: hidden;
	border-bottom: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.select_frame_absolute {
	height:115px !important;
    width:184px;
    /*
    search_result_frame_absolute
	width:184px;
    */
    display: inline-block;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	margin-top:7px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_frame_guideline_absolute {
    height:115px !important;
    width:184px;
    /*
    search_result_frame_absolute
	width:184px;
    */
    display: inline-block;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	margin-top:7px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

/*
guideline_select_container_td
select_menu_item01
select_options_object1
feed_container_top_bar_td1
*/
/************************************/
/*
select_brand_menu_item
*/
.select_options_absolute {
	height:114px !important;
	width:174px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.1);
	display: inline-block;
	margin-top:2px;
	margin-left:2px;
	vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_default_options1{
	height:113px !important;
	width:121px;
    display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.0);
	background-size: 117px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
    vertical-align:top;
	text-align:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*


*/
.select_letter_options1{
	height:113px !important;
	width:121px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.0);
	background-size: 120px 45.5px;
	background-position: center;
	background-repeat: no-repeat;
	/*
	
	background-position:50% 45%;
	*/
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_brand_options1{
	height:113px !important;
	width:121px;
	/*
	position: absolute;
	*/
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.0);
	background-size: 112px 112px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_option_2 {
	/*
		
	*/
	height:50px !important;
	width:55px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:3px;
	
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    background-size: 53px 48px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 1.50em  !important;
    font-weight: 700;
	text-indent: 0px;
}
.select_option_3{
	z-index: 10;
	height:35px !important;
	width:160px;
	line-height: 35px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:42px;
	margin-left:10px;
	vertical-align:top;
    
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.25em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
   
}
.select_action_right {
	z-index: 10;
	height:45px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:102px;
	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 65px 25px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_action_left {
	z-index: 10;
	height:45px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:80px;
	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 35px 30px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_action_right_active {
	z-index: 10;
	height:45px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:102px;
	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(3,255,3, 0.7);
	background-size: 65px 25px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_action_left_active {
	z-index: 10;
	height:45px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:80px;
	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(255,57,101, 0.7);
	background-size: 35px 30px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_rec_active {
	z-index: 10;
	height:45px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:10px;
	margin-left:20px;
	vertical-align:top;
	text-align:left;background-color:rgba(3,210,3, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/************************************/
.guideline_select_container_td {
	height: 108px;
	width: 950px !important;
	
	position:relative;
	
	background-color:rgba(255,255,255, 0.3);

	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border-left: hidden;
	border-right: hidden;
	border-bottom: 1px solid #CCC;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.guideline_select_container_td1 {
	height: 108px;
	width: 248px !important;
	background-color:rgba(255,255,255, 0.3);
	margin-top: 0px;
	margin-left: 2px;
	vertical-align:middle;
	text-align:left;
	border-top: hidden;
	border-left: hidden;
	border-right: hidden;
	border-bottom: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.guideline_select_container_td2 {
	height: 108px;
	width: 460px !important;
	line-height: 24px;
	background-color:rgba(255,255,47, 0.3);
	margin-top: 0px;
	margin-left: 2px;
	vertical-align:middle;
	text-align:left;
	border-top: hidden;
	border-left: hidden;
	border-right: hidden;
	border-bottom: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.guideline_select_container_td3 {
	height: 108px;
	width: 250px !important;
	background-color:rgba(255,255,255, 0.3);
	margin-top: 0px;
	margin-left: 2px;
	vertical-align:middle;
	text-align:left;
	border-top: hidden;
	border-left: hidden;
	border-right: hidden;
	border-bottom: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.guideling_select_data_label { 
	z-index: 10;
	height:108px !important;
	width:460px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#000;
   
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.guideling_select_type_label { 
	z-index: 10;
	height: 108px;
	width: 250px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	font-size: 0.70em;
    color:#000;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_results_td {
    /*
    select_frame_guideline_absolute
    event_guideline_list_profile1
    */
	height: 375px !important;
	width: 950px !important;
    margin-top: 0px;
    position:absolute;
    border: hidden;
	background-color:rgba(255,255,255, 0.3);
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/************************************/
/*
    social_select_command_td1
*/
.social_select_container_td {
	height: 100px;
	width: 950px !important;
	position:relative;
	margin-top: 0px;
	border-spacing: 0px;
	border: hidden;
    background-color:rgba(255,255,255, 0.7);
    vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_select_container_td1 {
	height: 100px;
	width: 248px !important;
	margin-top: 0px;
	margin-left: 2px;
    border-spacing: 0px;
	border: hidden;
    background-color:rgba(255,255,255, 0.7);
    vertical-align:middle;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_select_container_td2 {
	height: 108px;
	width: 580px !important;
	line-height: 12px;
	margin-top: 0px;
	margin-left: 2px;
    border-spacing: 0px;
	border: hidden;
    background-color:rgba(255,255,255, 0.7);
    vertical-align:top;
    text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_select_command_td1 {
    height:55px !important;
    width:240px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background:rgba(255,255,255, 0.7);
    vertical-align:left;
    text-align:top;
}
.social_select_command_td2 {
    height:55px !important;
    width:97px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background:rgba(255,255,255, 0.7);
    vertical-align:left;
    text-align:top;
}
.social_select_command_td3 {
    height:55px !important;
    width:135px !important;
    line-height:12px !important;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
    background:rgba(255,255,255, 0.7);
    vertical-align:left;
    text-align:top;
}
.social_select_container_td3 {
	height: 100px;
	width: 250px !important;
	margin-top: 0px;
	margin-left: 2px;
	vertical-align:top;
	text-align:left;
    border-spacing: 0px;
	border: hidden;
    background-color:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.social_select_data_label { 
	z-index: 10;
	height:108px !important;
	width:580px;
	margin-top:0px;
	margin-left:0px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#000;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.social_select_type_label { 
	z-index: 10;
	height: 108px;
	width: 250px !important;
	margin-top:0px;
	margin-left:0px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.7);
	vertical-align:middle;
	text-align:left;
	font-size: 0.70em;
    color:#000;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.social_select_index_title{
	z-index: 10;
	height:35px !important;
	width:120px;
	line-height: 12px;
	position: absolute;
	display: inline-block;
	margin-top:42px;
	margin-left:10px;
    border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.0);
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.15em  !important;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
	text-indent: 0px;
   
}
.social_select_disable {
    height:50px !important;
    width:94px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 2px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.95em  !important;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
	text-indent: 0px;
}

.social_select_clear {
    height:50px !important;
    width:94px !important;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 2px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.95em  !important;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
	text-indent: 0px;
}

.social_select_connect {
    height:50px !important;
    width:130px !important;
    line-height:12px !important;
    margin-top: 0px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    background: url(../images/buttons/clear_img.png) rgba(11,183,255, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.95em  !important;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
	text-indent: 0px;
}
.social_select_activate {
    height:50px !important;
    width:130px !important;
    line-height:12px !important;
    margin-top: 0px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    background: url(../images/buttons/clear_img.png) rgba(11,183,255, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.95em  !important;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
	text-indent: 0px;
}
.social_select_activated {
    height:50px !important;
    width:130px !important;
    line-height:12px !important;
    margin-top: 0px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    background: url(../images/buttons/clear_img.png) rgba(10, 245, 92, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.95em  !important;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
	text-indent: 0px;
}

.social_select_import {
    height:50px !important;
    width:237px !important;
    line-height:12px !important;
    margin-top: 0px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.95em  !important;
    font-weight: 600;
    vertical-align:middle;
    text-align:left;
	text-indent: 10px;
}
.social_select_import_count {
    height:40px !important;
    width:80px !important;
    line-height:12px !important;
    position:absolute;
    margin-top: 4px;
	margin-left: 150px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.85em  !important;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
	text-indent: 0px;
}

.social_select_metrics {
    height:95px !important;
    width:250px !important;
    line-height:12px !important;
    margin-top: 5px;
	margin-left: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    background: url(../images/buttons/clear_img.png) rgba(204,204,204, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    social_select_container_td3
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.95em  !important;
    font-weight: 600;
    vertical-align:middle;
    text-align:left;
	text-indent: 10px;
}
.social_select_metrics_label {
    height:50px !important;
    width:230px !important;
    line-height:12px !important;
    position:absolute;
    margin-top: 40px;
	margin-left: 10px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
    background-position:center;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 0.85em  !important;
    font-weight: 600;
    vertical-align:middle;
    text-align:center;
	text-indent: 0px;
}
/************************************/
.social_metrics_canvas1 {
    height:530px !important;
    width:474px !important;
    line-height:12px !important;
    margin-top: 0px;
	margin-left: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-align:left;
}
/************************************/
.social_metrics_canvas2 {
    height:530px !important;
    width:474px !important;
    line-height:12px !important;
    margin-top: 0px;
	margin-left: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    border-spacing: 0px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
    text-align:left;
}
/************************************/

.swipe_select_window_back{
	z-index: 8;
	height:340px !important;
	width:10px !important;

	position: absolute;
	top: 9px;
	/*
	margin-top: 100;
	*/
	margin-left: 0px; 

	border:1px solid #CCC;
	background-color:lightgray;
	padding:0;

	vertical-align:text-top !important;
}
.swipe_select_window_forward{
	z-index: 8;
	height:340px !important;
	width:10px !important;

	position: absolute;
	top: 9px;
	/*
	margin-top: 100;
	*/
	margin-left: 938px; 

	border:1px solid #CCC;
	background-color:lightgray;
	padding:0;

	vertical-align:text-top !important;
}
/************************************/
.swipe_select_forward_button_active {
	z-index: 11;
	height:340px !important;
	width:90px !important;

	display: inline-block;
	line-height: 12px;

	position: absolute;
	top: 0px;
	left:-75px;

	border-spacing: 0px;
	border:1px solid #CCC;
	/*
	margin-left: 70;
	*/

	color: white;
	font-size: .40em  !important;
	font-weight: 500;
	text-align:center;
	vertical-align:bottom;
	text-decoration:none;

	background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
	background-size: 30px 380px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.swipe_select_forward_button_disabled {
	z-index: 11;
	height:340px !important;
	width:90px !important;

	display: inline-block;
	line-height: 12px;

	position: absolute;
	top: 0px;
	left:-78px;

	border-spacing: 0px;
	border:1px solid #CCC;
	/*
	margin-left: -70;
	*/

	color: white;
	font-size: .40em  !important;
	font-weight: 500;
	text-align:center;
	vertical-align:bottom;
	text-decoration:none;

	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 380px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.swipe_select_back_button_active {
	z-index: 11;
	height:340px !important;
	width:90px !important;

	display: inline-block;
	line-height: 12px;

	position: absolute;
	top: 0px;
	left:0px;

	border-spacing: 0px;
	border:1px solid #CCC;

	color: white;
	font-size: .40em  !important;
	font-weight: 500;
	text-align:center;
	vertical-align:bottom;
	text-decoration:none;

	background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
	background-size: 30px 380px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.swipe_select_back_button_disabled {
	z-index: 11;
	height:340px !important;
	width:90px !important;

	display: inline-block;
	line-height: 12px;

	position: absolute;
	top: 0px;
	left:0px;

	border-spacing: 0px;
	border:1px solid #CCC;

	color: white;
	font-size: .40em  !important;
	font-weight: 500;
	text-align:center;
	vertical-align:bottom;
	text-decoration:none;

	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 380px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/************************************/
	.swipe_view_div_report{
		z-index: 10;
		height:80px !important;
		width:80px !important;
		line-height: 80;
		vertical-align: middle;
		text-align: center;
		
		position: absolute;
	    margin-top: 10;
		margin-left: 10; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.swipe_view_report_enabled{
		z-index: 0;
	  	height:80px !important;
		width:80px !important;
		
		display: inline-block;
		line-height: 12px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(0, 255, 0, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.swipe_view_div_exit{
		z-index: 5;
		height:45px !important;
		width:100px !important;
		
		position: absolute;
	    margin-top: 1;
		margin-left: 5; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
/*
	

*/	
	.swipe_view_div_option_menu{
		z-index: 998;
		height:125px !important;
		width:45px !important;
		line-height: 125;
		position: absolute;
		/*
		display: inline-block;
		
		
		*/
		left: 898px;
		top: 480px;
		
		border:hidden;
		background-color:rgba(239,239,239, 0.6);
		padding:0;
	}
	.swipe_view_div_option1{
		z-index: 1;
		height:35px !important;
		width:35px !important;
		line-height: 35;
		
		vertical-align: text-top;
		text-align: left;
		
		
		position: absolute;
		top: 5px;
		left: 4px;
		/*
	    margin-top: 1;
		margin-left: 120; 
		
		left: 890px;
		top: 490px;
		*/
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.swipe_view_div_option2{
		z-index: 1;
		height:35px !important;
		width:35px !important;
		line-height: 35;
		
		vertical-align: text-top;
		text-align: left;
		
		
		position: absolute;
		top: 45px;
		left: 4px;
		/*
	    margin-top: 1;
		margin-left: 120; 
		
		left: 890px;
		top: 530px;
		*/
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	
	.swipe_view_div_option3{
		z-index: 1;
		height:35px !important;
		width:35px !important;
		line-height: 35;
		
		vertical-align: text-top;
		text-align: left;
		
		
		position: absolute;
		top: 85px;
		left: 4px;
		/*
	    margin-top: 1;
		margin-left: 120; 
		
		left: 890px;
		top: 570px;
		*/
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	/*
swipe_image_view_contol_single

	.swipe_view_div_option4{
		z-index: 1;
		height:35px !important;
		width:35px !important;
		line-height: 35;
		
		vertical-align: text-top;
		text-align: left;
		
		
		position: absolute;
		top: 2px;
		left: 2px;
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	*/
	.swipe_image_view_contol_multi{
		z-index: 0;
	  	height:35px !important;
		width:35px !important;
		
		display: inline-block;
		position: absolute;
		left: 0px;
		top: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		vertical-align:text-top;
		
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.6);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_image_view_contol_multi_active{
		z-index: 0;
	  	height:35px !important;
		width:35px !important;
		
		display: inline-block;
		position: absolute;
		left: 0px;
		top: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		vertical-align:text-top;
		
		background: url(../images/buttons/butt_template.png) rgba(239,45,239, 0.6);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
 	.swipe_image_view_contol_stat{
		z-index: 0;
	  	height:35px !important;
		width:35px !important;
		
		display: inline-block;
		position: absolute;
		left: 0px;
		top: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		vertical-align:text-top;
		
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.6);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_image_view_contol_stat_active{
		z-index: 0;
	  	height:35px !important;
		width:35px !important;
		
		display: inline-block;
		position: absolute;
		left: 0px;
		top: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		vertical-align:text-top;
		
		background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.6);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_image_view_contol_single{
		z-index: 0;
	  	height:35px !important;
		width:35px !important;
		
		display: inline-block;
		position: absolute;
		left: 0px;
		top: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.6);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_image_view_contol_single_active{
		z-index: 0;
	  	height:35px !important;
		width:35px !important;
		
		display: inline-block;
		position: absolute;
		left: 0px;
		top: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background: url(../images/buttons/butt_template.png) rgba(239,239,87, 0.6);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_image_view_contol_map{
		z-index: 0;
	  	height:35px !important;
		width:35px !important;
		
		display: inline-block;
		position: absolute;
		left: 0px;
		top: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.6);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_image_view_contol_map_active{
		z-index: 0;
	  	height:35px !important;
		width:35px !important;
		
		display: inline-block;
		position: absolute;
		left: 0px;
		top: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.6);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*
swipe_view_div_option4
	.swipe_view_div_view_option_menu{
		z-index: 998;
		height:40px !important;
		width:40px !important;
		line-height: 40;
		position: absolute;
		
		left: 90px;
		top: 80px;
		
		border:hidden;
		background-color:rgba(239,239,239, 0.6);
		padding:0;
	}
	*/
	/*
	


	*/
.event_swipe_messagedisplayevent_view_options_settings {
	z-index: 5;
	height:28px;
	width:28px;
	display:inline-block;
	/**/
	position:absolute;

	vertical-align:top;

	margin-top: 3px;
	margin-left: 333px;

	border:1px solid #ccc;
	background: url(../images/buttons/sys_settings_button_large.png) rgba(255,255,255, 0.7);
	background-size: 23px 23px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;

}


	.swipe_child_messagedisplayguideline_status_pending{
		z-index: 12;
	  	height:30px !important;
		width:30px !important;
		
		display: inline-block;
		line-height: 20px;
		position:absolute;
		
		border-spacing: 0px;
		border:3px solid #CCC;
		
		margin-top: -31px;
		margin-left: 2px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 1.50em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
		background-size: 20px 20px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.swipe_child_messagedisplayguideline_status_active{
		z-index: 12;
	  	height:30px !important;
		width:30px !important;
		
		display: inline-block;
		line-height: 20px;
		position:absolute;
		
		border-spacing: 0px;
		border:3px solid #CCC;
		
		margin-top: -31px;
		margin-left: 2px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 1.50em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(0,128,0, 0.7);
		background-size: 20px 20px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.swipe_child_messagedisplayguideline_status_completed{
		z-index: 12;
	  	height:30px !important;
		width:30px !important;
		
		display: inline-block;
		line-height: 20px;
		position:absolute;
		
		border-spacing: 0px;
		border:3px solid #CCC;
		
		margin-top: -31px;
		margin-left: 2px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 1.50em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
		background-size: 20px 20px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	.swipe_child_messagedisplayguideline_status_error{
		z-index: 12;
	  	height:30px !important;
		width:30px !important;
		
		display: inline-block;
		line-height: 30px;
		position:absolute;
		
		border-spacing: 0px;
		border:3px solid #CCC;
		
		margin-top: -31px;
		margin-left: 2px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 1.50em  !important;
		font-weight: 700;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		
		background: url(../images/buttons/butt_template.png) rgba(0,0,255, 0.7);
		background-size: 20px 20px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
/*
position:absolute;
*/
	.swipe_child_messagedisplayguideline_pagination_count{
		z-index: 12;
	  	height:40px !important;
		width:80px !important;
		
		display: inline-block;
		line-height: 20px;
		
		
		border-spacing: 0px;
		border:3px solid #CCC;
		
		margin-top: -60px;
		margin-left: 2px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .70em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		
		background: url(../images/buttons/clear_img.png) rgba(255, 255, 128, 0.7);
		background-size: 80px 20px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	  }
	/*
	
	swipe_view_div_view_option_menu
display: inline-block;
ls_form_media_listview_frame


	*/
	.swipe_image_multi_image_absolute {
		height:183px !important;
		width:204px !important;
		border-spacing: 0px;
		border:hidden;
		
		position: relative;
		
		display:inline-table;
		font-size: 0 !important;
		
		padding: 0px !important;
		
		
		margin-top:9px;
		margin-left:3px !important;
		/*
		margin-bottom:1px;
		margin-right: -12px;
		*/
		vertical-align:top;
		text-align:left;
		
		background: url(../images/buttons/clear_img.png) rgba(255,255,244, 0.1);
		background-size: 180px 143px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*

swipe_view_back_button_active

*/
	.swipe_image_multi_image_frame{
		z-index: 3;
	  	height:183px !important;
		width:204px !important;
		position: absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		vertical-align:top;
		text-align:left;
		
		padding: 0px !important;
		
		background: url(../images/buttons/clear_img.png) rgba(255,255,244, 0.1);
		background-size: 202px 149px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
/*
-40
*/
	.swipe_messagedisplaylistview_like_disabled{
		z-index: 15;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 98px;
		margin-left: 131px;
		margin-right: -2px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
/*

*/
	.swipe_messagedisplaylistview_like_enabled{
		z-index: 15;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 98px;
		margin-left: 131px;
		margin-right: -2px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
/*

*/
	.swipe_messagedisplaylistview_dislike_disabled{
		z-index: 15;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 98px;
		margin-left: 80px;
		margin-right: -2px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
/*

*/
	.swipe_messagedisplaylistview_dislike_enabled{
		z-index: 15;
	  	height:40px !important;
		width:50px !important;
		
		display: inline-block;
		line-height: 12px;
		
		position:absolute;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 98px;
		margin-left: 80px;
		margin-right: -2px;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
		background-size: 40px 35px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }



/*

*/
	/*
		close

	background-image:url(../images/buttons/sys_close_buttonDefault.png) ;
	*/
	.swipe_view_exit_button_active {
		z-index: 0;
	  	height:45px !important;
		width:100px !important;
		
		display: inline-block;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background: url(../images/buttons/sys_close_buttonDefault.png);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.swipe_count_data_div{
		z-index: 10;
		height:25px !important;
		width:140px !important;
		
		
		position: absolute;
		
		margin-top: -450;
		/*
	    margin-top: 10;
		*/
		margin-left: 680; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	/*
	swipe_view_div_option

	*/
	.swipe_view_div_count{
		z-index: 5;
		height:35px !important;
		width:160px !important;
		
		position: absolute;
		/*
	    margin-top: 1;
		margin-left: 780; 
		*/
		top: 20px;
		left: 780px;
		
		vertical-align: text-top;
		text-align:left;
		
		border:1px solid #CCC;
		background-color:rgba(255,255,255, 0.3);
		padding:0;
	}
	.swipe_count_form_field{
	  	height:35px !important;
		/*width: 195px;*/
		width: 100%;
		line-height:30px !important;
		
		position: absolute;
		left: 0px;
		top: 0px;
		
		background-color:rgba(255,255,255, 0.6);
		border-spacing: 0px;
		border:hidden;
		
		color: crimson  !important;
		font-size: 1.00em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle !important;
		text-decoration:none;
	  }


/********//*	SWIPE_ITEM_PAGINATION	*/

	

/*
margin-top: 0;

*/
	
/*
	

display: inline-block;
media_menu_pagination_date_option
swipe_child_messagedisplayguideline_status_pending
*/
	.media_menu_pagination_month_option{
		z-index: 10;
		height:30px !important;
		width:70px !important;
		line-height: 30px !important;
		
		
		position: absolute;
		
		margin-top: -65px !important;
		margin-left: 5px !important;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 1.60em  !important;
		font-weight: 700;
		text-align:center !important;
		vertical-align:text-top;
		text-decoration:none;
		
		border:hidden;
		
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
		background-size: 30px 30px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.media_menu_pagination_date_option{
		z-index: 10;
		height:60px !important;
		width:60px !important;
		line-height: 70px !important;
		
		display: inline-block;
		position:absolute;
		
		text-align:center;
		vertical-align:text-top !important;
		
		margin-top: -68px;
		margin-left: 12px; 
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 1.40em  !important;
		font-weight: 700;
		
		text-decoration:none;
		
		border:hidden;
		
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
		background-size: 40px 10px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.media_menu_pagination_option_disabled{
		z-index: 10;
		height:74px !important;
		width:80px !important;
		line-height: 74;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position:relative;
		
		margin-top: 2;
		margin-left: 1; 
		border:1px solid #CCC;
		background: url(../images/buttons/clear_img.png) rgba(255,255,244, 0.1);
		background-size: 76px 57px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
/*
	

*/
	
	
/*
	
*/
	.media_menu_pagination_option_selected{
		z-index: 999999;
		height:74px !important;
		width:80px !important;
		line-height: 74px !important;
		vertical-align: middle;
		
		display: inline-block;
		position:relative;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 1.10em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		margin-top: 2;
		margin-left: 1; 
		
		border:3px solid #51E343;
		background: url(../images/buttons/clear_img.png) rgba(50,153,50, 0.1);
		background-size: 76px 57px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*

media_menu_pagination_back_active
*/
	.media_menu_pagination_option_active{
		z-index: 999999;
		height:74px !important;
		width:80px !important;
		line-height: 74px !important;
		
		display: inline-block;
		position:relative;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: 1.80em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:text-top  !important;
		text-decoration:none;
		
		
		margin-left: 1; 
		margin-top: -25px;
		
		border:1px solid #CCC;
		background: url(../images/buttons/clear_img.png) rgba(255,255,244, 0.1);
		background-size: 76px 57px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	
	/*
		swipe_menu_pagination_option_div1
swipe_view_window_pagination_menu
position: absolute;


	*/
	.swipe_menu_pagination_div{
		z-index: 9999;
		height:80px !important;
		width:645px !important;
		line-height: 80px;
		
		vertical-align:text-top;
		text-align:left;
		
		position: absolute;
		
		/*
	    margin-top: 10;
		110
		margin-top: -90 !important;
		margin-left: 50 !important; 
		*/
		top: -5px;
		left: 153px;
		
		border:hidden;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
/*
swipe_child_messagedisplayguideline_pagination_count
*/
	.swipe_multi_menu_pagination_div{
		z-index: 8;
		height:80px !important;
		width:650px !important;
		line-height: 80;
		vertical-align: middle;
		text-align:left;
		
		position: absolute;
		
		margin-top: -90;
		/*
	    margin-top: 10;
		*/
		margin-left: 153; 
		
		border:hidden;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.swipe_menu_pagination_ajax{
		z-index: 10;
		height:80px !important;
		width:645px !important;
		line-height: 80;
		vertical-align:middle;
		text-align:center;
		
		position: absolute;
	    margin-top: 0;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}

	.swipe_menu_pagination_back_disabled{
		z-index: 10;
		height:74px !important;
		width:37px !important;
		line-height: 74;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		
	    margin-top: 2;
		margin-left: 1; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
		background-size: 20px 60px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_menu_pagination_back_active{
		z-index: 10;
		height:74px !important;
		width:37px !important;
		line-height: 74;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		
	    margin-top: 2;
		margin-left: 1; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
		background-size: 20px 60px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_menu_pagination_forward_disabled{
		z-index: 10;
		height:74px !important;
		width:37px !important;
		line-height: 74;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		
	    margin-top: 2;
		margin-left: 1; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
		background-size: 20px 60px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_menu_pagination_forward_active{
		z-index: 10;
		height:74px !important;
		width:37px !important;
		line-height: 74;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		
	    margin-top: 2;
		margin-left: 1; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
		background-size: 20px 60px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*


*/
	.swipe_menu_pagination_option_div1 .swipe_menu_pagination_option_div11 .swipe_menu_pagination_option_div9  .swipe_menu_pagination_option_div10{
		z-index: 99999;
		height:78px !important;
		width:40px !important;
		line-height: 78px;
		
		vertical-align:text-top;
		text-align: center;
		display:inline-block;
		top: 0px;
		/*
	    margin-top: 0;
		margin-left: 2; 
		*/
		
	}
	/*
vertical-align: middle;
		text-align: center;


media_pagination_options_options3active

	*/
	 .swipe_menu_pagination_option_div2 .swipe_menu_pagination_option_div3 .swipe_menu_pagination_option_div4 .swipe_menu_pagination_option_div5 .swipe_menu_pagination_option_div6 .swipe_menu_pagination_option_div7 .swipe_menu_pagination_option_div8 {
		z-index: 99999;
		height:78px !important;
		width:84px !important;
		line-height: 78px;
		
		display:inline-block;
		background-color: azure;
		
		border:1px solid #CCC;
		 
		vertical-align:text-top;
		
	    margin-top: 0;
		margin-left: 0; 
		
	}
/*

vertical-align: middle;
		text-align: center;
height:74px !important;
media_menu_pagination_option_active
*/
	.swipe_menu_pagination_option_active{
		z-index: 10;
		height:74px !important;
		width:80px !important;
		line-height: 74px;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position:relative;
		/*
		top:-20px;
		*/
		margin-top: 2;
		margin-left: 1; 
		
		
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(0,63,244, 0.7);
		background-size: 76px 57px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_menu_pagination_option_disabled{
		z-index: 10;
		height:74px !important;
		width:80px !important;
		line-height: 74px;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position:relative;
		
		margin-top: 2;
		margin-left: 1; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
		background-size: 76px 57px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_menu_pagination_option_selected{
		z-index: 10;
		height:74px !important;
		width:80px !important;
		line-height: 74px;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position:relative;
		
		margin-top: 2;
		margin-left: 1; 
		
		border:3px solid #51E343;
		background: url(../images/buttons/butt_template.png) rgba(0,63,244, 0.7);
		background-size: 76px 57px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.swipe_count_data_main_alter_dislike{
	  	height:25px !important;
		/*width: 195px;*/
		width: 100%;
		line-height:20px !important;
		background-color:rgba(255, 153, 0, 0.7);
		border-spacing: 0px;
		border:hidden;
		
		color: rgba(15, 5, 26, 0.6)  !important;
		font-size: .75em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle !important;
		text-decoration:none;
	  }
	.swipe_count_data_main_alter_like{
	  	height:25px !important;
		/*width: 195px;*/
		width: 100%;
		line-height:20px !important;
		background-color:rgba(100, 149, 237, 0.7);
		border-spacing: 0px;
		border:hidden;
		
		color: rgba(15, 5, 26, 0.6)  !important;
		font-size: .75em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle !important;
		text-decoration:none;
	  }
/*
line-height: 75;
		

	*/
	.swipe_menu_like_div_frame{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		
		
		position: absolute;
		
		margin-top: 400;
		/*
	    margin-top: 10;
		*/
		margin-left: 90; 
		
		border:hidden;
		background-color:transparent;
		padding:0;
	}
/*
line-height: 75;
		vertical-align: middle;
		text-align:left;

*/
	.swipe_menu_like_div{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		
		
		position: absolute;
		
		margin-top: -115;
		/*
	    margin-top: 10;
		*/
		margin-left: -17; 
		
		border:hidden;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	/*
position: absolute;
line-height: 75;
		vertical-align: middle;
		text-align:center;
	*/
	.swipe_menu_like_div_control{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		
		
		
		
		margin-top: 0;
		/*
	    margin-top: 10;
		*/
		margin-left: 0; 
		
		border:hidden;
		background-color:rgba(239,45,25, 0.3);
		padding:0;
	}

	.swipe_like_view_active{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		line-height: 20px !important;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		color:aqua;
		font-size: .85em  !important;
		font-weight: 600;
		
	    margin-top: 0;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(10,63,58, 0.7);
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.swipe_dislike_view_active{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		line-height: 20px !important;
		
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		color:aqua;
		font-size: .85em  !important;
		font-weight: 600;
		
	    margin-top: 0;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(142,63,189, 0.7);
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	
	/* -----  */
	.swipe_menu_stat_div_frame{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		line-height: 75;
		vertical-align: middle;
		text-align:left;
		
		position: absolute;
		
		margin-top: 70;
		/*
	    margin-top: 10;
		*/
		margin-left: 90; 
		
		border:hidden;
		background-color:rgba(239,239,58, 0.7);
		padding:0;
	}
/*
line-height: 75;
		vertical-align:text-top;
		text-align:left;
		
*/
	.swipe_menu_stat_div{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		
		
		position: absolute;
		
		margin-top: -445;
		/*
	    margin-top: 10;
		*/
		margin-left: -17; 
		
		border:hidden;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	/*
position: absolute;
line-height: 75;
		vertical-align: middle;
		
		
	*/
	.swipe_menu_stat_div_control{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		
		
		
		margin-top: 0;
		/*
	    margin-top: 10;
		*/
		margin-left: 0; 
		
		border:hidden;
		background-color:rgba(239,45,25, 0.3);
		padding:0;
	}
/************************//************************//************************/
	/************************//*	SWIPE_IMAGE_VIEW_WINDOW	*/
	/********//*	SWIPE_IMAGE_VIEW_MAIN	*/

	.swipe_media_option_main_table{
		height:619px !important;
		width:952px !important;
		line-height:478px;
		border:1px solid #CCC; 
		padding:0;
		margin:0;
		vertical-align:text-top;
	}

	.swipe_media_option_main_table_td{
		height:606px !important;
		width:952px !important;

		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:right;
		vertical-align:middle !important;
	}
	.swipe_media_view_window{
		height:606px !important;
		width:952px !important;

		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:top !important;
	}
	.swipe_media_panel_window{
		z-index: 0;
		height:569px !important;
		width:920px !important;

		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-left: 0px;
		margin-top: 25px;
		
		color: white;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		background-color: white;
		
		/*
		background: url(../images/buttons/butt_template.png) rgba(255,255,8, 0.4);
		
		background-size: 400px 400px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		*/
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.swipe_media_view_window_menu_top{
		z-index: 10;
		height:60px !important;
		width:950px !important;
		
		position: absolute;
	    margin-top: 0;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background-color:rgba(255,255,255, 0.4);
		padding:0;
		
		vertical-align:middle !important;
	}
	.swipe_media_view_window_menu_bottom{
		z-index: 8;
		height:100px !important;
		width:950px !important;
		
		position: absolute;
	    margin-top: 506;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background-color:rgba(0,63,76, 0.7);
		padding:0;
		
		vertical-align:middle !important;
	}

	.swipe_media_view_window_back{
		z-index: 8;
		height:400px !important;
		width:20px !important;
		
		position: absolute;
	    margin-top: 100;
		margin-left: 0px; 
	
		border:1px solid #CCC;
		background-color:lightgray;
		padding:0;
		
		vertical-align:middle !important;
	}
	.swipe_media_view_window_forward{
		z-index: 8;
		height:400px !important;
		width:20px !important;
	
		position: absolute;
		margin-top: 100;
		margin-left: 929px; 
		
		border:1px solid #CCC;
		background-color:lightgray;
		padding:0;
		
		vertical-align:middle !important;
	}


	.swipe_media_view_div_like{
		z-index: 10;
		height:80px !important;
		width:80px !important;
		line-height: 80;
		vertical-align: middle;
		text-align: center;
		
		position: absolute;
	    margin-top: 510;
		margin-left: 865; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.swipe_media_view_div_dislike{
		z-index: 10;
		height:80px !important;
		width:80px !important;
		line-height: 80;
		vertical-align: middle;
		text-align: center;
		
		position: absolute;
		
	    margin-top: 510;
		margin-left: 775; 
		
		
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.swipe_media_view_div_report{
		z-index: 8;
		height:80px !important;
		width:80px !important;
		line-height: 80;
		vertical-align: middle;
		text-align: center;
		
		position: absolute;
	    margin-top: 510;
		margin-left: 10; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.swipe_media_upload{
		z-index: 9;
		height:80px !important;
		width:80px !important;
		line-height: 80;
		vertical-align: middle;
		text-align: center;
		/*
		margin-left: 835; swipe_media_panel_add required
		*/
		position: absolute;
	    margin-top: 10;
		margin-left: 10;
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.swipe_media_view_div_count{
		z-index: 8;
		height:25px !important;
		width:140px !important;
		
		
		position: absolute;
		
		margin-top: -450;
		/*
	    margin-top: 10;
		*/
		margin-left: 680; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}

/************************//************************//************************/
	/************************//*	MEDIA_VIEW_WINDOW	*/
	/********//*	MEDIA_VIEW_MAIN	*/

.event_media_action_frame_div {
	z-index:5;
	height: 130px;
	width: 130px !important;
    display:inline-block;
	position:absolute;
	margin-top: 425px;
	margin-left: 5px;
    border-radius: 100px;
    border-spacing: 0px;
    border: 2px solid rgba(4, 144, 242, 0.7);
	background-color:rgba(4, 144, 242, 0.3);
	font-size: 1em;
	font-weight: 600;
    vertical-align:top;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_media_upload_frame_div {
	z-index:5;
	display:inline-block;
	height: 125px;
	width: 125px !important;
    line-height:125px;
	margin-top: 1px;
	margin-left: 1px;
    border-radius: 100px;
    border:hidden;
    /*
	background-color:rgba(255,255,255, 0.0);
    */
    background: url(../images/bkgrounds/upload_background.png) rgba(255,255,255, 0.0);
    background-size: 100px 100px;
	background-repeat: repeat;
	background-position:center;
	font-size: 1em;
	font-weight: 600;
	vertical-align:top;
	text-align:left;
	text-decoration:none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_media_upload_button {
    z-index:5;
	height: 124px;
	width: 125px !important;
	display:inline-block;
    margin-top: 0px;
	margin-left: 0px;
	border-radius: 100px;
    border:hidden;
	background-color:rgba(255,255,255, 0.0);
	background: url(../images/buttons/sys_upload_graphic.png) rgba(255,255,255, 0.0);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.media_view_map {
	
	width: 948px !important;
	
	/*
	z-index: 1;
	height:586px !important;
	left: 81px;
	top: 19px;
	display: none;
	*/
}
/*
	swipe_image_view_contol_multi_active
select_date_map_view
media_view_map_swipe
swipe_map_view_window

*/
.media_view_stat_swipe {	
    z-index: 1;
    height:586px !important;
    width: 790px !important;	

    position: absolute !important;
	
    margin-top: 0;
    margin-left: 0;
    left: 81px;
    top: 19px;

    background-color:rgba(255,255,255, 0.7);
}
.media_view_map_swipe {	
    z-index: 1;
    height:590px !important;
    width: 948px !important;	
	/**/
	display: none;
	
    position: absolute !important;

    margin-top: 0;
    margin-left: 0;
    left: 4px;
    top: -2px;
}
/*
media_menu_stat_div_frame
*/
	.media_overview_map {
		z-index: 200;
		height:170px !important;
	  	width: 400px !important;
		
		line-height: 125;
		vertical-align: middle;
		text-align:center;
		
		position: absolute;
		
		margin-top: 370;
		margin-left: 490;
		
		border:3px solid #CCC;
		background-color:rgba(255,255,255, 0.7);
		padding:0;
	}

	/*
	count_data_main_field
media_menu_like_div
	*/
	.media_count_data_div{
		z-index: 10;
		height:25px !important;
		width:140px !important;
		
		
		position: absolute;
		
		margin-top: -450;
		/*
	    margin-top: 10;
		*/
		margin-left: 680; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
/*
	.media_count_data_control{
		z-index: 10;
		height:35px !important;
		width:125px !important;
		
		margin-top: 0;
		
		margin-left: 0; 
		
		border:hidden;
		background-color:rgba(239,45,25, 0.3);
		padding:0;
	}
*/
	.media_count_data_control_td{
		height:25px !important;
		width:70px !important;
		border-spacing: 0px;
		border:hidden;
	}
	.count_data_main_field{
	  	height:25px !important;
		/*width: 195px;*/
		width: 100%;
		line-height:20px !important;
		background-color:rgba(239,239,239, 0.3);
		border-spacing: 0px;
		border:hidden;
		
		color: rgba(15, 5, 26, 0.6)  !important;
		font-size: .75em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle !important;
		text-decoration:none;
	  }
	
	.count_data_edit_field{
	  	height:25px !important;
		/*width: 195px;*/
		width: 100%;
		line-height:20px !important;
		background-color:rgba(239,239,239, 0.3);
		border-spacing: 0px;
		border:hidden;
		
		color: rgba(15, 5, 26, 0.6)  !important;
		font-size: .75em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle !important;
		text-decoration:none;
	  }
	.count_data_main_alter_dislike{
	  	height:25px !important;
		/*width: 195px;*/
		width: 100%;
		line-height:20px !important;
		background-color:rgba(255, 153, 0, 0.7);
		border-spacing: 0px;
		border:hidden;
		
		color: rgba(15, 5, 26, 0.6)  !important;
		font-size: .75em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle !important;
		text-decoration:none;
	  }
	.count_data_main_alter_like{
	  	height:25px !important;
		/*width: 195px;*/
		width: 100%;
		line-height:20px !important;
		background-color:rgba(100, 149, 237, 0.7);
		border-spacing: 0px;
		border:hidden;
		
		color: rgba(15, 5, 26, 0.6)  !important;
		font-size: .75em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle !important;
		text-decoration:none;
	  }
/*
line-height: 75;
		vertical-align: middle;
		text-align:left;

	*/
	.media_menu_like_div_frame{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		
		
		position: absolute;
		
		margin-top: 400;
		/*
	    margin-top: 10;
		*/
		margin-left: 90; 
		
		border:hidden;
		background-color:transparent;
		padding:0;
	}
/*
line-height: 75;
		vertical-align: middle;
		text-align:left;

*/
	.media_menu_like_div{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		
		
		position: absolute;
		
		margin-top: -115;
		/*
	    margin-top: 10;
		*/
		margin-left: -17; 
		
		border:hidden;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	/*
position: absolute;
line-height: 75;
		vertical-align: middle;
		text-align:center;
	*/
	.media_menu_like_div_control{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		
		
		
		
		margin-top: 0;
		/*
	    margin-top: 10;
		*/
		margin-left: 0; 
		
		border:hidden;
		background-color:rgba(239,45,25, 0.3);
		padding:0;
	}

	.media_like_view_active{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		line-height: 20px !important;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		color:aqua;
		font-size: .85em  !important;
		font-weight: 600;
		
	    margin-top: 0;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(10,63,58, 0.7);
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.media_dislike_view_active{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		line-height: 20px !important;
		
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		color:aqua;
		font-size: .85em  !important;
		font-weight: 600;
		
	    margin-top: 0;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(142,63,189, 0.7);
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

/* -----  */
	.media_menu_stat_div_frame{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		line-height: 75;
		vertical-align: middle;
		text-align:left;
		
		position: absolute;
		
		margin-top: 70;
		/*
	    margin-top: 10;
		*/
		margin-left: 90; 
		
		border:hidden;
		background-color:rgba(239,239,58, 0.7);
		padding:0;
	}
/*
line-height: 75;
		vertical-align:text-top;
		text-align:left;
		
*/
	.media_menu_stat_div{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		
		
		position: absolute;
		
		margin-top: -445;
		/*
	    margin-top: 10;
		*/
		margin-left: -17; 
		
		border:hidden;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	/*
position: absolute;
line-height: 75;
		vertical-align: middle;
		text-align:center;
		
	*/
	.media_menu_stat_div_control{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		
		
		
		margin-top: 0;
		/*
	    margin-top: 10;
		*/
		margin-left: 0; 
		
		border:hidden;
		background-color:rgba(239,45,25, 0.3);
		padding:0;
	}
/*
position: absolute;
margin-top: 0;

	.media_menu_stat_div_td{
		z-index: 10;
		height:45px !important;
		width:75px !important;
		line-height: 45;
		vertical-align: middle;
		text-align:left;
		
		
		
		margin-top: 0;
		
		margin-left: 0; 
		
		border:hidden;
		background-color:rgba(239,45,25, 0.3);
		padding:0;
	}
*/
	/*

	*/
	.media_map_view_active{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		line-height: 20px !important;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		
		position: relative;
		color:aqua;
		font-size: .85em  !important;
		font-weight: 600;
		
	    margin-top: 0;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(170,63,207, 0.7);
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}

	.media_data_view_active{
		z-index: 10;
		height:75px !important;
		width:75px !important;
		line-height: 20px !important;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		
		position: relative;
		color:aqua;
		font-size: .85em  !important;
		font-weight: 600;
		
	    margin-top: 0;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(221,63,89, 0.7);
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		background-position:center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}


/* ################################################ */
	/*
media_menu_pagination_option_div1

	*/
	.media_menu_pagination_div{
		z-index: 10;
		height:80px !important;
		width:645px !important;
		line-height: 80;
		vertical-align: middle;
		text-align:left;
		
		position: absolute;
		
		margin-top: -90;
		/*
	    margin-top: 10;
		*/
		margin-left: 110; 
		
		border:hidden;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.media_menu_pagination_ajax{
		z-index: 10;
		height:80px !important;
		width:645px !important;
		line-height: 80;
		vertical-align:middle;
		text-align:center;
		
		position: absolute;
	    margin-top: 0;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.media_menu_pagination_back_disabled{
		z-index: 9999;
		height:74px !important;
		width:37px !important;
		line-height: 74;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		
	    margin-top: 2;
		margin-left: 1; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
		background-size: 20px 60px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*
swipe_view_window_pagination_menu

*/
	.media_menu_pagination_back_active{
		z-index:9999;
		height:74px !important;
		width:37px !important;
		line-height: 74;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		
	    margin-top: 2;
		margin-left: 1; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
		background-size: 20px 60px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.media_menu_pagination_forward_disabled{
		z-index: 9999;
		height:74px !important;
		width:37px !important;
		line-height: 74;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		
	    margin-top: 2;
		margin-left: 1; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
		background-size: 20px 60px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.media_menu_pagination_forward_active{
		z-index: 9999;
		height:74px !important;
		width:37px !important;
		line-height: 74;
		vertical-align: middle;
		text-align: center;
		display: inline-block;
		position: relative;
		
	    margin-top: 2;
		margin-left: 1; 
		
		border:1px solid #CCC;
		background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
		background-size: 20px 60px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
/*
position: absolute;
.swipe_menu_pagination_div
media_menu_pagination_ajax
media_menu_pagination_option_active
*/
	.media_menu_pagination_option_div1 .media_menu_pagination_option_div9 {
		z-index: 10;
		height:78px !important;
		width:40px !important;
		line-height: 78;
		vertical-align: middle;
		text-align: center;
		display:inline-block;
		
		
	    margin-top: 0;
		margin-left: 2; 
		
	}

	 .media_menu_pagination_option_div2 .media_menu_pagination_option_div3 .media_menu_pagination_option_div4 .media_menu_pagination_option_div5 .media_menu_pagination_option_div6 .media_menu_pagination_option_div7 .media_menu_pagination_option_div8 {
		z-index: 10;
		height:76px !important;
		width:84px !important;
		line-height: 78;
		vertical-align: middle;
		text-align: center;
		display:inline-block;
		 background-color: azure;
		
		border:1px solid #CCC;
		
	    margin-top: 0;
		margin-left: 0; 
		
	}
	
/*
vertical-align:middle !important;
rgba(239,239,239, 0.7)
swipe_view_window
*/
	.media_option_main_table{
		height:626px !important;
		width:957px !important;
		line-height:478px;
		border:1px solid #CCC; 
		background-color: rgba(239,239,239, 0.7);
		
		padding:0;
		margin:0;
		vertical-align:text-top;
	}
/*
	.media_option_filter_menu {
		height:36px !important;
		width:948px;
		display:inline-block;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}

	.media_option_filter_option {
		height:36px !important;
		width:100px !important;
		display:inline-block;
		border-right:1px solid #CCC;
		border-left:1px solid #CCC;
		border-top:1px solid #CCC;
		border-bottom:1px solid #CCC;
		border-spacing: 0px;
		background: url(../images/buttons/butt_template.png);
		background-size: 90px 32px;
   		background-repeat: no-repeat;
		background-position:center;
		border:hidden;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.media_option_filter_menutd1 {
		height:36px !important;
		width:480px;
		display:inline-block;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
	.media_option_filter_menutd2 {
		height:36px !important;
		width:368px !important;
		display:inline-block;
		vertical-align: top;
		border-right:hidden;
		border-left:hidden;
		border-top:hidden;
		border-bottom:hidden;
	}
*/
	.media_option_main_table_td{
		height:606px !important;
		width:957px !important;

		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:transparent;
		padding:0;
		margin:0;
		text-align:right;
		vertical-align:middle !important;
	}
	.media_view_window{
		height:606px !important;
		width:952px !important;

		border-top:hidden;
		border-bottom:hidden;
		border-left:hidden;
		border-right:hidden;
		background-color:#FFF;
		padding:0;
		margin:0;
		text-align:center;
		vertical-align:top !important;
	}

	.media_image_view_window {
		z-index: 0;
	  	height:589px !important;
		width:790px !important;
		line-height: 24;
		
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-left: 0px;
		
		color: white;
		text-align:center;
		vertical-align:middle;
		text-decoration:none;
		
		/**/
		background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
		
		background-size: 790px 589px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*
height:589px !important;
media_view_div_dislike
*/
	.media_stat_view_window {
		z-index: 0;
	  	height:604px !important;
		width:948px !important;
		line-height: 24;
		
		display: inline-block;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 0;
		margin-left: 0px;
		
		color: white;
		text-align:center;
		vertical-align:text-top;
		text-decoration:none;
		
		/*background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);*/
		
		
		background-size: 545px 389px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_view_window_menu_top{
		z-index: 10;
		height:60px !important;
		width:950px !important;
		
		position: absolute;
	    margin-top: 0;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background-color:rgba(0,63,76, 0.7);
		padding:0;
		
		vertical-align:middle !important;
	}
	.media_view_window_menu_bottom{
		z-index: 10;
		height:100px !important;
		width:950px !important;
		
		position: absolute;
	    margin-top: 506;
		margin-left: 0; 
		
		border:1px solid #CCC;
		background-color:rgba(0,63,76, 0.7);
		padding:0;
		
		vertical-align:middle !important;
	}
	
/*
	ls_form_half_field
	background-color:rgba(239,239,239, 0.3);

*/	
	.media_view_div_option{
		z-index: 0;
		height:45px !important;
		width:85px !important;
		line-height: 45;
		
		position: absolute;
	    margin-top: 8;
		margin-left: 750; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	/*
		close
	background-image:url(../images/buttons/sys_close_buttonDefault.png) ;
	*/
	.media_view_image_button_active {
		z-index: 0;
	  	height:45px !important;
		width:85px !important;
		
		display: inline-block;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background: url(../images/buttons/butt_template.png) rgba(25,247,94, 0.7);
		background-size: 60px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_view_stat_button_active {
		z-index: 0;
	  	height:35px !important;
		width:35px !important;
		/*
		width:85px !important;
		*/
		
		display: inline-block;
		
		position: absolute;
		left: 0px;
		top: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background: url(../images/buttons/butt_template.png) rgba(204,15,104, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_view_div_exit{
		z-index: 0;
		height:45px !important;
		width:45px !important;
		
		position: absolute;
	    margin-top: 8;
		margin-left: 900; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	/*
		close
	background-image:url(../images/buttons/sys_close_buttonDefault.png) ;
	*/
	.media_view_exit_button_active {
		z-index: 0;
	  	height:45px !important;
		width:45px !important;
		
		display: inline-block;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		background: url(../images/buttons/sys_close_buttonDefault.png);
		background-size: 40px 40px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_view_div_count{
		z-index: 0;
		height:35px !important;
		width:120px !important;
		
		position: absolute;
	    margin-top: 8;
		margin-left: 5; 
		
		border:1px solid #CCC;
		background-color:transparent;
		padding:0;
	}
	.count_form_field{
	  	height:35px !important;
		/*width: 195px;*/
		width: 100%;
		line-height:30px !important;
		background-color:rgba(255,255,255, 0.3);
		border-spacing: 0px;
		border:hidden;
		
		color: white  !important;
		font-size: 1.20em  !important;
		font-weight: 600;
		text-align:center;
		vertical-align:middle !important;
		text-decoration:none;
	  }
	

/*
	media_view_window_forward
	media_view_window_back
*/
	.media_view_window_back{
		z-index: 10;
		height:400px !important;
		width:40px !important;
		
		position: absolute;
	    margin-top: 100;
		margin-left: 0px; 
		
		border:1px solid #CCC;
		background-color:lightgray;
		padding:0;
		
		vertical-align:middle !important;
	}
	.media_view_window_forward{
		z-index: 10;
		height:400px !important;
		width:40px !important;
	
		position: absolute;
		margin-top: 100;
		margin-left: 914px; 
		
		border:1px solid #CCC;
		background-color:lightgray;
		padding:0;
		
		vertical-align:middle !important;
	}
/*
	.media_view_bottom_table {
		height:10px !important;
		width:720px !important;
		vertical-align:middle;
		background-color: rgba(15, 5, 26, 0.7);
	} 
*/
	
	.media_view_forward_button_active {
		z-index: 0;
	  	height:400px !important;
		width:90px !important;
		
		display: inline-block;
		line-height: 12px;
		
		
		border-spacing: 0px;
		border:1px solid #CCC;
		margin-left: -50;
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
		background-size: 30px 380px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_view_forward_button_disabled {
		z-index: 0;
	  	height:400px !important;
		width:90px !important;
		
		display: inline-block;
		line-height: 12px;
		
		
		border-spacing: 0px;
		border:1px solid #CCC;
		margin-left: -50;
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
		background-size: 30px 380px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_view_back_button_active {
		z-index: 0;
	  height:400px !important;
		width:90px !important;
		
		display: inline-block;
		line-height: 12px;
		
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(0,63,76, 0.7);
		background-size: 30px 380px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.media_view_back_button_disabled {
		z-index: 0;
	  height:400px !important;
		width:90px !important;
		
		display: inline-block;
		line-height: 12px;
		
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color: white;
		font-size: .40em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
		background-size: 30px 380px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*

	
*/
/*
	.media_view_div_like{
		z-index: 10;
		height:80px !important;
		width:75px !important;
		line-height: 80;
		vertical-align:text-top;
		text-align:left;
		
		position: absolute;
		
		left: 870px;
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.media_view_div_dislike{
		z-index: 10;
		height:80px !important;
		width:75px !important;
		line-height: 80;
		vertical-align:text-top;
		text-align:left;
		
		position: absolute;
		
		left: 792px;
		
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
*/
/*
	media_view_map_swipe
*/
	.media_view_div_action{
		z-index: 9;
		height:84px !important;
		width:160px !important;
		line-height: 84;
		
		
		position: absolute;
		top: 513px;
		left: 723px;
		
		border:hidden;
		background-color:rgba(239,239,239, 0.3);
		padding:0;


	}
	
	.media_view_div_like{
		z-index: 10;
		height:80px !important;
		width:76px !important;
		line-height: 80;
		vertical-align:text-top;
		text-align:left;
		
		position: absolute;
		top: 1px;
		left: 80px;
		/*
		top: 520px;
		left: 790px;
		*/
		
		border:hidden;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.media_view_div_dislike{
		z-index: 10;
		height:80px !important;
		width:76px !important;
		line-height: 80;
		vertical-align:text-top;
		text-align:left;
		
		position: absolute;
		top: 1px;
		left: 2px;
		/*
		top: 520px;
		left: 712px;
		*/
		
		border:hidden;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}

/*
position:absolute;
swipe_view_div_count
*/
	.media_view_like_disabled{
		z-index: 9999;
	  	height:80px !important;
		width:75px !important;
		line-height: 12px;
		
		display: inline-block;
		
		position: absolute;
		margin-top: 0px;
		margin-left: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.media_view_like_enabled{
		z-index: 9999;
	  	height:80px !important;
		width:75px !important;
		line-height: 12px;
		
		display: inline-block;
		
		position: absolute;
		margin-top: 0px;
		margin-left: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(0, 255, 0, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.media_view_dislike_disabled{
		z-index: 9999;
	  	height:80px !important;
		width:75px !important;
		line-height: 12px;
		
		display: inline-block;
		position: absolute;
		margin-top: 0px;
		margin-left: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }
	.media_view_dislike_enabled{
		z-index: 9999;
	  	height:80px !important;
		width:75px !important;
		line-height: 12px;
		
		display: inline-block;
		
		position: absolute;
		margin-top: 0px;
		margin-left: 0px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) rgba(255, 25, 52, 0.7);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }

	.media_view_div_report{
		z-index: 10;
		height:80px !important;
		width:80px !important;
		line-height: 80;
		vertical-align: middle;
		text-align: center;
		
		position: absolute;
	    margin-top: 10;
		margin-left: 10; 
		
		border:1px solid #CCC;
		background-color:rgba(239,239,239, 0.3);
		padding:0;
	}
	.media_view_report_enabled{
		z-index: 0;
	  	height:80px !important;
		width:80px !important;
		
		display: inline-block;
		line-height: 12px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		color:rgba(15, 5, 26, 0.6);
		font-size: .800em  !important;
		font-weight: 500;
		text-align:center;
		vertical-align:bottom;
		text-decoration:none;
		
		background: url(../images/buttons/butt_template.png) #5BC80B;
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position:50% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	  }



.business_item_photosystem {
	z-index: 20;
	height:56px !important;
	width:70px !important;
	line-height: 12px;

	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	
	border-spacing: 0px;
	border: thin solid rgba(239,239,239, 0.7);
	vertical-align: top;
    text-align:center;
    text-decoration:none;
    text-indent:0px;
	background:rgba(255,255,255, 0.4);
	font-size: 1.05em;
	font-weight: 700; 
    /*
	event_container_data9_2
	event_container_data8_1_1
    event_container_data8_1_2
    */
    /*
   	color: #000;
	text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	ls_form_catalog_field
		event_container_data8_1
	*/
}
.business_item_photolist {
	z-index: 18;
	height:56px !important;
	width:65px !important;
	line-height: 12px;
	position: absolute;
	display:inline-block;
	vertical-align: top;
	margin-top:-28px;
	margin-left:5px;
	border-spacing: 0px;
	border: hidden;
	
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.4);
	background-size: 185px 56px;
	background-repeat: no-repeat;
	background-position:center;
	
	font-size: 1.05em;
	font-weight: 700; 
    /*
    
    */
    /*
   	color: #000;
	text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
		event_container_data8_1
    ls_menu_event_item_half_display_field
	*/
} 
/******************************/

.event_item_streamsystem {
	z-index: 20;
	height:56px !important;
	width:130px !important;
	line-height: 45px;
	position: absolute;

	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	
	border-spacing: 0px;
	border: thin solid rgba(239,239,239, 0.7);

	background:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
		event_container_data8_1
	*/
}
.event_item_streamlist {
	z-index: 18;
	height:56px !important;
	width:130px !important;
	line-height: 45px;
	position: absolute;
	display:inline-block;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border: hidden;
	
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.4);
	background-size: 185px 56px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
		event_container_data8_1
	*/
} 

/*****************************/
.photolist_frame_absolute {
	height:125px !important;
	width:231px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:5px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.photolist_frame{
	z-index: 1;
   	height:125px !important;
	width:231px;
    line-height:99px  !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,233,233, 0.5);
	/**/
	background: url(../images/buttons/clear_img.png);
    background-size: 231px 121px;
    background-repeat: no-repeat;
    background-position:center;
	
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*photolist_social*/
.photolist_upload {
    height:160px !important;
	width:231px;
    line-height:12px !important;
    display: inline-block;
	position: absolute;
    border-spacing: 0px;
   border: 1px solid rgba(239,239,239, 0.3);
    vertical-align:middle;
    text-align:center;
    background: url(../images/buttons/sys_upload_graphic.png) rgba(239,239,239, 0.7);
    background-size: 100px 100px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.photolist_logo {
    height:85px !important;
	width:112px;
    line-height:12px !important;
    display: inline-block;
	position: absolute;
    margin-top:0px;
    margin-left:0px;
    border-spacing: 0px;
   border: 1px solid rgba(239,239,239, 0.3);
    vertical-align:middle;
    text-align:center;
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photolist_upload_image {
    height:85px !important;
	width:112px;
    line-height:12px !important;
    display: inline-block;
	position: absolute;
    margin-top:0px;
    margin-left:114px;
    border-spacing: 0px;
   border: 1px solid rgba(239,239,239, 0.3);
    vertical-align:middle;
    text-align:center;
    background: url(../images/buttons/sys_upload_graphic.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photolist_qr_code {
    height:85px !important;
	width:112px;
    line-height:12px !important;
    display: inline-block;
	position: absolute;
    margin-top:87px;
    margin-left:0px;
    border-spacing: 0px;
   border: 1px solid rgba(239,239,239, 0.3);
    vertical-align:middle;
    text-align:center;
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photolist_map {
    height:85px !important;
	width:112px;
    line-height:12px !important;
    display: inline-block;
	position: absolute;
    margin-top:87px;
    margin-left:114px;
    border-spacing: 0px;
   border: 1px solid rgba(239,239,239, 0.3);
    vertical-align:middle;
    text-align:center;
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 60px 60px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}


.photolist_remove{
	z-index: 1;
   	height:43px !important;
	width:43px !important;
	display:inline-block;
	position:absolute;
	margin-left: 187px;
	margin-top: 1px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(255,255,255, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photolist_social{
	z-index: 1;
   	height:43px !important;
	width:43px !important;
	display:inline-block;
	position:absolute;
	margin-left: 5px;
	margin-top: 5px;
	vertical-align:top;
	border:2px solid rgba(0,0,255, 0.7);
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.9);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
	border-radius: 45px;
	font-size: 1.15em;
	font-weight: 700; 
    /*
    
    */
    /*
   	color: #000;
	text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photolist_social_null{
	z-index: 1;
   	height:43px !important;
	width:43px !important;
	display:inline-block;
	position:absolute;
	margin-left: 5px;
	margin-top: 5px;
	vertical-align:top;
	border:2px solid rgba(255,255,255, 0.7);
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
	border-radius: 45px;
	font-size: 1.15em;
	font-weight: 700; 
    /*
    
    */
    /*
   	color: #000;
	text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    */
	
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

.photobucket_frame_absolute {
	height:173px !important;
	width:231px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:3px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.photobucket_frame{
	z-index: 1;
   	height:173px !important;
	width:231px;
    line-height:99px  !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,233,233, 0.5);
	/**/
	background: url(../images/buttons/clear_img.png);
    background-size: 231px 173px;
    background-repeat: no-repeat;
    background-position:center;
	
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************/
/************************//************************/
/*

*/
.photobucket_action_like_active{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 175px;
	margin-top: 127px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageLikeactive.png) rgba(255,255,255, 0.3);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_action_like_disabled{
    /*
    container_page_STANDARD_footer_frame_option_DOESLIKE_DEFAULT
    */
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 175px;
	margin-top: 127px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageLikedefault.png) rgba(255,255,255, 0.3);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_action_dislike_active{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 120px;
	margin-top: 127px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageDislikeactive.png) rgba(255,255,255, 0.3);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_action_dislike_disabled{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 120px;
	margin-top: 127px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageDislikedefault.png) rgba(255,255,255, 0.3);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.photobucket_view_like_active{
    /*
    catalog_image_viewer_dislike_frame
    */
	z-index: 1;
   	height:58px !important;
	width:98px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageLikeactive.png) rgba(255,255,255, 0.3);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_view_like_disabled{
	z-index: 1;
   	height:58px !important;
	width:98px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageLikedefault.png) rgba(255,255,255, 0.3);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_view_dislike_active{
	z-index: 1;
   	height:58px !important;
	width:98px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageDislikeactive.png) rgba(255,255,255, 0.3);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_view_dislike_disabled{
	z-index: 1;
   	height:58px !important;
	width:98px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageDislikedefault.png) rgba(255,255,255, 0.3);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.photobucket_scroll_like_active{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageLikeactive.png) rgba(255,255,255, 0.3);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_scroll_like_disabled{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageLikedefault.png) rgba(255,255,255, 0.3);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_scroll_dislike_active{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageDislikeactive.png) rgba(255,255,255, 0.3);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_scroll_dislike_disabled{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageDislikedefault.png) rgba(255,255,255, 0.3);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************/
/************************//************************/
.photobucket_menu_profile{
	z-index: 1;
   	height:23px !important;
	width:23px !important;
	display:inline-block;
	position:absolute;
	margin-left: 2px;
	margin-top: 2px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 23px 23px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************/
/************************//************************/
.photobucket_menu_activity_positive{
	z-index: 1;
   	height:23px !important;
	width:33px !important;
	display:inline-block;
	position:absolute;
	margin-left: 130px;
	margin-top: 2px;
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 20px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_activity_neutral{
	z-index: 1;
   	height:23px !important;
	width:33px !important;
	display:inline-block;
	position:absolute;
	margin-left: 130px;
	margin-top: 2px;
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 20px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_menu_activity_negative{
	z-index: 1;
   	height:23px !important;
	width:33px !important;
	display:inline-block;
	position:absolute;
	margin-left: 130px;
	margin-top: 2px;
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 20px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.photobucket_view_activity_positive{
	z-index: 1;
   	height:28px !important;
	width:48px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 45px 25px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_view_activity_neutral{
	z-index: 1;
   	height:28px !important;
	width:48px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 45px 25px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_view_activity_negative{
	z-index: 1;
   	height:28px !important;
	width:48px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 45px 25px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.photobucket_scroll_activity_positive{
	z-index: 1;
   	height:23px !important;
	width:43px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 50px 23px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_scroll_activity_neutral{
	z-index: 1;
   	height:23px !important;
	width:43px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 50px 23px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_scroll_activity_negative{
	z-index: 1;
   	height:23px !important;
	width:43px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 50px 23px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************/
/************************//************************/
.photobucket_menu_count{
	z-index: 1;
   	height:23px !important;
	width:63px !important;
	line-height: 23px; 
	display:inline-block;
	position:absolute;
	margin-left: 165px;
	margin-top: 2px;
	vertical-align:middle;
	border:1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.7);
    font-size: .75em;
    font-weight: 600;
    text-align:right;
    text-decoration:none;
    text-indent:0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;

	
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
photobucket_image_viewer_dislike_frame
photobucket_image_viewer_menu_count
*/
.photobucket_list_count{
	z-index: 1;
   	height:28px !important;
	width:88px !important;
	line-height: 28px; 
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:middle;
	border:1px solid rgba(239,239,239, 0.7);
	background-color: rgba(239,239,239, 0.7);
    font-size: 1.25em;
    font-weight: 600;
    text-align:right;
    text-decoration:none;
    text-indent: 0px;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************************/
	.event_swipe_full_base_table_td2_2_1_2 {
		height:260px !important;
		width:165px !important;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		display:inline-block;
		background-color:transparent;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_swipe_full_base_table_td2_2_1_2_1 {
		height:54px !important;
		width:165px !important;
		line-height: 54;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: 1px solid #ccc;
		display:inline-block;
		background-color:transparent;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_swipe_full_base_table_td2_2_1_2_2 {
		height:205px !important;
		width:165px !important;
		border-spacing: 0px;
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		display:inline-block;
		background-color:transparent;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	/************************************/
	.event_swipe_full_base_table_td2_2_2{
		height:98px !important;
		width:586px !important;
		line-height: 98px;
		
		display:inline-block;
		margin-top: 219px;
		position: absolute;
		
		vertical-align: text-top;
		
		border-spacing: 0px;
		
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		
		border:1px solid #ccc;
		
		display:inline-block;
		
		background-color:transparent;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.event_swipe_full_base_table_td2_2_2_1{
		height:97px !important;
		width:117px !important;
		line-height: 97px;
		
		display:inline-block;
		vertical-align: top;
		
		border-spacing: 0px;
		
		border-right: hidden;
		border-left: hidden;
		border-top: hidden;
		border-bottom: hidden;
		
		
		background-color:transparent;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/*
feed_container
*/
.seindex_container_td {
	height: 580px;
	width: 950px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.seindex_container_td_left {
	height: 580px;
	width: 712px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(239,239,239, 0.6);
    background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.seindex_container_td_right {
	height: 580px;
	width: 234px !important;
	position:relative;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(239,239,239, 0.6);
    background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/************************//************************//************************/
	/************************//*	PROFILE_FEED_SETTINGS	*/
	/********//*	PROFILE_FEED_SETTINGS_MAIN	*/
	
	/*
		active_feed_BodyTable	
		
	*/
.standard_container {
    /*
	height:660px;
    height:711px;
    height: 100%;
    */
    width: 960px;
    position:relative;
    background-color:rgba(255,255,255, 0.0);
    margin: 0 auto;
    /*
    border-top: 1px solid #CCC;
    border-left: hidden;
    border-right: hidden;
    border-bottom: hidden;
    */
    border-top: 1px solid #CCC;
    border-left: hidden;
    border-right: hidden;
    border-bottom: hidden;
    padding:0;
    overflow-y: hidden;  /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.feed_container {
    
    /*
	height:660px;
    height:711px;
    height: 100%;
    */
    width: 960px;
    position:relative;
    background-color:rgba(255,255,255, 0.0);
    margin: 0 auto;
    /*
    border-top: 1px solid #CCC;
    border-left: hidden;
    border-right: hidden;
    border-bottom: hidden;
    */
    border-top: 1px solid #CCC;
    border-left: hidden;
    border-right: hidden;
    border-bottom: hidden;
    padding:0;
    overflow-y: hidden;  /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.active_feed_container {
    /*
	
    height:711px;
    height: 100%;
    */
    height:2000px;
    width: 960px;
    position:relative;
    background-color:rgba(255,255,255, 0.7);
    margin: 0 auto;
    /*
    border-top: 1px solid #CCC;
    border-left: hidden;
    border-right: hidden;
    border-bottom: hidden;
    */
    border-top: 1px solid #CCC;
    border-left: hidden;
    border-right: hidden;
    border-bottom: hidden;
    padding:0;
    overflow-y: hidden;  /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.active_feed_BodyTable{
    height:2000px;
    width:735px !important;

    margin-left: 0px !important;
    margin-top: 51px !important;

    background-color:rgba(255,255,255, 0.7);

    border:0; 

    padding:0;
    margin:0;
    overflow-y: hidden;  /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.active_feed_BodyTable_td{
    height:2000px;
    width:635px !important;
    vertical-align:top !important;
    overflow-y: hidden;  /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.feed_control_menu {
	height: 658px;
	width: 170px !important;
	position:relative;
	background-color: rgba(239,239,239, 0.8);
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border: hidden;
/*
	
	border-top: 1px solid rgba(204,204,204, 0.3);
	border-bottom: 1px solid rgba(204,204,204, 0.3);
	border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
*/
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_menu_td {
	height: 40px;
	width: 715px !important;
	position:relative;
	background-color:rgba(239,239,239, 0.3);
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:middle;
	text-align:left;
	border:1px solid rgba(233,233,233, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_listview_container_td {
	/*
	height:660px;
	height:711px;
	*/
	height: 525px;
	/*
	width: 545px !important;
	*/
	width: 950px !important;
	
	
	position:relative;
	
	background-color:rgba(255,255,255, 0.3);

	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border-left: 1px solid #CCC;
	border-right: 1px solid #CCC;
	border-bottom: 1px solid #CCC;

	/*
	
	*/
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
social_share_page_left
*/
.feed_pagination_container_td_left {
	height: 600px;
	width: 15px !important;
	position:relative;
	margin-top: 0px;
    margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_pagination_container_td_right {
	height: 600px;
	width: 15px !important;
	position:relative;
	margin-top: 0px;
    margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_pagination_container_td_bottom {
	height: 50px;
	width: 916px !important;
	position:relative;
	margin-top: 0px;
    margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_pagination_container_td_bottom1 {
	height: 50px;
	width: 656px !important;
	position:relative;
	margin-top: 0px;
    margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_pagination_container_td_bottom2 {
	height: 50px;
	width: 250px !important;
	position:relative;
	margin-top: 0px;
    margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_pagination_container_div_1 {
	height: 20px;
	width: 135px !important;
    line-height: 20px;
    display: inline-block;
	margin-top: 15px;
    margin-left:0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:right;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.85em  !important;
    font-weight: 600;
	text-indent: 0px;
}
.feed_pagination_container_div_2 {
	height: 20px;
	width: 100px !important;
    line-height: 20px;
    display: inline-block;
	margin-top: 15px;
    margin-left:0px;	
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 0.85em  !important;
    font-weight: 600;
	text-indent: 0px;
}
.feed_pagination_container_td {
	height: 550px;
	width: 916px !important;
	position:relative;
	margin-top: 0px;
    margin-left:0px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_pagination_container_exit_div {
    z-index:10;
	height: 73px;
	width: 95px !important;
	position:absolute;
	margin-top: 5px;
    margin-left:850px;
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_pagination_exit_option {
    height:73px !important;
    width:95px !important;
    display:inline-block;
    line-height:12px !important;
    margin-top: 2px;
	margin-left: 2px;
	/*
    font-size: .85em;
    font-weight: 500;
	*/
    text-align:left;
    text-decoration:none;
    text-indent:35px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.9);
    background-size: 60px 50px;
    background-repeat: no-repeat;
	background-position: center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

.feed_container_td {
	height: 530px;
	width: 950px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_td_left {
	height: 528px;
	width: 712px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(239,239,239, 0.6);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_td_right {
	height: 568px;
	width: 234px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(239,239,239, 0.6);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_td_left_top_td{
	height: 65px;
	width: 710px !important;
	position:relative;
	background-color:rgba(14,255,255, 0.3);
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(239,239,239, 0.6);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_td_left_bottom_td{
	height: 460px;
	width: 714px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border: hidden;
	/*
	border: 1px solid rgba(239,239,239, 0.6);
	*/
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/**********************************************/
/*

*/
.mail_container_td {
	height: 530px;
	width: 950px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_td_left {
	height: 520px;
	width: 145px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.4);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_td_right {
	height: 520px;
	width: 145px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.4);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_td_right_td1 {
	height: 355px;
	width: 145px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.4);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_td_right_td2 {
	height: 43px;
	width: 145px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.4);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_td_right_td2_td1{
    height:41px !important;
    width:48px;
    line-height:20px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.3);
	background:rgba(255,255,255, 0.0);
	vertical-align: middle;
	text-align: center;
}
.mail_container_td_right_td2_td2{
    height:41px !important;
    width:48px;
    line-height:20px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.3);
	background:rgba(255,255,255, 0.0);
	vertical-align: middle;
	text-align: center;
}
.mail_container_td_right_td2_td3{
    height:41px !important;
    width:48px;
    line-height:20px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.3);
	background:rgba(255,255,255, 0.0);
}
.mail_container_td_right_td3 {
	height: 140px;
	width: 145px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.4);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	mail_add_button
	*/
}
/**************************************/
.mail_container_feed {
	height:430px !important;
	width:657px !important;
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(239,239,239, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_menu_bar {
	height:110px !important;
	width:657px !important;
	border-spacing: 0px;
	border:hidden;
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_menu_bar_full {
	height:110px !important;
	width:657px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.0);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.4);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.mail_form_messageboxinput_field{
    height:60px !important;
    width: 595px !important;
	line-height: 20px !important;
 
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
    background-color:rgba(239,239,239, 0.0);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
    text-indent:5px;
	*/
    
}
.mail_text_count_field{
    height:33px !important;
    width: 50px;
    line-height:20px !important;
	margin-top: 1px;
	margin-left: 2px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
	background-color:rgba(255,255,255, 0.5);
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
  }
.mail_general_table_td1{
    height:35px !important;
    width:55px !important;
    line-height:20px !important;
	position: absolute;
	margin-top: 1px;
	margin-left: 597px;
	border-bottom: 1px solid rgba(204,204,204, 0.0);
    background:rgba(239,239,239, 0.0);
}
.mail_general_table_td2{
    height:60px !important;
    width:655px !important;
	display: inline-block;
    border: 1px solid rgba(204,204,204, 0.0);
	background:rgba(239,239,239, 0.0);
}
.mail_general_table_td3{
    height:45px !important;
    width:655px !important;
    line-height:45px !important;
	border: 1px solid rgba(204,204,204, 0.0);
    background:rgba(239,239,239, 0.0);
}

.mail_form_command_bar_td1{
    height:41px !important;
    width:496px;
    line-height:20px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
	background:rgba(255,255,255, 0.0);
}

.mail_form_command_bar_td2{
    height:41px !important;
    width:110px;
    line-height:20px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
	background:rgba(255,255,255, 0.0);
}
.mail_form_command_bar_td3{
    height:41px !important;
    width:110px;
    line-height:20px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.0);
	background:rgba(255,255,255, 0.0);
}

.mail_submit_button {
    height: 40px;
    width: 100px;
    line-height:38px;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 20px 20px;
    background-position:1% 45%;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.mail_cancel_button {
    height: 40px;
    width: 100px;
    line-height:40px;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
    background-size: 20px 20px;
    background-position:1% 45%;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.mail_add_button {
    height: 39px;
    width: 45px;
    line-height:23px;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/plus_button.png) rgba(239,239,239, 0.7);
    background-size: 30px 30px;
    background-position:center;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	
		mail_container_td_right_td1
	DELETE
	*/
}
.mail_remove_button {
    height: 39px;
    width: 45px;
    line-height:23px;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(239,239,239, 0.7);
    background-size: 30px 30px;
    background-position:center;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.mail_leave_button {
    height: 39px;
    width: 45px;
    line-height:23px;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(239,200,239, 0.7);
    background-size: 30px 30px;
    background-position:center;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.mail_profile_button {
    height: 140px;
    width: 140px;
    line-height:40px;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.7);
    /*
    background-size: 130px 130px;

    
	background-repeat: no-repeat;
    */
    background-position:center;
    background-size: cover; /* Resize the background image to cover the entire container */
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}


.mail_new_button {
    height: 35px;
    width: 80px;
    line-height:23px;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.4);
    background-size: 20px 20px;
	background-position:center;
	/*
    background-position:1% 45%;
	*/
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.mail_delete_generate_button {
    height: 35px;
    width: 80px;
    line-height:23px;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.4);
    background-size: 20px 20px;
    background-position:center;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.mail_delete_button {
    height: 35px;
    width: 60px;
    line-height:23px;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.4);
    background-size: 20px 20px;
    background-position:center;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.mail_mark_button {
    height: 35px;
    width: 60px;
    line-height:23px;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(0,0,255, 0.4);
    background-size: 20px 20px;
    background-position:center;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.mail_select_button {
    height: 35px;
    width: 40px;
    line-height:23px;
	vertical-align: middle;
    text-align:center;
    text-decoration:none;
    border:1px solid rgba(239,239,239, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.4);
    background-size: 20px 20px;
    background-position:center;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/**********************************************/
.mailview_rec_absolute_small{
	z-index: 10;
    height:67px !important;
    width:67px;
    line-height:99px  !important;
    border-spacing: 0px;
	background:rgba(239,239,239, 0.5);
    border:hidden;
    display:inline-grid;
    margin-top:3px !important;
    margin-left:3px !important;
    vertical-align:top !important;
    text-align:center;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.mailview_rec_absolute_large{
	z-index: 10;
    height:130px !important;
    width:135px;
    line-height:99px  !important;
    border-spacing: 0px;
	background:rgba(239,239,239, 0.1);
    border:hidden;
    display:inline-grid;
    margin-top:3px !important;
    margin-left:4px !important;
    vertical-align:top !important;
    text-align:center;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.mailview_rec_frame_absolute_small {
	height:67px !important;
    width:67px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.1);
	background-color: rgba(233,233,233, 0.1);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mailview_rec_frame_absolute_large {
	height:130px !important;
    width:135px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.1);
	background-color: rgba(233,233,233, 0.1);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.mailview_rec_frame_option_1_small {
	z-index: 1;
    height:67px !important;
    width:67px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:1px;
	margin-left:1px;
    background: url(../images/buttons/clear_img.png);
    background-size: 67px 67px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.mailview_rec_frame_option_1_large {
	z-index: 1;
    height:130px !important;
    width:135px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:0px;
	margin-left:0px;
    background: url(../images/buttons/clear_img.png);
    background-size: 130px 130px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.mailview_rec_frame_option_2_large{
	z-index: 10;
	height:25px !important;
	width:136px;
	line-height: 25px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.1);
	display: inline-block;
	margin-top:107px;
	margin-left:2px;
	vertical-align:top;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 1.05em  !important;
    font-weight: 500;
	text-indent: 0px;
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/**************************************/
/**********************************************/
/*

*/
.mail_container_scroll_td {
	height: 575px;
	width: 950px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_scroll_td_left {
	height: 570px;
	width: 145px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.4);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_scroll_td_right {
	height: 570px;
	width: 145px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color:rgba(239,239,239, 0.4);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_scroll_td_right_td1 {
	height: 384px;
	width: 145px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.4);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_scroll_td_right_td2 {
	height: 43px;
	width: 145px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.4);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_scroll_td_right_td2_td1{
    height:41px !important;
    width:48px;
    line-height:20px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.3);
	background:rgba(255,255,255, 0.0);
	vertical-align: middle;
	text-align: center;
}
.mail_container_scroll_td_right_td2_td2{
    height:41px !important;
    width:48px;
    line-height:20px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.3);
	background:rgba(255,255,255, 0.0);
	vertical-align: middle;
	text-align: center;
}
.mail_container_scroll_td_right_td2_td3{
    height:41px !important;
    width:48px;
    line-height:20px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.3);
	background:rgba(255,255,255, 0.0);
}
.mail_container_scroll_td_right_td3 {
	height: 140px;
	width: 145px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
	background-color:rgba(239,239,239, 0.4);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	/*
	mail_add_button
	*/
}
.mail_container_scroll_feed {
	height:460px !important;
	width:657px !important;
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	overflow: hidden;
	vertical-align:middle;
	text-align:left;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	background-color: rgba(239,239,239, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.mail_container_scroll {
	height:100% ;
	/*
	width:657px !important;
	*/
	position: relative;
	margin-top: 0px;
	margin-left: 0px;
	/**/
	overflow: auto;
	padding-bottom:10px;
	background-color: rgba(255,255,255, 0.9);
}
/**********************************************/
	.feed_footer_container {
		/*
		height:660px;
		height:711px;
		*/
		
		width: 960px;
		position:relative;
		
		background-color:rgba(255,255,255, 0.3);
		
		margin: 0 auto;
		
		border-top: 1px solid #CCC;
		border-left: 1px solid #CCC;
		border-right: 1px solid #CCC;
		border-bottom:  1px solid #CCC;
		
		padding:0;
		
	}
	
	.controlFeedTable_div {
		height:660px;
		width:173px;
		
		position:fixed;
		
		background-color:rgba(255,255,255, 0.7);
		border-top: 1px solid #CCC;
		border-left: 1px solid #CCC;
		border-right:  1px solid #CCC;
		border-bottom:  1px solid #CCC;
		
		align:left;
		
		padding:0;
		margin-top: 51px;
	}

.controlFeedAdvertise_ManagerTable_div {
    height:656px;
    width:215px;
    position:absolute;
    background-color:rgba(255,255,255, 0.7);
    border: 1px solid #CCC;
    /*
    align:left;
    top: 0px;
    
    */
    padding:0;
    margin-top: 2px;
    margin-left: 0px;
}
.controlBusinessAdvertise_ManagerTable_div {
	/*
	
	window_panel_land business_container_td2
	*/
    height:620px;
    width:225px;

    position:absolute;

    background-color:rgba(255,255,255, 0.7);
	border: hidden;
	 /*
    border-top: 1px solid #CCC;
    border-left:  1px solid #CCC;
    border-right: 1px solid #CCC;
    border-bottom:  1px solid #CCC;
   
    align:left;
    */
    padding:0;
    top: 0px;
}
.adv_panel_Table_td{
    height:624px;
    width:220px  !important;
    /**/
    vertical-align: text-top;
    position:fixed;
}

	.controlFeedTable {
		height:660px;
		width:173px;
		
		position:fixed;
		
		background-color:rgba(255,255,255, 0.7);
		
		border:0; 
		align:left;
		
		padding:0;
		margin-top: 0px;
	}
.controlBodyTable {
    height:660px;
    width:735px !important;

    margin-left: 0px !important;
    margin-top: 51px !important;

    background-color:rgba(255,255,255, 0.7);

    border:0; 

    padding:0;
    margin:0;
}
.controlBodyTable_td{
    height:660px;
    width:635px !important;
    vertical-align:top !important;
}
/*
controlBodyTable
controlFeedAdvertise_ManagerTable_div
*/




/*

*/
	.feed_functional_full_table {
		height:650px !important;
		width:553px !important;
		
		background:#FFF;
		/*
		border-right: 1px solid #ccc;
		border-left: 1px solid #ccc;
		border-top: 1px solid #ccc;
		border-bottom: 1px solid #ccc;
		*/
		text-align:center;
		vertical-align:top;
		
		
		border:hidden;
		border-spacing: 0px;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		
	} 
	.feed_functional_full_table_td {
		width:553px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.feed_functional_calendar_table_td_r1 {
		height:0px !important;
		width:553px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
		background-color:#666;
	} 
	
	
	/* profile_view_control  
	

	*/
	.feed_functional_calendar_table_td_r3 {
		height:100px !important;
		width:553px;
		border:hidden;
		border-spacing: 0px;
		vertical-align: top;
	} 
	.feed_functional_calendar_table_td_r3_1 {
		height:100px !important;
		width:320px !important;
		border:hidden;
		border-spacing: 0px;
		vertical-align:top;
	} 
	.feed_functional_calendar_table_td_r3_2 {
		height:100px !important;
		width:400px !important;
		border:hidden;
		border-spacing: 0px;
	} 

.feed_profile_view_control{
    height:40px !important;
    width: 160px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:transparent;
    text-align:left !important;
    vertical-align:middle !important;
}
.feed_profile_view_control_cell{
    height:40px !important;
    width: 40px !important;

    margin-left: 2px;

    background-color:transparent;

    display:inline-block;
}

.feed_profile_add_control{
	height:40px !important;
	width: 160px !important;
	line-height: 40px;
	position: absolute;

	display: inline-block;

	margin-left: 10px;
	/*
	event_create_active
	*/
	background-color:transparent;
	text-align:left !important;
	vertical-align:middle !important;
}
/*


*/
.feed_view_profile_temp{
	height:40px !important;
	width: 40px !important;

	text-align:center !important;
	vertical-align:middle !important;

	border:hidden;

	background: url(../images/buttons/butt_template.png) #EFEFEF;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:50% 45%;

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_view_profile_active{
	height:40px !important;
	width: 40px !important;

	text-align:center !important;
	vertical-align:middle !important;

	border:hidden;

	background: url(../images/buttons/butt_template.png) #0C0;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:50% 45%;

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

	.fixed-header {
	  	position: fixed;
		z-index: 999;
	  /*top:0; left:0;8/
	  /*width: 100%; */
	}
	.absolute-header {
	  	position:fixed;
		z-index: 999;
		margin-top: 50px;
		
	  /*
		
		display: none;
		margin-left: auto;
		
		top:0; 
		left:0;8/
	  /*width: 100%; */
	}
/********//*	PROFILE_FEED_STYLE_MAIN	*/
	.temp_feed_time_frame {
		/*
		height:35px !important;
		*/
		height:55px !important;
		width:540px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top:5px;
		/**/
		margin-left:2px;
		
		background-color:rgba(255,65,89, 0.7);
		
		color:#888888;
		
		font-size: 0.80em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:text-top;
		
		text-decoration:none;
		text-indent:2px;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 	




	.temp_feed_time_future_frame {
		/*
		height:35px !important;
		*/
		height:55px !important;
		width:540px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top:5px;
		/**/
		margin-left:2px;
		
		background-color:rgba(15,56,200, 0.7);
		
		color:#FFF;
		
		font-size: 0.80em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:text-top;
		
		text-decoration:none;
		text-indent:2px;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 	
	.temp_feed_time_recent_frame {
		/*
		height:35px !important;
		*/
		height:55px !important;
		width:540px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top:5px;
		/**/
		margin-left:2px;
		
		background-color:rgba(15,95,111, 0.7);
		
		color:#FFF;
		
		font-size: 0.80em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:text-top;
		
		text-decoration:none;
		text-indent:2px;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 	
	.temp_feed_time_history_frame {
		/*
		height:35px !important;
		*/
		height:55px !important;
		width:540px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top:5px;
		/**/
		margin-left:2px;
		
		background-color:rgba(255,134,3, 0.7);
		
		color:#FFF;
		
		font-size: 0.80em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:text-top;
		
		text-decoration:none;
		text-indent:2px;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 	
	.temp_feed_time_active_button {
		height:40px !important;
		width:40px;
		
		/**/
		position: absolute;
		
		
		border-spacing: 0px;
		border-radius: 45px;
		border:1px solid #CCC;
		
		
		
		margin-top:-35px;
		/*
		display: inline-block;
		*/
		margin-left:500px;
		
		background-color:rgba(9,65,89, 0.7);
		
		color:white;
		
		font-size: 1.20em;
		font-weight: 600;
		
		text-align:center;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 	

	.temp_feed_list_time_frame {
		height:15px !important;
		width:540px;
		
		border-spacing: 0px;
		
		border-top:hidden;
		border-bottom:1px dashed;
		border-left:hidden;
		border-right:hidden;
		/*
		1px solid #CCC
		*/
		
		display: inline-block;
		
		margin-top:5px;
		/**/
		margin-left:2px;
		
		background-color:rgba(255,255,255, 0.7);
		
		color:#888888;
		
		font-size: 0.80em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:2px;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 	
	/********//*	PROFILE_FEED_FRAME_OPTIONS	*/
/*************************************/
/************	FRAME 4 	**********/
/*
height:366px !important;
width:532px;

HEADER = W/H =2.79
*/
	.temp_feed_2rows_header {
		height:191px !important;
		width:532px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top:10px;
		margin-left:-536px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background-image: url(../images/sys_option_default/sys_event_default_header.jpg) rgba(255,255,255, 0.2);
		background-size: 532px 191px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
		
	.temp_feed_2rows_date {
		height:120px !important;
		width:125px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:225px;
		margin-left:-520px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 1.10em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		
		background-color: rgba(255,255,255, 0.1);
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_2rows_start_date {
		height:30px !important;
		width:184px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:202px;
		margin-left:-375px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.80em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:20px;
		/*
		background-color: rgba(255,255,255, 0.1);
		*/
   		background: url(../images/buttons/sys_calendar_active.png) rgba(255,255,255, 0.2);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:3% 45%;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_2rows_end_date {
		height:30px !important;
		width:184px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:202px;
		margin-left:-191px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.80em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:20px;
		/*
		background-color: rgba(255,255,255, 0.1);
		*/
   		background: url(../images/buttons/sys_calendar_error.png) rgba(255,255,255, 0.2);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:3% 45%;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.temp_feed_2rows_panel1 {
		height:140px !important;
		width:184px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:233px;
		margin-left:-375px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.80em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:20px;
		/*
		background-color: rgba(255,255,255, 0.1);
		*/
   		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
		background-size: 160px 120px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_2rows_panel2 {
		height:140px !important;
		width:184px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:233px;
		margin-left:-191px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.80em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:20px;
		/*
		background-color: rgba(255,255,255, 0.1);
		*/
   		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
		background-size: 160px 120px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.temp_feed_2rows_environment_physical {
		height:172px !important;
		width:160px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top: 202px;
		margin-left:-536px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/sys_physical_location.png) rgba(255,255,255, 0.1);
		background-size: 140px 140px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.temp_feed_2rows_environment_virtual {
		height:172px !important;
		width:160px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top: 202px;
		margin-left:-536px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/sys_virtual_location.png) rgba(255,255,255, 0.1);
		background-size: 140px 140px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.temp_feed_2rows_environment_global {
		height:172px !important;
		width:160px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top: 202px;
		margin-left:-536px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/sys_global_location.png) rgba(255,255,255, 0.1);
		background-size: 140px 140px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.temp_feed_2rows_title {
		height:50px !important;
		width:400px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top:120px;
		margin-left:-424px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 1.10em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		
		background-color: rgba(255,255,255, 0.7);
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.temp_feed_2rows_compatibility {
		height:75px !important;
		width:75px;
		
		border-spacing: 0px;
		border:4px solid rgba(54,89,255, 0.8);
		border-radius: 15px;
		
		margin-top: 300px;
		margin-left:-534px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 1.50em;
		font-weight: 700;
		
		text-align:center;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		/*
		background-color: rgba(255,255,255, 0.2);
		*/
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
		background-size: 70px 70px;
   		background-repeat: no-repeat;
		background-position: center;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_2rows_attending_default {
		height:20px !important;
		width:80px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-110px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_2rows_attending_active {
		height:20px !important;
		width:80px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-110px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(0, 255, 0, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_2rows_activity_live {
		height:20px !important;
		width:20px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-26px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(0,128,0, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_2rows_activity_pending {
		height:20px !important;
		width:20px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-26px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,1, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_2rows_activity_completed {
		height:20px !important;
		width:20px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-26px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 


/*************************************/
/************	FRAME 2 	**********/
/*
height:178px !important;
width:262px;

HEADER = W/H =2.79
*/
	.temp_feed_double_header {
		height:94px !important;
		width:262px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top:10px;
		margin-left:-266px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background-image: url(../images/sys_option_default/sys_event_default_header.jpg) rgba(255,255,255, 0.2);
		background-size: 262px 94px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.temp_feed_double_date {
		height:70px !important;
		width:60px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:110px;
		margin-left:-257px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.90em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		
		background-color: rgba(255,255,255, 0.1);
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_start_date {
		height:30px !important;
		width:92px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:105px;
		margin-left:-187px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.45em;
		font-weight: 400;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:5px;
		
		/*
		background-color: rgba(255,255,255, 0.1);
		*/
   		background: url(../images/buttons/sys_calendar_active.png) rgba(255,255,255, 0.2);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:3% 45%;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_end_date {
		height:30px !important;
		width:92px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:105px;
		margin-left:-97px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.45em;
		font-weight: 400;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:5px;
		
		/*
		background-color: rgba(255,255,255, 0.1);
		*/
   		background: url(../images/buttons/sys_calendar_error.png) rgba(255,255,255, 0.2);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:3% 45%;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.temp_feed_double_panel1 {
		height:51px !important;
		width:92px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:136px;
		margin-left:-187px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.80em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:20px;
		/*
		background-color: rgba(255,255,255, 0.1);
		*/
   		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
		background-size: 80px 40px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_panel2 {
		height:51px !important;
		width:92px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:136px;
		margin-left:-97px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.80em;
		font-weight: 500;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:20px;
		/*
		background-color: rgba(255,255,255, 0.1);
		*/
   		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
		background-size: 80px 40px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.temp_feed_double_environment_physical {
		height:82px !important;
		width:78px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top: 105px;
		margin-left:-266px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/sys_physical_location.png) rgba(255,255,255, 0.1);
		background-size: 60px 60px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_environment_virtual {
		height:82px !important;
		width:78px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top: 105px;
		margin-left:-266px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/sys_virtual_location.png) rgba(255,255,255, 0.1);
		background-size: 60px 60px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_environment_global {
		height:82px !important;
		width:78px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top: 105px;
		margin-left:-266px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/sys_global_location.png) rgba(255,255,255, 0.1);
		background-size: 60px 60px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_title {
		height:40px !important;
		width:190px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top:50px;
		margin-left:-200px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.80em;
		font-weight: 400;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		
		background-color: rgba(255,255,255, 0.7);
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	

	.temp_feed_double_compatibility {
		height:55px !important;
		width:55px;
		
		border-spacing: 0px;
		border:4px solid rgba(54,89,255, 0.8);
		border-radius: 15px;
		
		margin-top:132px;
		margin-left:-265px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 1.00em;
		font-weight: 700;
		
		text-align:center;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		/*
		background-color: rgba(255,255,255, 0.2);
		*/
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
		background-size: 50px 50px;
   		background-repeat: no-repeat;
		background-position: center;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_attending_default {
		height:20px !important;
		width:80px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-110px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_attending_active {
		height:20px !important;
		width:80px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-110px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(0, 255, 0, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_activity_live {
		height:20px !important;
		width:20px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-26px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(0,128,0, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_activity_pending {
		height:20px !important;
		width:20px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-26px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,1, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_activity_completed {
		height:20px !important;
		width:20px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-26px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 




/*************************************/
/************	FRAME 1 	**********/
/*
height:178px !important;
width:126px;

HEADER = W/H =2.79
*/
	.temp_feed_single_header {
		height:45px !important;
		width:126px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top:10px;
		margin-left:-131px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background-image: url(../images/sys_option_default/sys_event_default_header.jpg) rgba(255,255,255, 0.2);
		background-size: 126px 45px;
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.temp_feed_single_date {
		height:20px !important;
		width:70px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:138px;
		margin-left:-75px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.50em;
		font-weight: 300;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		
		/*
		background-color: rgba(255,255,255, 0.1);
		*/
   		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.6);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:5% 45%;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_single_start_date {
		height:26px !important;
		width:70px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:136px;
		margin-left:-75px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.40em;
		font-weight: 300;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		
		/*
		background-color: rgba(255,255,255, 0.1);
		*/
   		background: url(../images/buttons/sys_calendar_active.png) rgba(255,255,255, 0.2);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:3% 45%;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_single_end_date {
		height:26px !important;
		width:70px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top:162px;
		margin-left:-75px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.40em;
		font-weight: 300;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		
		/*
		background-color: rgba(255,255,255, 0.1);
		*/
   		background: url(../images/buttons/sys_calendar_error.png) rgba(255,255,255, 0.2);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position:3% 45%;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_single_panel1 {
		height:0px !important;
		width:0px;
		
		display:none;
		visibility: hidden;
		
	} 
	.temp_feed_single_panel2 {
		height:0px !important;
		width:0px;
		
		display:none;
		visibility: hidden;
	} 
	
	.temp_feed_single_environment_physical {
		height:128px !important;
		width:126px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top: 57px;
		margin-left:-131px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/sys_physical_location.png) rgba(255,255,255, 0.1);
		background-size: 110px 110px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.temp_feed_single_environment_virtual {
		height:128px !important;
		width:126px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top: 57px;
		margin-left:-131px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/sys_virtual_location.png) rgba(255,255,255, 0.1);
		background-size: 110px 110px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.temp_feed_single_environment_global {
		height:128px !important;
		width:126px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top: 57px;
		margin-left:-131px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/sys_global_location.png) rgba(255,255,255, 0.1);
		background-size: 110px 110px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_single_title {
		height:60px !important;
		width:124px;
		
		border-spacing: 0px;
		border:hidden;
		
		margin-top:75px;
		margin-left:-129px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.90em;
		font-weight: 400;
		
		text-align:left;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		
		background-color: rgba(255,255,255, 0.2);
   		background-repeat: no-repeat;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.temp_feed_single_compatibility {
		height:50px !important;
		width:50px;
		
		border-spacing: 0px;
		border:4px solid rgba(54,89,255, 0.8);
		border-radius: 15px;
		
		margin-top:137px;
		margin-left:-127px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		color:#000;
		
		font-size: 0.90em;
		font-weight: 700;
		
		text-align:center;
		vertical-align:middle;
		
		text-decoration:none;
		text-indent:0px;
		/*
		background-color: rgba(255,255,255, 0.2);
		*/
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
		background-size: 45px 45px;
   		background-repeat: no-repeat;
		background-position: center;
		
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_single_attending_default {
		height:20px !important;
		width:80px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-110px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_single_attending_active {
		height:20px !important;
		width:80px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-110px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(0, 255, 0, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_single_activity_live {
		height:20px !important;
		width:20px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-26px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(0,128,0, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_single_activity_pending {
		height:20px !important;
		width:20px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-26px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(255,255,1, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_single_activity_completed {
		height:20px !important;
		width:20px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		margin-top: 12px;
		margin-left:-26px;
		/**/
		position: absolute;
		
		display: inline-block;
		
		background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
		background-size: 15px 15px;
   		background-repeat: no-repeat;
		background-position: center;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 


/************************************default_pagination_listview_02*/
/********//*	RECOMMENDATIONS_FRAME_MANAGER	*/

.recommendation_container_td {
	height: 610px;
	width: 950px !important;

	position:relative;
	background-color:rgba(255,255,255, 0.3);
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-top: hidden;
	border-left: 1px solid #CCC;
	border-right: 1px solid #CCC;
	border-bottom: 1px solid #CCC;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_container_top_bar_full {
	height:40px !important;
	width:948px !important;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.1);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.feed_container_shares_panel1 {
	height: 570px;
	width: 150px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-top: hidden;
	border:hidden;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_shares_panel2 {
	height: 405px;
	width: 575px !important;
	position:relative;
	
	background-color:rgba(25,255,255, 0.3);

	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border:hidden;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_shares_panel3 {
	height: 405px;
	width: 225px !important;
	position:relative;
	
	background-color:rgba(255,255,3, 0.3);

	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border:hidden;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/*************************************/
/*

*/

.feed_container_shares_panel1_tr1 {
	height: 109px;
	width: 150px !important;
	position:relative;
	
	background-color:rgba(255,255,255, 0.3);

	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border:hidden;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_shares_panel1_tr2 {
	height: 109px;
	width: 150px !important;
	position:relative;
	
	background-color:rgba(255,255,255, 0.3);

	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border:hidden;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_shares_panel1_tr3 {
	height: 109px;
	width: 150px !important;
	position:relative;
	
	background-color:rgba(255,255,255, 0.3);

	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border:hidden;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.feed_container_shares_panel1_tr4 {
	height: 109px;
	width: 150px !important;
	position:relative;
	
	background-color:rgba(255,255,255, 0.3);

	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border:hidden;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_manager_menu1_default {
	height:113px !important;
	width:140px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:5px;
	margin-left:5px;

	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.0);
	background-size: 120px 93px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_manager_menu1_active {
	height:113px !important;
	width:140px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:5px;
	margin-left:5px;

	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 120px 93px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_manager_menu2_default {
	height:113px !important;
	width:140px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:5px;
	margin-left:5px;

	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 120px 93px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_manager_menu2_active {
	height:113px !important;
	width:140px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:5px;
	margin-left:5px;

	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 120px 93px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_manager_menu3_default {
	height:113px !important;
	width:140px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:5px;
	margin-left:5px;

	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 120px 93px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_manager_menu3_active {
	height:113px !important;
	width:140px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:5px;
	margin-left:5px;

	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 120px 93px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_manager_menu4_default {
	height:113px !important;
	width:140px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:5px;
	margin-left:5px;

	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 120px 93px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_manager_menu4_active {
	height:113px !important;
	width:140px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:5px;
	margin-left:5px;

	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 120px 93px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
/************************************event_media_view_option_media_full stream_container_menu_td_video_media_div			*/
.stream_container_td {
	height: 400px;
	width: 950px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);

	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-top: hidden;
	border-left: 1px solid rgba(239,239,239, 0.5);
	border-right: 1px solid rgba(239,239,239, 0.5);
	border-bottom: 1px solid rgba(239,239,239, 0.5);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.stream_container_td1 {
	height: 400px;
	width: 645px !important;
	position:relative;
	border: 1px solid rgba(209,209,209, 0.2)  !important;

	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.stream_container_video_div {
	height: 400px;
	width: 645px !important;
	position:absolute;
	border: 2px solid rgba(209,209,209, 0.2)  !important;
	

	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.stream_container_td2 {
	height: 400px;
	width: 300px !important;
	position:relative;
	border: 1px solid rgba(209,209,209, 0.2)  !important;
	

	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,47, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.stream_container_interaction_td {
    height:166px !important;
    width: 950px !important;
	border: 1px solid rgba(239,239,239, 0.5);
    
    vertical-align:top;
	text-align:center;
    background-color:rgba(47,255,255, 0.3);
}
/*************************************/
/*
	
*/
.recommendation_panel_td {
	height: 570px;
	width: 800px !important;

	position:relative;
	background-color:rgba(255,255,255, 0.3);
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-top: hidden;
	border-left: 1px solid #CCC;
	border-right: hidden;
	border-bottom: hidden;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_td1{
    height:40px !important;
    width: 120px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:transparent;
    text-align:left !important;
    vertical-align:middle !important;
}
.recommendation_td2_menu{
    height:40px !important;
    width: 413px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.3);
    text-align:left !important;
    vertical-align:middle !important;
}
.recommendation_td2_space1{
    height:40px !important;
    width: 50px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.3);
    text-align:left !important;
    vertical-align:middle !important;
}
.recommendation_td2_space2{
    height:40px !important;
    width: 150px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.3);
    text-align:left !important;
    vertical-align:middle !important;
}
.recommendation_td2_menu2{
    height:40px !important;
    width: 60px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.3);
    text-align:left !important;
    vertical-align:middle !important;
}
.recommendation_td2_menu3{
    height:40px !important;
    width: 60px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.3);
    text-align:left !important;
    vertical-align:middle !important;
}
.recommendation_td2_menu4{
    height:40px !important;
    width: 60px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.3);
    text-align:left !important;
    vertical-align:middle !important;
}
.recommendation_td3{
    height:40px !important;
    width: 100px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.3);
    text-align:left !important;
    vertical-align:middle !important;
}
.recommendation_td4{
    height:40px !important;
    width: 100px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.3);
    text-align:left !important;
    vertical-align:middle !important;
}
.recommendation_td5{
    height:40px !important;
    width: 100px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.3);
    text-align:left !important;
    vertical-align:middle !important;
}
.recommendation_td6{
    height:40px !important;
    width: 100px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 2px;
    background-color:rgba(255,255,255, 0.3);
    text-align:left !important;
    vertical-align:middle !important;
}
/*************************************/
.recommendation_metrics_dualviewtop{
	height: 200px;
	width: 798px !important;
	position:relative;
	margin-top: 0px;
	border: thin solid rgba(204,204,204, 0.3);
	padding:0;
	vertical-align:top;
	text-align:left;
	background-color:rgba(100,255,100, 0.3);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_metrics_dualview01 {
	height: 368px;
	width: 399px !important;
	position:relative;
	margin: 0 auto;
	margin-top: 0px;
	border: thin solid rgba(204,204,204, 0.3);
	padding:0;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,45, 0.3);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_metrics_dualview02 {
	height: 368px;
	width: 399px !important;
	position:relative;
	margin-top: 0px;
	border-top: hidden;
	border: thin solid rgba(204,204,204, 0.3);
	padding:0;
	vertical-align:top;
	text-align:left;
	background-color:rgba(45,255,255, 0.3);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_metrics_singleview01 {
	height: 568px;
	width: 798px !important;
	position:relative;
	margin: 0 auto;
	margin-top: 0px;
	border: thin solid rgba(204,204,204, 0.3);
	padding:0;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,45,255, 0.3);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************************/
.recommendation_map_view {
	height: 568px;
	width: 798px !important;
	position:relative;
	margin-top: 0px;
	border: thin solid rgba(204,204,204, 0.3);
	padding:0;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.3);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_map {
	height: 568px;
	width: 798px !important;
	position:relative;
	margin-top: 0px;
	border: hidden;
	padding:0;
	vertical-align:top;
	text-align:left;
	background-color:rgba(233,233,233, 0.6);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
.map_key_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:510px;

	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
*/
.map_key_recommendation_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:640px;

	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.map_key_recommendation_search {
	z-index: 100;
	height:20px !important;
	width:450px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:10px;

	border-spacing: 0px;
	border:hidden;
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.0);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
/*********************************/
.recommendation_menu_td {
	height: 40px;
	width: 550px !important;
	background-color:rgba(239,239,239, 0.3);
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border-top: 1px solid #CCC;
	border-left: hidden;
	border-right: hidden;
	border-bottom: 1px solid #CCC;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_view_control{
    height:40px !important;
    width: 10px !important;
    line-height: 40px;
    display: inline-block;
    margin-left: 0px;
    background-color:transparent;
    text-align:left !important;
    vertical-align:middle !important;
}
.recommendation_results_container_td {
	height: 492px;
	width: 799px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);
	margin: 0 auto;
	margin-top: 0px;
	vertical-align:top;
	text-align:left;
	border: hidden;
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_results_container_top_bar {
	height:40px !important;
	width:799px !important;
	border-spacing: 0px;
	border:hidden;
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.recommendation_results_container_top_bar_full {
	height:40px !important;
	width:540px !important;
	
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.1);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	background-color: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/



/*************************************/
.recommendation_manager_menu1_default {
	height:40px !important;
	width:100px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/business_details/business_global_metrics_icon.png) rgba(239,239,239, 0.0);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_menu1_active {
	height:40px !important;
	width:100px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/business_details/business_global_metrics_icon.png) rgba(10, 245, 92, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_menu2_default {
	height:40px !important;
	width:100px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/buttons/sys_pro_default.png) rgba(239,239,239, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_menu2_active {
	height:40px !important;
	width:100px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/buttons/sys_pro_default.png) rgba(10, 245, 92, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_menu3_default {
	height:40px !important;
	width:100px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/sys_option_default/default_building.png) rgba(239,239,239, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_menu3_active {
	height:40px !important;
	width:100px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/sys_option_default/default_building.png) rgba(10, 245, 92, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_menu4_default {
	height:40px !important;
	width:100px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/sys_option_default/sys_pin_default.png) rgba(239,239,239, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_menu4_active {
	height:40px !important;
	width:100px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/sys_option_default/sys_pin_default.png) rgba(10, 245, 92, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
.recommendation_manager_logo1_default {
	height:40px !important;
	width:60px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.0);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_logo1_active {
	height:40px !important;
	width:60px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_logo2_default {
	height:40px !important;
	width:60px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/business_details/default_lettermark_logo.png) rgba(239,239,239, 0.7);
	background-size: 50px 30px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_logo2_active {
	height:40px !important;
	width:60px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/business_details/default_lettermark_logo.png) rgba(10, 245, 92, 0.7);
	background-size: 50px 30px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_logo3_default {
	height:40px !important;
	width:60px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/business_details/default_brandmark_logo.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_manager_logo3_active {
	height:40px !important;
	width:60px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;

	background: url(../images/business_details/default_brandmark_logo.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
.recommendation_results_frame_absolute {
	height:108px !important;
	width:790px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:3px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_results_left_frame {
	height:108px !important;
	width:200px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.9);
	margin-top:0px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_results_center_frame {
	height:108px !important;
	width:280px !important;
	position: absolute !important;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(255,255,255, 0.3);
	margin-top:0px;
	margin-left:205px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_results_right_frame {
	height:108px !important;
	width:235px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(255,255,255, 0.7);
	margin-top:0px;
	margin-left:485px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
.recommendation_results_test_center {
	height:108px !important;
	width:350px !important;
	position: absolute !important;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(88,233,233, 0.9);
	margin-top:0px;
	margin-left:205px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_results_test_right {
	height:108px !important;
	width:197px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,24, 0.9);
	margin-top:0px;
	margin-left:600px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
*/
.recommendation_results_frame_option_1 {
	z-index: 1;
    height:107px !important;
    width:107px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:1px;
	margin-left:1px;
    background: url(../images/buttons/clear_img.png);
    background-size: 107px 107px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.recommendation_results_frame_letter {
	z-index: 1;
    height:107px !important;
    width:107px;
	position: absolute;
    border-spacing: 0px;
    /*
	select_letter_options1
	top:4px;
	*/
	margin-top:1px;
	margin-left:1px;
    background: url(../images/buttons/clear_img.png);
    background-size: 133px 50px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.recommendation_results_frame_brand {
	z-index: 1;
    height:107px !important;
    width:107px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:1px;
	margin-left:1px;
    background: url(../images/buttons/clear_img.png);
    background-size: 95px 95px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.recommendation_results_frame_option_2 {
	height:80px !important;
	width:85px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:110px;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    background-size: 80px 75px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	ertical-align:middle;
	text-align:center;
	text-decoration:none;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	
    font-size: 2.25em  !important;
    font-weight: 700;
	text-indent: 0px;
}
.recommendation_results_frame_option_3{
	z-index: 10;
	height:25px !important;
	width:200px;
	line-height: 25px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:77px;
	margin-left:5px;
	
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.25em  !important;
    font-weight: 500;
    text-align:left;
	text-indent: 15px;
    vertical-align:top;
    text-decoration:none;
}

.recommendation_standard_frame_absolute {
	height:108px !important;
	width:205px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:0px;
	margin-left:0px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
/*

*/
.recommendation_frame_action_top_positive {
	z-index: 10;
	height:53px !important;
	width:70px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:720px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.recommendation_frame_action_top_neutral {
	z-index: 10;
	height:53px !important;
	width:70px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:720px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.recommendation_frame_action_bottom_negative {
	z-index: 10;
	height:53px !important;
	width:70px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:56px;
	margin-left:720px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.recommendation_frame_action_left_positive {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_frame_action_left_negative {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:265px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(252, 71, 71, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_frame_action_left_neutral {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:265px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(255, 255, 128, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_frame_action_right_positive {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:350px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_frame_action_right_negative {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:350px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(252, 71, 71, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_frame_action_right_neutral {
	z-index: 10;
	height:35px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:350px;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(255, 255, 128, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
.recommendation_frame_action_logo {
	z-index: 10;
	height:35px !important;
	width:100px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(203,203,203, 0.9);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:middle;
	text-align:center;
	
	color:#000;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(233,233,233, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.recommendation_frame_action_slot {
	z-index: 10;
	height:71px !important;
	width:100px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(203,203,203, 0.9);
	display: inline-block;
	margin-top:37px;
	margin-left:1px;
	vertical-align:middle;
	text-align:center;
	
	color:#000;
    font-size: 1.40em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(233,233,233, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_frame_action_duration_label {
	/*
		standard_frame_action_right_neutral
		.recommendation_results_center_frame
	*/
	z-index: 10;
	height:35px !important;
	width:75px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(203,203,203, 0.9);
	display: inline-block;
	margin-top:1px;
	margin-left:102px;
	vertical-align:middle;
	text-align:left;
	
	color:#000;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(233,233,233, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_frame_action_duration {
	/*
	
		
	*/
	z-index: 10;
	height:35px !important;
	width:100px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(203,203,203, 0.9);
	display: inline-block;
	margin-top:1px;
	margin-left:178px;
	vertical-align:middle;
	text-align:left;
	
	color:#000;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(233,233,233, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_frame_action_request_label {
	z-index: 10;
	height:35px !important;
	width:75px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(203,203,203, 0.9);
	display: inline-block;
	margin-top:37px;
	margin-left:102px;
	vertical-align:middle;
	text-align:right;
	
	color:#000;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(233,233,233, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_frame_action_request_cost {
	z-index: 10;
	height:35px !important;
	width:101px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(203,203,203, 0.9);
	display: inline-block;
	margin-top:37px;
	margin-left:177px;
	vertical-align:middle;
	text-align:center;
	
	color:#000;
    font-size: 0.90em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(0,0,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_frame_action_sales_label {
	z-index: 10;
	height:35px !important;
	width:75px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(203,203,203, 0.9);
	display: inline-block;
	margin-top:73px;
	margin-left:102px;
	vertical-align:middle;
	text-align:right;
	
	color:#000;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(233,233,233, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_frame_action_sales {
	z-index: 10;
	height:35px !important;
	width:101px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(203,203,203, 0.9);
	display: inline-block;
	margin-top:73px;
	margin-left:177px;
	vertical-align:middle;
	text-align:center;
	
	color:#000;
    font-size: 0.90em  !important;
    font-weight: 500;
	text-indent: 0px;
	
	background-color:rgba(10, 245, 92, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
.recommendation_select_options1_1 {
	height:35px !important;
	width:77px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.9);
	margin-top:1px;
	margin-left:280px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_options1_2 {
	height:35px !important;
	width:77px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.4);
	margin-top:1px;
	margin-left:358px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_options1_3 {
	height:35px !important;
	width:77px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.9);
	margin-top:1px;
	margin-left:436px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_options2_1 {
	height:35px !important;
	width:77px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.3);
	margin-top:37px;
	margin-left:280px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_options2_2 {
	height:35px !important;
	width:77px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color:rgba(233,233,233, 0.9);
	margin-top:37px;
	margin-left:358px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_options2_3 {
	height:35px !important;
	width:77px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.3);
	margin-top:37px;
	margin-left:436px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_options3_1 {
	height:35px !important;
	width:77px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.9);
	margin-top:73px;
	margin-left:280px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_options3_2 {
	height:35px !important;
	width:77px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color:rgba(233,233,233, 0.3);
	margin-top:73px;
	margin-left:358px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_options3_3 {
	height:35px !important;
	width:77px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color:rgba(233,233,233, 0.9);
	margin-top:73px;
	margin-left:436px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_active {
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color:rgba(10, 245, 92, 0.7);
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#000;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
recommendation_frame_action_cost_label

*/
/************************************/
.recommendation_select_1_1 {
	height:35px !important;
	width:82px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_1_2 {
	height:35px !important;
	width:82px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.4);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_1_3 {
	height:35px !important;
	width:82px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_2_1 {
	height:35px !important;
	width:82px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.3);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_2_2 {
	height:35px !important;
	width:82px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color:rgba(233,233,233, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_2_3 {
	height:35px !important;
	width:82px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.3);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_3_1 {
	height:35px !important;
	width:82px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color: rgba(233,233,233, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_3_2 {
	height:35px !important;
	width:82px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color:rgba(233,233,233, 0.3);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_3_3 {
	height:35px !important;
	width:82px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color:rgba(233,233,233, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#A3A3A3;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_active {
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.8);
	background-color:rgba(10, 245, 92, 0.7);
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#000;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_menu_td_01 {
	height: 108px;
	width: 323px !important;
	position:relative;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align:top;
	text-align:left;
	border:1px solid rgba(233,233,233, 0.0);
	background-color:rgba(255,255,255, 1.0);
}
.select_menu_td_02 {
	height: 108px;
	width: 143px !important;
	position:relative;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align:top;
	text-align:left;
	border:1px solid rgba(233,233,233, 0.0);
	background-color:rgba(255,255,255, 1.0);
}
.recommendation_select_title { 
	z-index: 10;
	height:35px !important;
	width:320px;
	/*
	position: absolute;
	select_menu_item02
	select_container_td2
	*/
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	/*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1em;
    font-weight: 600;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_data_label { 
	z-index: 10;
	height:30px !important;
	width:90px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#000;
   
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_data { 
	z-index: 10;
	height:41px !important;
	width:90px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 1.25em;
    color:#000;

    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_cost_label { 
	z-index: 10;
	height:30px !important;
	width:115px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	/*
	recommendation_select_3_3
	
	*/
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_cost { 
	z-index: 10;
	height:41px !important;
	width:115px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(0,0,255, 0.3);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_sales_cost { 
	/*
		recommendation_frame_action_sales
	*/
	z-index: 10;
	height:41px !important;
	width:115px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(10, 245, 92, 0.3);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_type_label { 
	z-index: 10;
	height:35px !important;
	width:60px;
	/*
	position: absolute;
	select_menu_item02
	select_container_td2
	*/
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	font-size: 0.70em;
    color:#000;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_type { 
	z-index: 10;
	height:35px !important;
	width:80px;
	/*
	position: absolute;
	select_menu_item02
	select_container_td2
	*/
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 0.9);
	margin-top:0px;
	margin-left:0px;
	vertical-align:middle;
	text-align:left;
	font-size: 0.70em;
    color:#000;
    font-weight: bold;
    text-decoration: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_status_pending { 
	z-index: 10;
	height:71px !important;
	width:140px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 0.9);
	margin-top:1px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#000;
    font-weight: bold;
    text-decoration: none;
	background: url(../images/buttons/butt_template.png);
    background-size: 70px 50px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_status_activate { 
	z-index: 10;
	height:71px !important;
	width:140px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(10, 245, 92, 0.7);
	margin-top:1px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#000;
    font-weight: bold;
    text-decoration: none;
	background: url(../images/buttons/butt_template.png);
    background-size: 70px 50px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.recommendation_select_status_active { 
	z-index: 10;
	height:71px !important;
	width:140px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.5);
	background-color:rgba(1010, 245, 92, 0.7);
	margin-top:1px;
	margin-left:0px;
	vertical-align:middle;
	text-align:center;
	font-size: 0.70em;
    color:#000;
    font-weight: bold;
    text-decoration: none;
	background: url(../images/buttons/butt_template.png);
    background-size: 70px 50px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/*

*/
.select_menu_item01 {
	height: 100px;
	width: 238px !important;
	position:relative;

	margin-top: 5px;
	margin-left: 1px;
	
	vertical-align:middle;
	text-align:center;
	
	border:1px solid rgba(233,233,233, 0.5);
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.0);
	background-size: 150px 70px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
select_letter_menu_item
select_letter_options1

*/
.select_letter_menu_item{
	height: 108px;
	width: 238px !important;
	position:relative;

	margin-top: 0px;
	margin-left: 1px;
	
	vertical-align:middle;
	text-align:center;
	
	border:1px solid rgba(233,233,233, 0.5);
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.0);
	background-size: 150px 63px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_brand_menu_item{
	height: 108px;
	width: 238px !important;
	position:relative;

	margin-top: 0px;
	margin-left: 1px;
	
	vertical-align:middle;
	text-align:center;
	
	border:1px solid rgba(233,233,233, 0.5);
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.0);
	background-size: 95px 95px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.select_menu_item02 {
	height: 108px;
	width: 200px !important;
	position:relative;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align:middle;
	text-align:center;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.0);
	background-size: 300px 90px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.select_menu_item03 {
	height: 100px;
	width: 262px !important;
	position:relative;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align:middle;
	text-align:center;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.0);
	background-size: 300px 90px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
 delete
*/
.select_menu_item04 {
	z-index: 10;
	height:43px !important;
	width:43px !important;
	display:inline-block;
	position:absolute;
	margin-left: 190px;
	margin-top: 5px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(255,255,255, 0.7);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/************************************/
/*

*/
.select_feed_container_td {
	height: 398px;
	width: 950px !important;
	
	position:relative;
	
	background-color:rgba(255,255,255, 0.3);
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left !important;
	
	border-top: hidden;
	border-left: hidden;
	border-right: hidden;
	border-bottom: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}


/*************************************/



/*************************************/

.sys_admin_menu_div {
	z-index: 100;
	height: 60px;
	width: 60px !important;
	
	position:absolute;
	margin-top: 5px;
	margin-left: 875px !important;

	background-color:rgba(255,255,255, 0.0);
	
	border:thin solid rgba(204,204,204, 0.7);
	
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.sys_admin_menu_default {
	height:50px !important;
	width:50px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:5px;
	margin-left:5px;

	background: url(../images/buttons/sys_settings_button_large.png) rgba(239,239,239, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.sys_admin_menu_active{
	height:50px !important;
	width:50px !important;

	border-spacing: 0px;
	border: thin solid rgba(204,204,204, 0.7);
	position:absolute;
	
	margin-top:5px;
	margin-left:5px;

	background: url(../images/buttons/sys_settings_button_large.png) rgba(10, 245, 92, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
/********//*	PROFILE_FEED_FRAME_SETTINGS	*/
/*
feed_container

*/
.event_personal_reporting_active {
	z-index: 2000;
	height:14px !important;
	width:14px !important;

	border-spacing: 0px;
	border:hidden;
	position:absolute;
	
	margin-top:185px;
	margin-left:520px;

	background: url(../images/buttons/butt_template.png) rgba(240,239,136, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_standard_reporting_active {
	z-index: 2000;
	height:14px !important;
	width:14px !important;

	border-spacing: 0px;
	border:hidden;
	position:absolute;
	
	margin-top:185px;
	margin-left:580px;

	background: url(../images/buttons/butt_template.png) rgba(240,239,136, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.event_managed_reporting_active {
	z-index: 2000;
	height:44px !important;
	width:44px !important;

	border-spacing: 0px;
	border:hidden;
	position:absolute;
	
	margin-top:185px;
	margin-left:10px;

	background: url(../images/buttons/butt_template.png) rgba(240,239,136, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_managed_reporting_active {
	z-index: 2000;
	height:14px !important;
	width:14px !important;

	border-spacing: 0px;
	border:hidden;
	position:absolute;
	
	margin-top:112px;
	margin-left:213px;

	background: url(../images/buttons/butt_template.png) rgba(240,239,136, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_list_fill_frame {
	height:130px !important;
	width:60px;

	border-spacing: 0px;

	border:hidden;
	/*
	1px solid #CCC
	*/
	background-color:rgba(239,239,239, 0.7);

	color:#888888;

	font-size: 0.70em;
	font-weight: 500;

	text-decoration:none;
	text-indent:1px;


	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
/*

item_feed_day
*/
.item_feed_list_time_frame {
	height:130px !important;
	width:60px;

	border-spacing: 0px;

	border-top:hidden;
	border-bottom:hidden;
	border-left:1px dashed;
	border-right:1px dashed;
	
	background-color:rgba(255,255,47, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_timeline_month {
	height:25px !important;
	width:60px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	/**/
	position:absolute;
	
	margin-top:0px;
	margin-left:0px;
	
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.05em;
	font-weight: 600;
	background-color: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_timeline_day {
	height:25px !important;
	width:56px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	
	margin-top:0px;
	margin-left:2px;
	
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	
	background-color:rgba(255,255,255, 0.3);
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.results_feed_frame_absolute {
	height:145px !important;
	width:231px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	display: inline-block;
	margin-top:5px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.item_feed_frame {
	border-spacing: 0px;
	border:1px solid #CCC;

	display: inline-block;
	position: relative;

	margin-top:10px;
	margin-left:4.6px;
	/*
		margin-left:4.6px;
	*/
	vertical-align:middle;
	text-align:left;

	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_default_frame {
	height:130px !important;
	width:180px;

	border-spacing: 0px;
	border: hidden;
	
	vertical-align:middle;
	text-align:left;

	background-color:rgba(255,255,255, 0.2);
	color:#888888;
	font-size: 0.85em;
	font-weight: 500;

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_personal_frame {
	height:130px !important;
	width:180px;
	border-spacing: 0px;
	border: hidden;
	background: url(../images/sys_option_default/sys_pro_default.png) rgba(255,255,255, 0.2);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:1% 98%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_standard_frame {
	height:130px !important;
	width:180px;
	border-spacing: 0px;
	border: hidden;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.2);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:1% 98%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_managed_frame {
	height:130px !important;
	width:180px;
	border-spacing: 0px;
	border: hidden;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.2);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:1% 98%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_hidden {
	height:0px !important;
	width:0px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	display: none;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_reporting_active {
	height:14px !important;
	width:14px !important;

	border-spacing: 0px;
	border:hidden;
	position:absolute;
	/*
	margin-top:-15px;
	margin-left:164px;
	*/

	background: url(../images/buttons/butt_template.png) rgba(240,239,136, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_privacy_active {
	/*
	account_summary_public
	item_media_panel_absolute
	*/
	height:20px !important;
	width:20px !important;

	border-spacing: 0px;
	border:hidden;
	position: absolute;
	/*
	margin-top:-129px;
	margin-left:162px;
	*/
	background: url(../images/buttons/account_summary_private.png) rgba(255,255,255, 0.3);
	background-size: 17px 17px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_feed_title {
	height:20px !important;
	width:170px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	/*
	margin-top:-120px;
	margin-left:5px;
	*/
	background-color: rgba(255,255,255, 0.3);
	
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 0.65em;
	font-weight: 500;

	
}
.item_event_activity_active {
	z-index: 0;
	height:20px !important;
	width:35px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	/*
	margin-top:-130px;
	margin-left:144px;
	*/
	background: url(../images/buttons/butt_template.png) rgba(0,240,0, 0.3);
	background-size: 35px 18px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_event_activity_pending {
	z-index: 0;
	height:20px !important;
	width:35px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	/*
	margin-top:-130px;
	margin-left:144px;
	*/
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.3);
	background-size: 35px 18px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.item_event_activity_complete {
	z-index: 0;
	height:20px !important;
	width:35px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	/*
	margin-top:-130px;
	margin-left:144px;
	*/
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.3);
	background-size: 35px 18px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
/*********/
.item_event_view_active {
	z-index: 0;
	height:20px !important;
	width:35px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 53px;
	margin-left: 50px;
	background: url(../images/buttons/butt_template.png) rgba(0,240,0, 0.3);
	background-size: 35px 18px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_event_view_pending {
	z-index: 0;
	height:20px !important;
	width:35px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 53px;
	margin-left: 50px;
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.3);
	background-size: 35px 18px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.item_event_view_complete {
	z-index: 0;
	height:20px !important;
	width:35px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 53px;
	margin-left: 50px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.3);
	background-size: 35px 18px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
/*********/
.item_event_display_active {
	z-index: 0;
	height:78px !important;
	width:25px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 133px;
	/*
	margin-top: -113px;
	margin-left: 175px;
	background: url(../images/buttons/butt_template.png) rgba(0,240,0, 0.3);
	*/
	
	background-color: rgba(0,240,0, 0.3);
	background-size: 55px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_event_display_pending {
	z-index: 0;
	height:78px !important;
	width:25px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 133px;
	/*
	margin-top: -113px;
	margin-left: 175px;
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.3);
	*/
	
	background-color: rgba(255, 255, 128, 0.3);
	background-size: 55px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.item_event_display_complete {
	z-index: 0;
	height:78px !important;
	width:25px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 133px;
	/*

	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.3);
	*/
	background-color: rgba(252, 71, 71, 0.3);
	background-size: 55px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********/
.item_event_calendar_active {
	z-index: 10;
	height:145px !important;
	width:25px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 204px;
	/*
	margin-top: -113px;
	margin-left: 175px;
	background: url(../images/buttons/butt_template.png) rgba(0,240,0, 0.3);
	*/
	
	background-color: rgba(0,240,0, 0.3);
	background-size: 55px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_event_calendar_pending {
	z-index: 10;
	height:145px !important;
	width:25px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 204px;
	/*
	margin-top: -113px;
	margin-left: 175px;
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.3);
	*/
	
	background-color: rgba(255, 255, 128, 0.3);
	background-size: 55px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.item_event_calendar_complete {
	z-index: 10;
	height:145px !important;
	width:25px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 204px;
	/*

	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.3);
	*/
	background-color: rgba(252, 71, 71, 0.3);
	background-size: 55px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********/
.item_event_sp_calendar_active {
	z-index: 10;
	height:233px !important;
	width:25px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 204px;
	/*
	margin-top: -113px;
	margin-left: 175px;
	background: url(../images/buttons/butt_template.png) rgba(0,240,0, 0.3);
	*/
	
	background-color: rgba(0,240,0, 0.3);
	background-size: 55px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_event_sp_calendar_pending {
	z-index: 10;
	height:233px !important;
	width:25px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 204px;
	/*
	margin-top: -113px;
	margin-left: 175px;
	background: url(../images/buttons/butt_template.png) rgba(255, 255, 128, 0.3);
	*/
	
	background-color: rgba(255, 255, 128, 0.3);
	background-size: 55px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.item_event_sp_calendar_complete {
	z-index: 10;
	height:233px !important;
	width:25px !important;
	border-spacing: 0px;
	border:hidden;
	position: absolute;
	margin-top: 0px;
	margin-left: 204px;
	/*

	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.3);
	*/
	background-color: rgba(252, 71, 71, 0.3);
	background-size: 55px 38px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*


*/
.item_feed_day {
	height:25px !important;
	width:170px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	/*
	margin-top:-95px;
	margin-left:5px;
	*/
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.05em;
	font-weight: 600;
	background-color: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_time {
	height:25px !important;
	width:90px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	/*
	margin-top:-75px;
	margin-left:85px;
	*/
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 100px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_interaction_dislike {
	height:40px !important;
	width:40px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	/*
	margin-top:-41px;
	margin-left:82px;
	*/
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 37px 37px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_feed_interaction_like {
	height:40px !important;
	width:40px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	/*
	margin-top:-41px;
	margin-left:124px;
	*/
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 37px 37px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/***************************************/
/***************************************/
/*
Feed_ajax_calendar_main
item_feed_frame
calendar_menu_td

*/

.calendar_menu_td {
	/*
	height:660px;
	height:711px;
	*/
	height: 50px;
	/*
	width: 545px !important;
	*/
	width: 950px !important;
	
	
	position:relative;
	
	background-color:rgba(255,255,255, 0.1);

	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border-left: hidden;
	border-right: hidden;
	border-bottom: hidden;

	/*
	border: hidden;
	border-left: 1px solid #CCC;
	border-right: 1px solid #CCC;
	
	*/
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.calendar_container_td {
	/*
	height:660px;
	height:711px;
	*/
	height: 870px;
	/*
	width: 545px !important;
	*/
	width: 555px !important;
	
	
	position:relative;
	
	background-color:rgba(255,255,255, 0.1);

	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border: hidden;
	border-left: 1px solid rgba(233,233,233, 0.1);
	border-right: 1px solid rgba(233,233,233, 0.1);
	border-bottom: 1px solid rgba(233,233,233, 0.1);

	/*
	
	border-left: 1px solid #CCC;
	border-right: 1px solid #CCC;
	*/
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.Feed_ajax_calendar_main {
	background-color:rgba(255,255,255, 0.3);
	border-top: hidden;
	border: hidden;
	border-left: 1px solid #CCC;
	border-right: 1px solid #CCC;
	border-bottom: 1px solid #CCC;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
.Feed_ajax_calendar_results {
	background-color:rgba(255,255,255, 0.3);
	border-top: hidden;
	border: hidden;
	border-left: 1px solid #CCC;
	border-right: 1px solid #CCC;
	border-bottom: 1px solid #CCC;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

item_calendar_personal_frame
*/
.item_calendar_frame_blank {
	border-spacing: 0px;
	height:60px;
	border:1px solid rgba(204,204,204, 0.5);
	/*
	
	*/
	display: inline-block;
	position: relative;

	margin-top:1px;
	margin-left:1px !important;

	vertical-align:middle;
	text-align:left !important;

	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_frame {
	border-spacing: 0px;
	
	border-top:1px solid rgba(204,204,204, 0.5);
	border-left:1px solid rgba(204,204,204, 0.1);
	border-right:1px solid rgba(204,204,204, 0.1);
	border-bottom:1px solid rgba(204,204,204, 0.0);
	/*
	height:120px;
	*/
	display: inline-block;
	position: relative;

	margin-top:1px;
	margin-left:1px !important;

	vertical-align:middle;
	text-align:left !important;

	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_list_time_frame {
	height:40px !important;
	width:555px;
	position: relative;
	/*
	width:545px;
	
	*/
	border-spacing: 0px;

	border-top:1px dashed;
	border-bottom:1px dashed;
	border-left:hidden;
	border-right:hidden;
	
	background-color:rgba(239,239,239, 0.3);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_timeline_month {
	height:25px !important;
	width:110px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position: absolute;
	margin-top:-30px;
	margin-left:0px;
	
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 0.85em;
	font-weight: 600;
	background-color: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_timeline_day {
	height:25px !important;
	width:56px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position: absolute;
	
	margin-top:-30px;
	margin-left:115px;
	
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	
	background-color:rgba(255,255,255, 0.3);
	color:#888888;
	font-size: 0.85em;
	font-weight: 600;

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.child_theme {
	height:120px !important;
	width:120px;

	border-spacing: 0px;
	position: relative;

	border:1px solid #CCC;
	
	background-color:rgba(255,255,47, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_default_frame {
	height:130px !important;
	width:180px;
	border-spacing: 0px;
	margin-top:3px;
	margin-left:3px;
	display: inline-block !important;
	border:1px solid #CCC;
	background: url(../images/sys_option_default/sys_pro_default.png) rgba(255,255,255, 0.2);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:1% 98%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
item_calendar_frame
*/
.item_calendar_personal_frame {
	height:130px !important;
	width:180px;
	border-spacing: 0px;
	margin-top:3px;
	margin-left:3px;
	display: inline-block !important;
	border:1px solid #CCC;
	background: url(../images/sys_option_default/sys_pro_default.png) rgba(255,255,255, 0.2);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:1% 98%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_standard_frame {
	height:130px !important;
	width:180px;
	border-spacing: 0px;
	border:1px solid #CCC;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.2);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:1% 98%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_managed_frame {
	height:130px !important;
	width:180px;
	border-spacing: 0px;
	border:1px solid #CCC;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.2);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:1% 98%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_reporting_active {
	height:14px !important;
	width:14px !important;

	border-spacing: 0px;
	border:hidden;
	position:absolute;
	
	margin-top: 117px;
	margin-left:-20px;
/*
	
	margin-left:164px;
*/
	background: url(../images/buttons/butt_template.png) rgba(240,239,136, 0.7);
	background-size: 10px 10px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_privacy_active {
	/*
	account_summary_public
	item_media_panel_absolute
	*/
	height:20px !important;
	width:20px !important;

	border-spacing: 0px;
	border:hidden;
	position: absolute;

	margin-top:17px;
	margin-left:-20px;

	background: url(../images/buttons/account_summary_private.png) rgba(255,255,255, 0.3);
	background-size: 17px 17px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.item_calendar_title {
	height:20px !important;
	width:170px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:relative;
	
	margin-top:0px;
	margin-left:5px;
	background-color: rgba(255,255,255, 0.3);
	
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 0.65em;
	font-weight: 500;
}
.item_calendar_day {
	height:25px !important;
	width:170px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:relative;
	
	margin-top:-95px;
	margin-left:-179px;
	
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.05em;
	font-weight: 600;
	background-color: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_time {
	height:25px !important;
	width:90px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:relative;
	
	margin-top:-75px;
	margin-left:-93px;
	
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 100px 40px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_interaction_dislike {
	height:40px !important;
	width:40px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	
	margin-top:-41px;
	margin-left:82px;
	
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 37px 37px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.item_calendar_interaction_like {
	height:40px !important;
	width:40px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	
	margin-top:-41px;
	margin-left:124px;
	
	vertical-align:middle;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 37px 37px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/***************************************/
/***************************************/

	.temp_feed_single_frame {
		height:178px !important;
		width:126px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_double_frame {
		height:178px !important;
		width:262px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_2rows_frame {
		height:366px !important;
		width:532px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_extra_rows_frame {
		height:200px !important;
		width:532px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/**/
	.temp_feed_extra_media_frame {
		height:48px !important;
		width:48px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 0px;
		margin-left:-532px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_extra_media_frame_0 {
		height:192px !important;
		width:112px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 14px;
		margin-left:-531px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_extra_media_frame_1 {
		height:96px !important;
		width:82px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 14px;
		margin-left:-416px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_extra_media_frame_2 {
		height:96px !important;
		width:82px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 14px;
		margin-left:-334px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_extra_media_frame_3 {
		height:96px !important;
		width:82px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 14px;
		margin-left:-252px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.temp_feed_extra_media_frame_4 {
		height:96px !important;
		width:82px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 14px;
		margin-left:-170px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.temp_feed_extra_media_frame_5 {
		height:96px !important;
		width:82px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 14px;
		margin-left:-88px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.temp_feed_extra_media_frame_6 {
		height:96px !important;
		width:82px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 110px;
		margin-left:-416px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_extra_media_frame_7 {
		height:96px !important;
		width:82px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 110px;
		margin-left:-334px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.temp_feed_extra_media_frame_8 {
		height:96px !important;
		width:82px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 110px;
		margin-left:-252px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	
	.temp_feed_extra_media_frame_9 {
		height:96px !important;
		width:82px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 110px;
		margin-left:-170px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.temp_feed_extra_media_frame_10 {
		height:96px !important;
		width:82px;
		/*
		
		*/
		position: absolute;
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top: 110px;
		margin-left:-88px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/********//*	PROFILE_FEED_FRAME_SETTINGS	datetime_STARTING 	*/
/*
	border: 2px solid #77D805;
	feed_functional_ajax_now_load
*/
/*
	.STARTING_feed_single_frame {
		height:178px !important;
		width:126px;
		
		border-spacing: 0px;
		border:1px solid #77D805;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.STARTING_feed_double_frame {
		height:178px !important;
		width:262px;
		
		border-spacing: 0px;
		border:1px solid #77D805;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.STARTING_feed_2rows_frame {
		height:366px !important;
		width:532px;
		
		border-spacing: 0px;
		border:1px solid #77D805;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.ACTIVE_feed_single_frame {
		height:178px !important;
		width:126px;
		
		border-spacing: 0px;
		border:1px solid #2A8AEF;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.ACTIVE_feed_double_frame {
		height:178px !important;
		width:262px;
		
		border-spacing: 0px;
		border:1px solid #2A8AEF;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.ACTIVE_feed_2rows_frame {
		height:366px !important;
		width:532px;
		
		border-spacing: 0px;
		border:1px solid #2A8AEF;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 

	.ENDING_feed_single_frame {
		height:178px !important;
		width:126px;
		
		border-spacing: 0px;
		border:1px solid #E72E3C;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.ENDING_feed_double_frame {
		height:178px !important;
		width:262px;
		
		border-spacing: 0px;
		border:1px solid #E72E3C;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.ENDING_feed_2rows_frame {
		height:366px !important;
		width:532px;
		
		border-spacing: 0px;
		border:1px solid #E72E3C;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(255,255,255, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
*/
/********//*	PROFILE_FEED_FRAME_SETTINGS	datetime_POPULATE 	*/
/*
	border: 2px solid #CCC
	feed_functional_ajax_historic_load
background-color
*/
	.POPULATE_feed_single_frame {
		height:178px !important;
		width:126px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(15, 5, 26, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.POPULATE_feed_double_frame {
		height:178px !important;
		width:262px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(15, 5, 26, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.POPULATE_feed_2rows_frame {
		height:366px !important;
		width:532px;
		
		border-spacing: 0px;
		border:1px solid #CCC;
		
		display: inline-block;
		
		margin-top:10px;
		margin-left:5px;
		
		vertical-align:middle;
		text-align:center;
		
		background-color:rgba(15, 5, 26, 0.2);
		color:#888888;
		font-size: 0.85em;
		font-weight: 500;
		
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
/********//*	PROFILE_FEED_FRAME_SETTINGS	datetime_OTHER 		*/

/********//*	PROFILE_FEED_SERVER_MAIN	*/
/* 
	height:500px !important;
  */
	.feed_functional_ajax_server_load {
		
		width:543px;
		border: 2px solid #2A8AEF;
		
		border-spacing: 0px;
		
		text-align:left !important;
		vertical-align:top !important;
	} 





/********//*	PROFILE_FEED_NOW_MAIN	*/
	/*   */
	.feed_functional_ajax_now_load {
		height:;
		width:543px;
		border: 2px solid #77D805;
		
		border-spacing: 0px;
		
		text-align:left !important;
		vertical-align:top !important;
		
	} 




/********//*	PROFILE_FEED_HISTORIC_MAIN	*/
/*   */
	.feed_functional_ajax_historic_load {
		height:;
		width:543px;
		border: 2px solid #E72E3C;
		
		border-spacing: 0px;
		
		text-align:left !important;
		vertical-align:top !important;
	} 
/*************************************/
	/********//*	FORM_FILTER	*/
/*
form_data_filter_div
*/
.form_filter_div {
	z-index: 100;
	height:37px !important;
	width:40px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border:hidden;
	vertical-align:top;
	text-align:left;
	background-color:rgba(255,255,255, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.form_data_filter_div {
	z-index: 100;
	height:250px !important;
	width:627px !important;
	display: inline-block;
	position: absolute;
	left: 165px;
	vertical-align: top;
	text-align: center; 
	margin-top:20px; 
	margin-left:0px;
	border-spacing: 0px;
	border: thin rgba(209,209,209, 0.3);
	background-color:rgba(239,239,239, 0.7);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 

.form_filter_menu_option_1_active {
	z-index: 10;
	border-spacing: 0px;
	border:5px solid rgba(10, 245, 92, 0.7) !important;
}
.form_filter_menu_option_2_active {
	z-index: 10;
	border-spacing: 0px;
	border:5px solid rgba(0,0,255, 0.7) !important;
}
.form_filter_menu_option_3_active {
	z-index: 10;
	border-spacing: 0px;
	border:5px solid rgba(87,50,150, 0.7) !important;
}
.form_filter_menu_option_4_active {
	z-index: 10;
	border-spacing: 0px;
	border:5px solid rgba(255,0,255, 0.7) !important;
}
.form_filter_menu_option_5_active {
	z-index: 10;
	border-spacing: 0px;
	border:5px solid rgba(255, 255, 128, 0.7) !important;
}
/*
feed_container_safesearch_table
*/
.form_filter_index1 {
	height:34px !important;
	width:50px !important;
	line-height: 12px;
	position:absolute;
	margin-top:-1px;
	margin-left:0px;
	border-spacing: 0px;
	border: thin rgba(202,204,204, 0.2);
	vertical-align:top;
	text-align:center;
	text-indent: 0px;
	color:rgba(15, 5, 26, 0.7);
	font-size: 0.65em;
	font-weight: 525;
	background: url(../images/buttons/butt_template.png) rgba(20,100,239, 0.1);
    background-size: 40px 28px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.form_filter_index2 {
	height:34px !important;
	width:135px !important;
	line-height: 12px;
	position:absolute;
	
	margin-top:-1px;
	margin-left:52px;
	border-spacing: 0px;
	border:thin rgba(202,204,204, 0.2);
	background-color:rgba(20,100,239, 0.1);
	vertical-align:top;
	text-align:center;
	text-indent: 0px;
	color:rgba(15, 5, 26, 0.7);
	font-size: 0.65em;
	font-weight: 525;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_filter_option1 {
	height:34px !important;
	width:47px !important;
	line-height: 22px;
	position:absolute;
	
	margin-top:-1px;
	margin-left:189px;
	border-spacing: 0px;
	border: thin rgba(202,204,204, 0.2);
	vertical-align:top;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: .90em;
	font-weight: 600;

	background: url(../images/buttons/sys_stay_logged_default.png) rgba(20,100,239, 0.1);
	background-size: 30px 28px;
	background-repeat: no-repeat;
	background-position:center; 
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_filter_option2 {
	height:130px !important;
	width:149px !important;
	line-height:15px !important;
	margin-top:40px;
	margin-left:0px;
	display: inline-block;
	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:middle;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: .90em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 70px 70px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_filter_option3 {
	height:130px !important;
	width:149px !important;
	line-height:15px !important;
	border-spacing: 0px;
	border:1px solid #CCC;
	display: inline-block;
	
	margin-top:40px;
	margin-left:0px;
	
	vertical-align:middle;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: .90em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 70px 70px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_filter_option4 {
	height:130px !important;
	width:149px !important;
	line-height:15px !important;
	border-spacing: 0px;
	border:1px solid #CCC;
	display: inline-block;
	
	margin-top:40px;
	margin-left:0px;
	
	vertical-align:middle;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: .90em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 70px 70px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_filter_option5 {
	height:130px !important;
	width:149px !important;
	line-height:15px !important;
	border-spacing: 0px;
	border:1px solid #CCC;
	display: inline-block;
	
	margin-top:40px;
	margin-left:0px;
	
	vertical-align:middle;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: .90em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 70px 70px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_filter_optionSub {
	height:50px !important;
	width:98px !important;
	line-height:40px !important;
	border-spacing: 0px;
	border:1px solid #CCC;
	display: inline-block;
	
	margin-top:10px;
	margin-left:0px;
	
	vertical-align:middle;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: 0.80em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 90px 35px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_filter_option_submit {
	height:50px !important;
	width:98px !important;
	line-height:40px !important;
	border-spacing: 0px;
	border:1px solid #CCC;
	display: inline-block;
	
	margin-top:10px;
	margin-left:0px;
	
	vertical-align:middle;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: 0.80em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(100,255,255, 0.7);
	background-size: 90px 35px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.form_filter_micro_index1 {
	height:34px !important;
	width:83px !important;
	line-height: 34px;
	
	background-color:rgba(20,100,239, 0.1);
	border-spacing: 0px;
	border-right:1px solid rgba(203,203,203, 0.7);
	border-left:1px solid rgba(203,203,203, 0.7);
	border-top:1px solid rgba(203,203,203, 0.7);
	border-bottom:1px solid rgba(203,203,203, 0.7);
	position:absolute;
	
	margin-top:-1px;
	margin-left:0px;
	
	vertical-align:top;
	text-align:left;
	text-indent: 5px;
	color:rgba(15, 5, 26, 0.7);
	font-size: 0.80em;
	font-weight: 600;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_filter_micro_index2 {
	height:34px !important;
	width:83px !important;
	line-height: 34px;
	
	background-color:rgba(20,100,239, 0.1);
	border-spacing: 0px;
	border-right:1px solid rgba(203,203,203, 0.7);
	border-left:1px solid rgba(203,203,203, 0.7);
	border-top:1px solid rgba(203,203,203, 0.7);
	border-bottom:1px solid rgba(203,203,203, 0.7);
	position:absolute;
	
	margin-top:-1px;
	margin-left:89px;
	
	vertical-align:top;
	text-align:left;
	text-indent: 5px;
	color:rgba(15, 5, 26, 0.7);
	font-size: 0.80em;
	font-weight: 600;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
/*************************************/
/*


*/
.form_data_filter_dynamic_div {
	z-index: 100;
	height:375px !important;
	width:830px !important;
	display: inline-block;
	position: absolute;
	left: 60px;
	vertical-align: top;
	text-align: center; 
	margin-top:100px; 
	margin-left:0px;
	border-spacing: 0px;
	border: thin rgba(209,209,209, 0.3);
	background-color:rgba(239,239,239, 1.0);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_dynamic_td1 {
	z-index: 100;
	height:100px !important;
	width:205px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background-color:rgba(239,239,239, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left;
}
.form_data_filter_dynamic_td1_button {
	z-index: 100;
	height:90px !important;
	width:190px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: middle;
	text-align: center;
}
.form_data_filter_dynamic_td2 {
	z-index: 100;
	height:150px !important;
	width:125px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background-color:rgba(239,239,239, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_filter_dynamic_td2_button {
	z-index: 100;
	height:140px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: middle;
	text-align: center;
}
.form_data_filter_dynamic_td3 {
	z-index: 100;
	height:75px !important;
	width:63px !important;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.3);
	background-color:rgba(239,239,239, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_filter_dynamic_td3_button{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: middle;
	text-align: center; 
}
.form_data_filter_dynamic_td4 {
	z-index: 100;
	height:75px !important;
	width:200px !important;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px  rgba(209,209,209, 0.3);
	background-color:rgba(239,239,239, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_filter_dynamic_td4_button {
	z-index: 100;
	height:65px !important;
	width:190px !important;
	line-height: 12px; 
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
/*************************************/
/*************************************/
/*
form_data_filter_dynamic_td1_button
(3)
*/
.form_data_filter_dynamic_td1_button1_01 {
	z-index: 100;
	height:90px !important;
	width:190px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: left;
    text-decoration:none;
    text-indent:50px;
}
.form_data_filter_dynamic_td1_button1_02 {
	z-index: 100;
	height:90px !important;
	width:190px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: left;
    text-decoration:none;
    text-indent:50px;
}
.form_data_filter_dynamic_td1_button1_03 {
	z-index: 100;
	height:90px !important;
	width:190px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 50px 50px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: left;
    text-decoration:none;
    text-indent:50px;
}
.form_data_filter_view_button1_01 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button1_02 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button1_03 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
/*
form_data_filter_dynamic_td2_button
(5)
*/
.form_data_filter_dynamic_td2_button2_01 {
	z-index: 100;
	height:140px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td2_button2_02 {
	z-index: 100;
	height:140px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td2_button2_03 {
	z-index: 100;
	height:140px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td2_button2_04 {
	z-index: 100;
	height:140px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td2_button2_05 {
	z-index: 100;
	height:140px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}

.form_data_filter_view_button2_01 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button2_02 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button2_03 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button2_04 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button2_05 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
/*
form_data_filter_dynamic_td2_button
(5)
*/
.form_data_filter_dynamic_td2_button3_01 {
	z-index: 100;
	height:140px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td2_button3_02 {
	z-index: 100;
	height:140px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td2_button3_03 {
	z-index: 100;
	height:140px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td2_button3_04 {
	z-index: 100;
	height:140px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td2_button3_05 {
	z-index: 100;
	height:140px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 80px 80px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}

.form_data_filter_view_button3_01 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button3_02 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button3_03 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button3_04 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button3_05 {
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
/*
form_data_filter_dynamic_td3_button
(10)
*/
.form_data_filter_dynamic_td4_button4_01{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 45px 45px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td4_button4_02{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 45px 45px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td4_button4_03{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 45px 45px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td4_button4_04{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 45px 45px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td4_button4_05{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 45px 45px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td4_button4_06{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 45px 45px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td4_button4_07{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 45px 45px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td4_button4_08{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 45px 45px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td4_button4_09{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 45px 45px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}
.form_data_filter_dynamic_td4_button4_10{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 45px 45px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    font-size: 1.10em;
    font-weight: 600;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:middle !important;
    text-align: center;
    text-decoration:none;
    text-indent:0px;
}

.form_data_filter_view_button4_01{
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button4_02{
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button4_03{
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button4_04{
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button4_05{
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button4_06{
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button4_07{
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button4_08{
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button4_09{
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_filter_view_button4_10{
	z-index: 100;
	height:15px !important;
	width:15px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 15px 15px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*************************************/
/*************************************/
	/********//*	MAP_KEY	*/
.map_key_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:510px;

	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.map_key_select_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:800px;

	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.map_key_zoomin {
	height:25px !important;
	width:35px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	
	margin-top:7px;
	margin-left:105px;
	
	vertical-align:top;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 30px 20px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_key_zoomout {
	height:25px !important;
	width:35px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	
	margin-top:7px;
	margin-left:5px;
	
	vertical-align:text-top;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 30px 20px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_key_home {
	height:35px !important;
	width:35px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	
	margin-top:2px;
	margin-left:55px;
	
	vertical-align:text-top;
	text-align:left;
	text-indent: 0px;
	color:#888888;
	font-size: 1.25em;
	font-weight: 600;

	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.7);
	background-size: 35px 35px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_key_path_div {
	z-index: 100;
	height:70px !important;
	width:100px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:450px;
	margin-left:560px;

	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:middle;
	text-align:center;

	background-color:rgba(255,255,255, 0.3);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_activity_path_default {
	z-index: 101;
	height:60px !important;
	width:90px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	
	margin-top:456px;
	margin-left:566px;
	
	vertical-align:middle;
	text-align:center;

	background: url(../images/buttons/butt_template.png) rgba(255,255,55, 0.7);
	background-size: 80px 50px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_activity_path_active {
	z-index: 101;
	height:60px !important;
	width:90px !important;

	border-spacing: 0px;
	border:1px solid #CCC;
	position:absolute;
	
	margin-top:456px;
	margin-left:566px;
	
	vertical-align:middle;
	text-align:center;

	background: url(../images/buttons/butt_template.png) rgba(61,255,255, 0.7);
	background-size: 80px 50px;
	background-repeat: no-repeat;
	background-position:50% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/************************************/
/************************//************************//************************/
.calendar_month_label {
	z-index: 0;
	height: 450px;
	width: 948px !important;
	line-height: 400px; 
	position:absolute;
	margin-top: 20px;
	margin-left: 0px;
	border: 1px solid rgba(255,255,255, 0.9);
	background-color:rgba(239,239,239, 0.1);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: middle;
	text-align: center;
	color:rgba(100,100,100, 0.5);
	font-size: 7.00em;
    font-weight: 600;
    text-decoration:none;
    text-indent:0px;
}
.calendar_container_table {
	height: 530px;
	width: 950px !important;
	position:relative;
	
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border: 1px solid rgba(204,204,204, 0.1);
	background-color:rgba(255,255,255, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	
	box-shadow: none !important;
}
.calendar_container_table_td {
	height: 588px !important;
	width: 930px !important;
	margin-top: 0px;
	margin-left: 10px !important;
	padding:0;
	vertical-align:top;
	text-align:left;
	border: 0px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.0);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.calendar_container_td_label {
	height: 30px;
	width: 140px !important;
	line-height: 30px;
	/*
	position:absolute;
	*/
	display: inline-block;
	margin-top: 0px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(239,239,239, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	color:rgba(100,100,100, 1.0);
	font-size: 1.10em;
    font-weight: 600;
    text-decoration:none;
    text-indent:0px;
}
.calendar_container_td_label_in {
	height: 30px;
	width: 131px !important;
	line-height: 30px;
	/*
	position:absolute;
	*/
	display: inline-block;
	margin-top: 0px;
	margin-left: 2px;
	
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(239,239,239, 0.3);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	color:rgba(100,100,100, 1.0);
	font-size: 1.10em;
    font-weight: 600;
    text-decoration:none;
    text-indent:0px;
}
/**/
.calendar_container_td {
	height: 97px;
	width: 128.0px !important;
	/*
    width: 129.4px !important;
	position:absolute;
	*/
	display: inline-block;
	margin-top: 1px;
	margin-left: 1px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 0.1);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.calendar_container_td_active {
	height: 97px;
	width: 128px !important;
	position:absolute;
	display: inline-block;
	margin-top: -1px;
	margin-left: -1px;
	vertical-align:top;
	text-align:left;
	border: 2px solid rgba(10, 245, 92, 0.3);
	background-color:rgba(255,255,255, 0.1);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.calendar_container_td_default {
	height: 97px;
	width: 128px !important;
	position:absolute;
	display: inline-block;
	margin-top: -1px;
	margin-left: -1px;
	vertical-align:top;
	text-align:left;
	border: 2px solid rgba(4, 144, 242,0.0);
	background-color:rgba(4, 144, 242,0.1);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.calendar_container_td_history {
	height: 97px;
	width: 128px !important;
	position:absolute;
	display: inline-block;
	margin-top: -1px;
	margin-left: -1px;
	vertical-align:top;
	text-align:left;
	border: 2px solid rgba(4, 144, 242,0.0);
	background-color:rgba(252, 71, 71, 0.1);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.calendar_container_td_day {
	height: 95px;
	width: 126px !important;
	position:absolute;
	display: inline-block;
	margin-top: -1px;
	margin-left: -1px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(4, 144, 242,0.0);
	background-color:rgba(10, 245, 92, 0.7);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.calendar_container_td_01 {
	height: 35px;
	width: 126px !important;
	position:relative;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align:top;
	text-align:left;
	border: 2px solid rgba(255,255,255, 0.2);
	background-color:rgba(255,255,255, 0.5);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.calendar_container_td_01_date {
	z-index: 10;
	height: 45px;
	width: 45px !important;
	line-height: 12px;
	position:absolute;
	margin-top: 1px;
	margin-left: 1px;
	border: 2px solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 1.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	font-size: 1.20em;
    font-weight: 700;
    text-decoration:none;
    text-indent:0px;
}
.calendar_container_td_01_content {
	z-index: 10;
	height: 30px;
	width: 80px !important;
	position:absolute;
	margin-top: 3px;
	margin-left: 47px;
	border: 1px solid rgba(204,204,204, 0.2);
	background-color:rgba(255,255,255, 1.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	font-size: .85em;
    font-weight: 500;
    text-decoration:none;
    text-indent:0px;
}
.calendar_container_td_01_HD {
	z-index: 100;
	height: 27px;
	width: 25px !important;
	position:absolute;
	margin-top: 3px;
	margin-left: 99px;
	border: 1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
}

.calendar_container_td_02_HD {
	z-index: 100;
	height: 27px;
	width: 25px !important;
	position:absolute;
	margin-top: 3px;
	margin-left: 73px;
	border: 1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
}

.calendar_container_td_03_HD {
	z-index: 100;
	height: 27px;
	width: 25px !important;
	position:absolute;
	margin-top: 3px;
	margin-left: 47px;
	border: 1px solid rgba(204,204,204, 0.0);
	background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
}
.calendar_container_td_01_count {
	z-index: 10;
	height: 35px;
	width: 35px !important;
	line-height: 12px;
	position:absolute;
	margin-top: 58px;
	margin-left: 93px;
	border: 1px solid rgba(204,204,204, 0.5);
	background-color:rgba(255,255,255, 1.0);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	font-size: 0.90em;
    font-weight: 600;
    text-decoration:none;
    text-indent:0px;
}
.calendar_container_td_02 {
	height: 60px;
	width: 126px !important;
	position:relative;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align:top;
	text-align:left;
	border: 0px solid rgba(204,204,204, 0.0);
	background-color:rgba(255,255,255, 0.8);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.calendar_container_td_02_01_CT {
	z-index: 100;
	height: 15px;
	width: 126px !important;
	position:absolute;
	margin-top: 12px;
	margin-left: 0px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(255,255,255, 0.9);
	background-color:rgba(239,239,239, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.calendar_container_td_02_02_CT {
	z-index: 100;
	height: 15px;
	width: 126px !important;
	position:absolute;
	margin-top: 27px;
	margin-left: 0px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(255,255,255, 0.9);
	background-color:rgba(239,239,239, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.calendar_container_td_02_03_CT {
	z-index: 100;
	height: 15px;
	width: 126px !important;
	position:absolute;
	margin-top: 42px;
	margin-left: 0px;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(255,255,255, 0.9);
	background-color:rgba(239,239,239, 0.8);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.calendar_image_viewer_right {
	z-index: 150;
    height:350px;
    width:10px;
	position: absolute;
	margin-left: 941px;
	margin-top: 125px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.9);
    background-size: 10px 350px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.calendar_image_viewer_left {
	z-index: 150;
    height:350px;
    width:10px;
	position: absolute;
	margin-left: -3px;
	margin-top: 125px;
	border: hidden;
	padding:0px;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.9);
    background-size: 10px 350px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.calendar_viewer_right_disabled {
	z-index: 150;
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-top: 125px;
	margin-left: -80px;
	background: url(../images/buttons/sys_menu_right_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.calendar_viewer_right_active {
	z-index: 150;
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-top: 125px;
	margin-left: -80px;
	background: url(../images/buttons/sys_menu_right_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.calendar_viewer_left_disabled {
	z-index: 150;
   	height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-top: 125px;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow_disabled.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.calendar_viewer_left_active {
	z-index: 150;
    height:85px;
    width:85px;
    padding:0px;
    border: hidden;
	margin-top: 125px;
	margin-left: 4px;
	background: url(../images/buttons/sys_menu_left_arrow.png) rgba(239,239,239, 0.2);
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

/************************************/
/************************//************************//************************/
	/************************//*	PROFILE_IDENTITY_CLASS	*/
	/********//*	PROFILE_IDENTITY_CLASS_MAIN	*/
/*
profile_identity_default
identity_edit
select_letter_options1
select_action_right
*/
.identity_action_right {
	z-index: 10;
	height:45px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:102px;
	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(233,233,233, 0.7);
	background-size: 65px 25px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.identity_action_right_active {
	z-index: 10;
	height:45px !important;
	width:80px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:70px;
	margin-left:102px;
	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(3,255,3, 0.7);
	background-size: 65px 25px;
   	background-repeat: no-repeat;
	background-position:45% 40%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.default_identity_profile{
	height:37px !important;
	width:46px !important;
	line-height: 12px;
	margin-top: 1px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.3); 
	background-size: 46px 37px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
guideline_popup_profile1
*/
.default_identity_active_view{
	height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.3);
	background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/********//*	PROFILE_IDENTITY_CLASS:INTELECTUAL	*/
.I01_identity_active_view{
	height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,1, 0.3);
	background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.I01_identity_active_list{
	height:113px !important;
	width:121px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,1, 0.3);
	background-size: 117px 109px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.I01_identity_active{
	height:37px !important;
	width:46px !important;
	line-height: 12px;
	margin-top: 1px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,239,1, 0.3); 
	background-size: 46px 37px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/********//*	PROFILE_IDENTITY_CLASS:SOCIAL	*/
.S01_identity_active_view{
	height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(239,1,239, 0.3);
	background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.S01_identity_active_list{
	height:113px !important;
	width:121px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(239,1,239, 0.3);
	background-size: 117px 109px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.S01_identity_active{
	height:37px !important;
	width:46px !important;
	line-height: 12px;
	margin-top: 1px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,1,85, 0.3); 
	background-size: 46px 37px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/********//*	PROFILE_IDENTITY_CLASS:PHYSICAL	*/
.P01_identity_active_view{
	height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(1,239,239, 0.3);
	background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.P01_identity_active_list{
	height:113px !important;
	width:121px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(1,239,239, 0.3);
	background-size: 117px 109px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.P01_identity_active{
	height:37px !important;
	width:46px !important;
	line-height: 12px;
	margin-top: 1px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/butt_template.png) rgba(1,239,85, 0.3); 
	background-size: 46px 37px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/********//*	PROFILE_IDENTITY_CLASS:ACTIVITIES	*/
.A01_identity_active_view{
	height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,150, 0.3);
	background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.A01_identity_active_list{
	height:113px !important;
	width:121px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(239,239,150, 0.3);
	background-size: 117px 109px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.A01_identity_active{
	height:37px !important;
	width:46px !important;
	line-height: 12px;
	margin-top: 1px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,239,150, 0.3); 
	background-size: 46px 37px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/********//*	PROFILE_IDENTITY_CLASS:INFLUENCES	*/
.IN01_identity_active_view{
	height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(239,150,239, 0.3);
	background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.IN01_identity_active_list{
	height:113px !important;
	width:121px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(239,150,239, 0.3);
	background-size: 117px 109px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.IN01_identity_active{
	height:37px !important;
	width:46px !important;
	line-height: 12px;
	margin-top: 1px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,150,85, 0.3); 
	background-size: 46px 37px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/********//*	PROFILE_IDENTITY_CLASS:DIET	*/
.D01_identity_active_view{
	height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(150,239,239, 0.3);
	background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.D01_identity_active_list{
	height:113px !important;
	width:121px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(150,239,239, 0.3);
	background-size: 117px 109px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.D01_identity_active{
	height:37px !important;
	width:46px !important;
	line-height: 12px;
	margin-top: 1px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/butt_template.png) rgba(150,239,85, 0.3); 
	background-size: 46px 37px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/********//*	PROFILE_IDENTITY_CLASS:EMOTIONAL	*/
.E01_identity_active_view{
	height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(52,239,250, 0.3);
	background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.E01_identity_active_list{
	height:113px !important;
	width:121px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(52,239,250, 0.3);
	background-size: 117px 109px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.E01_identity_active{
	height:37px !important;
	width:46px !important;
	line-height: 12px;
	margin-top: 1px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/butt_template.png) rgba(52,239,250, 0.3); 
	background-size: 46px 37px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/********//*	PROFILE_IDENTITY_CLASS:INDIVIDUALITY	*/
.IND01_identity_active_view{
	height:100px !important;
	width:238px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(2,250,20, 0.3);
	background-size: 120px 98px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.IND01_identity_active_list{
	height:113px !important;
	width:121px;
	border-spacing: 0px;
	border:1px solid rgba(204,204,204, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:center;
	
	background: url(../images/buttons/butt_template.png) rgba(2,250,20, 0.3);
	background-size: 117px 109px;
   	background-repeat: no-repeat;
	background-position:50% 45%;
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.IND01_identity_active{
	height:37px !important;
	width:46px !important;
	line-height: 12px;
	margin-top: 1px;
	display:inline-block;
	border-spacing: 0px;
	border: 1px dashed #ccc;
	background: url(../images/buttons/butt_template.png) rgba(2,250,20, 0.3); 
	background-size: 46px 37px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/************************************/
/************************//************************//************************/
	/************************//*	CONTRIBUTIONS	*/
.contribution_tag_label{
    height:30px !important;
    line-height:20px;
    /*
    position: absolute;
	ss_frame_absolute
    */
    display: inline-block; 
    margin-top: 0px;
    margin-left: 1px;
    background-color:rgba(20,100,239, 0.1);
    border:3px solid rgba(255,255,255, 0.9);
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	color:#888888;
    font-size: 0.90em  !important;
    font-weight: 600;
    text-align:center;
    vertical-align:top;
    text-decoration:none;
}

.contribution_parent_option{
	height: 80px;
	width: 125px !important;
	margin-top: 320px;
    margin-left: 110px;
    position: absolute !important;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);
	vertical-align:top;
	text-align:left;
    /*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.6);
    */
	background: url(../images/buttons/sys_upload_graphic.png)  rgba(239,239,239, 0.7);
    
    background-position:center;
    background-size: 70px 70px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_child_option{
	height: 80px;
	width: 125px !important;
	margin-top: 320px;
    margin-left: 110px;
    position: absolute !important;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);
	vertical-align:top;
	text-align:left;
    /*
    
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.6);
    */
	background: url(../images/buttons/sys_upload_graphic.png)  rgba(239,239,239, 0.7);
    
    background-position:center;
    background-size: 70px 70px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_feed_option{
	height: 80px;
	width: 125px !important;
	margin-top: 320px;
    margin-left: 110px;
    position: absolute !important;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.7);
	vertical-align:top;
	text-align:left;
    /*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.6);
    */
	background: url(../images/buttons/sys_upload_graphic.png)  rgba(239,239,239, 0.7);
    
    background-position:center;
    background-size: 70px 70px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/**/
.contribution_frame_absolute {
	height:158px !important;
	width:231px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:3px;
	margin-left:2px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.contribution_frame{
	z-index: 1;
   	height:158px !important;
	width:231px;
    line-height:12px  !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,233,233, 0.5);
	/**/
	background: url(../images/buttons/clear_img.png);
    background-size: 231px 158px;
    background-repeat: no-repeat;
    background-position:center;
	
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_upload_frame{
	z-index: 1;
   	height:158px !important;
	width:231px;
    line-height:12px  !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color: rgba(233,233,45, 0.5);
	/**/
	background: url(../images/buttons/sys_upload_graphic.png)  rgba(239,239,239, 0.7);
    background-size: 120px 120px;
    background-repeat: no-repeat;
    background-position:center;
	
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_like_active{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 175px;
	margin-top: 112px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_like_disabled{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 175px;
	margin-top: 112px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_dislike_active{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 120px;
	margin-top: 112px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_dislike_disabled{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 120px;
	margin-top: 112px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_approve_active{
	z-index: 1;
   	height:43px !important;
	width:83px !important;
	display:inline-block;
	position:absolute;
	margin-left: 125px;
	margin-top: 112px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_approve_disabled{
	z-index: 1;
   	height:43px !important;
	width:83px !important;
	display:inline-block;
	position:absolute;
	margin-left: 125px;
	margin-top: 112px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_deny_active{
	z-index: 1;
   	height:43px !important;
	width:83px !important;
	display:inline-block;
	position:absolute;
	margin-left: 20px;
	margin-top: 112px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_deny_disabled{
	z-index: 1;
   	height:43px !important;
	width:83px !important;
	display:inline-block;
	position:absolute;
	margin-left: 20px;
	margin-top: 112px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.contribution_view_like_active{
	z-index: 1;
   	height:58px !important;
	width:98px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageLikeactive.png) rgba(255,255,255, 0.3);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_view_like_disabled{
	z-index: 1;
   	height:58px !important;
	width:98px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageLikedefault.png) rgba(255,255,255, 0.3);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_view_dislike_active{
    z-index: 1;
   	height:58px !important;
	width:98px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageDislikeactive.png) rgba(255,255,255, 0.3);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_view_dislike_disabled{
    /*
    photobucket_view_dislike_disabled
    contribution_view_dislike_disabled
    */
	z-index: 1;
   	height:58px !important;
	width:98px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/imageDislikedefault.png) rgba(255,255,255, 0.3);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.contribution_scroll_like_active{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_scroll_like_disabled{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_scroll_dislike_active{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_scroll_dislike_disabled{
	z-index: 1;
   	height:43px !important;
	width:53px !important;
	display:inline-block;
	position:absolute;
	margin-left: 0px;
	margin-top: 0px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_profile{
	z-index: 1;
   	height:40px !important;
	width:40px !important;
	display:inline-block;
	position:absolute;
	margin-left: 2px;
	margin-top: 2px;
	vertical-align:top;
	border:1px solid #ccc;
	background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.7);
	background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_delete{
	z-index: 1;
   	height:40px !important;
	width:40px !important;
	line-height: 23px; 
	display:inline-block;
	position:absolute;
	margin-left: 189px;
	margin-top: 2px;
	vertical-align:middle;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(239,239,239, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_privacy_default{
	z-index: 1;
   	height:40px !important;
	width:40px !important;
	line-height: 23px; 
	display:inline-block;
	position:absolute;
	margin-left: 105px;
	margin-top: 2px;
	vertical-align:middle;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/account_summary_public.png) rgba(239,239,239, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_privacy_active{
	z-index: 1;
   	height:40px !important;
	width:40px !important;
	line-height: 23px; 
	display:inline-block;
	position:absolute;
	margin-left: 105px;
	margin-top: 2px;
	vertical-align:middle;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/account_summary_private.png) rgba(10, 245, 92, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_sensitivity_default{
	z-index: 1;
   	height:40px !important;
	width:40px !important;
	line-height: 23px; 
	display:inline-block;
	position:absolute;
	margin-left: 147px;
	margin-top: 2px;
	vertical-align:middle;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.contribution_action_sensitivity_active{
	z-index: 1;
   	height:40px !important;
	width:40px !important;
	line-height: 23px; 
	display:inline-block;
	position:absolute;
	margin-left: 147px;
	margin-top: 2px;
	vertical-align:middle;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
photobucket_menu_activity_neutral
photobucket_view_activity_neutral
photobucket_scroll_activity_neutral

contribution_action_privacy_default
contribution_action_sensitivity_default
contribution_action_delete
*/
.photobucket_contribution_menu_privacy_active{
	z-index: 1;
   	height:50px !important;
	width:60px !important;
	display:inline-block;
	position:absolute;
	/*
	margin-left: 130px;
	margin-top: 2px;
    */
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/account_summary_private.png) rgba(10, 245, 92, 0.7);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_privacy_default{
	z-index: 1;
   	height:50px !important;
	width:60px !important;
	display:inline-block;
	position:absolute;
    /*
	margin-left: 130px;
	margin-top: 2px;
    */
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/account_summary_public.png) rgba(239,239,239, 0.7);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_sensitivity_active{
	z-index: 1;
   	height:50px !important;
	width:60px !important;
	display:inline-block;
	position:absolute;
    /*
	margin-left: 130px;
	margin-top: 2px;
    contribution_view_dislike_disabled
    */
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_sensitivity_default{
	z-index: 1;
   	height:50px !important;
	width:60px !important;
	display:inline-block;
	position:absolute;
	/*
	margin-left: 130px;
	margin-top: 2px;
    */
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.photobucket_contribution_menu_delete{
	z-index: 1;
   	height:50px !important;
	width:60px !important;
	display:inline-block;
	position:absolute;
    /*
	margin-left: 130px;
	margin-top: 2px;
    */
	vertical-align:top;
	border:1px solid rgba(239,239,239, 0.7);
	background: url(../images/buttons/sys_delete_buttonDefault.png) rgba(239,239,239, 0.7);
	background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}






/************************************/
/************************//************************//************************/
	/************************//*	IDENTITY_GEOGRAPHY	*/
	/********//*	IDENTITY_GEOGRAPHY_MAIN	*/
	
.identity_row_td01{
    /*
    identity_select_td01
    */
	height:50px !important;
	width:647px !important;
	line-height: 50px;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.0);
	background-color: rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.identity_row_td02{
	height:337px !important;
	width:647px !important;
	line-height: 50px;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.identity_row_td03{
	height:230px !important;
	width:647px !important;
	
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top!important;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.0);
	background-color: rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_identity_country {
	height:335px !important;
	width:647px !important;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.0);
	background-color: rgba(255,255,255, 0.0);
	margin-top:0px;
	margin-left:0px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.identity_right_panel{
    /*
    mainBodyTable_regright_base
    */
	height:624px !important;
	width:297.0px !important;
	line-height: 31px;
	margin-top: 0px;
	display:inline-block;
    position: absolute;
	text-align:left;
	vertical-align:top;

	border-spacing: 0px;
	border:hidden;

	background-color: rgba(255,255,255, 0.3);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.identity_result_td_right_panel {
    height:624px !important;
	width:297.0px !important;
	line-height: 31px;
	margin-top: 0px;
	display:inline-block;
	vertical-align:top;
	text-align:left;
	border-spacing: 0px;
	border:hidden;

	background-color: rgba(255,255,255, 0.3);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
} 
.identity_result_frame_absolute {
    height:30px !important;
    border-spacing: 0px;
    border:hidden;
    display: inline-block;
    margin-top:1px;
    margin-left:0px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.identity_selected_frame_absolute {
    height:30px !important;
    border-spacing: 0px;
    border:hidden;
    display: inline-block;
    margin-top:1px !important;
    margin-left:3px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.identity_result_label{
    height:30px !important;
    line-height:20px;
	vertical-align:top;
	text-align:center;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(239,239,239, 0.8);
    border:3px solid #FFF;
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
}
.identity_result_label_active{
    height:30px !important;
    line-height:20px;
	vertical-align:top;
	text-align:center;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(239,239,239, 0.8);
    border:3px solid rgba(10, 245, 92, 0.7);
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
}
.identity_result_country_selected{
    height:30px !important;
	display: inline-block;
    line-height:20px;
	vertical-align:top;
	text-align:center;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(239,239,239, 0.8);
    border:3px solid rgba(49,159,211, 0.7);
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	identity_select_td02
	*/
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
}
.identity_result_key_selected{
    height:30px !important;
	display: inline-block;
    line-height:20px;
	vertical-align:top;
	text-align:center;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(239,239,239, 0.8);
    border:3px solid rgba(10, 245, 92, 0.7);
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	identity_country_selected
	identity_field_selected
	*/
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
}
.identity_result_frame_settings {
    height:25px !important;
    border-spacing: 0px;
    border:hidden;
    display: inline-block;
    margin-top:1px;
    margin-left:0px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.identity_result_label_settings{
    height:25px !important;
    line-height:20px;
	vertical-align:top;
	text-align:center;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(239,239,239, 0.8);
    border:3px solid #FFF;
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
}

.identity_select_td01{
	height:35px !important;
	width:647px !important;
	line-height: 35px;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.0);
	background-color: rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.identity_select_td01_1{
	height:35px !important;
	width:593px !important;
	line-height: 35px;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.0);
	background-color: rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.identity_select_td01_2{
	height:35px !important;
	width:50px !important;
	line-height: 35px;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.0);
	background-color: rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.identity_select_td02{
	height:60px !important;
	width:647px !important;
	line-height: 33px;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.8);
	background-color: rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.identity_select_td03{
	height:133px !important;
	width:647px !important;
	line-height: 33px;
	margin-top: 0px;
	display:inline-block;
	text-align:left;
	vertical-align:top;
	border-spacing: 0px;
	border:thin solid rgba(233,233,233, 0.8);
	background-color: rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.identity_text_count_field{
    height:33px !important;
    width: 50px;
    line-height:30px !important;
    border-spacing: 0px;
    border:1px solid #ccc;
    text-align:center;
    vertical-align:top;
    text-decoration:none;
  }

 .indentity_field_list{
  height:24px !important;
  /*width: 345px;*/
  width: 590px;
  line-height:12px !important;
  background:rgba(239,239,239, 0.5);

  border-spacing: 0px;
  border-top:1px solid rgba(239,239,239, 0.9);
  border-bottom:hidden;
  border-right:1px solid rgba(239,239,239, 0.9);
  border-left:hidden;

  font-size: .85em;
  font-weight: 500;
  text-align:left;
  text-decoration:none;
  text-indent:5px;
}
.identity_country_selected{
    height:72px !important;
    width: 650px !important;
	line-height: 34px !important;
 
    background:rgba(239,239,239, 0.5);

    border-spacing: 0px;
    border-top:1px solid rgba(239,239,239, 0.9);
	border-bottom:1px solid rgba(239,239,239, 0.9);
	border-left:1px solid rgba(239,239,239, 0.9);
	border-right:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:left;
	vertical-align: top;
	/*
	font-size: .85em;
    font-weight: 500;
	
	
    text-decoration:none;
    text-indent:5px;
	identity_row_td03
	*/
 }
.identity_field_selected{
    height:120px !important;
    width: 650px !important;
	line-height: 34px;
    background:rgba(239,239,239, 0.5);
    border-spacing: 0px;
    border-top:1px solid rgba(239,239,239, 0.9);
	border-bottom:1px solid rgba(239,239,239, 0.9);
	border-left:1px solid rgba(239,239,239, 0.9);
	border-right:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:left;
	vertical-align: top  !important;
	
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
    text-indent:5px;
	
	*/
 }
/************************************/
/************************//************************//************************/
	/************************//*	SYSTEM_SEARCH	profile*/
	/********//*	SYSTEM_SEARCH_MAIN	*/

.search_container_td {
	height: 530px;
	width: 950px !important;
	position:relative;
	background-color:rgba(255,255,255, 0.3);
	margin: 0 auto;
	margin-top: 0px;
	
	vertical-align:top;
	text-align:left;
	
	border-top: hidden;
	border: 1px solid rgba(239,239,239, 0.5);
	padding:0;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ss_frame_absolute {/*FRAME  HOLDER*/
	height:145px !important;
	width:231px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color:rgba(255,255,255, 0.3);
	display: inline-block;
	margin-top:7px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ss_item_option_1 {/*PROFILE IMAGE HOLDER*/
	z-index: 1;
    height:160px !important;
    width:143px;
	position: absolute;
    border-spacing: 0px;
    /*
	top:4px;
	*/
	margin-top:1px;
	margin-left:1px;
    background: url(../images/buttons/clear_img.png);
    background-size: 143px 143px;
    background-repeat: no-repeat;
    background-position:center;
    border:1px solid rgba(233,233,233, 0.6);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.ss_item_option_2_PROFILE {/*PROFILE TYPE OPTION*/
	height:80px !important;
	width:85px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:145px;
	vertical-align:top;
	text-align:left;
	background: url(../images/sys_option_default/sys_pro_default.png) rgba(255,255,255, 0.3);
    background-size: 80px 75px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.ss_item_option_2_EVENT {/*PROFILE TYPE OPTION*/
	height:80px !important;
	width:85px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:145px;
	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/sys_pin_default.png) rgba(255,255,255, 0.3);
    background-size: 80px 75px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ss_item_option_2_BUSINESS {/*PROFILE TYPE OPTION*/
	height:80px !important;
	width:85px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.3);
	display: inline-block;
	margin-top:1px;
	margin-left:145px;
	vertical-align:top;
	text-align:left;
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.3);
    background-size: 80px 75px;
    background-repeat: no-repeat;
    background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ss_item_option_3{/*TITLE  OPTION*/
	z-index: 10;
	height:35px !important;
	width:230px;
	line-height: 35px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:72px;
	margin-left:2px;
	
	background-color:rgba(255,255,255, 0.2);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	
	color:#888888;
    font-size: 1.25em  !important;
    font-weight: 500;
    text-align:center;
	text-indent: 0px;
    vertical-align:top;
    text-decoration:none;
}
.ss_item_action_right {/*FOLLOW  OPTION*/
	z-index: 10;
	height:45px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:115px;
	margin-left:180px;
	vertical-align:top;
	text-align:left;
	background-color:rgba(5,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.ss_item_action_left {/*NOT FOLLOW  OPTION*/
	z-index: 10;
	height:45px !important;
	width:50px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:115px;
	margin-left:127px;
	vertical-align:top;
	text-align:left;background-color:rgba(255,255,8, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}



/************************************/
/************************//************************//************************/
	/************************//*	REPORT_CONCERNS	*/
	/********//*	REPORT_CONCERNS_MAIN	*/


.feedback_report_div01 {
	z-index: 10;
    height: 25px;
    width: 25px;
    line-height:38px;
	position: absolute;
	margin-top: 1px;
	margin-left: 690px;
    border:hidden;
	background-color: rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.feedback_report_div02 {
	z-index: 10;
    height: 25px;
    width: 25px;
    line-height:38px;
	position: absolute !important;
	margin-top: 195px;
	margin-left: 101px;
    border:hidden;
	background-color: rgba(239,239,239, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.feedback_report_div03 {
	z-index: 10;
    height: 25px;
    width: 25px;
    line-height:38px;
	position: absolute !important;
	margin-top: 10px;
	margin-left: 10px;
    border:hidden;
	background-color: rgba(239,239,239, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
.feedback_report_div04 {
	z-index: 10;
    height: 25px;
    width: 25px;
    line-height:38px;
	position: absolute !important;
	margin-top: 80px;
	margin-left: 10px;
    border:hidden;
	background-color: rgba(239,239,239, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
*/
.feedback_report_business_div {
	z-index: 10;
    height: 25px;
    width: 25px;
    line-height:38px;
	position: absolute !important;
	margin-top: 5px;
	margin-left: 5px;
    border:hidden;
	background-color: rgba(239,239,239, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.feedback_report_button {
    height: 20px;
    width: 20px;
    line-height:20px;
	vertical-align: top;
    text-align:left;
	margin-left: 2px;
	margin-top: 2px;
    text-decoration:none;
    border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(239,239,47, 0.9);
    background-size: 10px 10px;
	background-position: center;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.feedback_submit_button {
    height: 40px;
    width: 100px;
    line-height:15px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(4, 144, 242, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    font-size: .90em;
    font-weight: 500;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:top;
	text-align:center;
    box-shadow: none !important;
}
.feedback_cancel_button {
    height: 40px;
    width: 100px;
    line-height:15px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(255, 255, 128, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .90em;
    font-weight: 500;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:top;
	text-align:center;
}
.feedback_panel_button {
    height: 30px;
    width: 114px;
	margin-left: 1.3px;
	margin-top: 4px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color:  rgba(239,239,239, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .90em;
    font-weight: 500;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:top;
	text-align:center;
}
.feedback_panel_button_active {
    height: 30px;
    width: 114px;
	margin-left: 1.3px;
	margin-top: 4px;
    border: 1px solid rgba(204,204,204, 0.3);
    background-color:  rgba(10, 245, 92, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: .90em;
    font-weight: 500;
    color: rgba(15, 5, 26, 0.6);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */ 
    vertical-align:top;
	text-align:center;
}

.feedback_general_table_td1{
    height:55px !important;
    width:718px !important;
    line-height:55px !important;
    background-color:  rgba(255,255,255, 0.9);
    border-bottom: 1px solid rgba(204,204,204, 0.0);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.feedback_general_table_td2{
    height:180px !important;
    width:718px !important;
	display: inline-block;
    
	background-color: rgba(239,239,239, 0.3);
    border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
    border-bottom: 1px solid rgba(204,204,204, 0.0);
    border-top: 1px solid rgba(204,204,204, 0.0);
}
.feedback_general_table_td3{
    height:45px !important;
    width:718px !important;
    line-height:45px !important;
    background-color: rgba(255,255,255, 0.3);
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	/*
	reset_form_command_bar
	*/
}

.feedback_input_table_full{
 	height:280px !important;
  	width:718px !important;
	line-height: 30px;
	vertical-align:top;
	text-align: left;
	display: inline-block;
	
	background-color:rgba(239,239,239, 0.3);
	border-spacing: 0px;
	border-left: 1px solid rgba(239,239,239, 0.3);
	border-right: 1px solid rgba(239,239,239, 0.3);
	border-bottom: 1px solid rgba(239,239,239, 0.0);
	border-top: 1px solid rgba(239,239,239, 0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;

}
/*	


*/

.feedback_text_count_field{
    height:33px !important;
    width: 50px;
    line-height:20px !important;
    border-spacing: 0px;
    border:1px solid #ccc;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}

.feedback_space_td{
  	height:38px !important;
  	width: 395px;
  	line-height:38px !important;
  	display:inline-block;
  	border-spacing: 0px;
  	border:hidden;
	background: rgba(255,255,255, 0.7);
	vertical-align: top;
}
.feedback_word_td{
  	height:38px !important;
  	width: 80px;
  	line-height:38px !important;
  	display:inline-block;
  	border-spacing: 0px;
  	border:hidden;
  	text-align:center;
  	vertical-align:middle;
	background: rgba(255,255,255, 0.7);
	vertical-align: top;
}

.feedback_rating_td{
	height:38px !important;
	width: 40px;
	line-height:38px !important;
	display:inline-block;
	border-spacing: 0px;
	border:hidden;
	background: rgba(255,255,255, 0.7);
	text-align:center;
	vertical-align:top;
}

.feedback_rating_off{
  height:38px !important;
  width: 38px;
  line-height:38px !important;
  display:inline-block;

  border-spacing: 0px;
  border:hidden;
  text-align:center;
  vertical-align:middle;
  text-decoration:none;
  background: url(../images/buttons/star-off.svg);
  background-size: 38px 38px;
  background-repeat: no-repeat;
  background-position:center;
}

.feedback_rating_on{
  height:38px !important;
  width: 38px;
  line-height:38px !important;
  display:inline-block;

  border-spacing: 0px;
  border:hidden;
  text-align:center;
  vertical-align:middle;
  text-decoration:none;
  background: url(../images/buttons/star-on.svg);
  background-size:38px 38px;
  background-repeat: no-repeat;
}
	  

.feedback_form_command_bar{
    height:60px !important;
    width:710px !important;
    line-height:12px !important;
    margin-left:5px;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
    background:rgba(239,239,239, 0.7);
}  
.feedback_form_command_bar_td1{
    height:60px !important;
    width:485px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
}
.feedback_form_command_bar_td2{
    height:60px !important;
    width:110px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
}
.feedback_form_command_bar_td3{
    height:60px !important;
    width:110px;
    line-height:41px;
    text-align:center !important;
    vertical-align:middle !important;
    border-spacing: 0px;
    border:1px solid rgba(204,204,204, 0.0);
}

/***************************************/
/***************************************/
* { box-sizing: border-box; }
.grid {
  background: #EEE;
  max-width: 1200px;
}

/* clearfix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- grid-item ---- */



.grid-sizer,
.grid-item {
  width: 55%;
}

.grid-item {
  height: 200px;
  float: left;
  background: #D26;
  border: 2px solid #333;
  border-color: hsla(0, 0%, 0%, 0.5);
  border-radius: 5px;
	margin-left: 5px;
	margin-bottom: 5px;
}

.grid-item--width2 { width:  42%; }
.grid-item--width3 { width:  100%; }

.grid-item--height2 { height: 200px; }
.grid-item--height3 { height: 300px; }
.grid-item--height4 { height: 340px; }
/***************************************/
/***************************************/

.photo-item {
  margin: 40px 0;
  padding-top: 20px;
  border-top: 1px solid #DDD;
}

.photo-item__image {
  display: block;
  max-width: 100%;
}

.page-load-status {
  display: none; /* hidden by default */
  padding-top: 20px;
  border-top: 1px solid #DDD;
  text-align: center;
  color: #777;
}





/*  					rgba(255,255,255, 0.7) = #FFF (white)
/*						rgba(15, 5, 26, 0.7) =  (black)
/*						rgba(239,239,239, 0.7) = #EFEFEF (grey)
/* 						rgba(204,204,204, 0.7) = #CCC (dark border)
						rgba(10, 245, 92, 0.7)  =(green)
						rgba(255, 255, 128, 0.7) =(yellow)
						rgba(252, 71, 71, 0.7)  =(red)
*/
/************************************/
/************************//************************//************************/
	/************************//*	EVENT_MAP_MANAGER	*/
	/********//*	EVENT_MAP_MANAGER_MAIN	*/
/*

*/
.map_container_td {
	height: 620px;
	width: 950px !important;
	position:relative;
	background-color:rgba(239,239,239, 0.3);
	margin-top: 0px;
	padding:0;
	vertical-align:top;
	text-align:left;
	border: 1px solid rgba(239,239,239, 0.3);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.map_container_div {
	height: 618px;
	width: 948px !important;
	position:relative;
	background-color:rgba(239,239,239, 0.3);
	margin-top: 1px;
	margin-left: 1px;
	padding:0;
	vertical-align:top;
	text-align:left;
	border: hidden;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_container_key_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:80%;
	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:text-top;
	text-align:left;
	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_container_return_panel {
	z-index: 100;
	height:50px !important;
	width:100px !important;
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:20px;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.0);
	vertical-align:text-top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_container_left_panel {
	z-index: 100;
	height:300px !important;
	width:125px !important;
	display: inline-block;
	position: absolute;
	margin-top:70px;
	margin-left:20px;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.8);
	vertical-align:text-top;
	text-align:left;
	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_container_left_panel_table {
	height:300px !important;
	width:125px !important;
	display: inline-block;
	position: absolute;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border:hidden;
	vertical-align:text-top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_container_bottom_panel {
	z-index: 100;
	height:150px !important;
	width:780px !important;
	display: inline-block;
	position: absolute;
	margin-top:450px;
	margin-left:80px;
	border-spacing: 0px;
	border:1px solid rgba(239,239,239, 0.8);
	vertical-align:text-top;
	text-align:left;
	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.map_container_bottom_panel_table {
	height:150px !important;
	width:780px !important;
	display: inline-block;
	position: absolute;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border:hidden;
	vertical-align:text-top;
	text-align:left;
	background-color:rgba(255,255,255, 0.8);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}


.image_compliance_form_item_table {
    height:45px !important;
    width: 183px;
    line-height:12px !important;
    border-top:hidden;
    border-bottom:hidden;
    border-right:hidden;
    border-left:hidden;
	vertical-align:top;
	text-align:left;
    background:rgba(255,255,255, 0.5);
    margin:0;
    padding:0;
    /*
    ls_form_half_table3
    */
}
.image_compliance_alert_item_table {
    height:45px !important;
    width: 63px;
    line-height:12px !important;
    border-top:hidden;
    border-bottom:hidden;
    border-right:hidden;
    border-left:hidden;
	vertical-align:top;
	text-align:left;
    background:rgba(255,255,255, 0.5);
    margin:0;
    padding:0;
    /*
    ls_form_half_table3
    */
}
.image_compliance_status_item_table {
    height:45px !important;
    width: 133px;
    line-height:12px !important;
    border-top:hidden;
    border-bottom:hidden;
    border-right:hidden;
    border-left:hidden;
	vertical-align:top;
	text-align:left;
    background:rgba(255,255,255, 0.5);
    margin:0;
    padding:0;
    /*
    ls_form_half_table3
    */
}

.image_compliance_privacy_manage_active {
	z-index: 9;
	height:45px !important;
	width:177px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:0px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 50px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.image_compliance_data_privacy_default {
	z-index: 9;
	height:45px !important;
	width:177px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:0px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 50px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.image_compliance_meta_manage_active {
	z-index: 9;
	height:45px !important;
	width:177px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:0px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 50px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.image_compliance_data_meta_default {
	z-index: 9;
	height:45px !important;
	width:177px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:0px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 50px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.image_compliance_alert_manage_active {
	z-index: 9;
	height:45px !important;
	width:130px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:0px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 10px;
	background: url(../images/buttons/butt_template.png) rgba(203,203,203, 0.9);
	background-size: 40px 30px;
	background-repeat: no-repeat;
	background-position:90% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.image_compliance_alert_manage_default {
	z-index: 9;
	height:45px !important;
	width:130px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:0px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 10px;
	background: url(../images/buttons/butt_template.png) rgba(203,203,203, 0.9);
	background-size: 40px 30px;
	background-repeat: no-repeat;
	background-position:90% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.image_compliance_status_manage_active {
    z-index: 9;
	height:45px !important;
	width:60px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:0px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 10px;
	background: url(../images/buttons/butt_template.png) rgba(203,203,203, 0.9);
	background-size: 40px 30px;
	background-repeat: no-repeat;
	background-position:90% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.image_compliance_status_manage_default {
    z-index: 9;
	height:45px !important;
	width:60px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:0px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 10px;
	background: url(../images/buttons/butt_template.png) rgba(203,203,203, 0.9);
	background-size: 40px 30px;
	background-repeat: no-repeat;
	background-position:90% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/*********************************************/
/*********************************************/
.settings_compliance_control_table {
    height:240px !important;
    width: 708px;
   	margin:0;
    padding:0;
	border-spacing: 0px;
    border:hidden;
	background-color:rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:left!important;
	vertical-align: top !important;
}
.settings_compliance_main_table{
    height:120px !important;
    width:708px !important;
    line-height:12px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settings_compliance_main_table_a_row1{
    height:120px !important;
    width:708px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
	margin-top: 0px;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settings_compliance_main_table_b_row1{
    height:59px !important;
    width:708px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
	margin-top: 0px; 
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(239,239,239, 0.1);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settings_compliance_b_row_label{
    height:56px !important;
    width:200px !important;
    display:inline-block;
	margin-top: 1px; 
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:left;
	vertical-align: top;
}
.settings_compliance_b_row_status{
    height:56px !important;
    width:56px !important;
    display:inline-block;
	margin-top: 1px; 
    border:1px solid rgba(239,239,239, 0.2);
   	background: rgba(255,255,255, 0.1);
  	-webkit-box-shadow: none !important;
  	-moz-box-shadow: none !important;
  	box-shadow: none !important;
	text-align:left;
	vertical-align: top;
}
.settings_compliance_b_row_activity1{
    height:56px !important;
    width:194px !important;
    display:inline-block;
	margin-top: 1px;
	margin-left: 1px;
    border:1px solid rgba(239,239,239, 0.2);
   	background: rgba(255,255,255, 0.1);
  	-webkit-box-shadow: none !important;
  	-moz-box-shadow: none !important;
  	box-shadow: none !important;
	text-align:left;
	vertical-align: top;
}
.settings_compliance_b_row_activity2{
	/*
	settings_compliance_activity_mix_max2
	settings_compliance_status_disabled
	settings_compliance_action_table_td1_2
	*/
    height:56px !important;
    width:194px !important;
    display:inline-block;
	margin-top: 1px;
	margin-left: 1px;
    border:1px solid rgba(239,239,239, 0.2);
   	background: rgba(255,255,255, 0.1);
  	-webkit-box-shadow: none !important;
  	-moz-box-shadow: none !important;
  	box-shadow: none !important;
	text-align:left;
	vertical-align: top;
}
.settings_compliance_b_row_location1{
	/*
	settings_compliance_activity_location_op1
	*/
    height:56px !important;
    width:98px !important;
    display:inline-block;
	margin-top: 1px; 
    border:1px solid rgba(239,239,239, 0.2);
   	background: rgba(255,255,255, 0.1);
  	-webkit-box-shadow: none !important;
  	-moz-box-shadow: none !important;
  	box-shadow: none !important;
	text-align:left;
	vertical-align: top;
}
.settings_compliance_b_row_location2{
    height:56px !important;
    width:98px !important;
    display:inline-block;
	margin-top: 1px; 
    border:1px solid rgba(239,239,239, 0.2);
   	background: rgba(255,255,255, 0.1);
  	-webkit-box-shadow: none !important;
  	-moz-box-shadow: none !important;
  	box-shadow: none !important;
	text-align:left;
	vertical-align: top;
}
.settings_compliance_b_row_location3{
    height:56px !important;
    width:98px !important;
    display:inline-block;
	margin-top: 1px; 
    border:1px solid rgba(239,239,239, 0.2);
   	background: rgba(255,255,255, 0.1);
  	-webkit-box-shadow: none !important;
  	-moz-box-shadow: none !important;
  	box-shadow: none !important;
	text-align:left;
	vertical-align: top;
}
.settings_compliance_b_row_location4{
    height:56px !important;
    width:98px !important;
    display:inline-block;
	margin-top: 1px; 
    border:1px solid rgba(239,239,239, 0.2);
   	background: rgba(255,255,255, 0.1);
  	-webkit-box-shadow: none !important;
  	-moz-box-shadow: none !important;
  	box-shadow: none !important;
	text-align:left;
	vertical-align: top;
}
.settings_compliance_main_table_td{
    height:120px !important;
    width:298px !important;
    display:inline-block;
    text-align:left;
	vertical-align: top;
    border:1px solid rgba(239,239,239, 0.5);
    background: rgba(255,255,255, 0.7);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.settings_compliance_action_table {
    height:335px !important;
    width: 708px;
    margin:0;
    padding:0;
	text-align:left;
	vertical-align: top;
	background-color:rgba(239,239,239, 0.2);
	border-spacing: 0px;
    border:hidden;
}

.settings_compliance_action_table_td1_1 {
    height:56px !important;
    width:200px !important;
    margin:0;
    padding:0;
    text-align:center;
	vertical-align: middle;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
	background: rgba(255,255,255, 0.5);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settings_compliance_action_table_td1_2 {
	/*
	settings_compliance_category_button
	*/
    height:56px !important;
    width: 504px;
    margin:0;
    padding:0;
    text-align:center;
	vertical-align: middle;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
	background: rgba(255,255,255, 0.5);

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.settings_compliance_action_table_td2 {
    height:270px !important;
    width: 708px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.2);
	background: rgba(255,255,255, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:left;
    vertical-align:top;
}
.settings_compliance_event_identification_div {
   	height:266px !important;
    width: 704px;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.5);
	background: rgba(239,239,239, 0.5);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:center;
    vertical-align:top;
}
.event_identity_result_frame_absolute {
    height:31px !important;
    border-spacing: 0px;
    border:hidden;
    display: inline-block;
    margin-top:2px;
    margin-left:0px;
    vertical-align:top;
    text-align:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 

.event_identity_result_label{
    height:31px !important;
    line-height:12px;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(239,239,239, 0.8);
    border:3px solid #FFF;
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
}
.event_identity_result_label_active{
    height:30px !important;
    line-height:12px;
	vertical-align:top;
	text-align:center;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(239,239,239, 0.8);
    border:3px solid rgba(10, 245, 92, 0.7);
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
}

.event_holiday_result_label{
    height:31px !important;
    line-height:12px;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(239,239,239, 0.8);
    border:3px solid #FFF;
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
}
.event_holiday_result_label_active{
    height:30px !important;
    line-height:12px;
	vertical-align:top;
	text-align:center;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(239,239,239, 0.8);
    border:3px solid rgba(10, 245, 92, 0.7);
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
}

.event_holiday_result_list_label{
	height:45px !important;
	width:113px;
	margin-top:0px;
	margin-left:0px;
	border-spacing: 0px;
	border:3px solid rgba(204,204,204, 0.5);
    border-radius: 25px;
	background-color: rgba(239,239,239, 0.5);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	font-size: 0.75em  !important;
    font-weight: 600;
	/*
    height:31px !important;
    line-height:12px;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(239,239,239, 0.8);
    border:3px solid #FFF;
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
	*/
}
.event_holiday_result_list_label_active{
	height:45px !important;
	width:113px;
    margin-top: 0px;
    margin-left: 0px;
	border-spacing: 0px;
    border:3px solid rgba(10, 245, 92, 0.7);
    border-radius: 25px;
	background-color:rgba(239,239,239, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	text-decoration:none;
	
    font-size: 0.75em  !important;
    font-weight: 600;
	/*
	
	color:#888888;
    height:30px !important;
    line-height:12px;
	vertical-align:top;
	text-align:center;
    margin-top: 0px;
    margin-left: 0px;
    background-color:rgba(239,239,239, 0.8);
    border:3px solid rgba(10, 245, 92, 0.7);
    border-radius: 25px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	color:#888888;
    font-size: 0.60em  !important;
    font-weight: 700;
    text-decoration:none;
	*/
}

.activity_result_label{
   	height:130px !important;
	width:130px;
    line-height:12px;
    margin-top: 0px;
    margin-left: 0px;
	border-spacing: 0px;
	border:hidden;
    background-color:rgba(239,239,239, 0.2);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 1.60em  !important;
    font-weight: 700;
    text-decoration:none;
}
.activity_result_label_disabled{
   	height:130px !important;
	width:130px;
    line-height:12px;
    margin-top: 0px;
    margin-left: 0px;
	border-spacing: 0px;
	border:hidden;
    background-color:rgba(203,203,203, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 1.60em  !important;
    font-weight: 700;
    text-decoration:none;
}
/*
activity_result_label_disabled
*/
.activity_result_label_active{
   	height:130px !important;
	width:130px;
    line-height:12px;
    margin-top: 0px;
    margin-left: 0px;
	border-spacing: 0px;
	border:hidden;
    background-color:rgba(10, 245, 92, 0.8);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	color:#888888;
    font-size: 1.60em  !important;
    font-weight: 700;
    text-decoration:none;
}
.event_activity_result_frame_absolute {
    height:130px !important;
	width:130px;
	display: inline-block;
	margin-top:7px;
	margin-left:2px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background-color: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
}
.event_activity_compliance_event_div {
   	height:550px !important;
    width: 950px;
	margin-top: 1px;
	margin-left: 1px;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.5);
	background: rgba(255,255,255, 0.9);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	text-align:left;
    vertical-align:middle;
}
/*
event_identity_result_frame_absolute
*/
.settings_compliance_label_button {
	z-index: 9;
	height:54px !important;
    width:198px !important;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_compliance_category_button {
	z-index: 9;
	height:54px !important;
    width:504px !important;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align: left; 
	text-indent: 25px;
	font-size: 1.85em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	
    text-decoration:none;
	
}
.settings_compliance_status_disabled {
	z-index: 9;
	height:54px !important;
    width:54px !important;
	position: absolute;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.settings_compliance_status_active {
	z-index: 9;
	height:54px !important;
    width:54px !important;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_compliance_lock_status_disabled {
	/*
	settings_compliance_b_row_activity1
	*/
	z-index: 9;
	height:54px !important;
    width:54px !important;
	position: absolute;
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: url(../images/buttons/account_summary_private.png) rgba(252, 71, 71,252, 71, 71, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.settings_compliance_lock_status_active {
	z-index: 9;
	height:54px !important;
    width:54px !important;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/account_summary_public.png) rgba(10, 245, 92, 0.7);
	background-size: 40px 40px;
	background-repeat: no-repeat;
	background-position: center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_compliance_activity_mix_max1 {
	z-index: 9;
	height:56px !important;
    width:194px !important;
	
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(239,239,239, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 10px;
}
.settings_compliance_activity_mix_max1_active {
	z-index: 9;
	height:46px !important;
    width:100px !important;
	line-height: 12px;
	display: inline-block;
	position: absolute;
	margin-top:5px;
	margin-left:85px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 700;
	text-indent: 0px;
}
.settings_compliance_activity_mix_max2 {
	z-index: 9;
	height:56px !important;
    width:194px !important;
	
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(239,239,239, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:right;
	
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 500;
	text-indent: 10px;
}
.settings_compliance_activity_mix_max2_active {
	z-index: 9;
	height:46px !important;
    width:100px !important;
	line-height: 12px;
	display: inline-block;
	position: absolute;
	margin-top:5px;
	margin-left:10px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:center;
	
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 700;
	text-indent: 0px;
}

.settings_compliance_activity_location_active {
	z-index: 9;
	height:35px !important;
    width:43px !important;
	line-height: 12px;
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:50px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(255,255,255, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:middle;
	text-align:center;
	
	color:#888888;
    font-size: 1.00em  !important;
    font-weight: 700;
	text-indent: 0px;
}

.settings_compliance_activity_location_op1 {
	z-index: 9;
	height:56px !important;
    width:97px !important;
	
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(239,239,239, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.settings_compliance_activity_location_op2 {
	z-index: 9;
	height:56px !important;
    width:97px !important;
	
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(239,239,239, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.settings_compliance_activity_location_op3 {
	z-index: 9;
	height:56px !important;
    width:97px !important;
	
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(239,239,239, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.settings_compliance_activity_location_op4 {
	z-index: 9;
	height:56px !important;
    width:97px !important;
	
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	background: rgba(239,239,239, 0.7);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.settings_compliance_privacy_manage_positive {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/account_summary_public.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_compliance_privacy_manage_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/account_summary_private.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_compliance_activity_manage_positive {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_compliance_activity_manage_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.settings_compliance_req_reg_manage_positive {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_compliance_req_reg_manage_negative {
	z-index: 9;
	height:56px !important;
	width:196px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*
event_identity_result_label

*/
.settings_compliance_req_indv_manage_positive {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.settings_compliance_req_indv_manage_negative {
	z-index: 9;
	height:56px !important;
	width:203px;
	position: absolute;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.5);
	display: inline-block;
	margin-top:1px;
	margin-left:1px;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
	background: url(../images/buttons/butt_template.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/*********************************************/

.location_compliance_map {
    height:570px !important;
	width: 950px;
	position: absolute;
	margin-top: 2px;
    vertical-align: top!important;
    border-spacing: 0px;
	border:0px solid rgba(239,239,239, 0.0);
	background-color:rgba(239,239,239, 0.3);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
} 
.location_compliance_form_div {
	z-index: 100;
	height:250px !important;
	width:115px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:80px;
	margin-left:85%;
	border-spacing: 0px;
	border:4px solid rgba(255,255,255, 0.5);
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.2);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.location_compliance_form_menu_div{
	z-index: 100;
	height:190px !important;
	width:380px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:355px;
	margin-left:57%;
	border-spacing: 0px;
	border:4px solid rgba(255,255,255, 0.5);
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.2);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.location_compliance_map_key_div {
	z-index: 100;
	height:40px !important;
	width:150px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:78%;
	border-spacing: 0px;
	border:1px solid #CCC;
	vertical-align:text-top;
	text-align:left;

	background-color:rgba(255,255,255, 0.5);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.location_compliance_map_search_div {
	z-index: 100;
	height:32px !important;
	width:516px !important;
	
	display: inline-block;
	position: absolute;
	margin-top:10px;
	margin-left:100px;
	border-spacing: 0px;
	border:hidden;
	vertical-align:text-top;
	text-align:left;
	background-color:rgba(255,255,255, 0.0);

	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.location_compliance_country_active {
	z-index: 9;
	height:50px !important;
	width:100px;
	display: inline-block;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	margin-top:10px;
	margin-left:5px;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 600;
	text-indent: 20px;
}
.location_compliance_country_default {
	z-index: 9;
	height:50px !important;
	width:100px;
	display: inline-block;
	margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 600;
	text-indent: 20px;
}
.location_compliance_state_active {
	z-index: 9;
	height:50px !important;
	width:100px;
	display: inline-block;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	margin-top:10px;
	margin-left:5px;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 600;
	text-indent: 20px;
}
.location_compliance_state_default {
	z-index: 9;
	height:50px !important;
	width:100px;
	display: inline-block;
	margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 600;
	text-indent: 20px;
}
.location_compliance_city_active {
	z-index: 9;
	height:50px !important;
	width:100px;
	display: inline-block;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	margin-top:10px;
	margin-left:5px;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 600;
	text-indent: 20px;
}
.location_compliance_city_default {
	z-index: 9;
	height:50px !important;
	width:100px;
	display: inline-block;
	margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 600;
	text-indent: 20px;
}
.location_compliance_county_active {
	z-index: 9;
	height:50px !important;
	width:100px;
	display: inline-block;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	margin-top:10px;
	margin-left:5px;
	vertical-align:top;
	text-align:left;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 600;
	text-indent: 20px;
}
.location_compliance_county_default {
	z-index: 9;
	height:50px !important;
	width:100px;
	display: inline-block;
	margin-top:10px;
	margin-left:5px;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.8);
	background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align:top;
	text-align:left;
	
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 600;
	text-indent: 20px;
}
.location_compliance_field_selected{
    height:50px !important;
    width: 370px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 1px;
	margin-left: 1px;
    background:rgba(239,239,239, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:left;
	vertical-align: top  !important;
	
	/**/
	font-size: 01.00em;
    font-weight: 500;
	
    text-decoration:none;
    text-indent:2px;
 }
.location_compliance_selected1_1{
    height:30px !important;
    width: 60px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 55px;
	margin-left: 1px;
    background:rgba(239,239,239, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:right;
	vertical-align:top !important;
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
    text-indent:5px;
	
	*/
 }
.location_compliance_selected1_count{
    height:26px !important;
    width: 35px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 57px;
	margin-left: 64px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
	background:rgba(255,255,255, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:center;
	vertical-align:middle!important;
	color:rgba(15, 5, 26, 0.8); 
	font-size: .85em;
    font-weight: 500;
    text-decoration:none;
	text-indent:0px;
 }
.location_compliance_selected1_2{
    height:30px !important;
    width: 310px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 55px;
	margin-left: 61px;
    background:rgba(239,239,239, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:left;
	vertical-align:top !important;
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
	*/
    text-indent:33px;
 }
.location_compliance_selected2_1{
    height:30px !important;
    width: 60px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 87px;
	margin-left: 1px;
    background:rgba(239,239,239, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:right;
	vertical-align:top !important;
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
	
    text-indent:5px;
	
	*/
 }
.location_compliance_selected2_count{
    height:26px !important;
    width: 35px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 90px;
	margin-left: 64px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
	background:rgba(255,255,255, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:center;
	vertical-align:middle!important;
	color:rgba(15, 5, 26, 0.8); 
	font-size: .85em;
    font-weight: 500;
    text-decoration:none;
	text-indent:0px;
 }
.location_compliance_selected2_2{
    height:30px !important;
    width: 310px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 87px;
	margin-left: 61px;
    background:rgba(239,239,239, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:left;
	vertical-align:top !important;
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
	*/
    text-indent:33px;
 }
.location_compliance_selected3_1{
    height:30px !important;
    width: 60px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 119px;
	margin-left: 1px;
    background:rgba(239,239,239, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:right;
	vertical-align:top !important;
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
    text-indent:5px;
	
	*/
 }
.location_compliance_selected3_count{
    height:26px !important;
    width: 35px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 121px;
	margin-left: 64px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
	background:rgba(255,255,255, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:center;
	vertical-align:middle!important;
	color:rgba(15, 5, 26, 0.8); 
	font-size: .85em;
    font-weight: 500;
    text-decoration:none;
	text-indent:0px;
 }
.location_compliance_selected3_2{
    height:30px !important;
    width: 310px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 119px;
	margin-left: 61px;
    background:rgba(239,239,239, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:left;
	vertical-align:top !important;
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
	*/
    text-indent:33px;
 }
.location_compliance_selected4_1{
    height:30px !important;
    width: 60px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 151px;
	margin-left: 1px;
    background:rgba(239,239,239, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:right;
	vertical-align:top !important;
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
    text-indent:5px;
	settings_compliance_b_row_location1
	*/
 }
.location_compliance_selected4_count{
    height:26px !important;
    width: 35px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 154px;
	margin-left: 64px;
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
	background:rgba(255,255,255, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:center;
	vertical-align:middle!important;
	color:rgba(15, 5, 26, 0.8); 
	font-size: .85em;
    font-weight: 500;
    text-decoration:none;
	text-indent:0px;
 }
.location_compliance_selected4_2{
    height:30px !important;
    width: 310px !important;
	line-height: 12px;
	position: absolute;
	margin-top: 151px;
	margin-left: 61px;
    background:rgba(239,239,239, 0.5);
    border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.9);
   	word-wrap: break-word !important;
	overflow-wrap: break-word;
	text-align:left;
	vertical-align:top !important;
	/*
	font-size: .85em;
    font-weight: 500;
	
	text-align:left;
    text-decoration:none;
	*/
    text-indent:33px;	
 }

/*********************************************/
/*********************************************/
.upload_menu_td {
	height: 230px;
	width: 950px !important;
	
	position:relative;
	margin-top: 0px;
	
	vertical-align:middle;
	text-align:center;
	
	border: 1px solid rgba(209,209,209, 0.3);
	background-color:rgba(239,239,239, 0.7);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.upload_list_view {
	height: 335px;
	width: 950px !important;
	
	position:relative;
	margin-top: 0px;
	
	vertical-align:middle;
	text-align:center;
	
	border: 1px solid rgba(209,209,209, 0.3);
	background-color:rgba(239,239,239, 0.3);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

.dropzone {
	height: 227px !important;
	width: 947px !important;
    border: 2px dashed #0087F7 !important;
    border-radius: 5px !important;
    background-color:rgba(239,239,239, 0.7) !important;
	min-height: 150px !important;
}


.dropzone, .dropzone * {
    box-sizing: border-box;
}

.dropzone_label{
	
	display: none;
}
.media_dropzone_upload {
    height: 150px !important;
    width: 300px !important;
    line-height:30px;

	margin-top: 5px;
	margin-left: 15px;
    border: 1px solid rgba(209,209,209, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(1,239,239, 0.8);
    background-position:center;
    background-size: 200px 100px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:top;
	text-align: left;
	font-size: 0.80em;
	font-weight: 700;
    /*
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	text-indent:0px;
    */
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

    text-decoration:none;
}
/*
swipe_media_panel_add
upload_menu_td
*/
.media_upload_box {
    height: 230px;
	width: 950px !important;
	
	position:relative;
	margin-top: 0px;
	
    border: 1px solid rgba(209,209,209, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(239,239,239, 0.8);
    background-position:center;
    background-size: 400px 150px;
    background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	
	vertical-align:middle;
	text-align:center;
    text-decoration:none;
}
/*********************************************/
/*********************************************/
	
/*********************************************/
/*********************************************/

/*********************************************/
/*********************************************/



/*********************************************/
/*********************************************/

/*********************************************/
/*********************************************/












/*********************************************/
/*********************************************/
}
/*   -------------------------------------------------- */
/*   -------------------------------------------------- */
/*   ------------  mobile_main_system_frame ----------- */
/*   
/*
1) RGB(11,183,255);

2) RGB(232, 232, 232)

3) RGB(255, 68, 255)

4) RGB(255, 255, 255)
/*
/*  
/* 
/*
/*  	
/*
/*  
/* 
/*
/*
/*		
/*  
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 mobile_base_functional_full_table
CONTRUCTION
    BORDERS
        TABLE OUTSIDE   border: 1px solid rgba(209,209,209, 0.7);
        TD OUTSIDE      border: 1px solid rgba(204,204,204, 0.7);

        TABLE INSIDE    border: 1px solid rgba(204,204,204, 0.0);
        TD INSIDE       border: 1px solid rgba(204,204,204, 0.2);

        BUTTON          border: 1px solid rgba(204,204,204, 0.3);
		
    BACKGROUND
        MAIN            background-color: rgba(255,255,255, 0.7);
        PAGE            background-color: rgba(255,255,255, 0.2);
        FORM PAGE       background-color: rgba(239,239,239, 0.7);
        MENU            background-color: rgba(239,239,239, 0.7);
        BUTTON          background-color: rgba(204,204,204, 0.7);
		
    rgba(255,255,255, 0.7) = #FFF (white)
    rgba(0,0,0, 0.7) =  (black)
    rgba(239,239,239, 0.7) = #EFEFEF (grey)
    rgba(204,204,204, 0.7) = #CCC (dark border)
    rgba(0,255,0, 0.7)  =(green)
    rgba(255,255,0, 0.7) =(yellow)
    rgba(255,0,0, 0.7)  =(red)
    rgba(15,10,222,0.7)  =(blue)


    rgba(15, 5, 26, 0.7) =  (black scheme)
    rgba(10, 245, 92, 0.7)  =(green scheme)
    rgba(4, 144, 242, 0.7)  =(blue scheme)
    rgba(255, 28, 255, 0.7)  =(pink scheme)
    rgba(204, 204, 204, 0.7)  =(grey scheme)
    rgba(252, 71, 71, 0.7)  =(red scheme)
    rgba(255, 255, 128, 0.7) =(yellow scheme)
    204,204,204
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */
/*********************************************/
/*********************************************/
/*
    http://www.javascriptkit.com/dhtmltutors/cssmediaqueries2.shtml
###################################################################
#####		PHONE
##################################################################896
Device					Width		Height		Type	Unique
jioPhone 2				240			320			phone	X
Galaxy S9+				320			658			phone	X
Nokia Lumia 520			320			533			phone	X
iPhone 4				320			480			phone	X
iPhone 5/SE				320			568			phone	X
Galaxy Z Fold 5			344			882			phone	X
Pixel 4					353			745			phone	X
BlackBerry Z30			360			640			phone	X
Galaxy Note 3			360			640			phone	
Galaxy Note II			360			640			phone	
Galaxy S III			360			640			phone	
Galaxy S5				360			640			phone	
Galaxy S8				360			740			phone	X
Galaxy S8+				360			740			phone	
MicroSoft Lumia 950		360			640			phone	
Moto G4					360			640			phone	
Nexus 5					360			640			phone	
MicroSoft Lumia 550		360			640			phone	


iPhone 6/7/8			375			667			phone	X
iPhone SE				375			667			phone	
iPhone XR				375			812			phone	X
LG Optimus L70			384			640			phone	X
iPhone 12 PRO			390			844			phone	X
*****					393			786			phone   X


Pixel 2 XL				411			823			phone	X
Galaxy S20 Ultra		412			915			phone	X
Galaxy A51-71			412			914			phone	X
Moto G Power			412			823			phone	X
Nexus 6					412			732			phone	X
Pixel 7					412			915			phone	
Nexus 5X				412			732			phone	X
Nexus 6P				412			732			phone	
iPhone 6/7/8 Plus		414			736			phone	X
iPhone XR				414			896			phone	X

IPHONE 15 PLUS			430			932			phone	X	- SIMULATOR (PHONE) 
iPhone 14 PRO Max		430			932			phone	X	

IPHONE 15 PRO MAX		430			839			phone	X	- SIMULATOR (PHONE)

Nokia N9				480			854			phone	X
------------------------------------------------------------------------------ 
*****					412			732			phone	X	- SIMULATOR (PHONE) - Google Pixel Fold
*****					412			676			phone	X	- (Chrome Browser - Pixel Fold) Portrait
*****					701			657			phone	X	- (Chrome Browser - Pixel Fold) Open Portrait)
*****					515			842			phone	X	- (Chrome Browser - Pixel Fold) Open Landscape)
------------------------------------------------------------------------------
*****					360			536			phone	X	- (Chrome Browser - Android Small)
------------------------------------------------------------------------------
*****					393			704			phone	X	- (Chrome Browser - Pixel 3a)
------------------------------------------------------------------------------
*****					412			811			phone	X	- (Chrome Browser - Android Medium)
------------------------------------------------------------------------------
*****					412			771			phone	X	- (Chrome Browser - Pixel 7 PRO)
------------------------------------------------------------------------------
*****					448			867			phone	X	- (Chrome Browser - Pixel 8 PRO)
------------------------------------------------------------------------------
*****					414			732			phone	X	- (Edge Browser - iphone 11 PRO MAX)
*****					414			724			phone	X	- (Chrome Browser - iphone 11 PRO MAX)
*****					414			678			phone	X	- (Safari Browser - iphone 11 PRO MAX)
*****					414			713			phone	X	- (Firefox Browser - iphone 11 PRO MAX)
------------------------------------------------------------------------------
*****					428			759			phone	X	- (Edge Browser - iphone 13 PRO MAX)
*****					428			751			phone	X	- (Chrome Browser - iphone 13 PRO MAX)
*****					428			745			phone	X	- (Safari Browser - iphone 13 PRO MAX)
*****					428			740			phone	X	- (Firefox Browser - iphone 13 PRO MAX)
------------------------------------------------------------------------------
*****					393			659			phone	X	- (Safari Browser - iphone 15)
															- (Safari Browser - iphone 15 PRO)
*****					393			759			phone   X	- SIMULATOR (PHONE) - IPHONE 15 (15 PRO)
------------------------------------------------------------------------------
*****					430			739			phone	X	- (Safari Browser - iphone 15 PRO MAX)
															- (Safari Browser - iphone 15 PLUS)
------------------------------------------------------------------------------
*****					375			548			phone	X	- (Safari Browser - iphone SE 16.4)
															- (Safari Browser - iphone SE 15.5)
****					375			647			phone   X	- SIMULATOR (PHONE) - IPHONE SE 3rd gen (15.5, 16.4)
------------------------------------------------------------------------------
ANDROID SMALL			360			592			phone	X	- SIMULATOR (PHONE)
ANDROID MEDIUM			412			867			phone	X	- SIMULATOR (PHONE)
			
GOOGLE PIXEL 3A												- SIMULATOR (PHONE)
GOOGLE PIXEL 7 PRO		412			827			phone	X	- SIMULATOR (PHONE)
GOOGLE PIXEL 8 PRO		448			923			phone	X	- SIMULATOR (PHONE)
##################################################################
##################################################################
#####		TABLET
##################################################################
Device					Width		Height		Type	Unique
Surgance Duo			720			540			tablet	X
Nexus 7 				960			600			tablet	X
iPad					1024		768			tablet	X
iPad Mini				1024		768			tablet	
Nest Hub				1024		600			tablet	X
Blackberry Playbook		1024		600			tablet	
Galaxy Tab S4			1138		712			tablet	X


iPad Mini 				1133		744			tablet	X	IPAD MINI - SIMULATOR (TABLET)
IPAD MINI 				744			1133		tablet	X	- SIMULATOR (TABLET)

*****					1133		700			tablet	X


IPAD PRO 11 INCH		1194		834			tablet	X	IPAD PRO 11 INCH - SIMULATOR (TABLET)
*****					834			1194		tablet	X	- SIMULATOR (TABLET)

IPAD AIR 5 GEN & IPAD 10 GEN
iPad Air				825-818		1734		tablet	X	IPAD AIR 5 GEN & IPAD 10 GEN - SIMULATOR (TABLET)
iPad Air				820			1180		tablet	X	- SIMULATOR (TABLET)



*****					1155		714			tablet	X

Asus Zenbook Fold		1280		853			tablet	X
Nest Hub Max			1280		800			tablet	X
Nexus 10				1280		800			tablet	
Kindle Fire HDX			1280		800			tablet	

*****
iPad Pro 12 inch		1366		1024		tablet	X	IPAD PRO 12 INCH - SIMULATOR (TABLET)
IPAD PRO 12 INCH		1024		1366		tablet	X	- SIMULATOR (TABLET)


Surface Pro 7			1368		912			tablet	X
------------------------------------------------------------------------------
*****					1180		776			tablet	X	- (Safari Browser - ipad 10 generation)
------------------------------------------------------------------------------
*****					1194		790			tablet	X	- (Safari Browser - ipad pro 11 inch) SIMULATOR
------------------------------------------------------------------------------
*****					1366		980			tablet	X	- (Safari Browser - ipad pro 12.9 inch) SIMULATOR
------------------------------------------------------------------------------
*****					800			1100		tablet	X	- (Chrome Browser - Android Medium Tablet Open Portrait)
*****					1280		620			tablet	X	- (Chrome Browser - Android Medium Tablet Open Landscape)
------------------------------------------------------------------------------
*****					1133		670			tablet	X	- (Safari Browser - ipad Mini)
------------------------------------------------------------------------------
*****					1194		703			tablet	X	- (Edge Browser - ipad PRO 11 inch)
*****					1194		715			tablet	X	- (Chrome Browser - ipad PRO 11 inch)
*****					1194		760			tablet	X	- (Safari Browser - ipad PRO 11 inch)
*****					1194		713			tablet	X	- (Firefox Browser - ipad PRO 11 inch)
------------------------------------------------------------------------------
Google Pixel Fold		481			613			tablet	X	- SIMULATOR (TABLET) - Google Pixel Fold - SIMULATOR (TABLET) - Landscape
Google Pixel Fold		481			613			tablet	X	- SIMULATOR (TABLET) - Google Pixel Fold - SIMULATOR (TABLET) - portrait
Google Pixel 			555			719			tablet	X	- SIMULATOR (TABLET)
Google Medium Pixel 	555			719			tablet	X	- SIMULATOR (TABLET)





##################################################################




*/
/* -----------------------------------------------------------------------------------------/ 
mobile_feed_container						|	container	mobile_container
*mobile_mainBodyTable						|	mainBodyTable
*mobile_mainBodyTable_td					|	mainBodyTable_td
*mobile_defaultbody_table					|	
*mobile_defaultbody_table_td				|	mobile_site_open_footer_table_full_phrase
	|	
	|	
			
.
.			FOR FUTURE REVIEW: .mainBodyTable { position: relative;
*/

/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 320px)  and (max-device-width: 240px) and (min-device-width: 240px) {
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:190px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:108px !important;
		width:99.9% !important;
		/*
		top: 72px;
		*/
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:108px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:110px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:98px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:240px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:240px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 658px)  and (max-device-width: 325px) and (min-device-width: 310px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:270px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:453px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:453px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:455px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:443px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:320px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:320px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 568px)  and (max-device-width: 320px) and (min-device-width: 320px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:270px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:358px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:358px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:360px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:348px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:320px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:320px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:97% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 533px)  and (max-device-width: 325px) and (min-device-width: 310px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:275px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:328px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:328px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:330px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:318px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:320px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:320px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:97% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 481px)  and (max-device-width: 325px) and (min-device-width: 310px) and (orientation: portrait){
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:275px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:268px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:268px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:270px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:258px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:320px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:320px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 480px)  and (max-device-width: 320px) and (min-device-width: 320px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:270px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:278px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:278px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:280px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:268px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:320px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:320px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:98% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 882px)  and (max-device-width: 344px) and (min-device-width: 330px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:294px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:668px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:668px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:670px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:658px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:340px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:340px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:94% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 745px)  and (max-device-width: 353px) and (min-device-width: 353px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:303px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:538px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:538px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:540px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:528px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:350px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:350px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:93% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 640px)  and (max-device-width: 384px) and (min-device-width: 384px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:334px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:438px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:438px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:440px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:428px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:375px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:375px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 812px)  and (max-device-width: 375px) and (min-device-width: 375px) and (orientation: portrait){
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:325px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		/*skip*/
		height:598px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:598px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:600px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:588px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:375px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:375px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:90% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 667px)  and (max-device-width: 375px) and (min-device-width: 375px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:325px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:458px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:458px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:460px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:448px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:375px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:375px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 647px)  and (max-device-width: 375px) and (min-device-width: 375px) and (orientation: portrait){
	/*********************************************/
	/*
		IPHONE SE 3rd gen (15.5, 16.4) - SIMULATOR (PHONE)
		June 16 - DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:325px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:438px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:438px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:440px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:428px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:375px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:375px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:90% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 548px)  and (max-device-width: 375px) and (min-device-width: 375px) and (orientation: portrait){
	/*********************************************/
	/*
	(Safari Browser - iphone SE 16.4)
	(Safari Browser - iphone SE 15.5)
	DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:325px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:350px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.8);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:350px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:352px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:340px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:375px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:375px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:90% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 740px)  and (max-device-width: 365px) and (min-device-width: 355px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:315px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:528px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:528px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:530px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:518px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:360px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:360px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 645px)  and (max-device-width: 365px) and (min-device-width: 355px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:315px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:505px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:505px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:507px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:495px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:360px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:360px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 640px)  and (max-device-width: 360px) and (min-device-width: 360px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:310px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:438px!important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:438px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:440px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:428px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:360px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:360px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:92% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 592px)  and (max-device-width: 360px) and (min-device-width: 360px) and (orientation: portrait){
	/*********************************************/
	/*
		ANDROID SMALL - SIMULATOR (PHONE)
		June 19 - 
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:310px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:378px!important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:378px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:380px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:368px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:360px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:360px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:92% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 567px)  and (max-device-width: 360px) and (min-device-width: 360px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:310px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:368px!important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:368px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:370px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:358px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:360px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:360px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:92% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 536px)  and (max-device-width: 360px) and (min-device-width: 360px) and (orientation: portrait){
	/*********************************************/
	/*
	(Chrome Browser - Android Small)
	DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:310px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:318px!important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:318px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:320px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:308px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:360px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:360px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 786px)  and (max-device-width: 393px) and (min-device-width: 393px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:343px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		/*
		June 9	Done Quarter of an Inch from the bottom
		*/
		height:568px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:568px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:570px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:558px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:390px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:390px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 759px)  and (max-device-width: 393px) and (min-device-width: 393px) and (orientation: portrait){
	/*********************************************/
	/*
		IPHONE 15 (15 PRO) - SIMULATOR (PHONE)
		June 16 - DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:343px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:548px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:548px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:550px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:538px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:390px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:390px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 704px)  and (max-device-width: 393px) and (min-device-width: 393px) and (orientation: portrait){
	/*********************************************/
	/*
	(Chrome Browser - Pixel 3a)
	DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:343px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:488px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:488px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:490px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:478px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:390px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:390px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:88% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 660px)  and (max-device-width: 393px) and (min-device-width: 393px) and (orientation: portrait){
	/*********************************************/
	/*
	(Safari Browser - iphone 15)
	(Safari Browser - iphone 15 PRO)
	DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:343px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:460px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.8);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:460px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:462px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:450px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:390px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:390px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:88% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 844px)  and (max-device-width: 390px) and (min-device-width: 390px) and (orientation: portrait){
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:340px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:638px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  
		overflow-x: hidden; 
		-ms-overflow-style: none;  
		scrollbar-width: none;  
	}
	.mainBodyTable {
		height:638px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:640px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:628px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_site_footer_table_full {
		height:40px;
		width:390px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:390px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:88% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}


/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 854px)  and (max-device-width: 480px) and (min-device-width: 480px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:430px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:608px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:608px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:610px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:598px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:480px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:480px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 923px)  and (max-device-width: 448px) and (min-device-width: 448px) and (orientation: portrait){
	/*********************************************/
	/*
		GOOGLE PIXEL 8 PRO - SIMULATOR (PHONE)
		June 19 - DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:398px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:708px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:708px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:710px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:698px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:448px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:448px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:82% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 867px)  and (max-device-width: 448px) and (min-device-width: 448px) and (orientation: portrait){
	/*********************************************/
	/*
	(Chrome Browser - Pixel 8 PRO)
	DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:398px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:648px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:648px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:650px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:638px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:448px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:448px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:82% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 932px)  and (max-device-width: 430px) and (min-device-width: 430px) and (orientation: portrait){
	/*********************************************/
	/*
		IPHONE 15 PLUS - SIMULATOR (PHONE)
		June 16 - DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:380px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:718px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:718px !important;
		width:99.9% !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:720px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.2);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:708px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:430px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:430px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:85% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 839px)  and (max-device-width: 430px) and (min-device-width: 430px) and (orientation: portrait){
	/*********************************************/
	/*
		IPHONE 15 PRO MAX - SIMULATOR (PHONE)
		June 16 - DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:380px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:628px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:628px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:630px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:618px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:430px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:430px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:87% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 739px)  and (max-device-width: 430px) and (min-device-width: 430px) and (orientation: portrait){
	/*********************************************/
	/*
	(Safari Browser - iphone 15 PRO MAX)
	DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:380px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:530px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.8);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:530px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:532px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:520px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:430px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:430px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:87% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 759px)  and (max-device-width: 428px) and (min-device-width: 428px) and (orientation: portrait){
	/*********************************************/
	/*
	(Edge Browser - iphone 13 PRO MAX)
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:378px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		/*
		June 11	- active
		*/
		height:538px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:538px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:540px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:528px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:428px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:428px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:90% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 751px)  and (max-device-width: 428px) and (min-device-width: 428px) and (orientation: portrait){
	/*********************************************/
	/*
	(Chrome Browser - iphone 13 PRO MAX)
	DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:378px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		/*
		June 11	- active
		*/
		height:538px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:538px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:540px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:528px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:428px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:428px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:90% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 745px)  and (max-device-width: 428px) and (min-device-width: 428px) and (orientation: portrait){
	/*********************************************/
	/*
		(Safari Browser - iphone 13 PRO MAX)
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:378px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		/*
		June 11	- active
		*/
		height:538px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:538px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:540px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:528px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:428px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:428px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:90% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 740px)  and (max-device-width: 428px) and (min-device-width: 428px) and (orientation: portrait){
	/*********************************************/
	/*
	(Firefox Browser - iphone 13 PRO MAX)
	DONE
	*/ 
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:378px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		/*
		June 11	- active
		*/
		height:533px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:533px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:535px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:523px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:428px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:428px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:90% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 896px)  and (max-device-width: 414px) and (min-device-width: 414px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:364px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		/*
		done
		*/
		height:648px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:648px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:650px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:678px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:414px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:414px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 736px)  and (max-device-width: 414px) and (min-device-width: 414px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:364px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		/*
		done
		*/
		height:528px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:528px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:530px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:518px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:414px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:414px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:86% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 732px)  and (max-device-width: 414px) and (min-device-width: 414px) and (orientation: portrait){
	/*********************************************/
	/*
	(Edge Browser - iphone 11 PRO MAX)
	Done
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:364px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:518px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:518px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:520px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:508px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:414px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:414px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:86% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 724px)  and (max-device-width: 414px) and (min-device-width: 414px) and (orientation: portrait){
	/*********************************************/
	/*
	(Chrome Browser - iphone 11 PRO MAX)
	DONR
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:364px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:508px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:508px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:510px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:498px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:414px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:414px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:86% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 718px)  and (max-device-width: 414px) and (min-device-width: 414px) and (orientation: portrait){
	/*********************************************/
	/*
	(Safari Browser - iphone 11 PRO MAX)
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:364px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:498px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:498px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:500px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:488px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:414px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:414px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:86% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 713px)  and (max-device-width: 414px) and (min-device-width: 414px) and (orientation: portrait){
	/*********************************************/
	/*
	(Firefox Browser - iphone 11 PRO MAX)
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:364px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:488px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:488px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:490px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:478px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:414px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:414px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:86% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 915px)  and (max-device-width: 412px) and (min-device-width: 412px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:362px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:708px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:chocolate;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:708px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:710px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:698px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:412px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:412px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 914px)  and (max-device-width: 412px) and (min-device-width: 412px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:362px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:698px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:698px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:700px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:688px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:412px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:412px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:86% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 891px)  and (max-device-width: 412px) and (min-device-width: 412px) and (orientation: portrait){
	/*********************************************/
	/*
		ANDROID MEDIUM - SIMULATOR (PHONE)
		June 22 - DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:362px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:648px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:648px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:650px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:638px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:412px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:412px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:85% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 836px)  and (max-device-width: 412px) and (min-device-width: 412px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:362px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:624px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:624px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:624px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:614px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:412px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:412px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 828px)  and (max-device-width: 413px) and (min-device-width: 411px) and (orientation: portrait){
	/*********************************************/
	/*
		GOOGLE PIXEL 7 PRO - SIMULATOR (PHONE)
		June 22 - DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:362px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:618px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:618px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:620px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:608px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:412px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:412px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:85% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 823px)  and (max-device-width: 412px) and (min-device-width: 412px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:362px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:618px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:618px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:620px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:608px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:412px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:412px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 812px)  and (max-device-width: 412px) and (min-device-width: 411px) and (orientation: portrait){
	/*********************************************/
	/*
	(Chrome Browser - Android Medium)
	DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:362px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:608px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:608px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:610px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:598px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:412px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:412px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:86% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 772px)  and (max-device-width: 412px) and (min-device-width: 411px) and (orientation: portrait){
	/*********************************************/
	/*
	(Chrome Browser - Pixel 7 PRO)
	DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:362px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:558px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:558px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:560px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:548px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:412px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:412px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:85% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 733px)  and (max-device-width: 412px) and (min-device-width: 411px) and (orientation: portrait){
	/*********************************************/
	/*
	SIMULATOR (PHONE) - Google Pixel Fold
	
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:362px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:593px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:magenta;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:593px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:595px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:853px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:412px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:412px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:85% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- and (orientation: portrait)*/
@media only screen and (max-height: 677px)  and (max-device-width: 412px) and (min-device-width: 411px) {
	/*********************************************/
	/*
	(Chrome Browser - Pixel Fold) Portrait
	DONE
	*/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:361px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:458px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:violet;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:458px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:460px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:448px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:412px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:412px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:86% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 660px)  and (max-device-width: 412px) and (min-device-width: 412px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:362px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:458px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:458px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:460px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:448px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:412px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:412px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:91% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 823px)  and (max-device-width: 411px) and (min-device-width: 411px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:361px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:618px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:618px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:620px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:608px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:411px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:411px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:86% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 731px)  and (max-device-width: 411px) and (min-device-width: 411px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:50px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:361px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:528px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:528px !important;
		width:99.9% !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:530px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:518px !important;
		width:100% !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:411px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:411px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:86% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */




/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- *//* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- and (orientation: portrait)*/

@media only screen and (max-height: 781px)  and (max-device-width: 701px) and (min-device-width: 700px) {
	/*********************************************/
	/*
		LANDSCAPE
		Google Pixel Fold - SIMULATOR (TABLET) - portrait
		June 22 - DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:630px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:540px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:red;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:540px !important;
		width:700px !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:700px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:580px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:530px !important;
		width:700px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:700px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:700px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.3);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:70% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
@media only screen and (max-height: 781px)  and (max-device-width: 701px) and (min-device-width: 700px) and (orientation: portrait){
	/*********************************************/
	/*
		LANDSCAPE
		Google Pixel Fold - SIMULATOR (TABLET) - portrait
		June 22 - DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:630px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:540px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:cornflowerblue;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:540px !important;
		width:700px !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:700px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:580px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:530px !important;
		width:700px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:700px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:700px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.3);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:70% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 1280px)  and (max-device-width: 800px) and (min-device-width: 800px){
	/*********************************************/
	/*
		PORTRAIT (MAIN)
		Google Pixel 
		Google Medium Pixel - SIMULATOR (TABLET)
		June 22 - DONR
	*/
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:730px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:960px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:greenyellow;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:960px !important;
		width:800px !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:800px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:1000px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:1080px !important;
		width:800px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:800px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:800px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:67% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 1100px)  and (max-device-width: 800px) and (min-device-width: 800px){
	/*********************************************/
	/*
	(Chrome Browser - Android Medium Tablet Open Portrait)
	DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:730px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:860px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:860px !important;
		width:800px !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:800px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:900px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:850px !important;
		width:800px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:800px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:800px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:67% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}

/* -----------------------------------------------------------------------------------------  and (orientation: landscape)*/
@media only screen and (max-height: 912px)  and (max-device-width: 1368px) and (min-device-width: 1368px){
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1298px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:690px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:maroon;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:690px !important;
		width:1368px !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:blue;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1368px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:730px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:680px !important;
		width:1368px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1368px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1368px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  and (orientation: landscape)*/
@media only screen and (max-height: 1024px)  and (max-device-width: 1366px) and (min-device-width: 1366px){
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1296px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:850px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:850px !important;
		width:1366px !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1366px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:890px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:840px !important;
		width:1366px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1366px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1366px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:61% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 1366px)  and (max-device-width: 1024px) and (min-device-width: 1024px) {
	/*********************************************/
	/*
		IPAD PRO 12 INCH - SIMULATOR (TABLET)
		
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:954px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:1060px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.8);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:1060px !important;
		width:1024px !important;
		position: absolute;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1024px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:1100px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:1050px !important;
		width:1024px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1024px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1024px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 1366px)  and (max-device-width: 1024px) and (min-device-width: 1024px) and (orientation: landscape){
	/*********************************************/
	/*
		IPAD PRO 12 INCH - SIMULATOR (TABLET) - Landscape & Portrait (don't modify for portrait)
		DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:954px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:760px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.8);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:760px !important;
		width:1366px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1366px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:800px !important;
		table-layout: fixed;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:750px !important;
		width:1366px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1366px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1366px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:61% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- and (orientation: landscape)*/
@media only screen and (max-height: 981px)  and (max-device-width: 1367px) and (min-device-width: 1366px) {
	/*********************************************/
	/*
	(Safari Browser - ipad pro 12.9 inch) SIMULATOR
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1296px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:507px!important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:blanchedalmond;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:507px !important;
		width:1366px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,100, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1366px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:547px !important;
		table-layout: fixed;
		width:80% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:544px !important;
		width:1366px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1366px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1366px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- and (orientation: landscape) */
@media only screen and (max-height: 853px)  and (max-device-width: 1280px) and (min-device-width: 1280px){
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1210px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:640px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:palegreen;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:640px !important;
		width:1280px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(89,255,250, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1280px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:680px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:630px !important;
		width:1280px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1280px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1280px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  */
@media only screen and (max-height: 800px)  and (max-device-width: 1280px) and (min-device-width: 1280px) and (orientation: portrait){
	/*********************************************/
	/*********************************************/
	/*
		LANDSCAPE (MAIN)
		Google Pixel 
		Google Medium Pixel - SIMULATOR (TABLET)
		June 23 - DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1210px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:510px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:darkgreen;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:510px !important;
		width:555px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:555px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:550px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:500px !important;
		width:555px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:600px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:555px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:75% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 800px)  and (max-device-width: 1280px) and (min-device-width: 1280px) and (orientation: landscape){
	/*********************************************/
	/*
		LANDSCAPE
		June 22 - 
	*/
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1210px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:550px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:550px !important;
		width:1280px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1280px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:590px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:590px !important;
		width:1280px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1280px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1280px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:61% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- and (orientation: landscape)*/
@media only screen and (max-height: 620px)  and (max-device-width: 1280px) and (min-device-width: 1280px) {
	/*********************************************/
	/*
	(Chrome Browser - Android Medium Tablet Open Landscape)
	DONE
	*/
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1210px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:380px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:380px !important;
		width:1280px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1280px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:420px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:370px !important;
		width:1280px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1280px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1280px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:60.5% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  */
@media only screen and (max-height: 800px)  and (max-device-width: 1220px) and (min-device-width: 1220px) and (orientation: landscape){
	/*********************************************/
	/*
		LANDSCAPE
		June 22 - 
	*/
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1150px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:610px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:cornflowerblue;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:610px !important;
		width:1280px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1280px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:650px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:600px !important;
		width:1280px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1280px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1280px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  and (orientation: portrait)*/
@media only screen and (max-height: 518px)  and (max-device-width: 843px) and (min-device-width: 841px){
	/*********************************************/
	/*
	(Chrome Browser - Pixel Fold) Open Landscape)
	DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:771px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:310px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:orange;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:310px !important;
		width:99.9% !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:841px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:350px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:368px !important;
		width:842px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:842px !important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:842px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:66% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 1194px)  and (max-device-width: 834px) and (min-device-width: 834px){
	/*********************************************/
	/*
		IPAD PRO 11 INCH - SIMULATOR (TABLET)
		DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:764px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:910px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.8);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:910px !important;
		width:834px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:834px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:950px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:900px !important;
		width:834px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:834px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:834px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:67% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 1194px)  and (max-device-width: 834px) and (min-device-width: 834px) and (orientation: landscape){
	/*********************************************/
	/*
		IPAD PRO 11 INCH - SIMULATOR (TABLET)
		DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:764px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:560px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.8);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:560px !important;
		width:1194px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1194px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:600px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:550px !important;
		width:1194px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1194px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1194px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:62% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- and (orientation: landscape)*/
@media only screen and (max-height: 814px)  and (max-device-width: 1194px) and (min-device-width: 1194px) {
	/*********************************************/
	/*
	other
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1124px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:507px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:salmon;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:507px !important;
		width:1180px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,100, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1180px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:547px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:544px !important;
		width:1180px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1180px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1180px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  and (orientation: landscape)*/
@media only screen and (max-height: 790px)  and (max-device-width: 1194px) and (min-device-width: 1194px){
	/*********************************************/
	/*
	(Safari Browser - ipad pro 11 inch) SIMULATOR
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1124px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:507px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:fuchsia;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:507px !important;
		width:1180px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,100, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1180px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:547px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:544px !important;
		width:1180px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1180px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1180px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- and (orientation: landscape)*/
@media only screen and (max-height: 760px)  and (max-device-width: 1194px) and (min-device-width: 1194px) {
	/*********************************************/
	/*
	(Safari Browser - ipad PRO 11 inch)
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1124px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:507px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:darkolivegreen;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:507px !important;
		width:1180px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,100, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1180px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:547px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:544px !important;
		width:1180px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1180px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1180px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- and (orientation: landscape)*/
@media only screen and (max-height: 715px)  and (max-device-width: 1194px) and (min-device-width: 1194px) {
	/*********************************************/
	/*
	(Chrome Browser - ipad PRO 11 inch)
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1124px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:507px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:hotpink;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:507px !important;
		width:1180px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,100, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1180px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:547px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:544px !important;
		width:1180px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1180px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1180px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- and (orientation: landscape)*/
@media only screen and (max-height: 713px)  and (max-device-width: 1194px) and (min-device-width: 1194px) {
	/*********************************************/
	/*
	(Firefox Browser - ipad PRO 11 inch)
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1124px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:507px!important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:hotpink;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:507px !important;
		width:1180px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,100, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1180px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:547px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:544px !important;
		width:1180px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1180px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1180px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- and (orientation: landscape)*/
@media only screen and (max-height: 705px)  and (max-device-width: 1194px) and (min-device-width: 1194px) {
	/*********************************************/
	/*
	(Edge Browser - ipad PRO 11 inch)
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1124px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:507px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:hotpink;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:507px !important;
		width:1180px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,100, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1180px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:547px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:544px !important;
		width:1180px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1180px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1180px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height:1734px)  and (max-device-width:825px) and (min-device-width: 818px) {
	/*********************************************/
	/*
		IPAD AIR 5 GEN & IPAD 10 GEN - SIMULATOR (TABLET)
		
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1180px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:1510px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.8);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:1510px !important;
		width:1251px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1251px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:1550px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:1500px !important;
		width:1251px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1251px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1251px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:62% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height:1180px)  and (max-device-width:820px) and (min-device-width: 820px) and (orientation: landscape){
	/*********************************************/
	/*
		IPAD AIR 5 GEN & IPAD 10 GEN - SIMULATOR (TABLET)
		DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1180px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:610px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.8);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:610px !important;
		width:1251px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1251px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:650px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:600px !important;
		width:1251px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1251px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1251px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:61% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- and (orientation: landscape) */
@media only screen and (max-height: 777px)  and (max-device-width: 1181px) and (min-device-width: 1180px){
	/*********************************************/
	/*
	(Safari Browser - ipad 10 generation)
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1110px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:610px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:mediumvioletred;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:610px !important;
		width:1180px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,100, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1180px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:650px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:600px !important;
		width:1180px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1180px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1180px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  and (orientation: landscape)*/
@media only screen and (max-height: 714px)  and (max-device-width: 1155px) and (min-device-width: 1155px){
	/*********************************************/
	/*
	other
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1085px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:507px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:peru;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:507px !important;
		width:1180px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,100, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1155px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:547px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:544px !important;
		width:1180px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1180px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1180px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  and (orientation: landscape)*/
@media only screen and (max-height: 712px)  and (max-device-width: 1138px) and (min-device-width: 1138px){
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1138px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:510px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:510px !important;
		width:1138px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1138px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1138px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_base_functional_full_table {
		height:550px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:560px !important;
		width:1138px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1138px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1138px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:63% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  */
@media only screen and (max-height: 1133px)  and (max-device-width: 744px) and (min-device-width: 744px){
	/*********************************************/
	/*
		IPAD MINI - SIMULATOR (TABLET)
		DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:674px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:860px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.8);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:860px !important;
		width:744px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:744px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:900px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:850px !important;
		width:744px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:744px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:744px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:69% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------- */
@media only screen and (max-height: 1133px)  and (max-device-width: 744px) and (min-device-width: 744px) and (orientation: landscape){
	/*********************************************/
	/*
		IPAD MINI - SIMULATOR (TABLET)
		DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:674px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:485px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.8);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:485px !important;
		width:1133px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1133px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:525px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:475px !important;
		width:1133px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1133px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1133px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:63% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}

/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  and (orientation: landscape)*/
@media only screen and (max-height: 700px)  and (max-device-width: 1133px) and (min-device-width: 1133px){
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1063px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:507px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:goldenrod;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:507px !important;
		width:1133px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1133px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:547px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:544px !important;
		width:1133px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1133px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1133px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  and (orientation: landscape)*/
@media only screen and (max-height: 670px)  and (max-device-width: 1133px) and (min-device-width: 1133px){
	/*********************************************/
	/*
	(Safari Browser - ipad Mini)
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:1063px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:490px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:goldenrod;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:490px !important;
		width:1133px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1133px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:530px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:480px !important;
		width:1133px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1133px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1133px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  and (orientation: landscape)*/
@media only screen and (max-height: 768px)  and (max-device-width: 1024px) and (min-device-width: 1024px){
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:954px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:520px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:520px !important;
		width:1024px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1024px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:98% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:560px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:620px !important;
		width:1024px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1024px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1024px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:64% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------and (orientation: landscape) */
@media only screen and (max-height: 600px)  and (max-device-width: 1024px) and (min-device-width: 1024px) {
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:954px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:460px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:460px !important;
		width:1024px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:1024px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:500px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:500px !important;
		width:1024px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:1024px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:1024px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  and (orientation: landscape)*/
@media only screen and (max-height: 600px)  and (max-device-width: 960px) and (min-device-width: 960px){
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:890px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:460px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:460px !important;
		width:954px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:954px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:500px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:450px !important;
		width:954px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:960px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:410px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:65% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  and (orientation: landscape)*/
@media only screen and (max-height: 540px)  and (max-device-width: 720px) and (min-device-width: 720px){
	/*********************************************/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:650px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:410px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:rgba(255,255,255, 0.7);
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:410px !important;
		width:720px !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:720px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:450px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:400px !important;
		width:720px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:720px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:720px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:70% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ----------------------------------------------------------------------------------------- */
/* -----------------------------------------------------------------------------------------  and (orientation: landscape)*/
@media only screen and (max-height: 658px)  and (max-device-width: 702px) and (min-device-width: 701px){
	/*********************************************/
	/*
	(Chrome Browser - Pixel Fold) Open Portrait)
	DONE
	*/
	/*********************************************/
	/*********************************************/
	.mobile_profile_header_menu_table{
		z-index: 999;
		height:70px !important;
		width:70px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(0, 0, 0, 0.0);
		background-size: 50px 50px;
		background-repeat: no-repeat;
		background-position:center;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;

	}
	.mobile_profile_header_table {
		z-index: 999;
		height:70px !important;
		width:631px !important;
		line-height: 70px;
		border-spacing: 0px;
		border: hidden;
		background-color: transparent;
		text-align: center !important;
		vertical-align: text-top !important;
		/* THEPEOPLESFORM-01.png - ration: 10.54   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
		background-size: 316.2px 30px;
		*/
		/* THEPEOPLESFORM-02.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-03.png - ration: 9.069   
		background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
		background-size: 272.07px 30px;
		*/
		/* THEPEOPLESFORM-01.png - ration: 10.54  */ 
		/*background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);*/
		/*
		background-color:rgba(255,255,255, 0.0);
		background-size: 316.2px 30px;
		background-size: 263.5px 25px;
		*/
		/*
		background-size: 210px 20px;

		background-position:left;
		background-repeat:no-repeat;
		*/
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_container {
		height:440px !important;
		width:99.9% !important;
		top: 1px;
		margin-top:1px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background-color:purple;
		overflow-y: hidden;  /* Hide vertical scrollbar */
		overflow-x: hidden; /* Hide horizontal scrollbar */
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */
	}
	/*********************************************/
	.mainBodyTable {
		height:440px !important;
		width:99.9% !important;
		position: relative;
		margin-top: 0px;
		border-spacing: 0px;
		border: 1px solid rgba(209,209,209, 0.0);
		background: rgba(255,255,255, 0.8);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mainBodyTable_td{
		height:90% !important;
		width:100% !important;
		position: relative;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
		vertical-align:top !important;
	}
	/* ################################# */
	.mobile_defaultbody_table {
		height:99% !important;
		width:701px !important;
		top:0;
		margin-top: 2px;
		border-spacing: 0px;
		border:hidden;
		background-color:rgba(255,255,255, 0.0);
	}
	.mobile_defaultbody_table_td {
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/* ################################# */
	.mobile_lefttbody_table_td{
		height:99% !important;
		width:100% !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(255,255,255, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	.mobile_lefttbody_functional_full_table{
		height:99% !important;
		width:250px !important;
		text-align:left !important;
		vertical-align:top !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		background:  rgba(204,204,204, 0.7);
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	/*********************************************/
	.mobile_base_functional_full_table {
		height:480px !important;
		width:100% !important;
		border-spacing: 0px;
		border:1px solid rgba(233,233,233, 0.1);
		/*background: url(https://glbl-resources-public.s3.amazonaws.com/logo/THEPEOPLESFORM-LOGO-BRANDMARK-1000PX.png) rgba(255,255,255, 0.7);*/
		background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.7);
		background-size: 300px 300px;
		background-repeat: no-repeat;
		background-position:center;
		text-align:left !important;
		vertical-align:top !important;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	} 
	.mobile_base_functional_full_table_td {
		width:100% !important;
		border:hidden;
		border-spacing: 0px;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:top !important;
	}
	/*********************************************/
	.base_functional_table_td {
		height:500px !important;
		width:701px !important;
		border-spacing: 0px;
		border:hidden;
		vertical-align: top;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
	}
	/*********************************************/
	.mobile_site_footer_table_full {
		height:40px;
		width:701px!important;
		line-height: 40px !important;
	}
	.mobile_site_open_footer_table_full_phrase {
		height:40px;
		width:701px !important;
		line-height: 25px !important;
		margin-top: 0px;
		margin-left: 00px;
		border: 1px solid rgba(255,255,255, 0.4);
		background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
		background-size: 35px 35px;
		background-repeat: no-repeat;
		background-position:69.5% 45%;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		vertical-align:middle;
		text-align:center;
		font-size: .70em;
		font-weight: 600;
		color: rgba(0, 0, 0, 0.9);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

		*/ 
	}
	.site_copyright_footer_button{
		height:40px !important;
		width:100% !important;
		line-height: 40px;
		margin-left: 0px;
		text-decoration:none;
		border:hidden;
		background-color: rgba(255,255,255, 0.1); 
		display: inline-block;
		vertical-align:top;
		text-align:center;
		font-size: .70em;
		font-weight: 500;
		-webkit-box-shadow: none !important;
		-moz-box-shadow: none !important;
		box-shadow: none !important;
		color: rgba(15, 5, 26, 0.6);
		text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
		/*
		color: #fff;
		text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
		*/ 
	}
}
/* ################################# *//* ################################# *//* ################################# */
/* ################################# *//* ################################# *//* ################################# */




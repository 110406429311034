/*   -------------------------------------------------- */
/*   -------------------------------------------------- */
/*   ------------    main_feed         ---------------- */
/*   
/*
/*  
/*  INFINITE_SCROLL_MANAGER_FRAME
/*  FEED MENU PANEL
/*  MATRIX MENU PANEL
/*
/*  
/*
/*  
/*  
/*  	
/*
/*  
/* 
/*
/*
/*		
/*  
/*
/*
/*  
/*  	
/*
/*  
/* 
/*
/*
/*		
/*  
/*
/*
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001
CONTRUCTION
    BORDERS
        TABLE OUTSIDE   border: 1px solid rgba(209,209,209, 0.7)
        TD OUTSIDE      border: 1px solid rgba(204,204,204, 0.7)

        TABLE INSIDE    border: 1px solid rgba(204,204,204, 0.0)
        TD INSIDE       border: 1px solid rgba(204,204,204, 0.2)

        BUTTON          border: 1px solid rgba(204,204,204, 0.3)
		
    BACKGROUND
        MAIN            background-color: rgba(255,255,255, 0.7);
        PAGE            background-color: rgba(255,255,255, 0.2);
        FORM PAGE       background-color: rgba(239,239,239, 0.7);
        MENU            background-color: rgba(239,239,239, 0.7);





    rgba(255,255,255, 0.7) = #FFF (white)
    rgba(0,0,0, 0.7) =  (black)
    rgba(239,239,239, 0.7) = #EFEFEF (grey)
    rgba(204,204,204, 0.7) = #CCC (dark border)
    rgba(0,255,0, 0.7)  =(green)
    rgba(255,255,0, 0.7) =(yellow)
    rgba(255,0,0, 0.7)  =(red)
    rgba(15,10,222,0.7)  =(blue)
    rgba(191, 85, 236,0.7) = (purple)


    rgba(15, 5, 26, 0.7) =  (black scheme)
    rgba(10, 245, 92, 0.7)  =(green scheme)
    rgba(4, 144, 242, 0.7)  =(blue scheme)
    rgba(255, 28, 255, 0.7)  =(pink scheme)
    rgba(204, 204, 204, 0.7)  =(grey scheme)
    rgba(252, 71, 71, 0.7)  =(red scheme)
    rgba(255, 255, 128, 0.7) =(yellow scheme)
    204,204,204
                                                                            event_feed_OTHER_activity_follow_neutral container_scroll  

/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */
/************************//************************//************************/
/************************//*	INFINITE_SCROLL_MANAGER_FRAME	*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 
/*********************************************/
/*********************************************/
/* -----------------------------------------------------------------------------------------/   */
/* ----------------------------------------------------------------------------------------- */
@media screen and (max-device-width: 500px) and (min-device-width: 200px) {
	
.feed_body_table {
    height:650px !important;
    width:635px !important;
    top:0;
    background-color:rgba(255,255,255, 0.7);
    margin:0;
    padding:0;	
}
.feed_body_table_full {
    height:650px;
    width:635px;
    margin:0;
    padding:0;		
}

.feed_body_table_td {
    height:650px !important;
    width:635px;
    text-align:left !important;
    vertical-align:top !important;
    background-color:rgba(255,255,255, 0.7);
    margin:0;
    padding:0;	
}

.feed_panel_Table_td{
    height:624px;
    width:310px  !important;
    vertical-align: text-top;
    position:fixed;
}

.controlFeed_ManagerTable_div {
    /*
    feed_panel_menu_land
    height:650px;
    */
    height:740px;
    width:310px;
	padding:0;
    top: 0px;
	position:absolute;
	border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.7);
    background-color:rgba(255,255,255, 0.7);
    
}
.container_scroll {
    /*
    container_page_time_frame_standard
    max-height: 2000px;
    max-height: 735px;
    */
    min-height: 735px;
	width:635px !important;
	margin-top: 0;
	margin-left: 0;
	/*
    padding: 0;
    position: absolute; 
    */
    padding: 0px;
	background-color: rgba(239,239,239, 0.3);
    
    display: block;
    overflow: hidden;
    overflow-y: auto;
    /*overflow-y: hidden;  Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.container_scroll::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.scroll-status {
  display: none; /* hidden by default */
  padding-top: 20px;
  border-top: 1px solid #DDD;
  text-align: center;
  color: #777;
}
/************************//*	ACTIVITY_FEED	*/
.container_scroll_load {
    /*
    container_scroll
    */
    max-height: 645px;
	width:632px !important;
    position: relative; 
	margin-top: 5px !important;
	margin-left: 2px;
	vertical-align: top;
	text-align: left;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,255,255, 0.8);
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
}
.container_scroll_seperation_frame {
	height: 20px;
	width:632px !important;
    display: block;
    overflow: hidden;
    overflow-y: auto;
	margin-top: 10px !important;
	margin-left: 0px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border-top: 2px dashed rgba(204,204,204, 0.8);
    border-bottom: 1px solid rgba(204,204,204, 0.0);
	border-left: 1px solid rgba(204,204,204, 0.0);
    border-right: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,239, 0.0);
}
.container_scroll_tail_frame {
	height: 40px;
	width:632px !important;
	margin-top: 2px !important;
	margin-left: 5px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,239, 0.0);
}
.container_scroll_load_tail {
	height: 40px;
	width:632px !important;
	position: absolute;
	margin-top: 5px !important;
	margin-left: 2px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(0,239,0, 0.8);
}
.container_scroll_complete_tail {
	height: 40px;
	width:632px !important;
	position: absolute;
	margin-top: 0px !important;
	margin-left: 0px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,0,0, 0.8);
}
/**********************************************/
.container_scroll_extention1 {
    z-index: 100;
	height: 110px;
	width:630px !important;
	margin-top: 2px !important;
	margin-left: 4px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(23,239,239, 0.2);
}
/**********************************************/
.container_scroll_extention2 {
    z-index: 100;
	height: 156px;
	width:630px !important;
	margin-top: 2px !important;
	margin-left: 4px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,239, 0.7);
}
.container_scroll_extention2_01 {
    z-index: 100;
	height: 145px;
	width:160px !important;
    /*
	position: absolute;
    */
	margin-top: 1px !important;
	margin-left: 5px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,239, 0.7);
}
.container_scroll_extention2_02 {
    z-index: 100;
	height: 145px;
	width:160px !important;
    /*
	position: absolute;
    */
	margin-top: 2px !important;
	margin-left: 1px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,239, 0.7);
}
.container_scroll_extention2_03 {
    z-index: 100;
	height: 145px;
	width:291px !important;
    /*
	position: absolute;
    */
	margin-top: 2px !important;
	margin-left: 1px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,239, 0.7);
}
/**********************************************/
.container_scroll_extention3 {
	height: 310px;
	width:630px !important;
	margin-top: 2px !important;
	margin-left: 4px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,239, 0.7);
}

.container_scroll_extention3_01 {
	height: 145px;
	width:310px !important;
    /*
	position: absolute;
    */
	margin-top: 2px !important;
	margin-left: 2px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,239, 0.7);
}
.container_scroll_extention3_02 {
	height: 145px;
	width:310px !important;
    /*
	position: absolute;
    */
	margin-top: 151px !important;
	margin-left: 2px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,239, 0.7);
}
.container_scroll_extention3_03 {
	height: 295px;
	width:312px !important;
    /*
	position: absolute;
    */
	margin-top: 2px !important;
	margin-left: 317px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,239, 0.7);
}
/**********************************************/
.container_scroll_extention4 {
	height: 310px;
	width:630px !important;
	margin-top: 2px !important;
	margin-left: 4px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(239,239,57, 0.2);
}
/**********************************************/
.container_scroll_extention5 {
	height: 310px;
	width:630px !important;
	margin-top: 2px !important;
	margin-left: 4px;
	vertical-align: top;
	text-align: left;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(54,239,239, 0.2);
}
/*********************************************
.NOTIFICATION_EVENT_page01 {
	height:420px !important;
	width:628px !important;
	margin-top: 5px;
	margin-left: 2px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background-color: rgba(255,255,255, 0.7);
    vertical-align: top;
}
.POPUP_EVENT_page01 {
	height:610px !important;
	width:628px !important;
	margin-top: 5px;
	margin-left: 2px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background-color: rgba(239,239,239, 0.2);
    vertical-align: top;
}
.MEDIA_EVENT_page01 {
	height:420px !important;
	width:628px !important;
	vertical-align: top;
	margin-top: 5px;
	margin-left: 2px;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(191,85,236, 0.2);
}


*/
/**********************************************/
/**********************************************/
/*    
    EVENT - STRUCTURE
        
        PARENT_SPECIAL_EVENT_page01
        STANDARD_SPECIAL_EVENT_page01
*/

.POLLING_EVENT_page01 {
	height:420px !important;
	width:628px !important;
	vertical-align: top;
	margin-top: 5px;
	margin-left: 2px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255, 255, 128, 0.2);
}
.GLOBAL_EVENT_page01 {
	height:420px !important;
	width:628px !important;
	vertical-align: top;
	margin-top: 5px;
	margin-left: 2px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(47,239,239, 0.2);
}





.BUSINESS_EVENT_page01{
	height:320px !important;
	width:628px !important;
	vertical-align: top;
	margin-top: 5px;
	margin-left: 2px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(255,125,0, 0.7);
}

.USER_EVENT_page01{
	height:320px !important;
	width:628px !important;
	vertical-align: top;
	margin-top: 5px;
	margin-left: 2px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.0);
	background-color: rgba(252, 71, 71, 0.7);
}
/**********************************************/
/**********************************************//**********************************************/
/*
container_page_header_frame container_scroll_complete_tail
*/
.container_page_event_business_holiday_header_title {
	z-index: 100;
	height:110px !important;
	width:460px !important;
    line-height: 35px;
	position: absolute;
	margin-top: 120px;
	margin-left: 150px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.5);
    background-color: rgba(255,255,255, 0.1);
    vertical-align: top absolute;
    text-align: left;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 1.81em !important;
    font-weight: 600;
    text-indent: 0px;
}
.container_page_event_business_holiday_header_qr{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 5;
	height:120px !important;
	width:120px !important;
	position:absolute;
    display:inline-block;
    margin-top: 115px;
	margin-left: 20px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    background: url(../images/buttons/clear_img.png) rgba(100,255,255, 0.5);
	background-size: 115px 115px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.container_page_profile_header_title {
	z-index: 100;
	height:110px !important;
	width:460px !important;
    line-height: 35px;
	position: absolute;
	margin-top: 110px;
	margin-left: 90px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.5);
    background-color: rgba(255,255,255, 0.1);
    vertical-align: top absolute;
    text-align: left;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 1.81em !important;
    font-weight: 600;
    text-indent: 0px;
}

.container_page_header_title{
	z-index: 100;
	height:110px !important;
	width:460px !important;
    line-height: 35px;
	position: absolute;
	margin-top: 110px;
	margin-left: 90px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.5);
    background-color: rgba(255,255,255, 0.1);
    vertical-align: top absolute;
    text-align: left;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 1.81em !important;
    font-weight: 600;
    text-indent: 0px;
}
/**********************************************/
/**********************************************//**********************************************/
.feed_private_action {
	z-index: 100;
	height:35px !important;
	width:35px;
	position: absolute;
	display: inline-block;
	margin-top: 6px;
	margin-left: 445px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	background: url(../images/buttons/account_summary_private.png) rgba(252, 71, 71, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
    color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}
.feed_public_action {
	z-index: 100;
	height:37px !important;
	width:35px;
	position: absolute;
	display: inline-block;
	margin-top: 6px;
	margin-left: 445px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	background: url(../images/buttons/account_summary_public.png) rgba(10, 245, 92, 0.7);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
	color:#888888;
    font-size: 0.80em  !important;
    font-weight: 500;
	text-indent: 0px;
}



/**********************************************/
/**********************************************/
/*    
    EVENT - STRUCTURE
        PARENT

    container_scroll
    
        ITEM
            STANDARD_EVENT_page01
            
*/
.PARENT_EVENT_page01 {
	z-index: 0;
	height:600px !important;
	width:627px !important;
	margin-top: 40px;
	margin-left: 1px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background:rgba(239,239,239, 0.7);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.PARENT_STANDARD_EVENT_solo_page01{
	height:500px !important;
	width:628px !important;
	vertical-align: top;
	margin-top: 5px;
	margin-left: 2px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background-color: rgba(239,239,200, 0.2);
}

.PARENT_STANDARD_EVENT_page01{
	z-index: 0;
	height:600px !important;
	width:627px !important;
	margin-top: 40px;
	margin-left: 1px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background:rgba(239,239,239, 0.7);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}

.STANDARD_EVENT_page01 {
	z-index: 0;
	height:600px !important;
	width:627px !important;
	margin-top: 40px;
	margin-left: 1px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background:rgba(239,239,239, 0.7);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.OTHER_EVENT_page01{
	z-index: 0;
	height:600px !important;
	width:627px !important;
	margin-top: 40px;
	margin-left: 1px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background:rgba(239,239,239, 0.7);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
/**********************************************/
/**********************************************/
/*    
    HOLIDAY - STRUCTURE
        PARENT

    container_scroll
    
        ITEM
            STANDARD_SPECIAL_EVENT_page01
            
*/
.PARENT_SPECIAL_EVENT_solo_page01{
	height:500px !important;
	width:628px !important;
	vertical-align: top;
	margin-top: 5px;
	margin-left: 2px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background-color: rgba(239,239,200, 0.2);
}

.PARENT_SPECIAL_EVENT_page01{
	z-index: 0;
	height:600px !important;
	width:627px !important;
	margin-top: 40px;
	margin-left: 1px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background:rgba(239,239,239, 0.7);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.STANDARD_SPECIAL_EVENT_page01{
    /*
    container_page_OTHER_footer_frame_reporting
    event_feed_theme_display1
    */
    z-index: 0;
	height:600px !important;
	width:627px !important;
	margin-top: 40px;
	margin-left: 1px;
	padding: 0;
    border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
	background:rgba(239,239,239, 0.7);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.STANDARD_SPECIAL_EVENT_SCREEN_SCHIELD{
    z-index: 3;
    height:550px !important;
	width:627px !important;
    position: absolute;
	margin-top: 40px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.2);
    background: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.STANDARD_SPECIAL_EVENT_SCREEN{
    z-index: 1;
    height:550px !important;
	width:627px !important;
    position: absolute;
	margin-top: 40px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.2);
    background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}

.event_feed_theme_standard1{
    /*
    STANDARD_SPECIAL_EVENT_page01
    */
	z-index: 2;
	height:265.35px !important;
	width:628px !important;
	position:absolute;
    display:inline-block;
    /*
    margin-top: 1px;
    */
    margin-top: 38px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_feed_theme_standard2_1{
    /*
    STANDARD_SPECIAL_EVENT_page01
    */
	z-index: 2;
	height:265.35px !important;
	width:404.71px !important;
	position:absolute;
    display:inline-block;
    /*
    margin-top: 1px;
    */
    margin-top: 38px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_feed_theme_standard2_2{
    /*
    STANDARD_SPECIAL_EVENT_page01
    */
	z-index: 2;
	height:265.35px !important;
	width:255px !important;
	position:absolute;
    display:inline-block;
    /*
    margin-top: 1px;
    */
    margin-top: 38px;
	margin-left: 405px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.OTHER_SPECIAL_EVENT_page01{
    /*
    container_scroll_load
    container_page_OTHER_footer_frame_option1
	height:557.12px !important;
    
    height:595px !important;
    */
    z-index: 0;
    height:600px !important;
	width:627px !important;
	margin-top: 40px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.2);
    background:rgba(239,239,239, 0.7);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.OTHER_SPECIAL_EVENT_SCREEN_SCHIELD{
    z-index: 3;
    height:550px !important;
	width:627px !important;
    position: absolute;
	margin-top: 40px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.2);
    background: rgba(255,255,255, 0.3);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.OTHER_SPECIAL_EVENT_SCREEN{
    z-index: 1;
    height:550px !important;
	width:627px !important;
    position: absolute;
	margin-top: 40px;
	margin-left: 1px;
	border: 1px solid rgba(204,204,204, 0.2);
    background: url(../images/bkgrounds/event_header_default.png) rgba(255,255,255, 0.3);
    background-repeat: repeat repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:top;
	text-align:left;
}
.event_feed_theme_display1{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 2;
	height:557.12px !important;
	width:628px !important;
	position:absolute;
    display:inline-block;
    /*
    margin-top: 1px;
    */
    margin-top: 38px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_feed_theme_display2_1{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 2;
	height:277.032px !important;
	width:628px !important;
	position:absolute;
    display:inline-block;
    /*
    margin-top: 1px;
    */
    margin-top: 38px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_feed_theme_display2_2{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 2;
	height:277.032px !important;
	width:628px !important;
	position:absolute;
    display:inline-block;
    /*
    margin-top: 277px;
    */
    margin-top: 314px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.event_feed_theme_display3_1{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 2;
	height:184.3918px !important;
	width:628px !important;
	position:absolute;
    display:inline-block;
    /*
    margin-top: 1px;
    */
    margin-top: 38px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_feed_theme_display3_2{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 2;
	height:184.3918px !important;
	width:628px !important;
	position:absolute;
    display:inline-block;
    /*
    margin-top: 185px;
    */
    margin-top: 222px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_feed_theme_display3_3{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 2;
	height:184.3918px !important;
	width:628px !important;
	position:absolute;
    display:inline-block;
    /*
    margin-top: 370px;
    */
    margin-top: 407px;
	margin-left: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(204,204,204, 0.2);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/***************************************//***************************************/
.event_feed_activity_option1{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 5;
	height:110px !important;
	width:120px !important;
	position:absolute;
    display:inline-block;
    margin-top: 267px;
	margin-left: 20px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.1);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_feed_activity_option1_field_disabled{
    z-index: 4;
    height:110px !important;
    width: 110px !important;
    line-height:100%;
    position: absolute;
    margin-top: 267px;
	margin-left: 25px;
    border-spacing: 0px;
    border:hidden;
    border-radius: 100px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
	color:#000;
    font-size: 1.8em !important;
    font-weight: 700 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
.event_feed_activity_option2{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 5;
	height:110px !important;
	width:120px !important;
    line-height: 14px;
	position:absolute;
    display:inline-block;
    margin-top: 377px;
	margin-left: 20px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
	background-size: 120px 80px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    text-align:center;
    vertical-align:text-top;
    text-decoration:none;
}
.event_feed_activity_option2_label{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 5;
	height:40px !important;
	width:120px !important;
    line-height: 14px;
	position:absolute;
    display:inline-block;
    margin-top: 390px;
	margin-left: 20px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: rgba(255,255,255, 0.1);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    color:#000;
    font-size: 0.70em !important;
    font-weight: 600 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
.event_feed_activity_option2_field_disabled{
    z-index: 4;
    height:70px !important;
    width: 110px !important;
    line-height:100%;
    position: absolute;
    margin-top: 397px;
	margin-left: 25px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
    border-spacing: 0px;
    border:hidden;
	color:#000;
    font-size: 1.8em !important;
    font-weight: 700 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
.event_feed_activity_option3{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 5;
	height:100px !important;
	width:120px !important;
	position:absolute;
    display:inline-block;
    margin-top: 487px;
	margin-left: 20px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
	background-size: 120px 80px;
	background-repeat: no-repeat;
	background-position:1% 45%;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_feed_activity_option3_label{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 5;
	height:40px !important;
	width:120px !important;
    line-height: 14px;
	position:absolute;
    display:inline-block;
    margin-top: 495px;
	margin-left: 20px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.0);
    background: rgba(255,255,255, 0.1);
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    color:#000;
    font-size: 0.70em !important;
    font-weight: 600 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
.event_feed_activity_option3_field_disabled{
    z-index: 4;
    height:70px !important;
    width: 110px !important;
    line-height:100%;
    position: absolute;
    margin-top: 500px;
	margin-left: 25px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
    border-spacing: 0px;
    border:hidden;
	color:#000;
    font-size: 1.8em !important;
    font-weight: 700 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
/***************************************//***************************************/
.event_feed_activity_option4{
    /*
    OTHER_SPECIAL_EVENT_page01
    
    */
	z-index: 5;
	height:150px !important;
	width:320px !important;
	position:absolute;
    display:inline-block;
    margin-top: 247px;
	margin-left: 160px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.9);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_feed_activity_option4_field_disabled{
    z-index: 5;
    height:147px !important;
    width: 320px !important;
    line-height:100%;
    position: absolute;
    margin-top: 248px;
	margin-left: 161px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    border-spacing: 0px;
    border:hidden;
    color:#000;
    font-size: 1.10em !important;
    font-weight: 600 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
/***************************************//***************************************/
.event_feed_activity_option5{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 5;
	height:55px !important;
	width:135px !important;
	position:absolute;
    display:inline-block;
    margin-top: 247px;
	margin-left: 480px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.9);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_feed_activity_option5_field_label{
    z-index: 5;
    height:55px !important;
    width: 75px !important;
    line-height:100%;
    position: absolute;
    margin-top: 247px;
	margin-left: 480px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    border-spacing: 0px;
    border:hidden;
	color:#000;
    font-size: 1.10em !important;
    font-weight: 600 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
.event_feed_activity_option5_field_disabled{
    z-index: 5;
    height:55px !important;
    width: 60px !important;
    line-height:100%;
    position: absolute;
    margin-top: 247px;
	margin-left: 555px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    border-spacing: 0px;
    border:hidden;
	color:#000;
    font-size: 1.10em !important;
    font-weight: 600 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
/***************************************/
.event_feed_activity_option6{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 5;
	height:93px !important;
	width:135px !important;
	position:absolute;
    display:inline-block;
    margin-top: 304px;
	margin-left: 480px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.9);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.event_feed_activity_option6_field_label{
    z-index: 5;
    height:93px !important;
    width: 75px !important;
    line-height:100%;
    position: absolute;
    margin-top: 304px;
	margin-left: 480px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    border-spacing: 0px;
    border:hidden;
	color:#000;
    font-size: 1.10em !important;
    font-weight: 600 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
.event_feed_activity_option6_field_disabled{
    z-index: 5;
    height:93px !important;
    width: 60px !important;
    line-height:100%;
    position: absolute;
    margin-top: 304px;
	margin-left: 555px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    border-spacing: 0px;
    border:hidden;
	color:#000;
    font-size: 1.10em !important;
    font-weight: 600 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
/***************************************//***************************************/
.event_feed_activity_option7{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 5;
	height:100px !important;
	width:116px !important;
	position:absolute;
    display:inline-block;
    margin-top: 475px;
	margin-left: 500px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
	background-size:contain;
    background-repeat: no-repeat;
	-webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*
.event_feed_activity_option7_field_disabled{
    z-index: 5;
    height:125px !important;
    width: 125px !important;
    line-height:100%;
    position: absolute;
    margin-top: 430px;
	margin-left: 485px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,10, 0.5);
    border-spacing: 0px;
    border:hidden;
	color:#000;
    font-size: 1.8em !important;
    font-weight: 700 !important;
    text-align:center;
    vertical-align:middle;
    text-decoration:none;
}
*/
/***************************************/
/***************************************/
.container_page_header_frame {
	z-index: 100;
	height:38px !important;
	width:628px !important;
	position: absolute;
	background-color: rgba(239,239,239, 0.7);
	vertical-align: top;
	margin-top: 1px;
	margin-left: 0px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.5);
}
.container_page_header_frame_option1 {
	z-index: 100;
	height:35px !important;
	width:150px !important;
	position: absolute;
	margin-top: 2px;
	margin-left: 2px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(255,255,255, 0.9);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
	vertical-align: top;
}
.container_page_header_frame_option2 {
	z-index: 100;
	height:35px !important;
	width:35px;
	position: absolute;
	margin-top: 2px;
	margin-left: 440px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(255,255,255, 0.9);
	vertical-align: top;
}
.container_page_header_frame_option3 {
	z-index: 100;
	height:35px !important;
	width:50px !important;
	position: absolute;
	margin-top: 2px;
	margin-left: 480px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(255,255,255, 0.9);
	vertical-align: top;
}
.container_page_header_frame_option4 {
	z-index: 100;
	height:35px !important;
	width:90px !important;
	position: absolute;
	margin-top: 2px;
	margin-left: 535px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(255,255,255, 0.9);
	vertical-align: top;
}
/***************************************/
/***************************************/
.container_page_STANDARD_footer_frame {
    /*
    STANDARD_SPECIAL_EVENT_page01
    container_page_header_frame
    container_scroll_load
    OTHER_SPECIAL_EVENT_SCREEN
    */
	z-index: 10;
	height:48px !important;
	width:100px !important;
	position: absolute;
	vertical-align: top;
	margin-top: 595px;
	margin-left: 0px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background-color: rgba(239,239,239, 0.7);
}
.container_page_STANDARD_footer_frame_reporting {
	z-index: 11;
	height:40px !important;
	width:60px;
	position: absolute;
	margin-top: 595px;
	margin-left: 5px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(255,255,255, 0.9);
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_STANDARD_footer_frame_option_DISLIKE_DEFAULT {
	z-index: 11;
	height:40px !important;
	width:70px;
	position: absolute;
	margin-top: 595px;
	margin-left: 475px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/imageDislikedefault.png) rgba(255,255,255, 0.3);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_STANDARD_footer_frame_option_DISLIKE_ACTIVE {
	z-index: 11;
	height:40px !important;
	width:70px;
	position: absolute;
	margin-top: 595px;
	margin-left: 475px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	background: url(../images/buttons/imageDislikeactive.png) rgba(252,255,255, 0.3);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_STANDARD_footer_frame_option_DOESLIKE_DEFAULT {
	z-index: 11;
	height:40px !important;
	width:70px !important;
	position: absolute;
	margin-top: 595px;
	margin-left: 550px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/imageLikedefault.png) rgba(255,255,255, 0.3);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_STANDARD_footer_frame_option_DOESLIKE_ACTIVE {
	z-index: 11;
	height:40px !important;
	width:70px !important;
	position: absolute;
	margin-top: 595px;
	margin-left: 550px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/imageLikeactive.png) rgba(255,255,255, 0.3);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
/***************************************/
/***************************************/
.container_page_OTHER_footer_frame {
    /*
    container_page_header_frame
    container_scroll_load
    */
	z-index: 10;
	height:48px !important;
	width:100px !important;
	position: absolute;
	vertical-align: top;
	margin-top: 594px;
	margin-left: 0px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background-color: rgba(239,239,239, 0.7);
}
.container_page_OTHER_footer_frame_reporting {
	z-index: 11;
	height:40px !important;
	width:60px;
	position: absolute;
	margin-top: 595px;
	margin-left: 5px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(255,255,255, 0.9);
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_OTHER_footer_frame_option_DISLIKE_DEFAULT {
	z-index: 11;
	height:40px !important;
	width:70px;
	position: absolute;
	margin-top: 595px;
	margin-left: 475px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/imageDislikedefault.png) rgba(255,255,255, 0.3);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_OTHER_footer_frame_option_DISLIKE_ACTIVE {
	z-index: 11;
	height:40px !important;
	width:70px;
	position: absolute;
	margin-top: 595px;
	margin-left: 475px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
	background: url(../images/buttons/imageDislikeactive.png) rgba(255,255,255, 0.3);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_OTHER_footer_frame_option_DOESLIKE_DEFAULT {
	z-index: 11;
	height:40px !important;
	width:70px !important;
	position: absolute;
	margin-top: 595px;
	margin-left: 550px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/imageLikedefault.png) rgba(255,255,255, 0.3);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_OTHER_footer_frame_option_DOESLIKE_ACTIVE {
	z-index: 11;
	height:40px !important;
	width:70px !important;
	position: absolute;
	margin-top: 595px;
	margin-left: 550px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/imageLikeactive.png) rgba(255,255,255, 0.3);
	background-size: 30px 30px;
	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
/***************************************/
/***************************************/
.event_feed_OTHER_activity_follow_neutral{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 20;
	height:40px !important;
	width:100px !important;
	position:absolute;
    display:inline-block;
    margin-top: 50px;
	margin-left: 520px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(204, 204, 204, 0.7);
    /*
    239,239,239, 0.7
    255, 255, 128, 0.7
    255, 255, 255, 0.7
    */
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.event_feed_OTHER_activity_follow_positive{
    /*
    OTHER_SPECIAL_EVENT_page01
    */
	z-index: 20;
	height:40px !important;
	width:100px !important;
	position:absolute;
    display:inline-block;
    margin-top: 50px;
	margin-left: 520px;
	border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
	background-color:rgba(10, 245, 92, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
/***************************************/
/***************************************/
.container_page_menu_parent_listing {
    /*
    
    PARENT_EVENT_page01
    */
	z-index: 100;
	height:80px !important;
	width:628px !important;
    /**/
    position: absolute;
    
	margin-top: 420px;
	margin-left: 1px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.7);
    background-size: 627px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_menu_parent_listing_option1 {
	z-index: 100;
	height:75px !important;
	width:180px !important;
    position: absolute;
	margin-top: 422px;
	margin-left: 5px;
    border-spacing: 0px;
	border: 2px solid rgba(204,204,204, 0.4);
    border-radius: 5px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.9);
    background-size: 627px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
}
.container_page_menu_parent_listing_option2 {
	z-index: 100;
	height:65px !important;
	width:65px !important;
    position: absolute;
	margin-top: 427px;
	margin-left: 9px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-size: 55px 55px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
}
.container_page_menu_parent_listing_option3 {
    z-index: 100;
	height:60px !important;
	width:100px !important;
    position: absolute;
	margin-top: 430px;
	margin-left: 75px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background-color: rgba(44,255,255, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_menu_parent_listing_option4 {
	z-index: 100;
	height:75px !important;
	width:180px !important;
    position: absolute;
	margin-top: 422px;
	margin-left: 187px;
    border-spacing: 0px;
	border: 2px solid rgba(204,204,204, 0.4);
    border-radius: 5px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.9);
    background-size: 627px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
}
.container_page_menu_parent_listing_option5 {
	z-index: 100;
	height:65px !important;
	width:65px !important;
    position: absolute;
	margin-top: 427px;
	margin-left: 190px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-size: 55px 55px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
}
.container_page_menu_parent_listing_option6 {
    z-index: 100;
	height:60px !important;
	width:100px !important;
    position: absolute;
	margin-top: 430px;
	margin-left: 257px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background-color: rgba(44,255,255, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}

.container_page_menu_parent_listing_option7 {
	z-index: 100;
	height:75px !important;
	width:180px !important;
    position: absolute;
	margin-top: 422px;
	margin-left: 368px;
    border-spacing: 0px;
	border: 2px solid rgba(204,204,204, 0.4);
    border-radius: 5px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.9);
    background-size: 627px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
}
.container_page_menu_parent_listing_option8 {
	z-index: 100;
	height:65px !important;
	width:65px !important;
    position: absolute;
	margin-top: 427px;
	margin-left: 370px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.1);
    background-size: 55px 55px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
}
.container_page_menu_parent_listing_option9 {
    z-index: 100;
	height:60px !important;
	width:100px !important;
    position: absolute;
	margin-top: 430px;
	margin-left: 437px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background-color: rgba(44,255,255, 0.1);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_menu_parent_listing_option10 {
	z-index: 100;
	height:75px !important;
	width:75px !important;
    position: absolute;
	margin-top: 422px;
	margin-left: 552px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.4);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.9);
    background-size: 55px 55px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
/***************************************/
/***************************************/
/*
notificationsystem_table
*/
.feedsystem_table_main{
    z-index:2003;
    height:550px !important;
    width:951px !important;
	display:inline-block;
    background-color:rgba(255,255,255, 0.1) !important;
    vertical-align:text-top !important;
    text-decoration:none !important;
}
.feedsystem_menu_header{
    height:20px; 
    width:951px;
    position:relative;
    text-align:right;
	border: 1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.0);
    filter: Alpha(Opacity=0);
    opacity: 0.0;
}

.feedsystem_div_01{
    z-index:2004;
    height:600px; 
    width:951px;
	display: none;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(239,239,239, 0.8);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.feedsystem_div_02{
    height:600px;
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=80);
    opacity: 0.8;
}
.feedsystem_div_03{
    height:600px;
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}
.feedsystem_div_04{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}
.feedsystem_div_05{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}
.feedsystem_div_06{
    height:600px; 
    width:951px;
	display: none;
    position:relative;
    text-align:right;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(255,255,255, 0.9);
    filter: Alpha(Opacity=90);
    opacity: 0.9;
}
.feedsystem_div_01_map{
    /*
    z-index:10;
    */
    height:600px; 
    width:951px;
    margin-top: 1px;
	margin-left: 1px;
    position:absolute;
    vertical-align:top;
    text-align:left;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(239,239,239, 0.9);
}
.feedsystem_div_03_map{
    /*
    z-index:10;
    */
    height:600px; 
    width:951px;
    margin-top: 0px;
	margin-left: 0px;
    position:absolute;
    vertical-align:top;
    text-align:left;
	border:1px solid rgba(204,204,204, 0.5);
    background-color:rgba(239,239,239, 0.9);
}
.feedsystem_div_01_search{
    /*map_event_key_div*/
    z-index:100;
    
    height:40px !important;
    width:900px;
    margin-top: 60px;
	margin-left: 175px;
    position:absolute;
	border-spacing: 0px;
    vertical-align:middle;
	text-align:left !important;
	border:1px solid rgba(204,204,204, 0.0);
    background-color:rgba(255,255,255, 0.0);
}
.feedsystem_div_01_search_address_div_option{
    /*
    feedsystem_div_01_search
    */
    z-index:100;
    height:28px ;
    width: 48px;
	line-height: 28px !important;
	margin-top: 1px;
	margin-left: 460px;
    position: absolute !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    border-radius: 10px;
    background-color:rgba(252, 71, 71, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}
.feedsystem_div_01_search_address_div_close{
    z-index:1005;
    height:50px ;
    width: 100px;
	line-height: 50px !important;
	margin-top: 550px;
	margin-left: 847px;
    position: absolute !important;
    border-spacing: 0px;
    border: 1px solid rgba(239,239,239, 0.9);
    background-color:rgba(4, 144, 242, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
	text-align:center;
}

/***************************************/
/***************************************/


/***************************************/
/***************************************/


/***************************************/
/***************************************/
.container_page_media_type_frame_standard {
    /*
    STANDARD_EVENT_page01
    */
	z-index: 100;
	height:128px !important;
	width:128px !important;
	position: absolute;
	margin-top: 45px;
	margin-left: 5px;
    border-spacing: 0px;
	border-top: 1px solid rgba(204,204,204, 0.5);
	border-bottom: 1px solid rgba(204,204,204, 0.5);
	border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
    background-size: 128px 128px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: top;
}
.container_page_media_header_frame_standard {
	z-index: 100;
	height:228px !important;
	width:630px !important;
	position: absolute;
	margin-top: 42px;
	margin-left: 1px;
	border-top: 1px solid rgba(204,204,204, 0.5);
	border-bottom: 1px solid rgba(204,204,204, 0.5);
	border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/bkgrounds/event_header_default.png) rgba(239,239,239, 0.7);
    background-size:contain;
    background-repeat: repeat repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: top;
}
/*****************************    POP-UP: ;*********
.container_page_media_type_frame_popup {
	z-index: 100;
	height:128px !important;
	width:128px !important;
	position: absolute;
	margin-top: 45px;
	margin-left: 5px;
    border-spacing: 0px;
	border-top: 1px solid rgba(204,204,204, 0.5);
	border-bottom: 1px solid rgba(204,204,204, 0.5);
	border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
    background-size: 128px 128px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: top;
}
.container_page_media_header_frame_popup{
	z-index: 100;
	height:228px !important;
	width:630px !important;
	position: absolute;
	margin-top: 42px;
	margin-left: 1px;
	border-top: 1px solid rgba(204,204,204, 0.5);
	border-bottom: 1px solid rgba(204,204,204, 0.5);
	border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/bkgrounds/event_header_default.png) rgba(239,239,239, 0.7);
    background-size:contain;
    background-repeat: repeat repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: top;
}
.container_page_media_right_frame_popup{
	z-index: 100;
	height:200px !important;
	width:405px !important;
	position: absolute;
	margin-top: 412px;
	margin-left: 5px;
    border-spacing: 0px;
	border-top: 1px solid rgba(204,204,204, 0.5);
	border-bottom: 1px solid rgba(204,204,204, 0.5);
	border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
    background-color: rgba(239,239,239, 0.7);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: top;
}
*/
/**************************************
.container_page_media_type_frame_notification {
	z-index: 100;
	height:128px !important;
	width:128px !important;
	position: absolute;
	margin-top: 45px;
	margin-left: 5px;
    border-spacing: 0px;
	border-top: 1px solid rgba(204,204,204, 0.5);
	border-bottom: 1px solid rgba(204,204,204, 0.5);
	border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.3);
    background-size: 128px 128px;
    background-repeat: no-repeat;
    background-position:50% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: top;
}
.container_page_media_header_frame_notification {
	z-index: 100;
	height:228px !important;
	width:630px !important;
	position: absolute;
	margin-top: 42px;
	margin-left: 1px;
    border-spacing: 0px;
	border-top: 1px solid rgba(204,204,204, 0.5);
	border-bottom: 1px solid rgba(204,204,204, 0.5);
	border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/bkgrounds/event_header_default.png) rgba(239,239,239, 0.7);
    background-size:contain;
    background-repeat: repeat repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: top;
}
*/
/***************************************/
/***************************************/



/***************************************/
/***************************************/


/***************************************/
/***************************************/


/***************************************/
/***************************************/

/***************************************/
/***************************************/
.container_page_register_frame_standard {
	z-index: 100;
	height:70px !important;
	width:115px !important;
	position: absolute;
	background-color: rgba(47,255,255, 0.9);
	vertical-align: top;
	margin-top: 145px;
	margin-left: 515px;
	padding: 0;
    border-spacing: 0px;
	border-top: 1px solid rgba(204,204,204, 0.5);
	border-bottom: 1px solid rgba(204,204,204, 0.5);
	border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
}
.container_page_action_frame_standard {
	z-index: 100;
	height:45px !important;
	width:115px !important;
	position: absolute;
	background-color: rgba(47,255,255, 0.9);
	vertical-align: top;
	margin-top: 220px;
	margin-left: 515px;
	padding: 0;
    border-spacing: 0px;
	border-top: 1px solid rgba(204,204,204, 0.5);
	border-bottom: 1px solid rgba(204,204,204, 0.5);
	border-left: 1px solid rgba(204,204,204, 0.3);
    border-right: 1px solid rgba(204,204,204, 0.3);
}
.container_page_score_frame_standard {
	z-index: 100;
	height:100px !important;
	width:100px !important;
	position: absolute;
	margin-top: 45px;
	margin-left: 517px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    border-radius: 85px;
    /*
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.2);
    */
    background-color: rgba(255,255,255, 0.2);
    background-size: 85px 85px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    font-size: 1.90em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_time_frame_standard {
	z-index: 120;
	height:110px !important;
	width:110px !important;
	margin-top: 405px !important;
	margin-left: 533px !important;
    display: block;
    position: absolute;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    border-radius: 85px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    background-size: 180px 180px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: top;
}


.container_page_action_frame01_standard {
	z-index: 100;
	height:65px !important;
	width:130px !important;
	position: absolute;
	margin-top: 160px;
	margin-left: 502px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    border-radius: 85px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    background-size: 85px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 1.30em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_action_frame02_standard {
	z-index: 100;
	height:65px !important;
	width:130px !important;
	position: absolute;
	margin-top: 190px;
	margin-left: 502px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    border-radius: 85px;
    background: url(../images/buttons/clear_img.png) rgba(255,255,255, 0.0);
    background-size: 85px 80px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 2.25em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_action_frame02_time_wheel {
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.12em !important;
    font-weight: 500;
    text-indent: 0px;
}
/***************************************/
/***************************************/
.container_page_description_frame_standard {
	z-index: 100;
	height:105px !important;
	width:629px !important;
    position: absolute;
	margin-top: 270px;
	margin-left: 1px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.7);
    background-size: 627px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_description_frame_standard1_1 {
	z-index: 100;
	height:65px !important;
	width:100px !important;
    position: absolute;
	margin-top: 272px;
	margin-left: 2px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_description_frame_standard1_2 {
	z-index: 100;
	height:65px !important;
	width:420px !important;
    position: absolute;
	margin-top: 272px;
	margin-left: 101px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_description_frame_standard1_3 {
	z-index: 100;
	height:65px !important;
	width:109px !important;
    position: absolute;
	margin-top: 272px;
	margin-left: 520px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_description_frame_standard2_1 {
	z-index: 100;
	height:40px !important;
	width:100px !important;
    position: absolute;
	margin-top: 337px;
	margin-left: 2px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_description_frame_standard2_2 {
	z-index: 100;
	height:40px !important;
	width:420px !important;
    position: absolute;
	margin-top: 337px;
	margin-left: 101px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_description_frame_standard2_3 {
	z-index: 100;
	height:40px !important;
	width:109px !important;
    position: absolute;
	margin-top: 337px;
	margin-left: 520px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
/***************************************/
/*************************************container_page_menu_parent_listing**/
.container_page_menu_frame_standard {
	z-index: 100;
	height:40px !important;
	width:629px !important;
    position: absolute;
	margin-top: 380px;
	margin-left: 1px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(239,239,239, 0.7);
    background-size: 627px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_menu_frame_standard_option1 {
	z-index: 100;
	height:36px !important;
	width:120px !important;
    position: absolute;
	margin-top: 381px;
	margin-left: 1px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(239,45,239, 0.5);
    background-size: 627px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_menu_frame_standard_option2 {
	z-index: 100;
	height:36px !important;
	width:120px !important;
    position: absolute;
	margin-top: 381px;
	margin-left: 121px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(45,239,239, 0.5);
    background-size: 627px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_menu_frame_standard_option3 {
	z-index: 100;
	height:36px !important;
	width:70px !important;
    position: absolute;
	margin-top: 381px;
	margin-left: 240px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.0);
    background: url(../images/buttons/clear_img.png) rgba(45,45,239, 0.5);
    background-size: 627px 40px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 700;
    text-indent: 0px;
}
.container_page_menu_frame_standard_option4 {
	z-index: 100;
	height:36px !important;
	width:130px !important;
    position: absolute;
	margin-top: 381px;
	margin-left: 364px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.9);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 500;
    text-indent: 0px;
}
.container_page_menu_frame_standard_option5 {
	z-index: 100;
	height:36px !important;
	width:130px !important;
    position: absolute;
	margin-top: 381px;
	margin-left: 497px;
    border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.3);
    background: url(../images/buttons/butt_template.png) rgba(255,255,255, 0.9);
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position:1% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
    */
    font-size: 0.70em !important;
    font-weight: 500;
    text-indent: 0px;
}
/************************//************************//************************/
/************************//*	FEED MENU PANEL	*/
.feed_panel_menu_land {
    height:624px;
    width:290px  !important;
    position: absolute;
    vertical-align: top;
    text-align: left;
    top: 0px;
    margin-top: 2px;
    margin-left: 10px;
	background-color:transparent;
}
.feed_panel_menu_td1_land  {
    height:100px;
    width:290px  !important;
    background-color: rgba(239,239,239, 0.7);
	vertical-align: top;
    text-align: left;
}
.feed_panel_menu_td2_land {
    height:110px;
    width:290px !important;
    background-color: rgba(255,255,255, 0.7);
	vertical-align: top;
    text-align: left;
}
.feed_panel_menu_td3_land  {
    height:300px;
    width:290px !important; 
    background-color: rgba(239,239,239, 0.7);
	vertical-align: top;
    text-align: left;
}
.feed_panel_menu_td4_land  {
    height:105px;
    width:290px !important; 
    background-color: rgba(239,239,239, 0.7);
	vertical-align: top;
    text-align: left;
}
.feed_panel_menu_logo {
    height:100px;
    width:290px  !important;
    position: absolute;
    vertical-align: top;
    text-align: left;
    margin-top: 635px;
    margin-left: 10px;
	background-color:rgba(255,255,255, 0.7);
}
.feed_panel_menu_logo1 {
    height:100px;
    width:75px  !important;
    position: absolute;
    vertical-align: top;
    text-align: left;
    margin-top: 0px;
    margin-left: 10px;
	background: url(../images/logo/THEPEOPLESFORM/ThePeoplesForm-Logo-FINAL-200PX.png) rgba(255,255,255, 0.0);
    background-size: 74px 74px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.feed_panel_menu_logo2 {
    height:100px;
    width:180px  !important;
    position: absolute;
    vertical-align: top;
    text-align: left;
    margin-top: 0px;
    margin-left: 80px;
	background: url(../images/logo/THEPEOPLESFORM/THEPEOPLESFORM-LETTERMARK-FRAME-BLUE.png) rgba(255,255,255, 0.0);
    background-size: 180px 73px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.feed_panel_menu_logo3 {
    height:100px;
    width:75px  !important;
    position: absolute;
    vertical-align: top;
    text-align: left;
    margin-top: 0px;
    margin-left: 10px;
	background: url(../images/logo/MADEWITHTHEAPPLICATIONOFLOVE/MADEWITH-LOGO-01-BLUE.png) rgba(255,255,255, 0.0);
    background-size: 73px 73px;
    background-repeat: no-repeat;
    background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/************************//************************//************************/
/************************//*	MATRIX MENU PANEL	*/
.activity_matrixDate {
	height:60px !important;
	width:110px !important;
	line-height: 12px;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border: thin rgba(202,204,204, 0.2);
	background-color: rgba(47,204,204, 0.2);
	vertical-align:top;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: .70em;
	font-weight: 600;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.activity_matrixTime {
	height:60px !important;
	width:110px !important;
	line-height: 12px;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border: thin rgba(202,204,204, 0.2);
	background-color: rgba(47,204,204, 0.2);
	vertical-align:top;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: .70em;
	font-weight: 600;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.activity_matrixWeather {
	height:60px !important;
	width:62px !important;
	line-height: 12px;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border: thin rgba(202,204,204, 0.2);
	background-color: rgba(47,204,204, 0.2);
	vertical-align:top;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: .70em;
	font-weight: 600;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.activity_matrixLocation01 {
	height:40px !important;
	width:60px !important;
	line-height: 12px;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border: thin rgba(202,204,204, 0.2);
	background-color: rgba(254,204,204, 0.2);
	vertical-align:top;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: .70em;
	font-weight: 600;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.activity_matrixLocation02 {
	height:40px !important;
	width:222px !important;
	line-height: 12px;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border: thin rgba(202,204,204, 0.2);
	background-color: rgba(254,204,204, 0.2);
	vertical-align:top;
	text-align:center;
	text-indent: 0px;
	color:#888888;
	font-size: .70em;
	font-weight: 600;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
/***feed_panel_menu_td full_MGMT_query_metrics;*/
.activity_matrixViewDefault {
	height:56px !important;
	width:56px !important;
	line-height: 12px;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border: thin rgba(202,204,204, 0.2);
	vertical-align:top;
	text-align:center;
	background: url(../images/buttons/butt_template.png) rgba(204,204,204, 0.0);
	background-size: 35px 35px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.activity_matrixViewActive {
	height:56px !important;
	width:56px !important;
	line-height: 12px;
	margin-top:1px;
	margin-left:1px;
	border-spacing: 0px;
	border: thin rgba(202,204,204, 0.2);
	vertical-align:top;
	text-align:center;
	background: url(../images/buttons/butt_template.png) rgba(10, 245, 92, 0.7);
	background-size: 35px 35px;
   	background-repeat: no-repeat;
	background-position:center;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}

/*	form_data_filter_dynamic_div */

.form_data_matrix_dynamic_div {
	z-index: 100;
	height:575px !important;
	width:947px !important;
	display: inline-block;
	vertical-align: top;
	text-align: center; 
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border: thin rgba(209,209,209, 0.2);
	background-color:rgba(239,239,239, 0.7);
	
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
}
.form_data_matrix_dynamic_td1 {
	z-index: 100;
	height:100px !important;
	width:205px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.2);
	background-color:rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left;
}
.form_data_matrix_dynamic_td1_button {
	z-index: 100;
	height:90px !important;
	width:190px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: middle;
	text-align: center;
}
.form_data_matrix_dynamic_td_table {
    /*
    form_data_matrix_dynamic_td2
    */
	z-index: 100;
	height:215px !important;
	width:742px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.2);
	background-color:rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_matrix_dynamic_td2 {
    /*
    form_data_matrix_dynamic_td2_button
    */
	z-index: 100;
	height:40px !important;
	width:123.6px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:2px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.2);
	background-color:rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_matrix_dynamic_td2_frame_region {
    /*
    form_data_matrix_dynamic_map
    form_data_matrix_dynamic_map_td
    
    */
	z-index: 100;
	height:210px !important;
	width:741px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.2);
	background-color:rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_matrix_dynamic_td2_region {
    /*
    form_data_matrix_dynamic_td2_region_button
    */
	z-index: 100;
	height:45px !important;
	width:60px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:0px; 
	margin-left:54px;
    border-radius:100px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.2);
	background-color:rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: middle;
	text-align: center; 
}
.form_data_matrix_dynamic_td2_time {
    /*
    form_data_matrix_dynamic_td2_time_button
    */
	z-index: 100;
	height:75px !important;
	width:123.6px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.2);
	background-color:rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_matrix_dynamic_td2_span {
	z-index: 10;
	height:94px !important;
	width:369px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.2);
	background-color:rgba(239,239,239, 0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_matrix_dynamic_map {
	z-index: 1;
	height:210px !important;
	width:741px !important;
	line-height: 12px;
    position: absolute;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.0);
	background-color:rgba(239,239,239,0.7);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_matrix_dynamic_map_td {
	z-index: 1000;
	height:45px !important;
	width:741px !important;
	line-height: 12px;
    position: absolute;
	display: inline-block;
	margin-top:150px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.0);
	background-color:rgba(239,239,239,0.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_matrix_dynamic_map_button {
	z-index: 100;
	height:70px !important;
	width:60px !important;
	line-height: 12px;
	display: inline-block;
	position: absolute;
	margin-top:25px;  
	margin-left:300px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 45px 55px;
   	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
	text-align: center;
}
.form_data_matrix_dynamic_td2_region_button {
	z-index: 100;
	height:42px !important;
	width:57px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:1px;
    border-radius:100px;
	border-spacing: 0px;
	border:1px rgba(15, 5, 26, 0.7);
	background: rgba(255,255,255, 1.0);
	background-size: 25px 25px;
   	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
	text-align: center;
}
.form_data_matrix_dynamic_td2_time_button {
	z-index: 100;
	height:72px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 25px 25px;
   	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
	text-align: center;
}
.form_data_matrix_dynamic_td2_button {
	z-index: 100;
	height:37px !important;
	width:115px !important;
	line-height: 12px;
	display: inline-block;
	margin-top:1px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.3);
	background: url(../images/buttons/butt_template.png) rgba(255,255,255, 1.0);
	background-size: 25px 25px;
   	background-repeat: no-repeat;
	background-position:center;
	vertical-align: middle;
	text-align: center;
}
.form_data_matrix_dynamic_td3 {
	z-index: 100;
	height:75px !important;
	width:63px !important;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.2);
	background-color:rgba(239,239,239, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_matrix_dynamic_td3_button{
	z-index: 100;
	height:65px !important;
	width:53px !important;
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: middle;
	text-align: center; 
}
.form_data_matrix_dynamic_td4 {
	z-index: 100;
	height:75px !important;
	width:200px !important;
	display: inline-block;
	margin-top:0px; 
	margin-left:0px;
	border-spacing: 0px;
	border:1px  rgba(209,209,209, 0.2);
	background-color:rgba(239,239,239, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: top;
	text-align: left; 
}
.form_data_matrix_dynamic_td4_1_button {
	z-index: 100;
	height:65px !important;
	width:100px !important;
	line-height: 12px; 
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: middle;
	text-align: center; 
}
.form_data_matrix_dynamic_td4_2_button {
	z-index: 100;
	height:65px !important;
	width:200px !important;
	line-height: 12px; 
	display: inline-block;
	margin-top:5px; 
	margin-left:5px;
	border-spacing: 0px;
	border:1px rgba(209,209,209, 0.3);
	background-color:rgba(255,255,255, 1.0);
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	box-shadow: none !important;
	vertical-align: middle;
	text-align: center; 
}


/*********************************************/
/*********************************************/

/*********************************************/
/*********************************************/


/*********************************************/
/*********************************************/
}
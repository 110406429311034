/*!
 * ClockPicker v{package.version} for Bootstrap (http://weareoutman.github.io/clockpicker/)
 * Copyright 2014 Wang Shenwei.
 * Licensed under MIT (https://github.com/weareoutman/clockpicker/blob/gh-pages/LICENSE)
 */

/*
arrow
popover-title

popover-content
clockpicker-plate
clockpicker-canvas
clockpicker-svg

clockpicker-dial clockpicker-hours
clockpicker-dial clockpicker-minutes clockpicker-dial-out
popover clockpicker-popover bottom clockpicker-align-left
clockpicker-svg

popover clockpicker-popover bottom clockpicker-align-left

227px
max-width
*/

.title_left {
	background-color: #fff;
	border: 1px solid #ccc;
	height: 100px !important;
	width: 100px !important;
	vertical-align: middle;
	position: relative;
	display: inline-block;
}
.title_center {
	background-color: #fff;
	border: 1px solid #ccc;
	height: 100px !important;
	width: 400px !important;
	vertical-align: middle;
	position: relative;
	display: inline-block;
}
.title_right {
	background-color: #fff;
	border: 1px solid #ccc;
	height: 100px !important;
	width: 200px !important;
	vertical-align:middle;
	position: relative;
	display: inline-block;
}

.clockpicker .input-group-addon {
	cursor: pointer;
}
.clockpicker-moving {
	cursor: move;
}
.clockpicker-align-left.popover > .arrow {
	left: 25px;
}
.clockpicker-align-top.popover > .arrow {
	top: 17px;
}
.clockpicker-align-right.popover > .arrow {
	left: auto;
	right: 25px;
}
.clockpicker-align-bottom.popover > .arrow {
	top: auto;
	bottom: 6px;
}

/*
width: 550px;

text-align: center;
z-index: 1010;
width: 705px !important;
z-index: 9999 !important;

*/
.clockpicker-popover .popover-title {
	text-align:center;
	vertical-align: middle;
	background-color:#FFF;
	color: #999;
	font-size: 24px;
	font-weight: bold;
	line-height: 30px;
	
}
.clockpicker-popover .popover-title span {
	 z-index: 9999 !important;
	cursor: pointer;
}

/*

width: 712px !important;
height: 440px !important;
height: 525px !important;

*/
.clockpicker-popover .popover-content {
	height: 525px !important;
	background-color: #f8f8f8;
	padding: 9px;
}
.popover-content:last-child {

	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

/*
width: 200px;
height: 200px;
z-index: 1010;
left: 0px;
width: 430px !important;
height: 430px !important;
left: 137px;

*/
.clockpicker-plate {
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 50%;
	
	width: 530px !important;
	height: 530px !important;
	overflow: visible;
	position: relative;
	left: 110px;
	/* Disable text selection highlighting. Thanks to Hermanya */
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/*
width: 200px;
height: 200px;
	z-index: 1010;
left: -1px;
width: 430px;
	height: 430px;
clockpicker-svg
*/
.clockpicker-canvas,
.clockpicker-dial {

	width: 530px;
	height: 530px;
	position: absolute;
	left: -1px;
	top: -1px;
}

/*

color: #999;
	font-size: 10px !important;
	font-weight: bold;
*/
.clockpicker-minutes {
	
	visibility: hidden;
}
.clockpicker-tick {
	border-radius: 50%;
	color: #666;
	line-height: 50px;
	text-align: center;
	width: 50px;
	height: 50px;
	position: absolute;
	cursor: pointer;
	font-size: 34px !important;
	font-weight: bold;
	
	background-image: url(../images/buttons/butt_template.png);
	background-size: 35px 35px;
   	background-repeat: no-repeat;
	background-position:center;
}
.clockpicker-tick.active,
.clockpicker-tick:hover {
	background-color: rgb(192, 229, 247);
	background-color: rgba(0, 149, 221, .25);
	
}
/*
width: 480px;
background: url();
	
background-color:aqua;

*/
.clockpicker-button {
	
	background-image: none;
	background-color: #fff;
	border-width: 1px 0 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	margin: 0;
	padding: 10px 0;
}
.clockpicker-button:hover {
	background-image: none;
	background-color: #ebebeb;
}
.clockpicker-button:focus {
	outline: none !important;
}
.clockpicker-dial {
	-webkit-transition: -webkit-transform 350ms, opacity 350ms;
	-moz-transition: -moz-transform 350ms, opacity 350ms;
	-ms-transition: -ms-transform 350ms, opacity 350ms;
	-o-transition: -o-transform 350ms, opacity 350ms;
	transition: transform 350ms, opacity 350ms;
}
.clockpicker-dial-out {
	opacity: 0;
}
.clockpicker-hours.clockpicker-dial-out {
	-webkit-transform: scale(1.2, 1.2);
	-moz-transform: scale(1.2, 1.2);
	-ms-transform: scale(1.2, 1.2);
	-o-transform: scale(1.2, 1.2);
	transform: scale(1.2, 1.2);
}
.clockpicker-minutes.clockpicker-dial-out {
	-webkit-transform: scale(.8, .8);
	-moz-transform: scale(.8, .8);
	-ms-transform: scale(.8, .8);
	-o-transform: scale(.8, .8);
	transform: scale(.8, .8);
}
.clockpicker-canvas {
	-webkit-transition: opacity 175ms;
	-moz-transition: opacity 175ms;
	-ms-transition: opacity 175ms;
	-o-transition: opacity 175ms;
	transition: opacity 175ms;
}
.clockpicker-canvas-out {
	opacity: 0.25;
}
.clockpicker-canvas-bearing,
.clockpicker-canvas-fg {
	stroke: none;
	fill: rgb(0, 149, 221);
}
.clockpicker-canvas-bg {
	stroke: none;
	fill: rgb(192, 229, 247);
}
.clockpicker-canvas-bg-trans {
	fill: rgba(0, 149, 221, .25);
}
.clockpicker-canvas line {
	stroke: rgb(0, 149, 221);
	stroke-width: 1;
	stroke-linecap: round;
	/*shape-rendering: crispEdges;*/
}
/*
margin: 1px;
	padding: 5px;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 4px;

*/
.clockpicker-button.am-button {
	height: 30px;
	width: 30px;
	margin: 1px;
	padding: 1px;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 1px;
	display: inline-block;
	vertical-align: text-top;
	background-image: url(../images/buttons/butt_template.png);
	background-size: 22px 22px;
   	background-repeat: no-repeat;
	background-position:center;
	
}
.clockpicker-button.pm-button {
	height: 30px;
	width: 30px;
	margin: 1px 1px 1px 136px;
	padding: 1px;
	border: 1px solid rgba(0, 0, 0, .2);
	border-radius: 1px;
	display: inline-block;
	vertical-align: text-top;
	background-image: url(../images/buttons/butt_template.png);
	background-size: 22px 22px;
   	background-repeat: no-repeat;
	background-position:center;
}

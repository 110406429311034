/*   -------------------------------------------------- */
/*   -------------------------------------------------- */
/*   ------------                      ---------------- */
/*   
/*
1) RGB(11,183,255);

2) RGB(232, 232, 232)

3) RGB(255, 68, 255)

4) RGB(255, 255, 255) 
/*
/*  
/*  
/*
/*  

/*  
/* 
/*
/*
/*		
/*  
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 mainBodyTable_footer_td
CONTRUCTION
    BORDERS
        TABLE OUTSIDE   border: 1px solid rgba(209,209,209, 0.7);
        TD OUTSIDE      border: 1px solid rgba(204,204,204, 0.7);

        TABLE INSIDE    border: 1px solid rgba(204,204,204, 0.0);
        TD INSIDE       border: 1px solid rgba(204,204,204, 0.2);

        BUTTON          border: 1px solid rgba(204,204,204, 0.3);    
		
    BACKGROUND
        MAIN            background-color: rgba(255,255,255, 0.7);   
        PAGE            background-color: rgba(255,255,255, 0.2);
        FORM PAGE       background-color: rgba(239,239,239, 0.7);
        MENU            background-color: rgba(239,239,239, 0.7);  
        BUTTON          background-color: rgba(204,204,204, 0.7);   
		
    rgba(255,255,255, 0.7) = #FFF (white)
    rgba(0,0,0, 0.7) =  (black)
    rgba(239,239,239, 0.7) = #EFEFEF (grey)
    rgba(204,204,204, 0.7) = #CCC (dark border)
    rgba(0,255,0, 0.7)  =(green)
    rgba(255,255,0, 0.7) =(yellow)
    rgba(255,0,0, 0.7)  =(red)
    rgba(15,10,222,0.7)  =(blue)


    rgba(15, 5, 26, 0.7) =  (black scheme)
    rgba(10, 245, 92, 0.7)  =(green scheme)
    rgba(4, 144, 242, 0.7)  =(blue scheme)
    rgba(255, 28, 255, 0.7)  =(pink scheme)
    rgba(204, 204, 204, 0.7)  =(grey scheme)
    rgba(252, 71, 71, 0.7)  =(red scheme)
    rgba(255, 255, 128, 0.7) =(yellow scheme)
    204,204,204
01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 
    MESSAGE INPUT HEADERS
                        background:rgba(239,239,239, 0.1);
                        border-spacing: 0px;
                        border:hidden;
                        font-size: 1.05em;
                        font-weight: 500;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
    
    LABELS: ls_form_label   reset_form_label    ls_form_full_field
                        
                        font-size: .90em;
                        font-weight: 600;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.2);
                        background:rgba(239,239,239, 0.7);


    BUTONS:
                        font-size: .90em;
                        font-weight: 600;
                        text-align:center;
                        text-indent:0px;
                        text-decoration:none;
                        border-spacing: 0px;
                        border: 1px solid rgba(204,204,204, 0.3);
                        background-color: rgba(204,204,204, 0.7);
                        -webkit-box-shadow: none !important;
                        -moz-box-shadow: none !important;
                        box-shadow: none !important;

    INPUT FIELDS        ls_form_full_field_error 
                        height:35px !important;
                        width: 98%  !important;
                        line-height:12px !important;
                        text-align:left;
                        vertical-align:middle;
                        font-size: .80em;
                        font-weight: 500;
                        text-align:left;
                        text-decoration:none;
                        text-indent:5px;
                        border-spacing: 0px;
                        border:hidden;
                        background-color:rgba(239,239,239, 0.7);

ls_form_submit_button   login_submit_default    ls_notification_submit_button   eventsystem_div_04   mainBodyTable_footer_td1
                                                                                                    
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */

/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */












/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001 */

/* ----------------------------------------------------------------------------------------- 
	mobile_header_container					|	header_container
	mobile_profile_header_background		|	profile_header_background
	mobile_profile_header_table				|	profile_header_table

	*mobile_defaultbody_table
	*mobile_defaultbody_table_td
	*mobile_base_functional_full_table		|	base_functional_full_table
	*mobile_base_functional_full_table_td	|	base_functional_table_td
			
	mobile_foot_container
	mobile_site_open_footer_table_full_main
	mobile_site_footer_table_full

*/
/* ----------------------------------------------------------------------------------------- */
@media screen and (max-device-width: 500px) and (min-device-width: 200px){
body {
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    background-color:rgba(255,255,255, 0.0);
    font-family: Arial, Helvetica, sans-serif;
    /*
    font-family: "Times New Roman", Times, serif;
    */
    /*overflow-y: hidden;  Hide vertical scrollbar */
    overflow-x: hidden;/* Hide horizontal scrollbar */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar { 
    display: none; 
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}
.mobile_temp_text{
	z-index: 90;
	top:0px;
    margin-top: -200px;
    margin-left: 1px;
    border-spacing: 0px;
	background-color:transparent; 
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    font-size: 1.12em;
    font-weight: 500;
    color: #000;
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
	color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
	*/
	vertical-align:top !important;
    text-align: left;
    text-decoration:none;
    text-indent:0px;
}
/*********************************************/
/*********************************************/
.any_visuallyhidden {
    display:none;
    visibility:hidden;
    margin:0;
    padding:0;
    text-align:left;
}

.default{background-color:#FFF;}/* DEFAULT  */
.weak{
    width: 160px !important;
    background-color:#F00; 
    color:#FFF;
    font-weight:700;
}/* WEAK  */
.strong{
    width: 160px !important;
    background-color:#09F;
    color:#FFF;
    font-weight:700;
}/* AVERAGE  */
.stronger{
    width: 160px !important;
    background-color:#0C0; 
    color:#FFF;
    font-weight:700;
}/* STRONGER  */
.strongest{
    width: 160px !important;
    background-color:#FC0;
    color:#FFF;
    font-weight:800;
}/* SECURE  */
/* ################################# */
h1 { 
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    /*  32px  */
}

h2 {

    font-weight: bold;
    text-decoration:none;
    /*  24px  font-size: 1.5em;*/
}

h3 { 

    font-weight: bold;
    text-decoration:none;
    /*  18px  font-size: 1.17em;*/
}

h4 { 
    display: block;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}

h5 { 
    display: block;
    font-size: .83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    /*  13px  */
}

h6 { 
    display: block;
    font-size: .67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    /*  10px  */
}
.ui-checkbox {
        display:none;
        visibility:hidden;
}
.ui-radio {
        display:none;
        visibility:hidden;
}

.ui-overlay-a, .ui-page-theme-a, .ui-page-theme-a .ui-panel-wrapper{

}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
    font-weight: normal;
    color: #555 !important;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

.ui-input-text, .ui-input-search {
  margin: 0;
  padding:0;
  border-width: 1px;
  border-style: solid;
}
.ui-input-text ui-body-inherit ui-corner-all ui-shadow-inset {
  margin: 0;
  padding:0;
  border-width: 1px;
  border-style: solid;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: rgba(4, 144, 242,0.7) !important;
    background-image: none;
    color: rgb(0, 0, 0);
}
.brightRed {
    color: rgba(252, 71, 71, 0.7);
    width:5px !important;
    display:inline;
}


.form_error_border {
    border-width:thin;
    border-style:solid;
    border-color:rgba(252, 71, 71, 0.7);
}
.window_load_action {
    z-index: 99999999;
    background: rgba(255,255,255, 0.0);
    border:hidden;
    border-style: none !important;
    height:100% !important;
    width:100% !important;
    position: fixed;
    display:none;
}	
.window_load_cover {
    z-index: 999999999;
    border:hidden;
    border-style: none !important;
    height:100% !important;
    position:fixed;
    display:block;
    filter: Alpha(Opacity=99);
    opacity: .99;
    /*
    background: rgba(255,45,255, 0.4);
    */
	table-layout: auto;
    width: 100% !important;
}
.loader_active {
    z-index:99999999;
    position:absolute;
	/*
	
	height:300px !important;
    width:300px !important;
    */
    margin-left:0%;
    margin-top:0%;
	
    vertical-align: top;
    text-align: left;
    border:hidden;
    border-style: none !important;
    /*
    background-image:url(../images/bkgrounds/loader.gif) ;
	background:url(../images/loader/ThePeoplesForm-Logo04-GIF.gif);
	background:url(../images/loader/ThePeoplesForm-Logo04-GIF-03-SMALLER.gif);
	
	
    */
    background:url(../images/loader/ThePeoplesForm-Logo04-GIF.gif);
    background-size: 300px 300px;
    background-repeat:no-repeat;
	background-position:center;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
	table-layout: fixed;
	height:100% !important;
    width: 100% !important;
}

.pop_loader_active {
    position:absolute;
    z-index:150;
    height:100% !important;
    width:100% !important;
    line-height:620px;
    text-align:center;
    vertical-align:middle;
    background-color:transparent;
    border:hidden;
    border-style: none !important;
    /*
    background-image:url(../images/bkgrounds/loader.gif) ;
	
	background-image:url(../images/bkgrounds/loader.gif) ;
	background:url(../images/loader/ThePeoplesForm-Logo04-GIF.gif);
	background:url(../images/loader/ThePeoplesForm-Logo04-GIF-03-SMALLER.gif);
    */
    background:url(../images/loader/ThePeoplesForm-Logo04-GIF.gif);
    background-position:center;
    background-size: 300px 300px;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
}
.loader_default {
    visibility:hidden;
    display:none;
}
.progress_loader_default {
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #ddd;
    visibility:hidden;
    display:none;
}
.progress_loader_active {
    /*
    background-color: #ddd;
    */
    margin-left:25%;
    margin-top:25%;
    position: absolute;
    z-index:150;
    height:30px !important;
    width: 100%;
    line-height:30px;
    text-align:center;
    vertical-align:middle;

    background-color:#9CF;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
}
.progress_loader_text {
    z-index:150;
    position:absolute;
    width: 100%;
    height: 30px;
    background-color:transparent;
   -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
}
.progress_loader_bar {
    position:relative;
    /**/
    width: 1%;
    height: 30px;
    background-color: #4CAF50;
   -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    filter: Alpha(Opacity=99);
    opacity: .99;
}
.label {
    height:25px ;
    line-height:25px ;
    font-size: .90em;
    font-weight: 600;
    text-decoration:none;
    background:#FFF;
}
/*********************************************/
/*********************************************/
/************************//************************//************************/
/************************//*	WINDOW_OVERLAY	*/
/********//*	WINDOW_OVERLAY_MAIN	*/
#window_overlay{
    z-index: 900;
    display: none;
    position:fixed;
    left: 0px;
    background-color: rgba(255,255,255, 0.9);
    width: 100%;
    height: 100%;

}
.photo_overlay{
    z-index: 900;
    display: none;
    position:fixed;
    left: 0px;
    background-color: rgba(255,255,255, 0.7);
    width: 100%;
    height: 100%;
}

#window_view{
    z-index: 2002;
    margin-top: 18px;		
    position: absolute;
    border:thin #000;
    border-radius:7px; 
    display: none;
    background-color: rgba(255,255,255, 0.7);
	table-layout: fixed;
	height:100% !important;
    width: 100% !important;
}
.window_view_UI_control_class{
    z-index: 10;
    border:hidden;
    background-color:transparent;
    cursor:pointer;
}
.window_view_UI_main_control_class{
    z-index: 10;
    background-color:transparent;
    position:relative;
}
.notification_window_close{
    right:0;
    position:absolute;
    z-index: 10;
	background-color:transparent;
}
.window_view_close_button{
    height:50px;
    width:50px;
    background: url(../images/buttons/sys_close_buttonDefault.png) rgba(255,255,255, 0.3);
    background-position: center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    border:hidden;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    /*
    filter: Alpha(Opacity=99);
    opacity: .99;
    */
}
/*********************************************/
/*********************************************/
/************************//************************//************************/
/*********************************************/
/*****************************/
/* COOKIE */
#cookie-consent {
    display: none;

    width:100%;
    height: 100%;

    position:fixed;
    bottom:0px;

    background-color: rgba(15, 5, 26,0.8);

    z-index: 100000;
}

.cookie-consent-inner {
    width:100%;

    padding: 20px;

    position:fixed;
    bottom:0px;

    background-color: rgba(0,74,119,1);
    color: rgba(255,209,0,1);
}

.cookie {
    width: 100%;
    max-width: 1248px;
    margin: 0 auto;

    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.cookie-msg {
    width: calc(100% - 120px);
    margin-right: 20px;
    float: left;
}
.cookie-accept {
    width: 100px;
    float: right;
}
.cookie-button {
    font-size: 16px;
    line-height: 40px;
    padding: 0px;
    color: #FFD100;
    width: 100px;
    background-color: #337ab7;
    cursor: pointer;
    border: 1px solid white!important;
    -o-transition: all 0.4s linear;
    -moz-transition: all 0.4s linear;
    -ms-transition: all 0.4s linear;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
    text-align: center;
}
.cookie-button:hover {
    background-color: #0072AE;
}
/* END COOKIE */
/*****************************/
/*****************************/
.crop_image {
  	
  	/* This rule is very important, please don't ignore this */
  	max-width: 100%;
	
}
/* Thumbnail */
.thumbnail{
    width: 80px;
    height: 80px;
    padding: 2px;
    border: 2px solid lightgray;
    border-radius: 3px;
    float: left;
}
/*********************************************/
/************************//************************//************************/
.QR_CODE_DIV_BUSINESSS {
	z-index: 1 !important;
    height: 102px !important;
    width: 102px !important;
	padding: 0px  !important;
	position: absolute;
	margin-top: 0px;
	margin-left: -80px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);
	background-size: 138px 138px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.QR_CODE_DIV_BUSINESSS_BASIC {
	z-index: 1 !important;
    height: 102px !important;
    width: 102px !important;
	padding: 0px  !important;
	position: absolute;
	margin-top: -7px;
	margin-left: -80px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);
	background-size: 138px 138px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.QR_CODE_DIV_BUSINESSS_SLICE {
	z-index: 1 !important;
    height: 102px !important;
    width: 102px !important;
	padding: 0px  !important;
	position: absolute;
	margin-top: -7px;
	margin-left: -80px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);
	background-size: 138px 138px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.QR_CODE_DIV_BUSINESSS_SHOWROOM {
	z-index: 1 !important;
    height: 102px !important;
    width: 102px !important;
	padding: 0px  !important;
	position: absolute;
	margin-top: -7px;
	margin-left: -80px;
	vertical-align: top  !important;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.7);
	background-size: 138px 138px;
	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.QR_CODE_DIV_EVENT {
	z-index: 1000 !important;
    height: 102px !important;
    width: 102px !important;
	/*
	line-height: 0px !important;
	*/
	padding: 0px  !important;
	position: absolute;
	margin-top: 0px;
	margin-left: 0px;
	vertical-align: top;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.0);
	background-size: 138px 138px;
   	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.QR_CODE_DIV_EVENT_ITEM {
	z-index: 1000 !important;
    height: 102px !important;
    width: 102px !important;
	/*
	line-height: 0px !important;
	display: inline-block;
	*/
	padding: 0px  !important;
	position: absolute;
	margin-top: -28px;
	margin-left: 0px;
	vertical-align: top;
	text-align: left !important;
    border:1px solid rgba(239,239,239, 0.2);
    background: rgba(255,255,255, 0.0);
	background-size: 138px 138px;
   	background-repeat: no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************************/
/*********************************************/

/*
.mobile_base_functional_extended_full_table {
    width:100% !important;
    width:950px !important;
    background:#FFF;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-spacing: 0px;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.base_functional_left_panel_full_table_td {
    width:720px;
    border:hidden;
    border-spacing: 0px;
    vertical-align: top;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.base_functional_right_panelfull_table_td {
    height:615px;
    width:230px;
    border-right: hidden;
    border-left: 1px solid #ccc;
    border-top: hidden;
    border-bottom: hidden;
    border-spacing: 0px;
    position:absolute;
    text-align:center;
    max-height: 615px;
    table-layout:fixed;
    vertical-align: top;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
*/
/*********************************************/
/*********************************************/



/*********************************************/
/*********************************************/
.mobile_full_MGMT_query_metrics {
	z-index: 100;
    height:12px !important;
    width:180px;
    display:inline-block;
    line-height:12px;
    position:absolute;
    left:250px;
    top:85%;
	border-spacing: 0px;
    border:1px solid rgba(239,239,239, 0.7);
    background:rgba(255,255,255, 0.9);
	vertical-align: middle;
    text-align:center;
    font-size: 1em;
	font-weight: 700;
    color:rgba(203,203,203, 0.9);
    text-decoration:none;
}

/* ################################# */
.mobile_defaultbody_table {
    height:99% !important;
    width:100% !important;
    top:0;
    margin-top: 2px;
	border-spacing: 0px;
	border:hidden;
    background-color:rgba(255,255,255, 0.0);
}
.mobile_defaultbody_table_td {
    height:99% !important;
    width:100% !important;
    text-align:left !important;
    vertical-align:top !important;
	border-spacing: 0px;
	border:1px solid rgba(233,233,233, 0.1);
    background:  rgba(255,255,255, 0.7);
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************************/
/*********************************************/
.mobile_site_footer_table_full_td1 {
   	height:40px;
	width:100% !important;
	line-height: 40px;
	border-spacing: 0px;
	border: 1px solid rgba(204,204,204, 0.2);
    background-color:rgba(255,255,255, 0.4);
}
/*********************************************/
/*********************************************/
/*
    site_footer_table_full
    site_open_footer_table_full_main
    site_open_footer_table_full_td
    site_open_footer_table_full_phrase
    site_open_footer_table_full_main
	
	
*/
	
.mobile_site_open_footer_table_full_main {
    height:40px;
    width:99.9% !important;
    line-height: 40px !important;
}
.mobile_foot_container {
	z-index: 100;
    height:40px;
    width:99.9% !important;
    line-height:40px ;
    display: inline-block;
	position: absolute;
    margin-top: 5px;
    margin-left: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    /*
    background-color:rgba(204,204,204, 0.4);
    padding:0px;
    */
}
.mobile_site_open_footer_table_full_main_td {
    height:40px;
    line-height: 40px !important;
	vertical-align: middle  !important;
	text-align: center !important;
	table-layout: fixed;
	width: 100% !important;
}
/*********************************************/
/*********************************************/
.mobile_profile_header_table_logo {
    z-index: 999;
    height:70px !important;
    width:99.9% !important;
    line-height: 70px;
    border-spacing: 0px;
    border: hidden;
    background-color: transparent;
    text-align: center !important;
    vertical-align: text-top !important;
    /* THEPEOPLESFORM-01.png - ration: 10.54   
    background-image:url(../images/logo/HEADER/THEPEOPLESFORM-01.png);
    background-size: 316.2px 30px;
    */
    /* THEPEOPLESFORM-02.png - ration: 9.069   
    background-image:url(../images/logo/HEADER/THEPEOPLESFORM-02.png);
    background-size: 272.07px 30px;
    */
    /* THEPEOPLESFORM-03.png - ration: 9.069   
    background-image:url(../images/logo/HEADER/THEPEOPLESFORM-03.png);
    background-size: 272.07px 30px;
    */
    /* THEPEOPLESFORM-01.png - ration: 10.54  */ 
    background-image:url(../images/logo/HEADER/THEPEOPLESFORM-04.png);
    /*
    background-color:rgba(255,255,255, 0.0);
    background-size: 316.2px 30px;
    background-size: 263.5px 25px;
    */
    background-size: 210px 20px;

    background-position:left;
    background-repeat:no-repeat;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
/*********************************************/
/*********************************************/
.mainBodyTable_footer{
    z-index: 1999 !important;
    height:40px !important;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.0);
    background-color:rgba(255,255,255, 0.0);
    table-layout: fixed;
    width: 100% !important;
}
.mainBodyTable_footer_td{
    z-index: 1999 !important;
    height:40px !important;
    border-spacing: 0px;
    border:hidden;
    background-color:rgba(255,255,255, 0.0);
    vertical-align: middle  !important;
	text-align: center !important;
    table-layout: fixed;
    width: 100% !important;
}
.mainBodyTable_footer_td1{
    height:40px !important;
    border-spacing: 0px;
    border:hidden;
    background-color:rgba(255,255,255, 0.0);
    vertical-align: middle  !important;
	text-align: center !important;
    table-layout: fixed;
    width: 120px !important;
}
.mainBodyTable_footer_td2{
    height:40px !important;
    border-spacing: 0px;
    border:hidden;
    background-color:rgba(255,255,255, 0.0);
    vertical-align: middle  !important;
	text-align: center !important;
    table-layout: fixed;
    width: 120px!important;
}
.mainBodyTable_footer_td3{
    height:40px !important;
    border-spacing: 0px;
    border:hidden;
    background-color:rgba(255,255,255, 0.0);
    vertical-align: middle  !important;
	text-align: center !important;
    table-layout: fixed;
    width: 60% !important;
}
.active_mainBodyTable_footer_td1{
    height:40px !important;
    border-spacing: 0px;
    border:hidden;
    background-color:transparent;
    vertical-align: middle  !important;
	text-align: center !important;
    table-layout: fixed;
    width: 100% !important;
}
.active_mainBodyTable_footer_td2{
    height:40px !important;
    border-spacing: 0px;
    border:hidden;
    background-color:transparent;
    vertical-align: middle  !important;
	text-align: center !important;
    table-layout: fixed;
    width: 100% !important;
}
.active_mainBodyTable_footer_td3{
    height:40px !important;
    border-spacing: 0px;
    border:hidden;
    background-color:transparent;
    vertical-align: middle  !important;
	text-align: center !important;
    table-layout: fixed;
    width: 100% !important;
}
.active_mainBodyTable_footer_td4{
    height:40px !important;
    border-spacing: 0px;
    border:hidden;
    background-color:transparent;
    vertical-align: middle  !important;
	text-align: center !important;
    table-layout: fixed;
    width: 100% !important;
}
.active_mainBodyTable_footer_td5{
    height:40px !important;
    border-spacing: 0px;
    border:hidden;
    background-color:transparent;
    vertical-align: middle  !important;
	text-align: center !important;
    table-layout: fixed;
    width: 100% !important;
}
/*********************************************/
/*********************************************/
.mobile_site_open_footer_table_full_space{
    height:40px !important;
    border-spacing: 0px;
    border:hidden;
    background-color:rgba(255,255,255, 0.0);
    vertical-align: middle  !important;
	text-align: center !important;
    table-layout: fixed;
    width: 20% !important;
}
.mobile_site_open_footer_table_full_text {
    height:40px;
    line-height: 25px !important;
    margin-top: 0px;
    margin-left: 00px;
    border: 1px solid rgba(255,255,255, 0.4);
    background-color: rgba(255,255,255, 0.0);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:91% 45%;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
    text-align:left;
    font-size: .80em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

    */
	table-layout: fixed;
    width: 220px !important;
}
.mobile_site_open_footer_table_full_logo {
    height:40px;
    line-height: 25px !important;
    margin-top: 0px;
    margin-left: 0px;
    border: 1px solid rgba(255,255,255, 0.4);
    background: url(https://glbl-resources-public.s3.amazonaws.com/logo/MADEWITH-LOGO-BRANDMARK-2000PX.png) rgba(255,255,255, 0.0);
    background-size: 35px 35px;
    background-repeat: no-repeat;
    background-position:left;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    vertical-align:middle;
    text-align:center;
    font-size: .70em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
    text-shadow: 1px 0 0 #fff, 0 -1px 0 #fff, 0 1px 0 #fff, -1px 0 0 #fff;
    /*
    color: #fff;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;

    */
	table-layout: fixed;
    width: 37px !important;
}
/*********************************************/
/*********************************************/

/*********************************************/
/*********************************************/

/*********************************************/
/*********************************************/


/*********************************************//*********************************************//*********************************************/
/*********************************************//*********************************************//*********************************************/
}
/*********************************************//*********************************************//*********************************************/
/*********************************************//*********************************************//*********************************************/
/* ################################# */
/* ################################# */
.mobile_profile_header_background {
    z-index: 9999;
    height:70px !important;
    line-height: 70px;
    width:100% !important;
	margin-top:1px;
    border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.1);
    background-color:rgba(239,239,239, 1);
    background-repeat:repeat;
    text-align: center !important;
    vertical-align:text-top !important;
}

.mobile_header_container {
    height:70px !important;
    width:99.9% !important;
    line-height:70px ;
    display: inline-block;
    position:absolute;
    left:0px;
    margin-left: 0px;
    top: 0px;
	border-spacing: 0px;
    border: 1px solid rgba(209,209,209, 0.1);
    background-color:rgba(239,239,239, 0.0);
}

/*********************************************/


